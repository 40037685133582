import { motion } from 'framer-motion';
import styled from 'styled-components';

import { breakpoints } from 'theme';
export const HeaderIcon = styled.div`
  height: ${({ info }) => (info ? '30vh' : '42vh')};
  width: ${({ info }) => (info ? '30vh' : '42vh')};
  margin-top: ${({ info }) => (info ? '-50px' : '-75px')};
  max-width: ${({ info }) => (info ? '30vh' : '42vh')};
  max-height: ${({ info }) => (info ? '30vh' : '42vh')};
  div {
    max-width: ${({ info }) => (info ? '22vh' : '42vh')} !important;
    max-height: ${({ info }) => (info ? '30vh' : '42vh')} !important;
  }
  @media (${breakpoints.downSmPlus}) {
    height: ${({ info }) => (info ? '25vh' : '200px')};
    width: ${({ info }) => (info ? '30vh' : '200px')};
    margin-top: ${({ info }) => (info ? '-100px' : '-55px')};
  }
`;
export const CloseButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  button {
    width: 40px;
    height: 40px;
  }
`;
export const StyledAddMemberPopup = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  padding: 24px 24px 16px 24px;
  border-radius: 20px 24px 38px 24px;
  max-height: calc(100svh - 80px);
  align-items: center;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }

  @media ${breakpoints.downLg} {
    width: 80%;
    min-width: auto;
  }

  @media ${breakpoints.downMd} {
    width: 100%;
    max-height: calc(100svh - 80px);
  }
`;

StyledAddMemberPopup.Header = styled.div`
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  padding: 0px 16px 0px 16px;
  position: relative;
  align-items: center;
  gap: 6px;
  justify-content: center;
  width: 100%;
  @media ${breakpoints.downSmPlus} {
    padding: 24px 16px 0px 16px;
  }

  h2 {
    color: ${({ theme }) => theme.color.pickledBluewood};
    font-weight: 600;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    @media ${breakpoints.downMd} {
      line-height: 110%;
    }
  }
  p {
    color: ${({ theme }) => theme.color.baliHai};
    line-height: normal;
    letter-spacing: -0.011em !important;
    text-align: center !important;
    b {
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;
      color: ${({ theme }) => theme.color.pickledBluewood};
      border-bottom: 1px solid ${({ theme }) => theme.color.pickledBluewood};
    }
    @media ${breakpoints.downMd} {
      line-height: 123% !important;
      br {
        display: none;
      }
    }
  }
`;

StyledAddMemberPopup.CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 16px 16px 16px;
  position: relative;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
`;

StyledAddMemberPopup.CardWrapper.Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex: 1;
  justify-content: space-between;
  padding: 24px 24px 12px 24px;
  border-radius: 16px;
  align-items: center;
  min-width: 250px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  gap: 42px;
  @media ${breakpoints.downMd} {
    gap: 22px;
  }
`;

StyledAddMemberPopup.CardWrapper.Card.Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

StyledAddMemberPopup.CardWrapper.Card.title = styled.h3`
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 111%;
`;

StyledAddMemberPopup.CardWrapper.Card.subTitle = styled.p`
  font-size: 15px !important;
  letter-spacing: -0.011em !important;
  line-height: 140% !important;
  max-width: 360px;
  b {
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.color.pickledBluewood};
    border-bottom: 1px solid ${({ theme }) => theme.color.pickledBluewood};
  }
  br {
    content: '';
    display: block;
    margin: 10px 0;
  }
  span {
    cursor: pointer;
    font-weight: 600;
    letter-spacing: -0.011em;
    color: #163300;
    text-decoration: underline;
    line-height: 150%;
    text-underline-offset: 0.3em;
    svg {
      margin-left: 4px;
    }
  }
`;
StyledAddMemberPopup.CardWrapper.Card.BtnWrapper = styled.div`
  display: flex;
  width: 100%;
`;

StyledAddMemberPopup.CardWrapper.Card.Btn = styled.button`
  display: flex;
  border: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  touch-action: manipulation;
  letter-spacing: -0.011em;
  padding: 11px 24px;
  background-color: #2ebfba;
  gap: 12px;
  border-radius: 9999px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  cursor: pointer;
  margin: 12px 4px 6px 4px;
  transition: color, background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.color.brightTurquoise};
  }
  span {
    font-size: 65px;
    padding-top: 3px;
    font-weight: 500;
  }
  @media ${breakpoints.downMd} {
    margin: 12px 0px 6px 0px;
  }
`;
export const StyledAddMemberPopupWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  display: flex;
  justify-content: center;
  padding: 40px;
  background: rgba(0, 0, 0, 0.4);
  align-items: flex-start;
  z-index: 100000000;
  @media ${breakpoints.downMd} {
    padding: 40px 20px;
  }
  @media ${breakpoints.downSmPlus} {
    padding: 40px 10px;
  }
`;
