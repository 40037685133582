import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPageContainer from './Container';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <ErrorPageContainer errorTitle="404" errorText={t('errors.text404')} />
  );
};

export default Page404;
