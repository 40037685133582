import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ADD_FIELD } from 'constants/sidebars';
import { doUpdateCommunity } from 'store/actionCreators/communities';
import { openModal } from 'store/actionCreators/modal';
import { useGeneralCommunitySettingsForm } from './form';
import { FIELDS } from './types';
import { Community, CommunityCoverDetails } from 'types';
import { useInfoPopup } from 'hooks';
import { useState } from 'react';

export const useGeneralCommunitySettings = (community: Community) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showInfoPopup } = useInfoPopup();
  const [coverDetail, setCoverDetail] = useState<CommunityCoverDetails>({
    delete: false,
    ext: '',
    filename: '',
    mimetype: '',
    size: 0,
  });

  const links =
    community.links?.filter(
      (l) =>
        l.label !== 'website' &&
        l.label !== 'facebook' &&
        l.label !== 'linkedin' &&
        l.label !== 'instagram' &&
        l.label !== 'twitter' &&
        l.label !== 'youtube',
    ) || [];
  const partnerships = [
    'facebook',
    'linkedin',
    'instagram',
    'twitter',
    'youtube',
  ].map((l) => ({
    label: l,
    value: community.links?.find((link) => link.label === l)?.value || '',
  }));
  const website = community.links?.find((l) => l.label === 'website') || {
    label: 'website',
    value: '',
  };

  const {
    handleSubmit,
    register,
    setValue,
    unregister,
    linksFieldProps,
    partnershipsFieldProps,
    ...form
  } = useGeneralCommunitySettingsForm({
    [FIELDS.COMMUNITY_NAME]: community.name,
    [FIELDS.DESCRIPTION]: community.description,
    [FIELDS.WEBSITE]: website,
    [FIELDS.LINKS]: links,
    [FIELDS.PARTNERSHIPS]: partnerships,
    [FIELDS.CATEGORY]: community.category,
    [FIELDS.COVER]: community.cover,
    [FIELDS.LOGO]: community.logo,
  });

  const openAddFieldSidebar = () =>
    dispatch(
      openModal({
        type: ADD_FIELD,
        placement: 'right',
        data: (name) => linksFieldProps.append({ label: name, value: '' }),
      }),
    );

  const deletePartnership = (i) => {
    linksFieldProps.remove(i);
  };

  const onSuccess = () => {
    showInfoPopup({
      title: t('communitySettings.editSuccess'),
      type: 'success',
    });
  };

  const onSubmit = handleSubmit((values) => {
    let links = [...values[FIELDS.PARTNERSHIPS], values[FIELDS.WEBSITE]].filter(
      (i) => i.value,
    );

    if (values[FIELDS.LINKS]) {
      links = links.concat(values[FIELDS.LINKS]);
    }

    dispatch(
      doUpdateCommunity({
        id: community.id,
        ...values,
        links,
        notifications: community.notifications,
        onSuccess,
        coverDetail,
      }),
    );
  });

  return {
    onSubmit,
    deletePartnership,
    openAddFieldSidebar,
    register,
    unregister,
    setValue,
    linksFieldProps,
    partnershipsFieldProps,
    setCoverDetail,
    ...form,
  };
};
