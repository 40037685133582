import React, { FC } from 'react';
import { noop } from 'lodash/fp';

import {
  StyledButtonGroup,
  StyledLeftButton,
  StyledRightButton,
  StyledLeftLabel,
  StyledRightLabel,
  StyledLeftRadio,
  StyledRightRadio,
  StyledPromoLabel,
} from './styled';

interface ButtonGroupProps {
  name: string;
  leftText: string;
  rightText: string;
  disabled?: boolean;
  leftValue: string;
  rightValue: string;
  checkedButton: string;
  promo?: boolean;
  width?: string;
  onClick: (e: Event) => void;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  name,
  leftText,
  rightText,
  disabled,
  leftValue,
  rightValue,
  checkedButton,
  promo,
  width = '340px',
  onClick = () => {},
}) => {
  const getId = (radioName, text) => `${radioName}-${text}`;

  return (
    <StyledButtonGroup width={width}>
      <StyledLeftButton>
        <StyledLeftRadio
          name={name}
          type="radio"
          id={getId(name, leftText)}
          value={leftValue}
          disabled={disabled}
          onClick={onClick}
          checked={checkedButton === 'left'}
          onChange={noop}
        />
        <StyledLeftLabel htmlFor={getId(name, leftText)}>
          <span>
            {leftText}
            {promo && <StyledPromoLabel>-30%</StyledPromoLabel>}
          </span>
        </StyledLeftLabel>
      </StyledLeftButton>
      <StyledRightButton>
        <StyledRightRadio
          name={name}
          type="radio"
          id={getId(name, rightText)}
          value={rightValue}
          disabled={disabled}
          onClick={onClick}
          checked={checkedButton === 'right'}
          onChange={noop}
        />
        <StyledRightLabel htmlFor={getId(name, rightText)}>
          <span>{rightText}</span>
        </StyledRightLabel>
      </StyledRightButton>
    </StyledButtonGroup>
  );
};

export default ButtonGroup;
