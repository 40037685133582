import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: 'AIzaSyCe8zsy-xlqY4HaxBTRQBMwDjHLAXTHGRU',
  authDomain: 'gumb-d2c37.firebaseapp.com',
  databaseURL: 'https://gumb-d2c37.firebaseio.com',
  projectId: 'gumb-d2c37',
  storageBucket: 'gumb-d2c37.appspot.com',
  messagingSenderId: '634800902990',
  appId: '1:634800902990:web:d70fc53923eae4ce7c441f',
  measurementId: 'G-JD7R6ZVV65',
};

initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60;
remoteConfig.defaultConfig = {
  translations: '',
};

export { remoteConfig };
