import styled from 'styled-components';
import { breakpoints } from 'theme';
import { motion } from 'framer-motion';

export const PushTemplateContainer = styled.div`
  /* flex: 1; */
  padding-right: 8px;
  min-width: 320px;
  width: 320px;
  max-height: 874px;
  /* max-width: 800px; */
  /* margin-top: 20px;
  padding-top: 20px; */
  /* border: 2px solid ${({ theme }) => theme.color.lightGrey}; */
  /* border-radius: 16px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* @media ${breakpoints.lgPlus} {
    max-width: 400px;
  } */
  /* @media ${breakpoints.downLgPlus} {
    max-width: 360px;
    align-self: center;
    justify-self: center;
  } */
`;
export const CheckBoxWrapper = styled.div`
  /* position: absolute;
  top: -38px; */
  /* right: 0; */
  text-align: left;

  & > label {
    width: auto;
    font-size: 12px;
    color: #8298ab;
    font-weight: 600;
    padding-top: 1px;
  }
`;
export const SelectBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  gap: 24px;
  margin-bottom: 40px;
  border-right: ${({ rightBorder, theme }) =>
    rightBorder && `1px solid ${theme.color.lightGrey}`};
  padding: ${({ padding = '0px' }) => padding};
  /* @media (max-width: 1832px) {
    display: none;
  } */
`;

export const TemplateList = styled(motion.div)`
  scrollbar-width: thin;
  width: 100%;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: baseline;
  gap: 16px;
`;

export const PushTemplateWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  /* border: 1px solid ${({ theme }) => theme.color.lightGrey}; */
  padding: 8px;
  position: relative;
  /* background-color: ${({ theme }) => theme.color.porcelain};
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  background: linear-gradient(#031819 25%, #053340, #148c8c, #2ebebe); */
  height: auto;
  @media (max-width: 1832px) {
    max-width: 340px;
  }
`;

export const StyledTemplateHeader = styled.h3`
  padding: 8px;
  background-color: ${({ theme }) => theme.color.porcelain};
`;

export const TemplateName = styled.h4`
  color: ${({ theme }) => theme.color.black};
  font-family: 'Open sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin: 16px 0px;
  gap: 8px;
  width: 100%;
`;

export const Empty = styled.label`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 12px;
  font-weight: 600;
  margin-top: 9px;
`;

export const ButtonLoaderWrapper = styled.div`
  width: ${({ width = '100%' }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
`;
