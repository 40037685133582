import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootSaga from './sagas';

import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  sagaMiddleware,
  process.env.REACT_APP_API_PREFIX === 'stage' && logger,
].filter(Boolean);

const index = createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleware.run(rootSaga);

export default index;
