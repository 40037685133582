import React, { FC } from 'react';

import { StyledButton, StyledIcon, StyledIconContainer } from './styled';

interface CloseButtonProps {
  hidden?: boolean;
  onClick: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ hidden, onClick }) => (
  <StyledButton
    className="close-btn"
    hidden={hidden}
    type="button"
    onClick={onClick}
  >
    <StyledIconContainer>
      <StyledIcon />
    </StyledIconContainer>
  </StyledButton>
);

export default CloseButton;
