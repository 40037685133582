import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import {
  MainMenuUserItem,
  MainMenuCommunityItem,
  PlusButton,
  FaqButton,
  Tooltip,
} from 'components';
import { openModal, closeModal } from 'store/actionCreators/modal';
import { getAuthUser, getNotifications } from 'store/selectors/auth';
import { selectCurrentUserSubscription } from 'store/selectors/subscriptions';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';
import { getCommunities } from 'store/selectors/communities';
import { checkUnreadNotifications } from 'utils/common';
import { CREATE_COMMUNITY } from 'constants/sidebars';
import { POPUP_MESSAGE } from 'constants/popups';
import { CommunityRoles } from 'types';
import { Logo } from 'static';

import {
  StyledMainMenuBlock,
  StyledMainMenuTopBlock,
  StyledSeparator,
  StyledFaqButtonBlock,
  StyledMainMenuCommunities,
  StyledBottomMenu,
} from './styled';
import { PROFILE_ROUTES, ROUTES } from 'configs';
import { useNavigate } from 'react-router-dom';

const MainMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const communities = useSelector(getCommunities);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const notifications = useSelector(getNotifications);
  const subscription = useSelector(selectCurrentUserSubscription);
  const userProfile = useSelector(getAuthUser);
  const tooltipProps = {
    tooltipStyle: {
      fontSize: 12,
      maxWidth: 200,
      whiteSpace: 'pre-wrap',
      lineBreak: 'normal',
    },
  };
  const handleRedirect = () => {
    dispatch(closeModal());
    navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.SUBSCRIPTION}`, {
      replace: true,
    });
  };

  const onAddCommunity = () => {
    if ((subscription?.info?.communities || 0) < subscription.maxCommunities) {
      dispatch(openModal({ type: CREATE_COMMUNITY, placement: 'right' }));
    } else {
      dispatch(
        openModal({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: t('community.createOverLimitTitle'),
            popupMessageText: t('community.createOverLimitText'),
            popupButtonText: 'Ok',
            doClickOutSide: false,
            withoutCloseButton: true,
            handleConfirm: () => handleRedirect(),
          },
        }),
      );
    }
  };

  if (!userProfile) {
    return null;
  }

  return (
    <StyledMainMenuBlock isMenuOpen={isMenuOpen}>
      <StyledMainMenuTopBlock>
        <MainMenuUserItem
          image={userProfile.smallLogo || userProfile.logo}
          hasNotifications={checkUnreadNotifications(notifications)}
        />
        <PerfectScrollbar
          style={{
            width: '100%',
            maxHeight: 'calc(100svh - 266px)',
            marginBottom: '8px',
          }}
          className={'communities-list-scrollbar'}
          options={{
            wheelSpeed: 0.2,
            wheelPropagation: false,
            minScrollbarLength: 4,
          }}
        >
          <StyledMainMenuCommunities>
            {communities &&
              communities.map((community, index) => (
                <MainMenuCommunityItem
                  index={index}
                  isShowTooltip
                  key={community.id}
                  title={community.name}
                  image={community.logo}
                  id={community.id}
                  isDisabled={
                    community.users &&
                    community.users[0]?.communityPermission?.role ===
                      CommunityRoles.BLOCKED
                  }
                  invitesCount={
                    communities.find((c) => c.id === community.id)?.requests
                      ?.length || 0
                  }
                  userRole={
                    community.users &&
                    community.users[0]?.communityPermission?.role
                  }
                />
              ))}
          </StyledMainMenuCommunities>
        </PerfectScrollbar>

        <PlusButton
          contained
          tooltipStyle={tooltipProps.tooltipStyle}
          text={t('common.toolTipText.communityPlusIcon')}
          position="right-center"
          isVisible
          onClick={onAddCommunity}
        />
        <StyledSeparator />
        <Tooltip
          {...tooltipProps}
          isVisible
          text={t('common.toolTipText.logoGumb')}
          position="right-center"
        >
          <img src={Logo} alt="" />
        </Tooltip>
      </StyledMainMenuTopBlock>
      <StyledBottomMenu>
        <Tooltip
          {...tooltipProps}
          isVisible
          text={t('common.toolTipText.faq')}
          position="right-center"
        >
          <StyledFaqButtonBlock>
            <FaqButton />
          </StyledFaqButtonBlock>
        </Tooltip>
      </StyledBottomMenu>
    </StyledMainMenuBlock>
  );
};

MainMenu.defaultProps = {
  communities: [],
  userProfile: {},
  notifications: [],
};

export default MainMenu;
