// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { handleActions } from 'redux-actions';
import { assoc, compose, reject, get } from 'lodash/fp';

import {
  doGetGroupUsers,
  doGetCurrentGroup,
  doChangeGroupUserRole,
  doGetTotal,
  doDeleteGroupUser,
  doDeleteUserGroups,
  doAddUserGroups,
  doClearCurrentGroup,
} from 'store/actionCreators/currentGroup';
import {
  doAddMembersToGroup,
  doRequestStatistic,
} from 'store/actionCreators/groups';
import { doChangeUserProfile } from 'store/actionCreators/currentCommunity';
import { doRearrangeGroupUsers } from 'store/actionCreators/currentGroup/users';
import { CurrentGroup } from 'types';

type CurrentGroupStateLoaders = {
  currentGroup: boolean;
  getGroupUsers: boolean;
  changeRole: boolean;
  deleteGroupUser: boolean;
  addMembersToGroup: boolean;
  doAddMembersToGroup: boolean;
  getTotal: boolean;
  getStatistic: boolean;
};

export interface CurrentGroupState {
  data: CurrentGroup;
  errors: any;
  loaders: CurrentGroupStateLoaders;
}

const initialState: CurrentGroupState = {
  data: {
    id: null,
    name: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    communityId: null,
    order: null,
    users: { count: 0, data: [] },
    statistic: null,
  },
  loaders: {
    currentGroup: false,
    getGroupUsers: false,
    changeRole: false,
    deleteGroupUser: false,
    addMembersToGroup: false,
    getTotal: false,
    getStatistic: false,
    doAddMembersToGroup: false,
  },
  errors: {},
};

export default handleActions<CurrentGroupState, any>(
  {
    [doGetCurrentGroup.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'currentGroup'], true),
        assoc(['data'], null),
      )(state),
    [doGetCurrentGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'currentGroup'], false),
        assoc(['data'], payload),
      )(state),
    [doGetCurrentGroup.FAILURE]: (state) =>
      compose(
        assoc(['loaders', 'currentGroup'], false),
        assoc(['errors', 'currentGroup'], true),
        assoc(['data'], null),
      )(state),
    [doClearCurrentGroup.TRIGGER]: (state) =>
      compose(assoc(['data'], null))(state),
    [doGetGroupUsers.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'getGroupUsers'], true),
        assoc(['data', 'users'], []),
      )(state),
    [doGetGroupUsers.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getGroupUsers'], false),
        assoc(['data', 'users'], payload),
      )(state),
    [doGetGroupUsers.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getGroupUsers'], false),
        assoc(['errors', 'getGroupUsers'], payload.error),
      )(state),
    [doChangeGroupUserRole.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'changeRole'], true))(state),
    [doChangeGroupUserRole.SUCCESS]: (state, { payload }) => {
      const user = state.data.users.data.find(
        (u) => u.id === payload.user.userId,
      );

      return compose(
        assoc(['loaders', 'changeRole'], false),
        assoc(
          ['data', 'users', 'data'],
          [
            ...reject(
              { id: payload.user.userId },
              get(['data', 'users', 'data'], state),
            ),
            {
              ...user,
              groupPermission: {
                ...user.groupPermission,
                role: payload.user.role,
              },
            },
          ],
        ),
      )(state);
    },
    [doChangeGroupUserRole.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'changeRole'], false),
        assoc(['errors', 'changeRole'], payload.error),
      )(state),
    [doDeleteGroupUser.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteGroupUser'], true))(state),
    [doDeleteGroupUser.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroupUser'], false),
        assoc(['errors', 'deleteGroupUser'], payload),
      )(state),
    [doDeleteGroupUser.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroupUser'], false),
        assoc(
          ['data', 'users', 'data'],
          state.data.users.data.filter((u) => !payload.includes(u.id)),
        ),
      )(state),
    [doDeleteUserGroups.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteGroupUser'], true))(state),
    [doDeleteUserGroups.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroupUser'], false),
        assoc(['errors', 'deleteGroupUser'], payload),
      )(state),
    [doDeleteUserGroups.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroupUser'], false),
        assoc(
          ['data', 'users', 'data'],
          reject({ id: payload }, state.data.users.data),
        ),
      )(state),
    [doChangeUserProfile.SUCCESS]: (state, { payload }) => {
      if (state.data?.users?.data) {
        const ind = state.data.users.data.findIndex(
          (user) => user.id === payload.userId,
        );

        return compose(
          assoc(['data', 'users', 'data', ind, 'profile'], payload),
        )(state);
      }

      return state;
    },
    [doAddUserGroups.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteGroupUser'], true))(state),
    [doAddUserGroups.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroupUser'], false),
        assoc(['errors', 'deleteGroupUser'], payload),
      )(state),
    [doAddUserGroups.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteGroupUser'], false),
        assoc(
          ['data', 'users', 'data'],
          reject({ id: payload }, state.data.users.data),
        ),
      )(state),
    [doAddMembersToGroup.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'doAddMembersToGroup'], true))(state),
    [doAddMembersToGroup.SUCCESS]: (state) =>
      compose(assoc(['loaders', 'doAddMembersToGroup'], false))(state),
    [doAddMembersToGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'doAddMembersToGroup'], false),
        assoc(['errors', 'doAddMembersToGroup'], payload.error),
      )(state),
    [doGetTotal.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getTotal'], true))(state),
    [doGetTotal.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getTotal'], false),
        assoc(['data', 'total'], payload),
      )(state),
    [doGetTotal.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getTotal'], false),
        assoc(['errors', 'getTotal'], payload.error),
      )(state),
    [doRequestStatistic.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getStatistic'], true))(state),
    [doRequestStatistic.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getStatistic'], false),
        assoc(['data', 'statistic'], payload),
      )(state),
    [doRequestStatistic.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getStatistic'], false),
        assoc(['errors', 'getStatistic'], payload.error),
      )(state),
    [doRearrangeGroupUsers.SUCCESS]: (
      state,
      { payload: { newUsersList, role } },
    ) =>
      compose(
        assoc(
          ['data', 'users', 'data'],
          role
            ? state.data.users?.data
                ?.filter((u) => u.groupPermission.role !== role)
                .concat(newUsersList)
            : newUsersList,
        ),
      )(state),
  },
  initialState,
);
