import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getProfileName } from 'utils';
import { STATISTICS_TYPES } from 'configs';
import {
  CommunityOverallScheduler,
  EventResponse,
  EventType,
  OverallStatistics,
  OverallStatisticsEventType,
  OverallStatisticsReply,
} from 'types';

import { StyledAvatarNoPhoto } from './styled';

const getUserValuesReplies = (
  memberId: string,
  responses: (EventResponse | { color: string; id: string; title: string })[],
  replies: OverallStatisticsReply[],
) =>
  responses?.map(({ color, id }) => {
    let finalColor = '',
      text;

    const userReplies = replies.filter(({ userId }) => userId === memberId);
    if (id === 'penalty' || id === 'attended') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const x = userReplies.reduce((acc, cur) => acc + cur[id] * 1, 0);
      finalColor = x > 0 ? color : '';
      text = x > 0 ? x : undefined;
    } else {
      const thisResp = userReplies.find(
        ({ eventResponseId }) => eventResponseId === id,
      );
      finalColor = thisResp ? color : '';
      text = thisResp?.count;
    }

    return {
      responsesId: id,
      color: finalColor,
      text,
    };
  });

const getUserValuesEventTypes = (
  memberId: string,
  eventTypes: EventType[],
  data: OverallStatisticsEventType[],
  responses: (EventResponse | { color: string; id: string; title: string })[],
  replies: OverallStatisticsReply[],
) => {
  const userReplies = replies.filter(({ userId }) => userId === memberId);

  return eventTypes?.map(({ color, id }) => {
    const userValues = data.filter(({ userId }) => userId === memberId);
    const response = userValues.find(({ eventTypeId }) => eventTypeId === id);
    const repliesData = responses
      .filter((r) => !(r.id === 'penalty' || r.id === 'attended'))
      .map((r) => ({
        label: r.title,
        value: userReplies.find(
          ({ eventResponseId }) => eventResponseId === r.id,
        )?.count,
        color: r.color,
      }));

    return {
      color: response ? color : 'transparent',
      text: response?.count,
      repliesData,
    };
  });
};

export const useGridData = (
  statistic: OverallStatistics,
  responses: (EventResponse | { color: string; id: string; title: string })[],
  eventTypes: EventType[],
  overallScheduler: CommunityOverallScheduler,
  type: STATISTICS_TYPES,
) => {
  const { t } = useTranslation();

  const getGroupsData = (overallScheduler, type) => {
    return [
      {
        type: 'group',
        id: 'overall',
        label: t('menu.groupScheduler'),
      },
      ...overallScheduler.users.map((member) => ({
        type: 'statistics',
        id: member.profile.userId,
        group: 'overall',
        label: getProfileName(member.profile),
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getUserValuesReplies(
                member.profile.userId,
                responses,
                statistic.replies,
              )
            : getUserValuesEventTypes(
                member.profile.userId,
                eventTypes,
                statistic.repliesToEventTypes,
                responses,
                statistic.replies,
              ),
        icon: member.profile.smallLogo || member.profile.logo || (
          <StyledAvatarNoPhoto>
            {getProfileName(member.profile)[0].toUpperCase()}
          </StyledAvatarNoPhoto>
        ),
      })),
    ];
  };

  const [gridData, setGridData] = useState([
    ...getGroupsData(overallScheduler, type),
  ]);

  useEffect(() => {
    setGridData([...getGroupsData(overallScheduler, type)]);
  }, [overallScheduler, type]);

  return gridData;
};

const getTotalValuesReplies = (
  responses: (EventResponse | { color: string; id: string; title: string })[],
  statistic: OverallStatisticsReply[],
  group: { name: string; users: string[] },
) => {
  return responses?.map(({ id }) => {
    if (id === 'penalty' || id === 'attended') {
      const groupReplies = statistic
        .filter(({ userId }) => {
          return group.users.find((id) => id === userId);
        })
        .map((i) => parseInt(i[id], 10))
        .reduce((a, b) => a + b, 0);

      return groupReplies;
    } else {
      const replies = statistic.filter(
        ({ eventResponseId }) => id === eventResponseId,
      );
      const groupReplies = replies
        .filter(({ userId }) => group.users.find((id) => id === userId))
        .map((i) => parseInt(i.count, 10))
        .reduce((a, b) => a + b, 0);

      return groupReplies;
    }
  });
};

const getTotalValuesEventTypes = (
  eventTypes: EventType[],
  statistic: OverallStatisticsEventType[],
  group: { name: string; users: string[] },
) => {
  return eventTypes?.map(({ id }) => {
    const replies = statistic.filter(({ eventTypeId }) => id === eventTypeId);
    const groupReplies = replies
      .filter(({ userId }) => group.users.find((id) => id === userId))
      .map((i) => parseInt(i.count, 10))
      .reduce((a, b) => a + b, 0);

    return groupReplies;
  });
};

export const useFooterData = (
  totalLabel: string,
  responses: (EventResponse | { color: string; id: string; title: string })[],
  eventTypes: EventType[],
  statistic: OverallStatistics,
  overallScheduler: CommunityOverallScheduler,
  type: STATISTICS_TYPES,
) => {
  const { t } = useTranslation();

  return {
    label: totalLabel,
    data: [
      {
        label: t('menu.groupScheduler'),
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getTotalValuesReplies(responses, statistic.replies, {
                name: t('menu.groupScheduler'),
                users:
                  Array.isArray(overallScheduler.users) &&
                  overallScheduler.users.length > 0
                    ? overallScheduler.users.map((user) => user.id)
                    : [],
              })
            : getTotalValuesEventTypes(
                eventTypes,
                statistic.repliesToEventTypes,
                {
                  name: t('menu.groupScheduler'),
                  users:
                    Array.isArray(overallScheduler.users) &&
                    overallScheduler.users.length > 0
                      ? overallScheduler.users.map((user) => user.id)
                      : [],
                },
              ),
      },
    ],
  };
};
