import React, { FC, MutableRefObject, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import {
  ContentWrapper,
  Loader,
  ZoomInput,
  ScrollButton,
  SearchInput,
} from 'components';
import EventCard from './EventCard';
import {
  getCommunitiesLoading,
  getCommunities,
} from 'store/selectors/communities';
import { getMyEventsLoaders } from 'store/selectors/events';
import { MyEventsFilterValues, ScrollButtonsStatus } from './index';
import { EditEventType } from 'constants/sidebars';
import { ReplyFormData } from './form';
import { breakpoints } from 'theme';
import {
  MyEventStateData,
  MyEventsStateLoaders,
  FullUser,
  MyAppointmentsEvent,
  EventReply,
  EventResponse,
} from 'types';

import {
  Layout,
  StyledFloatingLoader,
  Heading,
  TasksList,
  NoEventsPlaceholder,
  NoEventIcon,
  StyledFilterBar,
} from './styled';
import MyAppointmentFilters from './MyAppointmentFilters';
import moment from 'moment';

interface UserProfileSettingsPageProps {
  data: MyEventStateData;
  loaders: MyEventsStateLoaders;
  listRef: MutableRefObject<HTMLDivElement>;
  currentUser: FullUser;
  zoom: number;
  filterValues: MyEventsFilterValues;
  search: string;
  scrollButtonsStatus: ScrollButtonsStatus;
  handleScroll: (position: 'start' | 'end') => void;
  setFilterValues: (value: MyEventsFilterValues) => void;
  onZoomChange: (value: number) => void;
  setSearch: (value: string) => void;
  onReset: () => void;
  onSubmit: (
    type: EditEventType,
    data: ReplyFormData,
    event: MyAppointmentsEvent,
    reply: {
      reply: EventReply & { tasks: string[] };
      communityResponse: EventResponse;
    },
  ) => void;
  onEventHeaderClick: (event: MyAppointmentsEvent) => void;
  onEventClick: (event: MyAppointmentsEvent) => void;
  shouldRedirect?: boolean;
}

const UserProfileSettingsPage: FC<UserProfileSettingsPageProps> = ({
  data,
  loaders,
  listRef,
  currentUser,
  zoom,
  filterValues,
  search,
  scrollButtonsStatus,
  handleScroll,
  setFilterValues,
  onZoomChange,
  setSearch,
  onReset,
  onSubmit,
  onEventHeaderClick,
  onEventClick,
  shouldRedirect,
}) => {
  const { t } = useTranslation();
  const communities = useSelector(getCommunities);
  const areCommunitiesLoading = useSelector(getCommunitiesLoading);
  const isDownMd = useMedia(breakpoints.downMd);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const loadersState = useSelector(getMyEventsLoaders);

  const [shouldResetLocalFilters, setShouldResetLocalFilters] =
    React.useState<boolean>();

  const eventTypesOptions: { [key: string]: string }[] = data.eventTypes && [
    { label: t('common.all'), value: 'all' },
    ...data.eventTypes.map((type) => {
      const community = communities.find((c) => c.id === type.communityId);

      return {
        label: type.title,
        value: type.id,
        icon: community?.logo || community?.name[0],
      };
    }),
  ];

  const eventsListComponent: JSX.Element | JSX.Element[] = useMemo(() => {
    if (!data.events || areCommunitiesLoading || loadersState.mainLoader) {
      return <Loader />;
    } else if (data.events.length > 0) {
      return data.events?.map((e) => (
        <EventCard
          key={e.id}
          event={e}
          community={communities.find(
            (c) =>
              c.id === e.communityId ||
              c?.groups?.some((g) => g.id === e.groupId),
          )}
          currentUser={currentUser}
          zoom={zoom}
          tasks={data.tasks}
          isLoading={loaders.event === e.id}
          eventResponses={data.eventResponses}
          eventTypes={data.eventTypes}
          onSubmit={onSubmit}
          onEventHeaderClick={onEventHeaderClick}
          onEventClick={onEventClick}
        />
      ));
    }

    return (
      <NoEventsPlaceholder>
        <NoEventIcon />
        {t('event.noEvents')}
      </NoEventsPlaceholder>
    );
  }, [data, areCommunitiesLoading, communities, currentUser, zoom, loaders]);

  useEffect(() => {
    if (data?.events?.length === 0 && filterValues.awaiting && shouldRedirect) {
      setFilterValues({
        ...filterValues,
        awaiting: false,
        from: moment().valueOf(),
        eventType: { label: 'All', value: 'all' },
      });
      setShouldResetLocalFilters((prevValue) => !prevValue);
    }
  }, [data]);

  return (
    <>
      {data.events &&
        (loaders.events || loaders.event) &&
        !loadersState.mainLoader && (
          <StyledFloatingLoader>
            <Loader size="60px" type={null} />
          </StyledFloatingLoader>
        )}
      <ContentWrapper>
        <Layout>
          <Layout.Header>
            {!isDownSmPlus && <Heading>{t('profile.myAppointments')}</Heading>}
            <StyledFilterBar>
              <MyAppointmentFilters
                filters={filterValues}
                eventTypesOptions={eventTypesOptions}
                noEndTime={true}
                setFilters={setFilterValues}
                resetFilters={onReset}
                applyFilters={setFilterValues}
                shouldResetLocalFilters={shouldResetLocalFilters}
              />
              <SearchInput
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                fillWidth
                noEndTime={true}
                applyFilters={() => {}}
              />
              <ZoomInput
                value={zoom}
                color="black"
                onChange={(zoom) => onZoomChange(zoom)}
              />
            </StyledFilterBar>
          </Layout.Header>
          <Layout.Data>
            {!isDownMd && scrollButtonsStatus.right && (
              <ScrollButton
                position="right"
                isMenuOpen={true}
                disabled={scrollButtonsStatus.disabled}
                onClick={() => handleScroll('end')}
              />
            )}
            {!isDownMd && scrollButtonsStatus.left && (
              <ScrollButton
                position="left"
                isMenuOpen={true}
                disabled={scrollButtonsStatus.disabled}
                onClick={() => handleScroll('start')}
              />
            )}
            <TasksList ref={listRef}>{eventsListComponent}</TasksList>
          </Layout.Data>
        </Layout>
      </ContentWrapper>
    </>
  );
};

export default UserProfileSettingsPage;
