import React, { FC } from 'react';

import {
  FacebookIcon,
  YoutubeIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'static';
import { ProfileSocialLinkType } from 'types';

interface SocialNetworkIconProps {
  socialNetwork: ProfileSocialLinkType;
}

const SocialNetworkIcon: FC<SocialNetworkIconProps> = ({ socialNetwork }) => {
  switch (socialNetwork) {
    case ProfileSocialLinkType.FACEBOOK:
      return <FacebookIcon />;
    case ProfileSocialLinkType.YOUTUBE:
      return <YoutubeIcon />;
    case ProfileSocialLinkType.TWITTER:
      return <TwitterIcon />;
    case ProfileSocialLinkType.INSTAGRAM:
      return <InstagramIcon />;
    case ProfileSocialLinkType.LINKEDIN:
      return <LinkedinIcon />;
    default:
      return null;
  }
};

export default SocialNetworkIcon;
