import React, { memo } from 'react';

import { StyledFooterCell, StyledSelectionHighlight } from './styled';
import { ModalTypes } from 'types';
import { modalSelector } from 'store/selectors/modal';
import { useSelector } from 'react-redux';

interface FooterCellProps {
  style: { [key: string]: string | number };
  zoom: number;
  center: boolean;
  eventResponseId: string;
  eventId: string;
  date: Date;
  statisticsTab?: boolean;
  children: number | string | JSX.Element | JSX.Element[];
  divide?: 'left' | 'right';
}

const FooterCell = memo<FooterCellProps>(
  ({
    style,
    center,
    children,
    zoom,
    eventResponseId,
    eventId,
    date,
    statisticsTab,
    divide,
  }) => {
    const {
      data,
      isOpen: communityModalOpen,
      type,
    } = useSelector(modalSelector);

    return (
      <div
        style={style}
        data-type="footer"
        data-id={eventId}
        data-clickable={center && !statisticsTab ? 'true' : 'false'}
        data-date={date}
        data-response-id={eventResponseId}
      >
        {communityModalOpen &&
          (type === ModalTypes.GIVE_REPLY ||
            type === ModalTypes.EVENT_DETAILS) &&
          data?.['eventId']?.toString() === eventId?.toString() && (
            <StyledSelectionHighlight zoom={zoom} />
          )}
        <StyledFooterCell
          clickable={center && !statisticsTab}
          centered={center}
          zoom={zoom}
          divide={divide}
          statisticsTab={statisticsTab}
        >
          {children}
        </StyledFooterCell>
      </div>
    );
  },
);

FooterCell.displayName = 'FooterCell';

export default FooterCell;
