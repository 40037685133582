import { Dispatch, SetStateAction, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { checkIfUserExists } from 'services/communities';
import { useChangeEmailForm } from './form';
import { sendChangeEmailMessage } from 'services/user';
import { Profile } from 'types';
import { NOT_FOUND, VALIDATION_ERROR } from 'constants/errors';
import { useInfoPopup } from 'hooks';

const checkEmail = async (email) => {
  return await checkIfUserExists(email)
    .then(() => {
      return true;
    })
    .catch((err) => {
      return err.response?.data?.error !== NOT_FOUND;
    });
};

export const useChangeEmail = (profile: Profile) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [saving, setSaving] = useState<boolean>(false);
  const { handleSubmit, setValue, ...form } = useChangeEmailForm();
  const { showInfoPopup } = useInfoPopup();

  const onSubmit = handleSubmit(async ({ email }) => {
    setSaving(true);

    const formattedEmail = email.toLowerCase();
    const user = await Auth.currentAuthenticatedUser();

    if ((user.attributes?.email || profile.email) === formattedEmail) {
      setSaving(false);

      return addToast(t('auth.yourEmail', { value: formattedEmail }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    const isExistEmail = await checkEmail(formattedEmail);

    if (isExistEmail) {
      setSaving(false);

      return addToast(t('auth.existEmail', { value: formattedEmail }), {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    await sendChangeEmailMessage(user.username, formattedEmail)
      .then(() => {
        showInfoPopup({
          title: t('email.changeEmail.title'),
          message: t('email.changeEmail.message'),
          size: 'lg',
          loop: true,
          type: 'info',
        });
        addToast(t('auth.codeSended'), {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(({ message, response }) => {
        if (response?.data?.error === VALIDATION_ERROR)
          return addToast(t('errors.invalidEmail'), {
            appearence: 'error',
            autoDismiss: true,
          });
        addToast(t('errors.errorToast', { message }), {
          appearance: 'error',
          autoDismiss: true,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  });

  return {
    onSubmit,
    setValue,
    saving,
    ...form,
  };
};
