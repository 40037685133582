import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

Wrapper.Name = styled.div``;

Wrapper.Logo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.porcelain};
  margin-right: 12px;
  box-sizing: content-box;
`;

Wrapper.Logo.Image = styled.img`
  width: ${({ imageWidth }) => (imageWidth ? imageWidth : '100%')};
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

Wrapper.Logo.Placeholder = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  min-height: ${({ width }) => width}px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: ${({ zoom }) => Math.floor(13 * zoom)}px;
    line-height: ${({ zoom }) => Math.floor(15 * zoom)}px;
    color: ${({ theme }) => theme.color.baliHai};
    user-select: none;
  }
`;

Wrapper.Name = styled.div``;
