import React, { FC } from 'react';

import { GreenPlusIcon } from 'static';

import { StyledButton } from './styled';
import Tooltip from 'components/Tooltip';
import { Placement } from 'react-laag';

interface PlusButtonProps {
  width?: string;
  contained?: boolean;
  disabled?: boolean;
  onClick: () => void;
  position?: Placement;
  tooltipStyle?: { [key: string]: string | number };
  text?: JSX.Element | JSX.Element[] | string;
  isVisible?: boolean;
  adjustToolTipPosition?: { [key: string]: string | number };
}

const PlusButton: FC<PlusButtonProps> = ({
  width = '40px',
  contained,
  disabled,
  onClick,
  position,
  text,
  isVisible,
  tooltipStyle,
  adjustToolTipPosition,
}) => (
  <Tooltip
    position={position}
    tooltipStyle={{ ...tooltipStyle }}
    text={text}
    isVisible={isVisible}
    adjustToolTipPosition={adjustToolTipPosition}
  >
    <StyledButton
      type="button"
      onClick={onClick}
      contained={contained}
      disabled={disabled}
      width={width}
    >
      <GreenPlusIcon width={width} />
    </StyledButton>
  </Tooltip>
);

export default PlusButton;
