import { axiosInstance as axios } from './index';
import { FORMFIELDS } from 'pages/PushNotification/form';
import { PushNotificationModuleFormValues } from 'pages/PushNotification/form';
import {
  PushTemplateFormat,
  UserMessageTemplate,
} from 'store/actionCreators/currentCommunity';

const sendPushNotification = async ({
  communityId,
  data,
  userId,
  withImage,
  eventId,
}: {
  communityId: string;
  data: PushNotificationModuleFormValues;
  userId: string;
  withImage: boolean;
  eventId?: string;
}) => {
  const formData = {
    userId,
    communityId,
    withImage,
    eventId,
    [FORMFIELDS.RECIPIENTS]: data[FORMFIELDS.RECIPIENTS],
    [FORMFIELDS.TITLE]: data[FORMFIELDS.TITLE],
    [FORMFIELDS.MESSAGE]: data[FORMFIELDS.MESSAGE],
    [FORMFIELDS.SENDCOPY]: data[FORMFIELDS.SENDCOPY],
    [FORMFIELDS.REDIRECT_LINK]: data[FORMFIELDS.REDIRECT_LINK] ?? '',
  };

  const response = await axios.post('/pushNotification', formData);
  return response.data;
};

const getTemplates = async ({ communityId, userId }) => {
  const response = await axios.get(`/getTemplate/${userId}/${communityId}`);
  return response.data;
};

const saveTemplate = async ({
  userId,
  message,
  title,
  communityId,
}: {
  userId: string;
  title: string;
  message: string;
  communityId: string;
}) => {
  const data = { userId, message, title, communityId };
  const response = await axios.post('/postTemplate', data);
  return response.data;
};

const saveDefaultTemplates = async ({
  defaultTemp,
}: {
  defaultTemp: UserMessageTemplate;
}) => {
  const response = await axios.post('/postDefaultTemplate', defaultTemp);
  return response.data;
};

const deleteTemplates = async ({
  id,
  userId,
  communityId,
}: {
  id: string;
  userId: string;
  communityId: string;
}) => {
  const data = { id, userId, communityId };

  const response = await axios.delete(`/deleteTemplate/${id}`);

  return response.data;
};

export {
  sendPushNotification,
  saveTemplate,
  deleteTemplates,
  getTemplates,
  saveDefaultTemplates,
};
