import axios from 'axios';
import { get } from 'lodash/fp';

export const axiosInstance = axios.create({
  baseURL: `${get(['REACT_APP_BACKEND_HOST'], process.env)}/${get(
    ['REACT_APP_API_PREFIX'],
    process.env,
  )}/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const axiosInstanceLocal = axios.create({
  baseURL: `http://localhost:3000/${get(
    ['REACT_APP_API_PREFIX'],
    process.env,
  )}/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const axiosInstanceArrayBuffer = axios.create({
  baseURL: `${get(['REACT_APP_BACKEND_HOST'], process.env)}/${get(
    ['REACT_APP_API_PREFIX'],
    process.env,
  )}/`,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

export const axiosInstanceArrayBufferLocal = axios.create({
  baseURL: `http://localhost:3000/${get(
    ['REACT_APP_API_PREFIX'],
    process.env,
  )}/`,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

export const axiosFormInstance = axios.create({
  baseURL: `${get(['REACT_APP_BACKEND_HOST'], process.env)}/${get(
    ['REACT_APP_API_PREFIX'],
    process.env,
  )}/`,
  headers: {
    Accept: 'multipart/form-data',
    'Content-Type': 'multipart/form-data',
  },
});

export const axiosFormInstanceLocal = axios.create({
  baseURL: `http://localhost:3000/${get(
    ['REACT_APP_API_PREFIX'],
    process.env,
  )}/`,
  headers: {
    Accept: 'multipart/form-data',
    'Content-Type': 'multipart/form-data',
  },
});

export const setHeaders = (accessToken) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosInstanceLocal.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosFormInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosFormInstanceLocal.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosInstanceArrayBuffer.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axiosInstanceArrayBufferLocal.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const clearHeaders = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
  delete axiosInstance.defaults.headers.common['id-token'];

  delete axiosInstanceLocal.defaults.headers.common.Authorization;
  delete axiosInstanceLocal.defaults.headers.common['id-token'];

  delete axiosFormInstance.defaults.headers.common.Authorization;
  delete axiosFormInstance.defaults.headers.common['id-token'];

  delete axiosFormInstanceLocal.defaults.headers.common.Authorization;
  delete axiosFormInstanceLocal.defaults.headers.common['id-token'];

  delete axiosInstanceArrayBuffer.defaults.headers.common.Authorization;
  delete axiosInstanceArrayBuffer.defaults.headers.common['id-token'];

  delete axiosInstanceArrayBufferLocal.defaults.headers.common.Authorization;
  delete axiosInstanceArrayBufferLocal.defaults.headers.common['id-token'];
};
