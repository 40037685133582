import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import PopupWrapper from '../Wrapper';
import { Button } from '../../index';
import errorCross from 'static/animations/error-cross.json';
import warningSignMotion from 'static/animations/warning-motion.json';

import {
  StyledAdblockContainer,
  StyledMessage,
  StyledButtonText,
  CloseIconContainer,
  StyledHeaderContainer,
  HeaderTitle,
  HeaderIcon,
  StyledButton,
  StyledButtonContainer,
  StyledMessageContainer,
  ContentWrapper,
} from './styled';
import { CloseIconBigger } from 'static';

interface AdBlockerPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdBlockerPopup: React.FC<AdBlockerPopupProps> = ({
  isOpen = false,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <PopupWrapper isOpen={isOpen}>
      <StyledAdblockContainer>
        <CloseIconContainer onClick={onClose}>
          <CloseIconBigger />
        </CloseIconContainer>
        <ContentWrapper>
          <StyledHeaderContainer>
            <HeaderIcon>
              <Lottie
                options={{
                  animationData: warningSignMotion,
                  loop: true,
                  autoPlay: true,
                }}
                width="150px"
                height="150px"
              />
            </HeaderIcon>
            <HeaderTitle>{t('subscriptions.adBlocker.header')}</HeaderTitle>
            <StyledMessageContainer>
              <StyledMessage>{t('subscriptions.adBlocker.body')}</StyledMessage>
            </StyledMessageContainer>
          </StyledHeaderContainer>
          <StyledButtonContainer>
            <StyledButton onClick={onClose}>
              <StyledButtonText>{t('common.ok')}</StyledButtonText>
            </StyledButton>
          </StyledButtonContainer>
        </ContentWrapper>
      </StyledAdblockContainer>
    </PopupWrapper>
  );
};

export default AdBlockerPopup;
