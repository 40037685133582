import styled from 'styled-components';

export const StyledSearchWrapper = styled.div`
  padding: 13px 16px 14px 15px;
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100svh - 179px);
`;
