import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  Button,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
  SidebarInputsWrapper,
  Loader,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useCreateGroup } from './hooks';
import { FIELDS } from './form';
import {
  getCreateGroupLoading,
  getCurrentCommunity,
} from 'store/selectors/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';

import { TextInputBlock } from './styled';

const CreateGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getCreateGroupLoading);

  const { onSubmit, register, errors } = useCreateGroup(community?.id);

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('group.createGroup')}
          onCloseClick={handleClose}
        />
        <SidebarContent>
          <SidebarInputsWrapper>
            <TextInputBlock>
              <TextInput
                register={register}
                width="100%"
                name={FIELDS.GROUP_NAME}
                label={t('group.groupName')}
                error={errors[FIELDS.GROUP_NAME]}
              />
            </TextInputBlock>
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter>
          <Button
            type="submit"
            variant={isLoading ? 'secondary' : null}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('common.create')
            )}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default CreateGroup;
