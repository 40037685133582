import React from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { compose } from 'lodash/fp';
import { arrayOf, shape } from 'prop-types';

import { COMMUNITY_ROLES } from 'configs';
import {
  getCurrentUserInCommunity,
  getGroups,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';

import GroupSettingsPage from './GroupSettingsPage';

const GroupSettingsContainer = ({
  groups,
  currentUser,
  isMenuOpen,
  isLoading,
}) => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const userRole = currentUser?.communityPermission.role;

  const renderSettings = () => {
    if (groups) {
      const group = groups.find((group) => group.id === groupId);

      if (
        userRole === COMMUNITY_ROLES.ADMIN ||
        userRole === COMMUNITY_ROLES.OWNER
      ) {
        return (
          <GroupSettingsPage
            group={group}
            isMenuOpen={isMenuOpen}
            isLoading={isLoading}
          />
        );
      }
      navigate('/404');
    }
    return null;
  };

  return renderSettings();
};

GroupSettingsContainer.propTypes = {
  communities: arrayOf(shape({})),
};

GroupSettingsContainer.defaultProps = {
  communities: [],
};

const mapStateToProps = (state) => ({
  groups: getGroups(state),
  currentUser: getCurrentUserInCommunity(state),
  isMenuOpen: getIsMenuOpen(state),
  isLoading: state.currentCommunity.loaders?.updateGroup,
});

export default compose(connect(mapStateToProps))(GroupSettingsContainer);
