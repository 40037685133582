import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAllSubscriptions,
  clearSubscriptionPlanId,
} from 'store/actionCreators/subscriptions';
import { Loader } from 'components';
import SubscriptionsInnerComponent from './SubscriptionInner';
import { selectAllSubscriptions } from 'store/selectors/subscriptions';

const SubscriptionsInnerPageContainer = () => {
  const { subscriptionId } = useParams();
  const dispatch = useDispatch();
  const subscriptions = useSelector(selectAllSubscriptions);

  useEffect(() => {
    dispatch(getAllSubscriptions());

    return () => {
      dispatch(clearSubscriptionPlanId());
    };
  }, []);

  return subscriptions?.find(({ id }) => id === subscriptionId) ? (
    <SubscriptionsInnerComponent />
  ) : (
    <Loader />
  );
};

export default SubscriptionsInnerPageContainer;
