import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { useLocation } from 'react-router-dom';

import { logInGoogle } from 'utils/auth';
import { GoogleLogo } from 'static';

import { Button } from './styled';

interface GoogleAuthButtonProps {
  disableContinueAsText?: boolean;
  preventDefaultAction?: boolean;
  style?: { [key: string]: string };
}

const GoogleAuthButton: FC<GoogleAuthButtonProps> = ({
  disableContinueAsText,
  preventDefaultAction,
  style,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [prevAuth] = useLocalStorage<{ [key: string]: string }>(
    'socialSignIns',
    {},
  );
  const buttonText = useMemo(() => {
    let text = `${t('auth.signInWith')} Google`;

    if (
      !disableContinueAsText &&
      location.pathname !== '/signup' &&
      prevAuth?.google
    ) {
      text = `${t('auth.continueAs')} ${prevAuth.google}`;
    }

    return text;
  }, [prevAuth, location, t, disableContinueAsText]);

  return (
    <Button
      style={style}
      type="button"
      onClick={preventDefaultAction ? null : logInGoogle}
    >
      <GoogleLogo />
      {buttonText}
    </Button>
  );
};

export default GoogleAuthButton;
