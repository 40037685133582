import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const Signature = styled.span`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 10px;
  line-height: 20px;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.color.brightTurquoise};
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;

  &:visited {
    color: ${(props) => props.theme.brightTurquoise};
  }
`;
