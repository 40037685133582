import { axiosInstance as axios } from './index';

export const sendRequest = async (communityId: string) => {
  const response = await axios.post(`communities/${communityId}/invites`);

  return response.data;
};

export const requestRequests = async (communityId: string) => {
  const response = await axios.get(`communities/${communityId}/invites`);

  return response.data;
};

export const approveRequests = async (communityId: string, data: string[]) => {
  const response = await axios.post(
    `communities/${communityId}/invites/status`,
    { invites: data, status: 'approved' },
  );

  return response.data;
};

export const rejectRequests = async (communityId: string, data: string[]) => {
  const response = await axios.post(
    `communities/${communityId}/invites/status`,
    { invites: data, status: 'rejected' },
  );

  return response.data;
};
