export enum COMMUNITY_ROLES {
  ADMIN = 'admin',
  MEMBER = 'member',
  BLOCKED = 'blocked',
  OWNER = 'owner',
}

export const COMMUNITY_ROLES_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'blocked', label: 'Blocked' },
];

export const GROUP_ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
  GROUP_LEADER: 'group_lead',
};

export const GROUP_ROLES_OPTIONS = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'group_lead', label: 'Group Leader' },
];
