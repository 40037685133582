export enum TimeIntervalUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type TimeIntervalOption = {
  value: TimeIntervalUnit;
  label: string;
};

export const intervalTimeUnits: TimeIntervalOption[] = [
  { value: TimeIntervalUnit.DAY, label: 'days' },
  { value: TimeIntervalUnit.WEEK, label: 'weeks' },
  { value: TimeIntervalUnit.MONTH, label: 'months' },
  { value: TimeIntervalUnit.YEAR, label: 'years' },
];
