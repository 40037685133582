import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { ModalState } from '../reducers/modal';

export const modalSelector = (state: IRootState): ModalState => state.modal;

export const getData = createSelector(
  modalSelector,
  (modal) => modal?.data || null,
);

export const getType = createSelector(
  modalSelector,
  (modal) => modal?.type || null,
);
