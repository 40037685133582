import styled from 'styled-components';

export const StyledArea = styled.textarea`
  width: ${({ width, zoom = 1 }) => width || Math.floor(340 * zoom) + 'px'};
  height: ${({ height }) => height || 'auto'};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: ${({ zoom = 1 }) => Math.floor(4 * zoom)}px;
  outline: none;
  padding: ${({ zoom = 1 }) =>
    `${Math.floor(8 * zoom)}px ${Math.floor(12 * zoom)}px`};
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: ${({ zoom = 1 }) => Math.floor(13 * zoom)}px;
  line-height: ${({ zoom = 1 }) => Math.floor(20 * zoom)}px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  resize: none;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}

  &:focus:enabled {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;
