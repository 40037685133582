import { CommunityRoles, CommunityUser, GroupRoles, GroupUser } from 'types';

export const isAdminOrOwner = (user: CommunityUser): boolean =>
  user.communityPermission.role === CommunityRoles.ADMIN ||
  user.communityPermission.role === CommunityRoles.OWNER;

export const checkPermissionToDrag = (
  currentUser: CommunityUser,
  currentUserInGroup: GroupUser,
  userId: string,
): boolean => {
  const communityRole = currentUser?.communityPermission?.role;
  const groupRole = currentUserInGroup?.groupPermission?.role;

  if (
    communityRole === CommunityRoles.OWNER ||
    communityRole === CommunityRoles.ADMIN
  ) {
    return true;
  }

  if (userId === currentUser.id) {
    return false;
  }

  return groupRole === GroupRoles.ADMIN || groupRole === GroupRoles.GROUP_LEAD;
};

export const hasPermissionToReply = (
  currentUser: CommunityUser,
  currentUserInGroup: GroupUser,
): boolean => {
  if (currentUser && currentUserInGroup) {
    return (
      currentUser.communityPermission?.role === CommunityRoles.OWNER ||
      currentUser.communityPermission?.role === CommunityRoles.ADMIN ||
      currentUserInGroup.groupPermission?.role === GroupRoles.ADMIN ||
      currentUserInGroup.groupPermission?.role === GroupRoles.GROUP_LEAD
    );
  } else if (currentUser && !currentUserInGroup) {
    return (
      currentUser.communityPermission?.role === CommunityRoles.OWNER ||
      currentUser.communityPermission?.role === CommunityRoles.ADMIN
    );
  } else if (!currentUser && currentUserInGroup) {
    return (
      currentUserInGroup.groupPermission?.role === GroupRoles.ADMIN ||
      currentUserInGroup.groupPermission?.role === GroupRoles.GROUP_LEAD
    );
  }
};

export const checkPermissionToEdit = (
  currentUser: CommunityUser,
  usersRole: CommunityRoles | GroupRoles,
  currentUserInGroup: GroupUser,
): boolean => {
  if (currentUserInGroup?.groupPermission?.role === GroupRoles.ADMIN) {
    return true;
  } else if (
    usersRole === CommunityRoles.ADMIN &&
    currentUser?.communityPermission?.role === CommunityRoles.MEMBER
  ) {
    return false;
  } else {
    return true;
  }
};

export const checkAuthorizedToMakeAdmin = (
  currentUser: CommunityUser,
  currentUserInGroup: GroupUser,
): boolean => {
  if (currentUser?.communityPermission?.role === CommunityRoles.BLOCKED) {
    return false;
  }

  if (
    currentUser?.communityPermission?.role === CommunityRoles.OWNER ||
    currentUser?.communityPermission?.role === CommunityRoles.ADMIN
  ) {
    return true;
  } else if (currentUserInGroup?.groupPermission?.role === GroupRoles.ADMIN) {
    return true;
  } else {
    return false;
  }
};
