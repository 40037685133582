import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTooltip = styled(motion.div)`
  z-index: 100;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 10px 15px;
  border-radius: 6px;
  width: 220px;
`;
