import React, { FC, useState } from 'react';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Divider, Loader } from 'components';
import { ShareIcon, PDFIcon } from 'static';
import Tooltip from '../Tooltip';

import {
  StyledTooltip,
  StyledOption,
  StyledThreeDots,
  StyledLoaderWrapper,
} from './styled';

interface EventShareOptionsTooltipProps {
  zoom?: number;
  onShareClick: (e: Event) => void;
  onExportPDFClick: () => void | null;
}

const EventShareOptionsTooltip: FC<EventShareOptionsTooltipProps> = ({
  zoom = 1,
  onShareClick,
  onExportPDFClick,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeTooltip = () => setIsVisible(false);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isVisible,
    placement: 'right-start',
    triggerOffset: 10,
    auto: true,
    onOutsideClick: closeTooltip,
  });

  const handleIconClick = (e) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const tooltipProps = {
    zoom,
    tooltipStyle: {
      fontSize: 12 * zoom,
      maxWidth: 200 * zoom,
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      lineBreak: 'normal',
    },
    opacity: '0.9',
    noArrow: true,
    text: t('event.shareEvent'),
  };

  const handlePDFExport = async (e) => {
    e.stopPropagation();
    setLoading(true);
    await onExportPDFClick();
    setLoading(false);
    setIsVisible(false);
  };

  return (
    <>
      <div {...triggerProps}>
        <StyledThreeDots zoom={zoom} onClick={handleIconClick}>
          ...
        </StyledThreeDots>
      </div>
      {renderLayer(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isVisible && (
            <StyledTooltip
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              {...layerProps}
              style={{
                ...layerProps.style,
              }}
              zoom={zoom}
            >
              <Arrow {...arrowProps} size={5} />
              <div>
                {loading && (
                  <StyledLoaderWrapper>
                    <Loader size="30px" type={null} />
                  </StyledLoaderWrapper>
                )}
                <Tooltip {...tooltipProps} position="top-center">
                  <StyledOption zoom={zoom} onClick={onShareClick}>
                    <ShareIcon />
                    <label>{t('event.shareEventOption')}</label>
                  </StyledOption>
                </Tooltip>
                {!!onExportPDFClick && (
                  <>
                    <Divider customMargin="5px 0px" />
                    <StyledOption zoom={zoom} onClick={handlePDFExport}>
                      <PDFIcon />
                      <label>{t('event.exportPDFOption')}</label>
                    </StyledOption>
                  </>
                )}
              </div>
            </StyledTooltip>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};

export default EventShareOptionsTooltip;
