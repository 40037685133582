import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { MainMenu, CommunityMenu } from 'components';

import { SidebarWrapper, StyledBurger } from './styled';

const CommunitySidebarMobile = () => {
  const [initialized, setInitialized] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const scrollHandler = () => {
    if (
      (window.location.hash === '#requests' ||
        window.location.hash === '#members') &&
      window.innerWidth <= 448
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  useEffect(() => {
    if (
      (window.location.hash === '#requests' ||
        window.location.hash === '#members') &&
      window.innerWidth <= 448
    ) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    scrollHandler();
    window.addEventListener('resize', scrollHandler);
    if (window.innerWidth >= 448) {
      window.addEventListener('resize', scrollHandler);
    }
    return () => {
      window.removeEventListener('resize', scrollHandler);
    };
  }, []);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (isOpen && initialized) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <>
      <StyledBurger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <SidebarWrapper isOpen={isOpen}>
        <MainMenu />
        <CommunityMenu />
      </SidebarWrapper>
    </>
  );
};

export default CommunitySidebarMobile;
