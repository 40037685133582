import styled, { css } from 'styled-components';
import { getBasicColor } from 'utils';
import { Link } from 'react-router-dom';

export const sharedStylesButton = css`
  height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  min-height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  width: ${({ width }) => `${width}`};
  background-color: ${({ variant, theme }) => getBasicColor(variant, theme)};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ variant }) =>
    variant === 'secondary' ? '#C9D9E2' : 'transparent'};
  border-radius: ${({ zoom }) => Math.floor(9999999 * zoom)}px;
  outline: none;
  position: relative;
  padding: 0 ${({ zoom }) => Math.floor(10 * zoom)}px;
  font-weight: 600;
  font-size: ${({ zoom }) => Math.floor(13 * zoom)}px;
  text-transform: uppercase;
  color: ${({ variant, theme }) =>
    variant === 'secondary' ? theme.color.baliHai : theme.color.black};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.2s, color 0.2s, background 0.2s;
  ${({ buttonStyles }) => buttonStyles};

  svg {
    height: 20px;
    margin: 0;
    width: 28px;
  }

  &:hover:enabled {
    background-color: #fff;
    border-color: ${({ variant, theme }) =>
      variant === 'secondary' || variant === 'text'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    color: ${({ variant, theme }) =>
      variant === 'secondary' || variant === 'text'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)} !important;
    cursor: pointer;

    svg g,
    svg path {
      fill: ${({ variant, theme }) =>
        variant === 'secondary'
          ? theme.color.pickledBluewood
          : getBasicColor(variant, theme)};
    }
  }

  &:focus:enabled {
    background-color: #fff;
    border-color: ${({ variant, theme }) =>
      variant === 'secondary'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    color: ${({ variant, theme }) =>
      variant === 'secondary' || variant === 'text'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    cursor: pointer;
  }

  &:active:enabled {
    background-color: #fff;
    border-color: ${({ variant, theme }) =>
      variant === 'secondary'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    color: ${({ variant, theme }) =>
      variant === 'secondary' || variant === 'text'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    cursor: pointer;

    svg g,
    svg path {
      fill: ${({ variant, theme }) =>
        variant === 'secondary'
          ? theme.color.pickledBluewood
          : getBasicColor(variant, theme)};
    }
  }

  &:disabled {
    background-color: ${({ theme, variant }) =>
      variant === 'secondary' ? 'transparent' : theme.color.blackSqueeze};
    color: ${({ theme }) => theme.color.baliHai};
  }
`;

export const StyledButton = styled.button`
  ${sharedStylesButton};
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 32px;
    `};
  ${({ marginTop }) =>
    !!marginTop &&
    css`
      margin-top: ${marginTop};
    `}
`;

export const StyledLinkButton = styled(Link)`
  ${sharedStylesButton}
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  &:hover {
    background-color: #fff;
    border-color: ${({ variant, theme }) => getBasicColor(variant, theme)};
    color: ${({ variant, theme }) =>
      variant === 'secondary'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    cursor: pointer;

    svg g {
      fill: ${({ variant, theme }) =>
        variant === 'secondary'
          ? theme.color.pickledBluewood
          : getBasicColor(variant, theme)};
    }
  }

  &:active {
    background-color: #fff;
    border-color: ${({ variant, theme }) =>
      variant === 'secondary'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    color: ${({ variant, theme }) =>
      variant === 'secondary'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)};
    cursor: pointer;
  }

  &:focus {
    background-color: #fff;
    border-color: ${({ variant, theme }) => getBasicColor(variant, theme)};
    color: ${({ variant, theme }) =>
      variant === 'secondary'
        ? theme.color.pickledBluewood
        : getBasicColor(variant, theme)} !important;
    cursor: pointer;
  }
`;

export const StyledTextWrapper = styled.span`
  display: inline-block;
  margin-right: ${({ zoom }) => Math.floor(10 * zoom)}px;
`;
