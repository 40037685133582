import { omit, pick, filter, isEmpty } from 'lodash/fp';

import { capMap } from 'utils';
import { ProfileSocialLinkType, ProfilePhoneType } from 'types';

const transformData = (data) =>
  capMap(
    (value, label) => ({
      label,
      value,
    }),
    data,
  );

const getData = (data, entity) =>
  filter(({ value }) => !!value, transformData(pick(entity, data)));

export const prepareData = (data) => {
  const phones = getData(data, Object.values(ProfilePhoneType));
  const links = getData(data, Object.values(ProfileSocialLinkType));
  const fields = omit(
    [
      ...Object.values(ProfileSocialLinkType),
      ...Object.values(ProfilePhoneType),
    ],
    data,
  );

  return {
    phones: !isEmpty(phones) ? phones : [],
    links: !isEmpty(links) ? links : [],
    ...fields,
  };
};
