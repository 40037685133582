import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SidebarFooter as SidebarFooterWrapper,
  ButtonsBlock,
  DeleteButtonBlock,
  DeleteButton,
  Loader,
  Button,
} from 'components';

interface SidebarFooterProps {
  isLoading: boolean;
  isDeleteButtonVisible: boolean;
  onDelete?: () => void;
}

const Index: FC<SidebarFooterProps> = ({
  isLoading,
  isDeleteButtonVisible,
  onDelete = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <SidebarFooterWrapper>
      <ButtonsBlock>
        {isDeleteButtonVisible ? (
          <DeleteButtonBlock>
            <DeleteButton
              width="75px"
              withLabel
              type="button"
              onClick={onDelete}
            >
              {isLoading ? (
                <Loader type="button" size="28px" thickness={2} />
              ) : null}
            </DeleteButton>
          </DeleteButtonBlock>
        ) : null}
        <Button
          type="submit"
          width="210px"
          variant={isLoading ? 'secondary' : null}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader type="button" size="28px" thickness={2} />
          ) : (
            t('common.saveChanges')
          )}
        </Button>
      </ButtonsBlock>
    </SidebarFooterWrapper>
  );
};

export default Index;
