import { createRoutine } from 'redux-saga-routines';
import { i18n } from 'i18next';

import { EditEventType } from 'constants/sidebars';
import { ReplyFormData } from 'pages/UserProfileEvents/form';
import {
  FullUser,
  IN_APP_NOTIFICATION_TYPE,
  MyEventStateData,
  ProfileLanguage,
  ProfilePhone,
  ProfileSettings,
  ProfileSocialLink,
  User,
} from 'types';
import { MyEventsFilterValues } from 'pages/UserProfileEvents';

export const editProfile = createRoutine<{
  data: {
    profileVisible?: boolean;
    description?: string | null;
    links?: ProfileSocialLink[];
    dob?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    logo?: string | null;
    profileName?: string;
    street?: string | null;
    region?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
    phones?: ProfilePhone[];
    timeZone?: string | null;
    allowedToEdit?: string[];
  };
  id: string;
  isMemberProfileEdit?: boolean;
  communityId?: string;
  onSuccess?: () => void;
  onFailure?: () => void;
}>('EDIT_PROFILE');

export const editMemberProfile = createRoutine('EDIT_MEMBER_PROFILE');

export const doDeleteProfile = createRoutine('DELETE_PROFILE');

export const doGetNotifications = createRoutine<{ userId: string }>(
  'GET_NOTIFICATIONS',
);

export const doMarkNotificationsAsRead = createRoutine(
  'MARK_NOTIFICATIONS_AS_READ',
  {
    trigger: (payload: { userId: string; notifications: string[] }) => payload,
  },
);

export const doChangeProfileSettings = createRoutine(
  'CHANGE_PROFILE_SETTINGS',
  {
    trigger: (payload: {
      userId: string;
      i18n?: i18n;
      data: {
        emailNotification?: boolean;
        inAppNotification?: boolean;
        language?: ProfileLanguage;
        pushNotification?: boolean;
        syncEventsGoogleFromCommunities?: string[];
        syncEventsAppleFromCommunities?: string[];
        timeFormat?: 24 | 12;
      };
      onSuccess?: () => void;
    }) => payload,
  },
);

export const doAddNotification = createRoutine('ADD_NOTIFICATION', {
  trigger: (payload: {
    id?: string;
    userId?: string;
    user?: User;
    isRead?: boolean;
    message: string;
    link?: string | null;
    image?: string | null;
    type: IN_APP_NOTIFICATION_TYPE | null;
    communityId?: string | null;
  }) => payload,
});

export const doChangeProfileLanguage = createRoutine(
  'CHANGE_PROFILE_LANGUAGE',
  {
    trigger: (payload: ProfileLanguage) => payload,
  },
);

export const doGetMyEvents = createRoutine('GET_MY_EVENTS', {
  trigger: (payload: {
    from: string;
    eventTypeId: string;
    key: string;
    page: number;
    limit: number;
    awaiting: boolean;
    nomination: boolean;
    unrespondedCount?: number;
  }) => payload,
});

export const doCreateEventReply = createRoutine('CREATE_MY_EVENT_REPLY', {
  trigger: (payload: {
    data: ReplyFormData;
    groupId?: string;
    communityId?: string;
    eventId: string;
    user: FullUser;
    eventSeriesId: string | null;
    type: EditEventType;
    from: string;
    filters?: MyEventsFilterValues;
    unrespondedCount?: number;
  }) => payload,
});

export const doUpdateEventReply = createRoutine('UPDATE_MY_EVENT_REPLY', {
  trigger: (payload: {
    data: ReplyFormData;
    groupId?: string;
    communityId?: string;
    eventId: string;
    user: FullUser;
    eventSeriesId: string | null;
    eventReplyId: string;
    type: EditEventType;
    from: string;
  }) => payload,
});

export const doDeleteEventReply = createRoutine('DELETE_MY_EVENT_REPLY', {
  trigger: (payload: {
    groupId?: string;
    communityId?: string;
    eventId: string;
    userId: string;
    eventSeriesId: string | null;
    eventReplyId: string;
    type: EditEventType;
    from: string;
    filters?: MyEventsFilterValues;
  }) => payload,
});

export const doGetFilteredUnrespondedEventsCount = createRoutine(
  'GET_FILTERED_UNRESPONDED_EVENTS_COUNT',
  {
    trigger: (payload: {
      from: string;
      eventTypeId: string;
      key: string;
      page: number;
      limit: number;
      awaiting: boolean;
      nomination: boolean;
    }) => payload,
    success: (payload: { filteredUnrespondedEventsCount: number }) => payload,
  },
);

export const doClearMyEvents = createRoutine<{ avoidEventTypes?: boolean }>(
  'CLEAR_MY_EVENTS',
);

export const doLinkGoogleCalendar = createRoutine('LINK_GOOGLE_CALENDAR', {
  trigger: (payload: {
    code: string;
    SetAllCommunities?: () => void;
    onSuccess: () => void;
    onFailure: () => void;
  }) => payload,
});

export const doUnlinkGoogleCalendar = createRoutine('UNLINK_GOOGLE_CALENDAR', {
  trigger: (payload: {
    settings: ProfileSettings;
    onSuccess: () => void;
    onFailure: () => void;
  }) => payload,
});

export const doLinkAppleCalendar = createRoutine('LINK_APPLE_CALEDNAR', {
  trigger: (payload: {
    userId: string;
    data: { syncEventsAppleFromCommunities: string[] };
    onSuccess: () => void;
  }) => payload,
});

export const doUnlinkAppleCalendar = createRoutine('UNLINK_APPLE_CALENDAR', {
  trigger: (payload: {
    userId: string;
    data: { syncEventsAppleFromCommunities: string[] };
  }) => payload,
});

export const doGetUnrespondedEventsCount = createRoutine(
  'GET_UNRESPONDED_EVENTS_COUNT',
);

export const doUpdatetMyEventsList = createRoutine('UPDATE_MY_EVENTS_LIST', {
  trigger: (payload: {
    type: EditEventType;
    from: string;
    eventId: string;
    eventSeriesId: string;
    myEventsData: MyEventStateData;
  }) => payload,
});
