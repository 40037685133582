import styled from 'styled-components';
import { Remove } from 'static';
import { motion } from 'framer-motion';

import { breakpoints } from 'theme';

export const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;

export const ContentWrapper = styled.div`
  padding-bottom: 100px;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  background: white;
  position: sticky;
  top: 0;
  z-index: 4;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  @media ${breakpoints.downLg} {
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  &:last-of-type {
    border-bottom: none;
  }

  @media ${breakpoints.downLg} {
    flex-wrap: wrap;
    border-bottom: none;
  }

  @media ${breakpoints.downMdPlus} {
    padding: 0;
  }
`;

export const StyledCardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: calc(100% - 170px);

  @media ${breakpoints.downLg} {
    width: 100%;
  }

  @media ${breakpoints.downMdPlus} {
    flex-direction: column;
  }
`;

export const StyledCardWrapper = styled.div`
  width: 31%;
  max-width: 31%;
  padding: 20px 0;

  form {
    width: 100%;
  }

  & > div {
    height: 100%;
  }

  @media ${breakpoints.downMdPlus} {
    margin: 20px;
    width: auto;
    max-width: unset;
  }
`;

export const StyledTypeBox = styled.div`
  width: 170px;
  min-width: 170px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 4px;
  span:first-child {
    font-size: 18px;
  }
  span:last-child {
    font-size: 15px;
    color: ${({ theme }) => theme.color.pickledBluewood};
  }

  @media ${breakpoints.downLg} {
    position: sticky;
    width: 100%;
    top: 32px;
    background: white;
    flex-direction: row;
    column-gap: 5px;
    border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
    margin-bottom: -1px;
    z-index: 3;

    span:last-child {
      font-size: 16px;
    }
  }

  @media ${breakpoints.downMdPlus} {
    height: 50px;
    margin: 0;
    border-bottom: none;
    top: 0;

    span {
      font-weight: bold;
    }
  }
`;

export const StyledClassBox = styled.div`
  width: calc(
    (100vw - ${({ isMenuOpen }) => (isMenuOpen ? 360 : 60)}px - 170px - 80px) /
      3
  );
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 5px 10px;

  span {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.pickledBluewood};
  }

  @media ${breakpoints.downLg} {
    width: 31%;
    margin: 0;
  }

  @media ${breakpoints.downMdPlus} {
    width: 100%;
    position: sticky;
    top: 50px;
    background: white;
    z-index: 2;
    border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

    span {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const StyledReminderRow = styled(motion.div)`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  position: relative;
  margin-top: 8px;

  & > div:first-of-type {
    width: 100px;
    margin-right: 10px;
  }

  & > div:last-of-type {
    width: 105px;
  }
`;

export const StyledAddReminder = styled.span`
  font-size: 11px;
  text-align: start;
  display: block;
  margin-top: 10px;
  color: ${(props) => props.theme.color.brightTurquoise};
  cursor: pointer;
`;

export const StyledRemoveIcon = styled(Remove)`
  transform: rotate(45deg);
  cursor: pointer;
  margin-left: 5px;
  margin-top: 12px;
`;
