import styled from 'styled-components';

export const StyledInputsBlockStart = styled.div`
  display: grid;
  grid-template-columns: 171px auto;
  grid-gap: 10px;
  align-items: start;
  margin-bottom: 12px;
`;

export const StyledInputsBlockEnd = styled.div`
  display: grid;
  grid-template-columns: 210px auto;
  grid-gap: 10px;
  align-items: end;
`;

export const StyledTimePickersBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

export const StyledCheckBoxBlock = styled.div`
  margin-left: ${({ canUpdate }) => (canUpdate ? '0px' : '10px')};
  margin-top: ${({ canUpdate }) => (canUpdate ? '5px' : '0px')};
  margin-bottom: ${({ canUpdate }) => (canUpdate ? '0px' : '6px')};
  label {
    height: ${({ canUpdate }) => (canUpdate ? '23px !important' : '32px')};
    span {
      top: ${({ canUpdate }) => (canUpdate ? '8.5px !important' : '8.5px')};
    }
  }
`;

export const StyledCheckButtonsBlock = styled.div`
margin - top: 20px;
`;
