import React, { FC, Fragment } from 'react';
import {
  FieldErrors,
  UseFieldArrayMethods,
  UseFormMethods,
} from 'react-hook-form';

import { DeleteButton } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';

import {
  StyledInputWrapper,
  StyledInputBlock,
  StyledDeleteBlock,
} from './styled';

interface DynamicInputsProps extends Partial<Pick<UseFormMethods, 'register'>> {
  width?: string;
  groupItems: UseFieldArrayMethods['fields'];
  errors?: FieldErrors;
  name: string;
  onDelete: (index: number) => void;
}

const DynamicInputs: FC<DynamicInputsProps> = ({
  width = '500px',
  groupItems = [],
  errors,
  name,
  register,
  onDelete,
}) => (
  <>
    {groupItems.map((field, index) => (
      <Fragment key={field.id}>
        <input
          type="hidden"
          name={`${name}.${index}.label`}
          value={field.label}
          ref={register}
        />
        <StyledInputWrapper>
          <StyledInputBlock width={width}>
            <TextInput
              error={errors && errors[index]?.value}
              label={
                field.label.charAt(0).toUpperCase() + field?.label?.slice(1)
              }
              name={`${name}.${index}.value`}
              register={register}
              defaultValue={field.value}
            />
            <StyledDeleteBlock>
              <DeleteButton onClick={() => onDelete(index)} />
            </StyledDeleteBlock>
          </StyledInputBlock>
        </StyledInputWrapper>
      </Fragment>
    ))}
  </>
);

export default DynamicInputs;
