import { call, put, takeEvery } from 'redux-saga/effects';

import {
  doGetCommunityResponses,
  doDeleteCommunityResponse,
  doCreateCommunityResponse,
  doEditCommunityResponse,
} from 'store/actionCreators/currentCommunity';
import {
  requestCommunityResponses,
  requestDeleteCommunityResponse,
  requestCreateCommunityResponse,
  requestEditCommunityResponse,
} from 'services/events';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

function* onGetCommunityResponses({
  payload,
}: ReturnType<typeof doGetCommunityResponses>) {
  try {
    const data = yield call(requestCommunityResponses, payload);
    yield put(doGetCommunityResponses.success({ data, communityId: payload }));
  } catch ({ response }) {
    yield put(doGetCommunityResponses.failure(response));
  }
}

function* onCreateCommunityResponse({
  payload,
}: ReturnType<typeof doCreateCommunityResponse>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      requestCreateCommunityResponse,
      payload.communityId,
      payload.data,
    );
    yield put(doCreateCommunityResponse.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doCreateCommunityResponse.failure(response));
  }
}

function* onEditCommunityResponse({
  payload,
}: ReturnType<typeof doEditCommunityResponse>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, id } = payload;
    const data = yield call(
      requestEditCommunityResponse,
      communityId,
      id,
      payload.data,
    );
    yield put(doEditCommunityResponse.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doEditCommunityResponse.failure(response));
  }
}

function* onDeleteCommunityResponse({
  payload,
}: ReturnType<typeof doDeleteCommunityResponse>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    yield call(requestDeleteCommunityResponse, payload.communityId, payload.id);
    yield put(doDeleteCommunityResponse.success(payload));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doDeleteCommunityResponse.failure(response));
  }
}

export default function* () {
  yield takeEvery(doGetCommunityResponses, onGetCommunityResponses);
  yield takeEvery(doDeleteCommunityResponse, onDeleteCommunityResponse);
  yield takeEvery(doCreateCommunityResponse, onCreateCommunityResponse);
  yield takeEvery(doEditCommunityResponse, onEditCommunityResponse);
}
