import React, { FC, RefObject, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CREATE_EVENT } from 'constants/sidebars';
import { GROUP_ROLES } from 'configs';
import { CreateEventButton, Loader } from 'components';
import GridScheduler from 'components/Grid/gridv2';
import { useGridData } from './hooks';
import { StyledStickyButtonBlock } from '../../CommunityOverallScheduler/Schedule/styled';
import {
  getEvents,
  getGroupEventsTotalCount,
  getRequestEventsLoading,
} from 'store/selectors/events';
import {
  getCurrentUserInGroup,
  getGroupAdmins,
  getGroupLeaders,
  getGroupMembers,
  getTotal,
} from 'store/selectors/currentGroup';
import { getCurrentUserInCommunity } from 'store/selectors/currentCommunity';
import {
  doRequestEvents,
  doGetCommunityTotal,
} from 'store/actionCreators/currentCommunity';
import { doGetTotal } from 'store/actionCreators/currentGroup';
import { openModal } from 'store/actionCreators/modal';
import { useSchedulerPageData } from 'hooks/useSchedulerPageData';
import { ScheduleProps } from '../../CommunityOverallScheduler/Schedule';
import { useLazyLoading } from 'hooks';
import { CommunityRoles } from 'types';
import { initialFiltersState } from 'sidebars/CreateEventOverall/utils';

const GroupSchedule: FC<ScheduleProps> = ({
  filters,
  community,
  usersFilter,
  zoom,
  headerHeight,
}) => {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const events = useSelector(getEvents);
  const adminMembers = useSelector(getGroupAdmins);
  const groupLeads = useSelector(getGroupLeaders);
  const members = useSelector(getGroupMembers);
  const eventsLoading = useSelector(getRequestEventsLoading);
  const eventsTotalCount = useSelector(getGroupEventsTotalCount);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const currentUserInGroup = useSelector(getCurrentUserInGroup);
  const total = useSelector(getTotal);
  const { t } = useTranslation();

  const filterHeight = useMemo(() => {
    return headerHeight;
  }, [headerHeight]);

  const {
    eventsToRender,
    footerData,
    openGiveReplySidebar,
    openTaskDetailsSidebar,
    openViewMemberSidebar,
    onHeaderClick,
    onFooterClick,
  } = useSchedulerPageData({
    events,
    community,
    filters,
    total: {
      data: [
        ...(total?.data || []),
        ...(community.overallScheduler?.total?.data || []),
      ],
    },
  });

  const canCreateEvent =
    [CommunityRoles.OWNER, CommunityRoles.ADMIN].includes(
      currentUser.communityPermission.role,
    ) ||
    [GROUP_ROLES.ADMIN, GROUP_ROLES.GROUP_LEADER].includes(
      currentUserInGroup?.groupPermission.role,
    );

  const eventsFilterValues = useMemo(() => {
    return {
      groupId,
      from: filters.from,
      to: filters.to,
      eventTypeId: filters.eventType.value,
      filterValue: usersFilter,
    };
  }, [filters, usersFilter, groupId]);

  const loadEvents = (filterValues) => {
    dispatch(doRequestEvents(filterValues));
  };

  useEffect(() => {
    if (community.id) {
      dispatch(
        doGetCommunityTotal({
          communityId: community.id,
          from: filters.from,
          to: filters.to,
        }),
      );
    }
  }, []);

  const gridData = useGridData({
    tasks: community?.eventTasks,
    adminMembers,
    groupLeads,
    members,
    eventsToRender,
    responses: community?.eventResponses,
    openGiveReplySidebar,
    openTaskDetailsSidebar,
    currentUser,
    currentUserInGroup,
    zoom,
    openViewMemberSidebar,
    community,
  });

  useEffect(() => {
    if (groupId) {
      dispatch(
        doGetTotal({
          groupId,
          from: filters.from,
          to: filters.to,
        }),
      );
    }
  }, [groupId, usersFilter, filters]);

  const pageSize = 40;

  const { listRef }: { listRef: RefObject<HTMLElement> } = useLazyLoading({
    isLoading: eventsLoading,
    loader: loadEvents,
    pageSize,
    listLength: events?.length || 0,
    hasNextPage: events && events.length < eventsTotalCount,
    cleaner: () => {},
    filterValues: eventsFilterValues,
    scollOffset: 1000,
  });

  const openCreateEventSidebar = () =>
    dispatch(
      openModal({
        type: CREATE_EVENT,
        placement: 'right',
        data: {
          groupId,
          filterFrom: filters.from,
          filterTo: initialFiltersState().to,
        },
      }),
    );

  if (eventsLoading && !events?.length) {
    return <Loader />;
  }

  return (
    <>
      <div>
        {eventsLoading ? <Loader floating={true} /> : null}
        <GridScheduler
          listRef={listRef}
          tableId={groupId}
          data={gridData}
          footer={footerData}
          header={eventsToRender}
          topHeadHeight={filterHeight + 27}
          zoom={zoom}
          onHeaderClick={onHeaderClick}
          onFooterClick={onFooterClick}
        />
      </div>
      {canCreateEvent && (
        <StyledStickyButtonBlock>
          <CreateEventButton
            extraText={t('event.createEvent')}
            onClick={openCreateEventSidebar}
          />
        </StyledStickyButtonBlock>
      )}
    </>
  );
};

export default GroupSchedule;
