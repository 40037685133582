import styled from 'styled-components';

export const ItemWrapper = styled.p`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: ${({ lineHeight }) => lineHeight || '20px'};
  font-weight: 400;
  color: ${({ theme }) => theme.color.shark};
  white-space: break-spaces;
  position: relative;
`;

export const Text = styled.span`
  word-break: ${({ truncatedText }) =>
    truncatedText ? 'break-word' : 'normal'}; ;
`;

export const BtnText = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.color.brightTurquoise};
  cursor: pointer;
  position: absolute;
  right: 0px;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    box-sizing: border-box;
    bottom: 0px;
    background-color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;
