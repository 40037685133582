import {
  Community,
  CommunityListItem,
  CommunityRoles,
  GroupRoles,
} from 'types';

export const hasCommunityAdminPermissions = (
  userId: string,
  community: Community | CommunityListItem,
): boolean => {
  if (community?.users) {
    const user = community.users.find((u) => u.id === userId);

    if (user?.communityPermission) {
      return [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(
        user.communityPermission.role,
      );
    }
  }

  return false;
};

export const hasGroupAdminPermissions = (
  userId: string,
  community: Community | CommunityListItem,
  groupId: string,
): boolean => {
  if (community?.users) {
    const group = community.groups.find((g) => g.id === groupId);
    const groupPermission = group?.users?.find((u) => u.id === userId)
      ?.groupPermission?.role;

    return (
      groupPermission === GroupRoles.ADMIN ||
      groupPermission === GroupRoles.GROUP_LEAD
    );
  }

  return false;
};

export const hasCommunityOrGroupAdminPermissions = (
  userId: string,
  community: Community,
): boolean => {
  if (community.users) {
    const user = community.users.find((u) => u.id === userId);

    if (
      [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(
        user?.communityPermission?.role,
      )
    ) {
      return true;
    } else {
      const userGroups =
        community.groups?.filter((g) => g.users.some((u) => u.id === userId)) ||
        [];
      const userGroupsPermissions = userGroups.map(
        (g) => g.users.find((u) => u.id === userId)?.groupPermission?.role,
      );

      if (
        userGroupsPermissions.some(
          (i) => i === GroupRoles.ADMIN || i === GroupRoles.GROUP_LEAD,
        )
      ) {
        return true;
      }
    }
  }

  return false;
};
