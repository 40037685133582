import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getProfileName } from 'utils';
import { getEventResponses } from 'store/selectors/eventResponses';
import { getEventTasks } from 'store/selectors/eventTasks';
import Popup from '../../Popup';
import { APPOINTMENT_SCHEDULE_COLUMNS } from 'configs/statistics';
import {
  AppointmentScheduleStatistics,
  AppointmentScheduleStatisticsTotal,
  EventGridItem,
  StatisticsData,
} from 'types';
import { AttendedStar, NoResponse, PenaltyDot } from 'static';
import { Tooltip } from '../../index';

import {
  CellTitleWithIconWrapper,
  DescriptionWrapper,
  FooterCellContent,
  SeeMoreTextWrapper,
  ShowPopupTrigger,
} from './styled';

const SeeMoreInnerComponent = ({
  textPairs,
}: {
  textPairs: (string | number)[][];
}) => (
  <PerfectScrollbar
    style={{
      width: '100%',
      maxHeight: 'calc(100svh - 160px)',
      marginBottom: 20,
    }}
    className={'menu-list-scrollbar'}
    options={{
      wheelSpeed: 0.1,
      wheelPropagation: false,
      minScrollbarLength: 4,
    }}
  >
    <SeeMoreTextWrapper>
      {textPairs.map((i) => (
        <div key={i[0]}>
          <div>{i[0]}:</div>
          <div>{i[1]}</div>
        </div>
      ))}
    </SeeMoreTextWrapper>
  </PerfectScrollbar>
);

export const useHeaderData = (visibleColumns: string[], zoom: number) => {
  const { t } = useTranslation();
  const responses = useSelector(getEventResponses) || [];
  const eventTasks = useSelector(getEventTasks) || [];

  return [
    {
      title: t('common.startDate'),
    },
    visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.DESCRIPTION)
      ? {
          title: t('common.description'),
        }
      : null,
    {
      title: t('event.eventType'),
    },
    visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.LOCATION)
      ? {
          title: t('event.location'),
        }
      : null,
    visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.CREATION_DATE)
      ? {
          title: t('event.createdAt'),
        }
      : null,
    visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.ORGANIZED_BY)
      ? {
          title: t('event.eventOrganizer'),
        }
      : null,
    visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.START)
      ? {
          title: t('common.startTime'),
        }
      : null,
    visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.END)
      ? {
          title: t('common.endTime'),
        }
      : null,
    {
      title: t('event.totalInvitees'),
    },
    {
      title: t('event.responsesReceived'),
    },
    {
      title: t('event.responsesPercentage'),
    },
    {
      title: (
        <CellTitleWithIconWrapper zoom={zoom}>
          <div>{t('event.awaiting')}</div>
          <NoResponse />
        </CellTitleWithIconWrapper>
      ),
    },
    ...responses.map((r) => ({
      title: r.title,
      color: r.color,
    })),
    {
      title: (
        <CellTitleWithIconWrapper zoom={zoom}>
          <div>{t('event.nomination')}</div>
          <AttendedStar />
        </CellTitleWithIconWrapper>
      ),
    },
    {
      title: (
        <CellTitleWithIconWrapper className="penalty" zoom={zoom}>
          <div>{t('event.penalty')}</div>
          <PenaltyDot />
        </CellTitleWithIconWrapper>
      ),
    },
    {
      title: t('event.additionalGuests'),
    },
    {
      title: t('event.acceptedTasks'),
    },
    {
      title: t('event.acceptedTasksByUser'),
    },
    ...eventTasks.map((t) => ({
      title: t.title,
      color: '#41a2ef',
    })),
    {
      title: t('event.givenComments'),
    },
    {
      title: t('event.givenCommentsByUser'),
    },
  ].filter((i) => !!i);
};

export const useGridData = (
  data: AppointmentScheduleStatistics,
  visibleColumns: string[],
  zoom: number,
) => {
  const { t } = useTranslation();
  const responses = useSelector(getEventResponses) || [];
  const eventTasks = useSelector(getEventTasks) || [];

  const gridData: (EventGridItem & { values: StatisticsData[] })[] =
    useMemo(() => {
      return (data?.events || []).map((e) => ({
        type: 'statistics',
        id: e.id,
        label: e.title,
        values: [
          { text: moment(e.from).format('DD.MM.YYYY'), align: 'flex-start' },
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.DESCRIPTION)
            ? {
                component: (
                  <Tooltip
                    isVisible
                    zoom={zoom}
                    text={e.description}
                    offset={11 * zoom}
                    position="right-center"
                    visibleAfterWidth={112 * zoom}
                    tooltipStyle={{
                      fontSize: 12 * zoom,
                      maxWidth: 200 * zoom,
                      display: 'block',
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      lineBreak: 'normal',
                    }}
                  >
                    <DescriptionWrapper>{e.description}</DescriptionWrapper>
                  </Tooltip>
                ),
                align: 'flex-start',
                clickable: true,
              }
            : null,
          { text: e.eventType?.title, align: 'flex-start' },
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.LOCATION)
            ? { text: e.location, align: 'flex-start' }
            : null,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.CREATION_DATE)
            ? {
                text: moment(e.createdAt).format('DD.MM.YYYY HH:mm'),
                align: 'flex-start',
              }
            : null,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.ORGANIZED_BY)
            ? {
                text: getProfileName(e.user?.profile || {}),
                align: 'flex-start',
              }
            : null,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.START)
            ? { text: moment(e.from).format('HH:mm'), align: 'flex-start' }
            : null,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.END)
            ? { text: moment(e.to).format('HH:mm'), align: 'flex-start' }
            : null,
          { text: e.totalInvitees, align: 'flex-start' },
          { text: e.totalResponses, align: 'flex-start' },
          { text: e.responsePercentage, align: 'flex-start' },
          { text: e.awaiting, align: 'flex-start' },
          ...responses.map((r) => ({
            text: e.subEventReplies.filter((i) => i.eventResponseId === r.id)
              .length,
            align: 'flex-start',
            color: `${r.color}d1`,
          })),
          {
            text: e.subEventReplies.filter((r) => !!r.attended).length,
            align: 'flex-start',
          },
          {
            text: e.subEventReplies.filter((r) => !!r.penalty).length,
            align: 'flex-start',
          },
          { text: e.additionalGuests, align: 'flex-start' },
          { text: e.totalTaskReplies, align: 'flex-start' },
          {
            component: (
              <Popup
                size="md"
                InnerComponent={
                  <SeeMoreInnerComponent
                    textPairs={e.taskRepliesByUser.map((i) => [
                      i.eventTaskName,
                      i.users,
                    ])}
                  />
                }
              >
                <ShowPopupTrigger>
                  {t('communityInvitation.membersInvitation.showMore')}
                </ShowPopupTrigger>
              </Popup>
            ),
            align: 'flex-start',
          },
          ...eventTasks.map((t) => ({
            text: e.subEventTaskReplies.filter((i) => i.eventTaskId === t.id)
              .length,
            align: 'flex-start',
            color: '#41a2efd1',
          })),
          { text: e.givenComments, align: 'flex-start' },
          {
            component: (
              <Popup
                size="md"
                InnerComponent={
                  <SeeMoreInnerComponent
                    textPairs={e.givenCommentsByUser.map((i) => [
                      i.profileName,
                      i.comment,
                    ])}
                  />
                }
              >
                <ShowPopupTrigger>
                  {t('communityInvitation.membersInvitation.showMore')}
                </ShowPopupTrigger>
              </Popup>
            ),
            align: 'flex-start',
          },
        ].filter((i) => !!i),
      }));
    }, [data, responses, eventTasks, visibleColumns]);

  // columns divider indexes
  const startIndexResponses = 12 - (6 - visibleColumns.length);
  const endIndexResponses = startIndexResponses + responses.length + 2;
  const divideColumns = [[startIndexResponses, endIndexResponses]];

  return { gridData, divideColumns };
};

export const useFooterData = (
  data: AppointmentScheduleStatistics,
  total: AppointmentScheduleStatisticsTotal,
  zoom: number,
  visibleColumns: string[],
) => {
  const { t } = useTranslation();
  const responses = useSelector(getEventResponses) || [];
  const eventTasks = useSelector(getEventTasks) || [];

  return {
    label: t('subscriptions.total'),
    data: [
      {
        label: <b>{`${t('event.allEvents')}: ${data?.totalCount || 0}`}</b>,
        values: [
          null,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.DESCRIPTION)
            ? null
            : undefined,
          <Popup
            size="md"
            key="0"
            InnerComponent={
              <SeeMoreInnerComponent
                textPairs={total?.data?.eventTypes.map((i) => [
                  i.label,
                  i.value,
                ])}
              />
            }
          >
            <ShowPopupTrigger>
              <b>{t('communityInvitation.membersInvitation.showMore')}</b>
            </ShowPopupTrigger>
          </Popup>,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.LOCATION)
            ? null
            : undefined,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.CREATION_DATE)
            ? null
            : undefined,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.ORGANIZED_BY) ? (
            <Popup
              size="md"
              key="0"
              InnerComponent={
                <SeeMoreInnerComponent
                  textPairs={total?.data?.eventOrganizers?.map((i) => [
                    i.label,
                    i.value,
                  ])}
                />
              }
            >
              <ShowPopupTrigger>
                <b>{t('communityInvitation.membersInvitation.showMore')}</b>
              </ShowPopupTrigger>
            </Popup>
          ) : undefined,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.START) ? (
            <FooterCellContent zoom={zoom} key="hours">
              <b>
                {t('event.hours')}: {total?.data?.totalTimeInHours || 0}
              </b>
            </FooterCellContent>
          ) : undefined,
          visibleColumns.includes(APPOINTMENT_SCHEDULE_COLUMNS.END)
            ? null
            : undefined,
          <FooterCellContent zoom={zoom} key="invitees">
            <b>{total?.data?.totalInvitees}</b>
          </FooterCellContent>,
          <FooterCellContent zoom={zoom} key="responses">
            <b>{total?.data?.totalResponses}</b>
          </FooterCellContent>,
          <FooterCellContent zoom={zoom} key="abgRespPercentage">
            <b>{total?.data?.avgResponsePercentage}</b>
          </FooterCellContent>,
          <FooterCellContent zoom={zoom} key="awaiting">
            <b>{total?.data?.totalAwaiting}</b>
          </FooterCellContent>,
          ...responses.map((r) => (
            <FooterCellContent zoom={zoom} key={r.id}>
              <b>{total?.data?.totalRepliesByResponse[r.id]?.value || 0}</b>
            </FooterCellContent>
          )),
          <FooterCellContent zoom={zoom} key="nomination">
            <b>{total?.data?.totalNominations}</b>
          </FooterCellContent>,
          <FooterCellContent zoom={zoom} key="penalty">
            <b>{total?.data?.totalPenalties}</b>
          </FooterCellContent>,
          <FooterCellContent zoom={zoom} key="guests">
            <b>{total?.data?.totalGuests}</b>
          </FooterCellContent>,
          <FooterCellContent zoom={zoom} key="taskResponses">
            <b>{total?.data?.totalTaskResponses}</b>
          </FooterCellContent>,
          <Popup
            size="md"
            key="0"
            InnerComponent={
              <SeeMoreInnerComponent
                textPairs={total?.data?.totalTaskRepliesByUser?.map((i) => [
                  i.eventTaskName,
                  i.users,
                ])}
              />
            }
          >
            <ShowPopupTrigger>
              <b>{t('communityInvitation.membersInvitation.showMore')}</b>
            </ShowPopupTrigger>
          </Popup>,
          ...eventTasks.map((t) => (
            <FooterCellContent zoom={zoom} key={t.id}>
              <b>{total?.data?.totalRepliesByTask[t.id] || 0}</b>
            </FooterCellContent>
          )),
          <FooterCellContent zoom={zoom} key="comments">
            <b>{total?.data?.totalComments}</b>
          </FooterCellContent>,
        ].filter((i) => i !== undefined),
      },
    ],
  };
};
