import React, { FC } from 'react';

import {
  StyledInputBox,
  StyledInput,
  StyledLeftIcon,
  StyledRightIcon,
  StyledInputWrapper,
} from './styled';

interface ZoomInputProps {
  value: number;
  color: 'black' | 'danger';
  onChange: (value: number) => void;
}

const ZoomInput: FC<ZoomInputProps> = ({ value, onChange, color }) => {
  return (
    <StyledInputWrapper>
      <StyledInputBox>
        <StyledLeftIcon
          color={color}
          onClick={() => onChange(value > 0.05 ? value - 0.05 : 0)}
        />
        <StyledInput value={Math.round(value * 100).toString() + '%'} />
        <StyledRightIcon alt="" onClick={() => onChange(value + 0.05)} />
      </StyledInputBox>
    </StyledInputWrapper>
  );
};

export default ZoomInput;
