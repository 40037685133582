import styled from 'styled-components';
import { breakpoints } from 'theme';

const MatrixList = styled.div`
  display: flex;
  position: relative;
  background: ${({ theme }) => `${theme.color.dew}`};
  padding: 0 0 100px 23px;
  height: calc(100svh - 154px);
  overflow-y: auto;
  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 119px);
  }
`;

MatrixList.Left = styled.div`
  min-width: 200px;
  padding-top: 95px;
  position: relative;
`;

MatrixList.Right = styled.div`
  padding-right: 75px;
  position: relative;
  display: flex;
  margin-top: 110px;
  height: ${({ height }) => `${height}px`};
  flex-direction: column;
`;

MatrixList.Right.Title = styled.div`
  display: flex;
  position: sticky;
  top: 110px;
  z-index: 1000;
`;

MatrixList.Right.Title.Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 35px;
  bottom: 18px;
`;

MatrixList.Right.Title.Name = styled.div`
  border-top: 1px solid #e5e9e9;
  transform: rotate(-60deg);
  position: relative;
  width: 150px;
  height: 35px;
  top: -39px;
  left: -22px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-left: 20px;
  padding-top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: ${({ theme }) => `${theme.color.dew}`};

  &:before {
    content: '';
    height: 35px;
    width: 20px;
    background: #fbfffe;
    border-top: 1px solid #e5e9e9;
    position: absolute;
    left: -9px;
    top: 1px;
    transform: rotateZ(-30deg);
  }

  &:after {
    content: '';
    height: 30px;
    width: 20px;
    background: ${({ theme }) => `${theme.color.dew}`};
    position: absolute;
    right: -20px;
    top: 0;
  }
`;

MatrixList.Right.Title.NameBox = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

MatrixList.Right.Title.CheckBox = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  width: 35px;
  height: 35px;
  padding-left: 10px;
  background: ${({ theme }) => `${theme.color.dew}`};
`;

MatrixList.Right.Row = styled.div`
  display: flex;
  width: fit-content;

  &:nth-of-type(2n) {
    background: ${({ theme }) => `${theme.color.blackSqueeze}`};
  }
`;

MatrixList.Right.Cell = styled.div`
  width: 35px;
  height: 35px;
  border-left: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  padding-left: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

MatrixList.SearchContainer = styled.div`
  padding-right: 50px;
  max-width: 270px;
  max-height: 124px;

  @media ${breakpoints.downSmPlus} {
    padding-right: 10px;
  }
  & > div {
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

MatrixList.NameContainer = styled.div`
  margin-top: 10px;
`;

MatrixList.NameContainer.Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  max-width: 300px;
  padding: 0 0 0 13px;
  @media ${breakpoints.downSmPlus} {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:nth-of-type(2n + 1) {
    background: ${({ theme }) => `${theme.color.blackSqueeze}`};
  }
`;

MatrixList.NameContainer.Item.Name = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 14px;
  font-weight: 500;
`;

MatrixList.Loader = styled.div`
  position: absolute;
  top: 150px;
  left: 400px;
`;

export { MatrixList };
