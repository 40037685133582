import React, { FC, useEffect, useRef } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components';
import { getProfileName } from 'utils/common';
import useGrid from './hook';
import { useParticipantsValues } from 'hooks';
import { CommunityUser, CommunityMembersByGroups } from 'types';

import {
  StyledHeader,
  StyledHeaderLabel,
  StyledSelectAllButton,
  ScrollWrapper,
  StyledSearchWrapper,
} from './styled';

interface CommunityUsersByGroupsProps {
  value: string[];
  height?: string;
  membersByGroups: CommunityMembersByGroups[];
  users: CommunityUser[];
  onChange: (value?: any) => void;
}

const CommunityUsersByGroups: FC<CommunityUsersByGroupsProps> = ({
  value,
  height,
  membersByGroups,
  users,
  onChange,
}) => {
  const { t } = useTranslation();
  const listRef = useRef(null);

  const {
    communityUsersWithGroup,
    selectedParticipants,
    selectParticipant,
    selectAllCommunityAdmins,
    selectAllGroupsAdmins,
    selectGroup,
    searchParticipantsInputValue,
    setSearchParticipantsInputValue,
    handleSelectAllParticipantsClick,
    allParticipantsChecked,
    allCommunityAdminsChecked,
    allGroupAdminsChecked,
    gridData: participantsListData,
  } = useParticipantsValues(value, membersByGroups, users, onChange);

  const { rowRenderer, onClick, filteredData } = useGrid(
    participantsListData,
    selectedParticipants,
    selectParticipant,
    selectGroup,
    getProfileName,
    searchParticipantsInputValue,
    communityUsersWithGroup,
  );

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.recomputeRowHeights();
      listRef.current.forceUpdateGrid();
    }
  }, [filteredData]);

  return (
    <>
      <StyledSearchWrapper>
        <SearchInput
          width="100%"
          value={searchParticipantsInputValue}
          onChange={(e) => setSearchParticipantsInputValue(e.target.value)}
        />
      </StyledSearchWrapper>
      <StyledHeader>
        <StyledSelectAllButton
          type="button"
          onClick={handleSelectAllParticipantsClick}
          checked={allParticipantsChecked}
        />
        <StyledHeaderLabel>{t('common.selectAll')}</StyledHeaderLabel>
      </StyledHeader>
      <StyledHeader>
        <StyledSelectAllButton
          type="button"
          onClick={selectAllCommunityAdmins}
          checked={allCommunityAdminsChecked}
        />
        <StyledHeaderLabel>
          {t('community.selectAllCommunityAdmins')}
        </StyledHeaderLabel>
      </StyledHeader>
      <StyledHeader>
        <StyledSelectAllButton
          type="button"
          onClick={selectAllGroupsAdmins}
          checked={allGroupAdminsChecked}
        />
        <StyledHeaderLabel>
          {t('community.selectAllGroupAdmins')}
        </StyledHeaderLabel>
      </StyledHeader>
      <div onClick={onClick}>
        <ScrollWrapper height={height}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/* @ts-ignore*/}
          <AutoSizer>
            {({ width }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <List
                ref={listRef}
                rowRenderer={rowRenderer}
                height={
                  filteredData.filter((item) => item.type === 'group').length *
                    37 +
                  filteredData.filter((item) => item.type !== 'group').length *
                    61
                }
                rowCount={filteredData.length}
                width={width}
                rowHeight={({ index }) =>
                  filteredData[index].type === 'group' ? 37 : 61
                }
              />
            )}
          </AutoSizer>
        </ScrollWrapper>
      </div>
    </>
  );
};

export default CommunityUsersByGroups;
