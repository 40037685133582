export enum LANGUAGES {
  English = 'English',
  Deutsch = 'Deutsch',
  Italian = 'Italiana',
  French = 'Français',
  Russian = 'Русский',
  Spanish = 'Español',
}

export enum PROFILE_LANGUAGE_TYPES {
  English = 'en',
  Deutsch = 'de',
  Italian = 'it',
  Spanish = 'es',
  Russian = 'ru',
  French = 'fr',
}
