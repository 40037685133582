import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import StatisticsTotal from './StatisticsTotal';
import StatisticsFilter from './Filter';
import Loader from 'components/Loader';
import { STATISTICS_TYPES } from 'configs';
import { doRequestStatistic } from 'store/actionCreators/groups';
import {
  getStatistic,
  getStatisticLoading,
} from 'store/selectors/currentGroup';
import AppointmentScheduleFilters from '../OverallScheduleStatistics/Filters/AppointmentScheduleFilters';
import AppointmentSchedule from '../OverallScheduleStatistics/AppointmentSchedule';
import { AppointmentSchedulerWrapper } from '../OverallScheduleStatistics/styled';
import {
  doClearMemberFiguresStatisticsTotal,
  doGetAppointmentScheduleStatistics,
  doGetAppointmentScheduleStatisticsTotal,
  doGetMemberFiguresStatistics,
  doGetMemberFiguresStatisticsTotal,
} from 'store/actionCreators/statistics';

import { Wrapper } from './styled';
import { useFilters } from '../OverallScheduleStatistics/hooks';
import MemberFigures from '../OverallScheduleStatistics/MemberFigures';

interface GroupStatisticsProps {
  zoom: number;
  headerHeight: number;
}

const GroupStatistics: FC<GroupStatisticsProps> = ({ zoom, headerHeight }) => {
  const dispatch = useDispatch();
  const { communityId, groupId } = useParams();
  const location = useLocation();
  const [type, setType] = useState(
    location.hash === '#memberPresence'
      ? STATISTICS_TYPES.MEMBER_FIGURES
      : STATISTICS_TYPES.APPOINTMENT_SCHEDULE,
  );
  const [gridData, gridDataHandler] = useState([]);
  const statistic = useSelector(getStatistic);
  const requestStatisticLoading = useSelector(getStatisticLoading);

  const { filters, setFilters } = useFilters();

  useEffect(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(
        doGetAppointmentScheduleStatistics({
          communityId,
          from,
          to,
          groupIds: [groupId],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          page: filters.page,
          visibleColumns: filters.visibleColumns,
          limit: filters.limit,
          excludeCommunityEvents: true,
        }),
      );
    } else if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(
        doGetMemberFiguresStatistics({
          communityId,
          from,
          to,
          groupIds: [groupId],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          memberIds: filters.members?.map((i) => i.value) || [],
          page: filters.page,
          limit: filters.limit,
        }),
      );
    }
  }, [filters, groupId, type]);

  useEffect(() => {
    if (
      type !== STATISTICS_TYPES.APPOINTMENT_SCHEDULE &&
      type !== STATISTICS_TYPES.MEMBER_FIGURES
    ) {
      dispatch(
        doRequestStatistic({
          id: groupId,
          fromDate: filters.from,
          toDate: filters.to,
          eventTypeId: filters.eventType?.value,
        }),
      );
    }
  }, [filters.from, filters.to, filters.eventType, groupId, type]);

  useEffect(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(
        doGetAppointmentScheduleStatisticsTotal({
          communityId,
          from,
          to,
          groupIds: filters.groups?.map((i) => i.value) || [],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          visibleColumns: filters.visibleColumns,
          excludeCommunityEvents: true,
        }),
      );
    } else if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(doClearMemberFiguresStatisticsTotal());

      dispatch(
        doGetMemberFiguresStatisticsTotal({
          communityId,
          from,
          to,
          groupIds: [groupId],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          memberIds: filters.members?.map((i) => i.value) || [],
          dispatch,
        }),
      );
    }
  }, [
    filters.dateRange,
    filters.from,
    filters.to,
    filters.groups,
    filters.eventTypes,
    filters.members,
    filters.eventType,
    filters.visibleColumns,
    groupId,
    type,
  ]);

  const displayLoader = useMemo(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      return false;
    }

    if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      return false;
    }

    return !statistic || requestStatisticLoading;
  }, [type, statistic, requestStatisticLoading]);

  if (displayLoader) {
    return <Loader />;
  }

  return (
    <Wrapper>
      {type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE ? (
        <AppointmentSchedulerWrapper headerHeight={headerHeight}>
          <AppointmentScheduleFilters
            type={type}
            filters={filters}
            setType={setType}
            setFilters={setFilters}
          />
          <AppointmentSchedule
            zoom={zoom}
            filters={filters}
            gridDataHandler={gridDataHandler}
          />
        </AppointmentSchedulerWrapper>
      ) : type === STATISTICS_TYPES.MEMBER_FIGURES ? (
        <AppointmentSchedulerWrapper headerHeight={headerHeight}>
          <AppointmentScheduleFilters
            type={type}
            filters={filters}
            setType={setType}
            setFilters={setFilters}
          />
          <MemberFigures
            zoom={zoom}
            gridDataHandler={gridDataHandler}
            selectedMembers={filters.members?.map((i) => i.label)}
          />
        </AppointmentSchedulerWrapper>
      ) : (
        <AppointmentSchedulerWrapper>
          <StatisticsFilter
            type={type}
            gridData={gridData}
            filters={filters}
            setType={setType}
            setFilters={setFilters}
          />
          <StatisticsTotal
            type={type}
            statistic={statistic}
            zoom={zoom}
            gridDataHandler={gridDataHandler}
          />
        </AppointmentSchedulerWrapper>
      )}
    </Wrapper>
  );
};

export default GroupStatistics;
