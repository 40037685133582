import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ customPadding }) => customPadding};
`;

export const StyledDivider = styled.hr`
  width: 100%;
  margin: ${({ customMargin }) =>
    customMargin ? customMargin : '0px 0px 32px 0px'};
  height: 1px;
  border: none;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 0%,
    #cfcfcf 5%,
    #cfcfcf 95%,
    rgba(255, 255, 255, 0.1) 100%
  );
  /* background-color: ${({ theme }) => theme.color.lightGrey}; */
`;

export const Title = styled.h3`
  z-index: 20;
  padding: 0px 5px;
  margin: 0px 2px;
  position: absolute;
  top: 0;
  background-color: white;
  color: ${({ theme }) => theme.color.pickledBluewood};
  ${({ position }) =>
    position === 'left'
      ? 'left: 3%'
      : position === 'right'
      ? 'right : 3%'
      : null}
`;
