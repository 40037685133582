import styled from 'styled-components';

export const StyledText = styled.span`
  margin-left: 14px;
  font-size: 15px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

export const StyledSpot = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-left: 3px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 100px;
`;
