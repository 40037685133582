import styled from 'styled-components';
import { NotificationsIcon } from 'static';

import { defaultIconButtonStyles } from '../shared';

export const StyledIcon = styled(NotificationsIcon)`
  user-select: none;
`;

export const StyledButton = styled.button`
  ${defaultIconButtonStyles};
  position: absolute;
  right: 16px;
  top: 15px;
`;

export const UnreadNotificationIndicator = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: -7px;
  right: -8px;
  background-color: ${({ theme }) => theme.color.bittersweet};
  border-radius: 50%;
  line-height: 18px;
  text-align: center;
  font-size: 10px;
  color: white;
  font-weight: 700;
`;
