import styled from 'styled-components';
import { CloseIcon } from 'static';

import { defaultIconButtonStyles } from '../shared';

export const StyledIcon = styled(CloseIcon)`
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const StyledButton = styled.button`
  ${defaultIconButtonStyles}
  width: 30px;
  height: 30px;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};

  &:hover ${StyledIcon} {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const StyledIconContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  transition: all ease 0.3s;
  :hover {
    background-color: ${({ theme }) => theme.color.lightGrey};
  }
  :hover ${StyledIcon} {
    path {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;
