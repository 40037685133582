import React, { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { useSelector } from 'react-redux';
import {
  Control,
  ControllerRenderProps,
  FieldErrors,
  UseFormMethods,
} from 'react-hook-form';
import moment from 'moment';

import { frequency, RepeatEventFrequency } from 'configs/frequency';
import { intervalTimeUnits } from 'configs/intervalTimeUnits';
import { deadlineTimeUnits } from 'configs/deadlineTimeUnits';
import { CreateEventStates } from 'constants/index';
import {
  OverflowSidebarContentWithTabs,
  SidebarInputsWrapper,
  TextArea,
  CheckButtonsGroup,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV3';
import CheckBox from 'components/CheckBox/CheckBoxV2';
import TimePicker from 'components/Inputs/TimePicker/TimePickerV2';
import DatePickerControlled from 'components/Inputs/DatePickers/DatePickerControlled/DatePickerControlledV2';
import { EventFormData, FIELDS } from '../form';

import {
  StyledInputsBlockStart,
  StyledInputsBlockEnd,
  StyledCheckBoxBlock,
  StyledTimePickersBlock,
  StyledCheckButtonsBlock,
} from './styled';
import {
  Responses,
  ResponseWrapper,
  StyledLabel,
} from 'components/Sidebar/shared';
import { getEventResponses } from 'store/selectors/eventResponses';
import {
  StyledResponseButton,
  StyledResponseLabel,
  StyledResponseRadio,
} from 'sidebars/GiveReply/styled';
import { CommentIcon } from 'static';
import { getdefaultEventSettings } from 'store/selectors/defaultEventSetting';

interface CreateEventEventProps
  extends Partial<Pick<UseFormMethods<EventFormData>, 'register'>> {
  sidebarState: CreateEventStates;
  errors: FieldErrors;
  control: Control;
  watchedValues: any;
  weekDaysFieldProps: ControllerRenderProps<EventFormData>;
  setValue: any;
  eventResponseFieldProps: any;
  editMode: boolean;
  eventResponseId: string;
}

const CreateEventEvent: FC<CreateEventEventProps> = ({
  editMode,
  control,
  register,
  errors,
  sidebarState,
  weekDaysFieldProps,
  watchedValues,
  setValue,
  eventResponseFieldProps,
  eventResponseId,
}) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const eventTypeAndDefaultSetting = useSelector(getdefaultEventSettings) || [];
  const responses = useSelector(getEventResponses) || [];
  const eventTypesOptions = useMemo(() => {
    return (
      eventTypeAndDefaultSetting
        ?.filter((type) => !type.eventType.deletedAt)
        .map((type) => ({
          label: type.eventType.title,
          value: type.eventType.id,
        })) || []
    );
  }, [eventTypeAndDefaultSetting]);

  useEffect(() => {
    const newEndVal = new Date(new Date().getTime() + 1000 * 60 * 15);
    const isSameDay =
      newEndVal.getDate() ===
      new Date(watchedValues[FIELDS.START_TIME]).getDate();

    //check if form is opened in create or edit mode
    const editMode = Boolean(watchedValues[FIELDS.TITLE]);

    if (isSameDay && !editMode) {
      setValue(
        FIELDS.END_TIME,
        new Date(watchedValues[FIELDS.START_TIME].getTime() + 1000 * 60 * 15),
      );
    }
  }, []);

  const onResponseChange = (responseId: string) => {
    const currantId =
      eventResponseFieldProps.value === responseId ? null : responseId;
    eventResponseFieldProps.onChange(currantId);
    if (currantId === null && typeof eventResponseId === 'string') {
      addToast(t('event.responseToast'), {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  };

  return (
    <div>
      <OverflowSidebarContentWithTabs>
        <SidebarInputsWrapper>
          <TextInput
            width="100%"
            label={t('event.eventName')}
            name={FIELDS.TITLE}
            register={register}
            error={errors[FIELDS.TITLE]}
          />
          <SelectInputControlled
            width="100%"
            label={t('event.eventType')}
            options={eventTypesOptions}
            name={FIELDS.EVENT_TYPE_ID}
            control={control}
            maxMenuHeight={200}
            info={t('tips.eventType')}
            error={errors[FIELDS.EVENT_TYPE_ID]}
            setValue={setValue}
            watchFieldName={FIELDS.EVENT_TYPE_ID}
            formWatcher={watchedValues}
            eventTypeAndDefaultSetting={eventTypeAndDefaultSetting}
          />
          <TextInput
            width="100%"
            label={t('event.location')}
            name={FIELDS.LOCATION}
            register={register}
            error={errors[FIELDS.LOCATION]}
          />
          <DatePickerControlled
            label={t('common.startDate')}
            name={FIELDS.FROM}
            error={errors[FIELDS.FROM]}
            control={control}
          />
          {!watchedValues[FIELDS.ALL_DAY] && (
            <StyledTimePickersBlock>
              <TimePicker
                label={t('common.startTime')}
                width="100%"
                name={FIELDS.START_TIME}
                control={control}
                setValue={setValue}
                watchFieldName={FIELDS.END_TIME}
                formWatcher={watchedValues}
              />
              <TimePicker
                label={t('common.endTime')}
                width="100%"
                name={FIELDS.END_TIME}
                control={control}
                setValue={setValue}
                watchFieldName={FIELDS.START_TIME}
                formWatcher={watchedValues}
              />
            </StyledTimePickersBlock>
          )}
          <StyledInputsBlockEnd>
            {sidebarState === CreateEventStates.EVENT_CREATE && (
              <SelectInputControlled
                width="100%"
                control={control}
                maxMenuHeight={200}
                label={t('event.repeatAnEvent')}
                options={frequency.map((item) => ({
                  ...item,
                  label: t(`frequencies.${item.value}`),
                }))}
                name={FIELDS.REPEATED_RULES_OPTION}
              />
            )}
            <StyledCheckBoxBlock
              canUpdate={sidebarState !== CreateEventStates.EVENT_CREATE}
            >
              <CheckBox
                name={FIELDS.ALL_DAY}
                control={control}
                label={t('event.allDay')}
                labelStyles={{
                  fontSize: '12px',
                  color: '#8298AB',
                  fontWeight: 600,
                }}
              />
            </StyledCheckBoxBlock>
          </StyledInputsBlockEnd>
          {watchedValues[FIELDS.REPEATED_RULES_OPTION]?.value === 'other' ? (
            <StyledInputsBlockEnd>
              <TextInput
                width="100%"
                label={t('event.repeatWithInterval')}
                name={FIELDS.REPEATED_RULES_VALUE}
                type="number"
                min="0"
                register={register}
                error={errors[FIELDS.REPEATED_RULES_VALUE]}
              />
              <SelectInputControlled
                width="100%"
                label="&#xfeff;"
                maxMenuHeight={200}
                options={intervalTimeUnits}
                name={FIELDS.REPEATED_RULES_MODIFIER}
                control={control}
                error={errors[FIELDS.REPEATED_RULES_MODIFIER]}
              />
            </StyledInputsBlockEnd>
          ) : null}
          {watchedValues[FIELDS.REPEATED_RULES_OPTION]?.value === 'other' &&
          watchedValues[FIELDS.REPEATED_RULES_MODIFIER]?.value === 'week' ? (
            <StyledCheckButtonsBlock>
              <CheckButtonsGroup
                values={weekDaysFieldProps.value}
                onChange={(i) => {
                  weekDaysFieldProps.onChange([
                    ...weekDaysFieldProps.value.slice(0, i),
                    {
                      label: weekDaysFieldProps.value[i].label,
                      value: weekDaysFieldProps.value[i].value === 0 ? 1 : 0,
                    },
                    ...weekDaysFieldProps.value.slice(i + 1),
                  ]);
                }}
              />
            </StyledCheckButtonsBlock>
          ) : null}
          {watchedValues[FIELDS.REPEATED_RULES_OPTION]?.value !==
            RepeatEventFrequency.DO_NOT_REPEAT && (
            <DatePickerControlled
              label={t('common.endDate')}
              name={FIELDS.END_DATE}
              minDate={new Date()}
              error={errors[FIELDS.END_DATE]}
              control={control}
            />
          )}
          <StyledInputsBlockStart>
            <TextInput
              width="100%"
              label={t('event.registrationDeadline')}
              name={FIELDS.REGISTRATION_DEADLINE_VALUE}
              register={register}
              error={errors[FIELDS.REGISTRATION_DEADLINE_VALUE]}
              type="number"
              min="0"
            />
            <SelectInputControlled
              width="100%"
              label="&nbsp;"
              options={deadlineTimeUnits.map((i) => ({
                ...i,
                label: t(`event.${i.value}s`),
              }))}
              control={control}
              maxMenuHeight={220}
              name={FIELDS.REGISTRATION_DEADLINE_UNIT}
              error={errors[FIELDS.REGISTRATION_DEADLINE_UNIT]}
            />
          </StyledInputsBlockStart>
          <TextArea
            width="100%"
            height="136px"
            label={t('common.description')}
            name={FIELDS.DESCRIPTION}
            register={register}
            error={errors[FIELDS.DESCRIPTION]}
          />
          <ResponseWrapper>
            <StyledLabel>{t('event.dfRes')}</StyledLabel>
            <Responses>
              {responses.map((response) => (
                <StyledResponseButton key={response.id}>
                  <StyledResponseRadio
                    color={response.color}
                    name="responses"
                    type="radio"
                    id={response.id}
                    value={response.id}
                    checked={eventResponseFieldProps.value === response.id}
                    onClick={() => onResponseChange(response.id)}
                  />
                  <StyledResponseLabel
                    color={response.color}
                    htmlFor={response.id}
                  >
                    {response.title}
                    {response.isCommentRequired && <CommentIcon />}
                  </StyledResponseLabel>
                </StyledResponseButton>
              ))}
            </Responses>
          </ResponseWrapper>
        </SidebarInputsWrapper>
      </OverflowSidebarContentWithTabs>
    </div>
  );
};

export default CreateEventEvent;
