import React, { FC, useRef, useMemo } from 'react';
import { useLayer, useHover, Arrow, mergeRefs, Placement } from 'react-laag';
import { AnimatePresence } from 'framer-motion';

import { StyledTooltip } from './styled';

interface TooltipProps {
  children: JSX.Element | JSX.Element[] | string | number;
  text: JSX.Element | JSX.Element[] | string;
  background?: string;
  border?: string;
  isVisible?: boolean;
  position?: Placement;
  tooltipStyle?: { [key: string]: string | number };
  offset?: number;
  opacity?: string;
  zoom?: number;
  visibleAfterWidth?: number;
  visibleAfterHeight?: number;
  auto?: boolean;
  isHover?: boolean;
  noArrow?: boolean;
  closeTooltip?: () => void;
  adjustToolTipPosition?: { [key: string]: string | number };
}

const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  background = '#304659',
  border,
  isVisible,
  position,
  tooltipStyle = {},
  offset = 10,
  opacity = '0.7',
  zoom = 1,
  visibleAfterWidth,
  visibleAfterHeight,
  auto = true,
  isHover = true,
  noArrow,
  closeTooltip = () => {},
  adjustToolTipPosition,
}) => {
  const ref = useRef<HTMLDivElement>();

  const [isOver, hoverProps] = useHover({ delayEnter: 50, delayLeave: 50 });

  const isTooltipVisible = useMemo(() => {
    if (!visibleAfterWidth && !visibleAfterHeight) {
      return true;
    }

    let isVisible = false;

    if (ref?.current?.getBoundingClientRect) {
      if (
        (visibleAfterWidth &&
          ref.current.getBoundingClientRect().width >= visibleAfterWidth) ||
        (visibleAfterHeight &&
          ref.current.getBoundingClientRect().height >= visibleAfterHeight)
      ) {
        isVisible = true;
      }
    }

    return isVisible;
  }, [visibleAfterWidth, visibleAfterHeight, zoom, ref?.current]);

  const isOpen = useMemo(() => {
    if (isHover) {
      return isOver && isTooltipVisible;
    }

    return isVisible;
  }, [isHover, isVisible, isOver, isTooltipVisible]);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    placement: position,
    triggerOffset: offset,
    auto,
    possiblePlacements: [
      'top-center',
      'right-center',
      'left-center',
      'bottom-center',
    ],
    onOutsideClick: closeTooltip,
  });

  const trigger = useMemo(() => {
    if (['string', 'number'].includes(typeof children)) {
      return (
        <span
          {...triggerProps}
          {...(isHover ? hoverProps : {})}
          ref={mergeRefs(triggerProps.ref, ref)}
        >
          {children}
        </span>
      );
    } else {
      return React.cloneElement(children as JSX.Element, {
        ...triggerProps,
        ...(isHover ? hoverProps : {}),
        ref: mergeRefs(triggerProps.ref, ref),
      });
    }
  }, [children, triggerProps, isHover, hoverProps]);

  return (
    <>
      {trigger}
      {renderLayer(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <StyledTooltip
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{
                opacity: parseFloat(opacity),
                scale: 1,
                ...adjustToolTipPosition,
              }}
              exit={{ opacity: 0, scale: 0.9 }}
              background={background}
              border={border}
              {...layerProps}
              style={{
                ...tooltipStyle,
                ...layerProps.style,
              }}
              zoom={zoom}
            >
              {text}
              {!noArrow && (
                <Arrow
                  {...arrowProps}
                  backgroundColor={background}
                  borderColor={border}
                  size={5 * zoom}
                />
              )}
            </StyledTooltip>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};

export default Tooltip;
