import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isString } from 'lodash/fp';
import { FieldError } from 'react-hook-form';

import { invokeIfArgExists } from 'utils';
import { LogoUploadPlaceholder } from 'static';
import { SUPPORTED_FORMATS, FILE_SIZE } from 'constants/images';
import ImageBox from './ImageBox';
import { useInfoPopup } from 'hooks';

import {
  LabelWrapper,
  HiddenInput,
  ButtonsContainer,
  TextButton,
  TipText,
  DeleteImageButton,
  StyledDeleteIcon,
} from './styled';

const validImageType = (imageFile) =>
  SUPPORTED_FORMATS.includes(imageFile.type);
const validImageSize = (imageFile) =>
  Math.round(imageFile.size / 1024) < FILE_SIZE;
const getUrl = invokeIfArgExists((file) =>
  isString(file) ? file : URL.createObjectURL(file),
);

interface ImageInputProps {
  register: any;
  unregister: any;
  setValue: (name: string, value: string) => void;
  name: string;
  placeholder?: string;
  label?: string;
  defaultValue?: string;
  error?: FieldError;
}

const ImageInput: FC<ImageInputProps> = ({
  register = () => {},
  unregister = () => {},
  setValue = () => {},
  name,
  placeholder = LogoUploadPlaceholder,
  label,
  defaultValue,
  error,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(defaultValue || null);
  const inputRef = useRef<HTMLInputElement>();
  const { showInfoPopup } = useInfoPopup();
  const [fileSizeTitle, fileSizeMessage] = t('errors.fileSizeCustom', {
    size: 5,
  }).split(':');

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  useEffect(() => {
    setValue(name, image);
  }, [setValue, name, image]);

  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    inputRef.current.value = null;

    if (!validImageSize(imageFile)) {
      showInfoPopup({
        title: fileSizeTitle,
        message: fileSizeMessage,
        type: 'warning',
        loop: true,
      });
    }

    if (!imageFile) return false;
    if (!validImageType(imageFile) || !validImageSize(imageFile)) return false;

    setImage(imageFile);
    return true;
  };

  const handleDelete = () => {
    setImage(null);
  };

  const preventExtraClicks = useCallback((e) => {
    const itemName = e.target.getAttribute('name');
    if (itemName !== 'upload') {
      e.preventDefault();
    }
  }, []);

  return (
    <LabelWrapper>
      <ImageBox
        image={image}
        src={getUrl(image)}
        errorMessage={error && error.message}
        placeholder={placeholder}
      />
      <HiddenInput
        ref={inputRef}
        type="file"
        name={name}
        onChange={handleChange}
        accept={SUPPORTED_FORMATS.join(', ')}
      />
      <DeleteImageButton visible={!!image} type="button" onClick={handleDelete}>
        <StyledDeleteIcon />
      </DeleteImageButton>
      <ButtonsContainer onClick={preventExtraClicks}>
        <TextButton name="upload">
          {label || t('community.selectLogo')}
        </TextButton>
        <TipText>{t('community.selectLogoTip')}</TipText>
      </ButtonsContainer>
    </LabelWrapper>
  );
};

export default ImageInput;
