import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useEditUserRoleForm } from './form';
import { closeModal } from 'store/actionCreators/modal';
import { doChangeUserRole } from 'store/actionCreators/currentCommunity';
import { doChangeGroupUserRole } from 'store/actionCreators/currentGroup';
import { CommunityRoles, GroupRoles } from 'types';

export const useEditUserRole = (
  isForGroup: boolean,
  userId: string,
  communityId: string,
  groupId: string,
  role: CommunityRoles | GroupRoles,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { handleSubmit, ...form } = useEditUserRoleForm();

  const onSuccess = () => {
    dispatch(closeModal());
    addToast(t('communitySettings.editRoleSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = handleSubmit((data) => {
    isForGroup
      ? dispatch(
          doChangeGroupUserRole({
            data: {
              role: data.role.value,
            },
            communityId,
            groupId,
            userId,
            onSuccess,
          }),
        )
      : dispatch(
          doChangeUserRole({
            data: { role: data.role.value, oldRole: role },
            communityId,
            userId,
            onSuccess,
          }),
        );
  });

  return {
    onSubmit,
    ...form,
  };
};
