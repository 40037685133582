import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EventResponse } from 'types';

export enum FIELDS {
  TITLE = 'title',
  COLOR = 'color',
  IS_COMMENT_REQUIRED = 'isCommentRequired',
}

export type EditCommunityResponseFormData = {
  [FIELDS.TITLE]: string;
  [FIELDS.COLOR]: string;
  [FIELDS.IS_COMMENT_REQUIRED]: boolean;
};

export const useEditCommunityResponseForm = (eventResponse: EventResponse) => {
  const { t } = useTranslation();

  yup.setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<EditCommunityResponseFormData> = yupResolver(
    yup.object().shape({
      [FIELDS.TITLE]: yup.string().required().max(50).min(3).trim(),
      [FIELDS.COLOR]: yup.string().required().trim(),
    }),
  );

  return useForm<EditCommunityResponseFormData>({
    resolver,
    defaultValues: eventResponse || {
      [FIELDS.TITLE]: '',
      [FIELDS.IS_COMMENT_REQUIRED]: false,
    },
  });
};
