import React, { FC } from 'react';

import { BorderPlusIcon, BorderMinusIcon } from 'static';

import { StyledLabel } from '../styled';
import {
  StyledInputBox,
  StyledInput,
  StyledLeftIcon,
  StyledRightIcon,
  StyledInputWrapper,
} from './styled';

interface NumberInputProps {
  name?: string;
  disabled?: boolean;
  value: number;
  label?: string;
  width?: string;
  height?: string;
  color?: 'primary' | 'secondary' | 'danger';
  zoom?: number;
  onAdd?: (data: { name: string; value: number }) => void;
  onSubtract?: (data: { name: string; value: number }) => void;
  onAddNumber?: () => void;
  onSubtractNumber?: () => void;
}

const NumberInput: FC<NumberInputProps> = ({
  name,
  disabled,
  value,
  label,
  width = '120px',
  height = '45px',
  color = 'primary',
  zoom = 1,
  onAdd,
  onSubtract,
  onAddNumber,
  onSubtractNumber,
}) => {
  const add = () => {
    if (onAddNumber) {
      onAddNumber();
    } else {
      onAdd({ name, value });
    }
  };

  const subtract = () => {
    if (onSubtractNumber) {
      onSubtractNumber();
    } else {
      onSubtract({ name, value });
    }
  };

  return (
    <StyledInputWrapper>
      {label && (
        <StyledLabel htmlFor={name} zoom={zoom}>
          {label}
        </StyledLabel>
      )}
      <StyledInputBox width={width} height={height} zoom={zoom}>
        <StyledLeftIcon
          src={BorderMinusIcon}
          disabled={disabled}
          onClick={subtract}
          color={color}
          alt=""
          zoom={zoom}
        />
        <StyledInput
          id={name}
          name={name}
          type="number"
          height={height}
          width={width}
          color={color}
          value={value}
          disabled={disabled}
          zoom={zoom}
        />
        <StyledRightIcon
          src={BorderPlusIcon}
          disabled={disabled}
          onClick={add}
          color={color}
          alt=""
          zoom={zoom}
        />
      </StyledInputBox>
    </StyledInputWrapper>
  );
};

export default NumberInput;
