import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { taskIconsOptions } from 'configs';
import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  SidebarInputsWrapper,
  ColorInputBlock,
  InputsBlock,
  IconSelectInput,
} from 'components';
import SidebarFooter from '../common/SidebarFooter';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useEditCommunityTask } from './hooks';
import { FIELDS } from './form';
import { getEventTaskLoading } from 'store/selectors/eventTasks';
import { getData } from 'store/selectors/modal';
import { closeModal } from 'store/actionCreators/modal';
import { EventTask } from 'types';

const EditCommunityTask = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getEventTaskLoading);
  const eventTask = useSelector(getData) as EventTask;

  const handleSidebarClose = () => {
    dispatch(closeModal());
  };

  const { register, errors, onSubmit, setValue } =
    useEditCommunityTask(eventTask);

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={eventTask ? t('event.editTask') : t('event.createTask')}
          onCloseClick={handleSidebarClose}
        />
        <SidebarContent height={'calc(100svh - 108px)'}>
          <SidebarInputsWrapper>
            <InputsBlock>
              <TextInput
                name={FIELDS.TITLE}
                register={register}
                label={t('event.eventTaskName')}
                width="225px"
                error={errors[FIELDS.TITLE]}
              />
              <ColorInputBlock>
                <IconSelectInput
                  defaultValue={eventTask?.icon || 'eye'}
                  width="80px"
                  label={t('event.icon')}
                  name={FIELDS.ICON}
                  setValue={setValue}
                  register={register}
                  options={taskIconsOptions}
                  error={errors[FIELDS.ICON]}
                />
              </ColorInputBlock>
            </InputsBlock>
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter isLoading={isLoading} isDeleteButtonVisible={false} />
      </form>
    </Sidebar>
  );
};

export default EditCommunityTask;
