import { useForm } from 'react-hook-form';
import { toPairs, map } from 'lodash/fp';

export const useHandleRequest = (
  approveRequests,
  rejectRequests,
  communityId,
) => {
  const { handleSubmit, ...form } = useForm();

  const onSubmit = (type: 'approve' | 'reject') =>
    handleSubmit((formData) => {
      const data = map(
        ([key, value]) => (value ? key : null),
        toPairs(formData),
      ).filter((id) => !!id);

      if (data?.length === 0) {
        return;
      }

      switch (type) {
        case 'approve':
          approveRequests({ communityId, data });
          break;
        case 'reject':
          rejectRequests({ communityId, data });
          break;
        default:
          break;
      }
    });

  return {
    onSubmit,
    ...form,
  };
};
