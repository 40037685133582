import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const appleFonts = css`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Open Sans',
    sans-serif;
`;

export const PhoneNotificationWrapper = styled(motion.div)`
  width: ${({ width = 'auto' }) => width};
  min-height: 60px;
  /* height: ${({ isExpanded, hideBackground }) =>
    isExpanded ? (hideBackground ? 'auto' : '240px') : '60px'}; */
  /* border: 1px solid ${({ theme }) => theme.color.white}; */
  border-radius: 20px;
  /* transition: height 0.3s ease; */
  /* background-color: ${({ theme }) => theme.color.closeToTimberBrown};
  background-color: #1b1c1e;
  background-color: #696969; */
  background-color: ${({ theme, templateView }) =>
    !templateView ? '#434545' : ''};
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isExpanded, templateView }) =>
    isExpanded ? (templateView ? 'center' : 'flex-start') : 'center'};
  opacity: 0.9;
  overflow-y: hidden;
  box-shadow: 1px 1px 3px 0.1px ${({ theme }) => theme.color.darkGrey};
  ${({ templateView, isExpanded }) =>
    templateView &&
    css`
      height: ${isExpanded ? '160px' : '60px'};
      /* height: 200px; */
      padding: 10px 4px;
    `}
`;

export const ImageContainer = styled(motion.div)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  object-fit: cover;
  padding-top: 8px;

  ${({ isAppIcon, theme }) =>
    isAppIcon &&
    css`
      padding: 4px;
      border-radius: 50%;
      border: 4px solid ${theme.color.koromiko};
    `}
  & > img {
    object-fit: cover;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 4px;
  }
  ${({ rightSideImage, backgroundImage, templateView }) =>
    rightSideImage
      ? css`
          position: absolute;
          right: 36px;
          top: 8px;
        `
      : backgroundImage &&
        css`
          position: absolute;
          bottom: 0;
          z-index: -1;
        `}
  align-self: ${({ alignSelf = 'center', isExpanded }) => alignSelf};
`;

export const BackGroundImage = styled(motion.div)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  & > img {
    object-fit: cover;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 4px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  align-items: center;
  padding-right: ${({ templateView }) => (templateView ? '36px' : '30px')};
  /* margin-bottom: 20px; */
  background-color: ${({ theme, templateView }) =>
    !templateView ? '#434545' : ''};
  padding-bottom: 4px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: ${({ isExpanded, templateView }) =>
    isExpanded
      ? 'calc(100% - 24px)'
      : templateView
      ? 'calc(100% - 24px)'
      : 'calc(100% - 30px - 8px - 30px)'};
  background-color: ${({ theme, templateView }) =>
    !templateView ? '#434545' : ''};
  color: ${({ templateView, theme }) =>
    templateView ? theme.color.black : theme.color.white};
`;

export const ExpandButtonContainer = styled(motion.div)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.black};
  padding: 4px;
  position: absolute;
  right: ${({ templateView }) => (templateView ? '6px' : '12px')};
  top: 12px;
  cursor: pointer;
`;

export const ExpandButton = styled.div`
  background: transparent;
  border-right: 1px solid ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.white};
  /* transform: rotateX('-90'); */
  width: 8px;
  height: 8px;
`;

export const NotificationHeader = styled.div`
  white-space: ${({ isExpanded }) => (isExpanded ? 'pre-wrap' : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  /* color: ${({ theme }) => theme.color.white}; */
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
  line-height: ${({ lineHeight = 17 }) => lineHeight}px;
  max-height: ${({ lineHeight }) => lineHeight * 2}px;
  ${appleFonts}
`;

export const NotificationBody = styled.div`
  white-space: ${({ isExpanded }) => (isExpanded ? 'pre-wrap' : 'nowrap')};
  /* display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3; */
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ templateView }) => (templateView ? 'inherit' : '#ededed')};
  font-size: 12px;
  font-weight: 400;
  ${appleFonts}
`;
