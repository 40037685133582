import styled from 'styled-components';

import { ReturnIcon } from 'static';

import { defaultIconButtonStyles } from '../shared';

export const StyledIcon = styled(ReturnIcon)`
  user-select: none;
`;

export const StyledButton = styled.button`
  ${defaultIconButtonStyles};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${StyledIcon} {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;
