export const categories = [
  { value: 'Sport', label: 'Sport' },
  { value: 'Music', label: 'Music' },
  { value: 'Non-Profit', label: 'Non-Profit' },
  { value: 'Other', label: 'Other' },
  { value: 'Animal, Nature protection', label: 'Animal, Nature protection' },
  { value: 'Culture', label: 'Culture' },
  { value: 'Media', label: 'Media' },
  { value: 'Leisure, Hobby', label: 'Leisure, Hobby' },
  { value: 'Social', label: 'Social' },
  { value: 'Rescue, Emergency', label: 'Rescue, Emergency' },
  { value: 'Business', label: 'Business' },
];
