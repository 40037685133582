import { call, put, select, takeEvery } from 'redux-saga/effects';
import i18n from 'i18next';

import {
  requestDocumentsOverview,
  requestDocuments,
  requestDeleteDocument,
  requestPreSignedUrl,
  uploadFile,
} from 'services/documents';
import {
  doGetDocumentsOverview,
  doUploadDocument,
  doGetDocuments,
  doDeleteDocument,
} from 'store/actionCreators/currentCommunity';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import { get } from 'lodash/fp';
import {
  selectCommunitySubscriptionPending,
  selectCurrentCommunitySubscription,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { getInfo, isCurrentUserOwner } from 'store/selectors/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { POPUP_MESSAGE } from 'constants/popups';
import { checkSubscription } from '../helper';

export function* onGetDocumentsOverview({
  payload,
}: ReturnType<typeof doGetDocumentsOverview>) {
  try {
    const data = yield call(requestDocumentsOverview, payload);

    yield put(doGetDocumentsOverview.success({ data, id: payload }));
  } catch ({ response }) {
    yield put(doGetDocumentsOverview.failure(response.data));
    yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onUploadDocument({
  payload,
}: ReturnType<typeof doUploadDocument>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { maxStorage } = yield select(selectCurrentCommunitySubscription);
    const { bucketSize } = yield select(getInfo);
    if (bucketSize + 0.005 >= maxStorage) {
      yield put(doUploadDocument.failure());
      const isOwner = yield select(isCurrentUserOwner);
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('documentation.limitExceededHeader'),
            popupMessageText: i18n.t(
              isOwner
                ? 'subscriptions.limitExceededMessageOwner'
                : 'subscriptions.limitExceededMessage',
            ),
            popupButtonText: 'Ok',
          },
        }),
      );
    } else {
      const {
        communityId,
        groupId,
        data: { document },
      } = payload;

      const data = yield call(
        requestPreSignedUrl,
        document.name,
        document.type,
        document.size / 1000000000,
        document.name.split('.').pop(),
        communityId,
        groupId === 'general' ? null : groupId,
      );

      yield call(uploadFile, data.presignedUrl, document, document.type);
      yield put(doUploadDocument.success(data.document));
      yield call(payload.onSuccess);
    }
  } catch ({ response }) {
    yield put(doUploadDocument.failure(response.data));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onGetDocuments({
  payload,
}: ReturnType<typeof doGetDocuments>) {
  try {
    const { data } = yield call(requestDocuments, payload);

    yield put(doGetDocuments.success({ data, payload }));
  } catch ({ response }) {
    yield put(doGetDocuments.failure(response.data));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onDeleteDocument({
  payload,
}: ReturnType<typeof doDeleteDocument>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, documentId } = payload;
    const data = yield call(requestDeleteDocument, communityId, documentId);
    yield put(doDeleteDocument.success({ data, documentId }));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doDeleteDocument.failure(response.data));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export default function* () {
  yield takeEvery(doGetDocumentsOverview, onGetDocumentsOverview);
  yield takeEvery(doUploadDocument, onUploadDocument);
  yield takeEvery(doGetDocuments, onGetDocuments);
  yield takeEvery(doDeleteDocument, onDeleteDocument);
}
