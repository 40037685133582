import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';

import {
  doGetAppointmentScheduleStatistics,
  doGetAppointmentScheduleStatisticsTotal,
  doGetMemberFiguresStatistics,
  doGetMemberFiguresStatisticsTotal,
} from 'store/actionCreators/statistics';
import {
  getAppointmentScheduleStatistics,
  getAppointmentScheduleStatisticsTotal,
  getMemberFiguresStatistics,
  getMemberFiguresStatisticsTotal,
} from 'services/statistics';
import { sliceIntoChunks } from '../../components/OverallScheduleStatistics/utils';

export function* onGetAppointmentScheduleStatistics({
  payload,
}: ReturnType<typeof doGetAppointmentScheduleStatistics>) {
  try {
    const data = yield call(getAppointmentScheduleStatistics, payload);
    yield put(doGetAppointmentScheduleStatistics.success(data));
  } catch (e) {
    yield put(doGetAppointmentScheduleStatistics.failure());
  }
}

export function* onGetAppointmentScheduleStatisticsTotal({
  payload,
}: ReturnType<typeof doGetAppointmentScheduleStatisticsTotal>) {
  try {
    const data = yield call(getAppointmentScheduleStatisticsTotal, payload);
    yield put(doGetAppointmentScheduleStatisticsTotal.success(data));
  } catch (e) {
    yield put(doGetAppointmentScheduleStatisticsTotal.failure());
  }
}

export function* onGetMemberFiguresStatistics({
  payload,
}: ReturnType<typeof doGetMemberFiguresStatistics>) {
  try {
    const data = yield call(getMemberFiguresStatistics, payload);
    yield put(doGetMemberFiguresStatistics.success(data));
  } catch (e) {
    yield put(doGetMemberFiguresStatistics.failure());
  }
}

export function* onGetMemberFiguresStatisticsTotal({
  payload,
}: ReturnType<typeof doGetMemberFiguresStatisticsTotal>) {
  try {
    const slicedMembers = sliceIntoChunks(payload.memberIds || []);

    for (let i = 0; i < slicedMembers.length; i++) {
      const data = yield call(getMemberFiguresStatisticsTotal, {
        ...payload,
        memberIds: slicedMembers[i],
      });

      yield put(
        doGetMemberFiguresStatisticsTotal.success({
          data,
          loaded: i === slicedMembers.length - 1,
        }),
      );
    }
  } catch (e) {
    yield put(doGetMemberFiguresStatisticsTotal.failure());
  }
}

export default function* () {
  yield takeEvery(
    doGetAppointmentScheduleStatistics,
    onGetAppointmentScheduleStatistics,
  );
  yield takeEvery(
    doGetAppointmentScheduleStatisticsTotal,
    onGetAppointmentScheduleStatisticsTotal,
  );
  yield takeEvery(doGetMemberFiguresStatistics, onGetMemberFiguresStatistics);
  yield takeLatest(
    doGetMemberFiguresStatisticsTotal,
    onGetMemberFiguresStatisticsTotal,
  );
}
