import { createRoutine } from 'redux-saga-routines';

import { GroupRoles } from 'types';

export const doGetGroupUsers = createRoutine('GET_GROUP_USERS');

export const doChangeGroupUserRole = createRoutine('CHANGE_GROUP_USER_ROLE', {
  trigger: (payload: {
    userId: string;
    communityId: string;
    groupId: string;
    data: { role: string; oldRole?: string };
    onSuccess: () => void;
    onFailure?: () => void;
  }) => payload,
});

export const doDeleteGroupUser = createRoutine('DELETE_GROUP_USER', {
  trigger: (payload: {
    communityId: string;
    groupId: string;
    users: string[];
    onSuccess: () => void;
  }) => payload,
});

export const doDeleteUserGroups = createRoutine('DELETE_USER_GROUPS');
export const doAddUserGroups = createRoutine('ADD_USER_GROUPS');

export const doRearrangeGroupUsers = createRoutine('REARRANGE_GROUP_USERS', {
  trigger: (payload: {
    groupId: string;
    start: number;
    end: number;
    prevRole: GroupRoles;
    newRole: GroupRoles;
    userId: string;
  }) => payload,
});
