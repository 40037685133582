import {
  getDateString,
  subtractDays,
  getTimeInterval,
  makeBinaryArrayFromInt,
  makeDaysObjectsArrayFromBinaryArray,
  makeDaysArrayFromDaysObjectsArray,
} from 'utils';
import { Event } from 'types';
import { getDateStringWithDay } from 'utils/dates';
import { useTranslation } from 'react-i18next';

const useDateDetails = (
  event: Event,
  date: Date,
  timeFormat: 12 | 24,
): {
  dateInterval: string;
  repeatString: string;
  dateStringWithDay?: string;
  timeInterval?: string;
} => {
  const { t } = useTranslation();
  if (!event) {
    return { dateInterval: '', repeatString: '' };
  }

  const selectedDate = new Date(date);
  const dateFrom = new Date(event.from);
  const dateTo = new Date(event.to);
  const endDate = event.endDate && new Date(event.endDate);
  const intervalStep = event.repeatedRules && event.repeatedRules.intervalStep;
  const perWeek = event.repeatedRules && event.repeatedRules.perWeek;
  const invetrvalModificator = event.repeatedRules?.invetrvalModificator;
  const dateInterval = `${getDateString(selectedDate)} ${
    !event.allDay ? getTimeInterval(dateFrom, dateTo, timeFormat) : ''
  }`;

  const dateStringWithDay = `${getDateStringWithDay(selectedDate, t)}`;
  const timeInterval = `${
    !event.allDay ? getTimeInterval(dateFrom, dateTo, timeFormat) : ''
  }`;

  const weekDays = (perWeekInt) =>
    makeDaysArrayFromDaysObjectsArray(
      makeDaysObjectsArrayFromBinaryArray(makeBinaryArrayFromInt(perWeekInt)),
    ).join(', ');

  const repeatString = `Every ${intervalStep} ${invetrvalModificator}(s) \
      ${perWeek ? 'on' : ''} ${perWeek ? weekDays(perWeek) : ''} \
      ${endDate ? 'till' : ''} ${
    endDate ? getDateString(subtractDays(endDate, 1)) : ''
  }`;

  return { dateInterval, repeatString, dateStringWithDay, timeInterval };
};

export default useDateDetails;
