import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useAllowedToEditForm, FIELDS } from './form';
import { editProfile } from 'store/actionCreators/profile';
import { getFullAuthUser } from 'store/selectors/auth';

export const useChangeAllowedToEdit = (
  defaultValues: { value: string; label: string }[],
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const user = useSelector(getFullAuthUser);
  const { handleSubmit, setValue, ...form } =
    useAllowedToEditForm(defaultValues);

  const onSubmit = handleSubmit(async (values) => {
    dispatch(
      editProfile({
        data: {
          [FIELDS.ALLOWED_TO_EDIT]: JSON.stringify(
            values[FIELDS.ALLOWED_TO_EDIT].map((i) => i.value),
          ),
        },
        id: user.id,
        onSuccess: () => {
          addToast(t('profile.editSuccess'), {
            appearance: 'success',
            autoDismiss: true,
          });
        },
        onFailure: () => {
          addToast(t('errors.text500'), {
            appearance: 'error',
            autoDismiss: true,
          });
        },
      }),
    );
  });

  return {
    onSubmit,
    setValue,
    ...form,
  };
};
