// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { handleActions } from 'redux-actions';
import { assoc, compose } from 'lodash/fp';
import { openModal, closeModal, editForm } from 'store/actionCreators/modal';
import {
  EventDetailsSidebarState,
  GiveReplySidebarState,
  CreateEventSidebarState,
  EditCommunityEventTypeState,
  EditCommunityEventResponseState,
  EditCommunityTaskState,
  AddGroupMembersState,
  EditUserRoleState,
  AddFieldState,
  SharePopupState,
  ViewMemberState,
  TaskDetailsState,
  CreateChatState,
} from 'types';

export type ModalState =
  | EventDetailsSidebarState
  | GiveReplySidebarState
  | CreateEventSidebarState
  | EditCommunityEventTypeState
  | EditCommunityEventResponseState
  | EditCommunityTaskState
  | AddGroupMembersState
  | EditUserRoleState
  | AddFieldState
  | SharePopupState
  | ViewMemberState
  | TaskDetailsState
  | CreateChatState;

const initialState: ModalState = {
  type: null,
  data: null,
  isOpen: false,
  placement: null,
};

export default handleActions<ModalState, any>(
  {
    [openModal.TRIGGER]: (state, { payload }) =>
      compose(
        assoc(['isOpen'], true),
        assoc(['data'], payload.data),
        assoc(['placement'], payload.placement),
        assoc(['type'], payload.type),
      )(state),
    [closeModal.TRIGGER]: (state) =>
      compose(assoc(['isOpen'], false), assoc(['type'], ''))(state),
    [editForm.TRIGGER]: (state, { payload }) =>
      compose(assoc(['edit'], payload))(state),
  },
  initialState,
);
