import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { doChangeProfileSettings } from 'store/actionCreators/profile';

export const useSettings = (userId: string) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const onSubmit = handleSubmit((values) => {
    dispatch(
      doChangeProfileSettings({
        userId,
        data: values,
        i18n,
      }),
    );
  });

  return {
    onSubmit,
    register,
  };
};
