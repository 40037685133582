export enum PHONES {
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  OFFICE_PHONE_NUMBER = 'officePhoneNumber',
  PRIVATE_PHONE_NUMBER = 'privatePhoneNumber',
}

export const phoneData = [
  'PRIVATE_PHONE_NUMBER',
  'OFFICE_PHONE_NUMBER',
  'MOBILE_PHONE_NUMBER',
];
