import React from 'react';
import { func, string, instanceOf, shape, number } from 'prop-types';

import { noop } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { CalendarIcon } from 'static';

import { StyledLabel, StyledErrorMessage } from '../../styled';

import { StyledPickerWrapper, StyledDatePicker } from './styled';

import { SharedStyledDateIcon } from '../styled';

const DatePicker = ({
  name,
  date,
  handleChange,
  label,
  minDate,
  maxDate,
  error,
  popperModifiers = {},
}) => {
  const { t } = useTranslation();
  const CalendarContainer = ({ className, children }) => {
    return (
      <div
        style={{
          height: '100%',
          padding: '16px',
          backgroundColor: '#ffffff',
          color: '#304659',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          transition: 'all',
          gap: '8px',
          boxShadow: '0px 0px 8px 2px #cfcfcf',
        }}
      >
        <div
          className={className}
          style={{
            borderRadius: '8px',
            borderColor: '#ffffff',
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  return (
    <>
      <StyledPickerWrapper>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>

        <StyledDatePicker
          id={name || false}
          selected={date}
          dateFormat="dd.MM.yyyy"
          minDate={minDate}
          maxDate={maxDate}
          error={error}
          popperPlacement="bottom-left"
          popperModifiers={popperModifiers}
          onChange={handleChange}
          calendarContainer={CalendarContainer}
        />

        <SharedStyledDateIcon src={CalendarIcon} alt="" />
      </StyledPickerWrapper>

      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </>
  );
};

DatePicker.propTypes = {
  name: string,
  date: instanceOf(Date) || number,
  minDate: instanceOf(Date),
  maxDate: instanceOf(Date),
  handleChange: func,
  label: string,
  error: shape({}),
};

DatePicker.defaultProps = {
  date: new Date(),
  minDate: null,
  maxDate: null,
  handleChange: noop,
  label: '',
  error: null,
  name: '',
};

export default DatePicker;
