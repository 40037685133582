import React, { FC } from 'react';

import {
  StyledButtonGroup,
  StyledButton,
  StyledLabel,
  StyledCheckbox,
} from './styled';

interface CheckButtonsGroupProps {
  values: { label: string; value: string | number }[];
  onChange: (ind: number) => void;
}

const CheckButtonsGroup: FC<CheckButtonsGroupProps> = ({
  values,
  onChange,
}) => {
  return (
    <StyledButtonGroup>
      {values.map((v, index) => (
        <StyledButton key={v.label}>
          <StyledCheckbox
            name={v.label}
            type="checkbox"
            id={v.label}
            checked={v.value === 1}
            onChange={() => onChange(index)}
          />
          <StyledLabel htmlFor={v.label}>{v.label}</StyledLabel>
        </StyledButton>
      ))}
    </StyledButtonGroup>
  );
};

export default CheckButtonsGroup;
