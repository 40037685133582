import styled from 'styled-components';

import { Button } from 'components';
import { breakpoints } from 'theme';

export const StyledAddMembersButton = styled(Button)`
  margin: 0 0 20px 25px;
  @media (max-width: 400px) {
    width: 100% !important;
    margin: 0px 0px 20px 0px;
  }
`;
export const BtnWarpper = styled.div`
  display: flex;
  @media (max-width: 400px) {
    padding: 0px 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
`;

export const StyledTableWrapper = styled.div`
  height: calc(
    100svh -
      ${({ headHeight, haveBtn }) =>
        headHeight + 27 + 20 + 40 + (haveBtn ? 0 : 60)}px
  );
  overflow-y: scroll;
  overflow-x: auto;

  @media ${breakpoints.downSmPlus} {
    height: calc(
      100svh -
        ${({ headHeight, haveBtn }) =>
          headHeight + 27 + 20 + 40 + (haveBtn ? 0 : 60)}px
    );
  }
`;
