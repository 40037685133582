import { CommunityPermission } from './community';

export enum ProfileSocialLinkType {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
}

export enum ProfilePhoneType {
  PRIVATE_NUMBER = 'privatePhoneNumber',
  OFFICE_NUMBER = 'officePhoneNumber',
  MOBILE_NUMBER = 'mobilePhoneNumber',
}

export enum ProfileLanguage {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  IT = 'it',
  FR = 'fr',
  RU = 'ru',
}

export type ProfileSocialLink = {
  label: ProfileSocialLinkType;
  value: string;
};

export type ProfilePhone = {
  label: ProfilePhoneType;
  value: string;
};

export type Profile = {
  selectedFiled?: string;
  profileVisible: boolean;
  id: string;
  email: string;
  description: string | null;
  links: ProfileSocialLink[];
  dob: string | null;
  firstName: string | null;
  lastName: string | null;
  logo: string | null;
  smallLogo: string | null;
  profileName: string;
  street: string | null;
  region: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  phones: ProfilePhone[];
  timeZone: string | null;
  allowedToEdit: string[];
  trialStarted: boolean;
  isDST: boolean;
  DSTUsed: boolean;
  userId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type ProfileSettings = {
  id: string;
  userId: string;
  communityIdToCalendarId: { [key: string]: string };
  appleCalendarUrl: { communityId: string; url: string }[];
  emailNotification: boolean;
  inAppNotification: boolean;
  language: ProfileLanguage;
  pushNotification: boolean;
  syncEventsGoogleFromCommunities: string[];
  syncEventsAppleFromCommunities: string[] | null;
  timeFormat: 24 | 12;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export enum IN_APP_NOTIFICATION_TYPE {
  EVENT = 'event',
  COMMUNITY = 'community',
  SUBSCRIPTION = 'subscription',
  MESSAGE = 'message',
}

export type UserNotificationItem = {
  id: string;
  userId: string;
  user: User;
  isRead: boolean;
  message: string;
  link: string | null;
  image: string | null;
  type: IN_APP_NOTIFICATION_TYPE | null;
  communityId: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type UserNotification = {
  count: number;
  data: UserNotificationItem[];
  unreadCount: number;
};

export type User = {
  id: string;
  sub: string;
  connectionIds: string[];
  role: string;
  deviceIds: string[];
  googleCalendarRefreshToken: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type UserWithProfile = User & { profile: Profile };

export type FullUser = UserWithProfile & {
  settings: ProfileSettings;
  notifications: UserNotification;
  communities: {
    id: string;
    communityPermission: CommunityPermission;
  }[];
  idToken: string;
};
