import styled from 'styled-components';
import { DeleteIcon } from 'static';

export const CoverContainer = styled.div`
  display: inherit;
`;

export const StyledWrapper = styled.label`
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme.color.brightTurquoise};
  border-radius: 8px;
  opacity: 60%;
  cursor: pointer;
  z-index: -1;
  box-sizing: content-box;

  &:hover {
    opacity: 100%;

    & > div > div > span:first-of-type {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  height: 13px;
  width: 11px;
`;

export const DeleteImageButton = styled.button`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: 25px;
  width: 25px;
  position: relative;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.color.dew};
  border-radius: 50%;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.15);
  bottom: 10px;
  cursor: pointer;

  &:hover ${StyledDeleteIcon} {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;
