import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  doGetPushTemplates,
  doCreatePushTemplate,
  doDeletePushTemplate,
  doSendPushNotification,
} from 'store/actionCreators/currentCommunity';

import {
  getTemplates,
  deleteTemplates,
  saveTemplate,
  sendPushNotification,
  saveDefaultTemplates,
} from 'services/push_notification';

export function* onGetPushTemplate({
  payload,
}: ReturnType<typeof doGetPushTemplates>) {
  const { communityId, userId, defaultTemp } = payload;
  try {
    let templateList = yield call(getTemplates, { communityId, userId });
    if (templateList.length <= 0) {
      templateList = yield call(saveDefaultTemplates, { defaultTemp });
      templateList?.reverse();
    }
    yield put(doGetPushTemplates.success(templateList));
  } catch (err) {
    //
  }
}

function* onCreatePushTemplate({
  payload,
}: ReturnType<typeof doCreatePushTemplate>) {
  const { userId, communityId, title, message, onSuccess } = payload;
  try {
    const template = yield call(saveTemplate, {
      userId,
      communityId,
      title,
      message,
    });
    yield put(
      doCreatePushTemplate.success({
        communityId: template.communityId,
        createdAt: template.createdAt,
        id: template.id,
        isTemplate: template.isTemplate,
        userId: template.userId,
        messageTemplate: template.messageTemplate,
      }),
    );
    yield onSuccess();
  } catch (err) {
    //
  }
}

function* onDeletePushTemplate({
  payload,
}: ReturnType<typeof doDeletePushTemplate>) {
  const { id, userId, communityId } = payload;
  try {
    const response = yield call(deleteTemplates, { id, communityId, userId });
    yield put(doDeletePushTemplate.success({ id: response.id }));
    yield payload.onSuccess();
  } catch (err) {
    //
  }
}

function* onSendPushNotification({
  payload,
}: ReturnType<typeof doSendPushNotification>) {
  const {
    data: {
      title,
      message,
      communityId,
      recipients,
      redirectLink,
      sendCopyToMe,
      userId,
      withImage,
      eventId,
    },
    onSuccess,
  } = payload;
  const formData = { title, message, sendCopyToMe, recipients, redirectLink };
  try {
    const lastUsedTemplate = yield call(sendPushNotification, {
      data: formData,
      communityId,
      userId,
      withImage,
      eventId,
    });
    yield call(onSuccess);
    // using some data below from payload.
    yield put(
      doSendPushNotification.success({
        communityId,
        id: lastUsedTemplate.id,
        createdAt: lastUsedTemplate.createdAt,
        isTemplate: lastUsedTemplate.isTemplate,
        userId,
        messageTemplate: { title, message, redirectLink },
      }),
    );
  } catch (err) {
    //
  }
}

export default function* () {
  yield takeLatest(doDeletePushTemplate, onDeletePushTemplate);
  yield takeLatest(doGetPushTemplates, onGetPushTemplate);
  yield takeLatest(doCreatePushTemplate, onCreatePushTemplate);
  yield takeEvery(doSendPushNotification, onSendPushNotification);
}
