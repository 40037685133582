import { default as axiosInstance } from 'axios';

import {
  axiosInstance as axios,
  axiosFormInstance as axiosForm,
} from './index';
import {
  CommunityNotifications,
  CommunityRoles,
  CommunitySocialLink,
  EventResponse,
  EventTask,
  EventType,
  GroupRoles,
  CommunityCoverDetails,
} from 'types';

export const requestCommunities = async (data?: {
  communityId: string;
  invitationCode?: string;
}) => {
  const response = await axios.get(
    `/communities/${data?.communityId || ''}${
      data?.communityId ? '?new=true' : ''
    }${
      data?.communityId && data?.invitationCode
        ? `&invitationCode=${data.invitationCode}`
        : ''
    }`,
  );
  return response.data;
};

export const requestCommunityMatrix = async () => {
  const response = await axios.get('communities/communityMatrix');
  return response.data;
};

export const createCommunity = async (data: {
  name: string;
  category: string;
  logo: string;
  responses: EventResponse[];
  eventTypes: EventType[];
  tasks: EventTask[];
}) => {
  const formData = await new FormData();
  await formData.append('name', data.name);
  await formData.append('category', data.category);
  await formData.append('responses', JSON.stringify(data.responses));
  await formData.append('eventTypes', JSON.stringify(data.eventTypes));
  await formData.append('tasks', JSON.stringify(data.tasks));
  if (typeof data.logo === 'object' && data.logo !== null) {
    await formData.append('logo', data.logo);
  }
  const response = await axiosForm.post('/communities', formData);
  return response.data;
};

export const updateCommunity = async (
  id: string,
  data: {
    category: string;
    name?: string;
    description?: string;
    logo?: string;
    cover?: string;
    links?: CommunitySocialLink[];
    notifications?: CommunityNotifications;
    coverDetail?: CommunityCoverDetails;
  },
) => {
  const formData = await new FormData();

  formData.append('category', data.category);

  if (data.name) {
    formData.append('name', data.name);
  }

  if (data.description) {
    formData.append('description', data.description);
  } else {
    formData.append('description', '');
  }

  if (typeof data.logo === 'object' && data.logo !== null) {
    formData.append('logo', data.logo);
  } else if (data.logo === null) {
    formData.append('logo', '');
  }

  if (data.cover === null) {
    formData.append('cover', '');
  }

  if (data.links) {
    formData.append('links', JSON.stringify(data.links || []));
  }

  if (data.notifications) {
    formData.append('notifications', JSON.stringify(data.notifications));
  }

  if (data.coverDetail) {
    formData.append('coverDetail', JSON.stringify(data.coverDetail));
  }

  const response = await axiosForm.put(`/communitiesNew/${id}`, formData);
  const presignedUrl = response?.data?.presignedUrl;
  !!presignedUrl && (await axiosInstance.put(presignedUrl, data.cover));

  return response.data;
};

export const deleteCommunity = async (id: string) => {
  const response = await axios.delete(`/communities/${id}`);
  return response.data;
};

export const getCommunityLanding = async (id: string) => {
  const response = await axios.get(`/communities/${id}/landing`);
  return response.data;
};

export const getOverallScheduler = async (
  communityId: string,
  from: Date,
  to: Date,
  eventTypeId: string,
) => {
  const response = await axios.get(
    `/communities/${communityId}/scheduler?from=${from.toISOString()}&to=${to.toISOString()}${
      eventTypeId && eventTypeId !== 'all' ? '&eventTypeId=' + eventTypeId : ''
    }&new=true`,
  );
  return response.data;
};

export const getCommunityTotal = async (
  communityId: string,
  from: Date = new Date(),
  to: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate() + 7,
  ),
) => {
  const fromToRequest = new Date(
    Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()),
  );
  const toToRequest = new Date(
    Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1),
  );
  const response = await axios.get(
    `communities/${communityId}/total?from=${fromToRequest.toISOString()}&to=${toToRequest.toISOString()}`,
  );
  return response.data;
};

export const getCommunityMembersByGroups = async (
  communityId: string,
  withoutBlocked: boolean,
) => {
  const response = await axios.get(
    `/communities/${communityId}/groupsData${
      withoutBlocked ? '?withoutBlocked=true' : ''
    }`,
  );
  return response.data;
};

export const getCommunityUserGroups = async (
  communityId: string,
  userIds: string[],
) => {
  const response = await axios.post(`/communities/${communityId}/users/`, {
    userIds,
  });
  return response.data;
};

export const getOverallScheduleStatistic = async ({
  id,
  fromDate,
  toDate,
  eventTypeId,
}: {
  id: string;
  fromDate: Date;
  toDate: Date;
  eventTypeId: string;
}) => {
  const fromToRequest = new Date(
    Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()),
  );
  const toToRequest = new Date(
    Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() + 1),
  );
  const response = await axios.get(
    `communities/${id}/statistic?from=${fromToRequest.toISOString()}&to=${toToRequest.toISOString()}${
      eventTypeId ? `&eventTypeId=${eventTypeId}` : ''
    }`,
  );
  return response.data;
};

export const checkIfUserExists = (email) => {
  return axios.get(`users/checkIfExists?email=${email}`);
};

export const addUserToCommunity = async (
  communityId: string,
  userId: string,
  role: CommunityRoles,
  groups: { id: string; role: GroupRoles }[],
) => {
  return axios.put(
    `communities/${communityId}/users${role ? `?role=${role}` : ''}`,
    { users: [userId], groups },
  );
};

export const renewCommunityInvitationCode = async (communityId: string) => {
  return axios.get(`communities/${communityId}/putCommunityInvitationCode`);
};
