import React, { memo, useMemo } from 'react';
import { isString } from 'lodash/fp';

import { EventGridItem } from 'types';

import { StyledStickyCell, StyledTaskIcon, StyledHighlight } from './styled';
import { modalSelector } from 'store/selectors/modal';
import { useSelector } from 'react-redux';

interface StickyProps {
  style: { [key: string]: string | number };
  zoom: number;
  isCurrentUser: boolean;
  data: EventGridItem;
  statisticsTab?: boolean;
  divide?: 'left' | 'right';
  userId?: string;
}

const Sticky = memo<StickyProps>(
  ({ style, data, zoom, isCurrentUser, statisticsTab, divide, userId }) => {
    const { icon, label, onClick } = data || {};
    const { data: communityData, isOpen: communityModalOpen } =
      useSelector(modalSelector);

    const cellContent = useMemo(() => {
      if (!icon) {
        return null;
      }

      if (isString(icon)) {
        return (
          <img
            className="grid-sticky-user-ava"
            src={icon as string}
            alt="avatar"
            style={{
              width: 30 * zoom,
              height: 30 * zoom,
              minWidth: 30 * zoom,
              margin: '0 ' + 20 * zoom + 'px',
            }}
          />
        );
      }

      return (
        <StyledTaskIcon zoom={zoom} statisticsTab={statisticsTab}>
          {icon}
        </StyledTaskIcon>
      );
    }, [icon, zoom]);

    return (
      <div style={style}>
        {!!isCurrentUser && <StyledHighlight zoom={zoom} />}
        {communityModalOpen &&
          communityData?.['userId']?.toString() === userId?.toString() && (
            <StyledHighlight zoom={zoom} size={5} />
          )}
        <StyledStickyCell
          clickable={!!onClick}
          zoom={zoom}
          divide={divide}
          isCurrentUser={isCurrentUser}
          selectedUser={
            communityModalOpen &&
            communityData?.['userId']?.toString() === userId?.toString()
          }
          onClick={onClick ? onClick : () => {}}
        >
          {cellContent}
          {typeof label === 'string' ? (
            <div className="grid-sticky-label">{label}</div>
          ) : (
            label
          )}
        </StyledStickyCell>
      </div>
    );
  },
);

Sticky.displayName = 'Sticky';

export default Sticky;
