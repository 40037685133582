import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useLocalStorage } from 'react-use';

import {
  UserProfilePage,
  UserProfileSettingsPage,
  UserProfileCalendarsPage,
  UserProfileSubscriptionPage,
  UserProfileCommunitiesPage,
  NotificationsPage,
  PrivateChatPage,
  SubscriptionsPage,
  SubscriptionInner,
  UserProfileEvents,
} from 'pages';
import { ROUTES, PROFILE_ROUTES } from 'configs';
import { breakpoints } from 'theme';
import { ProfileSidebarMobile, ProfileMenu } from 'components';
import AppleCalendarFeaturePopup from 'components/Popups/AppleCalFeature';

const ProfileRouter = () => {
  const [showApplePopup, setShowApplepopup] = useState<boolean>(false);
  const [lsApplePopup, setLsApplePopup] = useLocalStorage<boolean>(
    'appleCalendarFeatureShown',
    false,
  );
  const isWide = useMedia(breakpoints.md);

  useEffect(() => {
    if (!lsApplePopup) {
      setLsApplePopup(true);
      const timer = setTimeout(() => {
        setShowApplepopup(true);
      }, 2000);
    }
  }, [lsApplePopup]);

  return (
    <>
      <AppleCalendarFeaturePopup
        isOpen={showApplePopup}
        onClose={() => setShowApplepopup(false)}
      />
      {isWide ? <ProfileMenu /> : <ProfileSidebarMobile />}
      <Routes>
        <Route path="*" element={<UserProfilePage />} />

        <Route
          path={PROFILE_ROUTES.SETTINGS}
          element={<UserProfileSettingsPage />}
        />

        <Route
          path={PROFILE_ROUTES.CALENDAR_CONNECTION}
          element={<UserProfileCalendarsPage />}
        />

        <Route
          path={`${PROFILE_ROUTES.SUBSCRIPTION}/${PROFILE_ROUTES.PLANS}/:subscriptionId`}
          element={<SubscriptionInner />}
        />

        <Route
          path={`${PROFILE_ROUTES.SUBSCRIPTION}/${PROFILE_ROUTES.PLANS}`}
          element={<SubscriptionsPage />}
        />

        <Route
          path={PROFILE_ROUTES.SUBSCRIPTION}
          element={<UserProfileSubscriptionPage />}
        />

        <Route
          path={PROFILE_ROUTES.COMMUNITIES}
          element={<UserProfileCommunitiesPage />}
        />

        <Route path={ROUTES.EVENTS} element={<UserProfileEvents />} />

        <Route
          path={PROFILE_ROUTES.NOTIFICATIONS}
          element={<NotificationsPage />}
        />

        <Route
          path={`${ROUTES.MESSAGE}/:userId`}
          element={<PrivateChatPage />}
        />
      </Routes>
    </>
  );
};

export default ProfileRouter;
