import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDimensions from 'react-use-dimensions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { ROUTES, COMMUNITY_ROUTES } from 'configs';
import {
  SettingsButton,
  Tabs,
  ZoomInput,
  GroupStatistics,
  GroupChat,
} from 'components';
import ScheduleTab from './Schedule';
import MembersTab from './Members';
import DocumentationTab from './Documentation';
import { doGetTotal } from 'store/actionCreators/currentGroup';
import { useDebounce } from 'hooks';
import { getCurrentGroup } from 'store/selectors/currentGroup';
import { Community, CommunityRoles, SchedulerFilter } from 'types';
import { getCurrentUserInCommunity } from 'store/selectors/currentCommunity';
import { selectIsChatsAvailable } from 'store/selectors/subscriptions';
import { breakpoints } from 'theme';

import {
  StyledContentWrapper,
  StyledHeader,
  StyledGroupTitle,
  StyledSettingsButtonLink,
} from './styled';
import ImportCsv from 'components/ImportCsv';

interface GroupPageProps {
  filters: SchedulerFilter;
  community: Community;
  isMenuOpen: boolean;
  zoom: number;
  initialFiltersState: SchedulerFilter;
  setFilters: (value: SchedulerFilter) => void;
  setZoom: (value: number) => void;
}

const GroupPage: FC<GroupPageProps> = ({
  community,
  isMenuOpen,
  zoom,
  filters,
  initialFiltersState,
  setFilters,
  setZoom,
}) => {
  const [tabHeight, setTabHeight] = useState<number>(0);
  const [headHeight, setHeadHeight] = useState<number>(71);
  const { t } = useTranslation();
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const group = useSelector(getCurrentGroup);
  const [filterValue, setFilterValue] = useState<string>('');
  const [isFilterResetting, setIsFilterResetting] = useState<boolean>(false);
  const debouncedValue = useDebounce(filterValue, 500);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const isChatsAvailable = useSelector(selectIsChatsAvailable);
  const userRole = currentUser.communityPermission?.role;
  const isDownMd = useMedia(breakpoints.downMd);
  const { name, communityId, id } = group;
  const [tabsRef, { height: tabsHeight }, tabsHeightRef] = useDimensions();
  const [refHeader, { height: headerHeight }] = useDimensions();

  useEffect(() => {
    setTabHeight(tabsHeight);
    setHeadHeight(headerHeight);
  }, [headerHeight, tabsHeight]);

  useEffect(() => {
    setTimeout(() => {
      setTabHeight(tabsHeightRef?.offsetHeight);
    }, 1000);
  }, [isMenuOpen, tabsHeightRef]);

  const applyFilters = () => {
    dispatch(
      doGetTotal({
        groupId,
        from: filters.from,
        to: filters.to,
      }),
    );
  };

  useEffect(() => {
    if (isFilterResetting) {
      setIsFilterResetting(false);
      applyFilters();
    }
  }, [isFilterResetting]);

  const resetFilters = () => {
    setFilters(initialFiltersState);
    setIsFilterResetting(true);
  };

  return (
    <StyledContentWrapper isMenuOpen={isMenuOpen} controlOverflow={'auto'}>
      <StyledHeader ref={refHeader} isMenuOpen={isMenuOpen}>
        <StyledGroupTitle>{name}</StyledGroupTitle>
        {userRole === CommunityRoles.OWNER ||
        userRole === CommunityRoles.ADMIN ? (
          <StyledSettingsButtonLink
            to={`${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.GROUP}/${id}/${COMMUNITY_ROUTES.SETTINGS}`}
          >
            <SettingsButton withLabel={!isDownMd} />
          </StyledSettingsButtonLink>
        ) : null}
        <ZoomInput value={zoom} onChange={setZoom} color="black" />
      </StyledHeader>
      <Tabs
        filterValue={filterValue}
        tabsRef={tabsRef}
        noApply={false}
        filters={filters}
        setFilters={setFilters}
        setFilterValue={setFilterValue}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        initialFiltersState={initialFiltersState}
      >
        <div
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={t('group.tabs.schedule')}
          withSearch
          withFilter
          index={0}
        >
          <ScheduleTab
            filters={filters}
            usersFilter={debouncedValue}
            community={community}
            zoom={zoom}
            headerHeight={tabHeight + headHeight}
          />
        </div>
        <div
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          label={t('group.tabs.import')}
          style={{ display: 'flex' }}
          index={1}
          // withSearch={false}
          // withFilter={false}
        >
          <ImportCsv
            headerHeight={tabHeight + headHeight}
            communityDetails={community}
            groupDetails={group}
          />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('group.tabs.members')} withSearch index={2}>
          <MembersTab
            filterValue={debouncedValue}
            headerHeight={tabHeight + headHeight}
          />
        </div>
        {isChatsAvailable && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <div label={t('group.tabs.chat')} index={isChatsAvailable ? 3 : 2}>
            <GroupChat />
          </div>
        )}
        <div
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={t('group.tabs.documentation')}
          index={isChatsAvailable ? 4 : 3}
        >
          <DocumentationTab />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('group.tabs.statistic')} index={isChatsAvailable ? 5 : 4}>
          <GroupStatistics zoom={zoom} headerHeight={tabHeight + headHeight} />
        </div>
      </Tabs>
    </StyledContentWrapper>
  );
};

export default GroupPage;
