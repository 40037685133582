import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { getProfileName } from 'utils/common';
import { useCheckboxes } from 'hooks';
import { ProfileName } from 'components';
import { Profile } from 'types';

import {
  StyledHeader,
  StyledSelectAllButton,
  StyledHeaderLabel,
  StyledListWrapper,
  StyledListItem,
} from './styled';

interface CheckListProps extends Partial<Pick<UseFormMethods, 'register'>> {
  items: Profile[];
  label: string;
  usersList?: boolean;
  withEmail?: boolean;
  preSelectAll?: boolean;
}

const CheckList: FC<CheckListProps> = ({
  items,
  label,
  usersList,
  withEmail,
  register,
  preSelectAll,
}) => {
  const { createCheckbox, selectAll, allChecked } = useCheckboxes(
    items,
    register,
    preSelectAll,
  );

  const renderUsers = (users: Profile[]) =>
    users.map((user) => (
      <StyledListItem key={user.id}>
        <div>{createCheckbox(user.id)}</div>
        <div>
          <div>
            <ProfileName
              name={getProfileName(user)}
              avatar={user.smallLogo || user.logo}
            />
          </div>
          {withEmail && <div>{user.email}</div>}
        </div>
      </StyledListItem>
    ));

  const renderTitles = (titles) =>
    titles.map((title) => (
      <StyledListItem key={title.id}>
        {createCheckbox(title.id)}
        <span>{title.title ? title.title : title.name}</span>
      </StyledListItem>
    ));

  return (
    <div>
      <StyledHeader>
        <StyledSelectAllButton
          type="button"
          onClick={selectAll}
          checked={allChecked}
        />
        <StyledHeaderLabel>{label}</StyledHeaderLabel>
      </StyledHeader>
      <StyledListWrapper>
        {usersList ? renderUsers(items) : renderTitles(items)}
      </StyledListWrapper>
    </div>
  );
};

export default CheckList;
