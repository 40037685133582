import React, { useMemo } from 'react';

import {
  InsideBorder,
  PhoneContainer,
  PhoneMockup as Phone,
  Camera,
  ExtraInfo,
  Lock,
  DateTimeContainer,
  NotificationContainer,
  Torch,
  CameraButton,
  BottomLine,
} from './styled';
import MobileNotification from '../Notification';
import { useDateTime } from '../util';

type Props = {
  title: string;
  message: string;
  imageUrl?: string;
};

const PhoneMockup: React.FC<Props> = ({ message, title }) => {
  const dateTime = useDateTime();

  const hideImage = useMemo(() => {
    if (title.length > 60 || message.length > 100) {
      return true;
    } else {
      return false;
    }
  }, [title, message]);

  return (
    <PhoneContainer>
      <Phone>
        <InsideBorder>
          <Camera>
            <Camera.CameraDot />
            <Camera.Speaker />
          </Camera>
          <ExtraInfo>
            <ExtraInfo.Dots>...</ExtraInfo.Dots>
            <ExtraInfo.Battery>
              <ExtraInfo.Battery.Bar />
              <ExtraInfo.Battery.Dot />
            </ExtraInfo.Battery>
          </ExtraInfo>
          <Lock>
            <Lock.Locked />
          </Lock>
          <DateTimeContainer>
            <DateTimeContainer.Time>{dateTime.time}</DateTimeContainer.Time>
            <DateTimeContainer.Date>{dateTime.date}</DateTimeContainer.Date>
          </DateTimeContainer>

          <NotificationContainer>
            <MobileNotification
              width="100%"
              title={title}
              message={message}
              hideImage={hideImage}
            />
          </NotificationContainer>

          <Torch>
            <Torch.Light />
            <Torch.Top />
            <Torch.SwitchSection />
            <Torch.Switch>
              <Torch.Switch.Dot />
            </Torch.Switch>
          </Torch>

          <CameraButton>
            <CameraButton.Box />
            <CameraButton.Eye />
            <CameraButton.Circle />
            <CameraButton.Dot />
          </CameraButton>

          <BottomLine />
        </InsideBorder>
      </Phone>
    </PhoneContainer>
  );
};

export default PhoneMockup;
