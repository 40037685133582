import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'configs';
import { doLogout } from 'store/actionCreators/auth';
import { doDeleteProfile } from 'store/actionCreators/profile';
import { DocumentLink, PopupButtonMessage } from 'components';

import { StyledLogoutIcon, StyledWrapper, LogoutButton } from '../styled';

const Other = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onLogout = () => dispatch(doLogout());
  const onDelete = () => dispatch(doDeleteProfile());

  return (
    <>
      <StyledWrapper>
        <DocumentLink
          name={t('profileSettings.terms')}
          type="link"
          href={ROUTES.TERMS}
        />
      </StyledWrapper>
      <StyledWrapper>
        <DocumentLink
          name={t('profileSettings.protectionRegulation')}
          type="link"
          href={ROUTES.POLICY}
        />
      </StyledWrapper>
      <StyledWrapper>
        <StyledLogoutIcon />
        <LogoutButton onClick={onLogout}>
          {t('profileSettings.logout')}
        </LogoutButton>
      </StyledWrapper>
      <PopupButtonMessage
        text={t('profileSettings.deleteAccount')}
        popupButtonText={t('common.delete')}
        popupHeaderText={t('profileSettings.deleteProfileHeader')}
        onConfirm={onDelete}
      />
    </>
  );
};

export default Other;
