import React, { FC } from 'react';

import { PageLoader, LoaderWrapper, StyledFloatingLoader } from './styled';

interface LoaderProps {
  size?: string;
  thickness?: number;
  type?: 'page' | 'main' | 'button' | 'documentation';
  floating?: boolean;
}

const Loader: FC<LoaderProps> = ({
  size = '100px',
  thickness = 4,
  type = 'page',
  floating,
}) =>
  floating ? (
    <StyledFloatingLoader>
      <Loader size="60px" type={null} />
    </StyledFloatingLoader>
  ) : (
    <LoaderWrapper type={type}>
      <PageLoader size={size} type={type} thickness={thickness} />
    </LoaderWrapper>
  );

export default Loader;
