import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export type ResetPasswordFormValues = {
  [FIELDS.PASSWORD]: string;
  [FIELDS.CONFIRM_PASSWORD]: string;
};

export const useResetPasswordForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  const resolver: Resolver<ResetPasswordFormValues> = yupResolver(
    yup.object().shape({
      [FIELDS.PASSWORD]: yup
        .string()
        .required()
        .matches(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/, t('errors.password')),
      [FIELDS.CONFIRM_PASSWORD]: yup
        .string()
        .oneOf([yup.ref('password'), null], t('errors.confirmPassword')),
    }),
  );

  return useForm<ResetPasswordFormValues>({
    resolver,
  });
};
