import { css } from 'styled-components';

export const sharedStyles = css`
  width: 90%;
  height: 40px;
  padding: 8px;
  font-family: Roboto, 'sans-serif';
  font-weight: 500;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
  cursor: pointer;
`;
