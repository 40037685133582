import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  width: 100vw;
  height: 100svh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, darkBackground }) =>
    darkBackground ? theme.color.darkGrey : 'transparent'};
  z-index: 10000000000000000;
  left: 0;
  top: 0;
`;

export const StyledPopup = styled(motion.div)`
  width: 334px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  padding: 30px 0 20px 0;
  border-radius: 8px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.06);
  ${({ popupStyles }) => popupStyles};

  & > button:first-of-type {
    position: absolute;
    right: 13px;
    top: 13px;
  }
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding: 0 30px 20px 30px;
`;

export const Message = styled.p`
  white-space: pre-wrap;
  line-height: 20px;
  text-align: center;
  background: none;
  padding: 0 20px 30px 20px;
  font-weight: bold;
  font-size: 16px;
  ${({ theme }) => `
    color: ${theme.color.shark};
  `};
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  width: 100%;
  justify-items: center;
  ${({ buttonContainerStyles }) => buttonContainerStyles};
`;
