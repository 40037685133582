import React, { CSSProperties, FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import { ROUTES, COMMUNITY_ROUTES } from 'configs';
import { MenuListWrapper } from 'components';
import { doRearrangeGroups } from 'store/actionCreators/currentCommunity';
import { CommunityGroup, CommunityRoles } from 'types';

import {
  StyledMenuList,
  StyledMenuListLink,
  StyledMenuListItem,
  StyledMenuListAddGroup,
  StyledMenuListAddGroupText,
  StyledMenuListAddGroupIcon,
  StyledDragIcon,
} from './styled';
import { StyledShadow } from '../styled';

interface CommunityMenuListProps {
  communityId: string;
  title: string;
  icon: ReactNode;
  list: CommunityGroup[];
  isAddButtonContained?: boolean;
  isPlusIcon: boolean;
  userRole: CommunityRoles;
  titleLink: string;
  listRef: React.MutableRefObject<any>;
  listMaxHeight: number;
  onClick: () => void;
  iconStyles?: CSSProperties;
}

const MenuListItem = ({
  communityId,
  id,
  name,
}: {
  communityId: string;
  id: string;
  name: string;
}) => (
  <StyledMenuListLink
    to={`${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.GROUP}/${id}`}
  >
    <StyledMenuListItem beforeSymbol="">{name}</StyledMenuListItem>
  </StyledMenuListLink>
);

const CommunityMenuList: FC<CommunityMenuListProps> = ({
  communityId,
  title,
  icon,
  list = [],
  isAddButtonContained,
  isPlusIcon,
  userRole,
  titleLink = null,
  listRef,
  iconStyles,
  listMaxHeight = '290px',
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdminOrOwner = [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(
    userRole,
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(
      doRearrangeGroups({
        communityId,
        start: result.source.index,
        end: result.destination.index,
      }),
    );
  };

  return (
    <MenuListWrapper
      title={title}
      to={titleLink}
      icon={icon}
      isAddButtonContained={isAdminOrOwner && isAddButtonContained}
      userRole={userRole}
      toolTipText={t('common.toolTipText.schedulerPlusIcon')}
      iconStyles={iconStyles}
      onClick={onClick}
    >
      {list.length > 0 ? (
        <>
          <PerfectScrollbar
            style={{
              width: '100%',
              maxHeight: listMaxHeight,
            }}
            className={'menu-list-scrollbar'}
            options={{
              wheelSpeed: 0.1,
              wheelPropagation: false,
              minScrollbarLength: 4,
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="groupsList">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <StyledMenuList ref={listRef}>
                      {list.map((item, ind) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={ind}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                paddingTop: 10,
                                paddingBottom: 5,
                                marginTop: -10,
                                cursor: 'default',
                              }}
                            >
                              <StyledDragIcon className="drag-box">
                                <div></div>
                                <div></div>
                              </StyledDragIcon>
                              <MenuListItem
                                id={item.id}
                                communityId={item.communityId}
                                name={item.name}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </StyledMenuList>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </PerfectScrollbar>
        </>
      ) : (
        isAdminOrOwner && <></>
      )}
    </MenuListWrapper>
  );
};

export default CommunityMenuList;
