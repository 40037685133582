import { CommunityGroup } from '../../types';
import { getProfileName } from '../../utils';

export const getMembersByGroups = ({
  groups,
  filteredGroups,
}: {
  groups: CommunityGroup[];
  filteredGroups: { label: string; value: string }[];
}) => {
  const groupMembers = groups
    .filter((g) => filteredGroups.some((i) => i.value === g.id))
    .map((g) => g.users)
    .flat();
  const uniqueUserIds = [...new Set(groupMembers.map((u) => u.id))];

  return uniqueUserIds.map((id) => ({
    label: getProfileName(groupMembers.find((u) => u.id === id)?.profile),
    value: id,
  }));
};

export const sliceIntoChunks = (arr) => {
  const minChunkSize = 4;
  const maxChunkSize = 10;
  const chunkSize = Math.max(
    minChunkSize,
    Math.min(
      maxChunkSize,
      Math.ceil(arr.length / Math.ceil(arr.length / maxChunkSize)),
    ),
  );

  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }

  return result;
};
