import styled from 'styled-components';

import { DeleteIcon } from 'static';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 19px;
  height: calc(100svh - 151px);
  overflow-y: scroll;
  overflow-x: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-around !important;
  width: 100%;
`;

export const StyledIcon = styled(DeleteIcon)`
  margin-right: 10px;
`;

export const StyledGroupsList = styled.div`
  margin-top: 15px;
`;

export const StyledGroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

export const StyledGroupName = styled.p`
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;
