import styled from 'styled-components';

export const StyledText = styled.span`
  margin-left: 14px;
  font-size: 15px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;
