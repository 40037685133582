import styled from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoints } from 'theme';

export const StyledPopup = styled(motion.div)`
  width: 800px;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.brightTurquoise};

  @media ${breakpoints.downMd} {
    width: 94%;
  }

  & > button:first-of-type {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;

    svg path {
      fill: black;
      transition: fill 0.3s;
    }
  }
`;

export const StyledHeader = styled.div`
  padding: 30px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const StyledBody = styled.div`
  padding: 0 26px;

  h3 {
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 26px;
    color: ${({ theme }) => theme.color.pickledBluewood};

    @media ${breakpoints.downMd} {
      font-size: 26px;
      line-height: 44px;
    }
  }

  p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.pickledBluewood};
    text-align: center;

    @media ${breakpoints.downMd} {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 26px;
  margin-top: 34px;
  margin-bottom: 26px;
`;
