import React, { FC, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { SearchIcon } from 'static';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledInput,
  InputWrapper,
  ShowPasswordButton,
  ShowPasswordIcon,
  HidePasswordIcon,
} from './styled';
import { StyledIconsBlock, StyledLeftIcon } from '../SearchInput/styled';

interface TextInputV2Props {
  name: string;
  type?: 'text' | 'number' | 'password';
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
  error?: FieldError;
  register: any;
  width?: string;
  defaultValue?: string | number;
  pattern?: string;
  min?: string;
  withShowIcon?: boolean;
  withSearchIcon?: boolean;
  onBlur?: () => void;
}

const TextInputV2: FC<TextInputV2Props> = ({
  name,
  type = 'text',
  placeholder,
  disabled = false,
  readonly = false,
  label,
  error,
  register,
  width = '100%',
  defaultValue,
  min,
  pattern,
  withShowIcon,
  withSearchIcon,
  onBlur = () => {},
}) => {
  const [inputType, setType] = useState<'text' | 'password' | 'number'>(type);

  const togglePasswordVisibility = () =>
    inputType === 'text' ? setType('password') : setType('text');

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel htmlFor={name} error={error}>
          {label}
        </StyledLabel>
      )}
      <InputWrapper>
        <StyledInput
          width={width}
          id={name}
          type={inputType}
          disabled={disabled}
          readOnly={readonly}
          placeholder={placeholder}
          error={error}
          name={name}
          ref={register}
          min={min || null}
          pattern={pattern || null}
          defaultValue={defaultValue}
          onBlur={onBlur}
        />
        {withSearchIcon && (
          <StyledIconsBlock>
            <StyledLeftIcon src={SearchIcon} alt="" />
          </StyledIconsBlock>
        )}
        {type === 'password' && withShowIcon ? (
          <ShowPasswordButton
            type="button"
            onClick={togglePasswordVisibility}
            tabIndex="-1"
          >
            {inputType === 'password' ? (
              <ShowPasswordIcon />
            ) : (
              <HidePasswordIcon />
            )}
          </ShowPasswordButton>
        ) : null}
      </InputWrapper>
      {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}
    </StyledWrapper>
  );
};

export default TextInputV2;
