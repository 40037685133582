import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledWrapper = styled.div`
  width: 500px;
  margin: 30px auto;
  @media ${breakpoints.downSmPlus} {
    width: 94%;
    margin: 30px 27px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(100svh - 144px);
  @media ${breakpoints.downSmPlus} {
    min-height: calc(100svh - 109px);
  }
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 61px;
  border-radius: 8px 8px 0 0;
  background-color: #fbfffe;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.06);
`;
