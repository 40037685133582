import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { StyledBlock, StyledInput, StyledRadio } from './styled';

interface RadioButtonProps extends Partial<Pick<UseFormMethods, 'register'>> {
  name: string;
  label: string;
  checked: boolean;
  value: string;
  disabled?: boolean;
  onChange: () => void;
}

const RadioButton: FC<RadioButtonProps> = ({
  name,
  value,
  label,
  checked,
  disabled,
  register,
  onChange,
}) => (
  <StyledBlock>
    <StyledInput
      ref={register}
      name={name}
      type="radio"
      value={value}
      disabled={disabled && 'disabled'}
      checked={checked}
      onChange={onChange}
    />
    <StyledRadio />
    {label}
  </StyledBlock>
);

export default RadioButton;
