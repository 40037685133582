import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'translates/en.json';
import de from 'translates/de.json';
import fr from 'translates/fr.json';
import it from 'translates/it.json';
import es from 'translates/es.json';
import ru from 'translates/ru.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  es: {
    translation: es,
  },
  ru: {
    translation: ru,
  },
};

i18n.use(initReactI18next).init({
  resources,
  languages: ['en', 'de', 'fr', 'it', 'es', 'ru'],
  fallbackLng: 'en',
  react: {
    bindI18nStore: 'added',
    wait: true,
  },
  objectNotation: true,
});

export default i18n;
