import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  NAME = 'fieldName',
}

export type AddFieldValues = {
  [FIELDS.NAME]: string;
};

export const useAddFieldForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<AddFieldValues> = yupResolver(
    yup.object().shape({
      [FIELDS.NAME]: yup.string().required().max(50).min(3).trim(),
    }),
  );

  return useForm<AddFieldValues>({
    resolver,
  });
};
