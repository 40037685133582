import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ReplyFormData } from '../../../pages/UserProfileEvents/form';
import { getEventTasks } from 'store/selectors/eventTasks';
import { SearchInput, CheckListControlled } from 'components';
import { useFilter } from 'hooks';
import { Event } from 'types';

import {
  StyledSearchWrapper,
  StyledPlaceholder,
  StyledTasksListWrapper,
} from './styled';

interface GiveReplyTasksProps {
  event: Event;
  inInEventTab?: boolean;
  canEdit: boolean;
  width?: number;
  height?: number;
  tasksFieldProps: ControllerRenderProps<ReplyFormData>;
}

const GiveReplyTasks: FC<GiveReplyTasksProps> = ({
  event,
  canEdit,
  inInEventTab,
  tasksFieldProps,
  width,
  height,
}) => {
  const { t } = useTranslation();
  const communityTasks = useSelector(getEventTasks);
  const tasks = event?.tasks || [];
  const eventTasks = communityTasks.filter((task) => tasks.includes(task.id));
  const {
    filteredItems: filteredTasks,
    searchInputValue,
    handleChangeSearch,
  } = useFilter(eventTasks);

  const allChecked = useMemo(() => {
    return tasks.every((t) => tasksFieldProps?.value?.includes(t));
  }, [tasksFieldProps, tasks]);

  const handleSelectAllClick = useCallback(() => {
    if (allChecked) {
      tasksFieldProps.onChange([]);
    } else {
      tasksFieldProps.onChange(tasks);
    }
  }, [allChecked, tasksFieldProps]);

  return (
    <div>
      {!inInEventTab && (
        <StyledSearchWrapper>
          <SearchInput
            width="100%"
            value={searchInputValue}
            onChange={({ target }) => handleChangeSearch(target.value)}
          />
        </StyledSearchWrapper>
      )}
      <StyledTasksListWrapper
        height={height ? 119 + 40 * filteredTasks.length : height}
        width={width}
      >
        <CheckListControlled
          checkBoxWidth="16px"
          items={filteredTasks}
          label={t('common.tasks')}
          selectedItems={tasksFieldProps?.value}
          selectItem={(t) => {
            tasksFieldProps.onChange(
              tasksFieldProps.value.includes(t)
                ? tasksFieldProps.value.filter((i) => i !== t)
                : [...tasksFieldProps.value, t],
            );
          }}
          disabled={!canEdit}
          handleSelectAllClick={handleSelectAllClick}
          allChecked={allChecked}
        />
      </StyledTasksListWrapper>
      {!eventTasks[0] && (
        <StyledPlaceholder>{t('task.noTasks')}</StyledPlaceholder>
      )}
    </div>
  );
};

export default GiveReplyTasks;
