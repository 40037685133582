import { useDispatch } from 'react-redux';
import { DropResult } from 'react-beautiful-dnd';

import { openModal } from 'store/actionCreators/modal';
import { doRearrangeGroupUsers } from 'store/actionCreators/currentGroup/users';
import {
  ADD_GROUP_MEMBERS,
  EDIT_PROFILE,
  EDIT_USER_ROLE,
} from 'constants/sidebars';
import {
  Community,
  CommunityUser,
  CurrentGroup,
  GroupRoles,
  GroupUser,
} from 'types';

export const useRoles = (
  community: Community,
  group: CurrentGroup,
  users: GroupUser[],
  onDelete: (user: GroupUser) => void,
  currentUserInGroup: GroupUser,
  currentUserInCommunity: CommunityUser,
): {
  onDragEnd: (result: DropResult) => void;
  onAdd: () => void;
  onEdit: (user: GroupUser) => void;
} => {
  const dispatch = useDispatch();

  const onAdd = () => {
    dispatch(
      openModal({
        type: ADD_GROUP_MEMBERS,
        placement: 'right',
        data: {
          group,
          groupMembers: users,
          fromGroup: true,
        },
      }),
    );
  };

  const onEdit = (user: GroupUser) => {
    dispatch(
      openModal({
        type:
          user.profile?.allowedToEdit?.includes(community.id) ||
          user.id === currentUserInCommunity.id
            ? EDIT_PROFILE
            : EDIT_USER_ROLE,
        placement: 'right',
        data: {
          user,
          isForGroup: true,
          onDelete,
          currentUserInGroup,
          currentUserInCommunity,
        },
      }),
    );
  };

  const onDragEnd = (result: DropResult) => {
    const roleChanged =
      result.source.droppableId !== result.destination.droppableId;

    if (result.source.index !== result.destination.index || roleChanged) {
      dispatch(
        doRearrangeGroupUsers({
          groupId: group.id,
          start: result.source.index,
          end: result.destination.index,
          prevRole: result.source.droppableId as GroupRoles,
          newRole: result.destination.droppableId as GroupRoles,
          userId: result.draggableId,
        }),
      );
    }
  };

  return {
    onAdd,
    onEdit,
    onDragEnd,
  };
};
