import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'configs';
import { doDeleteCommunity } from 'store/actionCreators/communities';
import { PopupButtonMessage } from 'components';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useInfoPopup } from 'hooks';

const SettingsOther = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showInfoPopup } = useInfoPopup();
  const community = useSelector(getCurrentCommunity);

  const onSuccess = () => {
    showInfoPopup({
      type: 'success',
      title: t('community.deleteSuccess'),
    });
  };

  const deleteCommunity = (data: string) =>
    dispatch(doDeleteCommunity({ data, onSuccess }));

  const onDeleteCommunity = (id: string) => {
    deleteCommunity(id);
    navigate(ROUTES.PROFILE);
  };

  return (
    <PopupButtonMessage
      text={t('community.deleteCommunity')}
      onConfirm={() => onDeleteCommunity(community.id)}
      popupButtonText={t('common.delete')}
      popupHeaderText={t('communitySettings.deleteCommunityHeader')}
    />
  );
};

export default SettingsOther;
