import React from 'react';
import { Routes, Route } from 'react-router-dom';

import DocumentationDetailsPageContainer from './DocumentationDetails';
import DocumentationPageContainer from './Main';

const DocumentationPage = () => (
  <Routes>
    <Route path="*" element={<DocumentationPageContainer />} />
    <Route path=":groupId" element={<DocumentationDetailsPageContainer />} />
  </Routes>
);

export default DocumentationPage;
