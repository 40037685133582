import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Loader } from 'components';

import { useInfoPopup } from 'hooks';

import {
  ExportModuleWrapper,
  ComponentTitle,
  ComponentInfoText,
  PostSuccessMessage,
  SubSection,
  AnimationContainer,
  MaxWidthWrapper,
  ButtonLoaderWrapper,
} from './styles';

import SuccessAnimation from 'static/animations/success-check.json';
import { Community } from 'types';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import {
  doDeleteBulkEvents,
  doResetUploadStatus,
} from 'store/actionCreators/currentCommunity';
import {
  resetBulkEventUploadStatus,
  resetGroupBulkEventUploadStatus,
} from 'services/events';
import { getBulkUploadStatus } from 'store/selectors/currentCommunity';

type Props = {
  eventsCount?: number;
  community: Community;
};

const UploadSuccess: React.FC<Props> = ({ eventsCount, community }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [resetStateLoader, setResetStateLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showInfoPopup } = useInfoPopup();
  const bulkUploadStatus = useSelector(getBulkUploadStatus);
  const { groupId } = useParams();
  const animationOptions = {
    animationData: SuccessAnimation,
    autoplay: true,
    loop: true,
  };

  const handleDelete = async () => {
    setDeleteLoader(true);
    dispatch(
      doDeleteBulkEvents({
        communityId: community.id,
        groupId: groupId || '',
        onSuccess: () => {
          setDeleteLoader(false);
          showInfoPopup({
            title: t('bulkUploadEvents.deleteSuccessTitle'),
            message: t('bulkUploadEvents.deleteSuccessMessage'),
            type: 'success',
          });
        },
      }),
    );
  };

  const resetUploadStatus = async () => {
    try {
      setResetStateLoader(true);
      //TODO remove any type
      let data: { uploadStatus: any };
      if (groupId) {
        data = await resetGroupBulkEventUploadStatus(groupId);
      } else {
        data = await resetBulkEventUploadStatus(community.id);
      }
      setResetStateLoader(false);
      dispatch(
        doResetUploadStatus.success({ uploadStatus: data.uploadStatus }),
      );
    } catch (err) {
      //
    }
  };

  const goToScheduler = async () => {
    if (groupId) {
      navigate(
        `${ROUTES.COMMUNITY}/${community.id}/group/${groupId}#scheduler`,
      );
    } else {
      navigate(
        `${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.OVERALL}#scheduler`,
      );
    }
  };

  return (
    <ExportModuleWrapper customGap={'30px'}>
      <ComponentTitle>
        {t('bulkUploadEvents.stepFour.componentTitle')}
      </ComponentTitle>
      <PostSuccessMessage>
        <AnimationContainer>
          <Lottie options={animationOptions} width="80px" height="80px" />
        </AnimationContainer>
        <ComponentInfoText>
          {/* <strong>{bulkUploadStatus?.uploadEventsCount ?? 0} &nbsp;</strong> */}
          <div
            dangerouslySetInnerHTML={{
              __html: t('bulkUploadEvents.stepFour.componentDescription', {
                count: bulkUploadStatus?.uploadEventsCount ?? 0,
              }),
            }}
          ></div>
        </ComponentInfoText>
      </PostSuccessMessage>
      <SubSection marginTop="24px" marginBottom="0px" gap="8px">
        <ComponentTitle>
          {t('bulkUploadEvents.stepFour.secondTitle')}
        </ComponentTitle>
        <ComponentInfoText>
          {t('bulkUploadEvents.stepFour.secondSubTitle')}
        </ComponentInfoText>
      </SubSection>
      <MaxWidthWrapper>
        <SubSection>
          <Button
            disabled={resetStateLoader || deleteLoader}
            variant="secondary"
            onClick={goToScheduler}
            width="min(100%, 314px)"
          >
            {resetStateLoader || deleteLoader ? (
              <ButtonLoaderWrapper>
                <Loader type="button" size="28px" />
              </ButtonLoaderWrapper>
            ) : (
              t('bulkUploadEvents.stepFour.goToScheduler')
            )}
          </Button>

          <Button
            disabled={resetStateLoader || deleteLoader}
            variant="danger"
            width="min(100%, 314px)"
            onClick={() => {
              showInfoPopup({
                title: t('bulkUploadEvents.deletePopupTitle'),
                message: t('bulkUploadEvents.deletePopupMessage'),
                type: 'warning',
                buttonText: t('common.delete'),
                cancelButtonText: t('common.cancel'),
                onButtonClick: handleDelete,
              });
            }}
          >
            {resetStateLoader || deleteLoader ? (
              <ButtonLoaderWrapper>
                <Loader type="button" size="28px" />
              </ButtonLoaderWrapper>
            ) : (
              t('bulkUploadEvents.stepFour.undoImport')
            )}
          </Button>

          <Button
            disabled={resetStateLoader || deleteLoader}
            width="min(100%, 314px)"
            onClick={() => {
              showInfoPopup({
                title: t('bulkUploadEvents.restartProcessTitle'),
                message: t('bulkUploadEvents.restartProcessMessage'),
                type: 'warning',
                buttonText: t('bulkUploadEvents.restartProcessOkButton'),
                cancelButtonText: t('common.cancel'),
                onButtonClick: resetUploadStatus,
              });
            }}
          >
            {resetStateLoader || deleteLoader ? (
              <ButtonLoaderWrapper>
                <Loader type="button" size="28px" />
              </ButtonLoaderWrapper>
            ) : (
              t('bulkUploadEvents.stepFour.uploadNewList')
            )}
          </Button>
        </SubSection>
      </MaxWidthWrapper>
    </ExportModuleWrapper>
  );
};

export default UploadSuccess;
