import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderContainer, Header } from './styled';

interface EmailModuleHeaderProps {
  isMenuOpen: boolean;
}

const EmailModuleHeader: FC<EmailModuleHeaderProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <Header isMenuOpen={isMenuOpen}>{t('common.email')}</Header>
    </HeaderContainer>
  );
};

export default EmailModuleHeader;
