import { useDispatch } from 'react-redux';

import { useCreateChatForm } from './form';
import { doCreateChat } from 'store/actionCreators/chat';

export const useCreateChat = (communityId: string) => {
  const dispatch = useDispatch();
  const { handleSubmit, ...form } = useCreateChatForm();

  const onSubmit = handleSubmit(({ chatName }) => {
    dispatch(
      doCreateChat({
        communityId,
        chatName,
      }),
    );
  });

  return {
    onSubmit,
    ...form,
  };
};
