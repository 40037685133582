import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTooltip = styled(motion.div)`
  z-index: 10000000;
  background: ${({ theme }) => theme.color.blackSqueeze};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: ${({ zoom }) => `${9 * zoom}px ${8 * zoom}px`};
  border-radius: ${({ zoom }) => 6 * zoom + 'px'};
  & > svg {
    path {
      fill: #ebf2f7;
    }
  }
`;

export const StyledOption = styled.div`
  padding-left: ${({ zoom }) => 8 * zoom + 'px'};
  padding-right: ${({ zoom }) => 8 * zoom + 'px'};
  padding-top: ${({ zoom }) => 14 * zoom + 'px'};
  padding-bottom: ${({ zoom }) => 14 * zoom + 'px'};
  border-radius: ${({ zoom }) => 6 * zoom + 'px'};
  height: ${({ zoom }) => 26 * zoom + 'px'};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;

  svg {
    width: ${({ zoom }) => 12 * zoom + 'px'};
    margin-right: ${({ zoom }) => 7 * zoom + 'px'};

    path {
      fill: black;
      transition: fill 0.3s;
    }
  }

  label {
    display: flex;
    font-size: ${({ zoom }) => 14 * zoom + 'px'};
    cursor: pointer;
  }

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.brightTurquoise};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    svg {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

export const StyledThreeDots = styled.div`
  width: ${({ zoom }) => 20 * zoom + 'px'};
  height: ${({ zoom }) => 20 * zoom + 'px'};
  border: ${({ zoom }) => 2 * zoom + 'px'} solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ zoom }) => 12 * zoom + 'px'};
  padding-bottom: ${({ zoom }) => 8 * zoom + 'px'};
`;

export const StyledLoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff99;
`;
