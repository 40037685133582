import styled from 'styled-components';

export const StyledSearchWrapper = styled.div`
  padding: 13px 16px 14px 15px;
`;

export const StyledTasksListWrapper = styled.div`
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  height: ${({ height }) => (height ? height + 'px' : 'auto')};
`;

export const StyledPlaceholder = styled.p`
  margin-top: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
  font-weight: 600;
  text-align: center;
`;
