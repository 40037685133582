import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxContainer, RedirectionBox } from './styled';
interface ChangeSidebarBoxProps {
  onClick: (data?: string) => void;
  primary: boolean;
}

const ChangeSidebarBox: FC<ChangeSidebarBoxProps> = ({ onClick, primary }) => {
  const { t } = useTranslation();

  return (
    <BoxContainer>
      <RedirectionBox
        onClick={() => onClick('viewAppointment')}
        primary={primary ? true : false}
      >
        {t('event.viewAppointment')}
      </RedirectionBox>

      <RedirectionBox
        primary={primary ? false : true}
        onClick={() => onClick('myResponse')}
      >
        {t('event.myResponse')}
      </RedirectionBox>
    </BoxContainer>
  );
};

export default ChangeSidebarBox;
