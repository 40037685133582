import React, { FC } from 'react';

import { StyledProgressBar, Filler } from './styled';

interface ProgressBarProps {
  percentage: number;
  width?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ percentage, width = '219px' }) => (
  <StyledProgressBar width={width}>
    <Filler percentage={percentage} />
  </StyledProgressBar>
);

export default ProgressBar;
