import React, { FC } from 'react';
import Tooltip from 'components/Tooltip';

import { Wrapper } from './styled';

interface LogoWithTitleProps {
  logo?: string;
  title: string;
  width?: number;
  imageWidth?: string;
  showTooltip?: boolean;
  comminuteTitleStyle?: { [key: string]: string | number };
}

const tooltipStyle = {
  whiteSpace: 'pre-wrap',
  maxWidth: '200px',
  height: 'auto',
};

const LogoWithTitle: FC<LogoWithTitleProps> = ({
  logo,
  title,
  width = 40,
  imageWidth,
  showTooltip,
  comminuteTitleStyle,
}) => (
  <Wrapper>
    <Wrapper.Logo>
      {!logo ? (
        <Wrapper.Logo.Placeholder width={width}>
          <span>{title.charAt(0).toUpperCase()}</span>
        </Wrapper.Logo.Placeholder>
      ) : (
        <Wrapper.Logo.Image src={logo} imageWidth={imageWidth} />
      )}
    </Wrapper.Logo>
    {showTooltip ? (
      <Tooltip text={title} position="top-start" tooltipStyle={tooltipStyle}>
        <Wrapper.Name style={comminuteTitleStyle}>{title}</Wrapper.Name>
      </Tooltip>
    ) : (
      <Wrapper.Name style={comminuteTitleStyle}>{title}</Wrapper.Name>
    )}
  </Wrapper>
);

export default LogoWithTitle;
