import styled, { css } from 'styled-components';

import { breakpoints } from 'theme';

const Layout = styled.div`
  background: ${({ theme }) => theme.color.dew};
  height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
`;

Layout.Header = styled.div`
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  display: flex;
  align-items: center;
  padding: 14px 25px;
  position: sticky;
  top: 0px;
`;

Layout.Inner = styled.div`
  min-height: calc(100svh - 50px - 61px);
  background-color: ${({ theme }) => theme.color.dew};
  padding: 20px 25px;
  @media ${breakpoints.downSmPlus} {
    padding: 20px 16px;
  }

  hr {
    margin: 10px 0;
  }
`;

Layout.Footer = styled.div`
  border-radius: 8px 8px 0 0;
  background-color: #fbfffe;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.06);
  position: fixed;
  bottom: 0;
  width: 100vw;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  @media ${breakpoints.md} {
    width: calc(100vw - 360px);
  }
`;

Layout.Inner.Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

Layout.Inner.Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  &:nth-of-type(2) {
    font-weight: bold;
  }
`;

export const CalendarsCard = styled.div`
  padding: 15px 20px;
  border-radius: 8px;
  margin-top: 25px;
  box-shadow: 0px 0px 4px 0px #6a6a6aa6;
`;

CalendarsCard.Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: height 0.3s;
  height: 50px;
  overflow: hidden;
`;

CalendarsCard.Row.Main = styled.div`
  width: 100%;
  /* height: 100%; */

  & > div {
    @media ${breakpoints.sm} {
      &:nth-of-type(2) {
        order: 4;
        width: 100%;
      }

      &:nth-of-type(3) {
        width: 50%;
        text-align: left;
        order: 2;
      }

      &:nth-of-type(4) {
        width: 50%;
        text-align: right;
        order: 3;
      }
    }

    @media ${breakpoints.smPlus} {
      &:nth-of-type(2) {
        width: auto;
      }

      &:nth-of-type(3) {
        order: 3;
      }

      &:nth-of-type(4) {
        order: 2;
      }
    }

    @media ${breakpoints.mdPlus} {
      &:nth-of-type(2) {
        order: 2;
      }

      &:nth-of-type(3) {
        order: 3;
        width: auto;
      }

      &:nth-of-type(4) {
        width: 150px;
        order: 4;
      }
    }
  }
`;

export const CalendarTitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: 172px;

  span {
    font-weight: 400;
  }

  svg {
    width: 38px;
    margin-right: 10px;
  }

  @media ${breakpoints.downMdPlus} {
    margin-right: 0;
  }

  @media ${breakpoints.downSmPlus} {
    height: 30px;
    margin: 0 auto 20px auto;
    min-width: auto;

    span {
      font-size: 14px;
    }

    svg {
      width: 33px;
      margin-right: 8px;
    }
  }
`;

export const BeforeConnectedWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${breakpoints.downSmPlus} {
    flex-direction: column;

    button {
      margin-left: 0;
    }
  }
`;

export const AfterConnectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    &:nth-of-type(2) {
      span {
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media ${breakpoints.downMdPlus} {
    flex-wrap: wrap;

    & > div {
      width: 50%;
      height: 50px;
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: flex-end;
        order: 4;
      }

      &:nth-of-type(3) {
        order: 3;
      }

      &:nth-of-type(4) {
        justify-content: flex-end;
        order: 2;
      }
    }
  }

  @media ${breakpoints.downSmPlus} {
    justify-content: space-around;

    & > div {
      &:nth-of-type(1) {
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
      }

      &:nth-of-type(2) {
        justify-content: center;
        width: 100%;
      }

      &:nth-of-type(3) {
        order: 2;
        width: auto;
      }

      &:nth-of-type(4) {
        order: 3;
        width: auto;
      }
    }
  }
`;

export const ConnectionLabel = styled.span`
  text-transform: uppercase;
  color: ${({ success, theme }) =>
    success ? theme.color.brightTurquoise : theme.color.bittersweet};
  cursor: ${({ displayCheck }) => (displayCheck ? 'default' : 'pointer')};
  text-decoration: ${({ success }) => (success ? 'none' : 'underline')};
  position: relative;
  padding-left: 26px;

  &:before {
    display: ${({ displayCheck }) => (displayCheck ? 'inline-block' : 'none')};
    content: '\\2713';
    color: ${({ theme }) => theme.color.brightTurquoise};
    font-size: 25px;
    position: absolute;
    top: -8px;
    left: 0;

    @media ${breakpoints.downMd} {
      font-size: 18px;
      top: -1px;
      left: 4px;
    }
  }
`;

export const ConnectButtonWrapper = styled.div`
  margin-left: 25px;
  margin-top: 4px;
  width: 225px;
`;

Layout.Table = styled.div`
  margin-bottom: 10px;

  table {
    thead {
      th {
        height: 30px;
      }
    }
  }
`;

Layout.Title = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 700;
  font-size: 14px;
  padding: 30px 25px 20px 25px;
  text-transform: uppercase;
  text-align: left;
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListWrapper = styled.div`
  overflow: auto;
  width: 100%;
  @media ${breakpoints.downSmPlus} {
    width: calc(100vw - 89px);
  }
  /* Firefox */
  scrollbar-width: thin;
  /* Chrome, Edge and Safari */
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #f0f0f0;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: #e4e4e4;
  }

  ::-webkit-scrollbar-track:active {
    background-color: #e3e3e3;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #cfcfcf;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #9f9f9f;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #8e8e8e;
  }
`;

export const List = styled.table`
  width: 100%;
  margin-top: 20px;
  min-width: 600px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-spacing: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  table-layout: fixed;
  @media ${`${breakpoints.md} and ${breakpoints.downMdPlus}`} {
    width: calc(100vw - 120px);
  }
`;

List.Body = styled.tbody`
  position: relative;
  height: 100%;
`;

List.Row = styled.tr`
  /* overflow: auto; */
`;

List.Row.Cell = styled.td`
  height: 60px;
  max-height: 60px;
  border-top: 1px solid ${({ theme }) => theme.color.porcelain};
  padding: 0 16px;
  width: min-content;
  @media ${breakpoints.downSmPlus} {
    padding: 0 6px;
  }

  &:first-of-type {
    width: 5%;
    max-width: 50px;
    @media ${breakpoints.downLg} {
      padding: 0px 16px 0px 4px !important;
    }
    @media ${breakpoints.downMd} {
      padding: 0px 6px 0px 0px !important;
    }
  }
  ${({ isApple }) =>
    isApple &&
    css`
      &:nth-of-type(2) {
        width: 25%;
      }
      &:nth-of-type(3) {
        width: 60%;
      }
      &:nth-of-type(4) {
        max-width: 80px;
        width: 10%;
        padding: 0px 0px 0px 0px !important;

        @media ${breakpoints.downMdPlus} {
          padding: 0px 0px 0px 6px !important;
        }
      }
    `}
`;

List.Loader = styled.div`
  margin-top: 25px;
  position: relative;
`;

export const NoCommunitiesBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  svg {
    width: 60px;
    height: 46px;
  }

  span {
    width: 100%;
    white-space: pre-wrap;
    margin-top: 20px;
    text-align: center;
  }
`;

export const LoaderWrapper = styled.div`
  width: ${({ width }) => width};
  height: 50px;
`;

export const StyledAnchorTag = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black};
  display: ${({ display }) => (display ? display : 'flex')};
  gap: 5px;
  border-bottom: 1px solid;
  width: max-content;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
`;

export const StepsBlock = styled.ol`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0px;
  text-align: left;
`;

StepsBlock.Item = styled.li`
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  align-self: flex-start;
  padding: 0px 4px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ highlight, theme }) =>
    highlight ? theme.color.blackSqueeze : 'none'};
  white-space: pre-wrap;
`;

export { Layout, Heading };
