import styled, { css } from 'styled-components';

export const StyledBlock = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  ${({ hasNotifications }) =>
    hasNotifications &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.brightTurquoise};
      }
    `}
`;
