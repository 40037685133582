import { axiosInstance as axios } from './index';
import { Group, GroupRoles } from 'types';

export const createGroup = async (data: {
  communityId: string;
  name: string;
  onSuccess: (data: Group) => void;
}) => {
  const response = await axios.post('/groups', data);
  return response.data;
};

export const updateGroup = async (
  id: string,
  data: { id: string; name: string },
) => {
  const response = await axios.put(`/groups/${id}`, {
    name: data.name,
  });
  return response.data;
};

export const deleteGroup = async (id: string) => {
  await axios.delete(`/groups/${id}`);
};

export const addMembersToGroup = async (
  communityId: string,
  groupId: string,
  data: { users: string[] },
) => {
  const response = await axios.post(
    `/communities/${communityId}/group/${groupId}/users`,
    data,
  );
  return response.data;
};

export const getTotal = async (
  groupId,
  from = new Date(),
  to = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate() + 7,
  ),
) => {
  const fromToRequest = new Date(
    Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()),
  );
  const toToRequest = new Date(
    Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1),
  );
  const response = await axios.get(
    `groups/${groupId}/total?from=${fromToRequest.toISOString()}&to=${toToRequest.toISOString()}`,
  );
  return response.data;
};

export const getStatistic = async ({
  id,
  fromDate,
  toDate,
  eventTypeId,
}: {
  id: string;
  fromDate: Date;
  toDate: Date;
  eventTypeId?: string;
}) => {
  const fromToRequest = new Date(
    Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()),
  );
  const toToRequest = new Date(
    Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() + 1),
  );
  const response = await axios.get(
    `/groups/${id}/statistic?from=${fromToRequest.toISOString()}&to=${toToRequest.toISOString()}${
      eventTypeId ? `&eventTypeId=${eventTypeId}` : ''
    }`,
  );
  return response.data;
};

export const rearrangeGroups = (
  start: number,
  end: number,
  communityId: string,
) => {
  return axios.put(`communities/${communityId}/groupsOrder`, {
    start,
    end,
  });
};

export const rearrangeGroupUsers = (
  userId: string,
  end: number,
  groupId: string,
  role: GroupRoles,
) => {
  return axios.put(`groups/${groupId}/order`, {
    userId,
    end,
    role,
  });
};
