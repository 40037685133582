import React, { FC } from 'react';

import { LogoUploadIcon } from 'static';
import Loader from '../../../Loader';

import {
  ImageWrapper,
  StyledPlaceholder,
  StyledFileUploadIcon,
  StyledTextButton,
  StyledTipText,
  Wrapper,
} from './styled';

interface FileBoxProps {
  placeholder?: string;
  errorMessage?: string;
  text: string;
  tipText: string;
  isLoading: boolean;
}

const FileBox: FC<FileBoxProps> = ({
  placeholder = LogoUploadIcon,
  errorMessage,
  text,
  tipText,
  isLoading,
}) => (
  <ImageWrapper isError={errorMessage}>
    <StyledPlaceholder>
      {isLoading ? (
        <Loader type="documentation" size="30px" thickness={2} />
      ) : (
        <>
          <StyledFileUploadIcon src={placeholder} />
          <Wrapper>
            <StyledTextButton>{text}</StyledTextButton>
            <StyledTipText>{tipText}</StyledTipText>
          </Wrapper>
        </>
      )}
    </StyledPlaceholder>
  </ImageWrapper>
);

export default FileBox;
