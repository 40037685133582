import styled from 'styled-components';

export const StyledBlock = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 10px 6px;
  gap: 6px;
  width: 90%;
  cursor: pointer;
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.color.baliHai};
  transition: all 0.3s ease-in-out;
  position: relative;
  img {
    filter: ${({ attended }) =>
      attended &&
      'invert(82%) sepia(92%) saturate(320%) hue-rotate(371deg) brightness(154%) contrast(124%)'};
  }
  &.checked {
    border-radius: 4px;
    color: ${({ theme }) => theme.color.brightTurquoise};
    font-weight: 600;
    background-color: #ebf2f7;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: ${({ attended }) => (attended ? '75px' : '49px')};
      height: 3px;
      box-sizing: border-box;
      bottom: 8px;
      background-color: ${({ theme }) => theme.color.brightTurquoise};
    }

    img {
      filter: ${({ attended }) =>
        attended &&
        'invert(82%) sepia(92%) saturate(320%) hue-rotate(371deg) brightness(154%) contrast(124%)'};
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledInput = styled.input`
position: absolute;
-webkit - appearance: none;
-moz - appearance: none;
appearance: none;
`;

export const StyledIconBlock = styled.div`
  display: flex;
  height: 100 %;
  position: relative;
`;

export const StyledIcon = styled.img`
  width: 18px;
  height: 18px;
`;
