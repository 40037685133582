import { createRoutine } from 'redux-saga-routines';

export const doChangeUserRole = createRoutine('CHANGE_USER_ROLE', {
  trigger: (payload: {
    userId: string;
    communityId: string;
    data: { role: string; oldRole?: string };
    onSuccess: () => void;
    onFailure?: () => void;
  }) => payload,
});

export const doChangeUserProfile = createRoutine('CHANGE_USER_PROFILE');

export const doDeleteCommunityUser = createRoutine('DELETE_COMMUNITY_USER', {
  trigger: (payload: {
    userIds: string[];
    isCurrentUser?: boolean;
    communityId: string;
    onSuccess: (userIds: string | string[]) => void;
  }) => payload,
});

export const doAddCommunityUser = createRoutine('ADD_COMMUNITY_USER', {
  trigger: (payload: {
    communityId: string;
    userIds: string[];
    isCurrentUser?: boolean;
    onSuccess: () => void;
    onFailure: (e: any) => void;
  }) => payload,
});
