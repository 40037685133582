import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { doChangeProfileSettings } from 'store/actionCreators/profile';
import {
  useSyncEventsForm,
  FIELDS,
  FormValues,
  AppleFormValue,
  useAppleSyncEventsForm,
} from './form';
import { useInfoPopup } from 'hooks';

export const useChangeSyncEvents = (
  userId: string,
  defaultValues: FormValues,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, setValue, ...form } = useSyncEventsForm(defaultValues);
  const { showInfoPopup } = useInfoPopup();

  const onSubmit = (isSelecting?: boolean) =>
    handleSubmit(async (values) => {
      dispatch(
        doChangeProfileSettings({
          userId,
          data: {
            [FIELDS.SYNC_EVENTS_GOOGLE]: values[FIELDS.SYNC_EVENTS_GOOGLE],
          },
          onSuccess: isSelecting
            ? () => {
                showInfoPopup({
                  title: t('calendarConnection.syncSuccessTitle'),
                  message: t('calendarConnection.syncSuccessMessage'),
                  type: 'success',
                });
              }
            : null,
        }),
      );
    });

  return {
    onSubmit,
    setValue,
    ...form,
  };
};

export const useChangeAppleSyncEvents = (
  userId: string,
  defaultValues: AppleFormValue,
  onSuccess?: () => void,
) => {
  const dispatch = useDispatch();
  const { handleSubmit, ...form } = useAppleSyncEventsForm(defaultValues);
  const onSubmit = handleSubmit(async (values) => {
    dispatch(
      doChangeProfileSettings({
        userId,
        data: { [FIELDS.SYNC_EVENTS_APPLE]: values[FIELDS.SYNC_EVENTS_APPLE] },
      }),
    );
  });

  return { onSubmit, ...form };
};
