import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps } from 'react-hook-form';
import { isEmpty } from 'lodash/fp';
import { useSelector } from 'react-redux';

import {
  OverflowSidebarContentWithTabs,
  SearchInput,
  CheckListControlled,
} from 'components';
import { EventFormData } from '../form';
import { useFilter } from 'hooks';
import { getEventTasks } from 'store/selectors/eventTasks';

import { StyledSearchWrapper, StyledPlaceholder } from './styled';

interface CreateEventTasksProps {
  tasksFieldProps: ControllerRenderProps<EventFormData>;
}

const CreateEventTasks: FC<CreateEventTasksProps> = ({ tasksFieldProps }) => {
  const { t } = useTranslation();
  const tasks = useSelector(getEventTasks);
  const {
    filteredItems: filteredTasks,
    searchInputValue,
    handleChangeSearch,
  } = useFilter(tasks);

  const allChecked = useMemo(() => {
    return filteredTasks.every((t) => tasksFieldProps?.value?.includes(t.id));
  }, [tasksFieldProps, filteredTasks]);

  const handleSelectAllClick = useCallback(() => {
    if (allChecked) {
      tasksFieldProps.onChange([]);
    } else {
      tasksFieldProps.onChange(filteredTasks.map((t) => t.id));
    }
  }, [allChecked, tasksFieldProps, filteredTasks]);

  return (
    <OverflowSidebarContentWithTabs>
      <StyledSearchWrapper>
        <SearchInput
          width="100%"
          value={searchInputValue}
          onChange={({ target }) => handleChangeSearch(target.value)}
        />
      </StyledSearchWrapper>
      <CheckListControlled
        items={filteredTasks}
        label={t('common.tasks')}
        selectedItems={tasksFieldProps.value}
        selectItem={(t) => {
          tasksFieldProps.onChange(
            tasksFieldProps.value.includes(t)
              ? tasksFieldProps.value.filter((i) => i !== t)
              : [...tasksFieldProps.value, t],
          );
        }}
        handleSelectAllClick={handleSelectAllClick}
        allChecked={allChecked}
      />
      {isEmpty(tasks) && (
        <StyledPlaceholder>{t('task.noTasks')}</StyledPlaceholder>
      )}
    </OverflowSidebarContentWithTabs>
  );
};

export default CreateEventTasks;
