import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Sidebar,
  Button,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
  SidebarInputsWrapper,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useCreateChat } from './hooks';
import { FIELDS } from './form';
import { closeModal } from 'store/actionCreators/modal';

import { TextInputBlock } from './styled';

const CreateChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const { onSubmit, register } = useCreateChat(communityId);

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('chat.createChat')}
          onCloseClick={handleClose}
        />
        <SidebarContent>
          <SidebarInputsWrapper>
            <TextInputBlock>
              <TextInput
                register={register}
                width="100%"
                name={FIELDS.CHAT_NAME}
                label={t('chat.chatName')}
              />
            </TextInputBlock>
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter>
          <Button type="submit">{t('chat.createChat')}</Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default CreateChat;
