import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 80px;
  width: 340px;
  border-top: 1px solid ${(props) => props.theme.color.porcelain};
  position: relative;
  padding: 15px 0;

  & > svg {
    margin-right: 30px;
    cursor: pointer;
  }

  & > svg:last-child {
    margin-right: 0px;
  }

  @media (max-width: 350px) {
    max-width: 250px;
  }
`;

export const Label = styled.span`
  top: -10px;
  position: absolute;
  background-color: ${({ theme }) => theme.color.white};
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0 10px;
  font-size: 11px;
  color: ${(props) => props.theme.color.regentGray};
  font-weight: 400;
  text-transform: uppercase;
`;
