import React, { useState, useMemo, useCallback, useRef } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useChangeAppleSyncEvents } from './hooks';
import Lottie from 'react-lottie';
import { useMedia } from 'react-use';
import { useDispatch } from 'react-redux';

import {
  AfterConnectedWrapper,
  BeforeConnectedWrapper,
  CalendarTitle,
  CalendarsCard,
  ConnectButtonWrapper,
  ConnectionLabel,
  ListWrapper,
  LoaderWrapper,
  StepsBlock,
  StyledAnchorTag,
} from './styled';
import {
  Button,
  CheckBox,
  InfoPopup,
  LinkText,
  Loader,
  LogoWithTitle,
} from 'components';
import { AppleCalendarIcon, UsersIcon, Redirect, AppleLogo } from 'static';
import { getAvailableCommunities } from 'store/selectors/communities';
import { FIELDS } from './form';
import { List, NoCommunitiesBlock } from './styled';
import successCheck from 'static/animations/success-check.json';

import {
  getAppleCalendarConnectLoading,
  getFullAuthUser,
  getAppleCalendarDisconnectLoading,
} from 'store/selectors/auth';
import { useInfoPopup } from 'hooks';

import Step1 from 'static/images/step-1.png';
import Step2 from 'static/images/step-2.png';
import Step3 from 'static/images/step-3.png';
import Step4 from 'static/images/step-4.png';
import Step2German from 'static/images/step-2-de.png';
import Step3German from 'static/images/step-3-de.png';
import Step4German from 'static/images/step-4-de.png';

import { breakpoints, verticalBreakpoints } from 'theme';
import {
  doLinkAppleCalendar,
  doUnlinkAppleCalendar,
} from 'store/actionCreators/profile';

type Props = {
  expanded?: boolean;
  setExpanded?: React.Dispatch<
    React.SetStateAction<{ google: boolean; apple: boolean }>
  >;
  isConnected?: boolean;
  getRowHeight?: (type: string) => string;
  settingsSaving: boolean;
  isConnecting: boolean;
  isDisconnecting: boolean;
  onCommunityBlockToogle: (type: string) => void;
};

const ApplceCalendar: React.FC<Props> = ({
  expanded,
  isConnected,
  getRowHeight,
  settingsSaving,
  isConnecting,
  isDisconnecting,
  onCommunityBlockToogle,
  setExpanded,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const communities = useSelector(getAvailableCommunities);
  const user = useSelector(getFullAuthUser);
  const { showUserGuidePopup, showInfoPopup } = useInfoPopup();
  const calendarConnecting = useSelector(getAppleCalendarConnectLoading);
  const calendarDisconnecting = useSelector(getAppleCalendarDisconnectLoading);
  const hasVerticalSpace = useMedia(`${verticalBreakpoints.md}`);

  const defaultValues = useMemo(() => {
    return user?.settings?.syncEventsAppleFromCommunities;
  }, [user, communities]);

  const connectedCommunityCalendars = useMemo(() => {
    return user.settings.appleCalendarUrl;
  }, [user.settings.appleCalendarUrl]);

  const isLanguageGerman = user.settings.language === 'de';
  const supportPageUrl = isLanguageGerman
    ? 'https://gumb.app/support/gumb-mit-apple-ical-kalender-verbinden/'
    : 'https://gumb.app/en/support/connect-gumb-with-apple-calender/';

  const { onSubmit, syncAppleFieldProps, setValue } = useChangeAppleSyncEvents(
    user?.id,
    {
      [FIELDS.SYNC_EVENTS_APPLE]: defaultValues,
    },
  );

  const isSmPlus = useMedia(breakpoints.smPlus);
  const isMdPlus = useMedia(breakpoints.mdPlus);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const isAppleConnected = user.settings.appleCalendarUrl?.length > 0 ?? false;
  const stepBlockRef = useRef<HTMLOListElement>(null);

  const getRowHeightForApple = useCallback(
    (type) => {
      if (type === 'google') {
        if (!isConnected) {
          return (isDownSmPlus ? 100 : 50) + 'px';
        }
      } else {
        if (!isAppleConnected) {
          return (isDownSmPlus ? 100 : 50) + 'px';
        }
      }

      const isExpanded = expanded;
      const communitiesHeight = (communities.length || 8) * 60;
      const extraOffset = !isSmPlus ? 65 : 0;
      const mainBlockHeight = !isMdPlus ? 100 : 50;
      const stepsHeight = stepBlockRef.current
        ? stepBlockRef.current.clientHeight
        : 0;

      return (
        extraOffset +
        (isExpanded
          ? mainBlockHeight + 60 + communitiesHeight + stepsHeight
          : mainBlockHeight) +
        'px'
      );
    },
    [isDownSmPlus, isSmPlus, isMdPlus, expanded, communities, isConnected],
  );

  const renderAppleCalendarIcon = () => (
    <div>
      <AppleLogo />
    </div>
  );

  const showPopup = () => {
    showUserGuidePopup({
      title: t('calendarConnection.appleConnectionPopup.title'),
      multiStep: [
        {
          image: Step1,
          message: t('calendarConnection.appleConnectionPopup.stepOne'),
        },
        {
          image: isLanguageGerman ? Step2German : Step2,
          message: t('calendarConnection.appleConnectionPopup.stepTwo'),
        },
        {
          image: isLanguageGerman ? Step3German : Step3,
          message: t('calendarConnection.appleConnectionPopup.stepThree'),
        },
        {
          image: isLanguageGerman ? Step4German : Step4,
          message: t('calendarConnection.appleConnectionPopup.stepFour'),
        },
      ],
      buttonText: t('common.ok'),
      cancelButtonText: t('common.cancel'),
      messageAlign: 'center',
    });
  };

  const handleConnectionInitiate = async () => {
    setValue(
      FIELDS.SYNC_EVENTS_APPLE,
      communities.map((community) => community.id),
    );

    dispatch(
      doLinkAppleCalendar({
        userId: user.id,
        data: { [FIELDS.SYNC_EVENTS_APPLE]: communities.map((c) => c.id) },
        onSuccess: () => {
          setExpanded((prev) => ({ ...prev, apple: true }));
          showPopup();
        },
      }),
    );
  };

  const handleDisconnect = () => {
    setValue(FIELDS.SYNC_EVENTS_APPLE, []);
    dispatch(
      doUnlinkAppleCalendar({
        userId: user.id,
        data: { [FIELDS.SYNC_EVENTS_APPLE]: [] },
      }),
    );
  };

  const getCalendarUrl = (communityId: string) => {
    return connectedCommunityCalendars.find(
      (cal) => cal.communityId === communityId,
    )?.['url'];
  };

  const handleChange = (fieldName: string, communityId: string) => {
    const currentValue = syncAppleFieldProps.value;

    syncAppleFieldProps.onChange(
      currentValue.includes(communityId)
        ? currentValue.filter((i) => i !== communityId)
        : currentValue.concat([communityId]),
    );

    onSubmit();

    if (syncAppleFieldProps.value.length === 0)
      setExpanded((prev) => ({ ...prev, apple: false }));
  };

  const onCheckBoxChange = (communityId: string) => {
    if (syncAppleFieldProps.value.includes(communityId)) {
      showInfoPopup({
        title: t('calendarConnection.appleUncheckBoxPopup.title'),
        richMessage: t('calendarConnection.appleUncheckBoxPopup.message'),
        buttonText: t('calendarConnection.appleUncheckBoxPopup.okButton'),
        messageAlign: 'left',
        size: 'lg',
        type: 'warning',
        onButtonClick: () =>
          handleChange(FIELDS.SYNC_EVENTS_APPLE, communityId),
      });
      return;
    }
    handleChange(FIELDS.SYNC_EVENTS_APPLE, communityId);
  };

  const buttonStyle = css`
    text-transform: none;
    font-size: 14px;
    font-family: 'open sans';
    width: 240px;
  `;
  const comminuteTitleStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    width: 'calc(100% - 28px)',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    minWidth: '30px',
  };

  return (
    <CalendarsCard>
      <CalendarsCard.Row
        expanded={expanded}
        style={{
          height: getRowHeightForApple('apple'),
        }}
      >
        <CalendarsCard.Row.Main>
          {!isConnected ? (
            <BeforeConnectedWrapper>
              <CalendarTitle>
                <AppleCalendarIcon />
                <span>{t('calendarConnection.appleCalendar')}</span>
              </CalendarTitle>
              {calendarConnecting ? (
                <LoaderWrapper width="240px">
                  <Loader type="button" size="28px" thickness={2} />
                </LoaderWrapper>
              ) : (
                <ConnectButtonWrapper>
                  <Button
                    width="max-content"
                    variant="primary"
                    IconComponent={renderAppleCalendarIcon}
                    onClick={handleConnectionInitiate}
                    {...{ rounded: true }}
                    buttonStyles={buttonStyle}
                  >
                    <p>{t('calendarConnection.connectWithApple')}</p>
                  </Button>
                </ConnectButtonWrapper>
              )}
            </BeforeConnectedWrapper>
          ) : (
            <AfterConnectedWrapper>
              <CalendarTitle>
                <AppleCalendarIcon />
                <span>{t('calendarConnection.appleCalendar')}</span>
              </CalendarTitle>
              <div>
                <span onClick={() => onCommunityBlockToogle('apple')}>
                  {`${t('calendarConnection.selectCommunities')} (${
                    syncAppleFieldProps.value.length
                  }/${communities.length})`}
                </span>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                  }}
                >
                  <Lottie
                    options={{
                      animationData: successCheck,
                      loop: false,
                      autoplay: true,
                    }}
                    width="40px"
                    height="40px"
                  />
                  {t('calendarConnection.connected')}
                </div>
              </div>
              <InfoPopup
                title={t('calendarConnection.appleDisconnectPopup.title')}
                richMessage={t(
                  'calendarConnection.appleDisconnectPopup.message',
                )}
                messageAlign="left"
                type="warning"
                buttonText={t(
                  'calendarConnection.appleDisconnectPopup.okButton',
                )}
                cancelButtonText={t('common.cancel')}
                size={hasVerticalSpace ? 'lg' : 'md'}
                onButtonClick={handleDisconnect}
              >
                {calendarDisconnecting ? (
                  <LoaderWrapper width="120px">
                    <Loader type="button" size="28px" thickness={2} />
                  </LoaderWrapper>
                ) : (
                  <ConnectionLabel>
                    {t('calendarConnection.disconnect')}
                  </ConnectionLabel>
                )}
              </InfoPopup>
            </AfterConnectedWrapper>
          )}
        </CalendarsCard.Row.Main>

        {isConnected && (
          <StepsBlock ref={stepBlockRef}>
            <StepsBlock.Item
              fontSize="16px"
              marginBottom="8px"
              highlight
              borderRadius="4px"
            >
              {t('calendarConnection.appleConnectionSteps.headerOne')}{' '}
              <strong>{'(iCal)'}</strong>{' '}
              {t('calendarConnection.appleConnectionSteps.headerOneSplit')}
              <StyledAnchorTag
                display="inline-flex"
                marginLeft="5px"
                onClick={showPopup}
                marginRight="5px"
              >
                {t('calendarConnection.appleConnectionSteps.here')}
              </StyledAnchorTag>
              {/* {'| '} */}
              {'\n'}
              {t('calendarConnection.appleConnectionSteps.headerTwo')}
              <StyledAnchorTag
                display="inline-flex"
                marginLeft="5px"
                target="_blank"
                href={supportPageUrl}
              >
                {t('calendarConnection.appleConnectionSteps.here')}
                <Redirect />
              </StyledAnchorTag>
            </StepsBlock.Item>
            <StepsBlock.Item>
              {t('calendarConnection.appleConnectionSteps.stepOne')}
            </StepsBlock.Item>
            <StepsBlock.Item>
              {t('calendarConnection.appleConnectionSteps.stepTwo')}
            </StepsBlock.Item>
            <StepsBlock.Item>
              {t('calendarConnection.appleConnectionSteps.stepThree')}
            </StepsBlock.Item>
            <StepsBlock.Item>
              {t('calendarConnection.appleConnectionSteps.stepFour')}
            </StepsBlock.Item>
          </StepsBlock>
        )}

        {isConnected && (
          <ListWrapper>
            <List>
              <List.Body>
                {communities?.length > 0 ? (
                  communities.map((item) => {
                    const calendarUrl = getCalendarUrl(item.id);
                    return (
                      <List.Row key={item.id}>
                        <List.Row.Cell isApple={true}>
                          <CheckBox
                            isSelected={syncAppleFieldProps.value.includes(
                              item.id,
                            )}
                            name={FIELDS.SYNC_EVENTS_APPLE}
                            reduceMargin
                            onChange={() => {
                              onCheckBoxChange(item.id);
                            }}
                            width="20px"
                            checkBoxWidth="20px"
                            disabled={settingsSaving}
                          />
                        </List.Row.Cell>
                        <List.Row.Cell isApple={true}>
                          <LogoWithTitle
                            logo={item.logo}
                            title={item.name}
                            imageWidth="40px"
                            showTooltip
                            comminuteTitleStyle={comminuteTitleStyle}
                          />
                        </List.Row.Cell>

                        <List.Row.Cell isApple={true}>
                          {calendarUrl ? (
                            <LinkText
                              url={calendarUrl}
                              withCopy
                              copyText={t('common.copyLink')}
                              copiedText={t('common.copied')}
                            />
                          ) : (
                            <span
                              style={{
                                backgroundColor: '#faf2f2',
                                padding: '5px',
                                borderRadius: '5px',
                              }}
                            >
                              {t('calendarConnection.cantSeeICal')} ✔
                            </span>
                          )}
                        </List.Row.Cell>
                        <List.Row.Cell isApple={true}>
                          <StyledAnchorTag
                            target="_blank"
                            href={supportPageUrl}
                            key={item.id}
                          >
                            {t('common.tutorial')}
                            <Redirect />
                          </StyledAnchorTag>
                        </List.Row.Cell>
                      </List.Row>
                    );
                  })
                ) : (
                  <NoCommunitiesBlock>
                    <UsersIcon />
                    <span>{t('calendarConnection.noCommunities')}</span>
                  </NoCommunitiesBlock>
                )}
              </List.Body>
            </List>
          </ListWrapper>
        )}
      </CalendarsCard.Row>
    </CalendarsCard>
  );
};

export default ApplceCalendar;
