import React from 'react';
import { useSelector } from 'react-redux';

import { RadioButton } from 'components';
import { getKeyByValue } from 'utils/common';
import { LANGUAGES, PROFILE_LANGUAGE_TYPES } from 'configs';
import { useSettings } from '../hooks';
import { getFullAuthUser, getSettings } from 'store/selectors/auth';
import { ProfileLanguage } from 'types';

import { StyledWrapper } from '../styled';

const Languages = () => {
  const user = useSelector(getFullAuthUser);
  const settings = useSelector(getSettings);
  const { register, onSubmit } = useSettings(user.id);

  return (
    <form>
      {Object.values(ProfileLanguage).map((language) => (
        <StyledWrapper key={language}>
          <RadioButton
            register={register}
            name="language"
            label={LANGUAGES[getKeyByValue(PROFILE_LANGUAGE_TYPES, language)]}
            value={language}
            checked={language === settings.language}
            onChange={onSubmit}
          />
        </StyledWrapper>
      ))}
    </form>
  );
};

export default Languages;
