import styled from 'styled-components';

import { breakpoints } from 'theme';

const Layout = styled.div`
  width: -webkit-fill-available;
  height: 100svh;
  text-align: left;
  position: absolute;
  @media ${breakpoints.downMd} {
    width: 100vw;
  }
`;
Layout.Data = styled.div``;

Layout.Header = styled.div`
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  position: sticky;
  top: 0;
`;

Layout.Inner = styled.div`
  height: calc(100svh - 50px);
  background-color: ${({ theme }) => theme.color.dew};
  @media ${breakpoints.downMdPlus} {
    padding-bottom: 50vh;
    > :nth-child(even) {
      hr {
        margin: 0;
      }
    }
  }
`;

Layout.Title = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  padding: 15px 0;
  text-transform: uppercase;
  text-align: left;
`;

Layout.Details = styled.div`
  padding: 15px 20%;
  @media ${breakpoints.downMdPlus} {
    padding: 15px 25px;
  }
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
`;

const Details = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

Details.Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px 0;
  flex-wrap: wrap;

  @media ${breakpoints.downSmPlus} {
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

Details.Item.Label = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '220px')};
`;

Details.Item.Value = styled.div`
  width: calc(100% - 220px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  strong {
    font-weight: 600;
    margin-right: 4px;
  }

  a {
    color: ${({ theme }) => theme.color.brightTurquoise};
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    margin-left: 20px;

    &:hover {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  @media ${breakpoints.downSmPlus} {
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;

    & > span {
      text-align: right;
    }

    strong {
      margin-right: 0;
    }
  }
`;

const AdditionalInfo = styled.span`
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  margin: 25px 20% 15px 0px;
  @media ${breakpoints.downMdPlus} {
    margin: 25px 0% 15px 0px;
  }
`;

const SubscriptionsActionButton = styled.a`
  background-color: ${({ theme }) => theme.color.baliHai};
  border-radius: 4px;
  color: #fff;
  display: block;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 11px 15px;
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  @media ${breakpoints.downSmPlus} {
    font-size: 11px;
    padding: 9px 12px;
    margin-left: 15px;
    text-align: center;
  }
`;

export { Layout, Heading, Details, SubscriptionsActionButton, AdditionalInfo };
