import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getClient, getCurrentChat } from 'store/selectors/chat';
import ChatComponent from 'components/Chat';
import { doSetCurrentChat } from 'store/actionCreators/chat';

const GroupChat = () => {
  const { communityId, groupId } = useParams();
  const dispatch = useDispatch();
  const channel = useSelector(getCurrentChat);
  const client = useSelector(getClient);

  useEffect(() => {
    dispatch(doSetCurrentChat({ communityId, groupId }));
  }, [communityId, groupId, doSetCurrentChat]);

  return channel ? <ChatComponent channel={channel} client={client} /> : null;
};

export default GroupChat;
