export const toast = (
  message: string,
  config: { appearance: 'success' | 'error'; autoDismiss: boolean },
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { ReactToastProviderRef } = window;
  if (!ReactToastProviderRef) {
    // eslint-disable-next-line no-console
    console.error('Could not get react-toast ref');
    return;
  }
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    current: { add },
  } = ReactToastProviderRef;
  add(message, config);
};
