import styled from 'styled-components';

import { SettingsIcon } from 'static';
import { breakpoints } from 'theme';

export const StyledButton = styled.div`
  display: flex;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }

  @media ${breakpoints.downMd} {
    margin-right: 5px;
  }
`;

export const StyledLabel = styled.span`
  margin-right: 12px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledIcon = styled(SettingsIcon)`
  user-select: none;
`;
