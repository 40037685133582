import { decodeJwt } from 'jose';
import { get } from 'lodash/fp';

import { axiosFormInstance as axios, axiosInstance } from './index';
import { ProfileLanguage, ProfilePhone, ProfileSocialLink } from '../types';

export const requestProfileEdit = async (
  user: {
    description?: string | null;
    links?: ProfileSocialLink[];
    dob?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    logo?: string | null;
    profileName?: string;
    street?: string | null;
    region?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
    phones?: ProfilePhone[];
    timeZone?: string | null;
    allowedToEdit?: string[];
  },
  id: string,
  communityId: string,
) => {
  const formData = await new FormData();

  await Object.keys(user).forEach((key) => {
    if ((key === 'phones' || key === 'links') && user[key] !== null) {
      if (user[key][0]) {
        formData.set(key, JSON.stringify(user[key]));
      } else {
        formData.set(key, JSON.stringify([]));
      }
    } else if (key === 'logo') {
      if (typeof user[key] === 'string') {
        return null;
      } else {
        formData.set(key, user[key] || '');
      }
    } else if (key === 'postalCode') {
      formData.append(key, user[key] || '');
    } else if (user[key] !== null) {
      formData.append(key, user[key]);
    }
  });

  const { data } = await axios.put(
    `/users/${id}/profile${communityId ? `?communityId=${communityId}` : ''}`,
    formData,
  );

  return data;
};

export const getNotifications = async (userId: string) => {
  const response = await axiosInstance.get(
    `/users/${userId}/notifications?limit=100`,
  );

  return response.data;
};

export const markNotificationsAsRead = async (
  userId: string,
  notifications: string[],
) => {
  const response = await axiosInstance.post(
    `/users/${userId}/notifications/read`,
    { notifications },
  );

  return response.data;
};

export const changeProfileSettings = async (
  userId: string,
  data: {
    emailNotification?: boolean;
    inAppNotification?: boolean;
    language?: ProfileLanguage;
    pushNotification?: boolean;
    syncEventsGoogleFromCommunities?: string[];
    timeFormat?: 24 | 12;
    syncEventsAppleFromCommunities?: string[];
  },
) => {
  const response = await axiosInstance.put(`/users/${userId}/settings`, data);

  return response.data;
};

export const changeProfileLanguage = async (
  userId: string,
  language: string,
) => {
  const response = await axiosInstance.put(
    `/users/${userId}/settings/language`,
    { language },
  );

  return response.data;
};

export const sendChangeEmailMessage = async (
  userId: string,
  newEmail: string,
) => {
  const response = await axiosInstance.get(
    `/users/${userId}/changeEmail?newEmail=${newEmail}`,
  );

  return response.data;
};

export const postChangeEmail = async (
  userName: string,
  code: string,
  newEmail: string,
) => {
  const response = await axiosInstance.post(
    `/users/${userName}/changeEmail?code=${code}&newEmail=${newEmail}`,
  );

  return response.data;
};

export const deleteProfile = async ({
  id,
  idToken,
}: {
  id: string;
  idToken: string;
}) => {
  const decodedToken = decodeJwt(idToken);
  const authorizedViaGoogle = !!get('identities', decodedToken);
  const username = get('cognito:username', decodedToken);
  const body = authorizedViaGoogle ? { data: { username } } : {};
  const response = await axiosInstance.delete(`/users/${id}`, body);

  return response.data;
};

export const deleteCognitoProfile = async (userName: string) => {
  try {
    await axiosInstance.delete(`/users/cognito/${userName}`);
  } catch (_) {
    return true;
  }

  return true;
};

export const putCalendarToken = async (code: string) => {
  const response = await axiosInstance.put('/users/calendarToken', {
    code,
  });

  return response.data;
};

export const revokeCalendarToken = async () => {
  const response = await axiosInstance.delete('/users/calendarToken');

  return response.data;
};
