export enum EXPORT_FIELDS {
  TITLE = 'event_name',
  DESCRIPTION = 'event_description',
  LOCATION = 'event_location',
  DATE = 'event_date (DD-MM-YYYY)',
  FROM = 'event_start_time',
  TO = 'event_end_time',
  EVENT_TYPE_NAME = 'event_type_name',
  ALL_DAY = 'all_day_event',
}

export const exportFields = [
  EXPORT_FIELDS.TITLE,
  EXPORT_FIELDS.EVENT_TYPE_NAME,
  EXPORT_FIELDS.LOCATION,
  EXPORT_FIELDS.DATE,
  EXPORT_FIELDS.FROM,
  EXPORT_FIELDS.TO,
  EXPORT_FIELDS.DESCRIPTION,
];
