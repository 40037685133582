import { EditIcon } from 'static';
import styled from 'styled-components';

export const FieldEditIcon = styled(EditIcon)`
  width: 12px !important;
  height: 12px !important;
  position: absolute;
  cursor: pointer;
  top: -3px;
  right: -4px;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  border: 1px solid #ebf2f7;
  background-color: #f7faf9;
  transition: background-color 0.3s ease;
  border-color: ${({ fillColor }) => fillColor};
  &:hover {
    background-color: #e9efedc9;
  }
  & > svg {
    display: flex;
    width: ${({ width }) => width};
    height: ${({ width }) => width};
    path {
      fill: ${({ fillColor }) => fillColor};
    }
  }
  &:hover ${FieldEditIcon} {
    path {
      fill: ${({ theme }) => theme.color.black};
    }
  }
`;
