import styled from 'styled-components';

export const Container = styled.div`
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 700px;
  margin: -7px auto auto 40px;
  flex-wrap: wrap;
`;

export const StyledDateWrapper = styled.div`
  width: 325px;
  height: 67px;
  margin: 0 12px;

  &:last-of-type {
    margin-top: 27px;
  }
`;
