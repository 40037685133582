import { axiosInstance as axios } from '../index';
import { EditCommunityTaskFormData } from '../../sidebars/EditCommunityTask/form';

export const requestCommunityTasks = async (id: string) => {
  const response = await axios.get(`/communities/${id}/eventTask`);
  return response.data;
};

export const requestCreateCommunityTask = async (
  communityId: string,
  data: EditCommunityTaskFormData,
) => {
  const response = await axios.post(
    `/communities/${communityId}/eventTask`,
    data,
  );
  return response.data;
};

export const requestEditCommunityTask = async (
  communityId: string,
  id: string,
  data: EditCommunityTaskFormData,
) => {
  const response = await axios.put(
    `/communities/${communityId}/eventTask/${id}`,
    data,
  );
  return response.data;
};

export const requestDeleteCommunityTask = async (
  communityId: string,
  id: string,
) => {
  const response = await axios.delete(
    `/communities/${communityId}/eventTask/${id}`,
  );
  return response.data;
};
