import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';

export const FIELDS = { ALLOWED_TO_EDIT: 'allowedToEdit' };

export const useAllowedToEditForm = (
  defaultValues: { value: string; label: string }[],
) => {
  const resolver: Resolver<{ value: string; label: string }[]> = yupResolver(
    yup.object().shape({
      [FIELDS.ALLOWED_TO_EDIT]: yup.array().of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string().required(),
        }),
      ),
    }),
  );
  const formData = useForm({
    resolver,
  });
  const watcher = formData.watch(FIELDS.ALLOWED_TO_EDIT, defaultValues);

  return {
    ...formData,
    watcher,
  };
};
