import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  useFieldArray,
  useController,
  useWatch,
  Resolver,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FIELDS } from './types';
import { CommunityNotificationValues } from 'types';

export const useCommunityNotificationsForm = (
  defaultValues: CommunityNotificationValues,
) => {
  const { t } = useTranslation();

  const resolver: Resolver<CommunityNotificationValues> = yupResolver(
    yup.object().shape({
      [FIELDS.ENABLED]: yup.bool(),
      [FIELDS.FIELDS]: yup.array().of(yup.string()),
      [FIELDS.REPLIES]: yup.array().of(yup.string()),
      [FIELDS.REMINDERS]: yup.array().of(
        yup.object().shape({
          value: yup
            .number()
            .typeError(t('errors.integer'))
            .integer(t('errors.integer'))
            .required(t('errors.required')),
          modifier: yup
            .string()
            .oneOf(['minutes', 'hours', 'days'])
            .required(t('errors.required')),
        }),
      ),
    }),
  );

  const form = useForm<CommunityNotificationValues>({
    resolver,
    defaultValues,
  });
  const reminderFieldsProps = useFieldArray({
    name: FIELDS.REMINDERS,
    control: form.control,
  });
  const { field: updatedFieldsProps } = useController({
    name: FIELDS.FIELDS,
    control: form.control,
    defaultValue: defaultValues.fields || [],
  });
  const { field: selectedRepliesProps } = useController({
    name: FIELDS.REPLIES,
    control: form.control,
    defaultValue: defaultValues.replies || [],
  });

  const values = useWatch({
    name: [FIELDS.ENABLED, FIELDS.FIELDS, FIELDS.REPLIES, FIELDS.REMINDERS],
    control: form.control,
    defaultValue: defaultValues,
  });

  return {
    ...form,
    updatedFieldsProps,
    selectedRepliesProps,
    reminderFieldsProps,
    values,
  };
};
