import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { changeProfileLanguage } from 'services/user';
import { clearHeaders } from 'services';
import { getAmplifyAuthErrorMessage } from '../utils';
import { useInfoPopup } from 'hooks';

import { MessageContainer } from '../styled';

const VerifyPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { showInfoPopup } = useInfoPopup();

  const onFailure = (error) => {
    const title = getAmplifyAuthErrorMessage(t, error);

    showInfoPopup({
      type: 'error',
      title: title === t('auth.userNotFound') ? t('auth.codeExpired') : title,
    });

    navigate('/signup');
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const code = search.get('c');
    const userName = search.get('u');
    const role = search.get('role');
    const firstName = search.get('fName');
    const lastName = search.get('lName');
    const communityId = search.get('communityId');
    const invitationId = search.get('invitationId');
    const invitationCode = search.get('invitationCode');
    const groups = search.get('groups');

    if (!code || !userName) {
      showInfoPopup({
        type: 'error',
        title: t('errors.invalidRequest'),
      });
      navigate('/');
    }

    (async () => {
      await Auth.signOut();
      clearHeaders();

      Auth.confirmSignUp(userName, code, {
        clientMetadata: {
          firstName,
          lastName,
          role,
          communityId,
          groups,
          language: i18n.language,
        },
      })
        .then(() => {
          changeProfileLanguage(userName, i18n.language);

          showInfoPopup({
            type: 'success',
            title: t('auth.confirmed'),
          });

          if (invitationId) {
            if (invitationCode) {
              navigate(
                `/community/${invitationId}?invitationCode=${invitationCode}`,
              );
            } else {
              navigate(`/communities/${invitationId}/landing`);
            }

            return;
          }

          navigate('/');
        })
        .catch((err) => {
          onFailure(err);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return <MessageContainer>{t('auth.verifying')}</MessageContainer>;
};

export default VerifyPage;
