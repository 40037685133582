import styled, { css } from 'styled-components';

export const StyledForm = styled.form`
  height: 100%;
`;

export const StyledMainInfoBlock = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
`;

export const StyledImageNameBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledName = styled.span`
  margin-left: 13px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const StyledAboutBlock = styled.p`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.shark};
  font-weight: 400;
`;

export const StyledSocialLinksBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const StyledSelectBlock = styled.div`
  padding-top: 10px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
`;

export const StyledInfoBlock = styled.div`
  padding-top: 7px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  display: grid;
  grid-gap: 11px;
  &:last-child {
    padding-bottom: 49px;
    border-bottom: none;
  }
`;

export const StyledButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledDeleteButtonBlock = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
`;
