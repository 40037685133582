import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReplyStates } from 'constants/index';
import { EditEventType } from 'constants/sidebars';
import {
  ReplyFormData,
  useReplyForm,
} from '../../pages/UserProfileEvents/form';
import {
  doCreateEventReply,
  doCreateOverallEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
  doUpdateOverallEventReply,
  doDeleteOverallEventReply,
} from 'store/actionCreators/currentCommunity';
import { Event, EventReply } from 'types';

export const useGiveReply = ({
  event,
  eventDate,
  sidebarState,
  subReply,
  filterFrom,
  filterTo,
  userId,
  closeSidebar,
}: {
  event: Event;
  eventDate: Date;
  sidebarState: ReplyStates;
  subReply: EventReply;
  filterFrom: Date;
  filterTo: Date;
  userId: string;
  closeSidebar: () => void;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const form = useReplyForm({ reply: subReply });

  const onUpdateSuccess = () => {
    closeSidebar();
    addToast(t('event.updateEventReplySuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onFailure = (message) => {
    addToast(message, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const onCreateSuccess = () => {
    closeSidebar();
    addToast(t('event.createEventReplySuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onCreateReply = (
    type: EditEventType,
    dataToPost: ReplyFormData,
    event: Event,
  ) => {
    let tasks = dataToPost.tasks.filter(
      (li, index, arr) => arr.indexOf(li) === index,
    );
    if (!tasks) {
      tasks = [];
    }
    const commonFields = {
      onSuccess: onCreateSuccess,
      onFailure,
      filterFrom,
      filterTo,
      userId: userId,
      data: { ...dataToPost, tasks, date: eventDate.toISOString() },
      eventId: event.id,
      eventSeriesId: event.eventSeriesId,
      type,
      from: type === EditEventType.SINGLE_AND_FOLLOW ? event.from : null,
    };

    if (event.communityId) {
      dispatch(
        doCreateOverallEventReply({
          communityId: event.communityId,
          ...commonFields,
        }),
      );
    } else {
      dispatch(
        doCreateEventReply({
          groupId: event.groupId,
          ...commonFields,
        }),
      );
    }
  };

  const onUpdateReply = (
    type: EditEventType,
    dataToPost: ReplyFormData,
    event: Event,
  ) => {
    let tasks = dataToPost.tasks.filter(
      (li, index, arr) => arr.indexOf(li) === index,
    );
    if (!tasks) {
      tasks = [];
    }
    const commonFields = {
      onSuccess: onUpdateSuccess,
      onFailure,
      filterFrom,
      filterTo,
      userId: userId,
      eventReplyId: subReply.id,
      eventSeriesId: event.eventSeriesId,
      data: { ...dataToPost, tasks },
      eventId: event.id,
      type,
      from: type === EditEventType.SINGLE_AND_FOLLOW ? event.from : null,
    };

    if (event.communityId) {
      dataToPost?.eventResponseId
        ? dispatch(
            doUpdateOverallEventReply({
              communityId: event.communityId,
              ...commonFields,
            }),
          )
        : dispatch(
            doDeleteOverallEventReply({
              communityId: event.communityId,
              ...commonFields,
            }),
          );
    } else {
      dataToPost?.eventResponseId
        ? dispatch(
            doUpdateEventReply({
              groupId: event.groupId,
              ...commonFields,
            }),
          )
        : dispatch(
            doDeleteEventReply({
              groupId: event.groupId,
              ...commonFields,
            }),
          );
    }
  };

  const onSubmit = (type: EditEventType) =>
    form.handleSubmit(async (data) => {
      const dataToPost = { ...data };

      if (sidebarState === ReplyStates.UPDATE_THIS) {
        onUpdateReply(type, dataToPost, event);
      } else {
        onCreateReply(type, dataToPost, event);
      }
    })();

  return {
    onSubmit,
    ...form,
  };
};
