import { Profile, User } from './user';
import { CommunityPermission, OverallStatistics } from './community';

export enum GroupRoles {
  ADMIN = 'admin',
  GROUP_LEAD = 'group_lead',
  MEMBER = 'member',
}

export type GroupPermission = {
  role: GroupRoles;
  communityId: string;
  userId: string;
};

export type GroupUser = User & {
  groupPermission: GroupPermission;
  communityPermission?: CommunityPermission;
  profile?: Profile;
};

export type Group = {
  id: string;
  name: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  communityId: string;
  users: { count: number; data: GroupUser[] };
  groupPermission?: GroupPermission;
};

export type CurrentGroup = {
  id: string;
  name: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  communityId: string;
  users: {
    count: number;
    data: GroupUser[];
  };
  statistic: OverallStatistics;
};
