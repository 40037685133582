import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledWrapper = styled.div`
  width: 500px;
  margin: auto;
  @media ${breakpoints.downSmPlus} {
    width: 94%;
  }
`;

export const StyledCoverInputBlock = styled.div`
  margin-top: 38px;
`;

export const StyledLogoInputBlock = styled.div`
  margin-top: 27px;
  margin-bottom: 16px;
`;

export const DynamicInputsBlock = styled.div`
  margin-top: 26px;
  margin-bottom: 26px;
`;

export const StyledButtonBlock = styled.div`
  margin-top: 62px;
  margin-bottom: 13px;

  button {
    margin: auto;
  }
`;
