import {
  useEventsToRender,
  useFooterData,
} from '../pages/CommunityOverallScheduler/Schedule/hooks';
import {
  Community,
  Event,
  EventReply,
  EventTask,
  EventToRender,
  FooterData,
  User,
  SchedulerFilter,
  CommunityTotal,
  ModalTypes,
  CommunityRoles,
} from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from '../store/selectors/auth';
import { getCurrentUserInCommunity } from '../store/selectors/currentCommunity';
import { openModal } from '../store/actionCreators/modal';
import {
  EVENT_DETAILS,
  GIVE_REPLY,
  TASK_DETAILS,
  VIEW_MEMBER,
} from '../constants/sidebars';
import { getEventDetailsSideBarWidth } from '../utils/getEventDetailsSideBarWidth';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { initialFiltersState } from 'sidebars/CreateEventOverall/utils';

export const useSchedulerPageData = ({
  events,
  community,
  filters,
  total,
}: {
  events: Event[];
  community: Community;
  filters: SchedulerFilter;
  total: CommunityTotal;
}): {
  eventsToRender: EventToRender[];
  footerData: FooterData;
  openTaskDetailsSidebar: (task: EventTask, taskMembersIds: string[]) => void;
  openGiveReplySidebar: (args: {
    id: string;
    eventId: string;
    eventDate: Date;
    subReply?: EventReply;
    subTaskReplies?: string[];
    isViewOnly: boolean;
    isAdmin: boolean;
    name: string;
  }) => void;
  openViewMemberSidebar: (user: User) => void;
  onHeaderClick: (eventId: string, date: string) => void;
  onFooterClick: (eventId: string, date: string, responseId: string) => void;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const currentUser = useSelector(getCurrentUserInCommunity);
  const timeFormat = useSelector(getSettings)?.timeFormat;
  const canCreateEvent = [CommunityRoles.OWNER, CommunityRoles.ADMIN].includes(
    currentUser.communityPermission.role,
  );
  let eventsToRender = [];

  const handleGroupEventPopupOpen = () =>
    dispatch(
      openModal({
        type: ModalTypes.COMMUNITY_POPUP,
      }),
    );

  const openCreateEventSidebar = () => {
    dispatch(
      openModal({
        type: ModalTypes.CREATE_EVENT,
        placement: 'right',
        data: {
          groupId: groupId,
          filterFrom: initialFiltersState().from,
          filterTo: initialFiltersState().to,
        },
      }),
    );
  };

  const eventsToRenderArray = useEventsToRender({
    community,
    events,
    eventTypes: community?.eventTypes,
    eventResponses: community?.eventResponses,
    eventTasks: community?.eventTasks,
    filters,
    timeFormat,
    user: currentUser,
  });

  eventsToRender = canCreateEvent
    ? [
        ...(eventsToRenderArray || []),
        {
          isPlusIcon: true,
          title: `<span class="plus-icon">+</span><p class="plus-title">${t(
            'common.create',
          )}</p>`,
          recipients: [],
          id: groupId ? 'groupPlus' : 'overAllPlus',
          tasks: [],
          subEventReplies: [],
          subEventTaskReplies: [],
          date: '',
          description: t('community.plusIconSubText'),
          isAfterRegDeadline: false,
          eventType: '',
          dateString: '',
          time: null,
          color: '#EBF2F7',
        },
      ]
    : eventsToRenderArray;

  const footerData = useFooterData(
    community?.eventResponses,
    eventsToRenderArray,
    total,
  );

  const openTaskDetailsSidebar = (
    task: EventTask,
    taskMembersIds: string[],
  ) => {
    dispatch(
      openModal({
        type: TASK_DETAILS,
        placement: 'right',
        data: {
          task,
          taskMembersIds,
        },
      }),
    );
  };

  const openGiveReplySidebar = ({
    id,
    eventId,
    eventDate,
    subReply,
    subTaskReplies,
    isViewOnly,
    isAdmin,
    name,
  }: {
    id: string;
    eventId: string;
    eventDate: Date;
    subReply?: EventReply;
    subTaskReplies?: string[];
    isViewOnly: boolean;
    isAdmin: boolean;
    name: string;
  }) => {
    dispatch(
      openModal({
        type: GIVE_REPLY,
        placement: 'right',
        data: {
          userId: id,
          filterFrom: filters.from,
          filterTo: filters.to,
          eventId,
          date: eventDate,
          subReply: subReply
            ? {
                ...subReply,
                tasks: subTaskReplies,
              }
            : null,
          isViewOnly,
          isAdmin,
          name,
        },
      }),
    );
  };

  const openViewMemberSidebar = (user: User) =>
    dispatch(
      openModal({
        type: VIEW_MEMBER,
        placement: 'right',
        data: user,
      }),
    );

  const onHeaderClick = (eventId: string, date: string) => {
    if (eventId === 'overAllPlus') {
      handleGroupEventPopupOpen();
    } else if (eventId === 'groupPlus') {
      openCreateEventSidebar();
    } else {
      dispatch(
        openModal({
          type: EVENT_DETAILS,
          placement: 'right',
          data: {
            eventId,
            date,
            width: getEventDetailsSideBarWidth(community.eventResponses.length),
            filterFrom: filters.from,
            filterTo: filters.to,
          },
        }),
      );
    }
  };

  const onFooterClick = (
    eventId: string,
    date: string,
    eventResponseId: string,
  ) => {
    dispatch(
      openModal({
        type: EVENT_DETAILS,
        placement: 'right',
        data: {
          eventId,
          date,
          width: getEventDetailsSideBarWidth(community.eventResponses.length),
          eventResponseId,
          openTotal: true,
          filterFrom: filters.from,
          filterTo: filters.to,
        },
      }),
    );
  };

  return {
    eventsToRender,
    footerData,
    openTaskDetailsSidebar,
    openGiveReplySidebar,
    openViewMemberSidebar,
    onHeaderClick,
    onFooterClick,
  };
};
