import React, { FC } from 'react';
import { EditButtonWrapper, StyledEditIcon, StyledEditText } from './styled';

interface ProfileEditButtonProps {
  openEditSidebar?: () => void;
  title: string;
  titleVisible?: boolean;
  style?: { [key: string]: string | number };
}

const ProfileEditButton: FC<ProfileEditButtonProps> = ({
  openEditSidebar,
  title,
  style,
  titleVisible,
}) => (
  <EditButtonWrapper onClick={openEditSidebar} style={style}>
    <StyledEditIcon />
    <StyledEditText titleVisible={titleVisible}>{title}</StyledEditText>
  </EditButtonWrapper>
);

export default ProfileEditButton;
