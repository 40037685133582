import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useController, useForm, useWatch } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RegDeadlineTimeUnit } from 'configs/deadlineTimeUnits';
import { Event, RepeatedEventRule } from 'types';
import { mapEventToFormValues } from './utils';
import {
  RepeatEventFrequency,
  RepeatEventOption,
} from '../../configs/frequency';
import { TimeIntervalOption } from '../../configs/intervalTimeUnits';
import { getEventTypes } from 'store/selectors/eventTypes';

export enum FIELDS {
  TITLE = 'title',
  EVENT_TYPE_ID = 'eventTypeId',
  LOCATION = 'location',
  START_TIME = 'startTime',
  END_DATE = 'endDate',
  END_TIME = 'endTime',
  FROM = 'from',
  TO = 'to',
  TASKS = 'tasks',
  RECIPIENTS = 'recipients',
  DOCUMENTS = 'documents',
  REPEATED_RULES = 'repeatedRules',
  REPEATED_RULES_OPTION = 'repeatedRulesOption',
  REPEATED_RULES_VALUE = 'repeatedRulesValue',
  REPEATED_RULES_MODIFIER = 'repeatedRulesModifier',
  ALL_DAY = 'allDay',
  DESCRIPTION = 'description',
  REGISTRATION_DEADLINE_VALUE = 'registrationDeadlineValue',
  REGISTRATION_DEADLINE_UNIT = 'registrationDeadlineUnit',
  WEEK_DAYS = 'weekDays',
  EVENT_RESPONSE_ID = 'defaultEventResponseId',
}

export type EventFormData = {
  [FIELDS.TITLE]: string;
  [FIELDS.EVENT_TYPE_ID]: { label: string; value: string } | null;
  [FIELDS.LOCATION]: string;
  [FIELDS.START_TIME]: Date;
  [FIELDS.END_DATE]: Date;
  [FIELDS.END_TIME]: Date;
  [FIELDS.FROM]: Date;
  [FIELDS.TO]: Date;
  [FIELDS.RECIPIENTS]: string[];
  [FIELDS.DOCUMENTS]: string[];
  [FIELDS.REPEATED_RULES]: RepeatedEventRule;
  [FIELDS.REPEATED_RULES_OPTION]: RepeatEventOption;
  [FIELDS.REPEATED_RULES_VALUE]: string;
  [FIELDS.REPEATED_RULES_MODIFIER]: TimeIntervalOption;
  [FIELDS.ALL_DAY]: boolean;
  [FIELDS.DESCRIPTION]: string;
  [FIELDS.REGISTRATION_DEADLINE_VALUE]: number | null;
  [FIELDS.REGISTRATION_DEADLINE_UNIT]?: RegDeadlineTimeUnit;
  [FIELDS.WEEK_DAYS]?: { label: string; value: number }[];
  [FIELDS.TASKS]: string[];
  [FIELDS.EVENT_RESPONSE_ID]: string | null;
};

export const useEventMainInfoForm = ({ event }: { event: Event }) => {
  const { t } = useTranslation();
  const eventTypes = useSelector(getEventTypes) || [];

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<EventFormData> = yupResolver(
    yup.object().shape({
      [FIELDS.TITLE]: yup.string().required().max(200).min(1).trim(),
      [FIELDS.FROM]: yup.date().required(),
      [FIELDS.END_DATE]: yup
        .date()
        .nullable()
        .when(
          [FIELDS.REPEATED_RULES_OPTION, FIELDS.FROM],
          (repeatedRulesOption, from) => {
            return repeatedRulesOption?.value &&
              repeatedRulesOption.value !== RepeatEventFrequency.DO_NOT_REPEAT
              ? yup
                  .date()
                  .required()
                  .min(from, t('errors.endDate'))
                  .typeError(t('errors.required'))
              : yup.mixed().nullable();
          },
        ),
      [FIELDS.REPEATED_RULES_VALUE]: yup
        .mixed()
        .when([FIELDS.REPEATED_RULES_OPTION], (repeatedRulesOption) => {
          return repeatedRulesOption?.value === RepeatEventFrequency.OTHER
            ? yup.string().required()
            : yup.mixed().nullable();
        }),
      [FIELDS.REPEATED_RULES_MODIFIER]: yup
        .mixed()
        .when([FIELDS.REPEATED_RULES_OPTION], (repeatedRulesOption) => {
          return repeatedRulesOption?.value === RepeatEventFrequency.OTHER
            ? yup.mixed().required()
            : yup.mixed().nullable();
        }),
      [FIELDS.EVENT_TYPE_ID]: yup.mixed().required(),
      [FIELDS.EVENT_RESPONSE_ID]: yup.mixed(),
      [FIELDS.LOCATION]: yup.string().required().max(200).min(1).trim(),
      [FIELDS.DESCRIPTION]: yup.string().max(1000).trim(),
      [FIELDS.REGISTRATION_DEADLINE_VALUE]: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .typeError(t('errors.integer', { value: 0 }))
              .min(0),
      ),
      [FIELDS.REGISTRATION_DEADLINE_UNIT]: yup
        .mixed()
        .when([FIELDS.REGISTRATION_DEADLINE_VALUE], (regDeadline) => {
          return regDeadline ? yup.mixed().required() : yup.mixed().nullable();
        }),
    }),
  );

  const form = useForm<EventFormData>({
    resolver,
    defaultValues: mapEventToFormValues(t, event, eventTypes),
  });

  const { field: tasksFieldProps } = useController<EventFormData>({
    name: FIELDS.TASKS,
    control: form.control,
  });

  const { field: recipientsFieldProps } = useController<EventFormData>({
    name: FIELDS.RECIPIENTS,
    control: form.control,
  });

  const { field: documentsFieldProps } = useController<EventFormData>({
    name: FIELDS.DOCUMENTS,
    control: form.control,
  });

  const { field: weekDaysFieldProps } = useController<EventFormData>({
    name: FIELDS.WEEK_DAYS,
    control: form.control,
  });

  const { field: eventResponseFieldProps } = useController<EventFormData>({
    name: FIELDS.EVENT_RESPONSE_ID,
    control: form.control,
  });

  const watchedValues = useWatch<{
    [FIELDS.ALL_DAY]: boolean;
    [FIELDS.START_TIME]: Date;
    [FIELDS.END_TIME]: Date;
    [FIELDS.FROM]: Date;
    [FIELDS.TO]: Date;
    [FIELDS.REPEATED_RULES_OPTION]: RepeatEventOption;
    [FIELDS.REPEATED_RULES_MODIFIER]: TimeIntervalOption;
    [FIELDS.REPEATED_RULES_VALUE]: number;
    [FIELDS.WEEK_DAYS]: { label: string; value: number }[];
    [FIELDS.TITLE]: string;
    [FIELDS.REGISTRATION_DEADLINE_UNIT]: RegDeadlineTimeUnit;
    [FIELDS.EVENT_RESPONSE_ID]: string | null;
  }>({
    name: [
      FIELDS.ALL_DAY,
      FIELDS.START_TIME,
      FIELDS.END_TIME,
      FIELDS.FROM,
      FIELDS.TO,
      FIELDS.REPEATED_RULES_OPTION,
      FIELDS.REPEATED_RULES_MODIFIER,
      FIELDS.REPEATED_RULES_VALUE,
      FIELDS.WEEK_DAYS,
      FIELDS.TITLE,
      FIELDS.REGISTRATION_DEADLINE_UNIT,
      FIELDS.EVENT_RESPONSE_ID,
    ],
    control: form.control,
  });

  return {
    ...form,
    tasksFieldProps,
    recipientsFieldProps,
    documentsFieldProps,
    eventResponseFieldProps,
    weekDaysFieldProps,
    watchedValues,
  };
};
