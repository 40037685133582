import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/actionCreators/modal';
import {
  doEditCommunityDefaultEventSetting,
  doCreateCommunityDefaultEventSetting,
} from 'store/actionCreators/currentCommunity';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useEditCommunityEventTypeForm } from './form';
import { useInfoPopup } from 'hooks';
import { EventTypeAndDefaultEventSetting } from 'types';

export const useEditCommunityEventType = (
  eventTypeAndDefaultEventSetting: EventTypeAndDefaultEventSetting,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const { showInfoPopup } = useInfoPopup();
  const { handleSubmit, ...form } = useEditCommunityEventTypeForm(
    eventTypeAndDefaultEventSetting,
  );

  const onSuccess = (title, message?: string) => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title,
      message,
      duration: 3000,
      noButton: true,
      loop: false,
    });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      handleSubmit((data) => {
        const defaultSetting = {
          isSetDefaultRes: true,
          ...data.defaultSetting,
        };
        if (eventTypeAndDefaultEventSetting) {
          dispatch(
            doEditCommunityDefaultEventSetting({
              communityId: community.id,
              data: {
                eventType: { ...data.eventType, isResponseRequired: false },
                defaultSetting: defaultSetting,
              },
              id: eventTypeAndDefaultEventSetting.eventType.id,
              onSuccess: () =>
                onSuccess(
                  t('common.editSuccess.title'),
                  t('common.editSuccess.message'),
                ),
            }),
          );
        } else {
          dispatch(
            doCreateCommunityDefaultEventSetting({
              communityId: community.id,
              data: {
                eventType: { ...data.eventType, isResponseRequired: false },
                defaultSetting: defaultSetting,
              },
              onSuccess: () =>
                onSuccess(
                  t('common.createSuccess.title'),
                  t('common.createSuccess.message'),
                ),
            }),
          );
        }
      })();
    },
    [eventTypeAndDefaultEventSetting],
  );

  return {
    onSubmit,
    ...form,
  };
};
