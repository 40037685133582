import React from 'react';
import { isEmpty } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TabHeader, TabList, ContentPlaceholder } from 'components';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getEventTasks } from 'store/selectors/eventTasks';
import { useTasks } from './hooks';
import { TabListContainer } from '../styled';

const TasksPage = () => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const tasks = useSelector(getEventTasks);

  const { onAdd, onEdit, onDelete } = useTasks(community.id);

  return (
    <>
      <TabHeader
        title={t('communitySettings.tabs.tasks')}
        onAdd={onAdd}
        text={t('common.toolTipText.task')}
      />
      {!isEmpty(tasks) ? (
        <TabListContainer>
          <TabList
            items={tasks}
            onDelete={onDelete}
            onEdit={onEdit}
            type="tasks"
          />
        </TabListContainer>
      ) : (
        <ContentPlaceholder />
      )}
    </>
  );
};

export default TasksPage;
