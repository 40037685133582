import React, { FC, useState } from 'react';

import { CreateEventIcon } from 'static';

import { StyledButton, CreateEventText, CreateWrapper } from './styled';

interface PlusButtonProps {
  width?: string;
  disabled?: boolean;
  onClick: () => void;
  extraText?: string;
  orientation?: 'left' | 'right';
}

const PlusButton: FC<PlusButtonProps> = ({
  width = '60px',
  disabled,
  onClick,
  extraText,
  orientation = 'left',
}) => {
  const [animate, setAnimate] = useState<boolean>(false);
  return (
    <CreateWrapper
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      onClick={onClick}
    >
      <StyledButton
        type="button"
        disabled={disabled}
        width={width}
        animate={animate}
      >
        <CreateEventIcon />
      </StyledButton>
      {!!extraText && (
        <CreateEventText animate={animate} orientation={orientation}>
          {extraText}
        </CreateEventText>
      )}
    </CreateWrapper>
  );
};

export default PlusButton;
