import { createRoutine } from 'redux-saga-routines';

import { EventResponse, EventTask, EventType } from 'types';

export const doRequestCommunities = createRoutine('REQUEST_COMMUNITIES');
export const doRefreshCommunities = createRoutine('REFRESH_COMMUNITIES');

export const doCreateCommunity = createRoutine('CREATE_COMMUNITY', {
  trigger: (payload: {
    data: {
      name: string;
      category: string;
      logo: string;
      responses: EventResponse[];
      eventTypes: EventType[];
      tasks: EventTask[];
    };
    onSuccess: (communityId: string) => void;
  }) => payload,
});

export const doUpdateCommunity = createRoutine('UPDATE_COMMUNITY');
export const doDeleteCommunity = createRoutine('DELETE_COMMUNITY');
export const doRequestCommunityInvites = createRoutine(
  'REQUEST_COMMUNITY_INVITES',
);
export const removeInviteOnAcceptOrReject = createRoutine(
  'REMOVE_COMMUNITY_INVITE',
);

export const doRequestCommunityMatrix = createRoutine(
  'REQUEST_COMMUNITY_MATRIX',
);
export const doUpdateCommunityMatrixOnSelfDelete = createRoutine(
  'UPDATE_COMMUNITY_MATRIX',
);

export const doAddUserCommunities = createRoutine('ADD_USER_COMMUNITIES');
export const doDeleteUserCommunities = createRoutine('DELETE_USER_COMMUNITY');

export const doClearCommunities = createRoutine('CLEAR_COMMUNITIES');
