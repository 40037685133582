import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'components';
import { CheckSuccessIcon } from 'static';

import { LinkWrapper, StyledLink, CopyButtonWrapper } from './styled';

type Props = {
  url: string;
  withCopy?: boolean;
  copyText?: string;
  copiedText?: string;
  onClick?: () => void;
};

const LinkText: React.FC<Props> = ({
  url,
  withCopy,
  onClick,
  copyText,
  copiedText,
}) => {
  const [hasCopied, setHasCopied] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const compRef = useRef<HTMLDivElement>(null);
  let timer = undefined;
  const textStyle: React.CSSProperties = {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    position: 'absolute',
  };

  const copyUrl = async () => {
    await navigator.clipboard.writeText(url);
    if (!hasCopied) setHasCopied(true);
    setCopied(true);
    timer = setTimeout(() => {
      setCopied(false);
    }, 1700);
    onClick && onClick();
  };

  const renderCheckIcon = () => {
    return <img src={CheckSuccessIcon} />;
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  return (
    <LinkWrapper withCopy={withCopy} ref={compRef}>
      <StyledLink>{url}</StyledLink>
      {withCopy && (
        <CopyButtonWrapper
          expand={compRef.current ? compRef.current.clientWidth < 380 : false}
        >
          <Button
            onClick={copyUrl}
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              borderColor: '#ffffff',
              borderTopRightRadius: '15px',
              borderBottomRightRadius: '15px',
            }}
            width="100%"
          >
            <>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
              /* @ts-ignore*/}
              <AnimatePresence>
                {!copied && (
                  <motion.p
                    initial={{ y: 40 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.3 }}
                    exit={{ y: 40 }}
                    style={textStyle}
                  >
                    {copyText ?? ''}
                  </motion.p>
                )}
              </AnimatePresence>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
              /* @ts-ignore*/}
              <AnimatePresence>
                {copied && (
                  <motion.p
                    initial={{ y: -40 }}
                    animate={{ y: 0 }}
                    transition={{ duration: 0.3 }}
                    exit={{ y: -40 }}
                    style={textStyle}
                  >
                    {copiedText ?? ''}
                    {renderCheckIcon()}
                  </motion.p>
                )}
              </AnimatePresence>
            </>
          </Button>
        </CopyButtonWrapper>
      )}
    </LinkWrapper>
  );
};

export default LinkText;
