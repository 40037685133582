import React, { FC } from 'react';

import { CheckBox } from 'components';

import {
  StyledHeader,
  StyledSelectAllButton,
  StyledHeaderLabel,
  StyledListWrapper,
  StyledListItem,
  StyledListLink,
  StyledListWrapperScroll,
} from './styled';
import { Redirect } from 'static';

interface CheckListControlledProps {
  items: any[];
  label: string;
  selectedItems: string[];
  allChecked: boolean;
  renderType?: string;
  checkBoxWidth?: string;
  disabled?: boolean;
  selectItem: (name: string) => void;
  handleSelectAllClick: () => void;
}

const CheckListControlled: FC<CheckListControlledProps> = ({
  items = [],
  label,
  selectedItems = [],
  selectItem,
  renderType,
  disabled = false,
  handleSelectAllClick,
  allChecked,
  checkBoxWidth,
}) => {
  const handleChange = (e) => {
    selectItem(e.target.name);
  };

  const renderTitles = (titles) =>
    titles.map((title) =>
      title.url ? (
        <StyledListItem key={title.id}>
          <StyledListLink
            href={title.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CheckBox
              checkBoxWidth={checkBoxWidth}
              isSelected={selectedItems?.includes(title.id)}
              name={title.id}
              disabled={disabled}
              onChange={handleChange}
            />
            <p>{title.title ? title.title : title.name}</p>
            <Redirect style={{ color: 'black', marginLeft: '4px' }} />
          </StyledListLink>
        </StyledListItem>
      ) : (
        <StyledListItem key={title.id}>
          <CheckBox
            checkBoxWidth={checkBoxWidth}
            isSelected={selectedItems?.includes(title.id)}
            name={title.id}
            disabled={disabled}
            onChange={handleChange}
          />
          <span>{title.title ? title.title : title.name}</span>
        </StyledListItem>
      ),
    );

  return (
    <div>
      <StyledHeader>
        <StyledSelectAllButton
          checkBoxWidth={checkBoxWidth}
          type="button"
          disabled={disabled}
          onClick={handleSelectAllClick}
          checked={allChecked}
        />
        <StyledHeaderLabel>{label}</StyledHeaderLabel>
      </StyledHeader>
      <StyledListWrapper>
        <StyledListWrapperScroll
          stripped={renderType === 'documents' ? 207 : 276}
        >
          {renderTitles(items)}
        </StyledListWrapperScroll>
      </StyledListWrapper>
    </div>
  );
};

export default CheckListControlled;
