import React, { useEffect, useState } from 'react';
import { Route, Navigate, Routes, Outlet } from 'react-router-dom';
import { useLocalStorage, useMedia } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  PageContainer,
  MainMenu,
  NotificationsListener,
  AppWidget,
} from 'components';
import ProfileRouter from './ProfileRouter';
import CommunityRouterWrapper from './CommunityRouterWrapper';
import CommunityLandingWrapper from './CommunityLandingWrapper';
import { getCommunities } from 'store/selectors/communities';
import { getAuthUser } from 'store/selectors/auth';
import { doRequestCommunities } from 'store/actionCreators/communities';
import { breakpoints } from 'theme';

const PageContainerWrapper = () => {
  const isWide = useMedia(breakpoints.md);
  const dispatch = useDispatch();
  const communities = useSelector(getCommunities);
  const userProfile = useSelector(getAuthUser);

  useEffect(() => {
    if (!communities?.length && userProfile?.id) {
      dispatch(doRequestCommunities());
    }
  }, [userProfile]);

  const [appWidgetVisible, setAppWidgetVisible] = useState<boolean>(false);
  const [appWidgetStatus, setAppWidgetStatus] = useLocalStorage<boolean>(
    'appWidgetShown',
    false,
  );

  useEffect(() => {
    if (!isWide && !appWidgetStatus) {
      setAppWidgetStatus(true);
      setTimeout(() => setAppWidgetVisible(true), 2000);
    }
  }, [appWidgetStatus]);

  return (
    <PageContainer>
      <Modal />
      {isWide && <MainMenu />}
      <NotificationsListener />
      <Outlet />
      {appWidgetVisible ? <AppWidget /> : null}
    </PageContainer>
  );
};

const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<PageContainerWrapper />}>
        <Route path="profile/*" element={<ProfileRouter />} />
        <Route
          path="communities/:communityId/landing"
          element={<CommunityLandingWrapper />}
        />
        <Route
          path="community/:communityId/*"
          element={<CommunityRouterWrapper />}
        />
        <Route path="*" element={<Navigate to="profile" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
