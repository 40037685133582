import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEvent, getOverallEvent } from 'store/selectors/events';
import { Event } from 'types';
import { getEvent as fetchSingleEvent } from 'services/events';

export const useSingleEvent = (
  communityId: string,
  eventId: string,
): Event | null => {
  const [event, setEvent] = useState(null);
  const groupEvent = useSelector((state) => getEvent(state, eventId));
  const overallEvent = useSelector((state) => getOverallEvent(state, eventId));
  const foundEvent = groupEvent || overallEvent;

  useEffect(() => {
    if (!foundEvent) {
      (async () => {
        const result = await fetchSingleEvent(communityId, eventId);

        setEvent(result);
      })();
    } else {
      setEvent(foundEvent);
    }
  }, []);

  if (!event) {
    return null;
  }

  return event;
};
