import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export const FIELDS = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  OLD_PASSWORD: 'oldPassword',
};

export const useChangePasswordSchema = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  return yupResolver(
    yup.object().shape({
      [FIELDS.OLD_PASSWORD]: yup.string().required(),
      [FIELDS.PASSWORD]: yup
        .string()
        .required()
        .matches(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/, t('errors.password')),
      [FIELDS.CONFIRM_PASSWORD]: yup
        .string()
        .required()
        .oneOf([yup.ref('password'), null], t('errors.confirmPassword')),
    }),
  );
};
export const useChangePasswordForm = () =>
  useForm({
    resolver: useChangePasswordSchema(),
  });
