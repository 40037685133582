import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocation } from 'services/geolocation';
import AuthRouter from './router';

import { AuthContainer, FormContainer, ImageContainer } from './styled';

const Auth = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const availableLanguages = i18n.options['languages'];
    const fallBackLanguage = i18n.options.fallbackLng[0];

    getLocation()
      .then((result) => {
        const detectedLanguages = result.languages
          ? result.languages.split(',').slice(0, 4).reverse()
          : [];
        let langToSet = fallBackLanguage;

        if (Array.isArray(detectedLanguages) && detectedLanguages.length > 0) {
          detectedLanguages.forEach((lang) => {
            if (
              lang.toLowerCase().includes('-ch') ||
              lang.toLowerCase().includes('de-')
            ) {
              langToSet = 'de';
            } else if (lang.toLowerCase().includes('en')) {
              langToSet = 'en';
            } else if (availableLanguages.includes(lang)) {
              langToSet = lang;
            }
          });
        }

        i18n.changeLanguage(langToSet);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <AuthContainer>
      <FormContainer>
        <AuthRouter />
      </FormContainer>
      <ImageContainer />
    </AuthContainer>
  );
};

export default Auth;
