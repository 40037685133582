import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledButtonBlock = styled.div`
  margin-top: 40px;
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
  gap: 20px;

  & > button {
    width: 340px;
  }

  @media ${breakpoints.downMdPlus} {
    width: auto;
    max-width: 700px;
    margin: 40px;

    & > button {
      width: 100%;
    }
  }

  @media ${breakpoints.downMd} {
    width: 96%;
    margin: 40px auto;
  }

  @media ${breakpoints.downSmPlus} {
    flex-direction: column;
  }
`;

export const StyledPlaceholder = styled.div`
  margin-top: 30px;
  padding: 0 20px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color.pickledBluewood};
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
`;
