import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { getCurrentCommunity } from './currentCommunity';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;

export const getEventTasks = createSelector(
  getCurrentCommunity,
  (community) => community.eventTasks || null,
);

export const getEventTaskLoading = createSelector(
  currentCommunitySelector,
  (community) =>
    community.loaders.createEventTask ||
    community.loaders.editEventTask ||
    community.loaders.deleteEventTask,
);
