import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 23px;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding-left: ${({ isMenuOpen }) => (isMenuOpen ? 23 : 78)}px;
  transition: padding-left 0.3s;
`;

export const Header = styled.span`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-left: 14px;
`;

export const FileInputContainer = styled.div`
  padding: 12px 20px 12px 23px;
  height: 94px;
  background-color: ${({ theme }) => theme.color.dew};
`;

export const Headline = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  color: ${({ theme }) => theme.color.baliHai};
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  font-size: 12px;
`;

export const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.dew};
  overflow: auto;
  height: calc(100% - 174px);
`;
