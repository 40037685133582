import styled from 'styled-components';

export const StyledRow = styled.tr`
  height: 70px;
  ${({ opacity }) =>
    opacity &&
    `
        opacity: 50%;
    `}

  & > td:first-of-type {
    padding-left: 25px;
  }
`;

export const StyledCell = styled.td`
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  ${({ center }) =>
    center &&
    `
        text-align: center;
    `}
`;

export const StyledCellLink = styled.a`
  width: 100%;
  height: 70px;
  display: flex;
  text-decoration: none;
`;
