import { call, put, takeEvery } from 'redux-saga/effects';

import { doGetTotal } from 'store/actionCreators/currentGroup';
import {
  doCreateEventReply,
  doCreateOverallEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
  doUpdateOverallEventReply,
  doDeleteOverallEventReply,
  doGetCommunityTotal,
} from 'store/actionCreators/currentCommunity';
import {
  createEventReply,
  createOverallEventReply,
  updateEventReply,
  deleteEventReply,
  updateOverallEventReply,
  deleteOverallEventReply,
} from 'services/events';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

function* onCreateEventReply({
  payload,
}: ReturnType<typeof doCreateEventReply>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      createEventReply,
      payload.groupId,
      payload.eventId,
      payload.userId,
      payload.data,
      payload.eventSeriesId,
      payload.type,
      payload.from,
    );
    yield put(doCreateEventReply.success(Array.isArray(data) ? data : [data]));
    yield put(
      doGetTotal.trigger({
        groupId: payload.groupId,
        from: payload.filterFrom,
        to: payload.filterTo,
      }),
    );
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doCreateEventReply.failure(response.data));
    yield call(payload.onFailure, response.data.message);
  }
}

function* onCreateOverallEventReply({
  payload,
}: ReturnType<typeof doCreateOverallEventReply>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      createOverallEventReply,
      payload.communityId,
      payload.eventId,
      payload.data,
      payload.userId,
      payload.eventSeriesId,
      payload.type,
      payload.from,
    );
    yield put(
      doCreateOverallEventReply.success(Array.isArray(data) ? data : [data]),
    );
    yield put(
      doGetCommunityTotal.trigger({
        communityId: payload.communityId,
        from: payload.filterFrom,
        to: payload.filterTo,
      }),
    );
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doCreateOverallEventReply.failure(response.data));
    yield call(payload.onFailure, response.data.message);
  }
}

function* onUpdateEventReply({
  payload,
}: ReturnType<typeof doUpdateEventReply>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      updateEventReply,
      payload.groupId,
      payload.eventId,
      payload.userId,
      payload.eventReplyId,
      payload.data,
      payload.eventSeriesId,
      payload.type,
      payload.from,
    );
    yield put(
      doUpdateEventReply.success({
        response: Array.isArray(data) ? data : [data],
        userId: payload.userId,
      }),
    );
    yield put(
      doGetTotal.trigger({
        groupId: payload.groupId,
        from: payload.filterFrom,
        to: payload.filterTo,
      }),
    );
    yield call(payload.onSuccess);
  } catch ({ response }) {
    if (response) {
      yield put(doUpdateEventReply.failure(response.data));
      yield call(payload.onFailure, response.data.message);
    }
  }
}

function* onDeleteEventReply({
  payload,
}: ReturnType<typeof doDeleteEventReply>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      deleteEventReply,
      payload.groupId,
      payload.eventId,
      payload.userId,
      payload.eventReplyId,
      payload.eventSeriesId,
      payload.type,
      payload.from,
    );
    yield put(
      doDeleteEventReply.success({
        ...data,
        userId: payload.userId,
      }),
    );
    yield put(
      doGetTotal.trigger({
        groupId: payload.groupId,
        from: payload.filterFrom,
        to: payload.filterTo,
      }),
    );
    yield call(payload.onSuccess);
  } catch ({ response }) {
    if (response) {
      yield put(doDeleteEventReply.failure(response.data));
      yield call(payload.onFailure, response.data.message);
    }
  }
}

function* onUpdateOverallEventReply({
  payload,
}: ReturnType<typeof doUpdateOverallEventReply>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      updateOverallEventReply,
      payload.communityId,
      payload.eventId,
      payload.eventReplyId,
      payload.data,
      payload.userId,
      payload.eventSeriesId,
      payload.type,
      payload.from,
    );
    yield put(
      doUpdateOverallEventReply.success(Array.isArray(data) ? data : [data]),
    );
    yield put(
      doGetCommunityTotal.trigger({
        communityId: payload.communityId,
        from: payload.filterFrom,
        to: payload.filterTo,
      }),
    );
    yield call(payload.onSuccess);
  } catch ({ response }) {
    if (response) {
      yield put(doUpdateOverallEventReply.failure(response.data));
      yield call(payload.onFailure, response.data.message);
    }
  }
}

function* onDeleteOverallEventReply({
  payload,
}: ReturnType<typeof doDeleteOverallEventReply>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      deleteOverallEventReply,
      payload.communityId,
      payload.eventId,
      payload.eventReplyId,
      payload.userId,
      payload.eventSeriesId,
      payload.type,
      payload.from,
    );
    yield put(
      doDeleteOverallEventReply.success({
        ...data,
        userId: payload.userId,
      }),
    );
    yield put(
      doGetCommunityTotal.trigger({
        communityId: payload.communityId,
        from: payload.filterFrom,
        to: payload.filterTo,
      }),
    );
    yield call(payload.onSuccess);
  } catch ({ response }) {
    if (response) {
      yield put(doDeleteOverallEventReply.failure(response.data));
      yield call(payload.onFailure, response.data.message);
    }
  }
}

export default function* () {
  yield takeEvery(doCreateEventReply, onCreateEventReply);
  yield takeEvery(doCreateOverallEventReply, onCreateOverallEventReply);
  yield takeEvery(doUpdateEventReply, onUpdateEventReply);
  yield takeEvery(doDeleteEventReply, onDeleteEventReply);
  yield takeEvery(doUpdateOverallEventReply, onUpdateOverallEventReply);
  yield takeEvery(doDeleteOverallEventReply, onDeleteOverallEventReply);
}
