import React, { useCallback } from 'react';
import { isFirefox } from 'react-dnd-html5-backend/lib/BrowserDetector';
import { useMedia } from 'react-use';

import { breakpoints } from 'theme';

const useGridScroll = ({
  containerRef,
  stickyCellWidth,
  zoom,
  isMenuOpen,
}: {
  containerRef: React.RefObject<HTMLElement>;
  stickyCellWidth: number;
  zoom: number;
  isMenuOpen: boolean;
}): {
  handleScrollGrid: (position: 'start' | 'end') => void;
} => {
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);

  const handleScrollGrid = useCallback(
    (positionToScroll: 'start' | 'end') => {
      const scrollLeft = containerRef.current.scrollLeft;
      const eventBoxWidth = Math.floor(157 * zoom);
      const firstColumnWidth = Math.floor(stickyCellWidth * zoom);
      const eventsToScroll =
        Math.floor(
          (containerRef.current?.getBoundingClientRect()?.width -
            firstColumnWidth) /
            eventBoxWidth /
            (isDownSmPlus ? 1 : 2),
        ) || 1;
      const scrollOffset = eventsToScroll * eventBoxWidth;
      const newScrollLeft =
        positionToScroll === 'end'
          ? scrollLeft + scrollOffset
          : scrollLeft - scrollOffset;

      if (
        scrollLeft === 0 &&
        newScrollLeft <= 0 &&
        positionToScroll === 'start'
      ) {
        document.getElementById('toggle-filters-icon')?.click();

        return;
      }

      containerRef.current.scroll({
        top: 0,
        left: newScrollLeft,
        behavior: isFirefox() ? 'auto' : 'smooth',
      });
    },
    [containerRef, zoom, isMenuOpen, isDownSmPlus],
  );

  return {
    handleScrollGrid,
  };
};

export default useGridScroll;
