import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FIELDS } from './form';
import { useResetPassword } from './hooks';
import TextInput from 'components/Inputs/TextInput/TextInputV2';

import { StyledButton, Header, StyledForm, StyledGumbLogo } from '../styled';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { register, onSubmit, errors } = useResetPassword(location);

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledGumbLogo />
      <Header>{t('auth.recovery')}</Header>
      <TextInput
        register={register}
        error={errors[FIELDS.PASSWORD]}
        type="password"
        name={FIELDS.PASSWORD}
        label={t('auth.labels.password')}
        withShowIcon
      />
      <TextInput
        register={register}
        error={errors[FIELDS.CONFIRM_PASSWORD]}
        type="password"
        name={FIELDS.CONFIRM_PASSWORD}
        label={t('auth.labels.confirmPassword')}
      />
      <StyledButton>{t('auth.reset')}</StyledButton>
    </StyledForm>
  );
};

export default ResetPasswordPage;
