import styled from 'styled-components';
import { getBasicColor } from 'utils';
import { closeMark, checkMark } from 'static';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: ${({ customHeight }) => (customHeight ? '580px' : '600px')};
  width: ${({ isCustom, customHeight }) =>
    isCustom ? 285 : customHeight ? 285 : 285}px;
  min-width: ${({ isCustom, customHeight }) =>
    isCustom ? 285 : customHeight ? 285 : 285}px;
  margin: 5px 4px 10px 4px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 2px #00000012;
  border: ${({ type }) => (type === 'current' ? '2px solid #10CCC6' : 'none')};
  padding-top: ${({ type }) => (type === 'current' ? '0' : '2px')};
  overflow: hidden;
  font-weight: 500;
`;

export const CardHeader = styled.div`
  margin-top: 17px;
  font-size: 16px;
  line-height: 32px;
  padding-bottom: 21px;

  span {
    font-weight: 600;
  }
`;

export const Cost = styled.div`
  font-size: 34px;
  letter-spacing: 0;
  color: ${({ type, theme }) =>
    type ? theme.color.bittersweet : theme.color.brightTurquoise};
  line-height: 32px;

  p {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    color: #000;
    margin-top: 4px;
  }
`;

export const CardBody = styled.div`
  margin: 23px 0 auto;
  font-size: 15px;
  text-align: left;
  color: #202426;

  p {
    font-size: 13px;
    margin: 0 20px 0 16px;
  }
`;

export const BenefitsList = styled.ul`
  list-style: none;
  text-align: left;
  margin-block: 0px;
  padding-top: 0px;
  padding-bottom: 30px;
  li {
    display: flex;
    align-items: center;
    margin: 12px 18px 0 48px;
    min-height: 40px;
    position: relative;
    div {
      display: flex;
      align-items: center;
    }
  }
`;

export const CardFooter = styled.div`
  align-items: center;
  margin-bottom: 23px;

  button {
    margin: auto;
  }
`;

export const CornerLabel = styled.div`
  width: 115px;
  height: 26px;
  margin: 0px auto -26px -26px;
  padding-top: 2px;
  transform: rotate(-45deg);
  color: #fff;
  opacity: 0.8;
  background-color: ${({ variant, theme }) =>
    getBasicColor(variant === 'POPULAR' ? 'danger' : variant, theme)};
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
  text-align: center;
`;

export const CheckMark = styled.span`
  width: 22px;
  height: 22px;
  position: absolute;
  margin: 0px auto auto -32px;
  background-image: url(${({ type }) =>
    type === 'custom' ? checkMark : checkMark});
`;

export const StyledCloseIcon = styled.span`
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0px auto auto -30px;
  background-image: url(${closeMark});
`;

export const OptionLabel = styled.div`
  flex: 1;
`;

export const NumberInputContainer = styled.div`
  position: absolute;
  right: -6px;
`;
