import { createRoutine } from 'redux-saga-routines';

import { Group } from 'types';

export const doCreateGroup = createRoutine('CREATE_GROUP', {
  trigger: (payload: {
    communityId: string;
    name: string;
    onSuccess: (data: Group) => void;
  }) => payload,
});

export const doUpdateGroup = createRoutine('UPDATE_GROUP', {
  trigger: (payload: { id: string; name: string; onSuccess: () => void }) =>
    payload,
});

export const doDeleteGroup = createRoutine('DELETE_GROUP', {
  trigger: (payload: {
    data: {
      groupId: string;
      communityId: string;
    };
    onSuccess: () => void;
  }) => payload,
});

export const doAddMembersToGroup = createRoutine('ADD_MEMBERS_TO_GROUP', {
  trigger: (payload: {
    data: { users: string[] };
    communityId: string;
    groupId: string;
    onSuccess: () => void;
  }) => payload,
});

export const doRequestStatistic = createRoutine('REQUEST_STATISTIC', {
  trigger: (payload: {
    id: string;
    fromDate: Date;
    toDate: Date;
    eventTypeId?: string;
  }) => payload,
});
