import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from 'theme';

export const StyledContentWrapper = styled.div`
  width: calc(100% - ${({ isMenuOpen }) => (isMenuOpen ? 360 : 60)}px);
  background-color: white;
  transition: all 0.3s;
  position: absolute;
  height: 100svh;
  left: ${({ isMenuOpen }) => (isMenuOpen ? '360px' : '60px')};
  overflow: ${({ controlOverflow }) => controlOverflow ?? 'hidden'};

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.lightGrey};
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
  }

  .str-chat {
    height: calc(100svh - 165px);
    overflow: auto;
    @media ${breakpoints.downSmPlus} {
      height: calc(100svh - 130px);
    }
    @media (max-width: 480px) {
      height: calc(100svh - 113px);
    }
  }

  @media ${breakpoints.downMd} {
    width: 100%;
    left: 0;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 16px 24px;
  margin-left: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 55)}px;
  transition: margin-left 0.3s;

  @media ${breakpoints.downMd} {
    padding-right: 70px;
  }
`;

export const StyledGroupTitle = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.pickledBluewood};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
`;

export const StyledSettingsButtonLink = styled(Link)`
  text-decoration: none;
  position: absolute;
  right: 160px;

  @media ${breakpoints.downMd} {
    right: 43%;
  }
`;
