import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
  display: flex;

  & div:first-child label {
    border-radius: 4px 0 0 4px;
  }

  & div:last-child label {
    border-right-width: 1px;
    border-radius: 0 4px 4px 0;
  }
`;

export const StyledButton = styled.div`
  width: 100%;
  height: 39px;
  position: relative;
`;

export const StyledCheckbox = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.01;
  z-index: 100;

  &:hover + label {
    color: ${({ theme }) => theme.color.brightTurquoise};
  }

  &:checked + label {
    color: ${({ theme }) => theme.color.brightTurquoise};
    border-right-width: 1px;
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const StyledLabel = styled.label`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  border-right-width: 0px;
  cursor: pointer;
  z-index: 90;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;
`;
