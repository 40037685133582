import { axiosInstance as axios } from './index';
import { CommunityRoles, GroupRoles } from '../types';

export const loadUsers = async ({ limit, communityId, groupId }) => {
  const params = {};

  if (limit) params['limit'] = limit;
  if (groupId) params['groupId'] = groupId;
  if (communityId) params['communityId'] = communityId;

  const response = await axios.get('/users', { params });
  return response.data;
};

export const changeUserRole = async (
  communityId: string,
  userId: string,
  data: { role: string; oldRole?: string },
) => {
  const response = await axios.put(
    `/communities/${communityId}/users/${userId}`,
    data,
  );
  return response.data;
};

export const deleteCommunityUser = async (communityId, users) => {
  const data = {
    data: {
      users,
    },
  };
  const response = await axios.delete(
    `/communities/${communityId}/users`,
    data,
  );
  return response.data.users.filter((user) => user.id === users[0])[0];
};

export const addCommunityUsers = (communityId, users) => {
  const data = {
    users,
  };

  return axios.put(`/communities/${communityId}/users`, data);
};

export const addUserCommunities = async (communities, userId) => {
  const data = {
    communities,
  };
  const response = await axios.put(`/users/${userId}/communities`, data);
  return response.data.users.filter((user) => user.id === userId)[0];
};

export const deleteUserCommunities = async (communities, userId) => {
  const data = {
    communities,
  };
  const response = await axios.delete(`/users/${userId}/communities`, { data });
  return response.data.users.filter((user) => user.id === userId)[0];
};

export const deleteUserCommunity = async (
  communityId: string,
  userId: string,
) => {
  const data = {
    data: {
      communities: communityId,
    },
  };
  const response = await axios.delete(`/users/${userId}/communities`, data);
  return response;
};

export const changeGroupUserRole = async (
  communityId: string,
  groupId: string,
  userId: string,
  data: { role: string; oldRole?: string },
) => {
  const response = await axios.put(
    `/communities/${communityId}/group/${groupId}/users/${userId}`,
    data,
  );
  return response.data;
};

export const deleteGroupUser = async (
  communityId: string,
  groupId: string,
  users: string[],
) => {
  const data = {
    data: {
      users,
    },
  };
  const response = await axios.delete(
    `/communities/${communityId}/group/${groupId}/users`,
    data,
  );
  return response;
};

export const createUserProfile = async (data: {
  communityId: string;
  firstName?: string;
  lastName?: string;
  role: CommunityRoles;
  email: string;
  groups: { id: string; role: GroupRoles }[];
}) => {
  return axios.put(`createUser/${data.communityId}`, data);
};

export const deleteUserGroups = async (
  communityId: string,
  groupId: string,
  userId: string,
) => {
  const data = {
    data: {
      groups: groupId,
    },
  };

  const response = await axios.delete(
    `users/${userId}/communities/${communityId}/groups`,
    data,
  );
  return response;
};

export const addUserGroups = async (
  communityId: string,
  groupId: string,
  userId: string,
) => {
  const data = {
    groups: groupId,
  };

  const response = await axios.post(
    `users/${userId}/communities/${communityId}/groups`,
    data,
  );
  return response;
};

export const getMembersDetails = async (communityId: string) => {
  const response = await axios.get(`/communities/${communityId}/users`);
  return response.data;
};
