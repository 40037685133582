import styled from 'styled-components';

import { CheckboxCheckedIconBig, CheckboxDefaultIconBig } from 'static';

export const StyledHeader = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;

export const StyledSelectAllButton = styled.button`
  width: ${({ checkBoxWidth }) => checkBoxWidth};
  height: ${({ checkBoxWidth }) => checkBoxWidth};
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-image: ${({ checked }) =>
    `url(${checked ? CheckboxCheckedIconBig : CheckboxDefaultIconBig})`};
`;

export const StyledHeaderLabel = styled.span`
  margin-left: 11px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledListWrapperScroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledListItem = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  font-weight: 400;
  font-size: 15px;
  white-space: normal;
  span {
    line-height: 16px;
  }
`;

export const StyledListLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  & > p {
    color: ${({ theme }) => theme.color.black};
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid ${({ theme }) => theme.color.black};
  }
`;
