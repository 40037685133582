import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPageContainer from './Container';

const Page500 = () => {
  const { t } = useTranslation();

  return (
    <ErrorPageContainer
      errorTitle={t('errors.sorry')}
      errorText={t('errors.text500')}
    />
  );
};

export default Page500;
