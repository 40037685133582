import React, { FC, useMemo } from 'react';
import { isEmpty, get } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';

import { Button, Divider, NumberInput, PopupEventWrapper } from 'components';
import { startTrial } from 'store/actionCreators/subscriptions';
import {
  Profile,
  SubscriptionFrequency,
  SubscriptionStatus,
  UserSubscription,
} from 'types';

import {
  Card,
  CardHeader,
  CardBody,
  Cost,
  BenefitsList,
  CardFooter,
  CornerLabel,
  CheckMark,
  StyledCloseIcon,
  OptionLabel,
  NumberInputContainer,
} from './styled';

interface SubscriptionCardProps {
  id: string;
  name: string;
  options?: {
    ability: boolean;
    text: string;
    value?: number;
  }[];
  pricing: 'yearly' | 'monthly';
  isCustom?: boolean;
  currency: string;
  maxGroups?: number;
  maxMembers?: number;
  maxCommunities?: number;
  maxStorage?: number;
  isTrial?: boolean;
  chatEnabled: boolean;
  profile: Profile;
  yearlyValue: number;
  monthlyValue: number;
  currentUserSubscription: UserSubscription;
  addHandler?: () => void;
  subHandler?: () => void;
}

const SubscriptionCard: FC<SubscriptionCardProps> = ({
  id,
  name,
  options,
  pricing,
  isCustom,
  currency,
  maxGroups,
  maxMembers,
  maxStorage,
  yearlyValue,
  chatEnabled,
  monthlyValue,
  maxCommunities,
  isTrial,
  currentUserSubscription,
  profile,
  addHandler,
  subHandler,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentPlanId = get('subscriptionPlan.id', currentUserSubscription);
  const isCurrent =
    currentUserSubscription?.status !== SubscriptionStatus.CANCELLED &&
    (currentPlanId === id ||
      (currentUserSubscription?.status === SubscriptionStatus.TRIAL &&
        isTrial)) &&
    ((pricing === 'yearly' &&
      currentUserSubscription?.frequency === SubscriptionFrequency.YEAR) ||
      (pricing === 'monthly' &&
        currentUserSubscription?.frequency === SubscriptionFrequency.MONTH));

  const defaultLabel = name === 'Classic +' ? t('subscriptions.popular') : '';
  const labelValue = isCurrent ? t('subscriptions.current') : defaultLabel;
  const label = {
    type: labelValue,
    caption: labelValue,
  };
  const maxCommunitiesLabel =
    maxCommunities > 1
      ? t('subscriptions.communities')
      : t('subscriptions.community');

  const schedulerLabel =
    name === 'Classic'
      ? t('subscriptions.scheduler')
      : t('subscriptions.schedulers');
  const buttonType = useMemo(() => {
    if (isCustom) {
      return 'danger';
    }

    return isCurrent || !monthlyValue ? 'secondary' : 'primary';
  }, [isCustom, isCurrent, monthlyValue]);

  const buttonText = useMemo(() => {
    if (isCurrent) {
      return t('subscriptions.seeDetails');
    }

    return t('subscriptions.subscribe');
  }, [isCurrent, monthlyValue]);

  const cardType = useMemo(() => {
    if (isCurrent) {
      return 'current';
    }

    if (isCustom) {
      return 'custom';
    }

    return null;
  }, [isCustom, isCurrent]);

  const buttonComponent = useMemo(() => {
    if (!isTrial) {
      return (
        <Button
          style={{ borderRadius: '32px' }}
          width="85%"
          variant={buttonType}
          link={`${id}?frequency=${pricing === 'yearly' ? 'YEAR' : 'MONTH'}`}
        >
          {buttonText}
        </Button>
      );
    }

    if (!profile?.trialStarted && !currentUserSubscription?.id) {
      return (
        <PopupEventWrapper
          popupMessageText={t('subscriptions.startTrialText')}
          popupButtons={[
            {
              popupButtonText: t('subscriptions.startTrial'),
              handleConfirm: () => {
                dispatch(startTrial());
              },
            },
          ]}
          darkBackground={true}
        >
          <Button width="210px" variant="primary">
            {t('subscriptions.startTrial')}
          </Button>
        </PopupEventWrapper>
      );
    }

    return null;
  }, [
    isTrial,
    profile,
    currentUserSubscription,
    buttonType,
    pricing,
    buttonText,
    id,
  ]);
  return (
    <Card
      type={cardType}
      isCustom={isCustom}
      customHeight={
        i18n.language === 'en' ||
        i18n.language === 'de' ||
        i18n.language === 'ru'
          ? true
          : false
      }
    >
      <CardHeader>
        {label.caption && (
          <CornerLabel variant={label.type}>{label.caption}</CornerLabel>
        )}
        {isCustom ? (
          <span>{t('subscriptions.buildCustom')}</span>
        ) : (
          <span>{name}</span>
        )}
        <Cost type={cardType}>
          {pricing === 'monthly' ? (
            <>
              {monthlyValue}
              <p>
                {currency}/{t('subscriptions.month')}
              </p>
            </>
          ) : (
            <>
              {yearlyValue}
              <p>
                {currency}/{t('subscriptions.year')}
              </p>
            </>
          )}
        </Cost>
      </CardHeader>
      <Divider customMargin="0px" />
      <CardBody>
        <BenefitsList>
          {!isEmpty(options) ? (
            options.map(({ text, ability, value }) => (
              <li key={uuid()}>
                <OptionLabel>
                  {ability ? (
                    <CheckMark type={cardType} />
                  ) : (
                    <StyledCloseIcon />
                  )}
                  {text}
                </OptionLabel>
                {value && (
                  <NumberInput
                    name={text}
                    height="39px"
                    width="99px"
                    value={text === 'Members' ? maxMembers : maxCommunities}
                    onAdd={addHandler}
                    onSubtract={subHandler}
                  />
                )}
              </li>
            ))
          ) : (
            <>
              <li>
                <OptionLabel>
                  <CheckMark type={cardType} />
                  {!isCustom && maxCommunities} {maxCommunitiesLabel}
                </OptionLabel>
                {isCustom && (
                  <NumberInputContainer>
                    <NumberInput
                      name="maxCommunities"
                      height="39px"
                      width="99px"
                      color="danger"
                      value={maxCommunities}
                      onAdd={addHandler}
                      onSubtract={subHandler}
                    />
                  </NumberInputContainer>
                )}
              </li>
              <li>
                <OptionLabel>
                  <CheckMark type={cardType} />
                  {schedulerLabel}
                </OptionLabel>
              </li>
              <li>
                <OptionLabel>
                  <CheckMark type={cardType} />
                  {maxGroups} {t('subscriptions.group')}
                </OptionLabel>
              </li>
              <li>
                <OptionLabel>
                  <CheckMark type={cardType} />
                  {!isCustom && maxMembers} {t('subscriptions.capacity')}
                </OptionLabel>
                {isCustom && (
                  <NumberInputContainer>
                    <NumberInput
                      name="maxMembers"
                      height="39px"
                      width="99px"
                      color="danger"
                      value={maxMembers}
                      onAdd={addHandler}
                      onSubtract={subHandler}
                    />
                  </NumberInputContainer>
                )}
              </li>
              <li>
                <OptionLabel>
                  <CheckMark type={cardType} />
                  {maxStorage} {t('subscriptions.maxStorage')}
                </OptionLabel>
              </li>
              <li>
                {chatEnabled ? (
                  <OptionLabel>
                    <CheckMark type={cardType} />
                    {t('subscriptions.chatEnabled')}
                  </OptionLabel>
                ) : (
                  <OptionLabel>
                    <StyledCloseIcon />
                    {t('subscriptions.chatExcluded')}
                  </OptionLabel>
                )}
              </li>
            </>
          )}
        </BenefitsList>
      </CardBody>
      <CardFooter>{buttonComponent}</CardFooter>
    </Card>
  );
};

export default SubscriptionCard;
