import { axiosInstance as axios } from '../index';
import { EditEventType, SINGLE_EVENT } from 'constants/sidebars';
import { ReplyFormData } from 'pages/UserProfileEvents/form';

export const createOverallEventReply = async (
  communityId: string,
  eventId: string,
  data: ReplyFormData,
  userId: string,
  eventSeriesId: string,
  type: EditEventType,
  from: string,
) => {
  const response = await axios.post(
    `communities/${communityId}/event/${eventId}/userId/${userId}/eventReply?${
      eventSeriesId ? `&eventSeriesId=${eventSeriesId}` : ''
    }&type=${type || SINGLE_EVENT}${from ? `&from=${from}` : ''}`,
    data,
  );
  return response.data;
};

export const updateOverallEventReply = async (
  communityId: string,
  eventId: string,
  eventReplyId: string,
  data: ReplyFormData,
  userId: string,
  eventSeriesId: string,
  type: EditEventType,
  from: string,
) => {
  const response = await axios.put(
    `communities/${communityId}/event/${eventId}/userId/${userId}/eventReply/${eventReplyId}?${
      eventSeriesId ? `&eventSeriesId=${eventSeriesId}` : ''
    }&type=${type || SINGLE_EVENT}${from ? `&from=${from}` : ''}`,
    data,
  );
  return response.data;
};

export const deleteOverallEventReply = async (
  communityId: string,
  eventId: string,
  eventReplyId: string,
  userId: string,
  eventSeriesId: string,
  type: EditEventType,
  from: string,
) => {
  const response = await axios.delete(
    `communities/${communityId}/event/${eventId}/userId/${userId}/eventReply/${eventReplyId}?${
      eventSeriesId ? `&eventSeriesId=${eventSeriesId}` : ''
    }&type=${type || SINGLE_EVENT}${from ? `&from=${from}` : ''}`,
  );
  return response.data;
};

export const createEventReply = async (
  groupId: string,
  eventId: string,
  userId: string,
  data: ReplyFormData,
  eventSeriesId: string,
  type: EditEventType,
  from: string,
) => {
  const response = await axios.post(
    `groups/${groupId}/event/${eventId}/userId/${userId}/eventReply?${
      eventSeriesId ? `&eventSeriesId=${eventSeriesId}` : ''
    }&type=${type || SINGLE_EVENT}${from ? `&from=${from}` : ''}`,
    data,
  );
  return response.data;
};

export const updateEventReply = async (
  groupId: string,
  eventId: string,
  userId: string,
  eventReplyId: string,
  data: ReplyFormData,
  eventSeriesId: string,
  type: EditEventType,
  from: string,
) => {
  const response = await axios.put(
    `groups/${groupId}/event/${eventId}/userId/${userId}/eventReply/${eventReplyId}?${
      eventSeriesId ? `&eventSeriesId=${eventSeriesId}` : ''
    }&type=${type || SINGLE_EVENT}${from ? `&from=${from}` : ''}`,
    data,
  );
  return response.data;
};

export const deleteEventReply = async (
  groupId: string,
  eventId: string,
  userId: string,
  eventReplyId: string,
  eventSeriesId: string,
  type: EditEventType,
  from: string,
) => {
  const response = await axios.delete(
    `groups/${groupId}/event/${eventId}/userId/${userId}/eventReply/${eventReplyId}?${
      eventSeriesId ? `&eventSeriesId=${eventSeriesId}` : ''
    }&type=${type || SINGLE_EVENT}${from ? `&from=${from}` : ''}`,
  );
  return response.data;
};
