import React from 'react';
import toast from 'react-hot-toast';

import { InfoPopup, UserGuidePopup } from 'components';
import { InfoPopupProps } from 'components/InfoPopup';
import { UserGuidePopupProps } from 'components/Popups/UserGuidePopup';

type UseInfoPopupReturn = {
  showInfoPopup: (props: InfoPopupProps, duration?: number) => void;
  showUserGuidePopup: (props: UserGuidePopupProps, duration?: number) => void;
};

const useInfoPopup: () => UseInfoPopupReturn = () => {
  const showInfoPopup = (props, duration = 12000) => {
    toast.custom(<InfoPopup {...props} isOpen={true} />, {
      duration,
    });
  };

  const showUserGuidePopup = (props, duration = 300000) => {
    toast.custom(<UserGuidePopup {...props} isOpen={true} />, { duration });
  };

  return {
    showInfoPopup,
    showUserGuidePopup,
  };
};

export default useInfoPopup;
