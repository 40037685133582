import styled from 'styled-components';

const DragIcon = styled.div`
  display: inline-block;
  height: 7px;
  width: 15px;
  border: 2px solid ${({ theme }) => theme.color.casper};
  border-left: none;
  border-right: none;
  cursor: grab;
`;

export default DragIcon;
