import { createRoutine } from 'redux-saga-routines';
import { EditCommunityTaskFormData } from '../../../sidebars/EditCommunityTask/form';

export const doGetCommunityTasks = createRoutine('GET_COMMUNITY_TASKS', {
  trigger: (payload: string) => payload,
});

export const doCreateCommunityTask = createRoutine('CREATE_COMMUNITY_TASK', {
  trigger: (payload: {
    communityId: string;
    data: EditCommunityTaskFormData;
    onSuccess: () => void;
  }) => payload,
});

export const doEditCommunityTask = createRoutine('EDIT_COMMUNITY_TASK', {
  trigger: (payload: {
    communityId: string;
    id: string;
    data: EditCommunityTaskFormData;
    onSuccess: () => void;
  }) => payload,
});

export const doDeleteCommunityTask = createRoutine('DELETE_COMMUNITY_TASK', {
  trigger: (payload: {
    communityId: string;
    id: string;
    onSuccess: () => void;
  }) => payload,
});
