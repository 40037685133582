import styled from 'styled-components';

import { DeleteIcon } from 'static';

export const StyledString = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 25px;
`;

export const StyledIcon = styled(DeleteIcon)`
  user-select: none;
`;

export const StyledButton = styled.div`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.color.baliHai};
  display: flex;
  align-items: center;

  &:hover {
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }
    span {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  &:active {
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.brightTurquoise};
      }
    }
    span {
      color: ${({ theme }) => theme.color.brightTurquoise};
    }
  }
`;

export const StyledLabel = styled.span`
  font-weight: 400 !important;
  font-size: 15px !important;
  color: ${({ theme }) => theme.color.bittersweet} !important;
  text-transform: none !important;
  margin-left: 10px;
`;
