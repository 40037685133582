const regexMap = {
  facebook:
    /^(?:https?:\/\/)?(?:www\.)?(mbasic\.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)$/,
  linkedin:
    /^(?:http(?:s)?:\/\/)?(?:www\.|\w\w\.)?linkedin\.com\/((?:in)\/(?:[a-zA-Z0-9-]{5,30}(?:\/)?)|(?:profile\/)(?:view\?id=[0-9]+))?$/,
  instagram:
    /^(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)$/,
  youtube:
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:c\/|channel\/|user\/)?)([\w\-]+)(\S+)?$/,
  twitter:
    /^(?:http(?:s)?:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)$/,
};

export const getPattern = (label: string) => {
  return regexMap[label];
};

export const socialFieldToLabel = (label: string) => {
  if (label === 'facebook') {
    return 'Facebook';
  } else if (label === 'twitter') {
    return 'Twitter';
  } else if (label === 'linkedin') {
    return 'LinkedIn';
  } else if (label === 'instagram') {
    return 'Instagram';
  } else if (label === 'youtube') {
    return 'YouTube';
  }

  return '';
};
