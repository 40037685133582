import {
  EyeIcon,
  HomeIcon,
  AwardIcon,
  BallIcon,
  BasketIcon,
  BeerIcon,
  BookIcon,
  BoxIcon,
  CarIcon,
  CheckoutIcon,
  CrownIcon,
  CutIcon,
  DangerIcon,
  DropIcon,
  EatIcon,
  GlobeIcon,
  HandshakeIcon,
  HeartIcon,
  KeyIcon,
  MapIcon,
  ProtectIcon,
  RulerIcon,
  SunIcon,
  TaskIcon,
  WatchIcon,
  BinocularsIcon,
  BriefcaseIcon,
  ClipboardIcon,
  DiamondIcon,
  DrinkIcon,
  MicrophoneIcon,
  MusicIcon,
  PaintIcon,
} from 'static';
import { FunctionComponent, SVGProps } from 'react';

export const taskIcons = {
  task: TaskIcon,
  car: CarIcon,
  drink: DrinkIcon,
  eat: EatIcon,
  music: MusicIcon,
  basket: BasketIcon,
  beer: BeerIcon,
  binoculars: BinocularsIcon,
  book: BookIcon,
  briefcase: BriefcaseIcon,
  checkout: CheckoutIcon,
  clipboard: ClipboardIcon,
  crown: CrownIcon,
  paint: PaintIcon,
  cut: CutIcon,
  danger: DangerIcon,
  diamond: DiamondIcon,
  ball: BallIcon,
  drop: DropIcon,
  eye: EyeIcon,
  heart: HeartIcon,
  globe: GlobeIcon,
  handshake: HandshakeIcon,
  home: HomeIcon,
  key: KeyIcon,
  map: MapIcon,
  microphone: MicrophoneIcon,
  protect: ProtectIcon,
  ruler: RulerIcon,
  box: BoxIcon,
  watch: WatchIcon,
  award: AwardIcon,
  sun: SunIcon,
};

export const taskIconsOptions: {
  value: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
}[] = [
  { value: 'task', icon: TaskIcon },
  { value: 'car', icon: CarIcon },
  { value: 'drink', icon: DrinkIcon },
  { value: 'eat', icon: EatIcon },
  { value: 'music', icon: MusicIcon },
  { value: 'basket', icon: BasketIcon },
  { value: 'beer', icon: BeerIcon },
  { value: 'binoculars', icon: BinocularsIcon },
  { value: 'briefcase', icon: BriefcaseIcon },
  { value: 'checkout', icon: CheckoutIcon },
  { value: 'clipboard', icon: ClipboardIcon },
  { value: 'crown', icon: CrownIcon },
  { value: 'paint', icon: PaintIcon },
  { value: 'cut', icon: CutIcon },
  { value: 'danger', icon: DangerIcon },
  { value: 'diamond', icon: DiamondIcon },
  { value: 'ball', icon: BallIcon },
  { value: 'drop', icon: DropIcon },
  { value: 'eye', icon: EyeIcon },
  { value: 'heart', icon: HeartIcon },
  { value: 'globe', icon: GlobeIcon },
  { value: 'handshake', icon: HandshakeIcon },
  { value: 'home', icon: HomeIcon },
  { value: 'key', icon: KeyIcon },
  { value: 'map', icon: MapIcon },
  { value: 'microphone', icon: MicrophoneIcon },
  { value: 'protect', icon: ProtectIcon },
  { value: 'ruler', icon: RulerIcon },
  { value: 'box', icon: BoxIcon },
  { value: 'watch', icon: WatchIcon },
  { value: 'award', icon: AwardIcon },
  { value: 'sun', icon: SunIcon },
];
