import styled from 'styled-components';

import { sharedStyles } from '../styled';

export const Button = styled.button`
  color: white;
  background: #2374f2;
  box-shadow: 0 1px 3px 1px #00000036;
  border-radius: 5px;
  ${sharedStyles}

  svg {
    width: 31px;
    height: 31px;
    margin-right: 10px;
  }
`;
