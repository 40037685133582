export enum ROUTES {
  COMMUNITY = '/community',
  COMMUNITY_SETTINGS = 'settings',
  GROUP = 'group',
  PROFILE = '/profile',
  HOME_PAGE = '/',
  PROFILE_SETTINGS = 'settings',
  EVENTS = 'events',
  DOCUMENTATION = 'documentation',
  MESSAGE = 'message',
  PAGE404 = '/404',
  PAGE500 = '/500',
  TERMS = 'https://gumb.app/terms-and-conditions',
  POLICY = 'https://gumb.app/privacy-policy',
  FAQ = 'https://gumb.app/support',
  FAQ_OTHER_LANGUAGES = 'https://gumb.app/en/support',
}

export enum COMMUNITY_ROUTES {
  EMAIL = 'email',
  DOCUMENTATION = 'documentation',
  SETTINGS = 'settings',
  GROUP = 'group',
  CHAT = 'chat',
  SUBSCRIPTIONS = 'subscriptions',
  OVERALL = 'overall',
  EVENT = 'event',
  PUSH_NOTIFICATION = 'push',
}

export enum PROFILE_ROUTES {
  SETTINGS = 'settings',
  SUBSCRIPTION = 'subscription',
  COMMUNITIES = 'communities',
  NOTIFICATIONS = 'notifications',
  PLANS = 'plans',
  CALENDAR_CONNECTION = 'connect-calendar',
}
