import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';

import { Wrapper } from './styled';

interface PopupWrapperProps {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[];
}

const PopupWrapper: FC<PopupWrapperProps> = ({ isOpen, children }) => {
  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {children}
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default PopupWrapper;
