import React, { FC, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormMethods } from 'react-hook-form';

import { InfoIcon } from 'static';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledSelect,
  StyledOption,
  StyledOptionIcon,
  StyledOptionIconRemove,
} from './styled';

interface MultiSelectInputProps
  extends Partial<Pick<UseFormMethods, 'register'>> {
  name: string;
  label?: string;
  info?: string;
  options: { [key: string]: any }[];
  width?: string;
  error?: FieldError;
  labelAlignment?: 'center' | 'left' | 'right';
  isDisabled?: boolean;
  placeholder?: string;
  defaultValue?: { [key: string]: any }[];
  setValue: (name: string, value: { [key: string]: any }[]) => void;
  clearError?: (name: string) => void;
  onBlur?: () => void;
}

const Option = (props) => {
  const { innerProps, isSelected, data } = props;
  return (
    <div {...innerProps}>
      <StyledOption isSelected={isSelected}>
        {data.label}
        {!isSelected ? <StyledOptionIcon /> : <StyledOptionIconRemove />}
      </StyledOption>
    </div>
  );
};

const MultiSelectInput: FC<MultiSelectInputProps> = ({
  name,
  label,
  labelAlignment,
  options,
  width,
  info,
  error,
  isDisabled,
  placeholder = '',
  defaultValue,
  setValue,
  register,
  clearError,
  onBlur = () => {},
}) => {
  const { t } = useTranslation();
  const [value, changeValue] = useState(defaultValue || []);

  useEffect(() => {
    register({ name });
  }, []);

  const changeHandler = (v) => {
    changeValue(v);
    setValue(name, v);

    if (clearError) {
      clearError(name);
    }
  };

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel
          error={error}
          htmlFor={name}
          labelAlignment={labelAlignment}
        >
          {label}{' '}
          {info && (
            <>
              <InfoIcon data-tip data-for="infoTip" />
              <ReactTooltip id="infoTip" effect="solid">
                {info}
              </ReactTooltip>
            </>
          )}
        </StyledLabel>
      )}
      <StyledSelect
        width={width || '100%'}
        id={name}
        name={name}
        options={options}
        value={value}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isMulti={true}
        hideSelectedOptions={false}
        isClearable={false}
        backspaceRemovesValue={false}
        isDisabled={isDisabled}
        minMenuHeight={250}
        maxMenuHeight={250}
        components={{ Option }}
        styles={{
          control: (base) => ({
            ...base,
            flexWrap: 'no-wrap',
          }),
          valueContainer: (base) => ({
            ...base,
            flex: 'auto',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            overflowX: 'scroll',
          }),
          multiValue: (base) => ({
            ...base,
            minWidth: '60px',
            backgroundColor: '#edf3f8',
          }),
          multiValueRemove: () => ({
            display: 'none',
          }),
          option: (base, state) => ({
            ...base,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            backgroundColor: state.isSelected
              ? '#edf3f8!important'
              : 'transparent!important',
            width: '77px',
            textAlign: 'center',
            padding: '3px 6px',
          }),
        }}
        error={error}
        closeMenuOnSelect={false}
        onChange={changeHandler}
        onBlur={onBlur}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default MultiSelectInput;
