import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FileIcon, ReturnIcon } from 'static';

export const FileItemContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledFileIcon = styled(FileIcon)``;

export const FileName = styled.span`
  margin-left: 10px;
`;

export const StyledArrowIcon = styled(ReturnIcon)`
  width: 12px;
  height: 12px;
  transform: rotate(180deg);

  polygon {
    fill: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const linkStyles = () => `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  font-size: 15px;
  text-decoration: none;
  font-weight: normal;
 `;

export const LinkWrapper = styled(Link)`
  ${linkStyles()};
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const StyledLink = styled.a`
  ${linkStyles()};
  color: ${({ theme }) => theme.color.pickledBluewood};
`;
