import { call, put, select, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';

import {
  addUserGroups,
  changeGroupUserRole,
  deleteGroupUser,
  deleteUserGroups,
  loadUsers,
} from 'services/users';
import { doGetGroupUsers } from 'store/actionCreators/currentGroup';
import {
  doAddUserGroups,
  doChangeGroupUserRole,
  doDeleteGroupUser,
  doDeleteUserGroups,
  doRearrangeGroupUsers,
} from 'store/actionCreators/currentGroup/users';
import { rearrangeGroupUsers } from 'services/groups';
import { CurrentGroup, GroupRoles, GroupUser } from 'types';
import {
  getCurrentGroup,
  getGroupAdmins,
  getGroupLeaders,
  getGroupMembers,
} from 'store/selectors/currentGroup';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { toast } from '../../../utils/toastAbstraction';

export function* onGetGroupUsers({ payload }) {
  try {
    const data = yield call(loadUsers, {
      communityId: payload.communityId,
      groupId: payload.groupId,
      limit: 99999,
    });
    yield put(doGetGroupUsers.success(data));
  } catch ({ response }) {
    yield put(doGetGroupUsers.failure(response.data));
  }
}

export function* onChangeGroupUserRole({
  payload,
}: ReturnType<typeof doChangeGroupUserRole>) {
  try {
    const { communityId, groupId, userId } = payload;
    const data = yield call(
      changeGroupUserRole,
      communityId,
      groupId,
      userId,
      payload.data,
    );

    yield put(doChangeGroupUserRole.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doChangeGroupUserRole.failure(response.data));
  }
}

export function* onDeleteGroupUser({
  payload,
}: ReturnType<typeof doDeleteGroupUser>) {
  try {
    const { communityId, groupId, users } = payload;
    yield call(deleteGroupUser, communityId, groupId, users);
    yield put(doDeleteGroupUser.success(users));
    yield call(payload.onSuccess);
  } catch (response) {
    yield put(doDeleteGroupUser.failure(response.data));
  }
}

export function* onDeleteUserGroups({ payload }) {
  try {
    const { communityId, groupId, userId } = payload;
    yield call(deleteUserGroups, communityId, groupId, userId);
    yield put(doDeleteUserGroups.success(userId));
    yield call(payload.onSuccess);
  } catch (response) {
    yield put(doDeleteUserGroups.failure(response.data));
  }
}

export function* onAddUserGroups({ payload }) {
  try {
    const { communityId, groupId, userId } = payload;
    yield call(addUserGroups, communityId, groupId, userId);
    yield put(doAddUserGroups.success(userId));
    yield call(payload.onSuccess);
  } catch (response) {
    yield put(doAddUserGroups.failure(response.data));
  }
}

export function* onRearrangeGroupUsers({
  payload,
}: ReturnType<typeof doRearrangeGroupUsers>) {
  const { start, end, groupId, prevRole, newRole, userId } = payload;
  let selector = null;

  switch (newRole) {
    case GroupRoles.MEMBER: {
      selector = getGroupMembers;
      break;
    }
    case GroupRoles.GROUP_LEAD: {
      selector = getGroupLeaders;
      break;
    }
    case GroupRoles.ADMIN: {
      selector = getGroupAdmins;
      break;
    }
    default:
      break;
  }

  const usersByRole: GroupUser[] = yield select(selector);

  if (prevRole === newRole) {
    const newUsersList = [...usersByRole];

    newUsersList.splice(end, 0, newUsersList.splice(start, 1)[0]);

    yield put(
      doRearrangeGroupUsers.success({
        newUsersList,
        role: newRole,
      }),
    );
  } else {
    const currentCommnuity = yield select(getCurrentCommunity);
    const currentGroup: CurrentGroup = yield select(getCurrentGroup);
    const allGroupUsers = currentGroup?.users?.data || [];
    const userData = allGroupUsers.find((u) => u.id === userId);

    if (userData) {
      const updatedUsersByRole = [...usersByRole];

      updatedUsersByRole.splice(end, 0, {
        ...userData,
        groupPermission: { ...userData.groupPermission, role: newRole },
      });

      const newUsersList = [
        ...allGroupUsers.filter(
          (u) => u.groupPermission.role !== newRole && u.id !== userId,
        ),
      ].concat(updatedUsersByRole);

      yield put(
        doRearrangeGroupUsers.success({
          newUsersList,
        }),
      );

      yield call(changeGroupUserRole, currentCommnuity.id, groupId, userId, {
        role: newRole,
      });

      yield toast(i18n.t('communitySettings.editRoleSuccess'), {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  }

  yield call(rearrangeGroupUsers, userId, end, groupId, newRole);
}

export default function* () {
  yield takeLatest(doGetGroupUsers, onGetGroupUsers);
  yield takeLatest(doChangeGroupUserRole, onChangeGroupUserRole);
  yield takeLatest(doDeleteGroupUser, onDeleteGroupUser);
  yield takeLatest(doDeleteUserGroups, onDeleteUserGroups);
  yield takeLatest(doAddUserGroups, onAddUserGroups);
  yield takeLatest(doRearrangeGroupUsers, onRearrangeGroupUsers);
}
