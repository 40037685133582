import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { breakpoints } from 'theme';

export const StyledMenuBlock = styled.div`
  width: ${({ isMenuOpen }) => (isMenuOpen ? 300 : 47)}px;
  height: ${({ isMenuOpen }) => (isMenuOpen ? '100vh' : '42px')};
  box-shadow: 0 4px 12px 0 rgb(48 70 89 / 20%);
  background-color: #fbfffe;
  border-radius: 8px;
  text-align: left;
  z-index: 5;
  position: fixed;
  top: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 5)}px;
  left: ${({ isMenuOpen }) => (isMenuOpen ? 60 : 80)}px;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: width 0.3s, height 0.3s, left 0.3s, top 0.3s;

  @media ${breakpoints.downMd} {
    top: 0;
    left: 0;
    border-radius: 0 12px 12px 0;
  }

  @media (max-width: 500px) {
    width: calc(100vw - 30px);
    border-radius: 0;
  }
`;

export const StyledBurger = styled.div`
  width: 19px;
  height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 13px;
  cursor: pointer;
  margin-top: ${({ isMenuOpen }) => (isMenuOpen ? 0 : -5)}px;

  div {
    height: 3px;
    background: #25cdc8;
    border-radius: 1px;
  }

  @media ${breakpoints.downMd} {
    visibility: hidden;
  }
`;

export const StyledMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0px 12px 0px 15px;

  @media (max-width: 500px) {
    margin-right: 50px;
  }

  @media ${breakpoints.downMd} {
    .notifications-count {
      right: 11px;
    }
  }

  @media (max-width: 500px) {
    .notifications-count {
      right: 60px;
    }
  }
`;

export const StyledMenuItemsBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
  transition: opacity 0.3s;
  padding: 0px 4px;
`;

export const StyledSettingsLink = styled(NavLink)`
  &:hover {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  &.active {
    path {
      fill: ${({ theme }) => theme.color.brightTurquoise};
    }

    path:hover {
      fill: ${({ theme }) => theme.color.brightTurquoise};
    }
  }
`;

export const StyledShadow = styled.div`
  position: absolute;
  width: 92%;
  height: 10px;
  bottom: -2px;
  background: #fbfffe;
  box-shadow: 0px -7px 9px 7px #fbfffe94;
  padding-right: 15px;
`;
