export const isTotalShown = (tableId: string) => {
  try {
    const totalTablesState = JSON.parse(localStorage.getItem('totalBlocks'));

    if (!totalTablesState[tableId] && totalTablesState[tableId] !== false) {
      totalTablesState[tableId] = true;
      localStorage.setItem('totalBlocks', JSON.stringify(totalTablesState));
    }

    return totalTablesState[tableId];
  } catch (_) {
    localStorage.setItem('totalBlocks', JSON.stringify({ [tableId]: true }));

    return true;
  }
};

export const toggleTotal = (tableId: string) => {
  try {
    const totalTablesState = JSON.parse(localStorage.getItem('totalBlocks'));

    if (!totalTablesState[tableId] && totalTablesState[tableId] !== false) {
      totalTablesState[tableId] = false;
      localStorage.setItem('totalBlocks', JSON.stringify(totalTablesState));

      return;
    }

    totalTablesState[tableId] = !totalTablesState[tableId];

    return localStorage.setItem(
      'totalBlocks',
      JSON.stringify(totalTablesState),
    );
  } catch (_) {
    return localStorage.setItem(
      'totalBlocks',
      JSON.stringify({ [tableId]: false }),
    );
  }
};

export const isSubTableShown = (tableId: string, subTableId: string) => {
  try {
    const tablesState = JSON.parse(localStorage.getItem('tablesState'));
    const currentTableState = tablesState[tableId] || {};

    if (
      !currentTableState[subTableId] &&
      currentTableState[subTableId] !== false
    ) {
      currentTableState[subTableId] = true;
      localStorage.setItem(
        'tablesState',
        JSON.stringify({
          ...tablesState,
          [tableId]: currentTableState,
        }),
      );
    }

    return currentTableState[subTableId];
  } catch (_) {
    localStorage.setItem(
      'tablesState',
      JSON.stringify({ [tableId]: { [subTableId]: true } }),
    );

    return true;
  }
};

export const toggleSubTable = (tableId: string, subTableId: string) => {
  try {
    const tablesState = JSON.parse(localStorage.getItem('tablesState'));
    const currentTableState = tablesState[tableId] || {};

    if (
      !currentTableState[subTableId] &&
      currentTableState[subTableId] !== false
    ) {
      currentTableState[subTableId] = false;
      localStorage.setItem(
        'tablesState',
        JSON.stringify({
          ...tablesState,
          [tableId]: currentTableState,
        }),
      );

      return;
    }

    currentTableState[subTableId] = !currentTableState[subTableId];

    return localStorage.setItem(
      'tablesState',
      JSON.stringify({
        ...tablesState,
        [tableId]: currentTableState,
      }),
    );
  } catch (_) {
    localStorage.setItem(
      'tablesState',
      JSON.stringify({ [tableId]: { [subTableId]: false } }),
    );
  }
};

export const getColumnDivider = (
  columnIndex: number,
  divideColumns?: number[][],
) => {
  let divide: 'left' | 'right' = null;

  if (divideColumns?.flat().includes(columnIndex)) {
    const arrInd = divideColumns.findIndex((i) => i.includes(columnIndex));
    const isLeft = divideColumns[arrInd][0] === columnIndex;

    divide = isLeft ? 'left' : 'right';
  }

  return divide;
};
