import React, { FC } from 'react';

import { CloseButton, ReturnButton } from 'components';

import { StyledWrapper, StyledLabel } from './styled';

interface SidebarHeaderProps {
  label: string;
  variant?: 'primary' | 'secondary';
  onReturnClick?: () => void | null;
  onCloseClick?: () => void | null;
}

const SidebarHeader: FC<SidebarHeaderProps> = ({
  label,
  variant = 'primary',
  onCloseClick,
  onReturnClick,
}) => (
  <StyledWrapper variant={variant}>
    {onReturnClick && <ReturnButton onClick={onReturnClick} />}
    <StyledLabel>{label}</StyledLabel>
    {onCloseClick && <CloseButton hidden={false} onClick={onCloseClick} />}
  </StyledWrapper>
);

export default SidebarHeader;
