import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EventData } from '.';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { CommunityUsersByGroups, Tooltip } from 'components';

import { EventCard } from 'pages/UserProfileEvents/styled';
import {
  ComponentInfoText,
  ComponentTitle,
  CustomEventCard,
  EventAndRecipientContainer,
  ExportModuleWrapper,
} from './styles';
import { Community, Group } from 'types';
import moment from 'moment';
import { getDateString, getTimeInterval } from 'utils';
import { getSettings } from 'store/selectors/auth';
import { useDispatch } from 'react-redux';

type Props = {
  eventData: Array<EventData>;
  community: Community;
  group?: Group;
  participants?: Array<string>;
  onChange: (value: string[]) => void;
};

const PreviewBeforeSend: React.FC<Props> = ({
  eventData,
  community,
  group,
  participants,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const event = eventData[0];
  const zoom = 1;

  const eventType = community.eventTypes?.filter(
    (eType) => event.eventTypeName === eType.title,
  )[0];

  const users = useMemo(() => {
    if (group && group.id) {
      const groupUsers =
        community?.membersByGroups?.find((g) => g.id === group.id)?.users || [];
      return community.users.filter((user) =>
        groupUsers.some((groupUser) => groupUser.id === user.id),
      );
    }
    return community.users || [];
  }, [community?.membersByGroups, group]);

  const membersByGroups = useMemo(() => {
    if (group && group.id) {
      return community?.membersByGroups?.filter((g) => g.id === group.id);
    }
    return community?.membersByGroups || [];
  }, [community?.membersByGroups, group]);

  return (
    <ExportModuleWrapper>
      <ComponentTitle>
        {t('bulkUploadEvents.stepThree.componentTitle')}
      </ComponentTitle>
      <ComponentInfoText width={'min(400px, 100%)'}>
        {t('bulkUploadEvents.stepThree.componentDescription')}
        <br />
        <br />
      </ComponentInfoText>
      <EventAndRecipientContainer>
        <CustomEventCard zoom={zoom}>
          <EventCard.Event
            color={eventType?.color || theme.color.brightTurquoise}
            zoom={zoom}
            style={{ padding: '7px' }}
          >
            <EventCard.Event.Header>
              <Tooltip
                text={eventType?.title || event.eventTypeName}
                zoom={zoom}
                isVisible
                tooltipStyle={{
                  fontSize: 12 * zoom,
                  height: 23 * zoom + 'px',
                }}
                visibleAfterWidth={235 * zoom}
                offset={10 * zoom}
                position="top-center"
              >
                <EventCard.Event.EventType
                  zoom={zoom}
                  style={{ fontSize: '10px' }}
                >
                  <span>{eventType?.title || event.eventTypeName}</span>
                </EventCard.Event.EventType>
              </Tooltip>
            </EventCard.Event.Header>
            <Tooltip
              text={event.title}
              zoom={zoom}
              isVisible
              tooltipStyle={{
                fontSize: 12 * zoom,
                height: 23 * zoom + 'px',
              }}
              visibleAfterHeight={Math.floor(18 * zoom) * 3}
              offset={35 * zoom}
              position="top-center"
            >
              <EventCard.Event.EventTitle
                zoom={zoom}
                style={{ fontWeight: 800 }}
              >
                <span>{event.title}</span>
              </EventCard.Event.EventTitle>
            </Tooltip>
            <EventCard.Event.Footer style={{ alignItems: 'center' }}>
              <div>
                <EventCard.Event.DateTime
                  zoom={zoom}
                  style={{ fontSize: '10px' }}
                >
                  {`${t(
                    `dayOfWeek.day${moment(event.from).day()}`,
                  )}, ${getDateString(new Date(event.from))}`}
                </EventCard.Event.DateTime>
                {!event.allDay && (
                  <EventCard.Event.DateTime
                    zoom={zoom}
                    style={{ fontSize: '10px' }}
                  >
                    {getTimeInterval(
                      new Date(event.from),
                      new Date(event.to),
                      settings.timeFormat,
                    )}
                  </EventCard.Event.DateTime>
                )}
              </div>
              {event.description && (
                <Tooltip
                  text={event.description}
                  zoom={zoom}
                  isVisible
                  tooltipStyle={{
                    fontSize: 12 * zoom,
                    maxWidth: 200 * zoom,
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    lineBreak: 'normal',
                  }}
                  position="top-center"
                >
                  <div
                    className="icon-description"
                    style={{
                      width: 20.6 * zoom,
                      height: 20.6 * zoom,
                      fontSize: 12 * zoom + 'px',
                      borderWidth: 2 * zoom,
                      textAlign: 'center',
                      position: 'relative',
                    }}
                  >
                    <span
                      style={{
                        width: '100%',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        margin: 'auto',
                      }}
                    >
                      i
                    </span>
                  </div>
                </Tooltip>
              )}
            </EventCard.Event.Footer>
          </EventCard.Event>
        </CustomEventCard>
        <div style={{ height: '100%' }}>
          <CommunityUsersByGroups
            membersByGroups={membersByGroups}
            users={users}
            value={participants}
            onChange={onChange}
            height="230px"
            key={'eventUpload'}
          />
        </div>
      </EventAndRecipientContainer>
    </ExportModuleWrapper>
  );
};

export default PreviewBeforeSend;
