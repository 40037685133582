import styled from 'styled-components';

import { CheckboxCheckedIcon, CheckboxDefaultIcon } from 'static';
import { breakpoints } from 'theme';

export const StyledHeader = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;

export const StyledSelectAllButton = styled.button`
  width: 14px;
  height: 14px;
  border: none;
  outline: none;
  background-image: ${({ checked }) =>
    `url(${checked ? CheckboxCheckedIcon : CheckboxDefaultIcon})`};
  cursor: pointer;
`;

export const StyledHeaderLabel = styled.span`
  margin-left: 11px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledListItem = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  font-weight: 400;
  font-size: 15px;
  @media ${breakpoints.downSmPlus} {
    height: auto;
  }
  & > div:nth-of-type(1) {
    width: 56px;
    min-width: 56px;
  }

  & > div:nth-of-type(2) {
    /* width: calc(50% - 28px); */
    display: flex;
    width: 100%;
    gap: 30%;
    align-items: center;
    @media ${breakpoints.downMd} {
      gap: 10%;
    }
    @media ${breakpoints.downSmPlus} {
      gap: 0%;
      flex-direction: column;
      padding-bottom: 8px;
    }
  }

  /* & > div:nth-of-type(2) {
    /* width: calc(50% - 28px); */
  /* text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px; */
  /* }  */

  @media ${breakpoints.downSmPlus} {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div:nth-of-type(1) {
      position: sticky;
      left: 0;
      background-image: linear-gradient(90deg, white 63%, transparent);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /* & > div:nth-of-type(2) {
      width: 300px;
      min-width: 300px;
    }

    & > div:nth-of-type(3) {
      width: 300px;
      min-width: 300px;
    } */
  }
`;
