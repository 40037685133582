// util functions exclusive to push notification
import i18n from 'i18next';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
// new dimensions for more spacing
const PUSH_FORM_WIDTH = 320 + 60;
const MOBILE_WIDTH = 340 + 60;
const TEMPLATES_WIDTH = 320;

// old dimensions
const pushMessageWidth = 30 + 340 + 16;
const mobileWidth = 340 + 16;
const templateWidth = 340;

export const getDisplayFormat = (
  currentWidth: number,
): 'single' | 'double' | 'triple' => {
  const currentScreenWidth = window.innerWidth;
  const isDownMd = currentScreenWidth < 960 && currentScreenWidth > 600;
  const extraPadding = isDownMd ? 30 : 0;
  if (currentWidth) {
    if (currentWidth < PUSH_FORM_WIDTH + MOBILE_WIDTH - 60 + extraPadding) {
      //subtract 60 because only 2 elements and gap is 60px only.
      return 'single';
    } else if (
      currentWidth <
      PUSH_FORM_WIDTH + MOBILE_WIDTH + TEMPLATES_WIDTH
    ) {
      return 'double';
    } else if (
      currentWidth >=
      PUSH_FORM_WIDTH + MOBILE_WIDTH + TEMPLATES_WIDTH
    ) {
      return 'triple';
    }
  }
};

export const useDateTime = () => {
  const [dateTime, setDateTime] = useState<{ date: string; time: string }>({
    date: '',
    time: '',
  });

  useEffect(() => {
    const trackTime = setInterval(() => {
      const language = i18n.language;
      const today = moment().locale(language);
      const values = {
        date: today.format('dddd, D MMMM'),
        time: today.format('HH:mm'),
      };
      setDateTime(values);
    }, 10000);

    return () => {
      clearInterval(trackTime);
    };
  }, []);

  return dateTime;
};

export const useHideImage = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  return useMemo(() => {
    if (title.length > 60 || message.length > 100) {
      return true;
    } else {
      return false;
    }
  }, [title, message]);
};
