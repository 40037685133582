import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  GROUP_NAME = 'name',
}

export type CreateGroupValues = {
  [FIELDS.GROUP_NAME]: string;
};

export const useCreateGroupForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<CreateGroupValues> = yupResolver(
    yup.object().shape({
      [FIELDS.GROUP_NAME]: yup.string().required().min(3).max(30).trim(),
    }),
  );

  return useForm<CreateGroupValues>({
    resolver,
  });
};
