import React, { useState, useMemo, FC } from 'react';
import { noop } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLayer } from 'react-laag';

import { SearchIcon, FilterIcn } from 'static';
import { StyledResetButtonBlock } from '../../../pages/CommunityOverallScheduler/Schedule/styled';
import { FilterBlock } from '../../../pages/UserProfileEvents/styled';
import { getEventTypes } from '../../../store/selectors/eventTypes';
import { ResetButton, DatePickerControlled, Button, Tooltip } from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import { StyledTooltip } from 'components/FilterTooltip/styled';

import {
  StyledInputBox,
  StyledInput,
  StyledIconsBlock,
  StyledLeftIcon,
  StyledRightIcon,
} from './styled';
import { SchedulerFilter } from 'types';
import { MyEventsFilterValues } from '../../../pages/UserProfileEvents';

interface SearchInputProps {
  placeholder?: string;
  value: string;
  disabled?: boolean;
  withFilter?: boolean;
  width?: string;
  filters?: SchedulerFilter | MyEventsFilterValues;
  noEndTime?: boolean;
  noApply?: boolean;
  fillWidth?: boolean;
  eventTypesOptions?: { [p: string]: string }[];
  onChange: (e: any) => void;
  onSearch?: () => void;
  setFilters?: (value: SchedulerFilter | MyEventsFilterValues) => void;
  applyFilters?: () => void;
  resetFilters?: () => void;
  initialFiltersState?: SchedulerFilter;
}

const SearchInput: FC<SearchInputProps> = ({
  placeholder,
  value,
  disabled,
  withFilter,
  width = '225px',
  filters,
  noEndTime,
  noApply,
  fillWidth,
  eventTypesOptions: customEventTypes,
  onChange,
  onSearch = noop,
  setFilters = noop,
  applyFilters = noop,
  resetFilters: onReset = noop,
  initialFiltersState,
}) => {
  const { t } = useTranslation();
  const eventTypes = useSelector(getEventTypes) || [];
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState<
    SchedulerFilter | MyEventsFilterValues
  >(filters);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: filtersVisible,
    placement: 'bottom-center',
    triggerOffset: 10,
    auto: true,
    onOutsideClick: () => setFiltersVisible(false),
    containerOffset: 20,
  });

  const eventTypesOptions = useMemo(() => {
    if (customEventTypes) {
      return customEventTypes;
    }

    return (
      eventTypes && [
        ...eventTypes.map((type) => ({
          label: type.title,
          value: type.id,
          color: type.color,
        })),
        { label: t('common.all'), value: 'all', color: '#33ABA7' },
      ]
    );
  }, [customEventTypes, eventTypes]);

  const onFilterSubmit = () => {
    if (!Object.is(filters, localFilters)) {
      setFilters((prevFilter) => ({
        ...prevFilter,
        to: localFilters.to,
        from: localFilters.from,
        eventType: localFilters.eventType,
      }));
      setIsFilterApplied(true);
    }
    setFiltersVisible(false);
  };

  const onResetFilter = () => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      ...initialFiltersState,
    }));
    setIsFilterApplied(false);
    onReset();
  };

  return (
    <StyledInputBox fillWidth>
      <StyledInput
        disabled={disabled}
        placeholder={placeholder || t('common.search')}
        value={value}
        onChange={onChange}
        width={width}
        fillWidth={fillWidth}
        withFilter={withFilter}
      />
      <StyledIconsBlock>
        <StyledLeftIcon
          src={SearchIcon}
          value={value}
          disabled={disabled}
          onClick={onSearch}
          alt=""
        />
        {withFilter && (
          <>
            <div {...triggerProps}>
              <Tooltip
                isVisible
                text={t('filters.clickToSelect')}
                opacity="1"
                offset={9}
                tooltipStyle={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: '200px',
                  height: 'auto',
                }}
              >
                <StyledRightIcon
                  id="toggle-filters-icon"
                  selected={filtersVisible || isFilterApplied}
                  onClick={() => setFiltersVisible(!filtersVisible)}
                >
                  <FilterIcn />
                </StyledRightIcon>
              </Tooltip>
            </div>
            {renderLayer(
              filtersVisible && (
                <StyledTooltip {...layerProps}>
                  <FilterBlock>
                    <div>
                      <DatePickerControlled
                        label={t('common.from')}
                        date={localFilters.from}
                        popperModifiers={{
                          flip: { behavior: ['bottom'] },
                          preventOverflow: { enabled: false },
                          hide: { enabled: false },
                        }}
                        handleChange={(date) => {
                          setLocalFilters((prevFilter) => ({
                            ...prevFilter,
                            from: new Date(date),
                          }));
                        }}
                      />
                    </div>
                    {!noEndTime && (
                      <div>
                        <DatePickerControlled
                          label={t('common.to')}
                          date={localFilters['to']}
                          popperModifiers={{
                            flip: { behavior: ['bottom'] },
                            preventOverflow: { enabled: false },
                            hide: { enabled: false },
                          }}
                          handleChange={(date) => {
                            setLocalFilters((prevFilter) => ({
                              ...prevFilter,
                              to: new Date(date),
                            }));
                          }}
                        />
                      </div>
                    )}
                    <div>
                      <SelectInputControlled
                        options={eventTypesOptions}
                        width="100%"
                        label={t('event.eventType')}
                        value={localFilters.eventType}
                        maxMenuHeight={200}
                        onChange={(v) => {
                          setLocalFilters((prevFilter) => ({
                            ...prevFilter,
                            eventType: v,
                          }));
                        }}
                      />
                    </div>
                    <StyledResetButtonBlock>
                      <ResetButton onClick={onResetFilter} />
                    </StyledResetButtonBlock>
                    {!noApply && (
                      <Button width="100%" onClick={onFilterSubmit}>
                        {t('common.apply')}
                      </Button>
                    )}
                  </FilterBlock>
                </StyledTooltip>
              ),
            )}
          </>
        )}
      </StyledIconsBlock>
    </StyledInputBox>
  );
};

export default SearchInput;
