import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { getCurrentCommunity, selectGroupId } from './currentCommunity';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;

export const getDocumentationOverview = createSelector(
  getCurrentCommunity,
  (community) => community.documentation?.overview || null,
);

export const getDocuments = createSelector(
  getCurrentCommunity,
  (community) => community.documentation?.documents || null,
);

export const getGroupDocuments = createSelector(
  [getDocuments, selectGroupId],
  (documents, groupId) =>
    groupId === 'general'
      ? (documents || []).filter((d) => d.type === 'general')
      : (documents || []).filter((d) => d.groupId === groupId),
);

export const getIsDocumentUploading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.uploadDocument,
);
