import React, { useEffect, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getProfileName } from 'utils/common';
import { ProfileName, CheckBox, SearchInput } from 'components';
import { getAllGroupMembers } from 'store/selectors/currentGroup';
import { useParticipantsValues } from './hooks';

import {
  StyledHeader,
  StyledHeaderLabel,
  StyledListItem,
  StyledNameWrapper,
  StyledSelectAllButton,
  ScrollWrapper,
  StyledSearchWrapper,
} from './styled';

const GroupUsersListControlled = ({ recipientsFieldProps }) => {
  const { t } = useTranslation();
  const members = useSelector(getAllGroupMembers);
  const [filteredMembers, setFilteredMembers] = useState(members);

  const {
    communityUsers,
    selectParticipant,
    searchParticipantsInputValue,
    setSearchParticipantsInputValue,
    handleSelectAllParticipantsClick,
    selectAllGroupsAdmins,
    allParticipantsChecked,
    allGroupAdminsChecked,
    membersPermissions,
  } = useParticipantsValues(members, recipientsFieldProps);

  const renderMember = ({ index, key, style }) => {
    const member = filteredMembers[index];

    return (
      <StyledListItem key={key} style={style}>
        <CheckBox
          isSelected={recipientsFieldProps.value.includes(member.id)}
          onChange={(e) => selectParticipant(e.target.name)}
          name={member.id}
        />
        <StyledNameWrapper>
          <ProfileName
            name={getProfileName(member.profile)}
            avatar={member.profile.smallLogo || member.profile.logo}
            communityPermission={
              membersPermissions.find((m) => m.id === member.id)
                ?.communityPermission
            }
            groupPermission={
              membersPermissions.find((m) => m.id === member.id)
                ?.groupPermission
            }
          />
        </StyledNameWrapper>
      </StyledListItem>
    );
  };

  useEffect(() => {
    if (communityUsers?.length > 0) {
      setFilteredMembers(
        searchParticipantsInputValue
          ? communityUsers.filter(
              (u) =>
                getProfileName(u.profile)
                  .toLowerCase()
                  .includes(searchParticipantsInputValue.toLowerCase()) ||
                u.profile.email.includes(
                  searchParticipantsInputValue.toLowerCase(),
                ),
            )
          : communityUsers,
      );
    }
  }, [communityUsers, searchParticipantsInputValue]);

  return (
    <>
      <StyledSearchWrapper>
        <SearchInput
          width="100%"
          value={searchParticipantsInputValue}
          onChange={(e) => setSearchParticipantsInputValue(e.target.value)}
        />
      </StyledSearchWrapper>
      <StyledHeader>
        <StyledSelectAllButton
          type="button"
          onClick={handleSelectAllParticipantsClick}
          checked={allParticipantsChecked}
        />
        <StyledHeaderLabel>{t('common.selectAll')}</StyledHeaderLabel>
      </StyledHeader>
      <StyledHeader>
        <StyledSelectAllButton
          type="button"
          onClick={selectAllGroupsAdmins}
          checked={allGroupAdminsChecked}
        />
        <StyledHeaderLabel>
          {t('community.selectAllGroupAdmins')}
        </StyledHeaderLabel>
      </StyledHeader>
      <ScrollWrapper>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore*/}
        <AutoSizer>
          {({ width }) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <List
              rowRenderer={renderMember}
              height={filteredMembers.length * 61}
              rowCount={filteredMembers.length}
              width={width}
              rowHeight={61}
            />
          )}
        </AutoSizer>
      </ScrollWrapper>
    </>
  );
};

export default GroupUsersListControlled;
