import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import SelectInputControlled from '../SelectInputControlled/SelectInputControlledV2';

interface DateRangeSelectProps {
  value: { label: string; value: [Moment, Moment] | string };
  onChange: (option: { label: string; value: [Moment, Moment] }) => void;
}

const DateRangeSelect: FC<DateRangeSelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('filters.dateRanges.thisYear'),
      value: [moment().utc().startOf('year'), moment().utc().endOf('year')],
    },
    {
      label: t('filters.dateRanges.lastYear'),
      value: [
        moment().utc().subtract(1, 'year').startOf('year'),
        moment().utc().subtract(1, 'year').endOf('year'),
      ],
    },
    { label: t('filters.dateRanges.specificDateRange'), value: 'dateRange' },
    {
      label: t('filters.dateRanges.allTime'),
      value: [
        moment().utc().subtract(10, 'years'),
        moment().utc().add(1, 'month'),
      ],
    },
    {
      label: t('filters.dateRanges.yearToDate'),
      value: [moment().utc().startOf('year'), moment().utc().endOf('day')],
    },
    {
      label: t('filters.dateRanges.thisWeek'),
      value: [moment().utc().startOf('week'), moment().utc().endOf('week')],
    },
    {
      label: t('filters.dateRanges.thisMonth'),
      value: [moment().utc().startOf('month'), moment().utc().endOf('month')],
    },
    {
      label: t('filters.dateRanges.lastDays', { number: 30 }),
      value: [
        moment().utc().subtract(1, 'month').startOf('day'),
        moment().utc().endOf('day'),
      ],
    },
    {
      label: t('filters.dateRanges.lastDays', { number: 14 }),
      value: [moment().utc().subtract(14, 'days'), moment().utc()],
    },
    {
      label: t('filters.dateRanges.lastDays', { number: 7 }),
      value: [moment().utc().subtract(7, 'days'), moment().utc()],
    },
    {
      label: t('filters.dateRanges.yesterday'),
      value: [
        moment().utc().subtract(1, 'day').startOf('day'),
        moment().utc().subtract(1, 'day').endOf('day'),
      ],
    },
    {
      label: t('filters.dateRanges.today'),
      value: [moment().utc().startOf('day'), moment().utc().endOf('day')],
    },
    {
      label: t('filters.dateRanges.tomorrow'),
      value: [
        moment().utc().add(1, 'day').startOf('day'),
        moment().utc().add(1, 'day').endOf('day'),
      ],
    },
    {
      label: t('filters.dateRanges.nextDays', { number: 7 }),
      value: [
        moment().utc().startOf('day'),
        moment().utc().add(7, 'days').startOf('day'),
      ],
    },
    {
      label: t('filters.dateRanges.nextDays', { number: 30 }),
      value: [
        moment().utc().startOf('day'),
        moment().utc().add(30, 'days').startOf('day'),
      ],
    },
  ];

  return (
    <SelectInputControlled
      label={t('filters.dateRange')}
      width="100%"
      options={options}
      value={value}
      maxMenuHeight={350}
      onChange={onChange}
    />
  );
};

export default DateRangeSelect;
