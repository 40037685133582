import styled from 'styled-components';

export const StyledSearchWrapper = styled.div`
  padding: 13px 16px 14px 15px;
`;

export const StyledPlaceholder = styled.p`
  margin-top: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 400;
  text-align: center;
`;
