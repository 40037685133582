import { axiosInstance as axios } from '../index';
import { EditCommunityEventTypeFormData } from 'sidebars/EditCommunityEventType/form';

export const requestCommunityDefaultEventSetting = async (id: string) => {
  const response = await axios.get(
    `/communities/${id}/getDefaultEventTypeSetting`,
  );
  return response.data;
};

export const requestCreateCommunityDefaultEventSetting = async (
  communityId: string,
  data: EditCommunityEventTypeFormData,
) => {
  const response = await axios.post(
    `/communities/${communityId}/createDefaultEventTypeSetting`,
    data,
  );
  return response.data;
};

export const requestEditCommunityDefaultEventSetting = async (
  communityId: string,
  id: string,
  data: EditCommunityEventTypeFormData,
) => {
  const response = await axios.put(
    `/communities/${communityId}/updateDefaultEventTypeSetting/${id}`,
    data,
  );
  return response.data;
};

export const requestDeleteCommunityDefaultEventSetting = async (
  communityId: string,
  id: string,
) => {
  const response = await axios.delete(
    `/communities/${communityId}/deleteDefaultEventTypeSetting/${id}`,
  );
  return response.data;
};
