import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeModal } from 'store/actionCreators/modal';
import { SOCIAL_NETWORKS, PHONES } from 'configs';
import { capMap } from 'utils';
import {
  Sidebar,
  Button,
  ImageInput,
  SidebarHeader,
  SidebarFooter,
  SidebarInputsWrapper,
  SidebarImageInputWrapper,
  DatePickerWithYear,
  EmojiArea,
  OverflowSidebarContent,
  Loader,
  SelectInput,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useEditProfile } from './hooks';
import { FIELDS } from './form';
import { getAuthUser, getEditProfileLoading } from 'store/selectors/auth';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { Profile } from 'types';
import { getCountries } from 'utils/countries';

interface EditProfileProps {
  memberProfile?: Profile;
}

const EditProfile: FC<EditProfileProps> = ({ memberProfile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userData] = useState(memberProfile);
  const profile = useSelector(getAuthUser);
  const isLoading = useSelector(getEditProfileLoading);
  const community = useSelector(getCurrentCommunity);
  const { countryListForSelect: countries } = getCountries();
  const {
    onSubmit,
    register,
    unregister,
    setValue,
    control,
    errors,
    formState: { isDirty },
    getValues,
  } = useEditProfile({
    profile: (profile.id !== memberProfile.id && memberProfile) || {
      ...profile,
      profileVisible: false,
    },
    isMemberProfileEdit: profile.id !== memberProfile.id && !!memberProfile,
    communityId: community?.id,
  });

  useEffect(() => {
    if (userData?.selectedFiled) {
      const el = document.getElementById(userData.selectedFiled);
      const smoothScroll = () => {
        el?.scrollIntoView({
          behavior: 'smooth',
        });
      };
      const focusAfterScroll = () => {
        el?.focus();
      };
      setTimeout(() => {
        requestAnimationFrame(smoothScroll);
      }, 100);
      setTimeout(focusAfterScroll, 500);
    }
  }, [userData?.selectedFiled]);

  const onCloseClick = useCallback(() => {
    if (isDirty) {
      const result = window.confirm(t('common.modalExit'));

      if (result) {
        dispatch(closeModal());
      }
    } else {
      dispatch(closeModal());
    }
  }, [isDirty]);

  const country = useMemo(() => {
    const country: string = getValues(FIELDS.COUNTRY) ?? profile.country ?? '';
    return country ? { label: country, value: country } : null;
  }, [profile]);

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('profile.editProfile')}
          onCloseClick={onCloseClick}
        />
        <OverflowSidebarContent>
          <SidebarInputsWrapper>
            <SidebarImageInputWrapper>
              <ImageInput
                label={t('profile.selectImage')}
                name={FIELDS.IMAGE}
                register={register}
                unregister={unregister}
                setValue={setValue}
                defaultValue={
                  memberProfile ? memberProfile?.logo : profile.logo
                }
                error={errors[FIELDS.IMAGE]}
              />
            </SidebarImageInputWrapper>
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.PROFILE_NAME}
              label={t('profile.profileName')}
              error={errors[FIELDS.PROFILE_NAME]}
            />
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.FIRST_NAME}
              label={t('profile.firstName')}
              error={errors[FIELDS.FIRST_NAME]}
            />
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.LAST_NAME}
              label={t('profile.lastName')}
              error={errors[FIELDS.LAST_NAME]}
            />
            <DatePickerWithYear
              label={t('profile.dateOfBirth')}
              name={FIELDS.DATE_OF_BIRTH}
              register={register}
              control={control}
            />
            <EmojiArea
              width="100%"
              label={t('profile.about')}
              name={FIELDS.ABOUT}
              register={register}
              error={errors[FIELDS.ABOUT]}
              control={control}
            />
            {capMap(
              (value, name) => (
                <TextInput
                  key={name}
                  register={register}
                  width="100%"
                  name={FIELDS[name]}
                  label={t(`profile.${value}`)}
                  error={errors[FIELDS[name]]}
                />
              ),
              PHONES,
            )}
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.STREET}
              label={t('profile.street')}
              error={errors[FIELDS.STREET]}
            />
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.REGION}
              label={t('profile.region')}
              error={errors[FIELDS.REGION]}
            />
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.POSTAL_CODE}
              label={t('profile.postalCode')}
              type="number"
              error={errors[FIELDS.POSTAL_CODE]}
            />
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.CITY}
              label={t('profile.city')}
              error={errors[FIELDS.CITY]}
            />
            <SelectInput
              label={t('profile.country')}
              name={FIELDS.COUNTRY}
              register={register}
              setValue={setValue}
              options={countries}
              error={errors[FIELDS.COUNTRY]}
              fullHeight
              width="100%"
              defaultValue={country}
            />
            <div>
              {capMap(
                (value, name) => (
                  <TextInput
                    key={name}
                    register={register}
                    width="100%"
                    name={FIELDS[name]}
                    label={t(`common.${value}`)}
                    error={errors[FIELDS[name]]}
                  />
                ),
                SOCIAL_NETWORKS,
              )}
            </div>
          </SidebarInputsWrapper>
        </OverflowSidebarContent>
        <SidebarFooter>
          <Button
            type="submit"
            variant={isLoading ? 'secondary' : null}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('common.saveChanges')
            )}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default EditProfile;
