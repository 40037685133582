import React, { FC } from 'react';

import { DefaultCommunityImage } from 'components';

import {
  StyledCommunityTitleBlock,
  StyledCommunityImage,
  StyledCommunityTitle,
} from './styled';

interface CommunityTitleProps {
  title: string;
  image: string | null;
  isMenuOpen: boolean;
}

const CommunityTitle: FC<CommunityTitleProps> = ({
  title,
  image,
  isMenuOpen,
}) => (
  <StyledCommunityTitleBlock isMenuOpen={isMenuOpen}>
    {image ? (
      <StyledCommunityImage src={image} alt="" />
    ) : (
      <DefaultCommunityImage title={title} />
    )}
    <StyledCommunityTitle>{title}</StyledCommunityTitle>
  </StyledCommunityTitleBlock>
);

export default CommunityTitle;
