import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledContentWrapper = styled.div`
  width: calc(100% - ${({ isMenuOpen }) => (isMenuOpen ? 360 : 60)}px);
  min-height: 100svh;
  background-color: white;
  margin-left: ${({ isMenuOpen }) => (isMenuOpen ? 360 : 60)}px;
  transition: all 0.3s;
  @media ${breakpoints.downMd} {
    width: 100%;
    margin-left: 0;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px 16px 24px;
  margin-left: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 55)}px;
  transition: margin-left 0.3s;
  @media ${breakpoints.downMd} {
    padding-right: 70px;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;
