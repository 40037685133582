import { USER_ROLE_TYPES } from './roles';

export const DEFAULT_TASKS = [
  {
    title: 'Who is driving?',
    icon: 'car',
    role: USER_ROLE_TYPES.USER,
    maxParticipants: 100,
  },
  {
    title: 'Who brings the drinks?',
    icon: 'eat',
    role: USER_ROLE_TYPES.USER,
    maxParticipants: 100,
  },
];
