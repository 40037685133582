import { call, put, select, takeLatest } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash/fp';
import { StreamChat } from 'stream-chat';
import i18n from 'i18next';

import {
  doLoginChat,
  doLogoutChat,
  doCreateChat,
  doAddMembers,
  doGetChats,
  doSetCurrentChat,
  doSetOnlineMembers,
} from 'store/actionCreators/chat';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';

import { getAuthUser, getFullAuthUser } from 'store/selectors/auth';
import { getCurrentMembers } from 'store/selectors/currentCommunity';
import { getClient, getCurrentChat } from 'store/selectors/chat';
import {
  getUserToken,
  createChatRequest,
  getCommunityChats,
} from 'services/chat';
import { toast } from 'utils/toastAbstraction';

function* login() {
  try {
    const { firstName, lastName, email, logo } = yield select(getAuthUser);
    const name =
      firstName && lastName ? `${firstName} ${lastName}` : email.split('@')[0];
    const { id } = yield select(getFullAuthUser);
    const image = logo
      ? logo
      : `https://getstream.io/random_png/?id=${id}&name=${firstName}+${lastName}`;
    yield put(doLoginChat.request(id));
    const chatToken = yield call(getUserToken, id);
    const appKey = yield get(['REACT_APP_CHAT_KEY'], process.env);
    const chatClient = new StreamChat(appKey);
    yield chatClient.connectUser(
      {
        id,
        name,
        image,
      },
      chatToken,
    );
    yield put(doLoginChat.success(chatClient));
  } catch (e) {
    yield put(doLoginChat.failure(e));
  }
}

function* logout() {
  try {
    yield put(doLogoutChat.success());
  } catch (e) {
    yield put(doLogoutChat.failure(e));
  }
}

function* createChat({ payload }: ReturnType<typeof doCreateChat>) {
  try {
    const client = yield select(getClient);
    const { communityId, chatName, members } = payload;
    const data = {
      communityId,
      chatName,
      client,
      members,
    };
    yield put(doCreateChat.request(data));
    const channel = yield call(createChatRequest, data);
    yield put(doCreateChat.success(channel));
    yield toast(i18n.t('chat.created'), {
      appearance: 'success',
      autoDismiss: true,
    });
    yield put(doGetChats.trigger(communityId));
    yield put(doSetCurrentChat.success(channel));
  } catch (e) {
    yield toast(
      i18n.t([`errors.${e.code}`, 'errors.errorToast'], { message: e.message }),
      {
        appearance: 'error',
        autoDismiss: true,
      },
    );
    yield put(doCreateChat.failure(e));
    yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* addMembers({ payload }: ReturnType<typeof doAddMembers>) {
  try {
    const members = Object.entries(payload)
      .map(([key, value]) => value && key)
      .filter(Boolean);
    const { channel } = yield select(getCurrentChat);
    const client = yield select(getClient);
    const chat = client.channel(channel.type, channel.id);
    yield chat.addMembers(members);
    yield put(doAddMembers.success());
    yield toast(i18n.t('chat.membersAdded'), {
      appearance: 'success',
      autoDismiss: true,
    });
  } catch (e) {
    yield put(doAddMembers.failure(e));
    yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* setMembersOnlineStatus() {
  try {
    const { userId } = yield select(getAuthUser);
    const client = yield select(getClient);
    const members = yield select(getCurrentMembers);
    if (!isEmpty(members) && !isEmpty(client)) {
      const memberIds = members.map(({ id }) => id);
      yield put(doSetOnlineMembers.request({ client, members }));
      const { users } = yield client.queryUsers({ id: { $in: memberIds } });
      const membersWithStatus = members.map(({ id, profile }) => {
        const match = users.find((chatUser) => chatUser.id === id);
        if (!isEmpty(match))
          return { ...profile, online: match.online || userId === id };
        return profile;
      });
      yield put(doSetOnlineMembers.success(membersWithStatus));
      return;
    }
    yield put(
      doSetOnlineMembers.success(members.map(({ profile }) => profile)),
    );
  } catch (e) {
    yield put(doSetOnlineMembers.failure(e));
  }
}

function* getGroupChats({ payload }: ReturnType<typeof doGetChats>) {
  try {
    const client = yield select(getClient);
    yield put(doGetChats.request());
    const chats = yield call(getCommunityChats, {
      communityId: payload,
      client,
    });
    yield put(doGetChats.success(chats));
    yield put(doSetOnlineMembers.trigger());
  } catch (e) {
    yield put(doGetChats.failure(e));
    yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* setCurrentChat({ payload }: ReturnType<typeof doSetCurrentChat>) {
  try {
    const client = yield select(getClient);
    const { chatId, userId, authId, groupId } = payload;
    yield put(doSetCurrentChat.request());
    const channel = () => {
      if (chatId) return client.channel('messaging', chatId);
      if (groupId) return client.channel('messaging', groupId);
      if (userId) {
        return client.channel('messaging', {
          members: [authId, userId],
        });
      }
      return null;
    };
    yield put(doSetCurrentChat.success(channel()));
  } catch (e) {
    yield put(doSetCurrentChat.failure(e));
    yield toast(
      i18n.t([`errors.${e.code}`, 'errors.errorToast'], { message: e.message }),
      {
        appearance: 'error',
        autoDismiss: true,
      },
    );
    yield put(doCheckIsAuthorized.trigger({}));
  }
}

export default function* () {
  yield takeLatest(doLogoutChat.TRIGGER, logout);
  yield takeLatest(doLoginChat.TRIGGER, login);
  yield takeLatest(doCreateChat.TRIGGER, createChat);
  yield takeLatest(doGetChats.TRIGGER, getGroupChats);
  yield takeLatest(doSetCurrentChat.TRIGGER, setCurrentChat);
  yield takeLatest(doAddMembers.TRIGGER, addMembers);
  yield takeLatest(doSetOnlineMembers.TRIGGER, setMembersOnlineStatus);
}
