import React, {
  useRef,
  useEffect,
  useMemo,
  memo,
  RefObject,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ScrollSync, MultiGrid, AutoSizer } from 'react-virtualized';
import useDimensions from 'react-use-dimensions';
import { useMedia } from 'react-use';

import {
  getCurrentUserInCommunity,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { EventGridItem, EventToRender, FooterData } from 'types';
import { breakpoints } from 'theme';
import useGridGroups from './hooks/useGridGroups';
import useFooterData from './hooks/useFooterData';
import useCommonData from './hooks/useCommonData';

import './grid.css';
import { ScrollBlock } from './styled';
import useGridDimensions from './hooks/useGridDimensions';
import useGridScroll from './hooks/useGridScroll';

interface GridSchedulerProps {
  listRef?: RefObject<HTMLElement>;
  tableId?: string;
  zoom?: number;
  statisticsTab?: boolean;
  topHeadHeight: number;
  inverted?: boolean;
  data: EventGridItem[];
  header: EventToRender[];
  footer?: FooterData;
  mainGridHeight?: number;
  divideColumns?: number[][];
  onHeaderClick?: (eventId: string, date: string) => void;
  onFooterClick?: (
    eventId: string,
    date: string,
    eventResponseId: string,
  ) => void;
}

const GridScheduler = memo<GridSchedulerProps>(
  ({
    listRef,
    tableId,
    zoom = 1,
    statisticsTab,
    topHeadHeight,
    inverted,
    data = [],
    header = [],
    footer = { label: '', data: [] },
    mainGridHeight,
    divideColumns,
    onHeaderClick = () => {},
    onFooterClick = () => {},
  }) => {
    const [isHeightIncrease, setIsHeightIncrease] = useState(false);
    const isDownMd = useMedia(breakpoints.downMd);
    const isMenuOpen = useSelector(getIsMenuOpen);
    const currentUser = useSelector(getCurrentUserInCommunity);
    const gridRef = useRef<MultiGrid>();
    const footerRef = useRef<MultiGrid>();
    const scrollBlockRef = useRef<HTMLDivElement>();
    const containerRef = listRef || scrollBlockRef;
    const [ref, { height }, gridContainer] = useDimensions();
    const [gridContainerHeight, setGridContainerHeight] =
      useState<number>(height);

    useEffect(() => {
      setGridContainerHeight(gridContainer?.getBoundingClientRect().height);
    }, [isMenuOpen, gridContainer, topHeadHeight]);

    useEffect(() => {
      const elements = document.getElementsByClassName(
        'ReactVirtualized__Grid',
      );
      const targetElement = elements[2] as HTMLElement;

      if (targetElement && !isHeightIncrease) {
        const elHeight = targetElement.offsetHeight;
        if (elHeight > 0) {
          targetElement.style.height = `${elHeight + 17}px`;
          setIsHeightIncrease(true);
        }
      }
    });

    useEffect(() => {
      if (gridRef.current && footerRef.current) {
        gridRef.current.recomputeGridSize();
        footerRef.current.recomputeGridSize();
      }
    }, [zoom, isDownMd, data, header, footer]);

    useEffect(() => {
      if (footerRef.current) {
        footerRef.current.recomputeGridSize();
      }
    }, [footer]);

    const stickyCellWidth = useMemo(() => {
      if (isDownMd) {
        return 180;
      } else if (statisticsTab) {
        return 320;
      } else {
        return 280;
      }
    }, [isDownMd, statisticsTab]);

    const { handleScrollGrid } = useGridScroll({
      containerRef,
      zoom,
      stickyCellWidth,
      isMenuOpen,
    });

    const { groups, onClick } = useGridGroups({
      data,
      footerLabel: footer?.label,
      tableId,
      onHeaderClick,
      onFooterClick,
    });

    const { filteredData, cellRendererCommon } = useCommonData({
      data,
      header,
      zoom,
      statisticsTab,
      currentUser,
      handleScrollGrid,
      divideColumns,
      groups,
    });

    const {
      filteredFooterData,
      footerHeight,
      footerRowHeight,
      footerCellRender,
    } = useFooterData({
      footer,
      zoom,
      statisticsTab,
      divideColumns,
      groups,
    });

    const { gridWidth, getColumnWidth, getRowHeight } = useGridDimensions({
      statisticsTab,
      zoom,
      header,
      stickyCellWidth,
      filteredData,
    });

    const screenWidth: number = useMemo(() => {
      const sidebarOffset = isMenuOpen ? 300 : 0;

      return window.innerWidth - (isDownMd ? 60 - sidebarOffset : 0);
    }, [isMenuOpen, isDownMd]);

    const wrapperHeight = mainGridHeight
      ? mainGridHeight + footerHeight
      : `calc(100svh - ${topHeadHeight}px)`;

    return (
      <div
        ref={ref}
        style={{
          height: wrapperHeight,
          overflow: 'hidden',
        }}
        className="grid-container-new"
        onClick={onClick}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore*/}
        <ScrollSync>
          {({ scrollLeft, onScroll }) => {
            const commonGridProps = {
              columnCount: header.length + 1,
              enableFixedColumnScroll: true,
              fixedColumnCount: 1,
              scrollLeft,
              onScroll,
            };
            const columWidth = header[0] ? getColumnWidth : screenWidth;
            const grids = [
              {
                id: 'mainGrid',
                ref: gridRef,
                height: mainGridHeight || gridContainerHeight - footerHeight,
                fixedRowCount: 1,
                columnWidth: columWidth,
                rowHeight: getRowHeight,
                enableFixedRowScroll: true,
                cellRenderer: cellRendererCommon,
                rowCount: filteredData.length,
                hideTopLeftGridScrollbar: true,
                hideTopRightGridScrollbar: true,
                hideBottomLeftGridScrollbar: true,
                hideBottomRightGridScrollbar: true,
                ...commonGridProps,
              },
              {
                id: 'footerGrid',
                ref: footerRef,
                height: footerHeight,
                rowCount: filteredFooterData.data.length + 1,
                columnWidth: columWidth,
                rowHeight: footerRowHeight,
                enableFixedRowScroll: false,
                cellRenderer: footerCellRender,
                ...commonGridProps,
              },
            ];

            if (inverted) {
              grids.reverse();
            }

            return (
              <>
                <ScrollBlock
                  ref={containerRef}
                  gridWidth={gridWidth}
                  onScroll={(event) => {
                    const target: EventTarget & { id?: string } = event.target;
                    onScroll(target as HTMLDivElement);
                  }}
                >
                  <div />
                </ScrollBlock>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/* @ts-ignore*/}
                <AutoSizer>
                  {({ width }) => (
                    <>
                      {grids.map((g, ind) => (
                        <div
                          key={g.id}
                          className={
                            ind === 1 && !inverted ? 'footer-wrapper' : ''
                          }
                        >
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                          {/* @ts-ignore*/}
                          <MultiGrid width={width} {...g} />
                        </div>
                      ))}
                    </>
                  )}
                </AutoSizer>
              </>
            );
          }}
        </ScrollSync>
      </div>
    );
  },
);

GridScheduler.displayName = 'GridScheduler';

export default GridScheduler;
