import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { getCurrentCommunity } from './currentCommunity';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;

export const getEventResponses = createSelector(
  getCurrentCommunity,
  (community) => community.eventResponses || [],
);

export const getEventResponsesForScheduler = createSelector(
  getCurrentCommunity,
  (community) => [
    ...community.eventResponses,
    {
      color: '#10CCC6',
      id: 'penalty',
      title: 'Penalty',
    },
    {
      color: '#10CCC6',
      id: 'attended',
      title: 'attended',
    },
  ],
);

export const getEventResponseLoading = createSelector(
  currentCommunitySelector,
  (community) =>
    community.loaders.createEventResponse ||
    community.loaders.editEventResponse ||
    community.loaders.deleteEventResponse,
);
