import styled from 'styled-components';

export const StyledText = styled.span`
  margin-left: 14px;
  font-size: 15px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  max-width: 22px;
`;
