import React, { FC, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { useDebounce, useLazyLoading } from 'hooks';
import {
  DatePickerControlled,
  DefaultCommunityImage,
  FilterTooltip,
  ResetButton,
  TextInput,
  Tooltip,
  Loader,
  SearchInput,
} from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import SelectInputControlledMulti from 'components/Inputs/SelectInputControlled/SelectInputControlledMultiV2';
import {
  getDateString,
  getTimeInterval,
  hasCommunityOrGroupAdminPermissions,
} from 'utils';
import { StyledResetButtonBlock } from '../../CommunityOverallScheduler/Schedule/styled';
import { FilterIcon } from 'static';
import {
  doGetMailEvents,
  doClearMailEvents,
} from 'store/actionCreators/currentCommunity';
import { getEventTypes } from 'store/selectors/eventTypes';
import { getEventResponses } from 'store/selectors/eventResponses';
import { getMyEventsData } from 'store/selectors/events';
import { getSettings } from 'store/selectors/auth';
import { breakpoints } from 'theme';
import { Community, MyAppointmentsEvent } from 'types';

import {
  EventsList,
  EventCard,
  CommunityTitle,
  GroupTitle,
  FilterBlock,
  FilterButton,
  FilterWrapper,
} from './styled';
import {
  EventSectionContainer,
  GridGhostWrapper,
  StyledSectionTitle,
} from '../styled';

interface FilterTooltipComponentProps {
  community: Community;
  filterValues: {
    communityId: string;
    eventTypeId: string;
    from: number;
    eventsFromGroups: string[];
    key?: string;
  };
  setFilterValues: (value: any) => void;
}

const FilterTooltipComponent: FC<FilterTooltipComponentProps> = ({
  community,
  filterValues,
  setFilterValues,
}) => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const [groups, setGroups] = useState<string[]>([
    'overall',
    ...(community.groups?.map((g) => g.id) || []),
  ]);
  const eventTypes = useSelector(getEventTypes) || [];

  const getGroupsValues = (idArr) =>
    idArr
      .map((id) => {
        if (id === 'overall') {
          return { value: 'overall', label: t('common.overallScheduler') };
        }

        const group = community.groups?.find((g) => g.id === id);

        if (!group) {
          return null;
        }

        return {
          value: group.id,
          label: group.name,
        };
      })
      .filter((i) => !!i?.value);

  const setGroupsFilter = (values) => {
    setFilterValues({
      ...filterValues,
      eventsFromGroups: values,
    });
  };

  const handleReset = () => {
    setFilterValues({
      communityId,
      from: moment().valueOf(),
      eventTypeId: 'all',
      eventsFromGroups: [
        'overall',
        ...(community.groups?.map((g) => g.id) || []),
      ],
    });
  };

  return (
    <FilterBlock>
      <div>
        <DatePickerControlled
          label={t('common.from')}
          date={filterValues.from}
          handleChange={(date) =>
            setFilterValues({
              ...filterValues,
              from: moment(date).valueOf(),
            })
          }
        />
      </div>
      <div>
        <SelectInputControlled
          options={[
            {
              value: 'all',
              label: t('common.all'),
            },
            ...eventTypes.map((type) => ({
              value: type.id,
              label: type.title,
            })),
          ]}
          width="100%"
          label={t('event.eventType')}
          value={[
            {
              value: 'all',
              label: t('common.all'),
            },
            ...eventTypes.map((type) => ({
              value: type.id,
              label: type.title,
            })),
          ].find(({ value }) => value === filterValues.eventTypeId)}
          onChange={(v) =>
            setFilterValues({
              ...filterValues,
              eventTypeId: v?.value,
            })
          }
        />
      </div>
      <SelectInputControlledMulti
        label={t('common.displayEventsFrom')}
        value={getGroupsValues(groups)}
        options={[
          { value: 'overall', label: t('common.overallScheduler') },
          ...(community.groups || []).map((group) => ({
            value: group.id,
            label: group.name,
          })),
        ]}
        width="100%"
        onChange={(values) => setGroups(values.map((i) => i.value))}
        onBlur={() => setGroupsFilter(groups)}
      />
      <StyledResetButtonBlock>
        <ResetButton onClick={handleReset} />
      </StyledResetButtonBlock>
    </FilterBlock>
  );
};

interface EmailModuleSelectEventProps {
  selectedEvent: MyAppointmentsEvent;
  community: Community;
  currentUserId: string;
  eventResponsesFilter: { [p: string]: any }[];
  isLoading: boolean;
  isMenuOpen: boolean;
  onEventResponsesChange: (value: { [p: string]: any }) => void;
  onEventClick: (event: MyAppointmentsEvent) => void;
  withSelectInput?: boolean;
  handleOnWrapperClick: (e: MouseEvent) => void;
}

const EmailModuleSelectEvent: FC<EmailModuleSelectEventProps> = ({
  selectedEvent,
  community,
  eventResponsesFilter,
  isLoading,
  isMenuOpen,
  onEventResponsesChange,
  onEventClick,
  withSelectInput = true,
  handleOnWrapperClick,
  currentUserId,
}) => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filterValues, setFilterValues] = useState<{
    communityId: string;
    eventTypeId: string;
    from: number;
    eventsFromGroups: string[];
    key?: string;
  }>({
    communityId,
    eventTypeId: 'all',
    from: moment().valueOf(),
    eventsFromGroups: [
      'overall',
      ...(community.groups?.map((g) => g.id) || []),
    ],
  });
  const [search, setSearch] = useState<string>(null);
  const eventTypes = useSelector(getEventTypes) || [];
  const eventResponses = useSelector(getEventResponses);
  const eventsData = useSelector(getMyEventsData);
  const settings = useSelector(getSettings);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const timeFormat = settings?.timeFormat;
  const events = eventsData?.events || [];
  const totalCount = eventsData?.totalCount;

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    if (events) {
      setFilterValues({
        ...filterValues,
        key: debouncedSearch,
      });
    }
  }, [debouncedSearch]);

  const getEvents = (filterValues) => {
    dispatch(doGetMailEvents(filterValues));
  };

  const clearEvents = (data) => {
    dispatch(doClearMailEvents(data));
  };

  const pageSize = 30;

  const { listRef } = useLazyLoading({
    isLoading,
    loader: getEvents,
    pageSize,
    listLength: events?.length || 0,
    hasNextPage: events && events.length < totalCount,
    cleaner: clearEvents,
    filterValues,
  });

  const getEventResponsesOptions = () =>
    [
      { value: 'awaiting', label: t('event.awaiting') },
      { value: 'penalty', label: t('event.penalty') },
      { value: 'attended', label: t('event.nomination') },
    ].concat(
      eventResponses.map((r) => ({
        value: r.id,
        label: r.title,
      })),
    );

  if (!(eventTypes || eventResponses)) {
    return <Loader />;
  }

  return (
    <EventSectionContainer>
      <StyledSectionTitle>
        {!hasCommunityOrGroupAdminPermissions(
          currentUserId ?? '',
          community,
        ) && <GridGhostWrapper onClick={(e: any) => handleOnWrapperClick(e)} />}
        {isDownSmPlus ? t('event.event') : t('common.selectEvent')}
        <FilterWrapper>
          {/* <TextInput
            width="100%"
            value={search}
            placeholder={t('common.search')}
            withSearchIcon={true}
            onChange={({ target }) => setSearch(target.value)}
          /> */}
          <SearchInput
            width="100%"
            value={search}
            placeholder={t('common.search')}
            // withSearchIcon={true}
            onChange={({ target }) => setSearch(target.value)}
          />
          <FilterButton>
            <FilterTooltip
              InnerComponent={
                <FilterTooltipComponent
                  filterValues={filterValues}
                  community={community}
                  setFilterValues={setFilterValues}
                />
              }
              position="bottom-center"
              tooltipStyle={{ maxWidth: '350px' }}
              isVisible={filtersVisible}
              closeTooltip={() => setFiltersVisible(false)}
            >
              <img
                src={FilterIcon}
                onClick={() => setFiltersVisible(!filtersVisible)}
                alt=""
              />
            </FilterTooltip>
          </FilterButton>
        </FilterWrapper>
      </StyledSectionTitle>
      <PerfectScrollbar
        style={{
          maxWidth: `calc(100vw - 60px - 340px - ${({ isMenuOpen }) =>
            isMenuOpen ? 300 : 0}px)`,
          height: '100%',
          marginTop: '18px',
          position: 'relative',
        }}
        options={{
          wheelSpeed: 0.4,
          wheelPropagation: false,
          minScrollbarLength: 4,
          useBothWheelAxes: true,
        }}
        containerRef={(ref: HTMLDivElement) => (listRef.current = ref)}
      >
        {!hasCommunityOrGroupAdminPermissions(
          currentUserId ?? '',
          community,
        ) && <GridGhostWrapper onClick={(e) => handleOnWrapperClick(e)} />}
        <EventsList isMenuOpen={isMenuOpen}>
          {events.map((event) => {
            const group = community.groups.find((g) => g.id === event.groupId);

            return (
              <div key={event.id}>
                <EventCard onClick={() => onEventClick(event)}>
                  <EventCard.Community>
                    <EventCard.Community.ImageBlock>
                      {community.logo ? (
                        <img src={community.logo} />
                      ) : (
                        <DefaultCommunityImage
                          title={community.name}
                          zoom={0.7}
                        />
                      )}
                    </EventCard.Community.ImageBlock>
                    <div>
                      <Tooltip
                        text={community.name}
                        isVisible
                        tooltipStyle={{
                          fontSize: 11,
                          height: '23px',
                        }}
                        visibleAfterWidth={89}
                        offset={10}
                        position="top-center"
                      >
                        <CommunityTitle>{community.name}</CommunityTitle>
                      </Tooltip>
                      {group && <GroupTitle>{group.name}</GroupTitle>}
                    </div>
                  </EventCard.Community>
                  <EventCard.Event
                    color={
                      eventTypes.find((i) => i.id === event.eventTypeId)?.color
                    }
                  >
                    <EventCard.Header>
                      <Tooltip
                        text={
                          eventTypes.find((i) => i.id === event.eventTypeId)
                            ?.title
                        }
                        isVisible
                        tooltipStyle={{
                          fontSize: 11,
                          height: '23px',
                        }}
                        visibleAfterWidth={100}
                        offset={10}
                        position="top-center"
                      >
                        <EventCard.EventType>
                          <span>
                            {
                              eventTypes.find((i) => i.id === event.eventTypeId)
                                ?.title
                            }
                          </span>
                        </EventCard.EventType>
                      </Tooltip>
                      <EventCard.SelectButton
                        selected={selectedEvent?.id === event.id}
                      />
                    </EventCard.Header>
                    <Tooltip
                      text={event.title}
                      isVisible
                      tooltipStyle={{
                        fontSize: 11,
                        height: '23px',
                      }}
                      visibleAfterHeight={39}
                      offset={28}
                      position="top-center"
                    >
                      <EventCard.EventTitle>
                        <span>{event.title}</span>
                      </EventCard.EventTitle>
                    </Tooltip>
                    <EventCard.Footer>
                      <div>
                        <EventCard.DateTime>
                          {`${t(
                            `dayOfWeek.day${moment(event.from).day()}`,
                          )}, ${getDateString(new Date(event.from))}`}
                        </EventCard.DateTime>
                        {!event.allDay && (
                          <EventCard.DateTime>
                            {getTimeInterval(
                              new Date(event.from),
                              new Date(event.to),
                              timeFormat,
                            )}
                          </EventCard.DateTime>
                        )}
                      </div>
                      {event.description && (
                        <Tooltip
                          text={event.description}
                          isVisible
                          tooltipStyle={{
                            fontSize: 11,
                            maxWidth: 140,
                            whiteSpace: 'pre-wrap',
                            overflow: 'hidden',
                            lineBreak: 'normal',
                          }}
                          position="top-center"
                        >
                          <div className="icon-description">
                            <span>i</span>
                          </div>
                        </Tooltip>
                      )}
                    </EventCard.Footer>
                  </EventCard.Event>
                </EventCard>
              </div>
            );
          })}
        </EventsList>
        <br />
      </PerfectScrollbar>
      {selectedEvent && withSelectInput && (
        <SelectInputControlledMulti
          width="calc(100% - 50px)"
          label={t('communitySettings.notifyByReplies')}
          value={eventResponsesFilter}
          options={getEventResponsesOptions()}
          closeMenuOnSelect={false}
          onChange={onEventResponsesChange}
        />
      )}
    </EventSectionContainer>
  );
};

export default EmailModuleSelectEvent;
