import React, { useEffect } from 'react';

const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  func: () => any,
  doClickOutSide = true,
) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        doClickOutSide &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        func();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [func, ref]);
};

export default useOutsideClick;
