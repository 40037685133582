import { call, put, takeEvery } from 'redux-saga/effects';

import {
  sendRequest,
  requestRequests,
  approveRequests,
  rejectRequests,
} from 'services/requests';
import {
  doRequestCurrentCommunity,
  doSendRequest,
  doRequestRequests,
  doApproveRequests,
  doRejectRequests,
} from 'store/actionCreators/currentCommunity';
import { removeInviteOnAcceptOrReject } from 'store/actionCreators/communities';

import { toast } from 'utils/toastAbstraction';
import i18n from 'i18next';

export function* onSendRequest({ payload }: ReturnType<typeof doSendRequest>) {
  try {
    const result = yield call(sendRequest, payload.communityId);
    yield put(doSendRequest.success(result));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doSendRequest.failure(response.data.message));
    yield call(payload.onFailure, response.data.message);
  }
}

export function* onRequestRequests({
  payload,
}: ReturnType<typeof doRequestRequests>) {
  try {
    const data = yield call(requestRequests, payload);
    yield put(doRequestRequests.success(data.data));
  } catch (e) {
    yield put(doRequestRequests.failure(e));
  }
}

export function* onApproveRequests({
  payload,
}: ReturnType<typeof doApproveRequests>) {
  try {
    yield call(approveRequests, payload.communityId, payload.data);
    yield put(
      doRequestCurrentCommunity.trigger({ communityId: payload.communityId }),
    );
    yield put(doApproveRequests.success());
    yield put(removeInviteOnAcceptOrReject.success(payload));
    yield call(payload.onSuccess);
  } catch (e) {
    yield put(doApproveRequests.failure(e));
    yield toast(i18n.t('communityInvitation.invitationFailure'), {
      appearance: 'error',
      autoDismiss: true,
    });
  }
}

export function* onRejectRequests({
  payload,
}: ReturnType<typeof doRejectRequests>) {
  try {
    yield call(rejectRequests, payload.communityId, payload.data);
    yield put(removeInviteOnAcceptOrReject.success(payload));
    yield put(doRejectRequests.success());
    yield put(
      doRequestCurrentCommunity.trigger({ communityId: payload.communityId }),
    );
  } catch (e) {
    yield put(doRejectRequests.failure(e));
  }
}

export default function* () {
  yield takeEvery(doSendRequest, onSendRequest);
  yield takeEvery(doRequestRequests, onRequestRequests);
  yield takeEvery(doApproveRequests, onApproveRequests);
  yield takeEvery(doRejectRequests, onRejectRequests);
}
