import { call, put, takeEvery } from 'redux-saga/effects';

import {
  doGetCommunityDefaultEventSetting,
  doDeleteCommunityDefaultEventSetting,
  doCreateCommunityDefaultEventSetting,
  doEditCommunityDefaultEventSetting,
} from 'store/actionCreators/currentCommunity';
import {
  requestCommunityDefaultEventSetting,
  requestDeleteCommunityDefaultEventSetting,
  requestCreateCommunityDefaultEventSetting,
  requestEditCommunityDefaultEventSetting,
} from 'services/events';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

function* onGetCommunityDefaultEventSettings({
  payload,
}: ReturnType<typeof doGetCommunityDefaultEventSetting>) {
  try {
    const data = yield call(requestCommunityDefaultEventSetting, payload);
    yield put(
      doGetCommunityDefaultEventSetting.success({ data, communityId: payload }),
    );
  } catch ({ response }) {
    yield put(doGetCommunityDefaultEventSetting.failure(response));
  }
}

function* onCreateCommunityDefaultEventSetting({
  payload,
}: ReturnType<typeof doCreateCommunityDefaultEventSetting>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      requestCreateCommunityDefaultEventSetting,
      payload.communityId,
      payload.data,
    );
    yield put(doCreateCommunityDefaultEventSetting.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doCreateCommunityDefaultEventSetting.failure(response));
  }
}

function* onEditCommunityDefaultEventSetting({
  payload,
}: ReturnType<typeof doEditCommunityDefaultEventSetting>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, id } = payload;
    const data = yield call(
      requestEditCommunityDefaultEventSetting,
      communityId,
      id,
      payload.data,
    );
    yield put(doEditCommunityDefaultEventSetting.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doEditCommunityDefaultEventSetting.failure(response));
  }
}

function* onDeleteCommunityDefaultEventSetting({
  payload,
}: ReturnType<typeof doDeleteCommunityDefaultEventSetting>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    yield call(
      requestDeleteCommunityDefaultEventSetting,
      payload.communityId,
      payload.id,
    );
    yield put(doDeleteCommunityDefaultEventSetting.success(payload));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doDeleteCommunityDefaultEventSetting.failure(response));
  }
}

export default function* () {
  yield takeEvery(
    doGetCommunityDefaultEventSetting,
    onGetCommunityDefaultEventSettings,
  );
  yield takeEvery(
    doDeleteCommunityDefaultEventSetting,
    onDeleteCommunityDefaultEventSetting,
  );
  yield takeEvery(
    doCreateCommunityDefaultEventSetting,
    onCreateCommunityDefaultEventSetting,
  );
  yield takeEvery(
    doEditCommunityDefaultEventSetting,
    onEditCommunityDefaultEventSetting,
  );
}
