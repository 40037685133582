import React, { FC } from 'react';
import { range } from 'lodash/fp';
import { Control, Controller, UseFormMethods } from 'react-hook-form';

import { CalendarIcon } from 'static';

import { StyledLabel } from '../../styled';
import {
  StyledDateTimeBlock,
  StyledPickerWrapper,
  StyledDatePicker,
  CustomHeader,
} from './styled';
import { SharedStyledDateIcon } from '../styled';

interface DatePickerWithYearProps
  extends Partial<Pick<UseFormMethods, 'register'>> {
  name: string;
  label: string;
  control: Control;
}

const DatePickerWithYear: FC<DatePickerWithYearProps> = ({
  name,
  register,
  label,
  control,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const years = range(2020, 1900, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <StyledDateTimeBlock>
      <StyledPickerWrapper>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
        <Controller
          control={control}
          name={name}
          render={({ value, onChange }) => (
            <StyledDatePicker
              id={name}
              valueName="selected"
              selected={value}
              dateFormat="dd.MM.yyyy"
              onChange={onChange}
              ref={register}
              name={name}
              renderCustomHeader={({ date, changeYear, changeMonth }) => (
                <CustomHeader>
                  <select
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    selectedindex={date.getYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </CustomHeader>
              )}
            />
          )}
        />
        <SharedStyledDateIcon src={CalendarIcon} alt="" />
      </StyledPickerWrapper>
    </StyledDateTimeBlock>
  );
};

export default DatePickerWithYear;
