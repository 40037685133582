import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints } from 'theme';

export const StyledLink = styled(Link)`
  text-decoration: none;
  button {
    padding: 18px 40px;
    justify-content: center;
    align-items: center;

    @media ${breakpoints.downSmPlus} {
      padding: 18px 12px 14px 18px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Cover = styled.img`
  width: 100%;
  height: 450px;
  margin-top: -50px;
  position: relative;
  object-fit: contain;
  z-index: 1;
  @media ${breakpoints.downMdPlus} {
    object-fit: cover;
  }
`;
export const CoverBG = styled.div`
  width: 100%;
  height: 450px;
  margin-top: -50px;
  position: absolute;
  top: 0px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  filter: blur(8px);
`;

export const Head = styled.div`
  height: 50px;
  width: 100%;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px 0 27px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.24);
  padding-left: ${({ isMenuOpen }) => (isMenuOpen ? 20 : 80)}px;
  transition: padding-left 0.3s;
  z-index: 3;
  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
  }

  @media ${breakpoints.downMd} {
    padding-right: 65px;

    h4 {
      max-width: 85%;
    }
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.color.white};
  line-height: 32px;
  font-size: 16px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 125px;
  display: flex;
  z-index: 12;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 17px 27px;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Logo = styled.img`
  width: 91px;
  height: 91px;
  border-radius: 50%;
  object-fit: cover;
`;

export const LogoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 91px;
  font-size: 24px;
  letter-spacing: 1.85px;
  line-height: 15px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.baliHai};
  user-select: none;
`;

export const MembersInfo = styled.div`
  display: flex;
`;
