import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'theme';

export const StyledPopupContainer = styled(motion.div)`
  width: ${({ isMobileView }) => (isMobileView ? '80%' : '730px')};
  border: 1px solid ${({ theme }) => theme.color.brightTurquoise};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 8px 8px 20px 0px #000000;
  border-radius: 20px;
  padding: ${({ isMobileView, compact }) =>
    isMobileView
      ? '20px 20px 30px 20px'
      : compact
      ? '50px 0px'
      : '30px 0px 50px 0px'};
  gap: ${({ compact }) => (compact ? '25px' : '50px')};

  @media (${breakpoints.downMd}) {
    width: 90%;
  }

  @media (${breakpoints.downSmPlus}) {
    height: auto;
    border-radius: 10px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  max-height: 600px;
  @media (${breakpoints.downSmPlus}) {
    gap: 25px;
  }
`;

export const HeaderIcon = styled.div`
  height: 150px;
  width: 150px;

  @media (${breakpoints.downSmPlus}) {
    height: 70px;
    width: 70px;
  }
`;

export const HeaderTitle = styled.h3`
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-align: center;
  /* align-self: stretch; */
  font-size: 30px;
  letter-spacing: -0.45px;
  font-weight: 700;
  font-style: normal;
  line-height: 164.682%;
  @media (${breakpoints.downSmPlus}) {
    line-height: 44px;
    font-size: 16px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding: ${({ isMobileView }) => (isMobileView ? '0px' : '0px 68px')};
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const StyledMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-direction: column;
  overflow-y: auto;
  @media (${breakpoints.downSmPlus}) {
    height: auto;
    padding: 0px 20px;
  }
`;

export const StyledMessage = styled.p`
  flex: 1 0 0;
  text-align: center;
  color: ${({ theme, textBold }) =>
    textBold ? theme.color.pickledBluewood : theme.color.BlueBayoux};
  font-size: 17px;
  font-style: normal;
  font-weight: ${({ textBold }) => (textBold ? 700 : 600)};
  line-height: 164.682%;
  letter-spacing: -0.255px;
  @media (${breakpoints.downSmPlus}) {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  width: 100%;
  @media ${breakpoints.downSmPlus} {
    padding: 0px 20px;
  }
`;

export const TwoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media (${breakpoints.downSmPlus}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
`;

export const StyledButton = styled.div`
  width: 400px;
  height: 44px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  color: ${({ theme }) => theme.color.white};
  border: none;
  display: flex;
  border-radius: 5px;
  padding: 15px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.1s ease-in;
  :hover {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.brightTurquoise};
    border: 1px solid ${({ theme }) => theme.color.brightTurquoise};
  }
  @media (${breakpoints.downSmPlus}) {
    width: 100%;
  }
`;

export const StyledButtonText = styled.div`
  width: 300px;
  flex-shrink: 0;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.255px;
  @media (${breakpoints.downSmPlus}) {
    color: ${({ theme }) => theme.color.white};
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 30px;
  top: 30px;
  stroke-width: 2px;
  stroke: ${({ theme }) => theme.color.pickledBluewood};
  cursor: pointer;
  svg path {
    fill: black;
    transition: fill 0.3s;
  }
  @media (${breakpoints.downSmPlus}) {
    width: 14px;
    height: 14px;
    right: 20px;
    top: 20px;
    svg {
      width: 14px;
      height: 14px;
      right: 20px;
      top: 20px;
    }
  }
`;

export const CloseModalIcon = styled.div`
  ::before,
  ::after {
    content: '';
    display: block;
    height: 2px;
    background: ${({ theme }) => theme.color.pickledBluewood};
    position: absolute;
  }
  ::before {
    width: 25px;
    top: 48%;
    left: 16%;
    transform: rotateZ(50deg);
  }
  ::after {
    width: 25px;
    top: 48%;
    left: 16%;
    transform: rotateZ(-50deg);
  }
`;
