import { CommunitySubscriptionInfo } from './community';
import { User } from './user';

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  MIGRATED = 'MIGRATED',
  TRIAL = 'TRIAL',
}

export enum SubscriptionFrequency {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
}

type SubscriptionCommon = {
  id: string;
  chatEnabled: boolean;
  currency: string;
  maxCommunities: number;
  maxGroups: number;
  maxMembers: number;
  maxStorage: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type SubscriptionPlan = SubscriptionCommon & {
  description: string | null;
  name: string;
  productName: string;
  monthlyPlanId: string;
  yearlyPlanId: string;
  monthlyValue: number;
  yearlyValue: number;
  productId: string;
  tax: number;
};

export type Subscription = SubscriptionCommon & {
  status: SubscriptionStatus;
  frequency: SubscriptionFrequency;
  planId: string;
  subscriptionId: string | null;
  startDate: string;
  endDate: string;
  subscriptionPlanId: string;
  userId: string;
};

export type UserSubscription = Subscription & {
  info: CommunitySubscriptionInfo;
  subscriptionPlan: SubscriptionPlan;
  user: User;
};
