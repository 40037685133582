import React, { useState, useEffect, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { noop } from 'lodash/fp';

import FileBox from './FileBox';
import { FileIcon, CloseIcon } from 'static';

import {
  StyledWrapper,
  HiddenInput,
  StyledPreviewBox,
  StyledPreviewItem,
  StyledErrorMessage,
} from './styled';
import { UseFormMethods } from 'react-hook-form';

const AVAILABLE_SIZE = 1024 * 50;

const validFileSize = (size) => Math.round(size / 1024) < AVAILABLE_SIZE;

interface FileInputProps
  extends Partial<Pick<UseFormMethods, 'register' | 'unregister'>> {
  name: string;
  width?: string;
  height?: string;
  text: string;
  tipText: string;
  isLoading: boolean;
  withPreview?: boolean;
  allowMultiple?: boolean;
  setValue: (name: string, value: string) => void;
  handleSubmit: () => void;
}

const FileInput: FC<FileInputProps> = ({
  name,
  width = '500px',
  height = '220px',
  text,
  tipText,
  isLoading,
  withPreview,
  allowMultiple,
  register = noop,
  unregister = noop,
  setValue = noop,
  handleSubmit = noop,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [register, unregister, name]);

  useEffect(() => {
    if (setValue) {
      setValue(name, data);
    }
  }, [data]);

  const previewVisible = useMemo(() => {
    const areFilesUploaded = Array.isArray(data) ? data.length > 0 : !!data;

    return withPreview && areFilesUploaded;
  }, [withPreview, data]);

  const convertFilesToArray = (files) => {
    return Array(files.length)
      .fill(0)
      .map((_, ind) => files[ind]);
  };

  const handleChange = async (e) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    const chosen = allowMultiple
      ? convertFilesToArray(e.target.files)
      : e.target.files[0];

    if (Array.isArray(chosen) ? chosen.length === 0 : !chosen) return false;

    if (
      Array.isArray(chosen)
        ? !validFileSize(
            [...data, ...chosen].map((f) => f.size).reduce((a, b) => a + b),
          )
        : !validFileSize(chosen.size)
    ) {
      setErrorMessage(t('documentation.tipMessage'));
      return false;
    }

    await setData(allowMultiple ? [...data, ...chosen] : chosen);
    handleSubmit();
    return null;
  };

  const handleItemDelete = (e, ind) => {
    e.stopPropagation();
    e.preventDefault();

    if (errorMessage) {
      setErrorMessage('');
    }

    if (Array.isArray(data)) {
      const updatedArr = [...data];

      updatedArr.splice(ind, 1);
      setData(updatedArr);
    } else {
      setData(null);
    }

    return handleSubmit();
  };

  return (
    <StyledWrapper width={width} height={height} isError={errorMessage}>
      {previewVisible ? (
        <PerfectScrollbar
          style={{
            maxWidth: '100%',
            height: '100%',
          }}
          options={{
            wheelSpeed: 0.4,
            wheelPropagation: false,
            minScrollbarLength: 4,
            useBothWheelAxes: true,
          }}
        >
          <StyledPreviewBox>
            {Array.isArray(data) ? (
              data.map((i, ind) => (
                <StyledPreviewItem key={i.name}>
                  <StyledPreviewItem.DeleteButtonWrapper
                    onClick={(e) => handleItemDelete(e, ind)}
                  >
                    <CloseIcon />
                  </StyledPreviewItem.DeleteButtonWrapper>
                  <FileIcon />
                  <StyledPreviewItem.FileName>
                    {i.name}
                  </StyledPreviewItem.FileName>
                </StyledPreviewItem>
              ))
            ) : (
              <StyledPreviewItem />
            )}
          </StyledPreviewBox>
        </PerfectScrollbar>
      ) : (
        <FileBox
          errorMessage={errorMessage}
          text={text}
          tipText={tipText}
          isLoading={isLoading}
        />
      )}
      <HiddenInput
        type="file"
        name={name}
        onChange={handleChange}
        disabled={isLoading}
        multiple={allowMultiple}
      />
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </StyledWrapper>
  );
};

export default FileInput;
