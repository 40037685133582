import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SocialContainer, Link } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { FIELDS } from './form';
import { useSignIn } from './hooks';

import {
  StyledButton,
  ActionContainer,
  ForgotPassword,
  Header,
  StyledForm,
  StyledGumbLogo,
} from '../styled';
import { useLocation, useSearchParams } from 'react-router-dom';

const SignInPage = () => {
  const { t } = useTranslation();
  const { register, errors, reset, onSubmit } = useSignIn();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('u')) {
      reset({
        [FIELDS.EMAIL]: searchParams.get('u'),
      });
    }
  }, [searchParams]);

  const searchParamsString = useMemo(() => {
    const queryStringArr = [];
    const pathNameArr = location.pathname.split('/');

    if (pathNameArr[1] === 'communities' || pathNameArr[1] === 'community') {
      queryStringArr.push(`invitationId=${pathNameArr[2]}`);
    }

    const invitationCode = searchParams.get('invitationCode');

    if (invitationCode) {
      queryStringArr.push(`invitationCode=${invitationCode}`);
    }

    return queryStringArr.join('&');
  }, [location]);

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledGumbLogo />
      <Header>{t('auth.welcome')}</Header>
      <TextInput
        error={errors[FIELDS.EMAIL]}
        register={register}
        name={FIELDS.EMAIL}
        label={t('auth.labels.email')}
      />
      <TextInput
        error={errors[FIELDS.PASSWORD]}
        register={register}
        name={FIELDS.PASSWORD}
        label={t('auth.labels.password')}
        type="password"
        withShowIcon
      />
      <StyledButton>{t('auth.signIn')}</StyledButton>
      <ActionContainer>
        <Link to={`/signup?${searchParamsString}`}>
          {t('auth.createAccount')}
        </Link>
        <ForgotPassword to="forgotpassword">
          {t('auth.forgotPassword')}
        </ForgotPassword>
      </ActionContainer>
      <SocialContainer />
    </StyledForm>
  );
};

export default SignInPage;
