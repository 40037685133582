import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { DocumentIcon, ReturnIcon } from 'static';

export const StyledLink = styled(Link)`
  padding: 0 32px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border-bottom: 0.5px solid ${({ theme }) => theme.color.porcelain};
`;

export const DocumentItemContainer = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
`;

export const StyledDocumentIcon = styled(DocumentIcon)`
  width: 18px;
  height: 18px;
`;

export const StyledArrowIcon = styled(ReturnIcon)`
  width: 12px;
  height: 12px;
  transform: rotate(180deg);

  polygon {
    fill: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const DocumentName = styled.span`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 15px;
  margin-left: 10px;
  text-decoration: none;
  font-weight: normal;
`;
