import { combineReducers } from 'redux';
import communities from './communities';
import auth from './auth';
import modal from './modal';
import chat from './chat';
import currentCommunity from './currentCommunity';
import currentGroup from './currentGroup';
import subscriptions from './subscriptions';
import events from './events';
import statistics from './statistics';

const rootReducer = combineReducers({
  communities,
  auth,
  modal,
  chat,
  currentCommunity,
  currentGroup,
  subscriptions,
  events,
  statistics,
});

export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;
