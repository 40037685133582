import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { getNotificationDate } from 'utils/dates';
import { ContentWrapper, CloseButton, ContentPlaceholder } from 'components';
import {
  getAuthUser,
  getNotifications,
  getNotificationsUnreadCount,
} from 'store/selectors/auth';
import { doMarkNotificationsAsRead } from 'store/actionCreators/profile';
import { UserNotificationItem } from 'types';
import { getCommunities } from 'store/selectors/communities';

import {
  StyledHeader,
  StyledContent,
  NotificationWrapper,
  NotificationDate,
  Message,
  MessageWrapper,
  StatusIndicator,
  StyledArrowIcon,
  NotificationImage,
} from './styled';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getAuthUser);
  const notifications = useSelector(getNotifications) || [];
  const notificationsUnreadCount = useSelector(getNotificationsUnreadCount);
  const communities = useSelector(getCommunities);

  useEffect(() => {
    if (notificationsUnreadCount) {
      dispatch(
        doMarkNotificationsAsRead({
          userId: user.userId,
          notifications: [],
        }),
      );
    }
  }, [notifications]);

  const goToProfile = () => {
    navigate('/profile');
  };

  const getCommunityLabel = useCallback(
    (notification: UserNotificationItem) => {
      const community = communities.find(
        (c) => c.id === notification.communityId,
      );

      if (community) {
        return `${community.name} • `;
      }

      return '';
    },
    [notifications, communities],
  );

  const formatNotificationLink = (link: string) => {
    if (link) {
      return link.replace('communities', 'community');
    }

    return null;
  };

  return (
    <ContentWrapper>
      <StyledHeader>
        {t('common.notifications')}
        <CloseButton hidden={false} onClick={goToProfile} />
      </StyledHeader>
      <StyledContent>
        {isEmpty(notifications) ? (
          <ContentPlaceholder
            placeholder={t('profileSettings.notificationsPlaceholder')}
          />
        ) : (
          notifications.map((notification) => (
            <NotificationWrapper
              key={notification.id}
              href={formatNotificationLink(notification.link)}
            >
              <MessageWrapper>
                <StatusIndicator isRead={notification.isRead} />
                <NotificationImage
                  src={
                    notification.image ||
                    'https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/logo.svg'
                  }
                />
                <Message>
                  {getCommunityLabel(notification) && (
                    <NotificationDate>
                      {`${getCommunityLabel(notification)}${getNotificationDate(
                        t,
                        notification.createdAt,
                      )}`}
                    </NotificationDate>
                  )}
                  <div>{notification.message}</div>
                </Message>
              </MessageWrapper>
              {notification.link && <StyledArrowIcon />}
            </NotificationWrapper>
          ))
        )}
      </StyledContent>
    </ContentWrapper>
  );
};

export default NotificationsPage;
