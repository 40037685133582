import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components';
import { CommunityRoles, GroupRoles } from 'types';

import { Container, BadgeContent } from './styled';

interface BadgeProps {
  styleConfig: { [key: string]: string | number };
  isShowTooltip: boolean;
  tooltipContent: CommunityRoles | GroupRoles;
  text?: string;
  isStretch?: boolean;
  isBlinking?: boolean;
  textColor?: string;
  fontSize?: number;
  isShowBadge: boolean;
  userRole: CommunityRoles | GroupRoles;
  permissionType: 'Group' | 'Community';
  zoom?: number;
}

const Badge: FC<BadgeProps> = ({
  styleConfig,
  isShowTooltip,
  tooltipContent,
  text,
  isStretch,
  isBlinking,
  textColor,
  fontSize,
  isShowBadge,
  userRole,
  permissionType = '',
  zoom = 1,
}) => {
  const { t } = useTranslation();
  const [role, setRole] = useState<string>(userRole);

  useEffect(() => {
    if (userRole === GroupRoles.GROUP_LEAD) {
      setRole(
        !isStretch
          ? t('communitySettings.group_lead').charAt(0).toUpperCase()
          : t('communitySettings.group_lead'),
      );
    } else if (userRole === CommunityRoles.ADMIN) {
      setRole(
        !isStretch
          ? t('communitySettings.admin').charAt(0).toUpperCase()
          : t('communitySettings.admin'),
      );
    } else if (userRole === CommunityRoles.OWNER) {
      setRole(
        !isStretch
          ? t('communitySettings.owner').charAt(0).toUpperCase()
          : t('communitySettings.owner'),
      );
    }
  }, [userRole]);

  const renameTooltipContent = (tooltipContent) => {
    switch (tooltipContent) {
      case CommunityRoles.ADMIN:
        return permissionType === 'Group'
          ? t('communitySettings.group_admin')
          : t('communitySettings.community_admin');
      case CommunityRoles.OWNER:
        return t('communitySettings.owner');
      case GroupRoles.GROUP_LEAD:
        return t('communitySettings.group_lead');
      default:
        return t('communitySettings.member');
    }
  };

  return (
    <>
      {isShowBadge ? (
        <Tooltip
          isVisible={isShowTooltip}
          text={tooltipContent ? renameTooltipContent(tooltipContent) : text}
          zoom={zoom}
        >
          <Container
            isStretch={isStretch}
            isBlinking={isBlinking}
            userRole={userRole}
            {...styleConfig}
            zoom={zoom}
          >
            <BadgeContent textColor={textColor} fontSize={fontSize} zoom={zoom}>
              {text ? text : role}
            </BadgeContent>
          </Container>
        </Tooltip>
      ) : null}
    </>
  );
};

export default Badge;
