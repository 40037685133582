import styled from 'styled-components';

import { breakpoints } from 'theme';

export const Container = styled.div`
  width: 100%;

  @media ${breakpoints.downMd} {
    width: 1000px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  padding: 0 25px;
`;

export const HeaderColumn = styled.div`
  width: ${({ width }) => width || '44px'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 12px;
`;

export const PlusButtonColumn = styled(HeaderColumn)`
  justify-content: flex-end;
`;
