import React from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Lottie from 'react-lottie';

import PopupWrapper from '../Wrapper';
import { Button, CloseButton } from '../../index';
import resetPassword from 'static/animations/reset-password.json';

import { StyledPopup, StyledHeader, StyledBody, StyledFooter } from './styled';

const ResetPasswordPopup = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <PopupWrapper isOpen={isOpen}>
      <StyledPopup>
        <CloseButton onClick={onClose} />
        <PerfectScrollbar
          options={{
            wheelSpeed: 0.1,
            wheelPropagation: false,
            minScrollbarLength: 4,
          }}
        >
          <StyledHeader>
            <Lottie
              options={{
                animationData: resetPassword,
                loop: false,
                autoplay: true,
              }}
              width={150}
              height={150}
            />
          </StyledHeader>
          <StyledBody>
            <h3>{t('auth.resetPasswordModalTitle')}</h3>
            <p>{t('auth.resetPasswordModalText')}</p>
          </StyledBody>
          <StyledFooter>
            <Button onClick={onClose}>{t('common.ok')}</Button>
          </StyledFooter>
        </PerfectScrollbar>
      </StyledPopup>
    </PopupWrapper>
  );
};

export default ResetPasswordPopup;
