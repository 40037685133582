import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';
import { arrayOf, shape, bool, string, func, object } from 'prop-types';

import { doRequestCommunityMatrix } from 'store/actionCreators/communities';
import { CheckBox, SearchInput, Loader } from 'components';
import { LIMIT_MEMBERS, NOT_FOUND } from 'constants/errors';
import { getProfileName } from 'utils';

import { MatrixList } from './styled';
const defaultState = null;
const CommunitiesList = ({
  authUser,
  communities,
  deleteCommunityUser,
  addCommunityUsers,
  loadingMatrix,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [dataUsers, setDataUsers] = useState([]);
  const [dataCommunities, setDataCommunities] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filterUsersData, setFilterUsersData] = useState(null);
  const [selectedData, setSelectedData] = useState(defaultState);

  useEffect(() => {
    dispatch(doRequestCommunityMatrix());
  }, []);

  useEffect(() => {
    if (communities.length) {
      const usersData = [];
      communities.map((item) => usersData.push(...item.users));

      setDataUsers(_.uniqBy(usersData, 'id'));

      setDataCommunities(
        communities.map((item) => {
          return {
            ...item,
            userIds: item.users.map((item) => item.id),
          };
        }),
      );
    }
  }, [communities]);

  useEffect(() => {
    if (searchInputValue) {
      const filterUser = dataUsers.filter((user) =>
        String(`${user.profile.firstName} ${user.profile.lastName}`)
          .toLowerCase()
          .includes(searchInputValue.toLowerCase()),
      );

      setFilterUsersData(filterUser);
    } else {
      setFilterUsersData(null);
    }
  }, [searchInputValue]);

  const successMessage = () =>
    addToast(t('community.leaveSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });

  const failureMessage = (e) => {
    setSelectedData(defaultState);
    if (e?.data?.error === LIMIT_MEMBERS) {
      addToast(t('communitySettings.disableAddMemberTooltip'), {
        appearance: 'error',
        autoDismiss: false,
      });

      return;
    }
    setDataCommunities([
      ...dataCommunities.map((item) =>
        item.id === selectedData?.communityId
          ? {
              ...item,
              userIds: [...item.userIds].filter(
                (ids) => ids !== selectedData?.userId,
              ),
            }
          : {
              ...item,
            },
      ),
    ]);
    if (e?.data?.error === NOT_FOUND) {
      addToast(t('subscriptions.subscriptionExpired'), {
        appearance: 'error',
        autoDismiss: false,
      });
    } else {
      addToast(t('errors.text500'), {
        appearance: 'error',
        autoDismiss: false,
      });
    }
  };

  const onChangeCheckBox = (e, id, data) => {
    if (e.target.checked) {
      setDataCommunities([
        ...dataCommunities.map((item) =>
          item.id === data.id
            ? {
                ...item,
                userIds: [...item.userIds, id],
              }
            : {
                ...item,
              },
        ),
      ]);
      setSelectedData({ communityId: data.id, userId: id });
      addCommunityUsers({
        communityId: data.id,
        onSuccess: successMessage,
        onFailure: failureMessage,
        userIds: [id],
        isCurrentUser: true,
      });
    } else {
      setDataCommunities([
        ...dataCommunities
          .map((item) =>
            item.id === data.id
              ? {
                  ...item,
                  userIds: [...item.userIds.filter((item) => item !== id)],
                }
              : {
                  ...item,
                },
          )
          .filter(
            (item) =>
              item.ownerId === authUser.id ||
              item.userIds.includes(authUser.id),
          ),
      ]);
      deleteCommunityUser({
        communityId: data.id,
        userIds: [id],
        isCurrentUser: [id].includes(authUser.id),
      });
    }
  };

  const users = useMemo(() => {
    if (filterUsersData) {
      return filterUsersData;
    }

    return dataUsers;
  }, [dataCommunities, dataUsers, filterUsersData]);

  const isCheckboxDisabled = (user, com) => {
    return user.id === com.ownerId;
  };

  return (
    <MatrixList>
      {!loadingMatrix && (
        <>
          <MatrixList.Left>
            <MatrixList.SearchContainer>
              <SearchInput
                width="100%"
                onChange={(e) => setSearchInputValue(e.target.value)}
                value={searchInputValue}
              />
            </MatrixList.SearchContainer>

            <MatrixList.NameContainer>
              {users.map((item) => (
                <MatrixList.NameContainer.Item key={item.id}>
                  <MatrixList.NameContainer.Item.Name>
                    {getProfileName(item.profile)}
                  </MatrixList.NameContainer.Item.Name>
                </MatrixList.NameContainer.Item>
              ))}
            </MatrixList.NameContainer>
          </MatrixList.Left>

          <MatrixList.Right height={users.length * 35}>
            <MatrixList.Right.Title>
              {dataCommunities.map((item) => (
                <MatrixList.Right.Title.Container key={item.id}>
                  <MatrixList.Right.Title.Name>
                    <MatrixList.Right.Title.NameBox>
                      {item.name}
                    </MatrixList.Right.Title.NameBox>
                  </MatrixList.Right.Title.Name>
                </MatrixList.Right.Title.Container>
              ))}
            </MatrixList.Right.Title>
            {users.map((user) => (
              <MatrixList.Right.Row key={user.id}>
                {dataCommunities.map((item) => (
                  <MatrixList.Right.Cell key={item.id}>
                    <CheckBox
                      isSelected={item.userIds?.includes(user.id)}
                      onChange={(e) => onChangeCheckBox(e, user.id, item)}
                      disabled={isCheckboxDisabled(user, item)}
                    />
                  </MatrixList.Right.Cell>
                ))}
              </MatrixList.Right.Row>
            ))}
          </MatrixList.Right>
        </>
      )}
      {loadingMatrix && <Loader />}
    </MatrixList>
  );
};

CommunitiesList.propTypes = {
  data: arrayOf(shape({})).isRequired,
  deleteCommunityUser: func.isRequired,
  withDelete: bool,
  withLeave: bool,
  loadingMatrix: bool,
  userId: string,
  communities: object,
  addCommunityUsers: func.isRequired,
};

CommunitiesList.defaultProps = {
  withDelete: false,
  withLeave: false,
  loader: false,
  userId: null,
};

export default CommunitiesList;
