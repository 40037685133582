import React from 'react';

import { CellsTypes } from 'constants/index';
import { DragIcon } from 'components';

export const getAdminsConfig = (t) => ({
  title: t('common.admin'),
  withDnD: true,
  role: 'admin',
  headers: [
    {
      id: 'dragndrop',
      label: <DragIcon />,
      width: '5%',
      type: CellsTypes.DRAGNDROP,
    },
    {
      id: 'name',
      width: '28%',
      type: CellsTypes.AVATAR_NAME,
      headerType: 'title',
    },
    {
      id: 'edit',
      label: '',
      width: '7%',
      type: CellsTypes.EDIT,
    },
    {
      id: 'email',
      label: t('common.email'),
      width: '53%',
      key: 'email',
      type: CellsTypes.TEXT,
    },
    {
      id: 'delete',
      label: '',
      width: '7%',
      type: CellsTypes.DELETE,
    },
  ],
});

export const getGroupLeadersConfig = (t) => ({
  title: t('common.group_lead'),
  withDnD: true,
  role: 'group_lead',
  headers: [
    {
      id: 'dragndrop',
      label: <DragIcon />,
      width: '5%',
      type: CellsTypes.DRAGNDROP,
    },
    {
      id: 'name',
      width: '28%',
      type: CellsTypes.AVATAR_NAME,
      headerType: 'title',
    },
    {
      id: 'edit',
      label: '',
      width: '7%',
      type: CellsTypes.EDIT,
    },
    {
      id: 'email',
      label: t('common.email'),
      width: '53%',
      key: 'email',
      type: CellsTypes.TEXT,
    },
    {
      id: 'delete',
      label: '',
      width: '7%',
      type: CellsTypes.DELETE,
    },
  ],
});

export const getMembersConfig = (t) => ({
  title: t('common.member'),
  withDnD: true,
  role: 'member',
  headers: [
    {
      id: 'dragndrop',
      label: <DragIcon />,
      width: '5%',
      type: CellsTypes.DRAGNDROP,
    },
    {
      id: 'name',
      width: '28%',
      type: CellsTypes.AVATAR_NAME,
      headerType: 'title',
    },
    {
      id: 'edit',
      label: '',
      width: '7%',
      type: CellsTypes.EDIT,
    },
    {
      id: 'email',
      label: t('common.email'),
      width: '53%',
      key: 'email',
      type: CellsTypes.TEXT,
    },
    {
      id: 'delete',
      label: '',
      width: '7%',
      type: CellsTypes.DELETE,
    },
  ],
});
