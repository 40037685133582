import { useState, useCallback, useEffect } from 'react';
import { reduce, assoc } from 'lodash/fp';

import {
  isTotalShown,
  toggleTotal,
  isSubTableShown,
  toggleSubTable,
} from '../utils';
import { EventGridItem } from 'types';

const useGridGroups = ({
  data,
  footerLabel,
  tableId,
  onHeaderClick,
  onFooterClick,
}: {
  data: EventGridItem[];
  footerLabel: string;
  tableId: string;
  onHeaderClick?: (eventId: string, date: string) => void;
  onFooterClick?: (
    eventId: string,
    date: string,
    eventResponseId: string,
  ) => void;
}): {
  groups: { [key: string]: EventGridItem & { isShow?: boolean } };
  onClick: (event: any) => void;
} => {
  const initialGroups = reduce(
    (acc, item) => {
      if (item.type === 'group') {
        acc[item.id] = {
          ...item,
          isShow: isSubTableShown(tableId, item.id),
        };
      }
      return acc;
    },
    {},
    data,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialGroups.total = {
    label: footerLabel,
    type: 'group',
    id: 'total',
    isShow: isTotalShown(tableId),
  };

  const [groups, setGroups] = useState(initialGroups);

  const toggleGroup = useCallback(
    (id: string) => {
      if (groups[id]) {
        if (id === 'total') {
          toggleTotal(tableId);
        } else {
          toggleSubTable(tableId, id);
        }
        setGroups(assoc([id, 'isShow'], !groups[id].isShow, groups));
      }
    },
    [groups, tableId, setGroups],
  );

  const onClick = useCallback(
    (event) => {
      const elem = event.target.closest('[data-clickable="true"]');

      if (elem) {
        const { dataset } = elem;
        if (dataset.type === 'group') {
          toggleGroup(dataset.id);
        }
        if (dataset.type === 'header') {
          onHeaderClick(dataset.id, dataset.date);
        }
        if (dataset.type === 'footer') {
          onFooterClick(dataset.id, dataset.date, dataset['responseId']);
        }
      }
    },
    [toggleGroup, onHeaderClick, onFooterClick],
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === '`') {
        event.preventDefault();
        toggleGroup('total');
      }
    };

    const handleFocus = () => {
      document.addEventListener('keydown', handleKeyDown);
    };

    const handleBlur = () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [toggleGroup]);

  return {
    groups,
    onClick,
  };
};

export default useGridGroups;
