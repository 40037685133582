import React, { FC, forwardRef, Ref } from 'react';
import { Container } from './styled';

interface SidebarProps {
  placement?: 'right' | 'left';
  children: JSX.Element | JSX.Element[];
  width?: string | number;
}

const Sidebar: FC<SidebarProps & { ref?: Ref<HTMLDivElement> }> = forwardRef<
  HTMLDivElement,
  SidebarProps
>((props, ref) => {
  const { placement = 'right', children, width } = props;

  return (
    <Container width={width} ref={ref} placement={placement}>
      {children}
    </Container>
  );
});

Sidebar.displayName = 'Sidebar';

export default Sidebar;
