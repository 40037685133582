import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ROUTES } from 'configs';
import {
  MainMenuItemWrapper,
  MainMenuItem,
  DefaultCommunityImage,
  PendingSettingsInvite,
  Tooltip,
} from 'components';
import { POPUP_MESSAGE } from 'constants/popups';
import { openModal, closeModal } from 'store/actionCreators/modal';
import { CommunityRoles } from 'types';
import { useParams } from 'react-router-dom';

interface MainMenuCommunityItemProps {
  id: string;
  index?: number;
  isShowTooltip: boolean;
  title: string;
  image: string | null;
  isDisabled: boolean;
  invitesCount: number;
  userRole: CommunityRoles;
}

const MainMenuCommunityItem: FC<MainMenuCommunityItemProps> = ({
  isShowTooltip,
  title,
  image,
  id,
  isDisabled,
  invitesCount,
  userRole,
  index,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const isSelected = communityId === id;
  const marginTop = index === 0 && invitesCount > 0;
  return (
    <Tooltip isVisible={isShowTooltip} text={title} position="right-center">
      <div
        style={marginTop ? { marginTop: '8px' } : {}}
        onClick={
          isDisabled
            ? () => {
                dispatch(
                  openModal({
                    type: POPUP_MESSAGE,
                    data: {
                      popupHeaderText: t('community.userBlockedTitle'),
                      popupMessageText: t('community.userBlockedText'),
                      popupButtonText: 'Ok',
                      withoutCloseButton: true,
                      handleConfirm: () => {
                        dispatch(closeModal());
                      },
                    },
                  }),
                );
              }
            : () => {}
        }
      >
        <MainMenuItemWrapper
          to={`${ROUTES.COMMUNITY}/${id}/overall`}
          isDisabled={isDisabled}
          isSelected={isSelected}
        >
          {invitesCount > 0 &&
            (userRole === CommunityRoles.ADMIN ||
              userRole === CommunityRoles.OWNER) && (
              <PendingSettingsInvite
                invitesCount={invitesCount}
                addCss={{ top: -2, right: 0 }}
              />
            )}
          {image ? (
            <MainMenuItem image={image} />
          ) : (
            <DefaultCommunityImage title={title} />
          )}
        </MainMenuItemWrapper>
      </div>
    </Tooltip>
  );
};

export default MainMenuCommunityItem;
