import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';

import StatisticsTotal from './StatisticsTotal';
import StatisticsFilter from 'components/GroupStatistics/Filter';
import AppointmentSchedule from './AppointmentSchedule';
import Loader from 'components/Loader';
import { STATISTICS_TYPES } from 'configs';
import {
  getOverallScheduler,
  getOverallStatistic,
  getOverallStatisticLoading,
} from 'store/selectors/currentCommunity';
import { doGetOverallScheduleStatistics } from 'store/actionCreators/currentCommunity';
import {
  doClearMemberFiguresStatisticsTotal,
  doClearStatistics,
  doGetAppointmentScheduleStatistics,
  doGetAppointmentScheduleStatisticsTotal,
  doGetMemberFiguresStatistics,
  doGetMemberFiguresStatisticsTotal,
} from 'store/actionCreators/statistics';
import AppointmentScheduleFilters from './Filters/AppointmentScheduleFilters';

import { AppointmentSchedulerWrapper, Wrapper } from './styled';
import MemberFigures from './MemberFigures';
import { useFilters } from './hooks';

interface OverallScheduleStatisticsProps {
  zoom: number;
}

const OverallScheduleStatistics: FC<OverallScheduleStatisticsProps> = ({
  zoom,
}) => {
  const { communityId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [type, setType] = useState(
    location.hash === '#memberPresence'
      ? STATISTICS_TYPES.MEMBER_FIGURES
      : STATISTICS_TYPES.APPOINTMENT_SCHEDULE,
  );
  const [gridData, gridDataHandler] = useState([]);
  const overallScheduler = useSelector(getOverallScheduler);
  const requestStatisticLoading = useSelector(getOverallStatisticLoading);
  const statistic = useSelector(getOverallStatistic);

  const { filters, setFilters } = useFilters();

  const displayLoader = useMemo(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      return false;
    }

    if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      return false;
    }

    return !statistic || requestStatisticLoading;
  }, [type, statistic, requestStatisticLoading]);

  useEffect(() => {
    return () => {
      dispatch(doClearStatistics());
    };
  }, []);

  useEffect(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(
        doGetAppointmentScheduleStatistics({
          communityId,
          from,
          to,
          groupIds: filters.groups?.map((i) => i.value) || [],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          page: filters.page,
          visibleColumns: filters.visibleColumns,
          limit: filters.limit,
        }),
      );
    } else if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(
        doGetMemberFiguresStatistics({
          communityId,
          from,
          to,
          groupIds: filters.groups?.map((i) => i.value) || [],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          memberIds: filters.members?.map((i) => i.value) || [],
          page: filters.page,
          limit: filters.limit,
        }),
      );
    } else {
      dispatch(
        doGetOverallScheduleStatistics({
          id: communityId,
          fromDate: filters.from,
          toDate: filters.to,
          eventTypeId: filters.eventType?.value,
        }),
      );
    }
  }, [filters, communityId, type]);

  useEffect(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(
        doGetAppointmentScheduleStatisticsTotal({
          communityId,
          from,
          to,
          groupIds: filters.groups?.map((i) => i.value) || [],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          visibleColumns: filters.visibleColumns,
        }),
      );
    } else if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      const from =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.from)
          : (filters.dateRange.value[0] as Moment);
      const to =
        filters.dateRange.value === 'dateRange'
          ? moment(filters.to)
          : (filters.dateRange.value[1] as Moment);

      dispatch(doClearMemberFiguresStatisticsTotal());

      dispatch(
        doGetMemberFiguresStatisticsTotal({
          communityId,
          from,
          to,
          groupIds: filters.groups?.map((i) => i.value) || [],
          eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
          memberIds: filters.members?.map((i) => i.value) || [],
          dispatch,
        }),
      );
    }
  }, [
    filters.dateRange,
    filters.from,
    filters.to,
    filters.groups,
    filters.eventTypes,
    filters.members,
    filters.eventType,
    filters.visibleColumns,
    communityId,
    type,
  ]);

  if (displayLoader) {
    return <Loader />;
  }

  return (
    <Wrapper>
      {type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE ? (
        <AppointmentSchedulerWrapper>
          <AppointmentScheduleFilters
            type={type}
            filters={filters}
            setType={setType}
            setFilters={setFilters}
          />
          <AppointmentSchedule
            zoom={zoom}
            filters={filters}
            gridDataHandler={gridDataHandler}
          />
        </AppointmentSchedulerWrapper>
      ) : type === STATISTICS_TYPES.MEMBER_FIGURES ? (
        <AppointmentSchedulerWrapper>
          <AppointmentScheduleFilters
            type={type}
            filters={filters}
            setType={setType}
            setFilters={setFilters}
          />
          <MemberFigures
            zoom={zoom}
            gridDataHandler={gridDataHandler}
            selectedMembers={filters.members?.map((i) => i.label)}
          />
        </AppointmentSchedulerWrapper>
      ) : (
        <AppointmentSchedulerWrapper>
          <StatisticsFilter
            type={type}
            gridData={gridData}
            filters={filters}
            setType={setType}
            setFilters={setFilters}
          />
          <StatisticsTotal
            type={type}
            statistic={statistic}
            overallScheduler={overallScheduler}
            zoom={zoom}
            gridDataHandler={gridDataHandler}
          />
        </AppointmentSchedulerWrapper>
      )}
    </Wrapper>
  );
};

export default OverallScheduleStatistics;
