import { createRoutine } from 'redux-saga-routines';

import { EditCommunityResponseFormData } from 'sidebars/EditCommunityResponse/form';

export const doGetCommunityResponses = createRoutine(
  'GET_COMMUNITY_RESPONSES',
  {
    trigger: (payload: string) => payload,
  },
);

export const doCreateCommunityResponse = createRoutine(
  'CREATE_COMMUNITY_RESPONSE',
  {
    trigger: (payload: {
      communityId: string;
      data: EditCommunityResponseFormData;
      onSuccess: () => void;
    }) => payload,
  },
);

export const doEditCommunityResponse = createRoutine(
  'EDIT_COMMUNITY_RESPONSE',
  {
    trigger: (payload: {
      communityId: string;
      id: string;
      data: EditCommunityResponseFormData;
      onSuccess: () => void;
    }) => payload,
  },
);

export const doDeleteCommunityResponse = createRoutine(
  'DELETE_COMMUNITY_RESPONSE',
  {
    trigger: (payload: {
      communityId: string;
      id: string;
      onSuccess: () => void;
    }) => payload,
  },
);
