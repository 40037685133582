import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useParams } from 'react-router-dom';
import {
  NoResponse,
  GroupIconGreen,
  CommentWhiteIcon,
  AttendedIcon,
  YellowStar,
} from 'static';
import { Tooltip, UserAvatar } from 'components';
import { CommunityUser, Event, EventGridItem, GroupUser } from 'types';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getEventResponses } from 'store/selectors/eventResponses';
import { getCurrentGroup } from 'store/selectors/currentGroup';
import { getProfileName } from 'utils';
import { breakpoints } from 'theme';

import {
  ColorDotTabWrapper,
  InviteesImg,
  ReplyCircleWrapper,
  StyledCollapsedReplies,
  StyledCollapsedRepliesListItem,
  StyledCollapsedRepliesRow,
  StyledCollapsedRepliesRowBody,
  StyledCollapsedRepliesRowTitle,
  StyledGroupName,
  StyledGroupRow,
  StyledGroupRowHead,
  StyledGroupsBlock,
  StyledGroupsList,
  StyledNoRepliesWrapper,
  StyledRepliesBlock,
  StyledReplyCircle,
  StyledReplyCircleWrapper,
  StyledStatisticsBlock,
  StyledText,
  StyledTotalBlock,
  StyledTotalRowHead,
  TotalStatistics,
  TotalTabWrapper,
} from './styled';
import { TFunction } from 'i18next';

interface StatisticsBlockProps {
  event: Event;
  openTotal: boolean;
  eventResponseId: string;
  width: number;
}

const CollapsedRepliesListItem = ({
  user,
  tooltipText,
  attended,
  t,
}: {
  user: CommunityUser | GroupUser;
  tooltipText?: string;
  attended?: boolean;
  t?: TFunction;
}) => (
  <StyledCollapsedRepliesListItem>
    <UserAvatar
      avatar={user.profile?.smallLogo || user.profile?.logo}
      alt={user.profile?.email}
      style={{
        width: '20px',
        height: '20px',
        minWidth: '20px',
        margin: 0,
        marginRight: '8px',
      }}
    />
    <StyledText fontSize="12px" lineHeight="16px" ellipsis={true}>
      {getProfileName(user.profile)}
    </StyledText>
    {tooltipText ? (
      <Tooltip
        isVisible
        text={tooltipText}
        zoom={1}
        tooltipStyle={{
          whiteSpace: 'pre-wrap',
          maxWidth: '200px',
          height: 'auto',
          zIndex: 100000000,
        }}
      >
        <CommentWhiteIcon />
      </Tooltip>
    ) : null}
    {attended ? (
      <Tooltip
        isVisible
        text={t('event.nominated')}
        zoom={1}
        tooltipStyle={{
          whiteSpace: 'pre-wrap',
          maxWidth: '200px',
          height: 'auto',
          zIndex: 100000000,
        }}
      >
        <span>
          <InviteesImg src={YellowStar} alt="star" />
        </span>
      </Tooltip>
    ) : null}
  </StyledCollapsedRepliesListItem>
);

const CollapsedRepliesRowTitle = ({
  text,
  title,
  color,
  isAwaiting,
}: {
  text: number;
  title?: string;
  color?: string;
  isAwaiting?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledCollapsedRepliesRowTitle>
      <div>
        {isAwaiting ? (
          <NoResponse />
        ) : (
          <StyledReplyCircle
            width={15}
            style={{
              backgroundColor: color,
            }}
          />
        )}
        <StyledText fontSize="12px" lineHeight="16px">
          {isAwaiting ? t('event.awaiting') : title}
        </StyledText>
      </div>
      <StyledText fontSize="12px" lineHeight="16px">
        {text}
      </StyledText>
    </StyledCollapsedRepliesRowTitle>
  );
};

const StatisticsBlock: FC<StatisticsBlockProps> = ({
  event,
  openTotal,
  eventResponseId,
  width,
}) => {
  const { t } = useTranslation();
  const [totalOpened, setTotalOpened] = useState<boolean>(false);
  const [groupsState, setGroupsState] = useState<{ [key: string]: boolean }>(
    {},
  );
  const nominationLength = event?.subEventReplies.filter(
    (r) => r.attended,
  ).length;
  const refs = useRef<HTMLDivElement[]>([]);
  const totalRef = useRef<HTMLDivElement>();
  const totalBlocksRefs = useRef<HTMLDivElement[]>([]);
  const groupsRefs = useRef<HTMLDivElement[]>([]);
  const groupsBlocksRefs = useRef<HTMLDivElement[]>([]);
  const community = useSelector(getCurrentCommunity);
  const eventResponses = useSelector(getEventResponses);
  const currentGroup = useSelector(getCurrentGroup);
  const isDownMd = useMedia(breakpoints.downMd);
  const users = community.users || [];
  const groups = community.overallScheduler?.groups || community.groups;
  const total = community.overallScheduler?.total?.data || [];
  const paramData = useParams();

  useEffect(() => {
    if (currentGroup?.id) {
      if (event.communityId) {
        const groupsInd = groups.findIndex((g) => g.id === currentGroup.id);

        if (eventResponseId) {
          setTimeout(() => {
            groupsBlocksRefs.current[groupsInd][
              eventResponses.findIndex((r) => r.id === eventResponseId)
            ].scrollIntoView({
              behavior: 'smooth',
            });
          }, 500);
        } else {
          setTimeout(
            () =>
              groupsRefs.current[groupsInd].scrollIntoView({
                behavior: 'smooth',
              }),
            500,
          );
        }
      }

      setGroupsState({ ...groupsState, [currentGroup.id]: true });
    }
  }, []);

  useEffect(() => {
    if (openTotal) {
      if (eventResponseId) {
        if (event.communityId) {
          setTimeout(() => {
            totalBlocksRefs.current[
              eventResponses.findIndex((r) => r.id === eventResponseId)
            ].scrollIntoView({
              behavior: 'smooth',
            });
          }, 500);
        }

        if (event.groupId) {
          setTimeout(() => {
            groupsBlocksRefs.current[0][0].scrollIntoView({
              behavior: 'smooth',
            });
          }, 500);
        }
      } else if (!event.groupId) {
        setTimeout(() => {
          totalRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }, 500);
      }

      if (!(currentGroup?.id && event.communityId)) {
        setTotalOpened(true);
      }
    }
  }, []);

  const groupData: EventGridItem[] = Array.isArray(groups)
    ? groups
        .filter((g) => !!g)
        .map((group) => [
          ...group.users.map((member) => ({
            type: 'user',
            id: member.profile.userId,
            group: group.id,
          })),
        ])
        .flat()
    : [];

  const usersWithoutGroup =
    community.overallScheduler?.users?.filter(
      ({ id }) => !groupData.some((item) => item.id === id),
    ) || [];

  const groupValues = useMemo(() => {
    return groups
      .filter((g) =>
        currentGroup?.id && event.groupId ? g.id === currentGroup.id : true,
      )
      .map((g) => {
        const uniqueReplies = [];

        g?.users?.forEach((u) => {
          const userReplies = event.subEventReplies.filter(
            (r) => r.userId === u.id,
          );

          userReplies.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          );

          if (userReplies.length > 0) {
            uniqueReplies.push(userReplies[0]);
          }
        });

        return {
          group: g,
          responses: eventResponses.map((i) => ({
            count: uniqueReplies.filter((r) => r.eventResponseId === i.id)
              .length,
            userIds: uniqueReplies
              .filter((r) => r.eventResponseId === i.id)
              .map((r) => r.userId),
          })),
          relatedReplies: uniqueReplies,
        };
      });
  }, [eventResponses, event, groups]);

  const withoutGroupValues = useMemo(() => {
    if (!(paramData['*'] === 'overall')) {
      return [];
    }
    const uniqueReplies = [];

    usersWithoutGroup.forEach((u) => {
      const userReplies = event.subEventReplies.filter(
        (r) => r.userId === u.id,
      );

      userReplies.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      if (userReplies.length > 0) {
        uniqueReplies.push(userReplies[0]);
      }
    });

    return [
      {
        group: {
          id: 'NEwID',
          users: [...usersWithoutGroup],
          name: t('common.withoutGroup'),
          order: groupValues.length,
          createdAt: new Date().getTime(),
          updatedAt: new Date().getTime(),
          deletedAt: null,
          communityId: community.id,
        },
        responses: eventResponses.map((i) => ({
          count: uniqueReplies.filter((r) => r.eventResponseId === i.id).length,
          userIds: uniqueReplies
            .filter((r) => r.eventResponseId === i.id)
            .map((r) => r.userId),
        })),
        relatedReplies: uniqueReplies,
      },
    ];
  }, [eventResponses, event, groups, usersWithoutGroup]);

  const totalValues = useMemo(() => {
    if (total) {
      const uniqueReplies = [];

      users.forEach((u) => {
        const userReplies = event.subEventReplies.filter(
          (r) => r.userId === u.id,
        );

        userReplies.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

        if (userReplies.length > 0) {
          uniqueReplies.push(userReplies[0]);
        }
      });

      const data = [
        event.recipients.filter((r) => users.some((u) => r === u.id)).length -
          uniqueReplies.length,
      ];

      eventResponses.forEach((i) => {
        data.push(
          uniqueReplies.filter((r) => r.eventResponseId === i.id)?.length || 0,
        );
      });

      return data;
    }

    return [];
  }, [total, eventResponses, event]);

  const renderGroupRow = ({ responses, group, relatedReplies }, ind) => {
    const handleGroupClick = () => {
      setGroupsState({ ...groupsState, [group.id]: !groupsState[group.id] });
    };

    return (
      <StyledGroupRow
        key={group.id}
        ref={(r) => {
          groupsRefs.current[ind] = r;
        }}
        width={(width >= 376 ? width : 376) + (isDownMd ? 57 : 19)}
        height={
          groupsState[group.id]
            ? 30 + refs.current[ind].getBoundingClientRect().height
            : 30
        }
        onClick={handleGroupClick}
      >
        <StyledGroupRowHead>
          <StyledGroupName>
            <GroupIconGreen />
            <Tooltip
              text={group?.name}
              zoom={1}
              isVisible
              opacity="0.8"
              position="left-center"
            >
              <StyledText ellipsis={true}>{group?.name}</StyledText>
            </Tooltip>
          </StyledGroupName>
          <StyledRepliesBlock>
            <StyledNoRepliesWrapper key="no_response_key">
              <NoResponse />
              <StyledText>
                {group?.users?.filter((u) => event.recipients.includes(u.id))
                  .length -
                  responses
                    .map((item) => item.userIds.length)
                    .reduce((a, b) => a + b)}
              </StyledText>
            </StyledNoRepliesWrapper>
            {responses.map(({ count }, index) => (
              <StyledReplyCircleWrapper key={index}>
                <StyledReplyCircle
                  style={{
                    backgroundColor: eventResponses[index]?.color,
                  }}
                />
                <StyledText>{count}</StyledText>
              </StyledReplyCircleWrapper>
            ))}
          </StyledRepliesBlock>
        </StyledGroupRowHead>
        <StyledCollapsedReplies
          width={width - 18 - 19}
          ref={(r) => {
            refs.current[ind] = r;
          }}
        >
          <StyledCollapsedRepliesRow
            key="no-responses"
            style={{
              borderColor: 'grey',
              backgroundColor: 'white',
            }}
          >
            <CollapsedRepliesRowTitle
              isAwaiting={true}
              text={
                group?.users?.filter((u) => event.recipients.includes(u.id))
                  .length -
                responses
                  .map((item) => item.userIds.length)
                  .reduce((a, b) => a + b)
              }
            />
            <StyledCollapsedRepliesRowBody>
              {group?.users
                ?.filter(
                  (u) =>
                    event.recipients.includes(u.id) &&
                    !responses.some((r) => r.userIds.includes(u.id)),
                )
                .map((u) => (
                  <CollapsedRepliesListItem key={u.profile?.email} user={u} />
                ))}
            </StyledCollapsedRepliesRowBody>
          </StyledCollapsedRepliesRow>
          {eventResponses.map((r, index) => (
            <StyledCollapsedRepliesRow
              ref={(r) => {
                if (Array.isArray(groupsBlocksRefs.current[ind])) {
                  groupsBlocksRefs.current[ind][index] = r;
                } else {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  groupsBlocksRefs.current[ind] = [r];
                }
              }}
              key={index}
              style={{
                borderColor: r.color,
                backgroundColor: /^#([0-9A-F]{3}){1,2}$/i.test(r.color)
                  ? r.color + '80'
                  : 'white',
              }}
            >
              <CollapsedRepliesRowTitle
                text={responses[index].count}
                title={r.title}
                color={r.color}
              />
              <StyledCollapsedRepliesRowBody>
                {group?.users
                  ?.filter((u) => responses[index].userIds.includes(u.id))
                  .map((u) => (
                    <CollapsedRepliesListItem
                      key={u.profile?.email}
                      user={u}
                      tooltipText={
                        relatedReplies.find((r) => r.userId === u.id)?.comment
                      }
                      attended={
                        relatedReplies.find((r) => r.userId === u.id)?.attended
                      }
                      t={t}
                    />
                  ))}
              </StyledCollapsedRepliesRowBody>
            </StyledCollapsedRepliesRow>
          ))}
        </StyledCollapsedReplies>
      </StyledGroupRow>
    );
  };

  return (
    <StyledStatisticsBlock>
      <StyledTotalBlock
        width={(width >= 376 ? width - 34 : 376) + (isDownMd ? 57 : 19)}
        height={
          totalOpened
            ? 110 + totalRef.current.getBoundingClientRect().height + 24
            : 110
        }
        onClick={() => setTotalOpened(!totalOpened)}
      >
        <StyledTotalRowHead>
          <TotalTabWrapper>
            <StyledText>
              <StyledText.Bold>
                {
                  event.recipients.filter((r) => users.some((u) => r === u.id))
                    .length
                }
              </StyledText.Bold>{' '}
              {t('event.invitees')}{' '}
            </StyledText>
            <TotalStatistics>
              <TotalStatistics.NoRepliesWrapper key="no_response">
                <NoResponse />
                <StyledText>
                  <StyledText.Bold>{totalValues[0]}</StyledText.Bold>
                </StyledText>
              </TotalStatistics.NoRepliesWrapper>
              <TotalStatistics.Line />
              <TotalStatistics.NoRepliesWrapper key="AttendedIcon">
                <TotalStatistics.Img src={AttendedIcon} alt="star" />
                <StyledText>
                  <StyledText.Bold>{nominationLength}</StyledText.Bold>
                </StyledText>
              </TotalStatistics.NoRepliesWrapper>
            </TotalStatistics>
          </TotalTabWrapper>
          <ColorDotTabWrapper>
            {totalValues.slice(1).map((i, ind) => {
              return (
                <ReplyCircleWrapper key={eventResponses[ind].id}>
                  <Tooltip
                    text={eventResponses[ind].title}
                    isVisible
                    zoom={1}
                    opacity="1"
                    position="top-center"
                    tooltipStyle={{
                      whiteSpace: 'pre-wrap',
                      maxWidth: '200px',
                      height: 'auto',
                      zIndex: 100000000,
                    }}
                  >
                    <StyledReplyCircle
                      width="32"
                      style={{
                        backgroundColor: eventResponses[ind].color,
                      }}
                    />
                  </Tooltip>
                  <StyledText>
                    <StyledText.Bold>{i}</StyledText.Bold>
                  </StyledText>
                </ReplyCircleWrapper>
              );
            })}
          </ColorDotTabWrapper>
        </StyledTotalRowHead>
        <StyledCollapsedReplies width={width - 18 - 19} ref={totalRef}>
          <StyledCollapsedRepliesRow
            key="no-responses"
            style={{
              borderColor: 'grey',
              backgroundColor: 'white',
            }}
          >
            <CollapsedRepliesRowTitle isAwaiting={true} text={totalValues[0]} />
            <StyledCollapsedRepliesRowBody>
              {users
                .filter(
                  (u) =>
                    event.recipients.includes(u.id) &&
                    !event.subEventReplies.some((r) => r.userId === u.id),
                )
                .map((u) => (
                  <CollapsedRepliesListItem key={u.profile?.email} user={u} />
                ))}
            </StyledCollapsedRepliesRowBody>
          </StyledCollapsedRepliesRow>
          {eventResponses.map((r, index) => (
            <StyledCollapsedRepliesRow
              ref={(r) => {
                totalBlocksRefs.current[index] = r;
              }}
              key={index}
              style={{
                borderColor: r.color,
                backgroundColor: /^#([0-9A-F]{3}){1,2}$/i.test(r.color)
                  ? r.color + '80'
                  : 'white',
              }}
            >
              <CollapsedRepliesRowTitle
                text={totalValues[index + 1]}
                title={r.title}
                color={r.color}
              />
              <StyledCollapsedRepliesRowBody>
                {users
                  .filter((u) =>
                    event.subEventReplies
                      .filter((er) => er.eventResponseId === r.id)
                      .some((er) => er.userId === u.id),
                  )
                  .map((u) => (
                    <CollapsedRepliesListItem
                      key={u.profile?.email}
                      user={u}
                      tooltipText={
                        event.subEventReplies.find((r) => r.userId === u.id)
                          ?.comment
                      }
                      attended={
                        event.subEventReplies.find((r) => r.userId === u.id)
                          ?.attended
                      }
                      t={t}
                    />
                  ))}
              </StyledCollapsedRepliesRowBody>
            </StyledCollapsedRepliesRow>
          ))}
        </StyledCollapsedReplies>
      </StyledTotalBlock>
      {groups.length > 0 && (
        <StyledGroupsBlock>
          <StyledGroupsList>
            {[...groupValues, ...withoutGroupValues].map(renderGroupRow)}
          </StyledGroupsList>
        </StyledGroupsBlock>
      )}
    </StyledStatisticsBlock>
  );
};

export default StatisticsBlock;
