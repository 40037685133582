import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocalStorage, useMedia } from 'react-use';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { COMMUNITY_ROUTES } from 'configs';
import { CommunityMenu, CommunitySidebarMobile } from 'components';
import {
  CommunitySettingsPage,
  CommunityOverallSchedulerPage,
  GroupPage,
  GroupSettingsPage,
  DocumentationPage,
  CommunityChatPage,
  EmailModulePage,
  CommunityLandingPage,
  PushNotificationPage,
} from 'pages';
import { breakpoints } from 'theme';
import ImportFeaturePopup from 'components/Popups/importPopup';
import PrefrencePopup from 'components/Popups/prefrencePopup/PrefrencePopup';

const CommunityRouter = () => {
  const { addToast } = useToasts();
  const isWide = useMedia(breakpoints.md);
  const { t } = useTranslation();
  const [importFeatureVisible, setImportFeatureVisible] =
    useState<boolean>(false);
  const [prefrenceFeatureVisible, setPrefrenceFeatureVisible] =
    useState<boolean>(false);
  const [defaultFeaturePopup, setDefaultFeaturePopup] =
    useLocalStorage<boolean>('defaultPopupshown', false);

  const [lsImportPopup, setLsImportFeaturePopup] = useLocalStorage<boolean>(
    'importFeaturePopupShown',
    false,
  );

  const [lsShowToast, setIsShowToast] = useLocalStorage<boolean>(
    'importforGroup',
    false,
  );

  useEffect(() => {
    if (!defaultFeaturePopup) {
      setDefaultFeaturePopup(true);
      setTimeout(() => setPrefrenceFeatureVisible(true), 3000);
    }
  }, [defaultFeaturePopup]);

  useEffect(() => {
    if (!lsImportPopup) {
      setLsImportFeaturePopup(true);
      setTimeout(() => setImportFeatureVisible(true), 3000);
    }
  }, [lsImportPopup]);

  useEffect(() => {
    if (!lsShowToast) {
      setIsShowToast(true);
      addToast(t('bulkUploadEvents.toastText'), {
        appearance: 'success',
        autoDismiss: false,
      });
    }
  }, [lsShowToast]);

  return (
    <>
      <ImportFeaturePopup
        isOpen={importFeatureVisible}
        onClose={() => setImportFeatureVisible(false)}
      />
      <PrefrencePopup
        isOpen={prefrenceFeatureVisible}
        onClose={() => setPrefrenceFeatureVisible(false)}
      />
      {isWide ? <CommunityMenu /> : <CommunitySidebarMobile />}
      <Routes>
        <Route path="*" element={<CommunityLandingPage />} />
        <Route
          path={COMMUNITY_ROUTES.SETTINGS}
          element={<CommunitySettingsPage />}
        />
        <Route
          path={COMMUNITY_ROUTES.OVERALL}
          element={<CommunityOverallSchedulerPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.OVERALL}/${COMMUNITY_ROUTES.EVENT}/:eventId`}
          element={<CommunityOverallSchedulerPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.OVERALL}/${COMMUNITY_ROUTES.EVENT}/:eventId/details`}
          element={<CommunityOverallSchedulerPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId`}
          element={<GroupPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId/${COMMUNITY_ROUTES.EVENT}/:eventId`}
          element={<GroupPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId/${COMMUNITY_ROUTES.EVENT}/:eventId/details`}
          element={<GroupPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId/${COMMUNITY_ROUTES.SETTINGS}`}
          element={<GroupSettingsPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.DOCUMENTATION}/*`}
          element={<DocumentationPage />}
        />
        <Route path={COMMUNITY_ROUTES.EMAIL} element={<EmailModulePage />} />
        <Route
          path={`${COMMUNITY_ROUTES.CHAT}/:chatId`}
          element={<CommunityChatPage />}
        />
        <Route
          path={COMMUNITY_ROUTES.PUSH_NOTIFICATION}
          element={<PushNotificationPage />}
        />
      </Routes>
    </>
  );
};

export default CommunityRouter;
