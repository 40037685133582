import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import {
  doGetCurrentGroup,
  doClearCurrentGroup,
} from 'store/actionCreators/currentGroup';
import {
  getCurrentCommunity,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { getEvents, getMyEventsData } from 'store/selectors/events';
import { getEventTasks } from 'store/selectors/eventTasks';
import {
  getCurrentGroup,
  getCurrentGroupLoading,
  getCurrentGroupError,
} from 'store/selectors/currentGroup';
import { closeModal } from 'store/actionCreators/modal';
import { Loader } from 'components';
import GroupPage from './GroupPage';
import { SchedulerFilter } from 'types';
import { ROUTES, COMMUNITY_ROUTES } from 'configs';
import useEventRedirection from '../../hooks/useEventRedirection';

const GroupPageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [zoom, setZoom] = useLocalStorage(
    'groupSchedulerZoom',
    window.innerHeight > 760 ? 1 : 0.7499999999999998,
  );
  const isMenuOpen = useSelector(getIsMenuOpen);
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getCurrentGroupLoading);
  const isError = useSelector(getCurrentGroupError);
  const group = useSelector(getCurrentGroup);
  const events = useSelector(getEvents);
  const tasks = useSelector(getEventTasks);
  const myAppointmentEvents = useSelector(getMyEventsData);

  const initialFiltersState = {
    from: new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
    ),
    to: new Date(
      new Date().getUTCFullYear() + 5,
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
    ),
    eventType: {
      label: t('common.all'),
      value: 'all',
    },
  };

  const [filters, setFilters] = useState<SchedulerFilter>(initialFiltersState);

  useEffect(() => {
    const currentRoute = location.pathname;
    const routeValues = [
      ROUTES.COMMUNITY,
      COMMUNITY_ROUTES.GROUP,
      COMMUNITY_ROUTES.EVENT,
      'details',
    ];
    if (routeValues.every((route) => currentRoute.includes(route))) {
      const event = myAppointmentEvents.events.find(
        (event) => event.id === eventId,
      );
      setFilters((prevFilterState) => ({
        ...prevFilterState,
        from: new Date(event.from),
      }));
    }
    return () => {
      dispatch(closeModal());
      dispatch(doClearCurrentGroup());
    };
  }, []);

  useEffect(() => {
    if (groupId !== group?.id || !group?.id) {
      dispatch(
        doGetCurrentGroup({
          communityId: community.id,
          groupId,
        }),
      );
    }
  }, [community.id, doGetCurrentGroup, group, groupId]);

  useEventRedirection(filters, events);

  if (isError) {
    navigate('/404');
  }

  if (isLoading || isEmpty(group) || !tasks) {
    return <Loader />;
  }

  return (
    <GroupPage
      isMenuOpen={isMenuOpen}
      zoom={zoom}
      setZoom={setZoom}
      filters={filters}
      community={community}
      initialFiltersState={initialFiltersState}
      setFilters={setFilters}
    />
  );
};

export default GroupPageContainer;
