import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SchedulerFilter } from '../../types';
import { getProfileName } from '../../utils';
import { APPOINTMENT_SCHEDULE_COLUMNS } from '../../configs/statistics';
import { getEventTypes } from '../../store/selectors/eventTypes';
import {
  getCurrentMembers,
  getGroups,
} from '../../store/selectors/currentCommunity';
import { getCurrentGroup } from '../../store/selectors/currentGroup';
import { useParams } from 'react-router-dom';
import { getMembersByGroups } from './utils';

export const useFilters = (): {
  filters: SchedulerFilter;
  setFilters: (value: SchedulerFilter) => void;
} => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const eventTypes = useSelector(getEventTypes) || [];
  const groups = useSelector(getGroups) || [];
  const members = useSelector(getCurrentMembers);
  const group = useSelector(getCurrentGroup);

  const defaultFilters = {
    from: new Date(new Date().setHours(0, 0, 0, 0)),
    to: new Date(
      new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate() + 31,
      ).setHours(23, 59, 59),
    ),
    eventType: {
      value: '',
      label: t('common.all'),
    },
    groups: groups.map((i) => ({
      label: i.name,
      value: i.id,
    })),
    eventTypes: eventTypes.map((i) => ({
      label: i.title,
      value: i.id,
    })),
    dateRange: {
      label: t('filters.dateRanges.thisYear'),
      value: [moment().utc().startOf('year'), moment().utc().endOf('year')],
    } as
      | { label: string; value: [Moment, Moment] }
      | { label: string; value: string },
    visibleColumns: Object.values(APPOINTMENT_SCHEDULE_COLUMNS),
    members: members.map((m) => ({
      label: getProfileName(m.profile),
      value: m.id,
    })),
    page: 1,
    limit: 7,
  };

  const [filters, setFilters] = useState<SchedulerFilter>(defaultFilters);

  useEffect(() => {
    if (groupId) {
      setFilters({
        ...filters,
        members: group?.users?.data?.map((m) => ({
          label: getProfileName(m.profile),
          value: m.id,
        })),
      });
    } else {
      const allGroupsSelected = filters.groups.length === groups.length;

      if (allGroupsSelected || filters.groups.length === 0) {
        setFilters({
          ...filters,
          members: members.map((m) => ({
            label: getProfileName(m.profile),
            value: m.id,
          })),
        });
      } else {
        setFilters({
          ...filters,
          members: getMembersByGroups({
            groups,
            filteredGroups: filters.groups,
          }),
        });
      }
    }
  }, [groupId, group, groups, members, filters.groups]);

  const handleFiltersChange = (value: SchedulerFilter) => {
    setFilters(value);
  };

  return { filters, setFilters: handleFiltersChange };
};
