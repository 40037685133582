import React, { FC } from 'react';

import { StyledBlock } from './styled';
interface MainMenuItemWrapperProps {
  children: JSX.Element | JSX.Element[] | string;
  to: string;
  isDisabled?: boolean;
  isSelected?: boolean;
}

const MainMenuItemWrapper: FC<MainMenuItemWrapperProps> = ({
  children,
  to,
  isDisabled = false,
  isSelected,
}) => (
  <StyledBlock to={to} isDisabled={isDisabled} isSelected={isSelected}>
    {children}
  </StyledBlock>
);

export default MainMenuItemWrapper;
