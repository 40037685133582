import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledSelect,
  StyledOption,
  StyledOptionIcon,
  StyledOptionIconRemove,
} from './styled';

interface SelectInputControlledMultiV2Props {
  name?: string;
  label?: string;
  width?: string;
  value: { [key: string]: any }[];
  options: { [key: string]: any }[];
  error?: FieldError;
  isDisabled?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  closeMenuOnSelect?: boolean;
  onChange: (
    value: { [key: string]: any }[],
    prevValue: { [key: string]: any }[],
  ) => void;
  onBlur?: () => void;
  labelAlignment?: string;
  labelStyle?: { [p: string]: string };
}

const OptionMulti = (props) => {
  const { innerProps, isSelected, data } = props;
  return (
    <div {...innerProps}>
      <StyledOption isSelected={isSelected}>
        {data.label}
        {!isSelected ? <StyledOptionIcon /> : <StyledOptionIconRemove />}
      </StyledOption>
    </div>
  );
};

const SelectInputControlledMultiV2: FC<SelectInputControlledMultiV2Props> = ({
  name,
  label,
  options,
  width,
  error,
  value,
  isDisabled,
  placeholder = '',
  closeMenuOnSelect = true,
  onChange,
  onBlur = () => {},
  labelAlignment,
  labelStyle = {},
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel
          error={error}
          htmlFor={name}
          labelAlignment={labelAlignment}
          style={labelStyle}
        >
          {label}{' '}
        </StyledLabel>
      )}
      <StyledSelect
        error={error}
        width={width}
        id={name}
        name={name}
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        value={value}
        components={{ Option: OptionMulti }}
        isMulti={true}
        hideSelectedOptions={false}
        isClearable={false}
        minMenuHeight={250}
        maxMenuHeight={250}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={(v) => onChange(v, value)}
        onBlur={onBlur}
        styles={{
          control: (base) => ({
            ...base,
            flexWrap: 'no-wrap',
          }),
          valueContainer: (base) => ({
            ...base,
            flex: 'auto',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            overflowX: 'scroll',
          }),
          multiValue: (base) => ({
            ...base,
            minWidth: '60px',
            backgroundColor: '#edf3f8',
          }),
          multiValueRemove: () => ({
            display: 'none',
          }),
          option: (base, state) => ({
            ...base,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            backgroundColor: state.isSelected
              ? '#edf3f8!important'
              : 'transparent!important',
            width: '77px',
            textAlign: 'center',
            padding: '3px 6px',
          }),
        }}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default SelectInputControlledMultiV2;
