import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  width: ${({ width }) => width};
  height: auto;
  display: block;
  width: 40px;
  height: 57px;
  position: relative;
`;

export const ArrowIcon = styled.div`
  width: 40px;
  height: 57px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.skobeloffGreen};
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  border-radius: 4px;
  /* transform: rotate(90deg); */

  &::before {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-left: 20px solid currentColor;
    border-right: 20px solid transparent;
  }

  &::after {
    position: absolute;
    content: '';
    height: 0px;
    width: 0px;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-left: 10px solid ${({ theme }) => theme.color.skobeloffGreen};
    border-right: 10px solid transparent;
  }
`;
