import styled, { keyframes } from 'styled-components';

import { Loader } from 'static';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderWrapper = styled.div`
  width: ${({ type }) => (type === 'main' ? '100vw' : '100%')};
  position: ${({ type }) => (type === 'main' ? 'fixed' : 'unset')};
  height: ${({ type }) => (type === 'main' ? '100svh' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000000;
`;

export const PageLoader = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  animation: ${spin} 1s infinite;
  position: absolute;
  bottom: ${({ type }) => (type === 'page' ? '50%' : 'unset')};
  background-image: ${`url(${Loader})`};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;
