import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledTabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ isSidebar }) => (isSidebar ? '17px' : '23px')};
  padding-right: ${({ isSidebar }) => (isSidebar ? '5px' : '20px')};
  height: 67px;
  flex-wrap: wrap;
  margin: ${({ isSidebar }) => (isSidebar ? '5px 0px 5px 0px' : '0px')};
  @media ${breakpoints.downSmPlus} {
    & > div {
      width: 100%;

      & > input {
        width: 100%;
      }
    }
    min-height: 32px;
    height: 32px;
  }
`;

export const StyledTabsContent = styled.div`
  & > div {
    display: none;

    &:nth-of-type(${({ activeInd }) => activeInd + 1}) {
      display: block;
    }
  }
`;

export const StyledTabsList = styled.ol`
  display: flex;
  list-style-type: none;
  height: 67px;
  border-radius: 16px;
  padding: ${({ isSidebar }) => (isSidebar ? '0px 4px' : '0px 10px')};
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  width: ${({ width }) => width};
  margin: 0px;
  align-items: center;
  @media ${breakpoints.downSmPlus} {
    & > div {
      width: 100%;

      & > input {
        width: 100%;
      }
    }
    min-height: 32px;
    height: 32px;
  }
  & > li:nth-child(${({ activeTab }) => activeTab}) {
    color: ${({ theme }) => theme.color.pickledBluewood};
    &:before {
      content: '';
      display: inline-block;
      background-color: ${({ theme }) => theme.color.brightTurquoise};
      width: 100%;
      height: 4px;
      position: absolute;
      top: 0;
    }
  }
`;
