import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  PhoneNotificationWrapper,
  NotificationHeader,
  NotificationBody,
  ExpandButtonContainer,
  ExpandButton,
  ImageContainer,
  ContentContainer,
  InfoContainer,
  BackGroundImage,
} from './styled';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useDateTime } from '../util';

export type PhoneNotificationProps = {
  imageUrl?: string;
  title?: string;
  message?: string;
  width?: string;
  templateView?: boolean;
  hideImage?: boolean;
};

const GUMB_IMAGE =
  'https://gumb-cdn-prod.s3-eu-central-1.amazonaws.com/f117e224-3ddf-45c0-a9dc-3d2721090ae4.png';

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const titleLineHeight = 17;

const MobileNotification: React.FC<PhoneNotificationProps> = ({
  imageUrl,
  title,
  message,
  width,
  templateView,
  hideImage,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [bgImageHeight, setBgImageHeight] = useState<string>('160px');
  const community = useSelector(getCurrentCommunity);
  const communityLogo = community?.logo ?? GUMB_IMAGE;

  const titleRef = useRef<HTMLDivElement>();
  const messageRef = useRef<HTMLDivElement>();
  const contentContainerRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const dateTime = useDateTime();

  function getHeight() {
    if (wrapperRef?.current && contentContainerRef?.current) {
      const height =
        wrapperRef.current.clientHeight -
        (contentContainerRef?.current?.clientHeight + 24); // reducing by 24 extra to create some spacing top and bottom
      if (height <= 0 || height > 160) {
        setBgImageHeight('160px');
      } else {
        setBgImageHeight(`${height}px`);
      }
    }
  }

  useEffect(() => {
    getHeight();
  }, [title, message, isExpanded]);

  //checking the over flow state.

  return (
    <PhoneNotificationWrapper
      ref={wrapperRef}
      isExpanded={isExpanded}
      width={width}
      templateView={templateView}
      hideBackground={hideImage}
      initial={{ height: '60px' }}
      animate={{
        height: isExpanded ? (hideImage === true ? 'auto' : '240px') : '60px',
      }}
      transition={{ duration: 0.3 }} // Adjust duration as needed
    >
      <ExpandButtonContainer
        isExpanded={isExpanded}
        onClick={() => setIsExpanded((currVal) => !currVal)}
        animate={{
          rotate: isExpanded ? -135 : 45,
          top: 22,
          //  top: isExpanded ? 12 : 22
        }}
        transition={{ ease: 'linear', duration: 0.3 }}
        templateView={templateView}
      >
        <ExpandButton />
      </ExpandButtonContainer>
      <ContentContainer templateView={templateView} ref={contentContainerRef}>
        <ImageContainer
          width="24px"
          height="24px"
          animate={{
            top: 12,
          }}
          alignSelf="baseline"
          isExpanded
        >
          <img src={GUMB_IMAGE} />
        </ImageContainer>
        <InfoContainer isExpanded={isExpanded} templateView={templateView}>
          {!templateView && isExpanded && (
            <p
              style={{
                color: 'white',
                fontSize: '12px',
                fontWeight: 400,
                marginBottom: '4px',
              }}
            >
              Gumb • {dateTime.time}
            </p>
          )}
          <NotificationHeader
            isExpanded={isExpanded}
            ref={titleRef}
            lineHeight={titleLineHeight}
          >
            {title}
          </NotificationHeader>
          <NotificationBody
            isExpanded={isExpanded}
            ref={messageRef}
            templateView={templateView}
          >
            {message}
          </NotificationBody>
        </InfoContainer>
        {!isExpanded && !templateView && (
          <ImageContainer width="30px" height="30px" rightSideImage>
            <img src={communityLogo} />
          </ImageContainer>
        )}
      </ContentContainer>
      {/* {isExpanded && !templateView && !hideImage && (
        <ImageContainer width="90%" height="80%" backgroundImage>
          <img src={communityLogo} />
        </ImageContainer>
      )} */}
      {isExpanded && !templateView && !hideImage && (
        <BackGroundImage height={bgImageHeight} width="90%">
          <img src={communityLogo} />
        </BackGroundImage>
      )}
    </PhoneNotificationWrapper>
  );
};

export default MobileNotification;
