import styled from 'styled-components';
import Select from 'react-select';
import { AddIcon, Remove } from 'static';

export const StyledSelect = styled(Select)`
  width: ${({ width }) => width || '340px'};
  position: relative;
  transition: width 0.3s;

  .react-select__control {
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.color.bittersweet : theme.color.botticelli};
    cursor: pointer;
    box-shadow: none;
    min-height: 0;
  }
  .react-select__control:hover {
    border-color: ${({ theme }) => theme.color.botticelli};
  }
  .react-select__value-container {
    padding: 0 10px;
    font-size: 13px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.pickledBluewood};
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .react-select__indicator-separator {
    position: absolute;
  }
  .react-select__control--menu-is-open .react-select__indicator {
    transform: rotate(180deg);
  }
  .react-select__menu {
    z-index: 100;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  .react-select__menu-list {
    overflow-y: scroll;
  }
  .react-select__option {
    background-color: transparent;
    color: ${({ theme }) => theme.color.pickledBluewood};
    cursor: pointer;
    font-size: 13px;
    line-height: 32px;
    padding: 0 10px 5px 10px;
    text-align: left;
  }
`;

export const StyledOption = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 10px 5px;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 13px;
  line-height: 32px;
  color: rgb(48, 70, 89);
  background-color: ${({ isSelected }) =>
    isSelected ? '#edf3f8!important' : 'transparent!important'};
  transition: background-color 0.3s;

  &:hover {
    background-color: #edf3f8 !important;
  }
`;

export const StyledOptionIcon = styled(AddIcon)`
  z-index: 1000;
  cursor: pointer;
`;

export const StyledOptionIconRemove = styled(Remove)`
  transform: rotate(45deg);
  z-index: 1000;
  cursor: pointer;
`;
