import React from 'react';
import { components } from 'react-select';

import { taskIcons } from 'configs';

const ValueOption = (props) => {
  const { SingleValue } = components;
  const {
    data: { value },
  } = props;
  const Icon = taskIcons[value];

  return (
    <SingleValue {...props}>
      <Icon />
    </SingleValue>
  );
};

export default ValueOption;
