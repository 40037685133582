import styled from 'styled-components';

import { breakpoints } from 'theme';

const getMarginLeft = (isUserInCommunity, isMenuOpen) => {
  if (!isUserInCommunity) {
    return '60px';
  }

  return isMenuOpen ? '360px' : '60px';
};

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100svh;
  overflow: hidden;
  transition: margin-left 0.3s;
  margin-left: ${({ isUserInCommunity, isMenuOpen }) =>
    getMarginLeft(isUserInCommunity, isMenuOpen)};

  @media ${breakpoints.downMd} {
    margin-left: 0;
  }
`;
export const Layout = styled.div`
  height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.dew};
`;
