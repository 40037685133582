import React, { FC, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { PaginationBox, PaginationEllipsis, PaginationItem } from './styled';

interface PaginationProps {
  page: number;
  totalCount: number;
  itemsPerPage: number;
  onClick: (page: number) => any;
}

const Pagination: FC<PaginationProps> = ({
  page,
  totalCount,
  itemsPerPage,
  onClick,
}) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const complexPagination = useMemo(() => {
    if (totalPages < 6 || page < 3) {
      return (
        <>
          {[...Array(totalPages < 6 ? totalPages : 3)].map((_, ind) => (
            <PaginationItem
              key={uuid()}
              isActive={ind + 1 === page}
              onClick={() => onClick(ind + 1)}
            >
              {ind + 1}
            </PaginationItem>
          ))}
          {totalPages >= 6 && (
            <>
              <PaginationEllipsis />
              <PaginationItem
                key={totalPages}
                isActive={totalPages === page}
                onClick={() => onClick(totalPages)}
              >
                {totalPages}
              </PaginationItem>
            </>
          )}
        </>
      );
    } else if (page >= 3 && page <= totalPages - 2) {
      return (
        <>
          <PaginationItem onClick={() => onClick(1)}>{1}</PaginationItem>
          <PaginationEllipsis />
          <PaginationItem onClick={() => onClick(page - 1)}>
            {page - 1}
          </PaginationItem>
          <PaginationItem isActive>{page}</PaginationItem>
          <PaginationItem onClick={() => onClick(page + 1)}>
            {page + 1}
          </PaginationItem>
          <PaginationEllipsis />
          <PaginationItem
            key={totalPages}
            isActive={totalPages === page}
            onClick={() => onClick(totalPages)}
          >
            {totalPages}
          </PaginationItem>
        </>
      );
    } else {
      return (
        <>
          <PaginationItem
            key={1}
            isActive={1 === page}
            onClick={() => onClick(1)}
          >
            {1}
          </PaginationItem>
          <PaginationEllipsis />
          {[...Array(3)].map((_, ind) => (
            <PaginationItem
              key={uuid()}
              isActive={totalPages - 2 + ind === page}
              onClick={() => onClick(totalPages - 2 + ind)}
            >
              {totalPages - 2 + ind}
            </PaginationItem>
          ))}
        </>
      );
    }
  }, [page, totalPages, onClick]);

  return (
    <PaginationBox className="pagination">{complexPagination}</PaginationBox>
  );
};

export default Pagination;
