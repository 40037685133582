export enum RepeatEventFrequency {
  DO_NOT_REPEAT = 'doNotRepeat',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  EVERY_WORKING_DAY = 'everyWorkingDay',
  OTHER = 'other',
}

export type RepeatEventOption = { value: RepeatEventFrequency; label: string };

export const frequency: RepeatEventOption[] = [
  { value: RepeatEventFrequency.DO_NOT_REPEAT, label: 'Do not repeat' },
  { value: RepeatEventFrequency.DAILY, label: 'Daily' },
  { value: RepeatEventFrequency.WEEKLY, label: 'Weekly' },
  { value: RepeatEventFrequency.MONTHLY, label: 'Monthly' },
  { value: RepeatEventFrequency.YEARLY, label: 'Yearly' },
  { value: RepeatEventFrequency.EVERY_WORKING_DAY, label: 'Every working day' },
  { value: RepeatEventFrequency.OTHER, label: 'Other' },
];
