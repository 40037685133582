export default async (): Promise<boolean> => {
  let isBlocked;

  const tryRequest = async () => {
    try {
      await fetch(
        new Request(
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
          {
            method: 'HEAD',
            mode: 'no-cors',
          },
        ),
      );
      isBlocked = false;

      return isBlocked;
    } catch (err) {
      isBlocked = true;

      return isBlocked;
    }
  };

  return isBlocked !== undefined ? isBlocked : await tryRequest();
};
