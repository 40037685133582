import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledWrapper = styled.div`
  width: 500px;
  margin: 30px auto;

  @media ${breakpoints.downSmPlus} {
    width: auto;
    margin: 30px 27px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(100svh - 144px);

  @media ${breakpoints.downSmPlus} {
    min-height: calc(100svh - 109px);
  }
`;
