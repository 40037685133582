import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getNameFromEmail } from 'utils';
import { categories } from 'constants/categories';
import {
  Sidebar,
  Button,
  ImageInput,
  SelectInput,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
  SidebarInputsWrapper,
  SidebarImageInputWrapper,
  Loader,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useCreateCommunity } from './hooks';
import { FIELDS } from './form';
import { getAuthUser } from 'store/selectors/auth';
import { getCreateCommunityLoading } from 'store/selectors/communities';
import { closeModal } from 'store/actionCreators/modal';

const CreateCommunity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getAuthUser);
  const isLoading = useSelector(getCreateCommunityLoading);

  const { onSubmit, register, unregister, setValue, errors } =
    useCreateCommunity();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('community.createCommunity')}
          onCloseClick={handleClose}
        />
        <SidebarContent>
          <SidebarInputsWrapper>
            <SidebarImageInputWrapper>
              <ImageInput
                name={FIELDS.LOGO}
                register={register}
                unregister={unregister}
                setValue={setValue}
                error={errors[FIELDS.LOGO]}
              />
            </SidebarImageInputWrapper>
            <TextInput
              register={register}
              width="100%"
              name={FIELDS.COMMUNITY_NAME}
              label={t('community.communityName')}
              error={errors[FIELDS.COMMUNITY_NAME]}
              defaultValue={t('community.namePlaceholder', {
                value: profile.profileName || getNameFromEmail(profile.email),
              })}
            />
            <SelectInput
              register={register}
              width="100%"
              name={FIELDS.CATEGORY}
              label={t('community.category')}
              options={categories}
              setValue={setValue}
              error={errors[FIELDS.CATEGORY]}
            />
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter>
          <Button
            type="submit"
            variant={isLoading ? 'secondary' : null}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('common.create')
            )}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default CreateCommunity;
