import { createRoutine } from 'redux-saga-routines';

export const doGetCurrentGroup = createRoutine('GET_CURRENT_GROUP', {
  trigger: (payload: { communityId: string; groupId: string }) => payload,
});

export const doGetTotal = createRoutine('GET_TOTAL', {
  trigger: (payload: { groupId: string; from: Date; to: Date }) => payload,
});

export const doClearCurrentGroup = createRoutine('CLEAR_CURRENT_GROUP');
