import { useState, useMemo, useCallback } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { GROUP_ROLES } from 'configs';
import { EventFormData } from '../../sidebars/CreateEventOverall/form';
import { GroupUser } from 'types';

export const useParticipantsValues = (
  members: GroupUser[],
  recipientsFieldProps: ControllerRenderProps<EventFormData>,
) => {
  const [searchParticipantsInputValue, setSearchParticipantsInputValue] =
    useState<string>('');
  const membersPermissions = members.map((user) => ({
    id: user.id,
    communityPermission: user.communityPermission,
    groupPermission: user.groupPermission,
  }));

  const allParticipantsChecked = useMemo(() => {
    return recipientsFieldProps.value.length === members.length;
  }, [recipientsFieldProps, members]);

  const allGroupAdminsChecked = useMemo(() => {
    const groupsAdmins = members.filter(
      (u) =>
        u.groupPermission?.role === GROUP_ROLES.ADMIN ||
        u.groupPermission?.role === GROUP_ROLES.GROUP_LEADER,
    );

    return (
      groupsAdmins?.length > 0 &&
      groupsAdmins.every((u) => recipientsFieldProps.value.includes(u.id))
    );
  }, [members, recipientsFieldProps]);

  const selectParticipant = (id: string) => {
    recipientsFieldProps.onChange(
      recipientsFieldProps?.value?.includes(id)
        ? recipientsFieldProps?.value.filter((i) => i !== id)
        : [...recipientsFieldProps.value, id],
    );
  };

  const selectAllGroupsAdmins = () => {
    const groupAdminsIds = members
      .filter(
        (u) =>
          u.groupPermission?.role === GROUP_ROLES.ADMIN ||
          u.groupPermission?.role === GROUP_ROLES.GROUP_LEADER,
      )
      .map((u) => u.id);
    const groupAdmins = [...new Set(groupAdminsIds)];
    const allSelected = groupAdmins.every((id) =>
      recipientsFieldProps.value.includes(id),
    );

    if (allSelected) {
      recipientsFieldProps.onChange([]);
    } else {
      recipientsFieldProps.onChange([
        ...new Set(groupAdmins.concat(recipientsFieldProps.value)),
      ]);
    }
  };

  const handleSelectAllParticipantsClick = useCallback(() => {
    if (allParticipantsChecked) {
      recipientsFieldProps.onChange([]);
    } else {
      recipientsFieldProps.onChange(members.map((u) => u.id));
    }
  }, [allParticipantsChecked]);

  return {
    communityUsers: members,
    selectParticipant,
    searchParticipantsInputValue,
    setSearchParticipantsInputValue,
    handleSelectAllParticipantsClick,
    selectAllGroupsAdmins,
    allParticipantsChecked,
    allGroupAdminsChecked,
    membersPermissions,
  };
};
