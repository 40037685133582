import React, { FC } from 'react';

import { ItemWrapper, StyledSpot, StyledText } from './styled';

interface SpottedTextProps {
  color: string;
  text: string;
}

const SpottedText: FC<SpottedTextProps> = ({ color = '#000000', text }) => (
  <ItemWrapper>
    <StyledSpot color={color} />
    <StyledText>{text}</StyledText>
  </ItemWrapper>
);

export default SpottedText;
