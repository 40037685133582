import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { get } from 'lodash/fp';
import { requestRequests } from 'services/requests';

import {
  requestCommunities,
  createCommunity,
  updateCommunity,
  deleteCommunity,
  requestCommunityMatrix,
} from 'services/communities';
import { onGetCurrentUserSubscription } from 'store/sagas/subscriptions';
import {
  doRequestCommunities,
  doRefreshCommunities,
  doCreateCommunity,
  doUpdateCommunity,
  doDeleteCommunity,
  doRequestCommunityInvites,
  doRequestCommunityMatrix,
} from 'store/actionCreators/communities';
import {
  selectCurrentUserSubscription,
  selectUserSubscriptionPending,
  selectIsUserSubscriptionActive,
  selectIsCommunitySubscriptionActive,
  selectCommunitySubscriptionPending,
} from 'store/selectors/subscriptions';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import i18n from 'i18next';
import { toast } from 'utils/toastAbstraction';
import { openModal } from 'store/actionCreators/modal';
import { POPUP_MESSAGE } from 'constants/popups';
import { COMMUNITY_ROLES } from 'configs';
import { checkSubscription } from './helper';
import { getCommunities } from '../selectors/communities';

export function* onRequestCommunities() {
  try {
    const data = yield call(requestCommunities);
    yield put(doRequestCommunities.success(data));

    const communities = yield select(getCommunities);

    if (!communities?.find((c) => c.id === data.id)) {
      yield put(doRefreshCommunities.trigger());
    }
  } catch ({ response }) {
    yield put(doRequestCommunities.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onRefreshCommunities() {
  try {
    const data = yield call(requestCommunities);
    yield put(doRefreshCommunities.success(data));
  } catch ({ response }) {
    yield put(doRefreshCommunities.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onRequestCommunityInvites({ payload }) {
  try {
    const communityList = payload.filter(
      (el) =>
        el.communityPermission.role === COMMUNITY_ROLES.OWNER ||
        el.communityPermission.role === COMMUNITY_ROLES.ADMIN,
    );
    const data = yield all(
      communityList.map((c) => call(requestRequests, c.id)),
    );

    yield put(doRequestCommunityInvites.success(data));
  } catch ({ response }) {
    yield put(doRequestCommunityInvites.failure(response));
    if (get(['status'], response) === 401) {
      yield put(doCheckIsAuthorized.trigger({}));
    }
  }
}

export function* onRequestCommunityMatrix() {
  try {
    const data = yield call(requestCommunityMatrix);
    yield put(doRequestCommunityMatrix.success(data));
  } catch ({ response }) {
    yield put(doRequestCommunityMatrix.failure(response));
    if (get(['status'], response) === 401) {
      yield put(doRequestCommunityMatrix.trigger());
    }
  }
}

export function* onCreateCommunity({
  payload,
}: ReturnType<typeof doCreateCommunity>) {
  try {
    yield call(onGetCurrentUserSubscription);
    yield call(
      checkSubscription,
      selectIsUserSubscriptionActive,
      selectUserSubscriptionPending,
    );
    const { maxCommunities, info } = yield select(
      selectCurrentUserSubscription,
    );
    if (info.communities >= maxCommunities) {
      yield put(doCreateCommunity.failure());
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('community.limitExceededHeader'),
            popupMessageText: i18n.t('community.limitExceededMessageOwner'),
            popupButtonText: 'Ok',
          },
        }),
      );
    } else {
      const data = yield call(createCommunity, payload.data);
      yield put(doCreateCommunity.success(data));
      yield call(payload.onSuccess, data.id);
    }
  } catch ({ response }) {
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
    yield put(doCreateCommunity.failure(response));
    yield toast(i18n.t('community.createFailure'), {
      appearance: 'error',
      autoDismiss: true,
    });
  }
}

export function* onUpdateCommunity({ payload }) {
  try {
    yield call(onGetCurrentUserSubscription);
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(updateCommunity, payload.id, payload);
    yield put(doUpdateCommunity.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doUpdateCommunity.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
    yield toast(i18n.t('community.updateFailure'), {
      appearance: 'error',
      autoDismiss: true,
    });
  }
}

export function* onDeleteCommunity({ payload }) {
  try {
    yield call(onGetCurrentUserSubscription);
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    yield call(deleteCommunity, payload.data);
    yield put(doDeleteCommunity.success(payload.data));
    yield call(onGetCurrentUserSubscription);
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doDeleteCommunity.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export default function* () {
  yield takeEvery(doRequestCommunities, onRequestCommunities);
  yield takeEvery(doRefreshCommunities, onRefreshCommunities);
  yield takeEvery(doRequestCommunityInvites, onRequestCommunityInvites);
  yield takeEvery(doRequestCommunityMatrix, onRequestCommunityMatrix);
  yield takeEvery(doCreateCommunity, onCreateCommunity);
  yield takeEvery(doUpdateCommunity, onUpdateCommunity);
  yield takeEvery(doDeleteCommunity, onDeleteCommunity);
}
