import React, { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  getDateString,
  getProfileName,
  getTimeInterval,
  hexToRgbA,
} from 'utils';
import { Community, EventResponse, EventTask, EventType, Event } from 'types';

interface EventPDFProps {
  event: Event;
  community: Community;
  eventTypes: EventType[];
  eventResponses: EventResponse[];
  tasks: EventTask[];
  timeFormat: 12 | 24;
}

const EventPDF: FC<EventPDFProps> = ({
  event,
  community,
  eventTypes,
  eventResponses,
  tasks,
  timeFormat,
}) => {
  const { t } = useTranslation();

  const getProfileBlockFromReply = (userIds) => {
    const profiles = userIds.map(
      (id) => community.users.find((u) => u.id === id)?.profile,
    );
    const dividedProfiles = [];

    for (let i = 0; i < profiles.length; i = i + 4) {
      dividedProfiles.push(profiles.slice(i, i + 4));
    }

    return dividedProfiles.map((cells, ind) => (
      <tr key={ind}>
        {cells.map((profile) => (
          <td width="25%" key={profile.id} style={{ padding: '10px 8px' }}>
            <table
              style={{ borderCollapse: 'collapse', fontFamily: 'Helvetica' }}
            >
              <tr>
                <td>
                  <div>
                    <p
                      style={{
                        fontSize: '10px',
                        lineHeight: '12px',
                        padding: '4px 0',
                        margin: 0,
                      }}
                    >
                      <b>{getProfileName(profile)}</b>
                      <br />
                      <span
                        style={{
                          fontSize: '8px',
                          lineHeight: '10px',
                        }}
                      >
                        {profile.email}
                      </span>
                      {profile.phones?.find(
                        ({ label }) => label === 'mobilePhoneNumber',
                      ) && (
                        <>
                          <br />
                          <span
                            style={{
                              fontSize: '8px',
                              lineHeight: '10px',
                            }}
                          >
                            {
                              profile.phones.find(
                                ({ label }) => label === 'mobilePhoneNumber',
                              )?.value
                            }
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        ))}
        {cells.length < 4 &&
          new Array(4 - cells.length)
            .fill(null)
            .map((_, ind) => <td key={ind} width="25%" />)}
      </tr>
    ));
  };

  const checkIfGroupHasReplies = (users, event) =>
    users.some((u) => event.recipients.includes(u.id));

  const getTotalTable = (responses, event, users) => {
    const uniqueReplies = [];

    users.forEach((u) => {
      const userReplies = event.subEventReplies.filter(
        (r) => r.userId === u.id,
      );

      userReplies.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      if (userReplies.length > 0) {
        uniqueReplies.push(userReplies[0]);
      }
    });

    const nominationReplies = uniqueReplies.filter((r) => r.attended);
    const penaltyReplies = uniqueReplies.filter((r) => r.penalty);

    const data = [
      {
        response: { title: t('event.totalInvitees'), id: 'totalInvitees' },
        count: event.recipients.length,
      },
      {
        response: {
          title: t('event.responsesReceived'),
          id: 'responsesReceived',
        },
        count: uniqueReplies.length,
      },
      {
        response: { title: t('event.awaiting'), id: 'awaiting' },
        count: event.recipients.length - uniqueReplies.length,
      },
      {
        response: { title: t('event.nomination'), id: 'nomination' },
        count: nominationReplies.length,
      },
      {
        response: { title: t('event.penalty'), id: 'nomination' },
        count: penaltyReplies.length,
      },
    ];

    eventResponses.forEach((r) => {
      data.push({
        response: { title: r.title, id: r.id },
        count: users.filter((u) =>
          uniqueReplies
            .filter((er) => er.eventResponseId === r.id)
            .some((er) => er.userId === u.id),
        ).length,
      });
    });

    return (
      <table
        width="50%"
        style={{
          borderCollapse: 'collapse',
          fontFamily: 'Helvetica',
          margin: 'auto',
        }}
      >
        {data.map((i) => (
          <tr key={i.response.id}>
            <td
              width="80%"
              style={{
                border: '1px solid lightgrey',
                fontSize: '10px',
                padding: '5px 10px',
              }}
            >
              {i.response.title}
            </td>
            <td
              width="20%"
              style={{
                border: '1px solid lightgrey',
                fontSize: '10px',
                padding: '5px 10px',
              }}
            >
              {i.count}
            </td>
          </tr>
        ))}
      </table>
    );
  };

  const getTotalValues = (responses, event, users) => {
    const uniqueReplies = [];

    users.forEach((u) => {
      const userReplies = event.subEventReplies.filter(
        (r) => r.userId === u.id,
      );

      userReplies.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      if (userReplies.length > 0) {
        uniqueReplies.push(userReplies[0]);
      }
    });

    const nominationReplies = uniqueReplies.filter((r) => r.attended);

    const data: {
      response: { title: string; id: string; color?: string };
      userIds: string[];
    }[] = [
      {
        response: { title: t('event.nomination'), id: 'nomination' },
        userIds: nominationReplies.map((r) => r.userId),
      },
    ];

    eventResponses.forEach((r) => {
      data.push({
        response: { title: r.title, id: r.id, color: r.color },
        userIds: users
          .filter((u) =>
            uniqueReplies
              .filter((er) => er.eventResponseId === r.id)
              .some((er) => er.userId === u.id),
          )
          .map((u) => u.id),
      });
    });

    data.push({
      response: { title: t('event.awaiting'), id: 'awaiting' },
      userIds: users
        .filter(
          (u) =>
            event.recipients.includes(u.id) &&
            !uniqueReplies.some((r) => r.userId === u.id),
        )
        .map((u) => u.id),
    });

    const getBorderTopColor = (ind: number, color: string) => {
      if (ind <= 0) {
        return 'none';
      }

      return color ? hexToRgbA(color, 0.3) : 'lightgrey';
    };

    return data
      .filter((i) => i.userIds.length > 0)
      .map((i, ind) => (
        <>
          {ind > 0 && <tr style={{ height: '15px' }}></tr>}
          <tr key={i.response.id}>
            <td
              style={{
                textAlign: 'center',
                padding: '6px 0',
                color: i.response.color,
                fontSize: '11px',
                fontWeight: 'bold',
                borderTop: getBorderTopColor(ind, i.response.color),
                borderBottom: `1px solid ${
                  i.response.color
                    ? hexToRgbA(i.response.color, 0.3)
                    : 'lightgrey'
                }`,
              }}
            >
              <p>
                {i.response.id === 'nomination' && (
                  <img
                    src="https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/attended-black.svg"
                    alt="attended"
                    width="10px"
                    style={{ marginRight: '5px' }}
                  />
                )}
                {`${i.response.title} (${i.userIds.length})`}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <table
                  width="100%"
                  style={{
                    tableLayout: 'fixed',
                    borderCollapse: 'collapse',
                    fontFamily: 'Helvetica',
                  }}
                >
                  {getProfileBlockFromReply(i.userIds)}
                </table>
              </div>
            </td>
          </tr>
        </>
      ));
  };

  const getGroupBlock = (group, event, eventResponses) => {
    return checkIfGroupHasReplies(group?.users ?? [], event) ? (
      <>
        <tr style={{ height: '45px' }}></tr>
        <tr>
          <td
            colSpan={2}
            style={{
              textAlign: 'center',
              padding: '8px 0',
              borderTop: '1px solid lightgrey',
              borderBottom: '1px solid lightgrey',
            }}
          >
            <h3 style={{ fontSize: '14px', margin: 0 }}>
              {group?.name || t('communitySettings.tabs.responses')}
            </h3>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <table
              width="100%"
              style={{ borderCollapse: 'collapse', fontFamily: 'Helvetica' }}
            >
              {getTotalValues(
                eventResponses,
                event,
                group?.users || community.users,
              )}
            </table>
          </td>
        </tr>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <table width="100%" id="pageHeader-first">
        <tr>
          <td
            style={{
              textAlign: 'center',
              height: '20px',
              maxHeight: '20px',
            }}
          >
            <div>
              <img
                alt="gumb"
                src="https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/gumb-logo-green.png"
                width="70px"
                style={{
                  maxWidth: '70px',
                }}
              />
            </div>
          </td>
        </tr>
      </table>
      <table
        width="100%"
        style={{ borderCollapse: 'collapse', fontFamily: 'Helvetica' }}
      >
        <tbody>
          <tr>
            <td width="65%" style={{ padding: '30px 0 0 0' }}>
              <table
                width="100%"
                style={{
                  borderCollapse: 'collapse',
                  fontFamily: 'Helvetica',
                  fontSize: '12px',
                  lineHeight: '20px',
                }}
              >
                <tr style={{ verticalAlign: 'bottom' }}>
                  <td style={{ textAlign: 'left', paddingLeft: '10px' }}>
                    <p
                      style={{
                        margin: '0',
                      }}
                    >
                      {t('event.eventName')}: <b>{event?.title}</b>
                    </p>
                    <p
                      style={{
                        margin: '0',
                      }}
                    >
                      {t('event.eventType')}:{' '}
                      <b>
                        {
                          eventTypes?.find(
                            (type) => type.id === event?.eventTypeId,
                          )?.title
                        }
                      </b>
                    </p>
                    <p
                      style={{
                        margin: '0',
                      }}
                    >
                      {t('event.location')}: <b>{event?.location}</b>
                    </p>
                    <p
                      style={{
                        margin: '0',
                      }}
                    >
                      {t('common.startDateTime')}:{' '}
                      <b>{`${t(
                        `dayOfWeek.day${moment(event.from).day()}`,
                      )}, ${getDateString(new Date(event?.from))} ${
                        !event?.allDay
                          ? getTimeInterval(
                              new Date(event?.from),
                              new Date(event?.to),
                              timeFormat,
                            )
                          : ''
                      }`}</b>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
            <td
              width="35%"
              style={{ textAlign: 'center', padding: '30px 0 10px 0' }}
            >
              <img
                alt="community"
                src={community.logo}
                width="90px"
                height="90px"
                style={{
                  margin: '0 35px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
              <h3
                style={{
                  fontSize: '14px',
                  paddingTop: '10px',
                  margin: '0 auto',
                }}
              >
                {community.name}
              </h3>
            </td>
          </tr>
          <tr style={{ height: '20px' }}></tr>
          {event.description && (
            <tr>
              <td colSpan={2} style={{ padding: '15px 10px' }}>
                <p
                  style={{
                    fontSize: '10px',
                    lineHeight: '12px',
                    margin: '0',
                    whiteSpace: 'pre-wrap',
                    lineBreak: 'normal',
                    textAlign: 'center',
                  }}
                >
                  {event.description}
                </p>
              </td>
            </tr>
          )}
          <tr style={{ height: '20px' }}></tr>
          <tr>
            <td width="30%" colSpan={2} style={{ textAlign: 'center' }}>
              {getTotalTable(
                eventResponses,
                event,
                event.groupId
                  ? community.groups.find((g) => g.id === event.groupId)
                      ?.users || []
                  : community.users,
              )}
            </td>
          </tr>
          <tr style={{ height: '35px' }}></tr>
          {event.subEventTaskReplies.length > 0 && (
            <>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    textAlign: 'center',
                    padding: '8px 0',
                    borderTop: '1px solid lightgrey',
                    borderBottom: '1px solid lightgrey',
                  }}
                >
                  <h3 style={{ fontSize: '14px', margin: 0 }}>
                    {t('common.tasks')}
                  </h3>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table width="100%">
                    {tasks
                      .filter(
                        (t) =>
                          event.tasks.includes(t.id) &&
                          event.subEventTaskReplies.filter(
                            (r) => r.eventTaskId === t.id,
                          ).length > 0,
                      )
                      .map((t, index) => (
                        <>
                          {index > 0 && <tr style={{ height: '15px' }}></tr>}
                          <tr key={t.id}>
                            <td
                              style={{
                                textAlign: 'center',
                                padding: '6px 0',
                                fontSize: '11px',
                                fontWeight: 'bold',
                                borderTop:
                                  index > 0 ? '1px solid lightgrey' : 'none',
                                borderBottom: '1px solid lightgrey',
                              }}
                            >
                              <p>{`${t.title} (${
                                event.subEventTaskReplies.filter(
                                  (r) => r.eventTaskId === t.id,
                                ).length
                              })`}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table
                                width="100%"
                                style={{
                                  tableLayout: 'fixed',
                                  borderCollapse: 'collapse',
                                  fontFamily: 'Helvetica',
                                }}
                              >
                                {getProfileBlockFromReply(
                                  event.subEventTaskReplies
                                    .filter((r) => r.eventTaskId === t.id)
                                    .map((r) => r.userId),
                                )}
                              </table>
                            </td>
                          </tr>
                        </>
                      ))}
                  </table>
                </td>
              </tr>
              <tr style={{ height: '35px' }}></tr>
            </>
          )}
          {community.groups.length > 0
            ? event.groupId
              ? getGroupBlock(
                  community.groups.find((g) => g.id === event.groupId),
                  event,
                  eventResponses,
                )
              : community.groups
                  .map((g) => ({ name: g.name, users: g.users }))
                  .concat([
                    {
                      name: t('common.withoutGroup'),
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      users: community.users.filter(
                        (u) =>
                          !community.groups.some((g) =>
                            g.users.some((gu) => gu.id === u.id),
                          ),
                      ),
                    },
                  ])
                  .map((g) => getGroupBlock(g, event, eventResponses))
            : // : getGroupBlock(null, event, eventResponses)
              getGroupBlock(
                {
                  name: t('communitySettings.tabs.responses'),
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  users: community.users.filter(
                    (u) =>
                      !community.groups.some((g) =>
                        g.users.some((gu) => gu.id === u.id),
                      ),
                  ),
                },
                event,
                eventResponses,
              )}
        </tbody>
      </table>
    </>
  );
};

export default EventPDF;
