import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
    width: ${({ width }) => width || '340px'};
    position: relative;
    .react-select__control {
        border: 1px solid ${({ error, theme }) =>
          error ? theme.color.bittersweet : theme.color.botticelli};
        cursor: pointer;
        box-shadow: none;
        min-height: 0;
    };
    .react-select__control:hover {
        border-color: ${({ theme }) => theme.color.botticelli};
    };
    .react-select__value-container {
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        color: ${({ theme }) => theme.color.pickledBluewood};
        text-align: left;
    };
    .react-select__indicator-separator {
        position: absolute;
    };
    .react-select__control--menu-is-open .react-select__indicator {
        transform: rotate(180deg);
    };
    .react-select__menu {
        z-index: 65535;
        margin-top: 4px;
        margin-bottom: 0px;
        max-height: ${({ fullHeight }) => (fullHeight ? 'auto' : '115px')};
    };
    .react-select__menu-list {
      max-height: ${({ fullHeight }) => (fullHeight ? 'auto' : '115px')};
      overflow-y: scroll;
    };
    .react-select__option {
        background-color: transparent;
        color: ${({ theme }) => theme.color.pickledBluewood};
        cursor: pointer;
        font-size: 13px;
        line-height: 32px;
        padding: 0 10px 5px 10px;
        text-align: left;
      }),
`;
