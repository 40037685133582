import { useState } from 'react';
import { EventTask } from '../types';

const useFilter = <T extends EventTask>(
  items: T[],
): {
  filteredItems: T[];
  searchInputValue: string;
  handleChangeSearch: (text: string) => void;
} => {
  const [filteredItems, setFilteredItems] = useState<T[]>(items);
  const [searchInputValue, setSearchInputValue] = useState('');

  const handleChangeSearch = (text) => {
    const newItems = items.filter(({ title }) =>
      `${title}`.toLocaleLowerCase().includes(text.toLowerCase()),
    );
    setFilteredItems(newItems);
    setSearchInputValue(text);
  };

  return { filteredItems, searchInputValue, handleChangeSearch };
};

export default useFilter;
