import styled from 'styled-components';

import { PersonIcon } from 'static';

export const Placeholder = styled.div`
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme, clickable }) =>
    clickable
      ? `1px dashed ${theme.color.brightTurquoise}`
      : `1px solid ${theme.color.botticelli}`};
  border-radius: 50%;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};

  svg {
    transition: transform 0.4s;
  }

  ${({ clickable }) =>
    clickable &&
    `
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  `}
`;

export const StyledPersonIcon = styled(PersonIcon)`
  width: 20px;
  height: 20px;
`;
