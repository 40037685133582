import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import AppointmentScheduleCharts from './AppointmentScheduleCharts';
import Grid from 'components/Grid/gridv2';
import { Loader } from '../../index';
import { useHeaderData, useGridData, useFooterData } from './hooks';
import {
  getAppointmentScheduleStatistic,
  getAppointmentScheduleStatisticLoading,
  getAppointmentScheduleStatisticTotal,
  getAppointmentScheduleStatisticTotalLoading,
} from 'store/selectors/statistics';
import { SchedulerFilter } from 'types';

import './styles.css';

interface AppointmentScheduleProps {
  zoom: number;
  filters: SchedulerFilter;
  gridDataHandler: (data: any[]) => void;
}

const AppointmentSchedule: FC<AppointmentScheduleProps> = ({
  zoom,
  filters,
  gridDataHandler,
}) => {
  const data = useSelector(getAppointmentScheduleStatistic);
  const total = useSelector(getAppointmentScheduleStatisticTotal);
  const statisticsLoading = useSelector(getAppointmentScheduleStatisticLoading);
  const totalLoading = useSelector(getAppointmentScheduleStatisticTotalLoading);
  const isLoading = statisticsLoading || totalLoading;

  const headerData = useHeaderData(filters.visibleColumns, zoom);

  const { gridData, divideColumns } = useGridData(
    data,
    filters.visibleColumns,
    zoom,
  );

  const footerData = useFooterData(data, total, zoom, filters.visibleColumns);

  gridDataHandler(gridData);

  return (
    <div
      style={{
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      {data && isLoading ? <Loader floating={true} /> : null}
      <div
        style={{
          width: '100%',
        }}
      >
        {!data && isLoading ? null : (
          <Grid
            data={gridData}
            header={headerData}
            footer={footerData}
            zoom={zoom}
            statisticsTab={true}
            topHeadHeight={0}
            mainGridHeight={70 * zoom + 39 * gridData.length * zoom}
            divideColumns={divideColumns}
          />
        )}
      </div>
      <AppointmentScheduleCharts total={total} totalLoading={totalLoading} />
    </div>
  );
};

export default AppointmentSchedule;
