import moment from 'moment';

import { axiosInstance as axios, axiosInstanceArrayBuffer } from '../index';
import { EditEventType } from 'constants/sidebars';
import { EventFormData } from 'sidebars/CreateEventOverall/form';

import { EventData } from 'components/ImportCsv';
import { EXPORT_FIELDS } from 'constants/export_csv';

export const requestEvents = async (
  groupId: string,
  from: Date = new Date(),
  to: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate() + 7,
  ),
  eventType = 'all',
  filterValue,
  page,
  limit,
) => {
  const fromToRequest = new Date(
    Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()),
  );
  const toToRequest = new Date(
    Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1),
  );
  const { data } = await axios.get(
    `/groups/${groupId}/event?from=${fromToRequest.toISOString()}&to=${toToRequest.toISOString()}${
      eventType && eventType !== 'all' ? '&eventTypeId=' + eventType : ''
    }${filterValue ? '&key=' + filterValue : ''}${page ? '&page=' + page : ''}${
      limit ? '&limit=' + limit : ''
    }`,
  );

  return data;
};

export const requestOverallEvents = async (
  communityId,
  from = new Date(),
  to = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate() + 7,
  ),
  eventType = 'all',
  filterValue: string,
  page: number,
  limit: number,
) => {
  const fromToRequest = new Date(
    Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()),
  );
  const toToRequest = new Date(
    Date.UTC(to.getFullYear(), to.getMonth(), to.getDate() + 1),
  );
  const { data } = await axios.get(
    `/communities/${communityId}/event?from=${fromToRequest.toISOString()}&to=${toToRequest.toISOString()}${
      eventType && eventType !== 'all' ? '&eventTypeId=' + eventType : ''
    }${filterValue ? '&key=' + filterValue : ''}${page ? '&page=' + page : ''}${
      limit ? '&limit=' + limit : ''
    }`,
  );

  return data;
};

export const createEvent = async (
  groupId: string,
  data: EventFormData,
  filterFrom: Date,
  filterTo: Date,
) => {
  const response = await axios.post(
    `/groups/${groupId}/event?filterFrom=${filterFrom.toISOString()}&filterTo=${filterTo.toISOString()}`,
    data,
  );
  return response.data;
};

export const updateEvents = async (
  groupId: string,
  data: EventFormData & {
    notify: { toNotify: boolean; notifyText?: string };
    eventSeriesId: string;
    id: string;
  },
  editType: EditEventType,
  filterFrom: Date,
  filterTo: Date,
) => {
  const response = await axios.put(
    `/groups/${groupId}/event/${data.id}?type=${editType}&from=${
      data.from
    }&filterFrom=${filterFrom.toISOString()}&filterTo=${filterTo.toISOString()}`,
    {
      ...data,
      description: data.description || '',
    },
  );

  return response.data;
};

export const deleteEvents = async (
  groupId: string,
  eventId: string,
  editType: EditEventType,
  eventSeriesId: string,
  from: string,
  notify: { toNotify: boolean; notifyText?: string },
) => {
  const response = await axios.delete(
    `/groups/${groupId}/event/${eventId}?${
      editType ? `type=${editType}` : ''
    }&from=${from}&${eventSeriesId ? `eventSeriesId=${eventSeriesId}` : ''}&${
      notify ? `notify=${notify}` : ''
    }`,
    { data: { notify } },
  );
  return response.data;
};

export const createCommunityEvent = async (
  communityId: string,
  data: EventFormData,
  filterFrom: Date,
  filterTo: Date,
) => {
  const response = await axios.post(
    `/communities/${communityId}/event?filterFrom=${filterFrom.toISOString()}&filterTo=${filterTo.toISOString()}`,
    data,
  );
  return response.data;
};

export const updateCommunityEvents = async (
  communityId: string,
  data: EventFormData & {
    notify: { toNotify: boolean; notifyText?: string };
    eventSeriesId: string;
    id: string;
  },
  editType: EditEventType,
  filterFrom: Date,
  filterTo: Date,
) => {
  const response = await axios.put(
    `/communities/${communityId}/event/${data.id}?type=${editType}&from=${
      data.from
    }&filterFrom=${filterFrom.toISOString()}&filterTo=${filterTo.toISOString()}`,
    data,
  );
  return response.data;
};

export const deleteCommunityEvents = async (
  communityId: string,
  eventId: string,
  editType: EditEventType,
  eventSeriesId: string,
  from: string,
  notify: { toNotify: boolean; notifyText?: string },
) => {
  const response = await axios.delete(
    `/communities/${communityId}/event/${eventId}?${
      editType ? `type=${editType}` : ''
    }&from=${from}&${eventSeriesId ? `eventSeriesId=${eventSeriesId}` : ''}&${
      notify ? `notify=${notify}` : ''
    }`,
    { data: { notify } },
  );
  return response.data;
};

export const getShareLink = async (communityId: string, eventId: string) => {
  const response = await axios.get(
    `/communities/${communityId}/event/${eventId}`,
  );
  return response.data?.data;
};

export const getEvent = async (communityId: string, eventId: string) => {
  const response = await axios.get(
    `/communities/${communityId}/event/${eventId}/single`,
  );
  return response.data?.data;
};

export const requestMyEvents = async (
  from = moment().format(),
  eventTypeId: string,
  key: string,
  page: number,
  limit: number,
  awaiting: boolean,
  nomination: boolean,
) => {
  const UTCFrom = moment(from).utc().startOf('day').format();
  const { data } = await axios.get(
    `/users/events?from=${UTCFrom}${
      eventTypeId && eventTypeId !== 'all' ? '&eventTypeId=' + eventTypeId : ''
    }${key ? '&key=' + key : ''}${page ? '&page=' + page : ''}${
      limit ? '&limit=' + limit : ''
    }${awaiting ? '&awaiting=true' : ''}${
      nomination ? '&nomination=true' : ''
    }`,
  );

  return data;
};

export const requestMailEvents = async (
  communityId: string,
  from = moment().format(),
  eventTypeId: string,
  eventsFromGroups: string[],
  key: string,
  page: number,
  limit: number,
) => {
  const UTCFrom = moment(from).utc().startOf('day').format();
  const body = {
    from: UTCFrom,
    eventTypeId,
    key,
    page,
    limit,
    eventsFromGroups,
  };

  if (eventTypeId === 'all') {
    delete body.eventTypeId;
  }

  const { data } = await axios.post(
    `/communities/${communityId}/eventTimeline`,
    body,
  );

  return data;
};

export const getEventPDF = async (
  communityId: string,
  eventId: string,
  data: { html: string },
) => {
  const response = await axiosInstanceArrayBuffer.post(
    `/communities/${communityId}/event/${eventId}/pdf`,
    data,
  );
  return response.data;
};

export const getUnrespondedEventsCount = async () => {
  const { data } = await axios.get('/users/unrespondedEventsCount');

  return data;
};

export const uploadBulkEvents = async (
  communityId: string,
  eventData,
  uniqueEventTypes: Array<string>,
  recipients: Array<string>,
) => {
  const dataObj = {
    events: eventData,
    eventTypeName: uniqueEventTypes,
    recipients,
  };
  const { data } = await axios.post(
    `communities/${communityId}/eventBulk`,
    dataObj,
  );
  return data;
};

export const uploadGroupBulkEvents = async (
  groupId: string,
  communityId: string,
  eventData,
  uniqueEventTypes: Array<string>,
  recipients: Array<string>,
) => {
  const dataObj = {
    events: eventData,
    eventTypeName: uniqueEventTypes,
    recipients,
  };
  const { data } = await axios.post(
    `communities/${communityId}/group/${groupId}/eventBulk`,
    dataObj,
  );
  return data;
};

export const deleteBulkEvents = async (communityId: string) => {
  const { data } = await axios.delete(`communities/${communityId}/eventBulk`);
  return data;
};

export const deleteGroupBulkEvents = async (groupId: string) => {
  const { data } = await axios.delete(`group/${groupId}/eventBulk`);
  return data;
};

export const getBulkEventUploadStatus = async (communityId: string) => {
  const { data } = await axios.get(
    `communities/${communityId}/getCountEventForSchedulerBulk`,
  );
  return data;
};

export const getGroupBulkEventUploadStatus = async (groupId: string) => {
  const { data } = await axios.get(
    `group/${groupId}/getCountEventForGroupBulk`,
  );
  return data;
};

export const resetGroupBulkEventUploadStatus = async (groupId: string) => {
  const { data } = await axios.get(`group/${groupId}/resetUploadStatus`);
  return data;
};

export const resetBulkEventUploadStatus = async (communityId: string) => {
  const { data } = await axios.get(
    `communities/${communityId}/resetUploadStatus`,
  );
  return data;
};

export const getSampleCsvFile = async (
  data: Array<{
    [EXPORT_FIELDS.TITLE]: string;
    [EXPORT_FIELDS.DESCRIPTION]: string;
    [EXPORT_FIELDS.DATE]: string;
    [EXPORT_FIELDS.LOCATION]: string;
    [EXPORT_FIELDS.FROM]: string;
    [EXPORT_FIELDS.TO]: string;
    [EXPORT_FIELDS.EVENT_TYPE_NAME]: string;
  }>,
) => {
  const sendData = { events: data };
  const response = await axios.post('generateEventXlsx', sendData);
  const binaryDataBuffer = Buffer.from(response.data, 'base64');
  return binaryDataBuffer;
};
