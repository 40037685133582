import React, { FC } from 'react';

import { Placeholder, StyledPersonIcon } from './styled';

interface ProfileImagePlaceholderProps {
  width?: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

const ProfileImagePlaceholder: FC<ProfileImagePlaceholderProps> = ({
  width = '128px',
  icon,
  onClick = () => {},
}) => (
  <Placeholder width={width} onClick={onClick} clickable={!!onClick}>
    {icon || <StyledPersonIcon />}
  </Placeholder>
);

export default ProfileImagePlaceholder;
