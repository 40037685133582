import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { SharedStyledDatePicker, SharedStyledPickerWrapper } from '../styled';

export const StyledDateTimeBlock = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledPickerWrapper = styled(SharedStyledPickerWrapper)`
  width: 100%;
`;

export const StyledDatePicker = styled(SharedStyledDatePicker)`
  width: 100%;
`;

export const CustomHeader = styled.div`
  margin: 10;
  display: flex;
  justify-content: center;
`;
