import React, { FC, useEffect, useMemo, useState } from 'react';
import 'chart.js/auto';
import { Bar, Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/ru';

import { getSettings } from 'store/selectors/auth';
import { getEventTypes } from 'store/selectors/eventTypes';
import { getEventResponses } from 'store/selectors/eventResponses';
import { AppointmentScheduleStatisticsTotal } from 'types';

import { ChartsWrapper, ChartsRow } from './styled';
import { ChartBox, ChartBoxDimmer } from '../styled';

const ANIMATION_DURATION = 1000;

interface AppointmentScheduleChartsProps {
  total: AppointmentScheduleStatisticsTotal;
  totalLoading: boolean;
}

const AppointmentScheduleCharts: FC<AppointmentScheduleChartsProps> = ({
  total,
  totalLoading,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(getSettings);
  const eventTypes = useSelector(getEventTypes) || [];
  const eventResponses = useSelector(getEventResponses) || [];
  const [eventTypesChartDatasets, setEventTypesChartDatasets] = useState([]);
  const [eventRepliesChartDatasets, setEventRepliesChartDatasets] = useState(
    [],
  );
  const [loaderInterval, setLoaderInterval] = useState(null);
  const eventTypesByMonths = total?.data?.eventTypesByMonths || {};
  const totalRepliesByResponse = total?.data?.totalRepliesByResponse || {};

  const loaderDisplayed = useMemo(() => {
    return totalLoading;
  }, [totalLoading]);

  useEffect(() => {
    if (loaderDisplayed && !loaderInterval) {
      const interval = setInterval(() => {
        setEventTypesChartDatasets(
          eventTypes.slice(0, 4).map((t) => ({
            label: t.title,
            data: Array(12)
              .fill(0)
              .map(() => parseInt((Math.random() * 10).toFixed(0), 10)),
            backgroundColor: t.color,
          })),
        );
        setEventRepliesChartDatasets([
          {
            data: [null, ...eventResponses].map(() =>
              (Math.random() * 10).toFixed(0),
            ),
            backgroundColor: [{ color: '#8298AB' }, ...eventResponses].map(
              (r) => r.color,
            ),
            hoverOffset: [null, ...eventResponses].map(() => 5),
          },
        ]);
      }, ANIMATION_DURATION);

      setLoaderInterval(interval);
    } else if (!totalLoading && total?.data) {
      clearInterval(loaderInterval);
      setLoaderInterval(null);

      if (total.data.eventTypes.length > 0) {
        setEventTypesChartDatasets(
          total.data.eventTypes.map((i) => ({
            label: i.label,
            data: Object.values(eventTypesByMonths).map(
              (j) => j[i.id]?.value || 0,
            ),
            backgroundColor: i.color,
          })),
        );
        setEventRepliesChartDatasets([
          {
            data: Object.values(total.data.totalRepliesByResponse).map(
              (i) => i['value'],
            ),
            backgroundColor: Object.values(
              total.data.totalRepliesByResponse,
            ).map((i) => i['color']),
            hoverOffset: Object.values(total.data.totalRepliesByResponse).map(
              () => 5,
            ),
          },
        ]);
      } else {
        setEventTypesChartDatasets(
          eventTypes.map((i) => ({
            label: i.title,
            data: new Array(12).fill(0),
            backgroundColor: i.color,
          })),
        );
        setEventRepliesChartDatasets([
          {
            data: [
              ...Object.values(total.data.totalRepliesByResponse),
              { value: 1 },
            ].map((i) => i['value']),
            backgroundColor: Object.values(
              total.data.totalRepliesByResponse,
            ).map((i) => i['color']),
            hoverOffset: Object.values(total.data.totalRepliesByResponse).map(
              () => 5,
            ),
          },
        ]);
      }
    }
  }, [total, totalLoading, eventTypes, eventResponses, loaderInterval]);

  const eventTypesChartLabels = useMemo(() => {
    if (loaderDisplayed) {
      const months = [];

      for (let i = 0; i < 12; i += 1) {
        months.push(
          moment()
            .add(i, 'months')
            .startOf('month')
            .locale(settings.language || 'en')
            .format('MMMM YYYY'),
        );
      }

      return months;
    }

    return Object.keys(eventTypesByMonths).map((k) =>
      moment(k)
        .locale(settings.language || 'en')
        .format('MMMM YYYY'),
    );
  }, [total, loaderDisplayed, eventTypes]);

  const eventRepliesChartLabels = useMemo(() => {
    if (loaderDisplayed) {
      return [t('event.awaiting'), ...eventResponses.map((r) => r.title)];
    } else if (total?.data?.eventTypes.length > 0) {
      return Object.values(totalRepliesByResponse).map((i) =>
        i['label'] === 'awaiting' ? t('event.awaiting') : i['label'],
      );
    }

    return [...Object.values(totalRepliesByResponse), { label: '-' }].map((i) =>
      i['label'] === 'awaiting' ? t('event.awaiting') : i['label'],
    );
  }, [total, totalLoading, eventResponses]);

  const eventTypesChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('statistics.charts.eventTypesByMonth'),
      },
    },
    maintainAspectRatio: false,
    animation: {
      duration: ANIMATION_DURATION,
      easing: 'linear',
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: (value) => (value % 1 === 0 ? value : null),
        },
      },
    },
  };

  const eventRepliesChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('statistics.charts.eventReplies'),
      },
    },
    maintainAspectRatio: false,
    animation: {
      duration: ANIMATION_DURATION,
      easing: 'linear',
    },
  };

  const eventTypesChartData = {
    labels: eventTypesChartLabels,
    datasets: eventTypesChartDatasets,
  };

  const eventRepliesChartData = {
    labels: eventRepliesChartLabels,
    datasets: eventRepliesChartDatasets,
  };

  return (
    <ChartsWrapper>
      <ChartsRow>
        <ChartBox>
          {loaderDisplayed && (
            <ChartBoxDimmer>
              <div>{t('statistics.loadingStatistics')}</div>
            </ChartBoxDimmer>
          )}
          <Bar
            height={400}
            data={eventTypesChartData}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options={eventTypesChartOptions}
          />
        </ChartBox>
        <ChartBox>
          {loaderDisplayed && (
            <ChartBoxDimmer>
              <div>{t('statistics.loadingStatistics')}</div>
            </ChartBoxDimmer>
          )}
          <Doughnut
            data={eventRepliesChartData}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            options={eventRepliesChartOptions}
          />
        </ChartBox>
      </ChartsRow>
    </ChartsWrapper>
  );
};

export default AppointmentScheduleCharts;
