import React, { useMemo } from 'react';
import { find } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  COMMUNITY_ROLES_OPTIONS,
  GROUP_ROLES_OPTIONS,
  GROUP_ROLES,
} from 'configs';
import {
  capMap,
  addressBlockVisible,
  dobBlockVisible,
  phonesBlockVisible,
  getDate,
} from 'utils';
import { checkAuthorizedToMakeAdmin } from 'utils/roles';
import {
  Sidebar,
  SidebarHeader,
  Button,
  DeleteButton,
  SidebarFooter,
  SidebarInputsWrapper,
  ProfileImagePlaceholder,
  SocialLinkButton,
  ColumnInfoBlock,
  OverflowSidebarContent,
  PopupEventWrapper,
} from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV3';
import { FIELDS } from './form';
import { useEditUserRole } from './hooks';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import { getData } from 'store/selectors/modal';
import { EditUserRoleData } from 'types';
import { getCurrentGroup } from 'store/selectors/currentGroup';

import {
  StyledMainInfoBlock,
  StyledImageNameBlock,
  StyledImage,
  StyledName,
  StyledAboutBlock,
  StyledSocialLinksBlock,
  StyledSelectBlock,
  StyledInfoBlock,
  StyledButtonsBlock,
  StyledDeleteButtonBlock,
  StyledForm,
} from './styled';

const EditUserRole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const group = useSelector(getCurrentGroup);
  const modalData = useSelector(getData) as EditUserRoleData;

  const {
    user,
    currentUserInCommunity,
    currentUserInGroup,
    isForGroup,
    onDelete,
  } = modalData;

  const { profile } = user;
  const role = isForGroup
    ? user['groupPermission']?.role
    : user.communityPermission.role;
  const { onSubmit, errors, control } = useEditUserRole(
    isForGroup,
    profile.userId,
    community?.id,
    group?.id,
    role,
  );

  const isAuthorize = checkAuthorizedToMakeAdmin(
    currentUserInCommunity,
    currentUserInGroup,
  );

  const roleOptions = useMemo(() => {
    if (!isForGroup) {
      return COMMUNITY_ROLES_OPTIONS;
    }

    return isAuthorize
      ? GROUP_ROLES_OPTIONS
      : GROUP_ROLES_OPTIONS.filter((item) => item.value !== GROUP_ROLES.ADMIN);
  }, [isForGroup, isAuthorize]);

  const handleClose = () => {
    dispatch(closeModal());
  };
  const handleOpenSidebar = () => {};

  return (
    <Sidebar>
      <StyledForm onSubmit={onSubmit}>
        <SidebarHeader
          label={t('profile.userProfile')}
          onCloseClick={handleClose}
        />
        <OverflowSidebarContent>
          <SidebarInputsWrapper>
            <StyledMainInfoBlock>
              <StyledImageNameBlock>
                {profile.logo ? (
                  <StyledImage src={profile.logo} />
                ) : (
                  <ProfileImagePlaceholder width="100px" />
                )}
                <StyledName>{profile.profileName}</StyledName>
              </StyledImageNameBlock>
              {profile.description && (
                <StyledAboutBlock>{profile.description}</StyledAboutBlock>
              )}
              {profile?.links?.length > 0 && (
                <StyledSocialLinksBlock>
                  {profile.links.map((l) => (
                    <SocialLinkButton
                      key={l.label}
                      href={l.value}
                      socialNetwork={l.label}
                      width="16px"
                      openEditSidebar={handleOpenSidebar}
                    />
                  ))}
                </StyledSocialLinksBlock>
              )}
            </StyledMainInfoBlock>
            <StyledSelectBlock>
              <SelectInputControlled
                name={FIELDS.ROLE}
                control={control}
                width="100%"
                defaultValue={find(
                  { value: role },
                  isForGroup ? GROUP_ROLES_OPTIONS : COMMUNITY_ROLES_OPTIONS,
                )}
                options={roleOptions}
                label={
                  isForGroup
                    ? t('group.groupRole')
                    : t('community.communityRole')
                }
                error={errors[FIELDS.ROLE]?.value}
              />
            </StyledSelectBlock>
            {isForGroup && (
              <StyledInfoBlock>
                <ColumnInfoBlock
                  label={t('community.communityRole')}
                  preventEdit
                />
              </StyledInfoBlock>
            )}
            <StyledInfoBlock>
              <ColumnInfoBlock
                label={t('profile.email')}
                text={profile.email}
                preventEdit
              />
            </StyledInfoBlock>
            {dobBlockVisible(profile) && (
              <StyledInfoBlock>
                <ColumnInfoBlock
                  label={t('profile.dateOfBirth')}
                  text={getDate(profile.dob)}
                  preventEdit
                />
              </StyledInfoBlock>
            )}
            {phonesBlockVisible(profile) &&
              capMap(
                ({ value, label }) => (
                  <ColumnInfoBlock
                    key={label}
                    preventEdit
                    label={t(`profile.${label}`)}
                    text={value}
                  />
                ),
                profile.phones,
              )}
            {addressBlockVisible(profile) && (
              <StyledInfoBlock>
                <ColumnInfoBlock
                  label={t('profile.street')}
                  text={profile.street}
                  preventEdit
                />
                <ColumnInfoBlock
                  label={t('profile.region')}
                  text={profile.region}
                  preventEdit
                />
                <ColumnInfoBlock
                  label={t('profile.postalCode')}
                  text={profile.postalCode}
                  preventEdit
                />
                <ColumnInfoBlock
                  label={t('profile.city')}
                  text={profile.city}
                  preventEdit
                />
                <ColumnInfoBlock
                  label={t('profile.country')}
                  text={profile.country}
                  preventEdit
                />
              </StyledInfoBlock>
            )}
          </SidebarInputsWrapper>
        </OverflowSidebarContent>
        <SidebarFooter>
          <StyledButtonsBlock>
            <StyledDeleteButtonBlock>
              <PopupEventWrapper
                popupMessageText={t('common.deleteHeader')}
                popupButtons={[
                  {
                    popupButtonText: t('common.delete'),
                    handleConfirm: () => onDelete(),
                  },
                ]}
                darkBackground={true}
              >
                <DeleteButton withLabel type="button" />
              </PopupEventWrapper>
            </StyledDeleteButtonBlock>
            <Button type="submit" width="210px" variant={null}>
              {t('common.saveChanges')}
            </Button>
          </StyledButtonsBlock>
        </SidebarFooter>
      </StyledForm>
    </Sidebar>
  );
};

export default EditUserRole;
