import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { AddIcon } from 'static';

export const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(16, 204, 198, 0.71);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(16, 204, 198, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(16, 204, 198, 0);
  }
`;

export const StyledMenuList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  gap: 2px;
  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
  }
  a:last-of-type li {
    margin-bottom: 0;
  }

  &:hover .drag-box {
    opacity: 1;
  }
`;

export const StyledMenuListLink = styled(NavLink)`
  text-decoration: none;
  user-select: none;
  padding: 4px 15px 4px 16px;
  border-radius: 6px;
  &.active > li {
    color: ${({ theme }) => theme.color.brightTurquoise};
  }
  &:hover > li {
    color: ${({ theme }) => theme.color.brightTurquoise};
  }
  &.active,
  &:hover {
    background-color: ${({ theme }) => theme.color.blackSqueeze};
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 18px;
      left: 10px;
      background-color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
  ${({ addCss }) => addCss};
`;

export const StyledMenuListItem = styled.li`
  margin-left: 20px;
  cursor: pointer;
  font-size: 13px;
  color: ${({ theme }) => theme.color.baliHai};
  position: relative;

  &:after {
    content: '';
    display: ${({ hasGreenDot }) => (hasGreenDot ? 'block' : 'none')};
    position: absolute;
    top: 6px;
    left: -14px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.brightTurquoise};
    animation: ${pulse} 2s infinite;
  }

  &:before {
    content: '${({ beforeSymbol }) => beforeSymbol}';
  }
`;

export const StyledMenuListAddGroup = styled.li`
  margin-left: 14px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.brightTurquoise};
  position: relative;
  bottom: -2px;
  ${({ addCss }) => addCss};
`;

export const StyledMenuListAddGroupText = styled.span`
  margin-left: 6px;
  color: ${({ theme }) => theme.color.brightTurquoise};
`;

export const StyledMenuListAddGroupIcon = styled(AddIcon)`
  width: 8px;
  height: 8px;
`;

export const StyledDragIcon = styled.div`
  width: 11px;
  height: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: 19px;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: grab;

  div {
    width: 100%;
    height: 1px;
    background: #b1c1cb;
  }
`;
