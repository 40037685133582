import React, { FC } from 'react';

import { taskIcons } from 'configs';

import { IconWrapper, ItemWrapper, StyledText } from './styled';

interface IconTextProps {
  icon: string;
  text: string;
}

const IconText: FC<IconTextProps> = ({ icon, text }) => {
  const Icon = taskIcons[icon];

  return (
    <ItemWrapper>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <StyledText>{text}</StyledText>
    </ItemWrapper>
  );
};

export default IconText;
