import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { postChangeEmail } from 'services/user';
import { clearHeaders } from 'services';
import { getAmplifyAuthErrorMessage } from '../utils';
import { useInfoPopup } from 'hooks';

import { MessageContainer } from '../styled';

const ChangeEmailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { showInfoPopup } = useInfoPopup();

  const onFailure = (error) => {
    const title = getAmplifyAuthErrorMessage(t, error);

    showInfoPopup({
      type: 'error',
      title: title === t('auth.userNotFound') ? t('auth.codeExpired') : title,
    });
    navigate('/');
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const code = search.get('c');
    const userName = search.get('u');
    const newEmail = search.get('m');

    if (!code || !userName || !newEmail) {
      showInfoPopup({
        type: 'error',
        title: t('errors.invalidRequest'),
      });
      navigate('/');
    }

    (async () => {
      postChangeEmail(userName, code, newEmail)
        .then(() => {
          showInfoPopup({
            type: 'success',
            title: t('auth.confirmed'),
          });
          Auth.signOut();
          clearHeaders();
          navigate('/');
        })
        .catch((err) => {
          onFailure(err);
        });
    })();
  }, []);

  return <MessageContainer>{t('auth.verifying')}</MessageContainer>;
};

export default ChangeEmailPage;
