import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import {
  doRequestCurrentCommunity,
  doClearCurrentCommunityError,
  doClearCurrentCommunity,
} from 'store/actionCreators/currentCommunity';
import { openModal, closeModal } from 'store/actionCreators/modal';
import {
  getCurrentCommunity,
  getCurrentCommunityLoading,
  getCurrentCommunityError,
} from 'store/selectors/currentCommunity';
import {
  getCommunities,
  getCommunitiesLoading,
} from 'store/selectors/communities';
import { getAuthUser } from 'store/selectors/auth';
import { ROUTES } from 'configs';
import { Loader } from 'components';
import CommunityRouter from './CommunityRouter';
import {
  SUBSCRIPTION_NOT_FOUND,
  ACCESS_DENIED,
  LIMIT_MEMBERS,
  INVALID_INVITATION_CODE,
} from 'constants/errors';
import { POPUP_MESSAGE } from '../constants/popups';
import { ModalTypes } from 'types';

const CommunityRouterWrapper = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getAuthUser);
  const community = useSelector(getCurrentCommunity);
  const communities = useSelector(getCommunities);
  const isLoading = useSelector(getCurrentCommunityLoading);
  const areCommunitiesLoading = useSelector(getCommunitiesLoading);
  const error = useSelector(getCurrentCommunityError);
  const currCommunityLoader = useSelector(getCurrentCommunityLoading);
  const { communityId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    return () => {
      dispatch(doClearCurrentCommunity());
    };
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      community?.id !== communityId &&
      communities?.length &&
      !currCommunityLoader
    ) {
      dispatch(
        doRequestCurrentCommunity({
          communityId,
          invitationCode: searchParams.get('invitationCode'),
        }),
      );
    }
  }, [communityId, currentUser, community, communities]);

  const goTo404 = () => {
    navigate('/404');
  };

  if (isLoading || areCommunitiesLoading) {
    return <Loader type="main" />;
  }

  if (error) {
    if (error?.data?.error === ACCESS_DENIED) {
      if (communities.find((c) => c.id === communityId)) {
        navigate(ROUTES.PROFILE);
        dispatch(
          openModal({
            type: POPUP_MESSAGE,
            data: {
              popupHeaderText: t('community.userBlockedTitle'),
              popupMessageText: t('community.userBlockedText'),
              popupButtonText: 'Ok',
              withoutCloseButton: true,
              handleConfirm: () => {
                dispatch(closeModal());
              },
            },
          }),
        );
      } else {
        navigate(`/communities/${communityId}/landing`);
      }

      dispatch(doClearCurrentCommunityError());

      return null;
    }

    if (error?.data?.error === LIMIT_MEMBERS) {
      dispatch(doClearCurrentCommunityError());
      navigate('/profile');
      dispatch(
        openModal({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: t('communitySettings.disableAddMemberTooltip'),
            popupButtonText: 'Ok',
            withoutCloseButton: true,
            handleConfirm: () => {
              dispatch(closeModal());
            },
          },
        }),
      );
      return null;
    }

    if (error?.data?.error === INVALID_INVITATION_CODE) {
      dispatch(doClearCurrentCommunityError());
      navigate(`/communities/${communityId}/landing`);
      dispatch(
        openModal({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: t('community.invitationCodeExpired'),
            popupButtonText: 'Ok',
            handleConfirm: () => {
              dispatch(closeModal());
            },
          },
        }),
      );
      return null;
    }

    if (error?.data?.error !== SUBSCRIPTION_NOT_FOUND) {
      dispatch(doClearCurrentCommunityError());
      goTo404();
      return null;
    } else if (communities.length > 0) {
      dispatch(doClearCurrentCommunityError());
      dispatch(
        openModal({
          type: ModalTypes.SUBSCRIPTION_OVER_POPUP,
        }),
      );
      return null;
    }
  }

  return community?.id ? <CommunityRouter /> : <></>;
};

export default CommunityRouterWrapper;
