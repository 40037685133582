import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  TERMS = 'terms',
}

export type SignUpFormValues = {
  [FIELDS.EMAIL]: string;
  [FIELDS.FIRST_NAME]: string;
  [FIELDS.LAST_NAME]: string;
  [FIELDS.PASSWORD]: string;
  [FIELDS.CONFIRM_PASSWORD]: string;
  [FIELDS.TERMS]: string;
};

export const useSignUpForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  const resolver: Resolver<SignUpFormValues> = yupResolver(
    yup.object().shape({
      [FIELDS.EMAIL]: yup.string().email(t('errors.email')).required(),
      [FIELDS.FIRST_NAME]: yup.string().required(),
      [FIELDS.LAST_NAME]: yup.string().required(),
      [FIELDS.PASSWORD]: yup
        .string()
        .required()
        .matches(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/, t('errors.password')),
      [FIELDS.CONFIRM_PASSWORD]: yup
        .string()
        .oneOf([yup.ref('password'), null], t('errors.confirmPassword')),
      [FIELDS.TERMS]: yup.mixed().oneOf([true], t('errors.termsError')),
    }),
  );

  return useForm<SignUpFormValues>({
    resolver,
  });
};
