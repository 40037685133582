import { useDispatch } from 'react-redux';

import { closeModal } from 'store/actionCreators/modal';
import { useAddFieldForm } from './form';

export const useAddField = (onAddField) => {
  const dispatch = useDispatch();
  const { handleSubmit, ...form } = useAddFieldForm();

  const closeSidebar = () => {
    dispatch(closeModal());
  };

  const onSubmit = handleSubmit((data) => {
    closeSidebar();
    onAddField(data.fieldName);
  });

  return {
    closeSidebar,
    onSubmit,
    ...form,
  };
};
