import React, { FC, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  StyledWrapper,
  StyledInput,
  StyledColorBlock,
  StyledIcon,
  StyledLabel,
} from './styled';
import { generateRandomColor } from 'utils';

interface ColorInputProps {
  name: string;
  control: Control;
}

const ColorInput: FC<ColorInputProps> = ({ name, control }) => {
  const { t } = useTranslation();
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const { field } = useController({
    name,
    control,
    defaultValue: generateRandomColor(),
  });

  const displayPicker = () => {
    setIsPickerVisible(!isPickerVisible);
  };

  return (
    <StyledWrapper>
      <StyledLabel>{t('common.color')}</StyledLabel>
      <StyledInput onClick={displayPicker}>
        <StyledColorBlock color={field.value} />
        <StyledIcon isPickerVisible={isPickerVisible} />
      </StyledInput>
      {isPickerVisible && (
        <SketchPicker
          color={field.value}
          onChange={(pickedColor) => field.onChange(pickedColor.hex)}
          colors={[
            '#FF6900',
            '#FCB900',
            '#00D084',
            '#8ED1FC',
            '#000',
            '#0693E3',
            '#ABB8C3',
            '#EB144C',
            '#F78DA7',
          ]}
        />
      )}
    </StyledWrapper>
  );
};

export default ColorInput;
