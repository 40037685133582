import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { useLocation } from 'react-router-dom';

import { logInApple } from 'utils/auth';
import { AppleLogo } from 'static';

import { Button } from './styled';

const AppleAuthButton = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [prevAuth] = useLocalStorage<{ [key: string]: string }>(
    'socialSignIns',
    {},
  );
  const buttonText = useMemo(() => {
    let text = `${t('auth.signInWith')} Apple`;

    if (location.pathname !== '/signup' && prevAuth?.apple) {
      text = `${t('auth.continueAs')} ${prevAuth.apple}`;
    }

    return text;
  }, [prevAuth, location, t]);

  return (
    <Button type="button" onClick={logInApple}>
      <AppleLogo />
      {buttonText}
    </Button>
  );
};

export default AppleAuthButton;
