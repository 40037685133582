import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledTabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ isSidebar }) => (isSidebar ? '17px' : '23px')};
  padding-right: ${({ isSidebar }) => (isSidebar ? '5px' : '20px')};
  min-height: 67px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: wrap;
  margin: ${({ isSidebar }) =>
    isSidebar ? '5px 0px 5px 0px' : '0px 0px 10px 0px'};

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoints.downSmPlus} {
    & > div {
      width: 100%;

      & > input {
        width: 100%;
      }
    }
    min-height: 32px;
    height: 32px;
  }

  /* &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 25px;
    height: 67px;
    background: linear-gradient(90deg, white 40%, transparent);
    z-index: 4;
  } */

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 25px;
    height: 67px;
    background: linear-gradient(90deg, transparent, white 40%);
    z-index: 4;
  }
`;

export const StyledTabsList = styled.ol`
  border-radius: 16px;
  padding: ${({ isSidebar }) => (isSidebar ? '0px 4px' : '0px 10px')};
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  display: flex;
  list-style-type: none;
  height: 67px;
  width: ${({ width }) => width};
  margin: 0px;
  align-items: center;

  & > li:nth-child(${({ activeTab }) => activeTab}) {
    color: ${({ theme }) => theme.color.pickledBluewood};

    &:before {
      content: '';
      display: inline-block;
      background-color: ${({ theme }) => theme.color.brightTurquoise};
      width: 100%;
      height: 4px;
      position: absolute;
      top: 0;
    }
  }

  @media ${breakpoints.downSmPlus} {
    height: 100%;
  }
`;
