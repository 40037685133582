import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { useLocation } from 'react-router-dom';

import { logInFacebook } from 'utils/auth';
import { FacebookLogo } from 'static';

import { Button } from './styled';

const FacebookAuthButton = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [prevAuth] = useLocalStorage<{ [key: string]: string }>(
    'socialSignIns',
    {},
  );
  const buttonText = useMemo(() => {
    let text = `${t('auth.signInWith')} Facebook`;

    if (location.pathname !== '/signup' && prevAuth?.facebook) {
      text = `${t('auth.continueAs')} ${prevAuth.facebook}`;
    }

    return text;
  }, [prevAuth, location, t]);

  return (
    <Button type="button" onClick={logInFacebook}>
      <FacebookLogo />
      {buttonText}
    </Button>
  );
};

export default FacebookAuthButton;
