import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledCard = styled.div`
  width: ${({ fluid }) => (fluid ? '100%' : '96%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: ${({ size, isMobile }) =>
    isMobile ? '26px 20px' : size === 'lg' ? '26px 68px' : '26px 26px'};
  /* size === 'lg' && !isMobile ? '26px 68px' : '26px 20px'}; */
  border-radius: 20px;
  box-shadow: ${({ disabled }) =>
    !disabled ? '0 1px 1px 0 #00000021, 0 -1px 6px 0 #0000002e' : 'none'};
  pointer-events: ${({ disabled }) => (!disabled ? 'all' : 'none')};
  transition: width 0.3s, opacity 0.3s, box-shadow 0.3s;
  border: ${({ greenBorder, theme }) =>
    greenBorder ? `1px solid ${theme.color.brightTurquoise}` : 'none'};

  & > button.close-btn:first-of-type {
    position: absolute;
    top: ${({ size }) => (size === 'lg' ? '30px' : '24px')};
    right: ${({ size }) => (size === 'lg' ? '30px' : '24px')};
    width: ${({ size }) => (size === 'lg' ? '30px' : '24px')};
    height: ${({ size }) => (size === 'lg' ? '30px' : '24px')};
    width: 30px;
    height: 30px;
    z-index: 100;
    border-radius: 100%;
    :hover {
      background-color: grey;
      transition: all 0.5s ease;
      svg path {
        fill: white;
      }
    }

    svg {
      width: ${({ size }) => (size === 'lg' ? '20px' : '16px')};
      height: ${({ size }) => (size === 'lg' ? '20px' : '16px')};
    }

    svg path {
      fill: black;
      transition: fill 0.3s;
    }
  }

  @media ${breakpoints.smPlus} {
    width: ${({ fluid, width = '340px' }) => (fluid ? '100%' : width)};
  }
`;
