import styled from 'styled-components';

import { FileIcon, DeleteIcon } from 'static';

export const Container = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 0.5px solid ${({ theme }) => theme.color.porcelain};
  padding: 0 32px 0 24px;
  justify-content: space-between;
  text-decoration: none;
`;

export const FileItemContainer = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const StyledFileIcon = styled(FileIcon)`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

export const FileName = styled.span`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 15px;
  margin-left: 10px;
  text-decoration: none;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  width: 16px;
  height: 18px;
`;

export const DeleteFileButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  &:hover ${StyledDeleteIcon} {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;
