import React, { CSSProperties, FC, ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Channel } from 'stream-chat';
import { useSelector } from 'react-redux';

import { ROUTES, COMMUNITY_ROUTES } from 'configs';
import { MenuListWrapper } from 'components';
import { getGroups } from 'store/selectors/currentCommunity';

import {
  StyledMenuList,
  StyledMenuListLink,
  StyledMenuListItem,
} from '../CommunityMenuList/styled';
import { StyledShadow } from '../styled';

interface CommunityChatsListProps {
  icon: ReactNode;
  list: Channel[];
  listRef: React.MutableRefObject<any>;
  listMaxHeight: number;
  onClick: () => any;
  iconStyles?: CSSProperties;
}

const CommunityChatsList: FC<CommunityChatsListProps> = ({
  icon,
  list = [],
  listRef,
  iconStyles,
  listMaxHeight,
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const groups = useSelector(getGroups) || [];
  const shadowShown =
    listRef.current?.getBoundingClientRect().height > listMaxHeight;
  const sortedList = list.filter((i) => !i.data.isMain);

  sortedList.sort((a, b) => {
    return (
      groups.find((g) => g.id === a.data.id)?.order -
      groups.find((g) => g.id === b.data.id)?.order
    );
  });

  const mainChatIndex = list.findIndex((i) => i.data.isMain);

  if (mainChatIndex > 0) {
    sortedList.unshift([...list].splice(mainChatIndex, 1)[0]);
  }

  return (
    <MenuListWrapper
      title={t('menu.chats')}
      icon={icon}
      onClick={onClick}
      iconStyles={iconStyles}
    >
      {sortedList.length > 0 && (
        <>
          <PerfectScrollbar
            style={{
              width: '100%',
              maxHeight: listMaxHeight || 'calc((100svh - 520px) / 2)',
              paddingBottom: shadowShown ? 20 : 0,
            }}
            className={'menu-list-scrollbar'}
            options={{
              wheelSpeed: 0.1,
              wheelPropagation: false,
              minScrollbarLength: 4,
            }}
          >
            <StyledMenuList ref={listRef}>
              {sortedList.map(({ data: item, state }) => (
                <StyledMenuListLink
                  key={uuidv4()}
                  to={`${ROUTES.COMMUNITY}/${item.community}/${COMMUNITY_ROUTES.CHAT}/${item.id}`}
                >
                  <StyledMenuListItem
                    beforeSymbol="#"
                    hasGreenDot={state.unreadCount > 0}
                  >
                    {item.name}
                  </StyledMenuListItem>
                </StyledMenuListLink>
              ))}
            </StyledMenuList>
          </PerfectScrollbar>
          {shadowShown ? <StyledShadow /> : null}
        </>
      )}
    </MenuListWrapper>
  );
};

export default CommunityChatsList;
