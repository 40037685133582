import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getMembersDetails } from 'services/users';
import { getAuthUser } from 'store/selectors/auth';
import {
  getCommunityBlockedMembers,
  getCurrentCommunity,
  getCommunityOwner,
  getCommunityAdmins,
  getCommunityMembers,
  getCommunityUserGroupsLoading,
} from 'store/selectors/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import {
  doGetCommunityUserGroups,
  doClearCommunityUsersGroups,
  doDeleteCommunityUser,
  doAddMemberToGroup,
  doRemoveMemberFromGroup,
  doChangeUserRole,
} from 'store/actionCreators/currentCommunity';
import Members from './Members';
import { COMMUNITY_ROLES } from 'configs';
import { useDebounce, useInfoPopup } from 'hooks';
import { getProfileName } from 'utils/common';
import { CommunityUser, Group } from 'types';

const RolesContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memberListWrapperRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const owners = useSelector(getCommunityOwner);
  const admins = useSelector(getCommunityAdmins);
  const members = useSelector(getCommunityMembers);
  const blockedMembers = useSelector(getCommunityBlockedMembers);
  const currentUser = useSelector(getAuthUser);
  const community = useSelector(getCurrentCommunity);
  const userGroupsLoading = useSelector(getCommunityUserGroupsLoading);
  const [initialUsersRendered, setInitialUsersRendered] =
    useState<boolean>(false);
  const [rowsToShow, setRowsToShow] = useState<number>(0);
  const [users, setUsers] = useState<CommunityUser[]>(
    owners.concat(admins).concat(members).concat(blockedMembers),
  );
  const [initialUsersList, setInitialUsersList] = useState<CommunityUser[]>(
    owners.concat(admins).concat(members).concat(blockedMembers),
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [exportCSVData, setCommunityMembersForCSV] = useState([]);
  const [csvLoader, setCSVLoader] = useState<boolean>(false);
  const [tableGroupsState, setTableGroupsState] = useState<{
    [key in COMMUNITY_ROLES]: boolean;
  }>({
    [COMMUNITY_ROLES.OWNER]: true,
    [COMMUNITY_ROLES.ADMIN]: true,
    [COMMUNITY_ROLES.MEMBER]: true,
    [COMMUNITY_ROLES.BLOCKED]: true,
  });
  const [usersToGroups, setUsersToGroups] = useState<{
    [key: string]: Group[];
  }>({});
  const { showInfoPopup } = useInfoPopup();

  const debouncedValue = useDebounce(searchValue, 500);

  const handleListScroll = debounce(() => {
    if (memberListWrapperRef?.current) {
      const scrollTop = memberListWrapperRef.current.scrollTop;
      const documentHeight =
        memberListWrapperRef.current.getBoundingClientRect()?.height;
      const newRowsToShow = Math.floor((scrollTop + documentHeight) / 50) + 4;
      if (newRowsToShow > rowsToShow) {
        setRowsToShow(newRowsToShow);
      }
    }
  }, 150);

  useEffect(() => {
    handleListScroll();
    setInitialUsersRendered(true);

    return () => {
      dispatch(doClearCommunityUsersGroups());
    };
  }, []);

  useEffect(() => {
    memberListWrapperRef?.current?.addEventListener('scroll', handleListScroll);

    return () =>
      memberListWrapperRef?.current?.removeEventListener(
        'scroll',
        handleListScroll,
      );
  }, [rowsToShow, memberListWrapperRef]);

  useEffect(() => {
    const filteredUsers = users.slice(0, rowsToShow);

    const userIdsWithoutGroups = filteredUsers
      .filter((u) => !u.groups && !userGroupsLoading.includes(u.id))
      .map((u) => u.id);

    if (userIdsWithoutGroups.length > 0)
      dispatch(
        doGetCommunityUserGroups({
          communityId: community.id,
          userIds: userIdsWithoutGroups,
        }),
      );
  }, [rowsToShow, users]);

  useEffect(() => {
    if (initialUsersRendered) {
      const closedRoleGroups = Object.keys(tableGroupsState).filter(
        (k) => !tableGroupsState[k],
      );
      let filteredUsers = owners
        .concat(admins)
        .concat(members)
        .concat(blockedMembers)
        .filter((u) => !closedRoleGroups.includes(u.communityPermission?.role));

      if (debouncedValue) {
        filteredUsers = filteredUsers.filter(
          (user) =>
            getProfileName(user.profile)
              .toLowerCase()
              .includes(debouncedValue) ||
            user.profile?.email.toLowerCase().includes(debouncedValue),
        );
      }

      setUsers(filteredUsers);
    }
  }, [tableGroupsState, community.users]);

  useEffect(() => {
    const usersToGroups = {};

    community.users.map((u) => {
      if (u.groups) {
        usersToGroups[u.id] = u.groups;
      }
    });

    setUsersToGroups(usersToGroups);
  }, [community.users]);

  useEffect(() => {
    if (debouncedValue) {
      const filteredUsers = initialUsersList.filter(
        (user) =>
          getProfileName(user.profile).toLowerCase().includes(debouncedValue) ||
          user.profile?.email.toLowerCase().includes(debouncedValue),
      );

      setUsers(filteredUsers);

      const userIdsWithoutGroups = filteredUsers
        .filter((u) => !u.groups && !userGroupsLoading.includes(u.id))
        .map((u) => u.id);

      if (userIdsWithoutGroups.length > 0)
        dispatch(
          doGetCommunityUserGroups({
            communityId: community.id,
            userIds: userIdsWithoutGroups,
          }),
        );
    } else {
      setUsers(owners.concat(admins).concat(members).concat(blockedMembers));
    }
  }, [debouncedValue]);

  const handleGroupToggle = (type: COMMUNITY_ROLES) => {
    setTableGroupsState({
      ...tableGroupsState,
      [type]: !tableGroupsState[type],
    });
  };

  const handleGroupsChange = (
    groups: { [p: string]: any }[],
    prevGroups: { [p: string]: any }[],
    user: CommunityUser,
  ) => {
    const groupsIds = groups.map((group) => group.value);
    const prevGroupsIds = prevGroups.map((group) => group.value);

    if (groupsIds.length > prevGroupsIds.length) {
      const groupId = groupsIds.filter((id) => !prevGroupsIds.includes(id))[0];

      dispatch(
        doAddMemberToGroup({
          communityId: community.id,
          groupId,
          user,
          data: { users: [user.id] },
          onSuccess: () => {},
          onFailure: () => {},
        }),
      );
    } else {
      const groupId = prevGroupsIds.filter((id) => !groupsIds.includes(id))[0];

      dispatch(
        doRemoveMemberFromGroup({
          communityId: community.id,
          groupId,
          user,
          onSuccess: () => {},
          onFailure: () => {},
        }),
      );
    }
  };

  const handleRoleChange = (
    role: { [p: string]: any },
    user: CommunityUser,
  ) => {
    dispatch(
      doChangeUserRole({
        communityId: community.id,
        userId: user.id,
        data: { role: role.value },
        onSuccess: () => {},
        onFailure: () => {},
      }),
    );
  };

  const onSuccessUserDelete = (userId: string) => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title: t('communitySettings.removeUserSuccess.title'),
      message: t('communitySettings.removeUserSuccess.message'),
      noButton: true,
      duration: 4000,
      loop: false,
    });

    if (currentUser.userId === userId) {
      navigate('/profile');
    }
  };

  const handleUserDelete = (user: CommunityUser) => {
    dispatch(
      doDeleteCommunityUser({
        communityId: community.id,
        userIds: [user.id],
        onSuccess: onSuccessUserDelete,
      }),
    );
    setUsers(users.filter((u) => u.id !== user.id));
  };

  const getCommunityMembersForCSV = async (communityId: string) => {
    setCSVLoader(true);
    const users = await getMembersDetails(communityId);
    setCSVLoader(false);
    setCommunityMembersForCSV(users);
  };

  return (
    <Members
      memberListWrapperRef={memberListWrapperRef}
      tableGroupsState={tableGroupsState}
      users={users}
      community={community}
      currentUser={currentUser}
      searchValue={searchValue}
      onGroupToggle={handleGroupToggle}
      onGroupsChange={handleGroupsChange}
      onRoleChange={handleRoleChange}
      onUserDelete={handleUserDelete}
      setSearchValue={setSearchValue}
      usersToGroups={usersToGroups}
      rowsToShow={rowsToShow}
      exportCSVData={exportCSVData}
      csvLoader={csvLoader}
      onExportMembers={getCommunityMembersForCSV}
    />
  );
};

export default RolesContainer;
