import React, { FC } from 'react';

import {
  StyledButton,
  StyledIcon,
  UnreadNotificationIndicator,
} from './styled';

interface NotificationsButtonProps {
  unreadNotificationsCount: number;
}

const NotificationsButton: FC<NotificationsButtonProps> = ({
  unreadNotificationsCount,
}) => (
  <StyledButton type="button">
    <StyledIcon />
    {unreadNotificationsCount ? (
      <UnreadNotificationIndicator>
        {unreadNotificationsCount > 9 ? '9+' : unreadNotificationsCount}
      </UnreadNotificationIndicator>
    ) : null}
  </StyledButton>
);

export default NotificationsButton;
