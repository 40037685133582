import React, { useState, useEffect, useRef, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

import { DatePickerControlled, Tooltip, Button } from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import { CSVIcon } from 'static';
import { STATISTICS_TYPES } from 'configs';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getEventTypes } from 'store/selectors/eventTypes';
import {
  getCurrentGroup,
  getGroupAdmins,
  getGroupLeaders,
  getGroupMembers,
} from 'store/selectors/currentGroup';
import { getSettings } from 'store/selectors/auth';
import { getEventResponsesForScheduler } from 'store/selectors/eventResponses';
import { SchedulerFilter } from 'types';

import { Container, StyledDateWrapper } from './styled';

interface StatisticsFilterProps {
  type: STATISTICS_TYPES;
  filters: SchedulerFilter;
  gridData: any[];
  setFilters: (values: SchedulerFilter) => void;
  setType: (type: STATISTICS_TYPES) => void;
}

const StatisticsFilter: FC<StatisticsFilterProps> = ({
  type,
  filters,
  gridData,
  setFilters,
  setType,
}) => {
  const { t } = useTranslation();
  const csvLink = useRef<CSVLink>();
  const [csvData, setCsvData] = useState([]);
  const community = useSelector(getCurrentCommunity);
  const eventTypes = useSelector(getEventTypes) || [];
  const currentGroup = useSelector(getCurrentGroup);
  const adminMembers = useSelector(getGroupAdmins) || [];
  const leaders = useSelector(getGroupLeaders) || [];
  const members = useSelector(getGroupMembers) || [];
  const settings = useSelector(getSettings);
  const responses = useSelector(getEventResponsesForScheduler) || [];
  const userLanguage = settings?.language || 'en';

  const eventTypesValues = [{ value: '', label: t('common.all') }].concat(
    eventTypes.map(({ id, title }) => ({
      value: id,
      label: title,
    })),
  );

  const tableTypeOptions = Object.values(STATISTICS_TYPES).map((value) => ({
    value,
    label: t(`statistics.${value}`),
  }));

  const headersProfileData = [
    { label: t('profile.profileName'), key: 'profileName' },
    { label: t('profile.email'), key: 'email' },
    { label: t('profile.firstName'), key: 'firstName' },
    { label: t('profile.lastName'), key: 'lastName' },
    { label: t('profile.dateOfBirth'), key: 'dob' },
  ];

  const headersReplies = [
    ...headersProfileData,
    ...responses.map(({ title, id }) => {
      if (id === 'attended') {
        title = t('event.nomination');
      }

      if (id === 'penalty') {
        title = t('event.penalty');
      }

      return { label: title, key: id };
    }),
  ];

  const headersTypes = [
    ...headersProfileData,
    ...eventTypes.map(({ title, id }) => ({ label: title, key: id })),
  ];

  useEffect(() => {
    setCsvData(
      gridData
        .filter((x) => x.type === 'statistics')
        .map((user) => {
          const userInfo =
            community?.overallScheduler?.users?.find((u) => u.id === user.id) ||
            adminMembers?.find((m) => m.id === user.id) ||
            members?.find((m) => m.id === user.id) ||
            leaders?.find((m) => m.id === user.id);

          if (!userInfo) {
            return null;
          }

          if (type === STATISTICS_TYPES.EVENT_REPLIES) {
            const responseData = {};

            user.values.forEach((v) => (responseData[v.responsesId] = v.text));

            return {
              profileName: userInfo.profile.profileName,
              email: userInfo.profile.email,
              firstName: userInfo.profile.firstName,
              lastName: userInfo.profile.lastName,
              dob: userInfo.profile.dob
                ? moment(userInfo.profile.dob)
                    .tz(userInfo.profile.timeZone || 'Europe/Zurich')
                    .format('YYYY-MM-DD')
                : '-',
              ...responseData,
            };
          }

          const typesData = {};

          user.values.forEach(
            (v, ind) => (typesData[eventTypes[ind]?.id] = v.text),
          );

          return {
            profileName: userInfo.profile.profileName,
            email: userInfo.profile.email,
            firstName: userInfo.profile.firstName,
            lastName: userInfo.profile.lastName,
            dob: userInfo.profile.dob
              ? moment(userInfo.profile.dob)
                  .tz(userInfo.profile.timeZone || 'Europe/Zurich')
                  .format('YYYY-MM-DD')
              : '-',
            ...typesData,
          };
        })
        .filter((i) => !!i),
    );
  }, [gridData]);

  const onExportStatisticsClick = async () => {
    csvLink.current.link.click();
  };

  const getCSVName = useCallback(() => {
    return `${community.name}${
      currentGroup ? ' - ' + currentGroup.name : ''
    } (${moment(filters.from)
      .locale(userLanguage)
      .format('MMMM DD YYYY')} - ${moment(filters.to)
      .locale(userLanguage)
      .format('MMMM DD YYYY')}). ${t('event.eventType')} - ${
      filters.eventType.label
    }.csv`;
  }, [community, currentGroup, filters.eventType, userLanguage]);

  const handleFromChange = (v) => {
    setFilters({
      ...filters,
      from: v,
    });
  };

  const handleToChange = (v) => {
    setFilters({
      ...filters,
      to: v,
    });
  };

  const handleEventTypeChange = (v) => {
    setFilters({
      ...filters,
      eventType: v,
    });
  };

  return (
    <Container>
      <StyledDateWrapper>
        <SelectInputControlled
          label={t('statistics.tableType')}
          width="100%"
          options={tableTypeOptions}
          value={{ value: type, label: t(`statistics.${type}`) }}
          onChange={(v) => setType(v.value)}
        />
      </StyledDateWrapper>
      <StyledDateWrapper>
        <DatePickerControlled
          label={t('common.from')}
          date={filters.from}
          maxDate={filters.to}
          handleChange={handleFromChange}
        />
      </StyledDateWrapper>
      <StyledDateWrapper>
        <DatePickerControlled
          label={t('common.to')}
          date={filters.to}
          minDate={filters.from}
          handleChange={handleToChange}
        />
      </StyledDateWrapper>
      <StyledDateWrapper>
        <SelectInputControlled
          label={t('event.eventType')}
          width="100%"
          options={eventTypesValues}
          value={filters.eventType}
          onChange={handleEventTypeChange}
        />
      </StyledDateWrapper>
      <StyledDateWrapper>
        <Tooltip
          text={t('community.exportStatisticsTooltip')}
          zoom={1}
          isVisible
        >
          <div>
            <Button
              variant="secondary"
              IconComponent={CSVIcon}
              width="325px"
              onClick={() => onExportStatisticsClick()}
            >
              {t('community.exportStatistics')}
            </Button>
            <CSVLink
              data={csvData}
              headers={
                type === STATISTICS_TYPES.EVENT_REPLIES
                  ? headersReplies
                  : headersTypes
              }
              filename={getCSVName()}
              className="hidden"
              target="_blank"
              ref={csvLink}
            />
          </div>
        </Tooltip>
      </StyledDateWrapper>
    </Container>
  );
};

export default StatisticsFilter;
