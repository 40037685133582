import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';

import { useDebounce, useLazyLoading } from 'hooks';
import UserProfileEvents from './UserProfileEvents';
import {
  doGetMyEvents,
  doClearMyEvents,
  doCreateEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
} from 'store/actionCreators/profile';
import { EditEventType, SINGLE_AND_FOLLOW } from 'constants/sidebars';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import {
  getFilteredUnrespondedEventsCount,
  getMyEventsData,
  getMyEventsLoaders,
} from 'store/selectors/events';
import { getFullAuthUser } from 'store/selectors/auth';
import { EventReply, EventResponse, MyAppointmentsEvent } from 'types';
import { ReplyFormData } from './form';
// import { doUpdatetMyEventsList } from 'store/actionCreators/profile';

export type MyEventsFilterValues = {
  from: number | Date;
  key: string | null;
  eventType: { [key: string]: string };
  awaiting?: boolean;
  nomination?: boolean;
  to?: number | Date;
};

export type ScrollButtonsStatus = {
  left: boolean;
  right: boolean;
  disabled?: boolean;
};

const UserProfileEventsContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myEventsData = useSelector(getMyEventsData);
  const currentUser = useSelector(getFullAuthUser);
  const loaders = useSelector(getMyEventsLoaders);
  const [zoom, setZoom] = useLocalStorage<number>(
    'myEventsZoom',
    window.innerHeight > 760 ? 1 : 0.7499999999999998,
  );

  const [search, setSearch] = useState<string | null>(null);
  const [filterValues, setFilterValues] = useState<MyEventsFilterValues>({
    from: moment().valueOf(),
    key: null,
    eventType: { value: 'all', label: t('common.all') },
  });
  const [scrollButtonsStatus, setScrollButtonsStatus] =
    useState<ScrollButtonsStatus>({
      left: false,
      right: false,
      disabled: false,
    });
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const debouncedSearch: string = useDebounce(search, 300);
  const [redirectAfterReplyAll, setRedirectAfterReplyAll] =
    useState<boolean>(false);

  const filteredUnrespondedEventsCount = useSelector(
    getFilteredUnrespondedEventsCount,
  );

  useEffect(() => {
    if (myEventsData.events) {
      setFilterValues({
        ...filterValues,
        key: debouncedSearch,
      });
    }
  }, [debouncedSearch]);

  const scrollHandler = () => {
    const newScreenWidth = window.innerWidth - 360;

    setScreenWidth(newScreenWidth);

    if (myEventsData.events && myEventsData.events.length > 0) {
      const allEventsWidth = myEventsData.events.length * 270 * zoom;

      if (allEventsWidth > newScreenWidth) {
        setScrollButtonsStatus({
          left: listRef.current.scrollLeft > 0,
          right:
            myEventsData.totalCount !== myEventsData.events.length
              ? true
              : allEventsWidth > newScreenWidth,
        });
      } else {
        setScrollButtonsStatus({ left: false, right: false });
      }
    }
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener('resize', scrollHandler);

    return () => {
      window.removeEventListener('resize', scrollHandler);
    };
  }, [myEventsData.events, zoom]);

  const loadEvents = (data) => dispatch(doGetMyEvents(data));

  const clearData = (data) => dispatch(doClearMyEvents(data));

  const convertedFilters = useMemo(() => {
    return { ...filterValues, eventTypeId: filterValues.eventType.value };
  }, [filterValues]);

  const pageSize = 30;

  const { listRef } = useLazyLoading({
    isLoading: loaders.events,
    loader: loadEvents,
    pageSize,
    listLength: myEventsData.events?.length || 0,
    hasNextPage:
      myEventsData.events &&
      myEventsData.events.length < myEventsData.totalCount,
    cleaner: clearData,
    filterValues: convertedFilters,
    avoidCleanup: true,
  });

  const handleZoomChange = (value: number) => setZoom(value);

  const handleReset = () => {
    setFilterValues({
      ...filterValues,
      from: moment().valueOf(),
      eventType: { value: 'all', label: 'All' },
    });
  };

  const handleScrollClick = (positionToScroll) => {
    setScrollButtonsStatus({
      ...scrollButtonsStatus,
      disabled: true,
    });

    const scrollLeft = listRef.current.scrollLeft;
    const newScrollLeft =
      positionToScroll === 'end'
        ? scrollLeft + screenWidth / 2
        : scrollLeft - screenWidth / 2;

    listRef.current.scroll({
      top: 0,
      left: newScrollLeft,
      behavior: 'smooth',
    });

    if (newScrollLeft + screenWidth >= listRef.current.scrollWidth) {
      setTimeout(
        () =>
          setScrollButtonsStatus({
            left: true,
            right: true,
            disabled: false,
          }),
        100,
      );
    } else {
      setTimeout(
        () =>
          setScrollButtonsStatus({
            left: newScrollLeft > 0,
            right: true,
            disabled: false,
          }),
        100,
      );
    }
  };

  const onEventHeaderClick = (event: MyAppointmentsEvent) => {
    if (event.communityId) {
      navigate(
        `${ROUTES.COMMUNITY}/${event.community.id}/${COMMUNITY_ROUTES.OVERALL}/${COMMUNITY_ROUTES.EVENT}/${event.id}/details`,
      );
    } else {
      navigate(
        `${ROUTES.COMMUNITY}/${event.group.community.id}/${COMMUNITY_ROUTES.GROUP}/${event.groupId}/${COMMUNITY_ROUTES.EVENT}/${event.id}/details`,
      );
    }
  };
  const onEventClick = (event: MyAppointmentsEvent) => {
    if (event.communityId) {
      navigate(
        `${ROUTES.COMMUNITY}/${event.community.id}/${COMMUNITY_ROUTES.OVERALL}/${COMMUNITY_ROUTES.EVENT}/${event.id}`,
      );
    } else {
      navigate(
        `${ROUTES.COMMUNITY}/${event.group.community.id}/${COMMUNITY_ROUTES.GROUP}/${event.groupId}/${COMMUNITY_ROUTES.EVENT}/${event.id}`,
      );
    }
  };

  const handleSubmit = (
    type: EditEventType,
    data: ReplyFormData,
    event: MyAppointmentsEvent,
    usersEventReply: {
      reply: EventReply & { tasks: string[] };
      communityResponse: EventResponse;
    },
  ) => {
    if (!data.eventResponseId && usersEventReply.reply?.id) {
      dispatch(
        doDeleteEventReply({
          groupId: event.groupId,
          communityId: event.communityId,
          eventId: event.id,
          userId: currentUser.id,
          eventReplyId: usersEventReply.reply.id,
          eventSeriesId: event.eventSeriesId,
          type,
          from: type === EditEventType.SINGLE_AND_FOLLOW ? event.from : null,
          filters: filterValues,
        }),
      );

      return;
    }

    if (usersEventReply.reply?.id) {
      dispatch(
        doUpdateEventReply({
          data: {
            ...usersEventReply.reply,
            ...data,
          },
          groupId: event.groupId,
          communityId: event.communityId,
          eventId: event.id,
          user: currentUser,
          eventReplyId: usersEventReply.reply.id,
          eventSeriesId: event.eventSeriesId,
          type,
          from: type === SINGLE_AND_FOLLOW ? event.from : null,
        }),
      );
    } else {
      dispatch(
        doCreateEventReply({
          data: {
            ...usersEventReply.reply,
            ...data,
            date: new Date(event.from).toISOString(),
          },
          groupId: event.groupId,
          communityId: event.communityId,
          eventId: event.id,
          user: currentUser,
          eventSeriesId: event.eventSeriesId,
          type,
          from: type === SINGLE_AND_FOLLOW ? event.from : null,
          filters: filterValues,
          unrespondedCount: filteredUnrespondedEventsCount,
        }),
      );
      if (filterValues.awaiting && !redirectAfterReplyAll)
        setRedirectAfterReplyAll(true);
    }
  };

  useEffect(() => {
    if (redirectAfterReplyAll) setRedirectAfterReplyAll(false);
  }, [filterValues]);

  return (
    <UserProfileEvents
      data={myEventsData}
      loaders={loaders}
      listRef={listRef}
      currentUser={currentUser}
      zoom={zoom}
      filterValues={filterValues}
      search={search}
      scrollButtonsStatus={scrollButtonsStatus}
      handleScroll={handleScrollClick}
      setFilterValues={setFilterValues}
      onZoomChange={handleZoomChange}
      setSearch={setSearch}
      onReset={handleReset}
      onSubmit={handleSubmit}
      onEventHeaderClick={onEventHeaderClick}
      onEventClick={onEventClick}
      shouldRedirect={redirectAfterReplyAll}
    />
  );
};

export default UserProfileEventsContainer;
