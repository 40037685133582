export enum CellsTypes {
  DRAGNDROP = 'dragndrop',
  PLACEHOLDER = 'placeholder',
  AVATAR_NAME = 'avatar_name',
  TASK = 'task',
  TASK_WITH_SELECT = 'task_with_select',
  DOCUMENT = 'document',
  TEXT = 'text',
  ARRAY = 'array',
  TEXT_WITH_SPOT = 'text_with_spot',
  EDIT = 'edit',
  DELETE = 'delete',
  EDIT_DELETE = 'edit_delete',
}

export enum DndTypes {
  ROW = 'row',
}

export enum ReplyStates {
  UPDATE_THIS = 'updateThis',
  UPDATE_ALL = 'updateAll',
  CREATE = 'create',
  VIEW = 'view',
}

export enum CreateEventStates {
  EVENT_CREATE = 'create',
  EVENT_UPDATE = 'update',
}
