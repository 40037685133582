import styled from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoints } from 'theme';

export const StyledSectionWrapper = styled.div``;

export const StyledListWrapper = styled.div`
  overflow: auto;
  height: calc(100svh - 238px);
  @media ${breakpoints.downMdPlus} {
    height: calc(100svh - 289px);
  }
  @media ${breakpoints.downMd} {
    height: calc(100svh - 277px);
  }
  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 242px);
  }
  @media (max-width: 500px) {
    height: calc(100svh - 294px);
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

export const StyledHeaderRow = styled.tr`
  background-color: #ebf2f7;
  color: #8298ab;
  padding-left: 25px;
  font-size: 12px;
  height: 30px;
`;

export const StyledEmail = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
`;

export const StyledHeaderRowToggler = styled.span`
  margin-right: 10px;
  cursor: pointer;

  svg {
    transform: rotate(${({ isOpen }) => (!isOpen ? -90 : 0)}deg);
  }
`;

export const StyledRow = styled.tr`
  font-weight: normal;
  font-size: 14px;
  height: 59px;
  border-bottom: 1px solid #ebf2f7;
`;

export const StyledCell = styled.td`
  vertical-align: middle;
  text-align: left;

  &:nth-of-type(1) {
    width: 3%;
    min-width: 50px;
    text-align: center;

    @media ${breakpoints.downMdPlus} {
      width: 50px;
    }
  }

  &:nth-of-type(2) {
    width: 20%;
    padding: 1%;

    @media ${breakpoints.downMdPlus} {
      width: 220px;
    }
  }

  &:nth-of-type(3) {
    width: 20%;

    @media ${breakpoints.downMdPlus} {
      width: 240px;
    }
  }

  &:nth-of-type(4) {
    width: 15%;

    @media ${breakpoints.downMdPlus} {
      width: 140px;
    }
  }

  &:nth-of-type(5) {
    width: 20%;

    @media ${breakpoints.downMdPlus} {
      width: 270px;
    }
  }

  &:nth-of-type(6) {
    width: 10%;

    div {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        color: ${({ theme }) => theme.color.baliHai};
        font-weight: 600;
        border-bottom: 1px solid ${({ theme }) => theme.color.baliHai};
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span {
        color: ${({ theme }) => theme.color.baliHai};
        border: 1px solid ${({ theme }) => theme.color.baliHai};
        border-radius: 50%;
        width: 15px;
        min-width: 15px;
        height: 15px;
        line-height: 15px;
        font-size: 9px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
    }

    @media ${breakpoints.downMdPlus} {
      width: 120px;
    }
  }

  &:nth-of-type(7) {
    width: 10%;

    @media ${breakpoints.downMdPlus} {
      width: 100px;
    }
  }
`;

export const StyledProfileCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButtonsBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80px;
  margin: auto;
`;

export const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;

export const StyledSearchWrapper = styled.div`
  margin-right: 24px;

  @media ${breakpoints.downMdPlus} {
    margin-right: 0;
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  & > div,
  & > button {
    width: 225px;
    height: 40px;
  }

  @media ${breakpoints.downMdPlus} {
    flex-wrap: wrap;
    gap: 12px 0;
    justify-content: space-around;
    padding: 20px 0;

    & > div,
    & > button {
      width: 46%;
      margin: 0 2%;
    }
  }

  @media (max-width: 500px) {
    & > div,
    & > button {
      width: 94%;
    }
  }
`;

export const StyledSelectLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledAddMember = styled.div`
  position: relative;
  margin-right: 24px;

  @media ${breakpoints.downMdPlus} {
    margin-right: 0;
  }
`;

export const StyledExportMembers = styled.div``;

export const StyledAddMemberPopupWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000030;
  z-index: 100000000;
`;

export const StyledAddMemberPopup = styled(motion.div)`
  background: ${({ theme }) => theme.color.blackSqueeze};
  padding: 24px;
  border-radius: 8px;
  width: 1300px;
  min-width: 1300px;
  max-height: calc(100svh - 100px);

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }

  @media ${breakpoints.downLg} {
    width: 90%;
    min-width: auto;
  }

  @media ${breakpoints.downMd} {
    width: 94%;
    max-height: calc(100svh - 200px);
    margin-top: -60px;
  }
`;

StyledAddMemberPopup.Header = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-bottom: 1px solid ${({ theme }) => theme.color.pickledBluewood};
  padding-bottom: 16px;
  margin-bottom: 16px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 6px;
  }

  a {
    color: ${({ theme }) => theme.color.brightTurquoise};
    text-decoration: none;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

StyledAddMemberPopup.Card = styled.div`
  background: white;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
`;

StyledAddMemberPopup.Card.Main = styled.div`
  display: flex;
  margin-bottom: 24px;
  position: relative;

  .btn-primary,
  .btn-secondary {
    @media ${breakpoints.downMd} {
      width: 100%;
      magin-top: 10px;
    }
  }

  .btn-secondary {
    color: ${({ theme }) => theme.color.pickledBluewood};
    border-color: ${({ theme }) => theme.color.pickledBluewood};
  }

  & > svg {
    width: 36px;
    height: 36px;
    margin-right: 16px;

    @media ${breakpoints.downMd} {
      display: none;
    }
  }

  & > div {
    position: relative;

    @media ${breakpoints.downMd} {
      position: static;
      width: 100%;
    }
  }

  h3 {
    color: ${({ theme }) => theme.color.pickledBluewood};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 10px;
  }

  @media ${breakpoints.downMd} {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
`;

StyledAddMemberPopup.Card.Toggler = styled.span`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 40px;
  color: ${({ theme }) => theme.color.brightTurquoise};

  svg {
    width: 7px;
    height: auto;
    margin-right: 8px;
    transform: rotate(${({ selected }) => (selected ? 90 : 270)}deg);
    transition: transform 0.3s;
  }

  @media ${breakpoints.downMd} {
    position: static;
    margin-top: 16px;
  }
`;

StyledAddMemberPopup.Card.Description = styled(motion.div)`
  display: flex;

  & > div {
    width: calc(50% - 52px);
    margin-left: 52px;

    h4 {
      font-weight: 600;
      font-size: 16px;
      color: ${({ theme }) => theme.color.pickledBluewood};
      text-align: left;
      margin-bottom: 8px;
      position: relative;

      &:before {
        content: '';
        width: 12px;
        height: 12px;
        background: ${({ theme }) => theme.color.pickledBluewood};
        position: absolute;
        left: -26px;
        top: 5px;
        border-radius: 3px;
      }
    }

    p {
      color: ${({ theme }) => theme.color.baliHai};
    }

    ol {
      margin-left: 18px;
    }
  }

  @media ${breakpoints.downMd} {
    flex-direction: column;

    & > div {
      width: calc(100% - 48px);
      margin: 10px 24px;
    }
  }
`;

export const StyledRenewCode = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 370px;
  width: 100%;
  cursor: pointer;
  transition: color 0.3s;

  svg {
    width: 16px;
    margin-right: 10px;

    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
      transition: fill 0.3s;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.brightTurquoise};

    svg {
      path {
        fill: ${({ theme }) => theme.color.brightTurquoise};
      }
    }
  }

  @media ${breakpoints.downMd} {
    position: static;
    margin-top: 16px;
  }
`;
