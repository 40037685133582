import { createSelector } from 'reselect';
import { find } from 'lodash/fp';

import { IRootState } from '../reducers';
import { getCurrentCommunity, getOverallScheduler } from './currentCommunity';
import { EventsState } from '../reducers/events';

const selectEventId = (state, eventId) => eventId;
const getItemById = (items, id) => find({ id }, items);

export const myEventsSelector = (state: IRootState): EventsState =>
  state.events;

export const currentCommunitySelector = (state: IRootState) =>
  state.currentCommunity;

export const getEvents = createSelector(
  getCurrentCommunity,
  (community) => community.events || null,
);

export const getMyEventsData = createSelector(
  myEventsSelector,
  (events) => events.data || {},
);

export const getUnrespondedEventsCount = createSelector(
  myEventsSelector,
  (events) => events.data?.unrespondedEventsCount || 0,
);

export const getFilteredUnrespondedEventsCount = createSelector(
  myEventsSelector,
  (events) => events?.data?.filteredUnrespondedEventsCount,
);

export const getOverallEvents = createSelector(
  getOverallScheduler,
  (community) => community?.events || null,
);

export const getOverallEventsTotalCount = createSelector(
  getOverallScheduler,
  (community) => community.totalCountEvents,
);

export const getGroupEventsTotalCount = createSelector(
  getCurrentCommunity,
  (community) => community.totalCountEvents,
);

export const getEvent = createSelector([getEvents, selectEventId], getItemById);

export const getOverallEvent = createSelector(
  [getOverallEvents, selectEventId],
  getItemById,
);

export const getRequestEventsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.requestEvents,
);

export const getOverallEventsLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.overallEvents,
);

export const getCreateEventLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.createEvent,
);

export const getDeleteEventLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.deleteEvent,
);

export const getMyEventsLoaders = createSelector(
  myEventsSelector,
  (events) => events.loaders,
);
