import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

export enum FIELDS {
  PROFILE_VISIBLE = 'profileVisible',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PROFILE_NAME = 'profileName',
  IMAGE = 'logo',
  DATE_OF_BIRTH = 'dob',
  ABOUT = 'description',
  PRIVATE_PHONE_NUMBER = 'privatePhoneNumber',
  OFFICE_PHONE_NUMBER = 'officePhoneNumber',
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  STREET = 'street',
  REGION = 'region',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  COUNTRY = 'country',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  YOUTUBE = 'youtube',
  INSTAGRAM = 'instagram',
}

export type ProfileFormData = {
  [FIELDS.PROFILE_VISIBLE]: boolean;
  [FIELDS.FIRST_NAME]: string;
  [FIELDS.LAST_NAME]: string;
  [FIELDS.PROFILE_NAME]: string;
  [FIELDS.STREET]: string;
  [FIELDS.POSTAL_CODE]: string;
  [FIELDS.REGION]: string;
  [FIELDS.CITY]: string;
  [FIELDS.DATE_OF_BIRTH]: Date;
  [FIELDS.PRIVATE_PHONE_NUMBER]: string;
  [FIELDS.OFFICE_PHONE_NUMBER]: string;
  [FIELDS.MOBILE_PHONE_NUMBER]: string;
  [FIELDS.ABOUT]: string;
  [FIELDS.FACEBOOK]: string;
  [FIELDS.TWITTER]: string;
  [FIELDS.LINKEDIN]: string;
  [FIELDS.INSTAGRAM]: string;
  [FIELDS.YOUTUBE]: string;
};

const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }

  return value;
};

export const useEditProfileForm = (defaultValues: ProfileFormData) => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const validatePhoneNumber = () =>
    yup
      .string()
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, {
        message: 'must be valid number',
        excludeEmptyString: true,
      })
      .nullable();

  const resolver = yupResolver<ProfileFormData>(
    yup.object().shape({
      [FIELDS.PROFILE_VISIBLE]: yup.boolean(),
      [FIELDS.FIRST_NAME]: yup.string().max(20).trim().required(),
      [FIELDS.LAST_NAME]: yup.string().max(20).trim().required(),
      [FIELDS.PROFILE_NAME]: yup.string().max(20).trim().required(),
      [FIELDS.STREET]: yup.string().max(200).trim(),
      [FIELDS.POSTAL_CODE]: yup
        .number()
        .positive()
        .transform(emptyStringToNull)
        .nullable(),
      [FIELDS.REGION]: yup.string().max(200).trim(),
      [FIELDS.CITY]: yup.string().max(200).trim(),
      [FIELDS.PRIVATE_PHONE_NUMBER]: yup.string().max(50).trim(),
      [FIELDS.OFFICE_PHONE_NUMBER]: yup.string().max(50).trim(),
      [FIELDS.MOBILE_PHONE_NUMBER]: yup.string().max(50).trim(),
      [FIELDS.ABOUT]: yup.string().max(1000).trim().nullable(),
      [FIELDS.FACEBOOK]: yup.string().max(200).trim(),
      [FIELDS.TWITTER]: yup.string().max(200).trim(),
      [FIELDS.LINKEDIN]: yup.string().max(200).trim(),
      [FIELDS.YOUTUBE]: yup.string().max(200).trim(),
      [FIELDS.INSTAGRAM]: yup.string().max(200).trim(),
    }),
  );

  return useForm<ProfileFormData>({
    resolver,
    defaultValues,
  });
};
