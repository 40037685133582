import { v4 as uuidv4 } from 'uuid';
import { StreamChat } from 'stream-chat';

import { axiosInstance as axios } from './index';

export const getUserToken = async (id) => {
  const {
    data: { token },
  } = await axios.post('/chats/token', { userId: id }, {});
  return token;
};

export const createChatRequest = async ({
  client,
  communityId,
  chatName,
  members,
}: {
  client: StreamChat;
  communityId: string;
  chatName: string;
  members: string[];
}) => {
  const conversation = client.channel('messaging', uuidv4(), {
    name: chatName,
    community: communityId,
    members,
  });

  return conversation.create();
};

export const getCommunityChats = async ({
  communityId,
  client,
}: {
  communityId: string;
  client: StreamChat;
}) => {
  const filter = { community: communityId };
  const channels = await client.queryChannels(
    filter,
    { last_message_at: -1 },
    {
      watch: true,
      state: true,
      limit: 30,
      message_limit: 300,
    },
  );

  return channels;
};
