import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupButtonMessage, HeaderEditButton } from 'components';

import {
  ButtonsContainer,
  Headline,
  LogoutButton,
  StyledLogoutIcon,
  HeaderContainer,
} from './styled';

const Header = ({ onLogOut, openEditSidebar }) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <Headline>{t('profile.myProfile')}</Headline>
      <ButtonsContainer>
        <HeaderEditButton
          title={t('profile.editProfile')}
          openEditSidebar={openEditSidebar}
        />
        <PopupButtonMessage
          ButtonComponent={LogoutButton}
          IconComponent={StyledLogoutIcon}
          onConfirm={onLogOut}
          popupButtonText={t('common.logout')}
          popupHeaderText={t('common.logoutHeader')}
          popupMessageText={t('common.logoutMessage')}
          size="lg"
        />
      </ButtonsContainer>
    </HeaderContainer>
  );
};

export default Header;
