// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { handleActions } from 'redux-actions';
import { assoc, compose } from 'lodash/fp';

import {
  doClearMemberFiguresStatisticsTotal,
  doClearStatistics,
  doGetAppointmentScheduleStatistics,
  doGetAppointmentScheduleStatisticsTotal,
  doGetMemberFiguresStatistics,
  doGetMemberFiguresStatisticsTotal,
} from 'store/actionCreators/statistics';
import {
  AppointmentScheduleStatistics,
  AppointmentScheduleStatisticsTotal,
  MemberFiguresStatistics,
} from 'types';

type CurrentCommunityStateLoaders = {
  getAppointmentScheduleStatistics: boolean;
  getAppointmentScheduleStatisticsTotal: boolean;
  getMemberFiguresStatistics: boolean;
  getMemberFiguresStatisticsTotal: boolean;
};

export interface StatisticsState {
  data: {
    appointmentScheduleStatistics: AppointmentScheduleStatistics;
    appointmentScheduleStatisticsTotal: AppointmentScheduleStatisticsTotal;
    memberFiguresStatistics: MemberFiguresStatistics;
    memberFiguresStatisticsTotal: any;
  };
  errors: any;
  loaders: CurrentCommunityStateLoaders;
}

const initialState: StatisticsState = {
  data: {
    appointmentScheduleStatistics: null,
    appointmentScheduleStatisticsTotal: null,
    memberFiguresStatistics: null,
    memberFiguresStatisticsTotal: null,
  },
  errors: {},
  loaders: {
    getAppointmentScheduleStatistics: false,
    getAppointmentScheduleStatisticsTotal: false,
    getMemberFiguresStatistics: false,
    getMemberFiguresStatisticsTotal: false,
  },
};

export default handleActions<StatisticsState, any>(
  {
    [doGetAppointmentScheduleStatistics.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getAppointmentScheduleStatistics'], true))(
        state,
      ),
    [doGetAppointmentScheduleStatistics.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getAppointmentScheduleStatistics'], false),
        assoc(['data', 'appointmentScheduleStatistics'], payload),
      )(state),
    [doGetAppointmentScheduleStatistics.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getAppointmentScheduleStatistics'], false),
        assoc(['errors', 'getAppointmentScheduleStatistics'], payload.error),
      )(state),
    [doGetAppointmentScheduleStatisticsTotal.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'getAppointmentScheduleStatisticsTotal'], true),
      )(state),
    [doGetAppointmentScheduleStatisticsTotal.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getAppointmentScheduleStatisticsTotal'], false),
        assoc(['data', 'appointmentScheduleStatisticsTotal'], payload),
      )(state),
    [doGetAppointmentScheduleStatisticsTotal.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getAppointmentScheduleStatisticsTotal'], false),
        assoc(
          ['errors', 'getAppointmentScheduleStatisticsTotal'],
          payload.error,
        ),
      )(state),
    [doGetMemberFiguresStatistics.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'getMemberFiguresStatistics'], true))(state),
    [doGetMemberFiguresStatistics.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getMemberFiguresStatistics'], false),
        assoc(['data', 'memberFiguresStatistics'], payload),
      )(state),
    [doGetMemberFiguresStatistics.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getMemberFiguresStatistics'], false),
        assoc(['errors', 'getMemberFiguresStatistics'], payload.error),
      )(state),
    [doGetMemberFiguresStatisticsTotal.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'getMemberFiguresStatisticsTotal'], true),
        assoc(['data', 'memberFiguresStatisticsTotal'], null),
      )(state),
    [doGetMemberFiguresStatisticsTotal.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getMemberFiguresStatisticsTotal'], !payload.loaded),
        assoc(
          ['data', 'memberFiguresStatisticsTotal'],
          state.data.memberFiguresStatisticsTotal?.data
            ? {
                data: [
                  ...state.data.memberFiguresStatisticsTotal.data,
                  ...payload.data.data,
                ],
              }
            : payload.data,
        ),
      )(state),
    [doGetMemberFiguresStatisticsTotal.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'getMemberFiguresStatisticsTotal'], false),
        assoc(['errors', 'getMemberFiguresStatisticsTotal'], payload.error),
      )(state),
    [doClearStatistics.TRIGGER]: (state) =>
      compose(
        assoc(['data', 'memberFiguresStatistics'], null),
        assoc(['data', 'memberFiguresStatisticsTotal'], null),
        assoc(['data', 'appointmentScheduleStatistics'], null),
        assoc(['data', 'appointmentScheduleStatisticsTotal'], null),
      )(state),
    [doClearMemberFiguresStatisticsTotal.TRIGGER]: (state) =>
      compose(assoc(['data', 'memberFiguresStatisticsTotal'], null))(state),
  },
  initialState,
);
