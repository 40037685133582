import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import {
  Button,
  Loader,
  PopupEventWrapper,
  Sidebar,
  SidebarFooter,
  SidebarHeader,
  TabForm,
  TextInput,
} from 'components';
import { CreateEventStates } from 'constants/index';
import { EditEventType } from 'constants/sidebars';
import EventTab from './Event';
import ParticipantsTab from './Participants';
import TasksTab from './Tasks';
import DocumentsTab from './Documents';
import { useSubmitMainInfo } from './hooks';
import { getUpdatedFields, isNotifyPopupDisabled } from 'utils';
import { getEventRequestBody } from './utils';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getData } from 'store/selectors/modal';
import { closeModal } from 'store/actionCreators/modal';
import { getCreateEventLoading } from 'store/selectors/events';
import { doGetCommunityMembersByGroups } from 'store/actionCreators/currentCommunity';
import { breakpoints } from 'theme';
import { CreateEventSidebarData } from 'types';
import { useClickOutsideClose } from 'hooks';

const CreateEventOverall = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDownMd = useMedia(breakpoints.downMd);
  const [notifyPopup, setNotifyPopup] = useState<{
    isOpen: boolean;
    type?: EditEventType;
    notifyText?: string;
    notify?: boolean;
  }>({
    isOpen: false,
  });
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getCreateEventLoading);
  const modalData = useSelector(getData) as CreateEventSidebarData;
  const { filterFrom, filterTo, event, groupId } = modalData;
  const refModal = React.createRef<HTMLDivElement>();

  const sidebarState: CreateEventStates = event
    ? CreateEventStates.EVENT_UPDATE
    : CreateEventStates.EVENT_CREATE;
  const sidebarLabel =
    sidebarState === CreateEventStates.EVENT_CREATE
      ? t('event.createEvent')
      : t('event.updateEvent');

  useEffect(() => {
    if (!groupId) {
      dispatch(
        doGetCommunityMembersByGroups({
          communityId: community.id,
          withoutBlocked: true,
        }),
      );
    }
  }, []);

  const closeSidebar = () => dispatch(closeModal());

  const {
    onSubmit,
    register,
    trigger,
    errors,
    getValues,
    recipientsFieldProps,
    eventResponseFieldProps,
    tasksFieldProps,
    documentsFieldProps,
    weekDaysFieldProps,
    control,
    watchedValues,
    setValue,
  } = useSubmitMainInfo({
    event,
    closeSidebar,
    filterFrom,
    filterTo,
    sidebarState,
    communityId: community.id,
    groupId,
    community,
  });

  const handleTabChange = async () => {
    const isValid = await trigger();

    if (!isValid) {
      throw new Error();
    }
  };

  const onSubmitClick = async (type: EditEventType) => {
    await handleTabChange();

    const newValues = getValues();
    const updatedFields = getUpdatedFields(
      event,
      getEventRequestBody(newValues),
    );

    const ignoreNotifyPopup = isNotifyPopupDisabled(
      sidebarState,
      event,
      community.notifications,
      updatedFields,
    );

    if (ignoreNotifyPopup) {
      return onSubmit(type, { toNotify: false, notifyText: '' });
    }

    setNotifyPopup({ isOpen: true, type });
  };

  const editPopupButtons = [
    {
      popupButtonText: t('event.eventOperations.eventOnly'),
      handleConfirm: () => onSubmitClick(EditEventType.SINGLE_EVENT),
    },
    {
      popupButtonText: t('event.eventOperations.eventAndFollowUp'),
      handleConfirm: () => onSubmitClick(EditEventType.SINGLE_AND_FOLLOW),
    },
    {
      popupButtonText: t('event.eventOperations.all'),
      handleConfirm: () => onSubmitClick(EditEventType.FULL_EVENT),
    },
  ];

  const buttonStyles = { width: '100%', minWidth: '200px' };

  const popupButtons = [
    {
      popupButtonText: t('communitySettings.notificationPopupBack'),
      buttonStyles,
      variant: 'secondary',
      handleConfirm: () => setNotifyPopup({ isOpen: false }),
    },
    {
      popupButtonText: t('communitySettings.notificationPopupDoNotNotify'),
      buttonStyles,
      handleConfirm: () =>
        onSubmit(notifyPopup.type, { toNotify: false, notifyText: '' }),
    },
    {
      popupButtonText: t('communitySettings.notificationPopupNotify'),
      buttonStyles,
      handleConfirm: () =>
        onSubmit(notifyPopup.type, {
          toNotify: true,
          notifyText: notifyPopup.notifyText,
        }),
    },
  ];

  useClickOutsideClose({ ref: refModal, callback: closeSidebar });

  const buttonContent = isLoading ? (
    <Loader type="button" size="28px" thickness={2} />
  ) : (
    sidebarLabel
  );

  const buttonVariant: 'secondary' | null = isLoading ? 'secondary' : null;

  return community ? (
    <Sidebar ref={refModal}>
      <SidebarHeader label={sidebarLabel} onCloseClick={closeSidebar} />
      {notifyPopup.isOpen && (
        <PopupEventWrapper
          innerComponent={
            <div style={{ width: '100%' }}>
              <TextInput
                width="100%"
                value={notifyPopup.notifyText}
                label={t('communitySettings.notificationPopupMessageLabel')}
                onChange={(e) =>
                  setNotifyPopup({
                    ...notifyPopup,
                    notifyText: e.target.value,
                  })
                }
              />
            </div>
          }
          isOpen={true}
          popupMessageText={t('communitySettings.notificationPopupHeader')}
          popupButtons={popupButtons}
          buttonContainerStyles={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
            marginTop: '30px',
            flexDirection: isDownMd ? 'column' : 'row',
          }}
          popupStyles={{
            width: '94%',
            maxWidth: '670px',
            padding: '20px',
          }}
          onPopupClose={() => setNotifyPopup({ isOpen: false })}
          darkBackground={true}
        />
      )}
      <form>
        <TabForm
          // width="100%"
          isSidebar
        >
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t('event.event')}
            withSubmit
            submitForm={handleTabChange}
            index={0}
          >
            <EventTab
              eventResponseId={event?.defaultEventResponseId}
              editMode={Boolean(
                sidebarState === CreateEventStates.EVENT_UPDATE,
              )}
              register={register}
              errors={errors}
              sidebarState={sidebarState}
              control={control}
              watchedValues={watchedValues}
              weekDaysFieldProps={weekDaysFieldProps}
              setValue={setValue}
              eventResponseFieldProps={eventResponseFieldProps}
            />
          </div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div label={t('common.participants')} index={1}>
            <ParticipantsTab
              recipientsFieldProps={recipientsFieldProps}
              groupId={groupId}
            />
          </div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div label={t('common.tasks')} index={2}>
            <TasksTab tasksFieldProps={tasksFieldProps} />
          </div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div label={t('common.documentation')} iconLabel grow index={3}>
            <DocumentsTab
              documentsFieldProps={documentsFieldProps}
              groupId={groupId}
            />
          </div>
        </TabForm>
        <SidebarFooter>
          {sidebarState === CreateEventStates.EVENT_UPDATE &&
          event?.eventSeriesId ? (
            <PopupEventWrapper
              popupButtons={editPopupButtons}
              popupMessageText={t('event.eventOperations.editText')}
              darkBackground={true}
            >
              <Button
                type="button"
                variant={buttonVariant}
                disabled={isLoading}
              >
                {buttonContent}
              </Button>
            </PopupEventWrapper>
          ) : (
            <Button
              type="button"
              variant={buttonVariant}
              disabled={isLoading}
              onClick={() => onSubmitClick(EditEventType.SINGLE_EVENT)}
            >
              {buttonContent}
            </Button>
          )}
        </SidebarFooter>
      </form>
    </Sidebar>
  ) : null;
};

export default CreateEventOverall;
