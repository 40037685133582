import styled from 'styled-components';

import { UploadFileIcon } from 'static';

export const ImageWrapper = styled.div``;

export const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledFileUploadIcon = styled(UploadFileIcon)`
  width: 23px;
  height: 30px;
`;

export const StyledTextButton = styled.span`
  color: ${({ theme }) => theme.color.brightTurquoise};
  font-size: 14px;
  cursor: pointer;
  font-weight: normal;
`;

export const StyledTipText = styled.span`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 10px;
  font-weight: normal;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 60%;
  text-align: center;
`;
