import styled from 'styled-components';

import { breakpoints } from 'theme';

export const Container = styled.div`
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 30px;
  margin: -7px auto 30px 40px;
  flex-wrap: wrap;

  @media ${breakpoints.downMdPlus} {
    margin: -7px 24px 30px 24px;
  }

  & > div {
    width: 340px;

    @media ${breakpoints.downMdPlus} {
      width: calc(50% - 15px);
      max-width: 340px;
    }

    @media ${breakpoints.downSmPlus} {
      width: 100%;
      max-width: unset;

      &.pagination {
        width: max-content;
        margin: 14px auto 0 auto;
      }
    }
  }
`;

export const FieldWrapper = styled.div``;

export const FilterBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;

  & > div {
    width: 100%;
  }
`;

export const CheckboxesWrapper = styled.div`
  padding-left: 2px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: calc(50% - 25px);
  }
`;

export const FiltersInfo = styled.span`
  width: 100% !important;
  text-align: left;
  margin-top: 20px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const ExportTooltipOptions = styled.div`
  position: relative;
  width: 100%;

  & > div:not(:nth-of-type(3)) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 5px;
    height: auto;
    font-size: 14px;
    transition: color 0.3s;

    svg {
      width: 12px;
      margin-right: 7px;

      path {
        transition: fill 0.3s;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.brightTurquoise};

      svg path {
        fill: ${({ theme }) => theme.color.brightTurquoise};
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    background: lightgrey;
    margin: 2px auto;
  }
`;

export const ExportTooltipLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 83px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff99;
`;
