import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton, StyledLabel, StyledIcon } from './styled';

interface SettingsButtonProps {
  withLabel?: boolean;
  onClick?: () => void;
}

const SettingsButton: FC<SettingsButtonProps> = ({ withLabel, onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={onClick}>
      {withLabel && <StyledLabel>{t('groupSettings.title')}</StyledLabel>}
      <StyledIcon />
    </StyledButton>
  );
};

export default SettingsButton;
