import { RegistrationDeadlineModificator } from 'types';

export type RegDeadlineTimeUnit = {
  value: RegistrationDeadlineModificator;
  label: string;
};

export const deadlineTimeUnits: RegDeadlineTimeUnit[] = [
  { value: RegistrationDeadlineModificator.HOUR, label: 'hours' },
  { value: RegistrationDeadlineModificator.DAY, label: 'days' },
  { value: RegistrationDeadlineModificator.WEEK, label: 'weeks' },
  { value: RegistrationDeadlineModificator.MONTH, label: 'months' },
  { value: RegistrationDeadlineModificator.YEAR, label: 'years' },
];
