// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { map, noop } from 'lodash/fp';
import {
  EditButton,
  DeleteButton,
  SpottedText,
  IconText,
  PopupEventWrapper,
} from 'components';

import {
  Container,
  ButtonsContainer,
  LinkText,
  DefaultSettingWrapper,
} from './styled';
import { EventType } from 'types';
import { CalwithDate } from 'static';

interface TabListProps {
  items: any[];
  type?: string;
  onEdit?: (data: EventType) => void;
  onDelete?: (id: string) => void;
  onDefaultSetting?: (data: EventType) => void;
}

const TabList: FC<TabListProps> = ({
  items = [],
  type,
  onEdit = noop(),
  onDelete = noop(),
  onDefaultSetting = noop(),
  t,
}) =>
  map(
    (item) => (
      <Container key={item.id}>
        {type === 'tasks' ? (
          <IconText icon={item.icon} text={item.title} />
        ) : (
          <SpottedText
            text={item.title ? item.title : item.eventType.title}
            color={item.color ? item.color : item.eventType.color}
          />
        )}

        <ButtonsContainer>
          {onDefaultSetting && (
            <DefaultSettingWrapper>
              <CalwithDate
                width={20}
                height={20}
                style={{ minHeight: '20px', minWidth: '20px' }}
              />
              <LinkText onClick={() => onDefaultSetting(item)}>
                {t('event.setEventDefaults')}
              </LinkText>
            </DefaultSettingWrapper>
          )}
          {onEdit && <EditButton outlined onClick={() => onEdit(item)} />}
          {onDelete && (
            <PopupEventWrapper
              extraMessageStyle={{ padding: '0px 40px 30px' }}
              popupStyles={{ padding: '44px 0px 30px' }}
              popupMessageText={t('common.removeLogo')}
              popupButtons={[
                {
                  popupButtonText: t('common.delete'),
                  handleConfirm: () =>
                    onDelete(item.id ? item.id : item.eventType.id),
                },
              ]}
              darkBackground={true}
            >
              <DeleteButton type="button" outlined />
            </PopupEventWrapper>
          )}
        </ButtonsContainer>
      </Container>
    ),
    items,
  );

export default withTranslation()(TabList);
