import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Lottie from 'react-lottie';

import { InfoIcon } from 'static';
import newAnimation from 'static/animations/new.json';

import {
  StyledWrapper,
  StyledLabel,
  StyledErrorMessage,
  ColorDot,
} from '../styled';
import {
  StyledSelect,
  StyledIconPlaceholder,
  StyledIcon,
  StyledOptionSingle,
  StyledNewBadgeWrapper,
} from './styled';

interface SelectInputControlledV2Props {
  name?: string;
  label?: string;
  info?: string;
  width?: string;
  value: { [key: string]: any };
  options: { [key: string]: any }[];
  error?: FieldError;
  isDisabled?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  onChange: (value: { [key: string]: any }) => void;
  onBlur?: () => void;
}

const OptionSingle = (props) => {
  const { innerProps, data } = props;
  return (
    <div {...innerProps}>
      <StyledOptionSingle>
        {data.icon &&
          (data.icon.length === 1 ? (
            <StyledIconPlaceholder>{data.icon}</StyledIconPlaceholder>
          ) : (
            <StyledIcon src={data.icon} alt={data.label} />
          ))}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {data?.color && <ColorDot color={data?.color} />}
          <span>{data.label}</span>
        </div>
        {data.newLabel && (
          <StyledNewBadgeWrapper>
            <Lottie
              options={{
                animationData: newAnimation,
                loop: true,
                autoplay: true,
              }}
              width={30}
              height={30}
            />
          </StyledNewBadgeWrapper>
        )}
      </StyledOptionSingle>
    </div>
  );
};

const SelectInputControlledV2: FC<SelectInputControlledV2Props> = ({
  name,
  label,
  info,
  options,
  width,
  error,
  value,
  isDisabled,
  placeholder = '',
  maxMenuHeight = 115,
  onChange,
  onBlur = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}{' '}
          {info && (
            <>
              <InfoIcon data-tip data-for="infoTip" />
              <ReactTooltip id="infoTip" effect="solid">
                {info}
              </ReactTooltip>
            </>
          )}
        </StyledLabel>
      )}
      <StyledSelect
        error={error}
        width={width}
        id={name}
        name={name}
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        value={value}
        maxMenuHeight={maxMenuHeight}
        components={{ Option: OptionSingle }}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default SelectInputControlledV2;
