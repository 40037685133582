import { default as axiosInstance } from 'axios';

import {
  axiosInstance as axios,
  axiosFormInstance as axiosForm,
} from './index';

export const requestDocumentsOverview = async (communityId: string) => {
  const response = await axios.get(
    `/communities/${communityId}/documents/overview`,
  );

  return response.data;
};

export const requestDeleteDocument = async (
  communityId: string,
  documentId: string,
) => {
  const response = await axios.delete(
    `/communities/${communityId}/documents/${documentId}`,
  );

  return response.data;
};

export const requestDocuments = async (communityId: string) => {
  const response = await axios.get(`/communities/${communityId}/documents`);

  return response.data;
};

export const requestUploadDocument = async (
  data: { document: File },
  communityId: string,
  groupId: string,
) => {
  const formData = await new FormData();

  await formData.append('document', data.document);
  if (groupId !== 'general') await formData.append('groupId', groupId);

  const response = await axiosForm.post(
    `/communities/${communityId}/documents/`,
    formData,
  );

  return response.data;
};

export const requestPreSignedUrl = async (
  filename: string,
  mimetype: string,
  size: number,
  ext: string,
  communityId: string,
  groupId?: string,
) => {
  const response = await axios.post(
    `/communities/${communityId}/getPresignedUrl/`,
    {
      filename,
      mimetype,
      size,
      ext,
      communityId,
      groupId,
    },
  );

  return response.data;
};

export const uploadFile = async (
  url: string,
  file: File,
  contentType: string,
) => {
  const response = await axiosInstance.put(url, file, {
    headers: {
      'Content-Type': contentType,
    },
  });

  return response.data;
};
