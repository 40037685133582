import { get } from 'lodash/fp';

export const config = {
  Auth: {
    region: get(['REACT_APP_AWS_REGION'], process.env),
    userPoolId: get(['REACT_APP_AWS_USER_POOL_ID'], process.env),
    userPoolWebClientId: get(
      ['REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID'],
      process.env,
    ),
    identityPoolId: get(['REACT_APP_AWS_IDENTITY_POOL_ID'], process.env),
    oauth: {
      domain: get(['REACT_APP_AWS_OAUTH_DOMAIN'], process.env),
      scope: ['profile', 'phone', 'email', 'openid'],
      redirectSignIn: get(['REACT_APP_AWS_OAUTH_REDIRECT'], process.env),
      redirectSignOut: get(['REACT_APP_AWS_OAUTH_REDIRECT'], process.env),
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
};
