import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CheckBox } from 'components';
import { useSettings } from '../hooks';
import { getFullAuthUser, getSettings } from 'store/selectors/auth';

import { StyledWrapper } from '../styled';

const Notification = () => {
  const { t } = useTranslation();
  const user = useSelector(getFullAuthUser);
  const settings = useSelector(getSettings);
  const { onSubmit, register } = useSettings(user.id);

  return (
    <form>
      <StyledWrapper>
        <CheckBox
          register={register}
          name="pushNotification"
          label={t('profileSettings.receivePush')}
          onChange={onSubmit}
          isSelected={settings.pushNotification}
        />
      </StyledWrapper>
      <StyledWrapper>
        <CheckBox
          register={register}
          name="emailNotification"
          onChange={onSubmit}
          label={t('profileSettings.receiveByEmail')}
          isSelected={settings.emailNotification}
        />
      </StyledWrapper>
      <StyledWrapper>
        <CheckBox
          register={register}
          name="inAppNotification"
          onChange={onSubmit}
          label={t('profileSettings.receiveInApp')}
          isSelected={settings.inAppNotification}
        />
      </StyledWrapper>
    </form>
  );
};

export default Notification;
