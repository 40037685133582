import React, { FC } from 'react';

import { Container, Signature, Link } from './styled';

interface SignedLinkProps {
  name: string;
  path: string;
  signature: string;
}

const SignedLink: FC<SignedLinkProps> = ({ name, path, signature }) => (
  <Container>
    <Link href={path} target="_blank">
      {name}
    </Link>
    <Signature>
      {signature.charAt(0).toUpperCase() + signature?.slice(1)}
    </Signature>
  </Container>
);

export default SignedLink;
