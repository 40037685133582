import styled, { css } from 'styled-components';
import { breakpoints } from 'theme';

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* background-color: red; */
  height: 30px;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;

  &::after {
    position: absolute;
    content: '';
    height: 4px;
    background-color: ${({ theme }) => theme.color.blackSqueeze};
    /* transform: translateY(-50%); */
    width: 100%;
  }
`;

export const ProgressLine = styled.div`
  position: absolute;
  content: '';
  height: 4px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  width: ${({ width }) => width};
  /* transform: translateY(-50%); */
  transition: all 0.45s ease-out;
  z-index: 1;
`;

export const ProgressStepCircle = styled.div`
  width: 30px;
  height: 30px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, currentStep }) =>
    currentStep ? theme.color.brightTurquoise : theme.color.white};
  color: ${({ theme, currentStep }) =>
    currentStep ? theme.color.white : theme.color.pickledBluewood};
  transition: all 0.6s ease-in;
  z-index: 2;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.brightTurquoise};
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  height: 100%;
  width: ${({ eachWidth }) => eachWidth}%;
  transition: transform 0.45s ease-in;
  ${({ currentStep, eachWidth }) =>
    css`
      transform: translateX(-${currentStep * 100}%);
    `};
`;
export const MessageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: 350px;
`;

export const MessageContainerOuter = styled.div`
  width: ${({ totalWidth }) => totalWidth}%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
