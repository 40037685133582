import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  Button,
  SidebarFooter,
  SidebarContent,
  SidebarHeader,
  Loader,
  CommunityUsersByGroups,
} from 'components';
import { doGetCommunityMembersByGroups } from 'store/actionCreators/currentCommunity';
import { doAddMembersToGroup } from 'store/actionCreators/groups';
import { closeModal } from 'store/actionCreators/modal';
import { getData } from 'store/selectors/modal';
import {
  getAddMembersToGroupLoading as getAddMembersLoading,
  getCurrentCommunity,
  getGroups,
  getMembersToGroupsLoading,
} from 'store/selectors/currentCommunity';
import { getAddMembersToGroupLoading } from 'store/selectors/currentGroup';
import { AddGroupMembersData } from 'types';

import { ListPlaceholder } from './styled';

const AddGroupMembers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const data = useSelector(getData) as AddGroupMembersData;
  const community = useSelector(getCurrentCommunity);
  const membersToGroupsLoading = useSelector(getMembersToGroupsLoading);
  const membersLoading = useSelector(getAddMembersLoading);
  const addMembersLoading = useSelector(getAddMembersToGroupLoading);
  const currentGroups = useSelector(getGroups);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const { group } = data;

  useEffect(() => {
    dispatch(
      doGetCommunityMembersByGroups({
        communityId: community.id,
        withoutBlocked: true,
      }),
    );
  }, []);

  const filteredUsers = useMemo(() => {
    return (
      community?.users.filter(
        (u) => !group.users?.data?.some((i) => i.id === u.id),
      ) || []
    );
  }, [community, group]);

  const filteredMembersByGroups = useMemo(() => {
    if (community?.membersByGroups) {
      return (
        community.membersByGroups.map((g) => ({
          ...g,
          users: g.users.filter(
            (u) => !group.users?.data?.some((i) => i.id === u.id),
          ),
        })) || []
      );
    }

    return [];
  }, [community, group]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const onSuccess = () => {
    handleClose();
    addToast(t('group.addMembersSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = useCallback(() => {
    const data = { users: selectedUsers };

    dispatch(
      doAddMembersToGroup({
        data,
        communityId: community.id,
        groupId: !group.id
          ? currentGroups[currentGroups.length - 1].id
          : group.id,
        onSuccess: selectedUsers?.length > 0 ? onSuccess : () => {},
      }),
    );
  }, [selectedUsers]);

  return (
    <Sidebar>
      <SidebarHeader
        label={t('group.addParticipants')}
        onCloseClick={handleClose}
      />
      <SidebarContent>
        {membersToGroupsLoading ? (
          <Loader />
        ) : (
          <CommunityUsersByGroups
            value={selectedUsers}
            membersByGroups={filteredMembersByGroups}
            users={filteredUsers}
            height="calc(100svh - 269px)"
            onChange={setSelectedUsers}
          />
        )}
        {isEmpty(community.users) && !membersLoading && (
          <ListPlaceholder>{t('common.noUsers')}</ListPlaceholder>
        )}
      </SidebarContent>
      <SidebarFooter>
        <Button
          disabled={isEmpty(community.users) || addMembersLoading}
          onClick={onSubmit}
        >
          {addMembersLoading ? (
            <Loader type="button" size="28px" thickness={2} />
          ) : (
            t('group.addParticipants')
          )}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};

export default AddGroupMembers;
