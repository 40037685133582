import React, { memo } from 'react';
import cn from 'classnames';

import { ReactComponent as Down } from '../icons/Down.svg';
import { ReactComponent as DownLight } from '../icons/DownLight.svg';
import { EventGridItem, ModalTypes } from 'types';

import { StyledGroupCell, StyledSelectionHighlight } from './styled';
import { useSelector } from 'react-redux';
import { modalSelector } from 'store/selectors/modal';
import Tooltip from 'components/Tooltip';

interface GroupProps {
  style: { [key: string]: string | number };
  zoom: number;
  isFirst: boolean;
  isOpen: boolean;
  isMark?: boolean;
  dark?: boolean;
  group: EventGridItem;
  divide?: 'left' | 'right';
  noArrow?: boolean;
  eventId?: string;
}

const Group = memo<GroupProps>(
  ({
    style,
    isFirst,
    isOpen,
    isMark,
    dark,
    group,
    zoom,
    divide,
    noArrow,
    eventId = '',
  }) => {
    const { id, label } = group || {};
    const {
      data,
      isOpen: communityModalOpen,
      type,
    } = useSelector(modalSelector);

    return (
      <div style={style}>
        {communityModalOpen &&
          (type === ModalTypes.GIVE_REPLY ||
            type === ModalTypes.EVENT_DETAILS) &&
          data?.['eventId']?.toString() === eventId?.toString() && (
            <StyledSelectionHighlight zoom={zoom} />
          )}
        <StyledGroupCell zoom={zoom} dark={dark} divide={divide}>
          {isFirst ? (
            <>
              {isMark && (
                <div
                  className={cn('grid-group-mark', `grid-group-mark-${id}`)}
                />
              )}
              {!noArrow && (
                <span
                  data-clickable="true"
                  data-type="group"
                  data-id={id}
                  className={cn(
                    'grid-group-toogle-container',
                    'grid-clickable-item',
                    {
                      'grid-group-toogle-container-close': !isOpen,
                    },
                  )}
                  style={{
                    transform: `scale(${zoom})`,
                  }}
                >
                  {id === 'total' ? (
                    <Tooltip
                      isVisible
                      noArrow={true}
                      zoom={zoom}
                      opacity="0.9"
                      text=" Ctrl + ` "
                      offset={11 * zoom}
                      position="top-center"
                      tooltipStyle={{
                        fontSize: 13 * zoom,
                        maxWidth: 200 * zoom,
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        lineBreak: 'normal',
                      }}
                    >
                      {!dark ? <Down /> : <DownLight />}
                    </Tooltip>
                  ) : (
                    <>{!dark ? <Down /> : <DownLight />}</>
                  )}
                </span>
              )}
              {label}
            </>
          ) : null}
        </StyledGroupCell>
      </div>
    );
  },
);

Group.displayName = 'Group';

export default Group;
