import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import LogoImg from 'static/BGLogo.jpg';

import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledText,
  StyledButtonWrapper,
  StyledImageWrapper,
  StyledLogo,
} from './styled';

interface ErrorPageContainerProps {
  errorTitle: string;
  errorText: string;
}

const ErrorPageContainer: FC<ErrorPageContainerProps> = ({
  errorTitle,
  errorText,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/profile');
  };

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledTitle>{errorTitle}</StyledTitle>
        <StyledText>{errorText}</StyledText>
        <StyledButtonWrapper>
          <Button variant="secondary" onClick={goHome}>
            {t('common.backToGumb')}
          </Button>
        </StyledButtonWrapper>
      </StyledContentWrapper>
      <StyledImageWrapper>
        <StyledLogo src={LogoImg} />
      </StyledImageWrapper>
    </StyledWrapper>
  );
};

export default ErrorPageContainer;
