import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { CellsTypes } from 'constants/index';
import { getProfileName } from 'utils/common';
import {
  ProfileName,
  DragIcon,
  EditButton,
  DeleteButton,
  PopupButtonMessage,
} from 'components';
import { VIEW_MEMBER } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';

import {
  EditBtnWrapper,
  Row,
  RowColumn,
  StyledButtonsBox,
  TextPlaceholder,
} from './styled';

interface DndRowProps {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  canDrag?: boolean;
  dataItem: any;
  config: any;
  onEditRow?: (data: any) => void;
  onDelete?: (data: any) => void;
}

const DeleteButtonComponent = (buttonProps) => (
  <DeleteButton outlined={true} {...buttonProps} />
);

const DndRow: FC<DndRowProps> = ({
  dragHandleProps,
  canDrag,
  dataItem,
  config,
  onEditRow,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getCellProps = (type) => {
    switch (type) {
      case CellsTypes.PLACEHOLDER:
      case CellsTypes.EDIT:
      case CellsTypes.DELETE:
        return {
          center: true,
        };
      default:
        return null;
    }
  };

  const renderCellContent = (type, key) => {
    const { profile } = dataItem;
    switch (type) {
      case CellsTypes.DRAGNDROP:
        return canDrag ? (
          <div {...(dragHandleProps || {})}>
            <DragIcon />
          </div>
        ) : null;
      case CellsTypes.AVATAR_NAME:
        return (
          <ProfileName
            name={getProfileName(profile)}
            avatar={profile?.smallLogo || profile?.logo}
            communityPermission={dataItem.communityPermission}
            groupPermission={dataItem.groupPermission}
            onClick={() => {
              dispatch(
                openModal({
                  type: VIEW_MEMBER,
                  position: 'right',
                  data: dataItem,
                }),
              );
            }}
          />
        );
      case CellsTypes.EDIT:
        return canDrag ? (
          <EditBtnWrapper>
            <EditButton outlined={true} onClick={() => onEditRow(dataItem)} />
          </EditBtnWrapper>
        ) : null;
      case CellsTypes.PLACEHOLDER:
        return (
          dataItem.text && <TextPlaceholder>{dataItem.text}</TextPlaceholder>
        );
      case CellsTypes.TEXT:
        return profile[key];
      case CellsTypes.ARRAY:
        return profile[key] ? profile[key].join(', ') : '-';
      case CellsTypes.DELETE:
        return canDrag ? (
          <StyledButtonsBox>
            <PopupButtonMessage
              ButtonComponent={DeleteButtonComponent}
              onConfirm={() => onDelete(dataItem)}
              popupButtonText={t('common.delete')}
              popupHeaderText={t('community.deleteHeader')}
            />
          </StyledButtonsBox>
        ) : null;
      default:
        return dataItem[key];
    }
  };

  return (
    <div>
      <Row withOpacity={config.opacity}>
        {config.headers.map((cell) => (
          <RowColumn
            width={cell.width}
            key={cell.id}
            {...getCellProps(cell.type)}
          >
            {renderCellContent(cell.type, cell.key)}
          </RowColumn>
        ))}
      </Row>
    </div>
  );
};

export default DndRow;
