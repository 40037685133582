import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'theme';

export const StyledAdblockContainer = styled(motion.div)`
  width: 730px;
  border: 1px solid ${({ theme }) => theme.color.brightTurquoise};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 8px 8px 20px 0px #000000;
  border-radius: 20px;
  padding: 30px 0px 50px 0px;
  gap: 50px;
  @media (${breakpoints.downMd}) {
    width: 90%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  max-height: 600px;
  overflow-y: scroll;
`;

export const HeaderIcon = styled.div`
  height: 150px;
  width: 150px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-align: center;
  align-self: stretch;
  font-size: 30px;
  letter-spacing: -0.45px;
  font-weight: 700;
  font-style: normal;
  line-height: 164.682%;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding: 0px 68px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const StyledMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const StyledMessage = styled.div`
  flex: 1 0 0;
  text-align: center;
  color: ${({ theme }) => theme.color.BlueBayoux};
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 164.682%;
  letter-spacing: -0.255px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`;

export const StyledButton = styled.div`
  width: 400px;
  height: 44px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  border: none;
  display: flex;
  border-radius: 5px;
  padding: 15px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.1s ease-in;
  :hover {
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.botticelli};
  }
`;

export const StyledButtonText = styled.div`
  width: 300px;
  flex-shrink: 0;
  text-align: center;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.255px;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 30px;
  top: 30px;
  stroke-width: 2px;
  stroke: ${({ theme }) => theme.color.pickledBluewood};
  cursor: pointer;
`;

export const CloseModalIcon = styled.div`
  ::before,
  ::after {
    content: '';
    display: block;
    height: 2px;
    background: ${({ theme }) => theme.color.pickledBluewood};
    position: absolute;
  }
  ::before {
    width: 25px;
    top: 48%;
    left: 16%;
    transform: rotateZ(50deg);
  }
  ::after {
    width: 25px;
    top: 48%;
    left: 16%;
    transform: rotateZ(-50deg);
  }
`;
