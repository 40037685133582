import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  doUploadDocument,
  doDeleteDocument,
} from 'store/actionCreators/currentCommunity/documents';

export const useDocumentation = (
  communityId: string,
  groupId: string | null,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, ...form } = useForm<{ document: File }>();
  const { addToast } = useToasts();

  const onSuccessUpload = () => {
    addToast(t('documentation.uploadSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSuccessDelete = () => {
    addToast(t('documentation.deleteSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = handleSubmit((data) => {
    dispatch(
      doUploadDocument({
        communityId,
        groupId: groupId || null,
        data,
        onSuccess: onSuccessUpload,
      }),
    );
  });

  const onDelete = (e, documentId) => {
    e.preventDefault();
    dispatch(
      doDeleteDocument({ communityId, documentId, onSuccess: onSuccessDelete }),
    );
  };

  return {
    onSubmit,
    onDelete,
    ...form,
  };
};
