import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledInput = styled.input`
  height: 40px;
  width: ${({ width, fillWidth }) => (fillWidth ? '100%' : width)};
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  padding: 0 14px;
  font-size: 13px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  padding-right: ${({ withFilter }) => (withFilter ? 70 : 40)}px;

  &::placeholder {
    color: ${({ theme }) => theme.color.baliHai};
  }

  &:focus:enabled {
    border-color: ${({ theme }) => theme.color.brightTurquoise};

    &::placeholder {
      color: transparent;
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.color.blackSqueeze};
    color: ${({ theme }) => theme.color.baliHai};

    &::placeholder {
      opacity: 40%;
    }
  }
`;

export const StyledInputBox = styled.div`
  max-width: ${({ fillWidth }) => (fillWidth ? 'auto' : '340px')};
  position: relative;

  @media ${breakpoints.downSmPlus} {
    position: sticky;
    left: 0;
  }
`;

export const StyledIconsBlock = styled.div`
  position: absolute;
  top: 5px;
  right: 14px;
  display: flex;
`;

export const StyledLeftIcon = styled.img`
  cursor: pointer;
  width: 16px;
  height: 30px;

  &:hover {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  ${({ value }) =>
    value &&
    `
        filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%) hue-rotate(128deg) brightness(95%) contrast(87%);
        cursor: pointer;
    `};
  ${({ disabled }) =>
    disabled &&
    `
        filter: brightness(0) saturate(100%) invert(89%) sepia(7%) saturate(1004%) hue-rotate(171deg) brightness(84%) contrast(91%);
    `};

  @media ${breakpoints.downSmPlus} {
    display: none;
  }
`;

export const StyledRightIcon = styled.div`
  cursor: pointer;
  margin-left: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  border-radius: 50%;
  background: ${({ theme, selected }) =>
    selected ? theme.color.brightTurquoise : 'transparent'};
  transition: background 0.3s;

  svg path {
    fill: ${({ theme, selected }) =>
      selected ? 'white' : theme.color.brightTurquoise};
    transition: fill 0.3s;
  }
`;
