import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EDIT_COMMUNITY_TASK } from 'constants/sidebars';
import { doDeleteCommunityTask } from 'store/actionCreators/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { useInfoPopup } from 'hooks';
import { EventTask } from 'types';

export const useTasks = (communityId) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showInfoPopup } = useInfoPopup();

  const onAdd = () =>
    dispatch(
      openModal({
        type: EDIT_COMMUNITY_TASK,
        placement: 'right',
      }),
    );

  const onEdit = (defaultValues: EventTask) =>
    dispatch(
      openModal({
        type: EDIT_COMMUNITY_TASK,
        placement: 'right',
        data: defaultValues,
      }),
    );

  const onDelete = (id) =>
    dispatch(
      doDeleteCommunityTask({
        communityId,
        id,
        onSuccess: () =>
          showInfoPopup({
            type: 'success',
            // title: t('eventTasks.deleteSuccess'),
            title: t('common.deleteSuccess.title'),
            message: t('common.deleteSuccess.message'),
            noButton: true,
            duration: 3000,
            loop: false,
          }),
      }),
    );

  return {
    onDelete,
    onAdd,
    onEdit,
  };
};
