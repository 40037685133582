import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SharePopup, PopupMessage } from 'components';
import {
  EventDetails,
  GiveReply,
  CreateEventOverall,
  EditProfile,
  EditCommunityEventType,
  EditCommunityResponse,
  EditCommunityTask,
  AddField,
  AddMember,
  AddGroupMembers,
  CreateCommunity,
  CreateGroup,
  EditUserRole,
  ViewMember,
  TaskDetails,
  CreateChat,
} from 'sidebars';
import {
  CREATE_COMMUNITY,
  CREATE_GROUP,
  CREATE_CHAT,
  ADD_GROUP_MEMBERS,
  EDIT_PROFILE,
  EDIT_USER_ROLE,
  ADD_FIELD,
  CREATE_EVENT,
  CREATE_EVENT_OVERALL,
  VIEW_MEMBER,
  EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING,
  EDIT_COMMUNITY_RESPONSE,
  EDIT_COMMUNITY_TASK,
  EVENT_DETAILS,
  GIVE_REPLY,
  TASK_DETAILS,
  INVITE_MEMBER,
  ADD_MEMBER,
  COMMUNITY_POPUP,
  SUBSCRIPTION_OVER_POPUP,
} from 'constants/sidebars';
import { POPUP_MESSAGE, SHARE_POPUP } from 'constants/popups';
import { modalSelector } from 'store/selectors/modal';
import { closeModal } from 'store/actionCreators/modal';
import AddMemberPopup from 'pages/CommunitySettings/Members/AddMemberPopup';
import { Container, SidebarWrapper } from './styled';
import CreateEventGroupPopup from 'pages/CommunityOverallScheduler/CreateEventGroupPopup';
import PopupSubscriptionOver from 'components/PopupSubscriptionOver';

const Modal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const modalData = useSelector(modalSelector);

  const { isOpen, type, placement = 'left', data, edit } = modalData;

  const mapSidebar = (sidebarType, requiredData) => {
    switch (sidebarType) {
      case CREATE_COMMUNITY:
        return <CreateCommunity />;
      case CREATE_GROUP:
        return <CreateGroup />;
      case CREATE_CHAT:
        return <CreateChat />;
      case ADD_GROUP_MEMBERS:
        return <AddGroupMembers />;
      case EDIT_PROFILE:
        return <EditProfile memberProfile={requiredData?.user?.profile} />;
      case EDIT_USER_ROLE:
        return <EditUserRole />;
      case ADD_FIELD:
        return <AddField onAddField={requiredData} />;
      case CREATE_EVENT:
      case CREATE_EVENT_OVERALL:
        return <CreateEventOverall />;
      case VIEW_MEMBER:
        return <ViewMember user={requiredData} />;
      case EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING:
        return <EditCommunityEventType />;
      case EDIT_COMMUNITY_RESPONSE:
        return <EditCommunityResponse />;
      case EDIT_COMMUNITY_TASK:
        return <EditCommunityTask />;
      case EVENT_DETAILS:
        return <EventDetails />;
      case GIVE_REPLY:
        return (
          <GiveReply
            filterFrom={requiredData.filterFrom}
            filterTo={requiredData.filterTo}
          />
        );
      case TASK_DETAILS:
        return <TaskDetails {...requiredData} />;
      case POPUP_MESSAGE:
        return <PopupMessage {...requiredData} />;
      case SHARE_POPUP:
        return <SharePopup eventId={requiredData?.eventId} />;
      case INVITE_MEMBER:
        return <AddMember />;
      case ADD_MEMBER:
        return <AddMemberPopup isOpen={true} />;
      case COMMUNITY_POPUP:
        return <CreateEventGroupPopup isOpen={true} />;
      case SUBSCRIPTION_OVER_POPUP:
        return <PopupSubscriptionOver isOpen={true} />;
      default:
        return null;
    }
  };

  const actionTypes = [
    TASK_DETAILS,
    GIVE_REPLY,
    EVENT_DETAILS,
    CREATE_EVENT,
    CREATE_EVENT_OVERALL,
    VIEW_MEMBER,
  ];

  const getSidebar = (sidebarType, sidebarPlacement, requiredData) => {
    const onCloseModal = () => {
      if (location.pathname.includes('/event/')) {
        navigate(location.pathname.split('/event/')[0], { replace: true });
      }

      if (sidebarType === 'EDIT_PROFILE' && edit) {
        const result = window.confirm(t('common.modalExit'));
        if (result) {
          dispatch(closeModal());
        }
      } else dispatch(closeModal());
    };

    const showSidebarWrapper = (type: string) => {
      if (actionTypes.includes(type)) {
        return false;
      } else {
        return true;
      }
    };
    return (
      <Container placement={sidebarPlacement} width={data && data['width']}>
        <div id="modal">{mapSidebar(sidebarType, requiredData)}</div>
        {showSidebarWrapper(sidebarType) && (
          <SidebarWrapper
            width={data && data['width']}
            placement={sidebarPlacement}
            onClick={onCloseModal}
          />
        )}
      </Container>
    );
  };

  return isOpen === true
    ? createPortal(
        getSidebar(type, placement, data),
        document.getElementById('modal'),
      )
    : null;
};

export default Modal;
