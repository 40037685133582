import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput, Table } from 'components';
import { CellsTypes } from 'constants/index';
import { Profile } from 'types';

import {
  StyledSearchWrapper,
  SidebarContent,
  SidebarContentWrapper,
} from './styled';

interface EventDetailsParticipantsProps {
  users: Profile[];
}

const EventDetailsParticipants: FC<EventDetailsParticipantsProps> = ({
  users = [],
}) => {
  const { t } = useTranslation();
  const [filteredUsers, setUsers] = useState<Profile[]>(users);
  const [inputValue, setValue] = useState<string>('');

  const tableConfig = {
    title: t('community.members'),
    headers: [
      {
        id: 'name',
        width: '100%',
        type: CellsTypes.AVATAR_NAME,
        headerType: 'title',
      },
    ],
  };

  const handleChange = (text) => {
    const newMembers = users.filter(({ firstName, lastName }) =>
      `${firstName} ${lastName}`
        .toLocaleLowerCase()
        .includes(text.toLowerCase()),
    );
    setUsers(newMembers);
    setValue(text);
  };

  return (
    <SidebarContent>
      <StyledSearchWrapper>
        <SearchInput
          width="100%"
          value={inputValue}
          fillWidth={true}
          onChange={({ target }) => handleChange(target.value)}
        />
      </StyledSearchWrapper>
      <SidebarContentWrapper>
        <Table data={filteredUsers} config={tableConfig} />
      </SidebarContentWrapper>
    </SidebarContent>
  );
};

export default EventDetailsParticipants;
