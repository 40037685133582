import React, { FC } from 'react';

import { ROUTES } from 'configs';
import {
  MainMenuItemWrapper,
  MainMenuItem,
  ProfileImagePlaceholder,
} from 'components';
import { CameraIcon, DefaultAvatar } from 'static';

import { StyledBlock } from './styled';

interface MainMenuUserItemProps {
  image?: string;
  hasNotifications?: boolean;
}

const MainMenuUserItem: FC<MainMenuUserItemProps> = ({
  image = DefaultAvatar,
  hasNotifications = false,
}) => (
  <MainMenuItemWrapper to={ROUTES.PROFILE}>
    <StyledBlock hasNotifications={hasNotifications}>
      {image ? (
        <MainMenuItem image={image} />
      ) : (
        <ProfileImagePlaceholder
          width="40px"
          icon={<CameraIcon width="20" />}
        />
      )}
    </StyledBlock>
  </MainMenuItemWrapper>
);

export default MainMenuUserItem;
