import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useChangePassword } from './hooks';

import { StyledWrapper, StyledForm } from './styled';
import { StyledButtonBlock } from '../ChangeEmail/styled';

import { FIELDS } from './form';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const { onSubmit, register, errors } = useChangePassword();

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledWrapper>
        <TextInput
          register={register}
          name="oldPassword"
          width="100%"
          label={t('auth.labels.oldPassword')}
          type="password"
          error={errors[FIELDS.OLD_PASSWORD]}
          withShowIcon
        />
        <TextInput
          register={register}
          name={FIELDS.PASSWORD}
          width="100%"
          label={t('auth.labels.newPassword')}
          type="password"
          error={errors[FIELDS.PASSWORD]}
          withShowIcon
        />
        <TextInput
          register={register}
          name={FIELDS.CONFIRM_PASSWORD}
          width="100%"
          label={t('auth.labels.confirmPassword')}
          type="password"
          error={errors[FIELDS.CONFIRM_PASSWORD]}
          withShowIcon
        />
      </StyledWrapper>
      <StyledButtonBlock>
        <Button type="submit">{t('common.saveChanges')}</Button>
      </StyledButtonBlock>
    </StyledForm>
  );
};

export default ChangePassword;
