import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Loader } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { FIELDS } from './form';
import { useChangeEmail } from './hooks';
import { getFullAuthUser } from 'store/selectors/auth';

import { StyledWrapper, StyledButtonBlock, StyledForm } from './styled';

export const ChangeEmail = () => {
  const { t } = useTranslation();
  const user = useSelector(getFullAuthUser);
  const { onSubmit, register, errors, saving, getValues, formState } =
    useChangeEmail(user.profile);

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <StyledWrapper>
          <TextInput
            name=""
            register={() => {}}
            placeholder={user.profile?.email}
            disabled={true}
            width="100%"
            label={t('auth.labels.currentEmail')}
          />
          <TextInput
            register={register}
            name={FIELDS.EMAIL}
            error={errors[FIELDS.EMAIL]}
            width="100%"
            label={t('auth.labels.newEmail')}
          />
        </StyledWrapper>
        <StyledButtonBlock>
          {saving ? (
            <Loader type="button" size="28px" thickness={2} />
          ) : (
            <Button type="submit">{t('profileSettings.saveNewEmail')}</Button>
          )}
        </StyledButtonBlock>
      </StyledForm>
    </>
  );
};

export default ChangeEmail;
