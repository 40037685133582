import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SocialContainer, CheckBox, Loader } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { ROUTES } from 'configs/routes';
import { FIELDS } from './form';
import { useSignUp } from './hooks';

import {
  StyledButton,
  Header,
  StyledForm,
  StyledLink,
  StyledGumbLogo,
} from '../styled';
import { TermsContainer, SignInLink, StyledTerms } from './styled';

const SignUpPage = () => {
  const { t } = useTranslation();
  const [confirmTerms, setConfirmTerms] = useState(false);

  const { register, errors, onSubmit, isLoading } = useSignUp();

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledGumbLogo />
      <Header>{t('auth.create')}</Header>
      <TextInput
        register={register}
        error={errors[FIELDS.EMAIL]}
        name={FIELDS.EMAIL}
        label={t('auth.labels.email')}
      />
      <TextInput
        register={register}
        error={errors[FIELDS.FIRST_NAME]}
        name={FIELDS.FIRST_NAME}
        label={t('profile.firstName')}
      />
      <TextInput
        register={register}
        error={errors[FIELDS.LAST_NAME]}
        name={FIELDS.LAST_NAME}
        label={t('profile.lastName')}
      />
      <TextInput
        register={register}
        error={errors[FIELDS.PASSWORD]}
        name={FIELDS.PASSWORD}
        label={t('auth.labels.password')}
        type="password"
        withShowIcon
      />
      <TextInput
        register={register}
        error={errors[FIELDS.CONFIRM_PASSWORD]}
        name={FIELDS.CONFIRM_PASSWORD}
        label={t('auth.labels.confirmPassword')}
        type="password"
        withShowIcon
      />
      <TermsContainer>
        <CheckBox
          name={FIELDS.TERMS}
          error={errors[FIELDS.TERMS]}
          label={
            <StyledTerms>
              {t('auth.terms.0')}
              <StyledLink href={ROUTES.TERMS} target="_blank">
                {t('auth.terms.1')}
              </StyledLink>
              {t('auth.terms.2')}
              <StyledLink href={ROUTES.POLICY} target="_blank">
                {t('auth.terms.3')}
              </StyledLink>
            </StyledTerms>
          }
          isSelected={confirmTerms}
          onChange={() => setConfirmTerms(!confirmTerms)}
        />
      </TermsContainer>
      <StyledButton
        variant={isLoading ? 'secondary' : null}
        disabled={!confirmTerms || isLoading}
      >
        {isLoading ? (
          <Loader type="button" size="28px" thickness={2} />
        ) : (
          t('auth.signUp')
        )}
      </StyledButton>
      <SignInLink to="/">{t('auth.signIn')}</SignInLink>
      <SocialContainer />
    </StyledForm>
  );
};

export default SignUpPage;
