import React, { FC, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { v4 as uuid } from 'uuid';
import warningmotion from 'static/animations/warning-sign.json';
import { Divider } from 'components';
import { closeModal } from 'store/actionCreators/modal';
import Lottie from 'react-lottie';
import CloseButton from 'components/Buttons/CloseButton';
import { breakpoints } from 'theme';
import {
  CloseButtonWrapper,
  HeaderIcon,
  StyledAddMemberPopup,
  StyledAddMemberPopupWrapper,
} from '../../pages/CommunityOverallScheduler/CreateEventGroupPopup/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { PROFILE_ROUTES, ROUTES } from 'configs';
import { doClearCurrentCommunityError } from 'store/actionCreators/currentCommunity';
import { getCommunities } from 'store/selectors/communities';

interface PopupSubscriptionOverProps {
  isOpen: boolean;
}

const PopupSubscriptionOver: FC<PopupSubscriptionOverProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileView = useMedia(breakpoints.downSmPlus);
  const popupRef = useRef<HTMLDivElement>();
  const communities = useSelector(getCommunities);
  const { communityId } = useParams();
  const community = communities.find((c) => c.id === communityId);
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(doClearCurrentCommunityError());
    navigate(`${ROUTES.PROFILE}`, {
      replace: true,
    });
    dispatch(closeModal());
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(doClearCurrentCommunityError());
    navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.SUBSCRIPTION}`, {
      replace: true,
    });
    dispatch(closeModal());
  };

  const data = [
    {
      title: t('subscriptions.subscriptionsPopup.title'),
      subTitle: 'subscriptions.subscriptionsPopup.firstBox.title',
      btnTitle: t('subscriptions.subscriptionsPopup.firstBox.btnText'),
      onClick: handleClick,
    },
    {
      title: t('subscriptions.subscriptionsPopup.title'),
      subTitle: t('subscriptions.subscriptionsPopup.secondBox.title'),
      btnTitle: t('subscriptions.subscriptionsPopup.secondBox.btnText'),
      onClick: handleClick,
    },
  ];
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AnimatePresence>
      {isOpen && (
        <StyledAddMemberPopupWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <StyledAddMemberPopup
            ref={popupRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CloseButtonWrapper>
              <CloseButton hidden={false} onClick={handleClose} />
            </CloseButtonWrapper>
            <HeaderIcon info>
              <Lottie
                options={{
                  animationData: warningmotion,
                  loop: true,
                  autoPlay: true,
                }}
                width={isMobileView ? '18vh' : '18vh'}
                height={isMobileView ? '30vh' : '30vh'}
              />
            </HeaderIcon>
            <StyledAddMemberPopup.Header>
              <h2>{t('subscriptions.subscriptionsPopup.title')}</h2>
              <p>
                {' '}
                <Trans
                  i18nKey="subscriptions.subscriptionsPopup.subtitle"
                  values={{
                    ownerName: `${community?.communityOwner?.firstName} ${community?.communityOwner?.lastName}`,
                  }}
                  components={{
                    brPure: <br />,
                    b: <b onClick={handleClick}></b>,
                  }}
                />
              </p>
            </StyledAddMemberPopup.Header>
            <Divider customMargin="20px 0px 10px 0px" />
            <StyledAddMemberPopup.CardWrapper>
              {data.map((li) => {
                return (
                  <StyledAddMemberPopup.CardWrapper.Card key={uuid()}>
                    <StyledAddMemberPopup.CardWrapper.Card.Header>
                      <StyledAddMemberPopup.CardWrapper.Card.subTitle>
                        <Trans
                          i18nKey={li.subTitle}
                          values={{
                            ownerName: `${community?.communityOwner?.firstName} ${community?.communityOwner?.lastName}`,
                          }}
                          components={{
                            brPure: <br />,
                            b: <b onClick={handleClick}></b>,
                          }}
                        />
                      </StyledAddMemberPopup.CardWrapper.Card.subTitle>
                    </StyledAddMemberPopup.CardWrapper.Card.Header>
                    <StyledAddMemberPopup.CardWrapper.Card.BtnWrapper>
                      <StyledAddMemberPopup.CardWrapper.Card.Btn
                        onClick={li.onClick}
                      >
                        {li.btnTitle}
                      </StyledAddMemberPopup.CardWrapper.Card.Btn>
                    </StyledAddMemberPopup.CardWrapper.Card.BtnWrapper>
                  </StyledAddMemberPopup.CardWrapper.Card>
                );
              })}
            </StyledAddMemberPopup.CardWrapper>
          </StyledAddMemberPopup>
        </StyledAddMemberPopupWrapper>
      )}
    </AnimatePresence>
  );
};

export default PopupSubscriptionOver;
