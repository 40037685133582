import styled from 'styled-components';

import { StyledWrapper } from '../styled';

export const StyledInputWrapper = styled(StyledWrapper)`
  flex: 1;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid
    ${({ theme, color }) =>
      color === 'danger' ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: ${({ zoom }) => Math.floor(4 * zoom)}px;
  outline: none;
  font-weight: 600;
  font-size: ${({ zoom }) => Math.floor(13 * zoom)}px;
  /* line-height: 32px; */
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &::-webkit-outer-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const StyledInputBox = styled.div`
  max-width: ${({ zoom }) => Math.floor(340 * zoom)}px;
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  position: relative;
  margin: auto;
`;

export const StyledLeftIcon = styled.img`
  position: absolute;
  z-index: 1;
  left: ${({ zoom }) => Math.floor(9 * zoom)}px;
  top: calc(50% - 8px);
  filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%)
    hue-rotate(128deg) brightness(95%) contrast(87%);

  ${({ disabled }) =>
    disabled
      ? `
        pointer-events: none;
        opacity: 0.5;
    `
      : `
        cursor: pointer;
    `};
  ${({ color }) =>
    color === 'danger'
      ? `
        filter: invert(13%) sepia(96%) saturate(7477%) hue-rotate(0deg) brightness(114%) contrast(106%);
    `
      : `
        filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(572%) hue-rotate(158deg) brightness(89%) contrast(86%);
    `};

  &:hover {
    ${({ color }) =>
      color !== 'danger' &&
      `
        filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%) hue-rotate(128deg) brightness(95%) contrast(87%);
    `};
  }
`;

export const StyledRightIcon = styled.img`
  position: absolute;
  z-index: 1;
  right: ${({ zoom }) => Math.floor(9 * zoom)}px;
  top: calc(50% - 8px);
  filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(572%)
    hue-rotate(158deg) brightness(89%) contrast(86%);

  ${({ disabled }) =>
    disabled
      ? `
        pointer-events: none;
        opacity: 0.5;
    `
      : `
        cursor: pointer;
    `};

  ${({ color }) =>
    color === 'danger'
      ? `
        filter: invert(13%) sepia(96%) saturate(7477%) hue-rotate(0deg) brightness(114%) contrast(106%);
    `
      : `
        filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(572%) hue-rotate(158deg) brightness(89%) contrast(86%);
    `};

  &:hover {
    ${({ color }) =>
      color !== 'danger' &&
      `
        filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%) hue-rotate(128deg) brightness(95%) contrast(87%);
        cursor: pointer;
    `};
  }
`;
