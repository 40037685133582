import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Sidebar,
  SidebarHeader,
  SidebarInputsWrapper,
  Button,
  SidebarFooter,
  SidebarContent,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { FIELDS } from './form';
import { useAddField } from './hooks';

const AddField = ({ onAddField }) => {
  const { t } = useTranslation();
  const { register, onSubmit, errors, closeSidebar } = useAddField(onAddField);

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('common.newField')}
          onCloseClick={closeSidebar}
        />
        <SidebarContent>
          <SidebarInputsWrapper>
            <TextInput
              name={FIELDS.NAME}
              register={register}
              label={t('common.fieldName')}
              width="100%"
              error={errors[FIELDS.NAME]}
            />
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter>
          <Button type="submit">{t('common.addField')}</Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default AddField;
