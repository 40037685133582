import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacebookShareButton,
  VKShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  ViberShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  VKIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  ViberIcon,
} from 'react-share';
import { useDispatch, useSelector } from 'react-redux';

import { CloseButton, Loader } from 'components';
import { ShareIcon } from 'static';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import { useOutsideClick } from 'hooks';
import { getShareLink } from 'services/events';

import {
  StyledPopup,
  Wrapper,
  ShareButtons,
  StyledInvitationLink,
  Text,
  IconWrapper,
  PopupWrapper,
  Title,
} from './styled';

interface SharePopupProps {
  eventId?: string;
}

const SharePopup: FC<SharePopupProps> = ({ eventId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>();
  const [open, setOpen] = useState<boolean>(true);
  const [loaded, setLoaded] = useState(false);
  const [url, setUrl] = useState(null);
  const [emailData, setEmailData] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const community = useSelector(getCurrentCommunity);

  useEffect(() => {
    (async () => {
      if (eventId) {
        const data = await getShareLink(community.id, eventId);
        setUrl(data.shareLink.shortLink);
        setEmailData({ subject: data.subject, body: data.body });
      } else {
        setUrl(community.inviteLink);
      }

      setLoaded(true);
    })();
  }, []);

  const subject = useMemo(() => {
    if (eventId) {
      return emailData?.subject || null;
    }

    return `${t('communityInvitation.subjectFirst')} ${community.name} ${t(
      'communityInvitation.subjectSecond',
    )} - Gumb`;
  }, [eventId, emailData, community]);

  const body = useMemo(() => {
    if (eventId) {
      return emailData?.body || null;
    }

    return t('communityInvitation.body');
  }, [eventId, emailData]);

  const title = eventId
    ? null
    : `${t('communityInvitation.subjectFirst', {
        returnObjects: true,
      })}\n\n${community.name}\n${t(
        'communityInvitation.subjectSecond',
      )} - Gumb`;

  const copyToClipboard = async () => {
    const value = inputRef.current.value;

    await navigator.clipboard.writeText(value);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 1500);
  };

  const closePopup = () => {
    setOpen(false);
    dispatch(closeModal());
  };

  useOutsideClick(wrapperRef, closePopup);

  if (!open) {
    return null;
  }

  return (
    <Wrapper>
      <StyledPopup ref={wrapperRef}>
        {loaded ? (
          <PopupWrapper>
            <CloseButton onClick={closePopup} />
            <input type="hidden" value={url} ref={inputRef} />
            <Title>{t('common.shareEventTitle')}</Title>
            <ShareButtons>
              <IconWrapper>
                <EmailShareButton
                  url={url}
                  subject={subject}
                  body={body}
                  separator={'\n\n'}
                >
                  <EmailIcon size={42} round />
                  <Text>Email</Text>
                </EmailShareButton>
              </IconWrapper>
              <IconWrapper>
                <WhatsappShareButton
                  url={url}
                  title={title}
                  separator={eventId ? null : '\n\n'}
                >
                  <WhatsappIcon size={42} round />
                  <Text>Whatsapp</Text>
                </WhatsappShareButton>
              </IconWrapper>
              <IconWrapper>
                <TelegramShareButton url={url} title={title}>
                  <TelegramIcon size={42} round />
                  <Text>Telegram</Text>
                </TelegramShareButton>
              </IconWrapper>
              <IconWrapper>
                <FacebookShareButton url={url} quote={title}>
                  <FacebookIcon size={42} round />
                  <Text>Facebook</Text>
                </FacebookShareButton>
              </IconWrapper>
              <IconWrapper>
                <TwitterShareButton url={url} title={title}>
                  <TwitterIcon size={42} round />
                  <Text>Twitter</Text>
                </TwitterShareButton>
              </IconWrapper>
              <IconWrapper>
                <LinkedinShareButton url={url} title={title}>
                  <LinkedinIcon size={42} round />
                  <Text>Linkedin</Text>
                </LinkedinShareButton>
              </IconWrapper>
              <IconWrapper>
                <VKShareButton url={url} title={title}>
                  <VKIcon size={42} round />
                  <Text>VK</Text>
                </VKShareButton>
              </IconWrapper>
              <IconWrapper>
                <ViberShareButton
                  url={url}
                  title={title}
                  separator={eventId ? null : '\n\n'}
                >
                  <ViberIcon size={42} round />
                  <Text>Viber</Text>
                </ViberShareButton>
              </IconWrapper>
            </ShareButtons>
            <Title padding={'24px'}>{t('common.copyEventLink')}</Title>
            <StyledInvitationLink
              onClick={copyToClipboard}
              linkCopied={linkCopied}
            >
              {linkCopied ? t('common.copied') : t('common.copyLink')}
              <ShareIcon />
            </StyledInvitationLink>
          </PopupWrapper>
        ) : (
          <Loader />
        )}
      </StyledPopup>
    </Wrapper>
  );
};

export default SharePopup;
