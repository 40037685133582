import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControllerRenderProps,
  FieldName,
  SetFieldValue,
  SetValueConfig,
} from 'react-hook-form';

import { ReplyStates } from 'constants/index';
import { ClockIconGreen, InfoIconGreen } from 'static';
import {
  PenaltyButton,
  AttendedButton,
  NumberInput,
  Divider,
} from 'components';
import { FIELDS, ReplyFormData } from '../../../pages/UserProfileEvents/form';

import {
  StyledTitle,
  StyledIconTextBlock,
  StyledText,
  StyledTextBlock,
  StyledButtonsBlock,
  NumberInputWrapper,
  BlackDot,
  ButtonWrapper,
} from './styled';
import { StyledContainer } from '../styled';
import ChangeSidebarBox from 'sidebars/common/ChangeSidebarBox';

interface GiveReplyEventProps {
  title: string;
  date: string;
  timeInterval?: string;
  description: string;
  isRepeated: boolean;
  isAdmin: boolean;
  isPenalty: boolean;
  isAttended: boolean;
  canEdit: boolean;
  repeatString: string;
  sidebarState: ReplyStates;
  guestsFieldProps: ControllerRenderProps<ReplyFormData>;
  onViewEventClick: () => void;
  setValue: (
    name: FieldName<ReplyFormData>,
    value: SetFieldValue<ReplyFormData>,
    config?: SetValueConfig,
  ) => void;
}

const GiveReplyEvent: FC<GiveReplyEventProps> = ({
  title,
  date,
  description,
  isRepeated,
  repeatString,
  isAdmin,
  sidebarState,
  isPenalty,
  isAttended,
  canEdit,
  guestsFieldProps,
  timeInterval,
  onViewEventClick,
  setValue,
}) => {
  const { t } = useTranslation();
  const handleOpenSideBar = (data?: string) => {
    if (data === 'viewAppointment') {
      onViewEventClick();
    }
  };
  return (
    <StyledContainer>
      <ChangeSidebarBox onClick={handleOpenSideBar} primary={false} />
      <Divider customMargin="0px 0px 16px 0px" />
      <StyledTitle>{title}</StyledTitle>
      <NumberInputWrapper>
        {isAdmin && sidebarState !== ReplyStates.CREATE && canEdit && (
          <>
            <ButtonWrapper>
              <AttendedButton
                checked={isAttended}
                onChange={() => setValue(FIELDS.ATTENDED, !isAttended)}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <PenaltyButton
                checked={isPenalty}
                onChange={() => setValue(FIELDS.PENALTY, !isPenalty)}
              />
            </ButtonWrapper>
          </>
        )}
        <ButtonWrapper
          onlyInputNumber={
            isAdmin && sidebarState !== ReplyStates.CREATE && canEdit
              ? true
              : false
          }
        >
          <NumberInput
            label={t('event.addGuests')}
            height={
              isAdmin && sidebarState !== ReplyStates.CREATE && canEdit
                ? '28px'
                : '35px'
            }
            width="100%"
            value={guestsFieldProps.value}
            onAddNumber={() => {
              guestsFieldProps.onChange(guestsFieldProps.value + 1);
            }}
            onSubtractNumber={() => {
              if (guestsFieldProps.value > 0) {
                guestsFieldProps.onChange(guestsFieldProps.value - 1);
              }
            }}
            disabled={!canEdit}
          />
        </ButtonWrapper>
      </NumberInputWrapper>
    </StyledContainer>
  );
};

export default GiveReplyEvent;
