import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';

import { TabHeader, TabList, ContentPlaceholder, Loader } from 'components';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useEventTypes } from './hooks';
import { TabListContainer } from '../styled';
import { getdefaultEventSettings } from 'store/selectors/defaultEventSetting';

const EventTypesPage = () => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const eventTypesAndDefaultSeeting = useSelector(getdefaultEventSettings);

  const filteredEventTypeAndDefaultSeetings = eventTypesAndDefaultSeeting
    ?.filter((eventType) => eventType.eventType?.deletedAt === null)
    .sort(
      (a, b) =>
        new Date(a.eventType.createdAt).getTime() -
        new Date(b.eventType.createdAt).getTime(),
    );

  const { onAdd, onEdit, onDelete } = useEventTypes(community.id);

  return filteredEventTypeAndDefaultSeetings ? (
    <>
      <TabHeader
        title={t('communitySettings.tabs.eventTypes')}
        onAdd={onAdd}
        text={t('common.toolTipText.eventType')}
      />
      {!isEmpty(eventTypesAndDefaultSeeting) ? (
        <TabListContainer>
          <TabList
            items={filteredEventTypeAndDefaultSeetings}
            onEdit={onEdit}
            onDelete={onDelete}
            onDefaultSetting={onEdit}
          />
        </TabListContainer>
      ) : (
        <ContentPlaceholder />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default EventTypesPage;
