import { createSelector } from 'reselect';

import { getAuthUser } from './auth';
import { IRootState } from '../reducers';

export const selectGroupId = (state: IRootState, groupId: string) => groupId;
export const statisticsSelector = (state: IRootState) => state.statistics;

const usersInCommunitySelector = (state: IRootState) =>
  state.currentCommunity.data?.users;

export const getCurrentUserInCommunity = createSelector(
  [usersInCommunitySelector, getAuthUser],
  (users, currentUser) => users?.filter((u) => u.id === currentUser.userId)[0],
);

export const getStatistics = createSelector(
  statisticsSelector,
  (statistics) => statistics?.data,
);

export const getStatisticsLoaders = createSelector(
  statisticsSelector,
  (statistics) => statistics?.loaders,
);

export const getAppointmentScheduleStatistic = createSelector(
  getStatistics,
  (statistics) => statistics.appointmentScheduleStatistics,
);

export const getAppointmentScheduleStatisticTotal = createSelector(
  getStatistics,
  (statistics) => statistics.appointmentScheduleStatisticsTotal,
);

export const getAppointmentScheduleStatisticLoading = createSelector(
  getStatisticsLoaders,
  (loaders) => loaders.getAppointmentScheduleStatistics,
);

export const getAppointmentScheduleStatisticTotalLoading = createSelector(
  getStatisticsLoaders,
  (loaders) => loaders.getAppointmentScheduleStatisticsTotal,
);

export const getMemberFiguresStatistics = createSelector(
  getStatistics,
  (statistics) => statistics.memberFiguresStatistics,
);

export const getMemberFiguresStatisticsTotal = createSelector(
  getStatistics,
  (statistics) => statistics.memberFiguresStatisticsTotal,
);

export const getMemberFiguresStatisticsLoading = createSelector(
  getStatisticsLoaders,
  (loaders) => loaders.getMemberFiguresStatistics,
);

export const getMemberFiguresStatisticsTotalLoading = createSelector(
  getStatisticsLoaders,
  (loaders) => loaders.getMemberFiguresStatisticsTotal,
);
