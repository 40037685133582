import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ReplyStates } from 'constants/index';
import { EditEventType } from 'constants/sidebars';
import { useClickOutsideClose, useDateDetails } from 'hooks';
import { getSettings } from 'store/selectors/auth';
import { openModal, closeModal } from 'store/actionCreators/modal';
import {
  Sidebar,
  SidebarHeader,
  SidebarFooter,
  Tabs,
  TextArea,
  Button,
  Loader,
  PopupEventWrapper,
} from 'components';
import { CommentIcon } from 'static';
import EventTab from './Event';
import TasksTab from './Tasks';
import { useGiveReply } from './hooks';
import { FIELDS } from '../../pages/UserProfileEvents/form';
import { getEventDetailsSideBarWidth } from 'utils/getEventDetailsSideBarWidth';
import { useSingleEvent } from '../../hooks/useSingleEvent';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { getData } from 'store/selectors/modal';
import { getEventResponses } from 'store/selectors/eventResponses';
import {
  getCreateEventReplyLoading,
  getUpdateEventReplyLoading,
} from 'store/selectors/eventReplies';
import { GiveReplySidebarData, ModalTypes } from 'types';

import {
  StyledFooter,
  StyledResponseQuestionText,
  StyledResponsesBlock,
  StyledResponseButton,
  StyledResponseRadio,
  StyledResponseLabel,
  StyledForm,
  StyledContentWrapper,
  TaskWrapper,
  CommentWrapper,
} from './styled';

interface GiveReplyProps {
  filterFrom: Date;
  filterTo: Date;
}

const GiveReply: FC<GiveReplyProps> = ({ filterFrom, filterTo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToasts();
  const community = useSelector(getCurrentCommunity);
  const responses = useSelector(getEventResponses);
  const isCreateEventReplyLoading = useSelector(getCreateEventReplyLoading);
  const isUpdateEventReplyLoading = useSelector(getUpdateEventReplyLoading);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const refModal = React.createRef<HTMLDivElement>();
  const timeFormat = useSelector(getSettings)?.timeFormat;
  const modalData = useSelector(getData) as GiveReplySidebarData;
  const { date, eventId, userId, isAdmin, isViewOnly, name, subReply } =
    modalData;
  const isLoading = isCreateEventReplyLoading || isUpdateEventReplyLoading;

  const event = useSingleEvent(community.id, eventId);

  const { repeatString, dateStringWithDay, timeInterval } = useDateDetails(
    event,
    date,
    timeFormat,
  );

  let sidebarState;

  if (isViewOnly && userId !== currentUser.id) {
    sidebarState = ReplyStates.VIEW;
  } else if (subReply) {
    sidebarState = ReplyStates.UPDATE_THIS;
  } else {
    sidebarState = ReplyStates.CREATE;
  }

  const closeSidebar = () => {
    if (location.pathname.includes('/event/')) {
      navigate(location.pathname.split('/event/')[0], { replace: true });
    }
    dispatch(closeModal());
  };

  useClickOutsideClose({ ref: refModal, callback: closeSidebar });

  const {
    onSubmit,
    register,
    guestsFieldProps,
    replyFieldProps,
    tasksFieldProps,
    values,
    reset,
    setValue,
    setError,
    errors,
  } = useGiveReply({
    event,
    eventDate: date,
    sidebarState,
    subReply,
    filterFrom,
    filterTo,
    userId,
    closeSidebar,
  });

  useEffect(() => {
    const {
      eventResponseId = null,
      comment = '',
      tasks = [],
      guests = 0,
      penalty = false,
      attended = false,
    } = subReply || {};

    reset({
      [FIELDS.EVENT_RESPONSE_ID]: eventResponseId,
      [FIELDS.COMMENT]: comment,
      [FIELDS.TASKS]: tasks,
      [FIELDS.GUESTS]: guests,
      [FIELDS.PENALTY]: penalty,
      [FIELDS.ATTENDED]: attended,
    });
  }, [event]);

  const isCommentValid =
    values.comment ||
    !responses.filter((r) => r.id === replyFieldProps.value)[0]
      ?.isCommentRequired;

  const submitFromButton = async (type) => {
    if (isCommentValid || values[FIELDS.COMMENT]) {
      onSubmit(type);
    } else {
      setError(FIELDS.COMMENT, {
        type: 'required',
        message: t('errors.required'),
      });
    }
  };

  const canEditFields = useMemo(() => {
    // isViewOnly shows whether user has permissions to change event replies

    if (!isViewOnly) {
      return true;
    }

    if (userId !== currentUser.id) {
      return false;
    } else {
      const isAfterRegDeadline = event?.isAfterRegDeadline || false;

      return !isAfterRegDeadline;
    }
  }, [isViewOnly]);

  useEffect(() => {
    if (!canEditFields) {
      if (event?.isAfterRegDeadline) {
        addToast(t('event.deadlineExpired'), {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast(t('event.noPermissionToGiveReply'), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }, []);

  const editPopupButtons = [
    {
      popupButtonText: t('event.eventOperations.eventOnly'),
      handleConfirm: () => submitFromButton(EditEventType.SINGLE_EVENT),
    },
    {
      popupButtonText: t('event.eventOperations.eventAndFollowUp'),
      handleConfirm: () => submitFromButton(EditEventType.SINGLE_AND_FOLLOW),
    },
    {
      popupButtonText: t('event.eventOperations.all'),
      handleConfirm: () => submitFromButton(EditEventType.FULL_EVENT),
    },
  ];

  const handleViewEventClick = () => {
    closeSidebar();
    dispatch(
      openModal({
        type: ModalTypes.EVENT_DETAILS,
        placement: 'right',
        data: {
          eventId,
          date,
          width: getEventDetailsSideBarWidth(responses.length),
          filterFrom,
          filterTo,
        },
      }),
    );
  };

  const buttonDisabled = useMemo(() => {
    if (isLoading) {
      return true;
    }

    return sidebarState === ReplyStates.CREATE && !replyFieldProps.value;
  }, [isLoading, sidebarState, replyFieldProps]);

  const submitVisible = useMemo(() => {
    return sidebarState !== ReplyStates.VIEW;
  }, [sidebarState]);

  if (!event) {
    return null;
  }

  return (
    <>
      <Sidebar ref={refModal}>
        <SidebarHeader onCloseClick={closeSidebar} label={name} />
        <StyledForm submitVisible={submitVisible} onSubmit={onSubmit}>
          <StyledContentWrapper>
            <Tabs isSidebar>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <div label={t('event.event')} index={0}>
                <EventTab
                  timeInterval={timeInterval}
                  title={event?.title}
                  date={dateStringWithDay}
                  description={event?.description}
                  repeatString={repeatString}
                  isRepeated={event?.isRepeated}
                  isAdmin={isAdmin}
                  sidebarState={sidebarState}
                  isPenalty={values[FIELDS.PENALTY]}
                  isAttended={values[FIELDS.ATTENDED]}
                  setValue={setValue}
                  canEdit={canEditFields}
                  guestsFieldProps={guestsFieldProps}
                  onViewEventClick={handleViewEventClick}
                />
                <TaskWrapper>
                  <TasksTab
                    inInEventTab
                    event={event}
                    canEdit={canEditFields}
                    tasksFieldProps={tasksFieldProps}
                  />
                </TaskWrapper>
              </div>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <div label={t('common.tasks')} index={1}>
                <TasksTab
                  event={event}
                  canEdit={canEditFields}
                  tasksFieldProps={tasksFieldProps}
                />
              </div>
            </Tabs>
            <StyledFooter>
              <StyledResponseQuestionText>
                {t('event.isMemberComing')}
              </StyledResponseQuestionText>
              <StyledResponsesBlock disabled={!canEditFields}>
                {responses.map((response) => (
                  <StyledResponseButton key={response.id}>
                    <StyledResponseRadio
                      color={response.color}
                      name="responses"
                      type="radio"
                      id={response.id}
                      value={response.id}
                      checked={replyFieldProps.value === response.id}
                      onClick={() =>
                        replyFieldProps.onChange(
                          replyFieldProps.value === response.id
                            ? null
                            : response.id,
                        )
                      }
                    />
                    <StyledResponseLabel
                      color={response.color}
                      htmlFor={response.id}
                    >
                      {response.title}
                      {response.isCommentRequired && <CommentIcon />}
                    </StyledResponseLabel>
                  </StyledResponseButton>
                ))}
              </StyledResponsesBlock>
            </StyledFooter>
          </StyledContentWrapper>
          <CommentWrapper>
            <TextArea
              width="100%"
              rows={2}
              label={t('common.addComment')}
              name={FIELDS.COMMENT}
              disabled={
                !canEditFields &&
                (userId !== currentUser?.id ||
                  sidebarState === ReplyStates.CREATE)
              }
              register={register}
              error={errors[FIELDS.COMMENT]}
            />
          </CommentWrapper>
          {submitVisible && (
            <SidebarFooter>
              {event?.eventSeriesId ? (
                <PopupEventWrapper
                  popupButtons={editPopupButtons}
                  popupMessageText={t('event.eventOperations.editReplyText')}
                  darkBackground={true}
                >
                  <Button
                    type="button"
                    variant={isLoading ? 'secondary' : null}
                    disabled={buttonDisabled}
                    width="100%"
                  >
                    {isLoading ? (
                      <Loader type="button" size="28px" thickness={2} />
                    ) : (
                      t('common.answer')
                    )}
                  </Button>
                </PopupEventWrapper>
              ) : (
                <Button
                  type="button"
                  variant={isLoading ? 'secondary' : null}
                  disabled={buttonDisabled}
                  onClick={() => submitFromButton(EditEventType.SINGLE_EVENT)}
                >
                  {isLoading ? (
                    <Loader type="button" size="28px" thickness={2} />
                  ) : (
                    t('common.answer')
                  )}
                </Button>
              )}
            </SidebarFooter>
          )}
        </StyledForm>
      </Sidebar>
    </>
  );
};

export default GiveReply;
