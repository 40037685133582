import React, { FC } from 'react';

import {
  DocumentItemContainer,
  DocumentName,
  StyledArrowIcon,
  StyledDocumentIcon,
  StyledLink,
} from './styled';

interface DocumentItemProps {
  link: string;
  documentName: string;
}

const DocumentItem: FC<DocumentItemProps> = ({ link, documentName }) => (
  <StyledLink to={link}>
    <DocumentItemContainer>
      <StyledDocumentIcon />
      <DocumentName>{documentName}</DocumentName>
    </DocumentItemContainer>
    <StyledArrowIcon />
  </StyledLink>
);

export default DocumentItem;
