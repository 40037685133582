import _ from 'lodash';

import { Event } from 'types';
import { FIELDS } from '../sidebars/CreateEventOverall/form';

const eventFilterFields = [
  'title',
  'location',
  'description',
  'eventTypeId',
  'from',
  'to',
  'tasks',
];

export default (initial: Event, final: Event): FIELDS[] => {
  const diffs = _.reduce(
    initial,
    function (result, value, key) {
      return _.isEqual(value, final[key]) ? result : result.concat(key);
    },
    [],
  );

  return diffs.filter((i) => eventFilterFields.includes(i));
};
