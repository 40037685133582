// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { assoc, compose } from 'lodash/fp';
import { Channel, StreamChat } from 'stream-chat';

import {
  doLoginChat,
  doLogoutChat,
  doCreateChat,
  doAddMembers,
  doGetChats,
  doSetCurrentChat,
} from '../actionCreators/chat';

export interface ChatState {
  client: StreamChat;
  isAuthenticated: boolean;
  isLoading: boolean;
  chats: Channel[];
  currentChat: Channel | null;
}

const initialState: ChatState = {
  client: null,
  isAuthenticated: false,
  isLoading: false,
  chats: [],
  currentChat: null,
};

export default (
  state: ChatState = initialState,
  { type, payload },
): ChatState => {
  switch (type) {
    case doLoginChat.FAILURE:
    case doLogoutChat.SUCCESS:
      return compose(
        assoc(['client'], null),
        assoc(['isAuthenticated'], false),
        assoc(['isLoading'], false),
      )(state);

    case doCreateChat.REQUEST:
    case doAddMembers.REQUEST:
    case doLoginChat.TRIGGER:
    case doGetChats.TRIGGER:
      return compose(assoc(['isLoading'], true))(state);

    case doLoginChat.SUCCESS:
      return compose(
        assoc(['client'], payload),
        assoc(['isAuthenticated'], true),
        assoc(['isLoading'], false),
      )(state);

    case doGetChats.SUCCESS:
      return compose(
        assoc(['chats'], payload),
        assoc(['isLoading'], false),
      )(state);

    case doGetChats.FAILURE:
      return compose(assoc(['chats'], []), assoc(['isLoading'], false))(state);

    case doSetCurrentChat.SUCCESS:
      return compose(assoc(['currentChat'], payload))(state);

    case doCreateChat.SUCCESS:
    case doAddMembers.SUCCESS:
      return compose(assoc(['isLoading'], false))(state);

    default:
      return state;
  }
};
