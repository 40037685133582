import { NotificationType } from 'pages/CommunitySettings/NotificationsTab/types';
import { CommunityNotifications, Event } from 'types';
import { FIELDS } from '../sidebars/CreateEventOverall/form';
import { CreateEventStates } from '../constants';

export default (
  type: NotificationType | CreateEventStates,
  event: Event,
  notificationsSettings: CommunityNotifications,
  updatedFields: FIELDS[] = [],
): boolean => {
  const typeSettings = notificationsSettings.events[type];
  const values = Object.values(typeSettings);

  if (values.every((t) => !t.enabled)) {
    return true;
  }

  if (
    type === NotificationType.EVENT_UPDATE &&
    values.some((t) => t.fields?.length > 0)
  ) {
    const watchedFields = [...new Set(values.map((i) => i.fields).flat())];

    if (updatedFields.every((f) => !watchedFields.includes(f))) {
      return true;
    }
  }

  if (
    (type === NotificationType.EVENT_UPDATE ||
      type === NotificationType.EVENT_DELETE) &&
    values.some(
      (t) => t.replies?.length > 0 || t.penalty || t.attended || t.awaiting,
    )
  ) {
    const possibleRepliesFilters = [
      ...new Set(values.map((i) => i.replies).flat()),
    ];
    const filteredByPenalty = values.some((i) => i.penalty);
    const filteredByAttended = values.some((i) => i.attended);
    const filteredByAwaiting = values.some((i) => i.awaiting);

    if (event.subEventReplies.length === 0) {
      if (!filteredByAwaiting) {
        return true;
      }
    } else {
      const hasRepliesWithPenalty = !!event.subEventReplies.filter(
        (r) => r.penalty,
      ).length;
      const hasRepliesWithAttended = !!event.subEventReplies.filter(
        (r) => r.attended,
      ).length;
      const hasFilteredReplies = !!event.subEventReplies.filter((r) =>
        possibleRepliesFilters.includes(r.eventResponseId),
      ).length;

      if (
        !(filteredByPenalty && hasRepliesWithPenalty) &&
        !(filteredByAttended && hasRepliesWithAttended) &&
        !(possibleRepliesFilters.length !== 0 && hasFilteredReplies)
      ) {
        return true;
      }
    }
  }

  return false;
};
