import styled, { css } from 'styled-components';

import { CellsTypes } from 'constants/index';

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`;

export const StyledHeader = styled.thead`
  color: ${({ theme }) => theme.color.baliHai};
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  max-height: 30px;
`;

export const StyledHeaderRow = styled.tr`
  max-height: 30px;

  ${({ withPadding }) =>
    withPadding &&
    css`
      & > th:first-of-type {
        padding: 4px 15px;
      }
    `}
`;

export const StyledHeaderCell = styled.th`
  max-height: 30px;
  min-width: ${({ width }) => `${width}`};
  font-size: 12px;
  font-weight: 600;
  ${({ type }) =>
    type === CellsTypes.DRAGNDROP &&
    css`
      padding-right: 10px;
      text-align: right;
    `}
  ${({ headerType }) =>
    headerType === 'button' &&
    css`
      text-align: center;
    `}
`;

export const StyledBody = styled.tbody`
  font-size: 14px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 500;
`;
