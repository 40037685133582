import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  width: 100vw;
  height: 100svh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.dimmer};
  z-index: 10000000000000000;
  left: 0;
  top: 0;
`;

export const Trigger = styled.div`
  pointer-events: all;
  cursor: pointer;
  width: 100%;
`;
