import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { getCurrentCommunity } from './currentCommunity';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;

export const getdefaultEventSettings = createSelector(
  getCurrentCommunity,
  (community) => community.defaultEventSetting || null,
);

export const getdefaultEventSettingLoading = createSelector(
  currentCommunitySelector,
  (community) =>
    community.loaders.createDefaultEventSetting ||
    community.loaders.editDefaultEventSetting ||
    community.loaders.deleteDefaultEventSetting,
);
