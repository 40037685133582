import React from 'react';
import { isEmpty } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TabHeader, TabList, ContentPlaceholder, Loader } from 'components';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getEventResponses } from 'store/selectors/eventResponses';
import { useResponses } from './hooks';
import { TabListContainer } from '../styled';

const ResponsesPage = () => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const responses = useSelector(getEventResponses);

  const { onAdd, onEdit, onDelete } = useResponses(community.id);

  return responses ? (
    <>
      <TabHeader
        title={t('communitySettings.tabs.responses')}
        onAdd={onAdd}
        text={t('common.toolTipText.responses')}
      />
      {!isEmpty(responses) ? (
        <TabListContainer>
          <TabList items={responses} onDelete={onDelete} onEdit={onEdit} />
        </TabListContainer>
      ) : (
        <ContentPlaceholder />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default ResponsesPage;
