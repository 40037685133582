import React, { FC, useState, useEffect } from 'react';

import { getUTCDateFromDate } from 'utils';
import { SearchInput, SidebarContent, Table } from 'components';
import { CellsTypes } from 'constants/index';
import { EventTask, Event, Profile } from 'types';

import { StyledSearchWrapper, SidebarContentWrapper } from './styled';

const mockTasksTableConfig = {
  title: 'Tasks',
  headers: [
    {
      id: 'name',
      width: '100%',
      type: CellsTypes.TASK_WITH_SELECT,
      headerType: 'title',
    },
  ],
};

const mockMembersTableConfig = {
  title: 'Members',
  headers: [
    {
      id: 'name',
      width: '100%',
      type: CellsTypes.AVATAR_NAME,
      headerType: 'title',
    },
  ],
};

type FilteredTask = EventTask & { onClick: () => void };

interface EventDetailsTasksProps {
  tasks: EventTask[];
  selectedTask: string;
  event: Event;
  users: Profile[];
  date: Date;
  setSelectedTask: (id: string) => void;
}

const EventDetailsTasks: FC<EventDetailsTasksProps> = ({
  tasks = [],
  selectedTask,
  event,
  users,
  date,
  setSelectedTask,
}) => {
  const [filteredTasks, setTasks] = useState<FilteredTask[]>(
    tasks.map((t) => ({ ...t, onClick: () => setSelectedTask(t.id) })),
  );
  const [inputValue, setValue] = useState<string>('');
  const [taskUsers, setTaskUsers] = useState<Profile[]>([]);

  const handleChange = (text) => {
    const newTasks = tasks.filter(({ title }) =>
      `${title}`.toLocaleLowerCase().includes(text.toLowerCase()),
    );
    setTasks(
      newTasks.map((t) => ({ ...t, onClick: () => setSelectedTask(t.id) })),
    );
    setValue(text);
  };

  useEffect(() => {
    const subTaskReplies = event.subEventTaskReplies
      ?.filter((t) => t.eventTaskId === selectedTask)
      .filter(
        (t) =>
          new Date(t.date).getTime() ===
          getUTCDateFromDate(new Date(date)).getTime(),
      );
    const filteredTaskReplies = subTaskReplies?.filter(
      (task, i, self) => i === self.findIndex((t) => t.userId === task.userId),
    );

    setTaskUsers(
      users.filter((u) =>
        filteredTaskReplies.some((r) => r.userId === u.userId),
      ),
    );
  }, [selectedTask]);

  return (
    <SidebarContent
      height={'calc(100svh - 206px)'}
      smHeight={'calc(100svh - 170px)'}
    >
      {selectedTask ? (
        <Table data={taskUsers} config={mockMembersTableConfig} />
      ) : (
        <>
          <StyledSearchWrapper>
            <SearchInput
              width="100%"
              value={inputValue}
              fillWidth={true}
              onChange={({ target }) => handleChange(target.value)}
            />
          </StyledSearchWrapper>
          <SidebarContentWrapper>
            <Table data={filteredTasks} config={mockTasksTableConfig} />
          </SidebarContentWrapper>
        </>
      )}
    </SidebarContent>
  );
};

export default EventDetailsTasks;
