import React, { FC } from 'react';
import { useMedia } from 'react-use';
import { StyledCard } from './styled';
import { breakpoints } from 'theme';

interface CardProps {
  children: JSX.Element | JSX.Element[];
  cardRef?: React.RefObject<HTMLElement>;
  fluid?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  greenBorder?: boolean;
}

const Card: FC<CardProps> = ({
  children,
  cardRef = () => {},
  fluid,
  disabled,
  size = 'md',
  greenBorder,
}) => {
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const sizeToWidth = {
    sm: '340px',
    md: '550px',
    lg: '730px',
  };

  return (
    <StyledCard
      ref={cardRef}
      fluid={fluid}
      disabled={disabled}
      width={sizeToWidth[size]}
      greenBorder={greenBorder}
      size={size}
      isMobile={isDownSmPlus}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
