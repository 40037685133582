import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ADD_GROUP_MEMBERS } from 'constants/sidebars';
import { openModal, closeModal } from 'store/actionCreators/modal';
import { doCreateGroup } from 'store/actionCreators/groups';
import { useCreateGroupForm } from './form';

export const useCreateGroup = (communityId: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { handleSubmit, ...form } = useCreateGroupForm();

  const onSuccess = (data) => {
    dispatch(closeModal());
    addToast(t('group.createSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
    dispatch(
      openModal({
        type: ADD_GROUP_MEMBERS,
        placement: 'right',
        data: {
          group: data,
          communityId,
        },
      }),
    );
  };

  const onSubmit = handleSubmit(({ name }) =>
    dispatch(
      doCreateGroup({
        communityId,
        name,
        onSuccess,
      }),
    ),
  );

  return {
    onSubmit,
    ...form,
  };
};
