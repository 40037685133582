import styled from 'styled-components';

import { getZoomedValue } from 'utils/zoom';

export const StyledAvatar = styled.img`
  width: ${({ size, zoom }) =>
    getZoomedValue(size === 'sm' ? 30 : 40, zoom, 'px')};
  height: ${({ size, zoom }) =>
    getZoomedValue(size === 'sm' ? 30 : 40, zoom, 'px')};
  min-width: ${({ size, zoom }) =>
    getZoomedValue(size === 'sm' ? 30 : 40, zoom, 'px')};
  border-radius: 50%;
  object-fit: cover;
  margin-top: ${({ size, zoom }) =>
    getZoomedValue(size === 'sm' ? 0 : 8, zoom, 'px')};
  margin-right: ${({ zoom }) => getZoomedValue(10, zoom, 'px')};
  margin-bottom: ${({ size, zoom }) =>
    getZoomedValue(size === 'sm' ? 0 : 10, zoom, 'px')};
`;
