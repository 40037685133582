import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useFieldArray, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

import { CommunityRoles, CommunityUser, GroupRoles } from 'types';

export enum FIELDS {
  MEMBER_EMAIL = 'email',
  ROLE = 'role',
  GROUPS = 'groups',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export type InviteMemberFormData = {
  [FIELDS.MEMBER_EMAIL]: string;
  [FIELDS.ROLE]: { value: CommunityRoles; label: string };
  [FIELDS.GROUPS]: {
    role: { value: GroupRoles; label: string };
    value: string;
    label: string;
  }[];
  [FIELDS.FIRST_NAME]?: string;
  [FIELDS.LAST_NAME]?: string;
};

export const useInviteMemberForm = (
  users: CommunityUser[],
  nameFieldsShown: boolean,
  defaultValues: InviteMemberFormData,
) => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<InviteMemberFormData> = yupResolver(
    yup.object().shape({
      [FIELDS.MEMBER_EMAIL]: yup
        .string()
        .test(
          'notInCommunity',
          t('communitySettings.memberExists'),
          (value) => {
            return !users.some((u) => u.profile?.email === value);
          },
        )
        .required()
        .max(50)
        .min(3)
        .email()
        .trim(),
      [FIELDS.ROLE]: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
      [FIELDS.GROUPS]: yup.array().of(
        yup.object().shape({
          role: yup.object().shape({
            value: yup.string().required(),
            label: yup.string().required(),
          }),
          value: yup.string().required(),
        }),
      ),
      [FIELDS.LAST_NAME]: nameFieldsShown
        ? yup.string().min(2).trim().required()
        : yup.string().min(2).trim(),
      [FIELDS.FIRST_NAME]: nameFieldsShown
        ? yup.string().min(2).trim().required()
        : yup.string().min(2).trim(),
    }),
  );

  const form = useForm<InviteMemberFormData>({
    resolver,
    defaultValues,
  });

  const groupsFieldsProps = useFieldArray({
    name: FIELDS.GROUPS,
    control: form.control,
  });

  return { ...form, groupsFieldsProps };
};
