import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledMenuItemBlock = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 1px;
  padding: 10px 15px 10px 16px;
  border-radius: 6px;
`;
export const StyledMenuItemLinkBlock = styled(NavLink)`
  flex-grow: 1;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 10px;
  letter-spacing: 1px;
  padding: 10px 15px 10px 16px;
  border-radius: 6px;
  ${({ menuBlockStyles }) => menuBlockStyles}

  &.active {
    background-color: ${({ theme }) => theme.color.blackSqueeze};
    span {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
    & > div {
      & > svg {
        fill: ${({ theme }) => theme.color.pickledBluewood};
        path {
          fill: ${({ theme }) => theme.color.pickledBluewood};
          stroke: ${({ theme }) => theme.color.pickledBluewood};
        }
        circle {
          stroke: ${({ theme }) => theme.color.pickledBluewood};
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 18px;
      left: ${({ left }) => (left ? '5px' : '9px')};
      background-color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
    & > div {
      & > svg {
        path {
          fill: ${({ theme }) => theme.color.pickledBluewood};
          stroke: ${({ theme }) => theme.color.pickledBluewood};
        }
        circle {
          stroke: ${({ theme }) => theme.color.pickledBluewood};
        }
      }
    }

    background-color: ${({ theme }) => theme.color.blackSqueeze};
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 18px;
      left: ${({ left }) => (left ? '5px' : '9px')};
      background-color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 12px;
    ${({ iconStyles }) => iconStyles};
  }
`;

export const StyledRightIcon = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900000;
  > div {
    width: 31px;
    height: 31px;
    transition: all 0.2s linear;
    [aria-label='NEW'] {
      fill: white !important;
    }
    svg {
      [fill='rgb(15,204,198)'] {
        fill: #33aba7 !important;
      }
    }
    :hover {
      /* width: 33px !important; */
      /* height: 33px !important; */
      scale: 1.2;
      [aria-label='NEW'] {
        fill: ${({ theme }) => theme.color.pickledBluewood} !important;
      }
    }
  }
`;

export const StyledTitle = styled.span`
  font-size: 13px;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.baliHai};
  text-align: left;
  text-decoration: none;
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
`;
