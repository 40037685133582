import styled from 'styled-components';

import { breakpoints } from 'theme';

export const GridWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 340px 1fr;

  @media ${breakpoints.downMdPlus} {
    display: block;
  }
`;

export const GridGhostWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 123132;
`;

export const ContentContainer = styled.div`
  min-width: 100%;
  padding-left: 30px;
  position: relative;
  height: calc(100svh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

export const EventSectionContainer = styled.div``;

export const StyledSectionTitle = styled.div`
  height: 34px;
  font-size: 16px;
  position: relative;
  color: ${({ theme }) => theme.color.pickledBluewood};
  padding-top: 12px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;

  &:before {
    content: '';
    display: inline-block;
    background-color: rgb(16, 204, 198);
    width: 50px;
    height: 4px;
    position: absolute;
    top: 0px;
  }

  @media (max-width: 960px) {
    padding-left: 20px;
    padding-right: calc(50px - 15px - 3px);
  }

  @media ${breakpoints.downSmPlus} {
    padding-left: 0px;
    padding-right: calc(30px - 15px - 3px);
  }
`;

export const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;
