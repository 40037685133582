import React, { FC } from 'react';

import { taskIcons } from 'configs';

import { Wrapper, StyledText } from './styled';

interface IconTextProps {
  icon: string;
  text: string;
}

const IconText: FC<IconTextProps> = ({ icon, text }) => {
  const Icon = taskIcons[icon];

  return (
    <Wrapper>
      <Icon />
      <StyledText>{text}</StyledText>
    </Wrapper>
  );
};

export default IconText;
