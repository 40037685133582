// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { handleActions } from 'redux-actions';
import { assoc, compose, filter, get } from 'lodash/fp';

import {
  doRequestCommunities,
  doCreateCommunity,
  doUpdateCommunity,
  doRequestCommunityInvites,
  removeInviteOnAcceptOrReject,
  doDeleteCommunity,
  doRequestCommunityMatrix,
  doRefreshCommunities,
  doClearCommunities,
  doUpdateCommunityMatrixOnSelfDelete,
} from 'store/actionCreators/communities';
import {
  doCreateGroup,
  doUpdateGroup,
  doDeleteGroup,
} from 'store/actionCreators/groups';
import { CommunityListItem, Community } from '../../types';

type CommunitiesStateLoaders = {
  communities: boolean;
  communitiesLoaded: boolean;
  createCommunity: boolean;
  updateCommunity: boolean;
  deleteCommunity: boolean;
};

export interface CommunitiesState {
  data: CommunityListItem[];
  error: any;
  loading: boolean;
  currentCommunity: Community | null;
  loaders: CommunitiesStateLoaders;
  matrix: any;
  loadingMatrix: boolean;
}

const initialState: CommunitiesState = {
  data: [],
  error: null,
  loading: false,
  currentCommunity: null,
  loaders: {
    communities: false,
    communitiesLoaded: false,
    createCommunity: false,
    updateCommunity: false,
    deleteCommunity: false,
  },
  matrix: [],
  loadingMatrix: false,
};

export default handleActions<CommunitiesState, any>(
  {
    [doRequestCommunities.TRIGGER]: (state) =>
      compose(
        assoc(['loading'], true),
        assoc(['loaders', 'communities'], true),
      )(state),
    [doRequestCommunities.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loading'], false),
        assoc(['loaders', 'communities'], false),
        assoc(['loaders', 'communitiesLoaded'], true),
        assoc(['data'], payload.data),
      )(state),
    [doRequestCommunities.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loading'], false),
        assoc(['loaders', 'communities'], false),
        assoc(['error'], payload),
      )(state),
    [doRefreshCommunities.SUCCESS]: (state, { payload }) =>
      compose(assoc(['data'], payload.data))(state),
    [doCreateCommunity.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'createCommunity'], true))(state),
    [doCreateCommunity.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createCommunity'], false),
        assoc(['data'], [...state.data, payload]),
      )(state),
    [doCreateCommunity.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'createCommunity'], false),
        assoc(['error'], payload),
      )(state),
    [doUpdateCommunity.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateCommunity'], true))(state),
    [doUpdateCommunity.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateCommunity'], false),
        assoc(
          ['data'],
          [...state.data.map((i) => (i.id === payload.id ? payload : i))],
        ),
      )(state),
    [doUpdateCommunity.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'updateCommunity'], false),
        assoc(['error'], payload),
      )(state),
    [doDeleteCommunity.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteCommunity'], true))(state),
    [doDeleteCommunity.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteCommunity'], false),
        assoc(['data'], [...filter((c) => c.id !== payload, state.data)]),
      )(state),
    [doDeleteCommunity.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['loaders', 'deleteCommunity'], false),
        assoc(['error'], payload),
      )(state),
    [doUpdateGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          'data',
          state.data.map((community) =>
            community.id === payload.communityId
              ? {
                  ...community,
                  groups: community.groups.map((group) =>
                    group.id === payload.id ? payload : group,
                  ),
                }
              : community,
          ),
        ),
      )(state),
    [doUpdateGroup.FAILURE]: (state, { payload }) =>
      compose(assoc(['error'], payload))(state),
    [doCreateGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['data'],
          [
            ...state.data.map((i) =>
              i.id === payload.communityId
                ? {
                    ...i,
                    groups: i.groups ? [...i.groups, payload] : [payload],
                  }
                : i,
            ),
          ],
        ),
      )(state),
    [doCreateGroup.FAILURE]: (state, { payload }) =>
      compose(assoc(['error'], payload))(state),
    [doDeleteGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          'data',
          state.data.map((community) =>
            community.id === payload.communityId
              ? {
                  ...community,
                  groups: community.groups.filter(
                    (group) => group.id !== payload.groupId,
                  ),
                }
              : community,
          ),
        ),
      )(state),
    [doDeleteGroup.FAILURE]: (state, { payload }) =>
      compose(assoc(['error'], payload))(state),
    [doRequestCommunityInvites.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['data'],
          [
            ...state.data.map((c) => {
              const requests = payload.find(
                (r) => r.data[0]?.communityId === c.id,
              );

              if (requests) {
                return {
                  ...c,
                  requests: requests.data,
                };
              }

              return c;
            }),
          ],
        ),
      )(state),
    [doRequestCommunityMatrix.TRIGGER]: (state) =>
      compose(assoc(['loadingMatrix'], true))(state),
    [doRequestCommunityMatrix.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['loadingMatrix'], false),
        assoc(['matrix'], [...payload]),
      )(state),
    [doRequestCommunityMatrix.FAILURE]: (state) =>
      compose(assoc(['loadingMatrix'], false))(state),
    [doUpdateCommunityMatrixOnSelfDelete.SUCCESS]: (state, { payload }) =>
      state.matrix.length > 0
        ? compose(
            assoc(
              ['matrix'],
              state.matrix.filter(
                (community) => !(payload.communityId === community.id),
              ),
            ),
          )(state)
        : state,
    [removeInviteOnAcceptOrReject.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['data'],
          state.data.map((c) => {
            if (c.id === payload.communityId) {
              return {
                ...c,
                requests:
                  c.requests?.filter((r) => !payload.data.includes(r.id)) || [],
              };
            }

            return c;
          }),
        ),
      )(state),
    [doClearCommunities.TRIGGER]: (state) =>
      compose(assoc(['data'], []))(state),
  },
  initialState,
);
