import styled, { css } from 'styled-components';

const paginationItemAfter = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaginationBox = styled.div`
  display: flex;

  & > button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    outline: none;
    border: 1px solid ${({ theme }) => theme.color.botticelli};
    background: white;
    color: ${({ theme }) => theme.color.pickledBluewood};
    font-weight: bold;
    margin-left: -1px;
    cursor: pointer;
    transition: border-color 0.3s, color 0.3s;

    &:hover {
      border-color: ${({ theme }) => theme.color.pickledBluewood}!important;
      z-index: 10000;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const PaginationItem = styled.button`
  ${({ isActive, theme }) =>
    isActive
      ? `
        border: 2px solid ${theme.color.brightTurquoise}!important;
        z-index: 99;
    `
      : ''};
`;

export const PaginationEllipsis = styled.button`
  position: relative;

  &:after {
    content: '...';
    ${paginationItemAfter}
  }
`;
