import React from 'react';

import { ROUTES } from 'configs';
import { useTranslation } from 'react-i18next';

import { StyledButtonBlock, StyledButton } from './styled';

const FaqButton = () => {
  const { i18n } = useTranslation();
  const pathToSupportPage =
    i18n.language === 'de' ? ROUTES.FAQ : ROUTES.FAQ_OTHER_LANGUAGES;
  return (
    <StyledButtonBlock href={pathToSupportPage} target="_blank">
      <StyledButton>?</StyledButton>
    </StyledButtonBlock>
  );
};

export default FaqButton;
