import styled, { css } from 'styled-components';

import {
  CheckboxCheckedIcon,
  CheckboxDefaultIcon,
  CheckboxDisabledIcon,
} from 'static';
import { breakpoints } from 'theme';

export const StyledBlock = styled.label`
  display: block;
  position: relative;
  font-size: ${({ zoom }) => Math.floor(15 * zoom)}px;
  font-weight: 400;
  line-height: ${({ zoom }) => Math.floor(32 * zoom)}px;
  margin-left: ${({ zoom }) => Math.floor(25 * zoom)}px;
  width: ${({ width }) => width || 'unset'};
  color: ${({ theme }) => theme.color.pickledBluewood};
  ${({ labelStyles }) => labelStyles}

  &.disabled {
    color: ${({ theme }) => theme.color.baliHai};
  }
  height: ${({ zoom }) => Math.floor(32 * zoom)}px;
  ${({ reduceMargin }) =>
    reduceMargin &&
    css`
      @media ${breakpoints.downSmPlus} {
        margin-left: 20psx;
      }
    `}
`;

export const StyledInput = styled.input`
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  span {
    ${({ checkBoxWidth }) =>
      checkBoxWidth &&
      css`
        width: ${checkBoxWidth};
        height: ${checkBoxWidth};
      `}
  }

  &:checked + span {
    background-image: url(${CheckboxCheckedIcon});
    background-size: contain;

    ${({ indeterminate, theme }) =>
      indeterminate &&
      css`
        background: ${theme.color.baliHai};

        &:after {
          content: '';
        }
      `};
  }

  &:focus:not(:checked) + span {
    background-image: none;
    border: 1px solid ${({ theme }) => theme.color.brightTurquoise};
    border-radius: ${({ zoom }) => Math.floor(3 * zoom)}px;
    background-size: contain;
  }

  &:disabled + span {
    background: #ebf2f78a;
    border: 1px solid #c9d9e2;
    pointer-events: none;
    background-size: contain;
  }

  &:disabled:checked + span {
    border: none;
    background-image: url(${CheckboxDisabledIcon});
    background-size: contain;
  }

  label &:disabled {
    color: ${({ theme }) => theme.color.baliHai};
  }
`;

export const StyledCheckbox = styled.span`
  position: absolute;
  top: ${({ zoom }) => Math.floor(10 * zoom)}px;
  margin-left: -${({ zoom }) => Math.floor(25 * zoom)}px;
  width: ${({ zoom }) => Math.floor(14 * zoom)}px;
  height: ${({ zoom }) => Math.floor(14 * zoom)}px;
  background-image: url(${CheckboxDefaultIcon});
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: ${({ zoom }) => Math.floor(2 * zoom)}px;
  cursor: pointer;
  ${({ size }) =>
    size &&
    css`
      width: ${size};
      height: ${size};
    `}

  &:after {
    border-radius: ${({ zoom }) => Math.floor(2 * zoom)}px;
    width: ${({ zoom }) => Math.floor(8 * zoom)}px;
    height: ${({ zoom }) => Math.floor(2 * zoom)}px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledErrorMessage = styled.p`
  margin: 0 0 ${({ zoom = 1 }) => Math.floor(10 * zoom)}px 0;
  font-size: ${({ zoom = 1 }) => Math.floor(10 * zoom)}px;
  color: ${({ theme }) => theme.color.bittersweet};
  text-align: left;
  line-height: ${({ zoom = 1 }) => Math.floor(10 * zoom)}px;
`;
