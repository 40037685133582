import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  ColorInput,
  SidebarInputsWrapper,
  ColorInputBlock,
  InputsBlock,
  Divider,
} from 'components';
import SidebarFooter from '../common/SidebarFooter';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import CheckBox from 'components/CheckBox/CheckBoxV2';
import { closeModal } from 'store/actionCreators/modal';
import { useEditCommunityResponse } from './hooks';
import { FIELDS } from './form';
import { getData } from 'store/selectors/modal';
import { getEventResponseLoading } from 'store/selectors/eventResponses';
import { EventResponse } from 'types';
import {
  CheckBoxWrapper,
  TextH3,
  TextP,
  TextWrapper,
} from 'components/Sidebar/shared';

const EditCommunityResponse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getEventResponseLoading);
  const eventResponse = useSelector(getData) as EventResponse;

  const handleSidebarClose = () => {
    dispatch(closeModal());
  };

  const { register, errors, onSubmit, control } =
    useEditCommunityResponse(eventResponse);

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={
            eventResponse
              ? t('event.responsesEdit')
              : t('event.responsesCreate')
          }
          onCloseClick={handleSidebarClose}
        />
        <SidebarContent height={'calc(100svh - 108px)'}>
          <SidebarInputsWrapper>
            <InputsBlock>
              <TextInput
                name={FIELDS.TITLE}
                register={register}
                label={t('event.responseName')}
                width="225px"
                error={errors[FIELDS.TITLE]}
              />
              <ColorInputBlock>
                <ColorInput name={FIELDS.COLOR} control={control} />
              </ColorInputBlock>
            </InputsBlock>
            <div>
              <Divider customMargin="26px 0px 16px 0px" />
              <TextWrapper>
                <TextH3>{t('event.responseSubTitle')}</TextH3>
              </TextWrapper>
              <CheckBoxWrapper>
                <CheckBox
                  name={FIELDS.IS_COMMENT_REQUIRED}
                  control={control}
                  label=""
                  checkBoxWidth="20px"
                />
                <TextP>{t('event.requestComment')}.</TextP>
              </CheckBoxWrapper>
              <Divider customMargin="26px 0px 0px 0px" />
            </div>
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter isLoading={isLoading} isDeleteButtonVisible={false} />
      </form>
    </Sidebar>
  );
};

export default EditCommunityResponse;
