import moment from 'moment';

import { getUTCDateFromDate } from 'utils';
import { hasPermissionToReply } from 'utils/roles';
import {
  EventResponse,
  CommunityUser,
  Community,
  EventToRender,
  EventTask,
  TaskValue,
  GroupRoles,
  UserValue,
  EventReply,
} from 'types';
import { theme } from 'theme';

export const getTaskValues = (
  currentTask: EventTask,
  eventsToRender: EventToRender[],
  openTaskDetailsSidebar: (task: EventTask, userIds: string[]) => void,
): TaskValue[] =>
  eventsToRender?.map((event) => {
    const hasCurrentTask = event.tasks.includes(currentTask.id);
    const subTaskReplies = event.subEventTaskReplies
      ?.filter((t) => t.eventTaskId === currentTask.id)
      .filter(
        (t) =>
          new Date(t.date).getTime() ===
          getUTCDateFromDate(event.date).getTime(),
      );
    const filteredTaskReplies = subTaskReplies?.filter(
      (task, i, self) => i === self.findIndex((t) => t.userId === task.userId),
    );
    const userIds = filteredTaskReplies.map((reply) => reply.userId);

    if (hasCurrentTask) {
      return {
        color: '#41A2EF',
        guests: filteredTaskReplies?.length || 0,
        onClick: () => openTaskDetailsSidebar(currentTask, userIds),
      };
    }

    return null;
  });

export const getUserValues = ({
  userId,
  responses,
  eventsToRender,
  openGiveReplySidebar,
  currentUser,
  currentUserInGroup,
  name,
  community,
}: {
  userId: string;
  responses: EventResponse[];
  eventsToRender: EventToRender[];
  openGiveReplySidebar: (props: {
    id: string;
    eventId: string;
    eventDate: Date;
    subReply?: EventReply;
    subTaskReplies?: string[];
    isViewOnly: boolean;
    isAdmin: boolean;
    name: string;
  }) => void;
  currentUser: CommunityUser;
  currentUserInGroup;
  name: string;
  community: Community;
}): UserValue[] =>
  eventsToRender?.map((event) => {
    const subReply = event.subEventReplies
      ?.filter((r) => r.userId === userId)
      .filter(
        (r) =>
          moment(r.date).utc().startOf('day').format() ===
          moment(event.date).utc().startOf('day').format(),
      )[0];
    const subTaskReplies = event.subEventTaskReplies
      ?.filter((r) => r.userId === userId)
      .filter(
        (r) =>
          moment(r.date).utc().startOf('day').format() ===
          moment(event.date).utc().startOf('day').format(),
      )
      .map((task) => task.eventTaskId);
    const hasCurrentUser = event.recipients.includes(userId);

    if (hasCurrentUser) {
      //TODO: debug
      const getIsView = (): boolean => {
        if (hasPermissionToReply(currentUser, currentUserInGroup)) {
          return false;
        }

        return !community.groups.some((g) =>
          g.users.find(
            (u) =>
              u.id === userId &&
              (u.groupPermission.role === GroupRoles.ADMIN ||
                u.groupPermission.role === GroupRoles.GROUP_LEAD),
          ),
        );
      };

      if (subReply) {
        const color =
          responses?.filter((resp) => resp.id === subReply.eventResponseId)[0]
            ?.color || theme.color.baliHai;

        return {
          color,
          eventId: event.id,
          guests: subReply?.guests,
          comment: !!subReply?.comment,
          commentText: subReply?.comment,
          star: !!subReply?.attended,
          dot: !!subReply?.penalty,
          onClick: () =>
            openGiveReplySidebar({
              id: userId,
              eventId: event.id,
              eventDate: event.date,
              subReply,
              subTaskReplies,
              isViewOnly: getIsView(),
              isAdmin: hasPermissionToReply(currentUser, currentUserInGroup),
              name,
            }),
        };
      }

      return {
        color: theme.color.baliHai,
        guests: 0,
        eventId: event.id,
        comment: false,
        star: false,
        dot: false,
        onClick:
          !getIsView() ||
          (userId === currentUser.id && !event.isAfterRegDeadline)
            ? () =>
                openGiveReplySidebar({
                  id: userId,
                  eventId: event.id,
                  eventDate: event.date,
                  isViewOnly: getIsView(),
                  isAdmin: hasPermissionToReply(
                    currentUser,
                    currentUserInGroup,
                  ),
                  name,
                })
            : null,
      };
    }

    return null;
  });
