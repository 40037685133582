import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, FieldError, useController } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { FIELDS } from 'sidebars/CreateEventOverall/form';

import { InfoIcon } from 'static';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledSelect,
  StyledIconPlaceholder,
  StyledIcon,
  StyledOptionSingle,
} from './styled';
import { EventTypeAndDefaultEventSetting } from 'types';
import { convertEventDateTime } from 'sidebars/CreateEventOverall/utils';

interface SelectInputControlledV3Props {
  name: string;
  control: Control;
  label?: string;
  info?: string;
  width?: string;
  options: { [key: string]: any }[];
  error?: FieldError;
  isDisabled?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  defaultValue?: { [key: string]: any };
  onBlur?: () => void;
  setValue?: any;
  formWatcher?: any;
  watchFieldName?: string;
  eventTypeAndDefaultSetting?: EventTypeAndDefaultEventSetting[];
}

const OptionSingle = (props) => {
  const { innerProps, data } = props;
  return (
    <div {...innerProps}>
      <StyledOptionSingle>
        {data.icon &&
          (data.icon.length === 1 ? (
            <StyledIconPlaceholder>{data.icon}</StyledIconPlaceholder>
          ) : (
            <StyledIcon src={data.icon} alt={data.label} />
          ))}
        {data.label}
      </StyledOptionSingle>
    </div>
  );
};

const SelectInputControlledV3: FC<SelectInputControlledV3Props> = ({
  name,
  control,
  label,
  info,
  options,
  width,
  error,
  isDisabled,
  placeholder = '',
  maxMenuHeight = 115,
  defaultValue = null,
  onBlur = () => {},
  formWatcher,
  watchFieldName,
  eventTypeAndDefaultSetting,
  setValue,
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  const onChange = (value) => {
    if (eventTypeAndDefaultSetting && eventTypeAndDefaultSetting.length > 0) {
      const data = eventTypeAndDefaultSetting.filter(
        (list) => list?.eventType.id === value.value,
      )?.[0]?.defaultSetting;
      if (
        data?.defaultEventResponseId &&
        data?.defaultEventResponseId !== null
      ) {
        setValue(FIELDS.EVENT_RESPONSE_ID, data?.defaultEventResponseId);
      }
      if (data?.location && data?.location.length > 0)
        setValue(FIELDS.LOCATION, data?.location);
      if (data?.description && data?.description.length > 0)
        setValue(FIELDS.DESCRIPTION, data?.description);
      if (data?.eventTitle && data?.eventTitle.length > 0)
        setValue(FIELDS.TITLE, data?.eventTitle);
      if (data?.from)
        setValue(
          FIELDS.START_TIME,
          data?.from
            ? convertEventDateTime(data?.from)
            : convertEventDateTime(),
        );
      if (data?.to)
        setValue(
          FIELDS.END_TIME,
          data?.to
            ? convertEventDateTime(data?.to)
            : new Date(convertEventDateTime(data?.to).getTime() + 15 * 60000),
        );
    }
    field.onChange(value);
  };

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}{' '}
          {info && (
            <>
              <InfoIcon data-tip data-for="infoTip" />
              <ReactTooltip id="infoTip" effect="solid">
                {info}
              </ReactTooltip>
            </>
          )}
        </StyledLabel>
      )}
      <StyledSelect
        error={error}
        width={width}
        id={name}
        name={name}
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        value={field.value}
        maxMenuHeight={maxMenuHeight}
        components={{ Option: OptionSingle }}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default SelectInputControlledV3;
