import styled from 'styled-components';
import Select from 'react-select';
import { AddIcon, Remove } from 'static';

export const StyledSelect = styled(Select)`
  width: ${({ width }) => width || '340px'};
  min-width: 105px;
  position: relative;

  .react-select__single-value {
    text-align: left;
  }
  .react-select__control {
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.color.bittersweet : theme.color.botticelli};
    cursor: pointer;
    box-shadow: none;
    min-height: 0;
    height: 40px;
  }
  .react-select__control:hover {
    border-color: ${({ theme }) => theme.color.botticelli};
  }
  .react-select__value-container {
    padding: 0 10px;
    font-size: 13px;
    line-height: 28px;
    color: ${({ theme }) => theme.color.pickledBluewood};
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .react-select__indicator-separator {
    position: absolute;
  }
  .react-select__control--menu-is-open .react-select__indicator {
    transform: rotate(180deg);
  }
  .react-select__menu {
    z-index: 100;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  .react-select__menu-list {
    overflow-y: scroll;
  }
  .react-select__option {
    background-color: transparent;
    color: ${({ theme }) => theme.color.pickledBluewood};
    cursor: pointer;
    font-size: 13px;
    line-height: 32px;
    padding: 0 10px 5px 10px;
    text-align: left;
  }
`;

export const StyledOptionSingle = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px 5px;
  cursor: pointer;
  font-size: 13px;
  line-height: 32px;
  color: rgb(48, 70, 89);
  white-space: nowrap;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledNewBadgeWrapper = styled.div`
  min-width: 30px;
  margin-left: 10px;
`;

export const StyledOption = styled.div`
  display: flex;
  height: 40px;
  /* height: 60px; */
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 10px 5px;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 13px;
  /* line-height: 32px; */
  margin: 12px 0px;
  color: rgb(48, 70, 89);
  background-color: ${({ isSelected }) =>
    isSelected ? '#edf3f8!important' : 'transparent!important'};
  background-color: ${({ isSelected }) =>
    isSelected ? '#edf3f8!important' : 'transparent!important'};
`;

export const StyledOptionCheckbox = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  overflow: hidden;
  padding: 0 10px 5px;
  cursor: pointer;
`;

export const StyledOptionText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 32px;
  color: rgb(48, 70, 89);
  white-space: nowrap;
`;

export const StyledOptionIcon = styled(AddIcon)`
  z-index: 1000;
  cursor: pointer;
`;

export const StyledOptionIconRemove = styled(Remove)`
  transform: rotate(45deg);
  z-index: 1000;
  cursor: pointer;
`;

export const StyledIconPlaceholder = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
  background-color: rgb(235, 242, 247);
  color: rgb(133, 149, 153);
  margin-right: 8px;
`;

export const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
`;
