import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { closeModal } from 'store/actionCreators/modal';
import { editProfile } from 'store/actionCreators/profile';
import { FIELDS, useEditProfileForm } from './form';
import { prepareData } from './utils';
import { Profile, ProfilePhoneType, ProfileSocialLinkType } from 'types';

export const useEditProfile = ({
  profile,
  isMemberProfileEdit,
  communityId,
}: {
  profile: Profile;
  isMemberProfileEdit: boolean;
  communityId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { handleSubmit, setValue, ...form } = useEditProfileForm({
    ...profile,
    [FIELDS.PROFILE_VISIBLE]: profile.profileVisible || false,
    [FIELDS.DATE_OF_BIRTH]: profile.dob ? new Date(profile.dob) : new Date(),
    [FIELDS.PRIVATE_PHONE_NUMBER]:
      profile.phones?.find((p) => p.label === ProfilePhoneType.PRIVATE_NUMBER)
        ?.value || null,
    [FIELDS.OFFICE_PHONE_NUMBER]:
      profile.phones?.find((p) => p.label === ProfilePhoneType.OFFICE_NUMBER)
        ?.value || null,
    [FIELDS.MOBILE_PHONE_NUMBER]:
      profile.phones?.find((p) => p.label === ProfilePhoneType.MOBILE_NUMBER)
        ?.value || null,
    [FIELDS.FACEBOOK]:
      profile.links?.find((l) => l.label === ProfileSocialLinkType.FACEBOOK)
        ?.value || null,
    [FIELDS.TWITTER]:
      profile.links?.find((l) => l.label === ProfileSocialLinkType.TWITTER)
        ?.value || null,
    [FIELDS.INSTAGRAM]:
      profile.links?.find((l) => l.label === ProfileSocialLinkType.INSTAGRAM)
        ?.value || null,
    [FIELDS.YOUTUBE]:
      profile.links?.find((l) => l.label === ProfileSocialLinkType.YOUTUBE)
        ?.value || null,
    [FIELDS.LINKEDIN]:
      profile.links?.find((l) => l.label === ProfileSocialLinkType.LINKEDIN)
        ?.value || null,
  });

  const onSuccess = () => {
    dispatch(closeModal());
    addToast(t('profile.editSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = handleSubmit((data) =>
    dispatch(
      editProfile({
        data: prepareData(data),
        id: profile.userId,
        isMemberProfileEdit,
        communityId,
        onSuccess,
      }),
    ),
  );

  return {
    onSubmit,
    setValue,
    ...form,
  };
};
