import styled, { css } from 'styled-components';
import { breakpoints } from 'theme';

export const CreateWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateEventText = styled.p`
  position: absolute;
  display: flex;
  left: ${({ orientation }) => (orientation === 'right' ? '8%' : '')};
  right: ${({ orientation }) => (orientation === 'left' ? '8%' : '')};
  align-items: center;
  margin: auto;
  color: ${({ theme }) => theme.color.black};
  max-width: 0px;
  max-height: 50%;
  overflow: hidden;
  padding: 8px 0px 8px 0px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  transition: all 500ms ease, color 0.2s ease-in-out;
  white-space: nowrap;
  font-size: 1em;
  cursor: pointer;
  ${({ animate, orientation }) =>
    animate
      ? css`
          max-width: 300px;
          padding: ${orientation === 'left'
            ? '8px 110% 8px 16px'
            : '8px 16px 8px 110%'};
          color: ${({ theme }) => theme.color.white};
        `
      : ''}
  &:active:enabled {
    opacity: 80%;
    cursor: pointer;
  }
`;

export const StyledButton = styled.button`
  height: ${({ width }) => `${width}`};
  width: ${({ width }) => `${width}`};
  background: ${({ theme }) => theme.color.brightTurquoise};
  border: none;
  border-radius: 50%;
  outline: none;
  transition: transform 0.2s, opacity 0.2s, fill 0.3s ease-in-out;
  z-index: 1;
  @media ${breakpoints.downMdPlus} {
    width: 56px;
    height: 56px;
  }
  @media ${breakpoints.downSmPlus} {
    width: 48px;
    height: 48px;
  }
  svg {
    width: 50%;
    height: 50%;
    path {
      fill: ${({ theme }) => theme.color.black};
    }
  }

  ${({ animate }) =>
    animate
      ? css`
          transform: scale(1.2);
          cursor: pointer;
          svg {
            path {
              fill: ${({ theme }) => theme.color.white};
            }
          }
        `
      : ''}

  &:active:enabled {
    opacity: 80%;
    cursor: pointer;
  }

  &:disabled {
    opacity: 20%;
  }
`;
