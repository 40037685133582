import React, { FC } from 'react';

import {
  LinkWrapper,
  StyledFileIcon,
  FileName,
  FileItemContainer,
  StyledArrowIcon,
  StyledLink,
} from './styled';

interface DocumentLinkProps {
  name: string;
  href: string;
  type: string;
}

const DocumentLink: FC<DocumentLinkProps> = ({ name, href, type }) =>
  type === 'navigation' ? (
    <LinkWrapper to={href}>
      <FileItemContainer>
        <StyledFileIcon />
        <FileName>{name}</FileName>
      </FileItemContainer>
      <StyledArrowIcon />
    </LinkWrapper>
  ) : (
    <StyledLink href={href} target="_blank">
      <FileItemContainer>
        <StyledFileIcon />
        <FileName>{name}</FileName>
      </FileItemContainer>
      <StyledArrowIcon />
    </StyledLink>
  );

export default DocumentLink;
