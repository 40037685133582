import react from 'react';
import * as yup from 'yup';
import { ControllerRenderProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm, useWatch, Resolver } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MyAppointmentsEvent } from 'types';

export enum FORMFIELDS {
  RECIPIENTS = 'recipients',
  SENDCOPY = 'sendCopyToMe',
  TITLE = 'title',
  MESSAGE = 'message',
  REDIRECT_LINK = 'redirectLink',
}

export interface PushNotificationModuleFormValues {
  [FORMFIELDS.RECIPIENTS]: string[];
  [FORMFIELDS.SENDCOPY]: boolean;
  [FORMFIELDS.TITLE]: string;
  [FORMFIELDS.MESSAGE]: string;
  [FORMFIELDS.REDIRECT_LINK]: string;
}

export const usePushMessageForm = (
  defaultValues: PushNotificationModuleFormValues,
  selectedEvent: MyAppointmentsEvent,
  reciapient?: string[],
) => {
  const { t } = useTranslation();

  const getRecipientsValidation = (selectedEvent: MyAppointmentsEvent) => {
    return !selectedEvent || reciapient.length <= 1
      ? yup.array().of(yup.string()).required(t('errors.required'))
      : yup.array().of(yup.string());
  };

  const redirectionLinkValidation = selectedEvent
    ? yup.string().url().nullable().required(t('errors.required'))
    : yup.string().url().nullable();

  const resolver: Resolver<PushNotificationModuleFormValues> = yupResolver(
    yup.object().shape({
      [FORMFIELDS.RECIPIENTS]: getRecipientsValidation(selectedEvent),
      [FORMFIELDS.SENDCOPY]: yup.bool(),
      [FORMFIELDS.TITLE]: yup.string().required(t('errors.required')),
      [FORMFIELDS.MESSAGE]: yup.string().required(t('errors.required')),
      [FORMFIELDS.REDIRECT_LINK]: redirectionLinkValidation,
    }),
  );

  const form = useForm<PushNotificationModuleFormValues>({
    resolver,
    defaultValues,
    mode: 'onChange',
  });

  const { field: recipientFieldProps } = useController({
    name: FORMFIELDS.RECIPIENTS,
    control: form.control,
    defaultValue: defaultValues[FORMFIELDS.RECIPIENTS],
  });

  const { field: sendCopyFieldProps } = useController({
    name: FORMFIELDS.SENDCOPY,
    control: form.control,
    defaultValue: defaultValues[FORMFIELDS.SENDCOPY],
  });

  const watchedValues = useWatch({
    name: [FORMFIELDS.TITLE, FORMFIELDS.MESSAGE, FORMFIELDS.REDIRECT_LINK],
    control: form.control,
    defaultValue: defaultValues,
  });

  return {
    ...form,
    recipientFieldProps,
    sendCopyFieldProps,
    watchedValues,
  };
};
