import React, { FC } from 'react';
import { noop } from 'lodash/fp';
import { FieldError } from 'react-hook-form';

import {
  StyledBlock,
  StyledInput,
  StyledCheckbox,
  StyledErrorMessage,
} from './styled';

interface CheckBoxProps {
  label?: string | JSX.Element;
  disabled?: boolean;
  name?: string;
  isSelected: boolean;
  error?: FieldError;
  width?: string;
  indeterminate?: boolean;
  labelStyles?: { [key: string]: any };
  zoom?: number;
  checkBoxWidth?: string;
  register?: () => void;
  reduceMargin?: boolean;
  onClick?: (e?: any) => void;
  onChange?: (e: any) => void;
}

const CheckBox: FC<CheckBoxProps> = ({
  label = '',
  disabled = false,
  register = noop,
  name,
  error,
  isSelected,
  onChange = noop,
  onClick,
  width,
  indeterminate,
  labelStyles,
  reduceMargin = false,
  zoom = 1,
  checkBoxWidth,
}) => (
  <StyledBlock
    className={disabled && 'disabled'}
    width={width}
    labelStyles={labelStyles}
    zoom={zoom}
    onClick={onClick}
    reduceMargin={reduceMargin}
  >
    <StyledInput
      ref={register}
      name={name}
      type="checkbox"
      checked={isSelected}
      disabled={disabled}
      onChange={onChange}
      indeterminate={indeterminate}
      zoom={zoom}
      checkBoxWidth={checkBoxWidth}
    />
    <StyledCheckbox zoom={zoom} size={checkBoxWidth} />
    {label}
    {error && (
      <StyledErrorMessage zoom={zoom}>{error.message}</StyledErrorMessage>
    )}
  </StyledBlock>
);

export default CheckBox;
