export enum NotificationType {
  EVENT_CREATE = 'create',
  EVENT_UPDATE = 'update',
  EVENT_DELETE = 'delete',
  EVENT_REMINDER = 'reminders',
}

export enum NotificationTypeIcon {
  EVENT_CREATE_ICON = '📅',
  EVENT_UPDATE_ICON = '🔄',
  EVENT_DELETE_ICON = '🗑️',
  EVENT_REMINDER_ICON = '🔔',
}

export type NotificationsPageConfigItem = {
  updatedFieldsFilter: boolean;
  eventRepliesFilter: boolean;
  timeInterval: boolean;
};

export type NotificationsPageConfig = {
  [key in NotificationType]: NotificationsPageConfigItem;
};

export enum FIELDS {
  ENABLED = 'enabled',
  FIELDS = 'fields',
  REPLIES = 'replies',
  REMINDERS = 'rates',
}
