import { connect } from 'react-redux';

import {
  doDeleteCommunityUser,
  doAddCommunityUser,
} from 'store/actionCreators/currentCommunity';
import {
  doAddUserCommunities,
  doDeleteUserCommunities,
} from 'store/actionCreators/communities';
import { doAddMembersToGroup } from 'store/actionCreators/groups';
import {
  doDeleteGroupUser,
  doDeleteUserGroups,
  doAddUserGroups,
} from 'store/actionCreators/currentGroup';

import MatrixList from './MatrixList';

const mapDispatchToProps = {
  deleteCommunityUser: doDeleteCommunityUser,
  addCommunityUsers: doAddCommunityUser,
  addUserCommunities: doAddUserCommunities,
  deleteUserCommunities: doDeleteUserCommunities,
  addMembersToGroup: doAddMembersToGroup,
  deleteGroupUser: doDeleteGroupUser,
  deleteUserGroups: doDeleteUserGroups,
  addUserGroups: doAddUserGroups,
};

const mapStateToProps = (state) => ({
  communities: state.communities.matrix,
  loadingMatrix: state.communities.loadingMatrix,
  authUser: state.auth.user,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MatrixList);
