import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SHARE_POPUP } from 'constants/popups';
import EventShareOptionsTooltip from '../../EventShareOptionsTooltip';
import { openModal } from 'store/actionCreators/modal';
import { EventToRender } from 'types';
import { Tooltip } from 'components';
import { ChatIcon } from 'static';

import {
  StyledCardBottomIconContainer,
  StyledEventTypeLabel,
  StyledHeadContainer,
  StyledHeadContent,
  StyledHeadFooter,
  StyledHeadFooterLeft,
  StyledHeadText,
  StyledHighlightTop,
  StyledInfoIcon,
  StyledOptionalMessageIcon,
  StyledSelectionHighlight,
  StyledShareBlock,
  StyledTitle,
} from './styled';
import { useSelector } from 'react-redux';
import { modalSelector } from 'store/selectors/modal';

interface HeadProps {
  style: { [key: string]: string | number };
  zoom: number;
  statisticsTab: boolean;
  headData: EventToRender;
  divide?: 'left' | 'right';
}

const Head = memo<HeadProps>(
  ({ style, headData, zoom, statisticsTab, divide }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data, isOpen } = useSelector(modalSelector);

    const {
      color,
      title,
      dateString,
      time,
      width,
      id,
      date,
      eventType,
      description,
      onExportPDFClick,
      isPlusIcon,
      optionalMessage,
    } = headData || {};

    return (
      <>
        <div
          style={style}
          data-type="header"
          data-id={id}
          data-clickable="true"
          data-date={date}
          className="grid-clickable-item"
        >
          {isOpen && data?.['eventId']?.toString() === id?.toString() && (
            <StyledSelectionHighlight zoom={zoom} />
          )}
          {isOpen && data?.['eventId']?.toString() === id?.toString() && (
            <StyledHighlightTop zoom={zoom} />
          )}
          <StyledHeadContainer
            divide={divide}
            selected={
              isOpen && data?.['eventId']?.toString() === id?.toString()
            }
          >
            <StyledHeadContent
              flex={!statisticsTab}
              centered={!dateString && !time}
              color={color}
              width={width}
              zoom={zoom}
              statisticsTab={statisticsTab}
              selected={
                isOpen && data?.['eventId']?.toString() === id?.toString()
              }
            >
              {!statisticsTab && !isPlusIcon && (
                <Tooltip
                  isVisible
                  visibleAfterWidth={93 * zoom}
                  zoom={zoom}
                  text={eventType}
                  tooltipStyle={{
                    fontSize: 12 * zoom,
                  }}
                >
                  <StyledEventTypeLabel zoom={zoom}>
                    <span>{eventType}</span>
                  </StyledEventTypeLabel>
                </Tooltip>
              )}
              <Tooltip
                isVisible
                zoom={zoom}
                text={isPlusIcon ? t('event.createEvent') : title}
                offset={(statisticsTab ? 4 : 26) * zoom}
                tooltipStyle={{
                  fontSize: 12 * zoom,
                }}
              >
                <StyledTitle isStatisticsTab={statisticsTab} zoom={zoom}>
                  {isPlusIcon ? (
                    <StyledTitle.PlusIconText
                      zoom={zoom}
                      dangerouslySetInnerHTML={
                        isPlusIcon ? { __html: title.toString() } : undefined
                      }
                    />
                  ) : (
                    <span>{title}</span>
                  )}
                  {isPlusIcon && description && (
                    <StyledTitle.Description zoom={zoom}>
                      {description}
                    </StyledTitle.Description>
                  )}
                </StyledTitle>
              </Tooltip>
              <StyledHeadFooter>
                <StyledHeadFooterLeft>
                  {dateString && (
                    <StyledHeadText zoom={zoom}>
                      {t(
                        `dayOfWeek.day${new Date(
                          dateString.slice(3, 5) +
                            '/' +
                            dateString.slice(0, 2) +
                            '/' +
                            dateString.slice(6),
                        ).getDay()}`,
                      )}
                      {', '}
                      {dateString}
                    </StyledHeadText>
                  )}
                  {<StyledHeadText zoom={zoom}>{time}&nbsp;</StyledHeadText>}
                </StyledHeadFooterLeft>

                <StyledCardBottomIconContainer>
                  {optionalMessage?.length > 0 && (
                    <Tooltip
                      isVisible
                      zoom={zoom}
                      opacity="75"
                      text={t('event.optionalMessage')}
                      offset={11 * zoom}
                      position="right-center"
                      tooltipStyle={{
                        fontSize: 12 * zoom,
                        maxWidth: 200 * zoom,
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        lineBreak: 'normal',
                      }}
                    >
                      <StyledOptionalMessageIcon zoom={zoom}>
                        <ChatIcon />
                      </StyledOptionalMessageIcon>
                    </Tooltip>
                  )}

                  {!!description && !isPlusIcon && (
                    <Tooltip
                      isVisible
                      zoom={zoom}
                      text={description}
                      offset={11 * zoom}
                      opacity="75"
                      position="right-center"
                      tooltipStyle={{
                        fontSize: 12 * zoom,
                        maxWidth: 200 * zoom,
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        lineBreak: 'normal',
                      }}
                    >
                      <StyledInfoIcon zoom={zoom}>
                        <span>i</span>
                      </StyledInfoIcon>
                    </Tooltip>
                  )}
                </StyledCardBottomIconContainer>
              </StyledHeadFooter>
              {!statisticsTab && !isPlusIcon && (
                <StyledShareBlock zoom={zoom}>
                  <EventShareOptionsTooltip
                    zoom={zoom}
                    onShareClick={() =>
                      dispatch(
                        openModal({
                          type: SHARE_POPUP,
                          data: {
                            eventId: id,
                          },
                        }),
                      )
                    }
                    onExportPDFClick={onExportPDFClick}
                  />
                </StyledShareBlock>
              )}
            </StyledHeadContent>
          </StyledHeadContainer>
        </div>
      </>
    );
  },
);

Head.displayName = 'Head';

export default Head;
