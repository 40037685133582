import styled from 'styled-components';
import { breakpoints } from 'theme';

export const PhoneContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 6px;
`;

export const PhoneMockup = styled.div`
  width: 360px;
  height: 720px;
  box-shadow: 1px 1px 12px 3px #1f1f1f;
  /* background-image: url('https://webdevartur.com/wp-content/uploads/2022/08/ryan-klaus-8QjsdoXDsZs-unsplash-scaled.jpg'); */
  background: linear-gradient(
    180.2deg,
    rgb(120, 85, 137) -6.9%,
    rgb(35, 9, 31) 76.7%
  );
  background: ${({ theme }) => theme.color.lightGradient};
  background: linear-gradient(#031819 25%, #053340, #148c8c, #2ebebe);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 48px;
  @media ${breakpoints.downSmPlus} {
    max-width: 360px;
    width: 100%;
  }
`;

export const Silencer = styled.div``;

export const InsideBorder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 8px solid ${({ theme }) => theme.color.black};
  border-radius: 48px;
`;

export const Camera = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 144px;
  height: 20px;
  background-color: black;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  top: 0;
`;
Camera.CameraDot = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  left: 16px;
  top: 5px;
`;
Camera.Speaker = styled.div`
  position: absolute;
  background-color: #fff;
  width: 56px;
  height: 2px;
  border-radius: 20px;
  top: -2px;
  opacity: 0.2;
`;

export const ExtraInfo = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  right: 17px;
  top: 8px;
  font-size: 10px;
`;
ExtraInfo.Dots = styled.div`
  color: ${({ theme }) => theme.color.white};
`;
ExtraInfo.Battery = styled.div`
  position: relative;
  margin-top: 4px;
  height: 7px;
  width: 14px;
  border: 0.7px solid rgba(255, 255, 255, 0.709);
  border-radius: 2px;
`;
ExtraInfo.Battery.Bar = styled.div`
  width: calc(80% - 2px);
  height: calc(100% - 2px);
  background-color: #fff;
  margin: 1px;
  border-radius: 1px;
`;
ExtraInfo.Battery.Dot = styled.div`
  position: absolute;
  right: -2px;
  top: 1.5px;
  width: 1px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.709);
  border-radius: 2px;
`;

export const Lock = styled.div`
  position: absolute;
  width: 12px;
  height: 10px;
  top: 48px;
  background-color: white;
  border-radius: 2px;
`;
Lock.Locked = styled.div`
  position: absolute;
  width: 9px;
  height: 15px;
  border: 1.5px solid white;
  border-radius: 20px;
  top: -7px;
  left: 1.7px;
`;

export const DateTimeContainer = styled.div`
  position: absolute;
  top: 72px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.color.white};
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Open Sans',
    sans-serif;
`;
DateTimeContainer.Date = styled.p`
  font-size: 20px;
`;
DateTimeContainer.Time = styled.h1`
  font-size: 64px;
`;

export const NotificationContainer = styled.div`
  position: absolute;
  width: 95%;
  top: 30%;
`;

export const Torch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 42px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.202);
  left: 40px;
  bottom: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 250ms;
  &:hover {
    scale: 1.3;
  }
`;
Torch.Light = styled.div`
  width: 12px;
  height: 2px;
  background-color: #fff;
  border-radius: 3px;
`;
Torch.Top = styled.div`
  margin-top: 1px;
  width: 12px;
  height: 8px;
  background-color: #fff;
  border-top-left-radius: 0.5px;
  border-top-right-radius: 0.5px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
`;
Torch.SwitchSection = styled.div`
  margin-top: -1px;
  width: 6px;
  height: 12px;
  background-color: #fff;
  border-radius: 1px;
`;
Torch.Switch = styled.div`
  position: absolute;
  top: 20px;
  left: 22px;
  border-radius: 50%;
  width: 4px;
  height: 6px;
  background-color: #000;
`;
Torch.Switch.Dot = styled.div`
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 1.2px;
  height: 1.2px;
  background-color: #fff;
  border-radius: 50%;
`;

export const CameraButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 46px;
  height: 46px;
  background-color: rgba(255, 255, 255, 0.202);
  right: 40px;
  bottom: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 250ms;
  &:hover {
    scale: 1.3;
  }
`;
CameraButton.Box = styled.div`
  width: 24px;
  height: 16px;
  background-color: #fff;
  border-radius: 4px;
`;
CameraButton.Circle = styled.div`
  position: absolute;
  top: 18px;
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  border-radius: 50%;
`;
CameraButton.Eye = styled.div`
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 3px;
  top: 12px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`;
CameraButton.Dot = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: 15px;
  right: 10px;
  background-color: #000;
  border-radius: 50%;
  transform: scale(0.2);
`;

export const BottomLine = styled.div`
  position: absolute;
  height: 4px;
  width: 140px;
  background-color: #fff;
  bottom: 8px;
  border-radius: 4px;
  opacity: 0.8;
`;
