import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { InviteMemberFormData, useInviteMemberForm } from './form';
import { checkIfUserExists } from 'services/communities';
import { useInfoPopup } from 'hooks';
import { LIMIT_MEMBERS } from 'constants/errors';
import { doInviteMember } from 'store/actionCreators/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import { CommunityUser } from 'types';

export const useInviteMembers = ({
  communityId,
  users,
  defaultValues,
}: {
  communityId: string;
  users: CommunityUser[];
  defaultValues: InviteMemberFormData;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { showInfoPopup } = useInfoPopup();
  const [emailChecking, setEmailChecking] = useState<boolean>(false);
  const [nameFieldsShown, setNameFieldsShown] = useState<boolean>(false);

  const { handleSubmit, setValue, ...form } = useInviteMemberForm(
    users,
    nameFieldsShown,
    defaultValues,
  );

  const onSuccess = (title: string, message?: string) => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title,
      message,
      duration: 16000,
      noButton: true,
      loop: false,
    });
  };

  const onFailure = (e) => {
    if (e?.data?.error === LIMIT_MEMBERS) {
      showInfoPopup({
        type: 'error',
        title: t('communitySettings.disableAddMemberTooltip'),
      });
    }

    showInfoPopup({
      type: 'error',
      title: t('errors.text500'),
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setEmailChecking(true);

    try {
      const response = await checkIfUserExists(data.email.toLowerCase());

      if (response.data.id) {
        dispatch(
          doInviteMember({
            communityId,
            email: data.email.toLowerCase(),
            role: data.role.value,
            userId: response.data.id,
            groups:
              data.groups?.map((g) => ({ id: g.value, role: g.role.value })) ||
              [],
            onSuccess: () =>
              onSuccess(
                t('group.addMembersSuccessWithMessage.title'),
                t('group.addMembersSuccessWithMessage.message'),
              ),
            onFailure,
          }),
        );
      }

      setEmailChecking(false);
    } catch (err) {
      if (
        err?.response?.status === 400 &&
        err?.response?.data?.error === 'NOT_FOUND' &&
        !data.firstName
      ) {
        setNameFieldsShown(true);

        addToast(t('communitySettings.userNotFound'), {
          appearance: 'warning',
          autoDismiss: true,
          autoDismissTimeout: 15000,
        });
      }

      setEmailChecking(false);
    } finally {
      if (data.firstName) {
        dispatch(
          doInviteMember({
            communityId,
            email: data.email.toLowerCase(),
            role: data.role.value,
            firstName: data.firstName,
            lastName: data.lastName,
            groups:
              data.groups?.map((g) => ({ id: g.value, role: g.role.value })) ||
              [],
            onSuccess: () =>
              onSuccess(t('auth.inviteSentTitle'), t('auth.inviteSentText')),
            onFailure,
          }),
        );
      }
    }
  });

  return {
    onSubmit,
    setValue,
    nameFieldsShown,
    setNameFieldsShown,
    emailChecking,
    ...form,
  };
};
