import styled from 'styled-components';

export const FileInputContainer = styled.div`
  padding: 0px 20px 12px 23px;
  height: 94px;
  background-color: ${({ theme }) => theme.color.dew};
`;

export const StyledUploadDiv = styled.div`
  height: 74px;
`;
