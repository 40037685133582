import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommunityUsersByGroups, Loader } from 'components';
import { CommunityUser, CommunityMembersByGroups, Community } from 'types';

import { StyledHeaderWrapper, StyledRecipentsContainer } from './styled';
import { GridGhostWrapper, StyledSectionTitle } from '../styled';
import { hasCommunityOrGroupAdminPermissions } from 'utils';

interface EmailModuleRecipientsProps {
  selectedRecipients: string[];
  membersByGroups: CommunityMembersByGroups[];
  users: CommunityUser[];
  membersToGroupsLoading: boolean;
  onChange: (value: string[]) => void;
  handleOnWrapperClick: (e: MouseEvent) => void;
  currentUserId: string;
  community: Community;
}

const EmailModuleRecipients: FC<EmailModuleRecipientsProps> = ({
  selectedRecipients,
  membersByGroups,
  users,
  membersToGroupsLoading,
  onChange,
  handleOnWrapperClick,
  currentUserId,
  community,
}) => {
  const { t } = useTranslation();

  return (
    <StyledRecipentsContainer>
      {!hasCommunityOrGroupAdminPermissions(currentUserId, community) && (
        <GridGhostWrapper onClick={(e) => handleOnWrapperClick(e)} />
      )}

      <StyledHeaderWrapper>
        <StyledSectionTitle>{t('email.recipients')}</StyledSectionTitle>
      </StyledHeaderWrapper>
      {!membersToGroupsLoading ? (
        <CommunityUsersByGroups
          value={selectedRecipients}
          membersByGroups={membersByGroups}
          users={users}
          height={'calc(100svh - 220px - 60px)'}
          onChange={onChange}
        />
      ) : (
        <Loader />
      )}
    </StyledRecipentsContainer>
  );
};

export default EmailModuleRecipients;
