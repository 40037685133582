import React from 'react';
import { useNavigate } from 'react-router-dom';
import { shape, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tabs, CloseButton } from 'components';

import GeneralTab from './General';
import Other from './Other';

import { StyledContentWrapper, StyledHeader, StyledTitle } from './styled';

const GroupSettingsPage = ({ group, isMenuOpen, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <StyledContentWrapper isMenuOpen={isMenuOpen}>
      <StyledHeader isMenuOpen={isMenuOpen}>
        <StyledTitle>{t('groupSettings.title')}</StyledTitle>
        <CloseButton onClick={goBack} />
      </StyledHeader>
      <Tabs>
        <div label={t('groupSettings.tabs.general')} index={0}>
          <GeneralTab group={group} isLoading={isLoading} />
        </div>
        <div label={t('groupSettings.tabs.other')} index={1}>
          <Other group={group} />
        </div>
      </Tabs>
    </StyledContentWrapper>
  );
};

GroupSettingsPage.propTypes = {
  group: shape({}),
  isMenuOpen: bool.isRequired,
};

GroupSettingsPage.defaultProps = {
  group: {},
};

export default GroupSettingsPage;
