import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneMockup from './Phone';
import {
  CheckBoxWrapper,
  PhoneMockupContainer,
  PreviewWrapper,
  SelectBoxContainer,
} from './styled';
import { CheckBox, TextInput } from 'components';
import { FORMFIELDS, PushNotificationModuleFormValues } from './form';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { theme } from 'theme';

interface Props
  extends Partial<
    Pick<UseFormMethods<PushNotificationModuleFormValues>, 'register'>
  > {
  title?: string;
  message?: string;
  errors: DeepMap<PushNotificationModuleFormValues, FieldError>;
  selectedRecipients: string[];
  onSendCopyChange: (e: ChangeEvent<HTMLInputElement>) => void;
  currentView?: undefined | 'single' | 'double' | 'triple';
  isSelected?: boolean;
}

const NotificationPreview: React.FC<Props> = ({
  title = '🔔 Reminder • Gumb • 23h',
  errors,
  onSendCopyChange,
  message = 'Daily share-screen, March 05 2024, 16:00',
  currentView,
  isSelected,
  register,
}) => {
  const { t } = useTranslation();
  return (
    <PhoneMockupContainer>
      {currentView === 'double' && (
        <SelectBoxContainer padding="16.5px 0px 0px 0px">
          <CheckBoxWrapper>
            <CheckBox
              width="340px"
              isSelected={isSelected}
              onChange={onSendCopyChange}
              label={t('pushNotification.sendCopy')}
              labelStyles={{ marginLeft: 0, paddingLeft: '25px' }}
            />
          </CheckBoxWrapper>
          <TextInput
            label={t('pushNotification.pasteUrl')}
            name={FORMFIELDS.REDIRECT_LINK}
            type="text"
            error={errors[FORMFIELDS.REDIRECT_LINK]}
            register={register}
            extraWrapperStyle={{ width: 'calc(100% - 20px)' }}
            width="100%"
            inputValueStyle={{ color: theme.color.brightTurquoise }}
            showInfoIcon={true}
            infoText={t('pushNotification.urlInfoOne')}
          />
        </SelectBoxContainer>
      )}
      <PreviewWrapper currentView={currentView}>
        {/* <h2>Preview</h2>
        <Divider /> */}
        <PhoneMockup title={title} message={message} imageUrl="" />
      </PreviewWrapper>
    </PhoneMockupContainer>
  );
};

export default NotificationPreview;
