import { call, put, takeLatest } from 'redux-saga/effects';

import {
  changeUserRole,
  deleteCommunityUser,
  addCommunityUsers,
  addUserCommunities,
  deleteUserCommunities,
} from 'services/users';
import {
  doChangeUserRole,
  doDeleteCommunityUser,
  doAddCommunityUser,
} from 'store/actionCreators/currentCommunity';
import {
  doAddUserCommunities,
  doDeleteUserCommunities,
  doDeleteCommunity,
  doUpdateCommunityMatrixOnSelfDelete,
} from 'store/actionCreators/communities';
import { getCurrentUserSubscription } from 'store/actionCreators/subscriptions';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

export function* onChangeUserRole({
  payload,
}: ReturnType<typeof doChangeUserRole>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, userId } = payload;
    const data = yield call(changeUserRole, communityId, userId, payload.data);
    yield put(doChangeUserRole.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doChangeUserRole.failure(response.data));
  }
}

export function* onDeleteCommunityUser({
  payload,
}: ReturnType<typeof doDeleteCommunityUser>) {
  try {
    const { communityId, userIds, isCurrentUser } = payload;
    yield call(deleteCommunityUser, communityId, userIds);
    yield put(getCurrentUserSubscription.trigger());

    if (isCurrentUser) {
      yield put(doDeleteCommunity.success(communityId));
    }
    if (isCurrentUser && communityId) {
      yield put(doUpdateCommunityMatrixOnSelfDelete.success({ communityId }));
    }
    yield put(doDeleteCommunityUser.success());

    yield call(payload.onSuccess, userIds);
  } catch (e) {
    yield put(doDeleteCommunityUser.failure(e));
  }
}

export function* onAddCommunityUser({
  payload,
}: ReturnType<typeof doAddCommunityUser>) {
  try {
    const { communityId, userIds } = payload;
    yield call(addCommunityUsers, communityId, userIds);
    yield put(doAddCommunityUser.success());
  } catch (e) {
    if (payload.onFailure) {
      yield call(payload.onFailure, e?.response);
    }
    yield put(doAddCommunityUser.failure(e));
  }
}

export function* onAddUserCommunities({ payload }) {
  try {
    const { communityIds, userId } = payload;
    yield call(addUserCommunities, communityIds, userId);
  } catch (e) {
    yield put(doAddUserCommunities.failure(e));
  }
}

export function* onDeleteUserCommunities({ payload }) {
  try {
    const { communityIds, userId } = payload;
    yield call(deleteUserCommunities, communityIds, userId);
    yield put(doDeleteUserCommunities.success());
  } catch (e) {
    yield put(doDeleteUserCommunities.failure(e));
  }
}

export default function* () {
  yield takeLatest(doChangeUserRole, onChangeUserRole);
  yield takeLatest(doDeleteCommunityUser, onDeleteCommunityUser);
  yield takeLatest(doAddCommunityUser, onAddCommunityUser);
  yield takeLatest(doAddUserCommunities, onAddUserCommunities);
  yield takeLatest(doDeleteUserCommunities, onDeleteUserCommunities);
}
