import { createSelector } from 'reselect';

import { currentCommunitySelector } from 'store/selectors/currentCommunity';
import { IRootState } from '../reducers';
import { SubscriptionStatus } from 'types';

export const subscriptionsSelector = (state: IRootState) => state.subscriptions;

const checkDateNotExpired = (firstDate: Date, secondDate: Date) =>
  firstDate.getTime() <= secondDate.getTime();

export const selectAllSubscriptions = createSelector(
  subscriptionsSelector,
  (subscriptions) => subscriptions.allSubscriptions || [],
);

export const selectCurrentUserSubscription = createSelector(
  subscriptionsSelector,
  (subscriptions) => subscriptions.currentUserSubscription,
);

export const selectCurrentCommunitySubscription = createSelector(
  currentCommunitySelector,
  (community) => community?.data?.subscription || null,
);

export const selectIsSubscriptionLoading = createSelector(
  subscriptionsSelector,
  (subscriptions) => subscriptions.isLoading,
);

export const selectIsUserSubscriptionActive = createSelector(
  selectCurrentUserSubscription,
  (subscription) => {
    const endDate = subscription?.endDate;
    if (!endDate) return true;
    return checkDateNotExpired(new Date(), new Date(endDate));
  },
);

export const selectIsCommunitySubscriptionActive = createSelector(
  selectCurrentCommunitySubscription,
  (subscription) => {
    const endDate = subscription?.endDate;
    if (!endDate) return true;
    return checkDateNotExpired(new Date(), new Date(endDate));
  },
);

export const selectCommunitySubscriptionPending = createSelector(
  selectCurrentCommunitySubscription,
  (subscription) => subscription.status === SubscriptionStatus.APPROVAL_PENDING,
);

export const selectUserSubscriptionPending = createSelector(
  selectCurrentUserSubscription,
  (subscription) => subscription.status === SubscriptionStatus.APPROVAL_PENDING,
);

export const selectPaypalPlanId = createSelector(
  subscriptionsSelector,
  (subscriptions) => subscriptions?.paypalSubscriptionPlanId,
);

export const selectIsChatsAvailable = createSelector(
  selectCurrentCommunitySubscription,
  (subscription) => subscription?.chatEnabled,
);

export const hasAdblocker = createSelector(
  subscriptionsSelector,
  (subscriptionState) => subscriptionState.hasAdblocker,
);
