import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReturnIcon } from 'static';

const ReturnLinkIcon = styled(ReturnIcon)`
  width: 12px;
  height: 12px;
`;

const ReturnLink = styled(Link)`
  text-decoration: none;

  &:hover {
    ${ReturnLinkIcon} {
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }
  }
`;

ReturnLink.Wrapper = styled.button`
  display: inline-flex;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
`;

ReturnLink.Text = styled.div`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 14px;
  margin-left: 10px;
`;

export { ReturnLink, ReturnLinkIcon };
