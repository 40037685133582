import styled from 'styled-components';
import { ReturnIconGreen } from 'static';

const getCircleLeftOffset = (position, isMenuOpen) => {
  if (position !== 'left') {
    return 'unset';
  }

  return isMenuOpen ? '360px' : '60px';
};

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ position }) =>
    position === 'right' ? '30px 0 0 30px' : '0 30px 30px 0'};
  width: 40px;
  height: 150px;
  position: fixed;
  background-image: linear-gradient(270deg, #01c8a87b 0%, #19e1e17b 100%);
  box-shadow: 0 2px 8px 0 rgba(22, 223, 220, 0.18);
  z-index: 100000000;
  cursor: pointer;
  transition: all 0.2s;
  top: 40vh;
  left: ${({ position, isMenuOpen }) =>
    getCircleLeftOffset(position, isMenuOpen)};
  right: ${({ position }) => (position === 'right' ? 0 : 'unset')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    width: 50px;
  }
`;

export const Icon = styled(ReturnIconGreen)`
  transform: ${({ position }) =>
    `${position === 'right' ? 'rotate(180deg)' : 'rotate(0deg)'}`};
  width: 15px;
  height: 15px;
  position: absolute;
`;
