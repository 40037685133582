import styled from 'styled-components';

import Link from 'components/Link';

export const TermsContainer = styled.div`
  color: ${(props) => props.theme.color.baliHai};
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  align-items: center;
  padding-top: 12px;

  & > input {
    margin: 10px;
  }
`;

export const SignInLink = styled(Link)`
  font-size: 13px;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const StyledTerms = styled.span`
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;
