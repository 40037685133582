import { createRoutine } from 'redux-saga-routines';

import { FullUser } from 'types';

export const doLogin = createRoutine('LOGIN', {
  trigger: (payload: {
    email: string;
    password: string;
    onFailure: (error: string) => void;
  }) => payload,
});

export const doLogout = createRoutine('LOGOUT');

export const doCheckIsAuthorized = createRoutine('CHECK_IS_AUTHORIZED', {
  trigger: (payload: { onSuccess?: (user: FullUser, session: any) => void }) =>
    payload,
});
