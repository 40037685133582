import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export const FIELDS = {
  EMAIL: 'email',
};

export const useChangeEmailSchema = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  return yupResolver(
    yup.object().shape({
      [FIELDS.EMAIL]: yup.string().email(t('errors.email')).required(),
    }),
  );
};
export const useChangeEmailForm = () =>
  useForm({
    resolver: useChangeEmailSchema(),
  });
