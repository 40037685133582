import React, {
  FC,
  FunctionComponent,
  SVGProps,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash/fp';

import IconOption from './IconOption';
import ValueOption from './ValueOption';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import { StyledSelect } from './styled';
import { FieldError } from 'react-hook-form';

interface IconSelectInputProps {
  name: string;
  label?: string;
  options: {
    value: string | number;
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  }[];
  width?: string;
  defaultValue?: string;
  error?: FieldError;
  setValue: (name: string, value: string) => void;
  register: any;
}

const IconSelectInput: FC<IconSelectInputProps> = ({
  name,
  label,
  options,
  width,
  defaultValue,
  error,
  setValue = () => {},
  register = () => {},
}) => {
  const { t } = useTranslation();
  const [value, onChangeValue] = useState(defaultValue);

  useEffect(() => {
    register({ name });
    setValue(name, value);
  }, [register, name, setValue, value]);

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        defaultValue={find((option) => defaultValue === option.value, options)}
        error={error}
        width={width}
        id={name}
        name={name}
        options={options}
        placeholder=""
        classNamePrefix="react-select"
        onChange={(e) => onChangeValue(e.value)}
        components={{ Option: IconOption, SingleValue: ValueOption }}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default IconSelectInput;
