import React, { FC } from 'react';

import {
  Container,
  Bage,
  Message,
  StyledClose,
  StyledImg,
  CloseBtnWrapper,
} from './styled';

interface ToastProps {
  appearance: 'success' | 'error';
  children:
    | string
    | { link?: string; message: string; img?: string; image?: string };
  onDismiss?: () => void;
}

const Toast: FC<ToastProps> = ({ appearance, children, onDismiss }) => (
  <Container id="notificationTab">
    {children.link && (
      <a
        href={children.link as string}
        target="_blank"
        rel="noopener noreferrer"
      />
    )}
    <Bage appearance={appearance} />
    {typeof children === 'string' ? (
      <Message>{children}</Message>
    ) : (
      <>
        {(children.img || children.image) && (
          <StyledImg src={children.img || children.image} />
        )}
        <Message>{children.message}</Message>
      </>
    )}
    <CloseBtnWrapper onClick={onDismiss}>
      <StyledClose />
    </CloseBtnWrapper>
  </Container>
);

export default Toast;
