import { axiosFormInstance as axiosForm } from './index';

export const sendEmail = async (communityId: string, data: any, FIELDS) => {
  const formData = await new FormData();

  formData.append(FIELDS.SEND_TO_ME, data[FIELDS.SEND_TO_ME]);
  formData.append(FIELDS.SUBJECT, data[FIELDS.SUBJECT]);
  formData.append('body', data[FIELDS.MESSAGE]);
  formData.append(
    'recipients',
    JSON.stringify([
      ...new Set([
        ...data[FIELDS.RECIPIENTS],
        ...data[FIELDS.OTHER_RECIPIENTS],
      ]),
    ]),
  );

  if (data.attachments) {
    data.attachments.forEach((i) => formData.append('attachments', i));
  }

  const response = await axiosForm.post(
    `/communities/${communityId}/sendMail`,
    formData,
  );

  return response.data;
};
