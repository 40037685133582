import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find, get } from 'lodash/fp';
import { PayPalButton } from 'react-paypal-button-v2';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Loader,
  NumberInput,
  PopupEventWrapper,
  ReturnLinkButton,
  SelectInput,
  Tooltip,
  AdBlockerPopup,
} from 'components';
import { addMonths, getUTCDateAddMonth, getUTCDateString } from 'utils/dates';
import { checkAdBlocker } from 'utils';
import { PROFILE_ROUTES, ROUTES } from 'configs';
import {
  selectAllSubscriptions,
  selectCurrentUserSubscription,
  selectIsSubscriptionLoading,
  selectPaypalPlanId,
} from 'store/selectors/subscriptions';
import {
  cancelSubscription,
  clearSubscriptionPlanId,
  getSubscriptionPlanId,
  paymentApproved,
  setSubscription,
  switchSubscription,
} from 'store/actionCreators/subscriptions';
import { SubscriptionFrequency, SubscriptionStatus } from 'types';

import {
  CheckMark,
  OptionLabel,
  StyledCloseIcon,
} from 'components/SubscriptionCard/styled';
import {
  Content,
  ContentInner,
  DetailsInner,
  Header,
  HeaderContainer,
  InnerAnimCircle,
  StyledBluredBox,
  StyledCancelSubscription,
  StyledHeader,
  StyledLink,
  StyledNumberContainer,
  StyledP,
  StyledProblem,
  StyledRightWrapper,
  StyledSummaryUL,
  StyledTitle,
  StyledTotalUL,
  StyledUList,
  SubContainer,
  SubContainerBL,
  SubContainerBRB,
  SubContainerBRT,
} from '../styled';
import { StyledFloatingLoader } from '../../UserProfileSettings/styled';

const SubscriptionInner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { subscriptionId: currentSubscriptionId } = useParams();
  const [searchParams] = useSearchParams();
  const isLoading = useSelector(selectIsSubscriptionLoading);
  const subscriptions = useSelector(selectAllSubscriptions);
  const activeSubscription = useSelector(selectCurrentUserSubscription);
  const paypalSubscriptionPlanId = useSelector(selectPaypalPlanId);
  const [isProcessing, setIsProcessing] = useState(false);
  const [usingBlocker, setUsingBlocker] = useState<boolean>(false);
  const [hasAdBlocker, setHasAdBlocker] = useState<boolean>(false);

  const frequency = searchParams.get('frequency');

  const currentUserSubscriptionFrequency = activeSubscription?.frequency;
  const currentUserSubscriptionStatus = activeSubscription?.status;
  const currentUserSubscription = subscriptions?.find(
    ({ id }) => id === currentSubscriptionId,
  );

  useEffect(() => {
    checkAdBlocker().then((result) => {
      setUsingBlocker(result);
    });
  }, []);

  useEffect(() => {
    if (
      isProcessing &&
      currentUserSubscriptionStatus !== SubscriptionStatus.CANCELLED &&
      currentSubscriptionId === activeSubscription?.subscriptionPlanId &&
      currentUserSubscriptionFrequency === frequency
    ) {
      setIsProcessing(false);
      navigate('/profile/subscription');
    }
  }, [
    currentUserSubscriptionStatus,
    currentSubscriptionId,
    currentUserSubscriptionFrequency,
  ]);

  const {
    maxGroups,
    maxMembers,
    maxCommunities,
    maxStorage,
    currency,
    monthlyValue,
    yearlyValue,
    chatEnabled,
    name,
    tax,
    id,
  } = currentUserSubscription;

  const bucketSize = activeSubscription?.info?.bucketSize || 0;
  const communities = activeSubscription?.info?.communities || 0;
  const groups = activeSubscription?.info?.groups || 0;
  const users = activeSubscription?.info?.users || 0;

  const checkedButton = useMemo(() => {
    return frequency === SubscriptionFrequency.YEAR ? 'left' : 'right';
  }, [frequency]);

  const classicSubscriptions = useMemo(
    () =>
      subscriptions.filter(({ name: subName }) =>
        subName.includes('Classic'),
      ) || [],
    [subscriptions],
  );
  const customSubscriptions = useMemo(
    () =>
      subscriptions.filter(({ name: subName }) => subName.includes('Custom')) ||
      [],
    [subscriptions],
  );
  const clientId = get(['REACT_APP_PAYPAL_ID'], process.env);
  const isCustom = name.includes('Custom');
  const isCurrent =
    currentUserSubscriptionStatus !== SubscriptionStatus.CANCELLED &&
    currentSubscriptionId === activeSubscription?.subscriptionPlanId &&
    currentUserSubscriptionFrequency === frequency;
  const currentPrice =
    frequency === SubscriptionFrequency.YEAR ? yearlyValue : monthlyValue;
  const classicOptions = classicSubscriptions.map(
    ({ id: value, name: label }) => ({ value, label }),
  );
  const defaultCustomValue = {
    value: 'custom',
    label: t('subscriptions.buildCustom'),
  };
  const selectSubscriptions = [...classicOptions, defaultCustomValue];

  const planIdx = useMemo(() => {
    const result =
      classicSubscriptions.filter(({ id }) => id === currentSubscriptionId)
        .length !== 0
        ? classicSubscriptions.findIndex(
            ({ id }) => id === currentSubscriptionId,
          )
        : customSubscriptions.findIndex(
            ({ id }) => id === currentSubscriptionId,
          );

    return result || 0;
  }, [classicSubscriptions, customSubscriptions, currentSubscriptionId]);

  const checkMarkType = isCustom ? 'custom' : '';

  const onRadioClick = ({ target }) => {
    navigate({
      pathname: location.pathname,
      search: `?frequency=${
        target.value === 'left'
          ? SubscriptionFrequency.YEAR
          : SubscriptionFrequency.MONTH
      }`,
    });

    dispatch(clearSubscriptionPlanId());
  };

  const addHandler = useCallback(() => {
    if (planIdx === customSubscriptions.length - 1) return;

    navigate({
      pathname: `/profile/subscription/plans/${
        customSubscriptions[planIdx + 1].id
      }`,
      search: `?frequency=${frequency}`,
    });

    dispatch(clearSubscriptionPlanId());
  }, [planIdx, customSubscriptions, frequency]);

  const subHandler = useCallback(() => {
    if (!planIdx) return;

    navigate({
      pathname: `/profile/subscription/plans/${
        customSubscriptions[planIdx - 1].id
      }`,
      search: `?frequency=${frequency}`,
    });

    dispatch(clearSubscriptionPlanId());
  }, [planIdx, customSubscriptions, frequency]);

  const handleSelectSubscription = (_, subId) => {
    const newId = subId === 'custom' ? customSubscriptions[planIdx].id : subId;

    dispatch(
      switchSubscription({
        id: newId,
        frequency,
      }),
    );

    navigate({
      pathname: `/profile/subscription/plans/${newId}`,
      search: `?frequency=${frequency}`,
    });

    dispatch(clearSubscriptionPlanId());
  };

  const defaultSelectValue = isCustom
    ? defaultCustomValue
    : find({ value: id }, selectSubscriptions);

  const displayPayButton = useMemo(() => {
    if (!isCurrent) {
      return true;
    } else {
      if (
        new Date().getTime() >
          new Date(activeSubscription?.endDate || null).getTime() ||
        (activeSubscription?.status === SubscriptionStatus.ACTIVE &&
          activeSubscription?.planId === SubscriptionStatus.MIGRATED)
      ) {
        return true;
      }
    }

    return false;
  }, [isCurrent, activeSubscription]);

  const getEndOfPeriod = useCallback(
    (date: Date) => {
      if (frequency === SubscriptionFrequency.MONTH) {
        return (
          displayPayButton ? getUTCDateAddMonth(date) : getUTCDateString(date)
        ).replace(/\./gi, '/');
      }

      return (
        displayPayButton
          ? getUTCDateString(addMonths(date, 12))
          : getUTCDateString(date)
      ).replace(/\./gi, '/');
    },
    [frequency, isCurrent, activeSubscription],
  );

  const expirationDate = useMemo(() => {
    if (displayPayButton) {
      return new Date(Date.now());
    }

    return new Date(activeSubscription.endDate);
  }, [displayPayButton, activeSubscription]);

  const getNumberInputComponent = useCallback(
    (value: number) => {
      if (isCustom) {
        return (
          <StyledNumberContainer>
            <NumberInput
              name="maxCommunities"
              height="39px"
              width="99px"
              color="danger"
              value={value}
              onAdd={addHandler}
              onSubtract={subHandler}
            />
          </StyledNumberContainer>
        );
      }

      return null;
    },
    [isCustom, planIdx, frequency],
  );

  const payButtonContainerContent = useMemo(() => {
    if (displayPayButton) {
      return (
        <li>
          {!isProcessing && !paypalSubscriptionPlanId ? (
            <>
              <Button
                width="281px"
                variant={isLoading ? 'secondary' : null}
                disabled={isLoading}
                onClick={() => {
                  dispatch(
                    getSubscriptionPlanId({
                      productId: currentSubscriptionId,
                      frequency,
                    }),
                  );
                }}
              >
                {isLoading ? (
                  <Loader type="button" size="28px" thickness={2} />
                ) : (
                  t('subscriptions.pay')
                )}
              </Button>
              {/* {usingBlocker && (
                <div
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => {
                    setHasAdBlocker(true);
                  }}
                >
                  <Tooltip
                    text={t('subscriptions.usingBlocker')}
                    zoom={1}
                    isVisible
                  >
                    <StyledProblem>
                      <InnerAnimCircle></InnerAnimCircle>
                      <div style={{ zIndex: 2 }}>?</div>
                    </StyledProblem>
                  </Tooltip>
                </div>
              )} */}
            </>
          ) : (
            <>
              {isProcessing && (
                <StyledBluredBox>
                  <Loader size="30px" type={null} />
                </StyledBluredBox>
              )}
              <PayPalButton
                onApprove={(data, actions) => {
                  setIsProcessing(true);
                  dispatch(paymentApproved(actions));
                }}
                options={{
                  clientId,
                  vault: true,
                  intent: 'subscription',
                }}
                style={{
                  color: 'silver',
                }}
                createSubscription={async (data, actions) => {
                  const res = await actions.subscription.create({
                    plan_id: paypalSubscriptionPlanId,
                  });
                  dispatch(
                    setSubscription({
                      productId: currentSubscriptionId,
                      pricing: frequency,
                      subscriptionId: res,
                    }),
                  );
                  return res;
                }}
              />
            </>
          )}
        </li>
      );
    }

    return <li></li>;
  }, [
    isProcessing,
    paypalSubscriptionPlanId,
    displayPayButton,
    usingBlocker,
    isLoading,
    frequency,
    currentSubscriptionId,
  ]);

  return (
    <Content>
      <AdBlockerPopup
        isOpen={hasAdBlocker}
        onClose={() => {
          setHasAdBlocker(false);
        }}
      />
      <HeaderContainer>
        <Header>
          <ReturnLinkButton
            to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.SUBSCRIPTION}/${PROFILE_ROUTES.PLANS}`}
          />
          <StyledTitle>{t('subscriptions.title')}</StyledTitle>
        </Header>
      </HeaderContainer>
      <ContentInner>
        <StyledP>
          {t('subscriptions.textInnerTop', {
            groups: maxGroups,
            members: maxMembers,
          })}
        </StyledP>
        <DetailsInner>
          <div>
            <SubContainer>
              <SelectInput
                name="subscriptionSelect"
                width="100%"
                label={t('subscriptions.subscription')}
                options={selectSubscriptions}
                setValue={handleSelectSubscription}
                defaultValue={defaultSelectValue}
                register={() => {}}
              />
            </SubContainer>
            <SubContainer>
              <ButtonGroup
                name="subscriptions"
                leftText={t('subscriptions.yearly')}
                leftValue="left"
                rightText={t('subscriptions.monthly')}
                rightValue="right"
                width="100%"
                onClick={onRadioClick}
                checkedButton={checkedButton}
                promo
              />
            </SubContainer>
            <SubContainerBL>
              <StyledUList>
                <li>
                  <div>
                    {chatEnabled ? (
                      <OptionLabel>
                        <CheckMark type={checkMarkType} />
                        {t('subscriptions.chatEnabled')}
                      </OptionLabel>
                    ) : (
                      <OptionLabel>
                        <StyledCloseIcon />
                        {t('subscriptions.chatExcluded')}
                      </OptionLabel>
                    )}
                  </div>
                </li>
                <li>
                  <div>
                    <OptionLabel>
                      <CheckMark type={checkMarkType} />
                      {maxGroups} {t('common.groups')}
                    </OptionLabel>
                    <span>
                      {t('subscriptions.usedGroups')}
                      <strong> {groups}</strong>/{maxGroups}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <OptionLabel>
                      <CheckMark type={checkMarkType} />
                      {!isCustom && maxMembers} {t('common.member')}
                    </OptionLabel>
                    <span>
                      {t('subscriptions.usedMembers')}
                      <strong> {users}</strong>/{maxMembers}
                    </span>
                  </div>
                  {getNumberInputComponent(maxMembers)}
                </li>
                <li>
                  <div>
                    <OptionLabel>
                      <CheckMark type={checkMarkType} />
                      {!isCustom && maxCommunities}{' '}
                      {t('subscriptions.community')}
                    </OptionLabel>
                    <span>
                      {t('subscriptions.usedCommunity')}
                      <strong> {communities}</strong>/{maxCommunities}
                    </span>
                  </div>
                  {getNumberInputComponent(maxCommunities)}
                </li>
                <li>
                  <div>
                    <OptionLabel>
                      <CheckMark type={checkMarkType} />
                      {maxStorage} {t('subscriptions.maxStorage')}
                    </OptionLabel>
                    <span>
                      {t('subscriptions.usedStorage')}
                      <strong> {bucketSize} GB</strong>/{maxStorage}
                    </span>
                  </div>
                </li>
              </StyledUList>
            </SubContainerBL>
          </div>
          <StyledRightWrapper>
            <SubContainerBRT>
              <StyledHeader>{t('subscriptions.orderSummary')}</StyledHeader>
              <StyledSummaryUL>
                <li>
                  {isCustom ? 'Custom' : name}
                  <span>
                    {tax > 0
                      ? ((currentPrice * (100 - tax)) / 100).toFixed(1)
                      : currentPrice}{' '}
                    {currency}
                  </span>
                </li>
                <li>
                  {maxGroups} {t('common.groups')}
                </li>
                <li>
                  {maxMembers} {t('common.member')}
                </li>
                <li>
                  {maxCommunities} {t('subscriptions.community')}
                </li>
                <li>
                  {t('subscriptions.periodEnds')}{' '}
                  {getEndOfPeriod(expirationDate)}
                </li>
                <li>
                  {t('subscriptions.tax')}
                  <span>{tax}%</span>
                </li>
              </StyledSummaryUL>
            </SubContainerBRT>
            <SubContainerBRB>
              <StyledTotalUL>
                <li>
                  {t('subscriptions.total')}
                  <span>
                    {currentPrice} {currency}
                  </span>
                </li>
                {payButtonContainerContent}
                <li>
                  <StyledLink
                    href="https://paypal.com/invoice/manage"
                    target="_blank"
                  >
                    {t('subscriptions.paypalLink')}
                  </StyledLink>
                  {isCurrent && (
                    <>
                      {isLoading && (
                        <StyledFloatingLoader>
                          <Loader size="60px" type={null} />
                        </StyledFloatingLoader>
                      )}
                      <PopupEventWrapper
                        popupMessageText={t('subscriptions.cancelConfirmation')}
                        popupButtons={[
                          {
                            popupButtonText: t('subscriptions.unsubscribe'),
                            handleConfirm: () => {
                              dispatch(
                                cancelSubscription(
                                  activeSubscription?.subscriptionId,
                                ),
                              );
                            },
                          },
                        ]}
                        darkBackground={true}
                      >
                        <StyledCancelSubscription>
                          {t('subscriptions.cancelSubscription')}
                        </StyledCancelSubscription>
                      </PopupEventWrapper>
                    </>
                  )}
                </li>
              </StyledTotalUL>
            </SubContainerBRB>
          </StyledRightWrapper>
        </DetailsInner>
        <StyledP>{t('subscriptions.textInnerBottom')}</StyledP>
      </ContentInner>
    </Content>
  );
};

export default SubscriptionInner;
