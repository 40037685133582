export const makeBinaryArrayFromDaysObjectsArray = (
  daysObjArr: { label: string; value: number }[],
): number[] => daysObjArr.map((day) => day.value);

export const makeIntFromBinaryArray = (arr: number[]): number =>
  parseInt(arr.join(''), 2);

export const makeBinaryArrayFromInt = (int: number): string[] =>
  Array.from(int.toString().padStart(7, '0'));

export const makeDaysObjectsArrayFromBinaryArray = (
  arr: string[],
): {
  label: string;
  value: string;
}[] => [
  { label: 'Su', value: arr[0] },
  { label: 'Mo', value: arr[1] },
  { label: 'Tu', value: arr[2] },
  { label: 'We', value: arr[3] },
  { label: 'Th', value: arr[4] },
  { label: 'Fr', value: arr[5] },
  { label: 'Sa', value: arr[6] },
];

export const makeDaysArrayFromDaysObjectsArray = (
  daysObjArr: { label: string; value: string }[],
): string[] =>
  daysObjArr.filter((day) => day.value === '1').map((day) => day.label);
