import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledBlock = styled(NavLink)`
  text-decoration: none;
  width: 54px;
  height: 54px;
  border: 3px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: 4px 0;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  &:hover {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }
  &.active {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.color.brightTurquoise};
    `};

  &:after {
    content: '';
    display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
    width: 40px;
    height: 40px;
    background: #00000052;
    position: absolute;
    border-radius: 50%;
  }
`;
