import styled from 'styled-components';

export const StyledMainMenuBlock = styled.div`
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 100svh;
  margin-right: ${({ isMenuOpen }) => (isMenuOpen ? 60 : 0)}px;
  transition: margin-right 0.3s;
  position: fixed;
  z-index: 100000000;
  background: #ebf2f7;
`;

export const StyledMainMenuTopBlock = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-gap: 2px;
  align-content: start;
`;

export const StyledMainMenuCommunities = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  bottom: 2px;
  & > a {
    margin-top: 2px;
  }
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.color.casper};
  opacity: 50%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const StyledFaqButtonBlock = styled.div`
  margin-top: 18px;
  margin-bottom: 10px;
`;

export const StyledBottomMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
`;
