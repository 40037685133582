import React, { FC } from 'react';

import { ReturnLink, ReturnLinkIcon } from './styled';

interface ReturnLinkButtonProps {
  to: string;
  text?: string;
}

const ReturnLinkButton: FC<ReturnLinkButtonProps> = ({ to = '/', text }) => (
  <ReturnLink to={to}>
    <ReturnLink.Wrapper>
      <ReturnLinkIcon />
      {text && <ReturnLink.Text>{text}</ReturnLink.Text>}
    </ReturnLink.Wrapper>
  </ReturnLink>
);

export default ReturnLinkButton;
