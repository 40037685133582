import { TFunction } from 'react-i18next';

export const getAmplifyAuthErrorMessage = (
  t: TFunction,
  error: { code?: string; message?: string },
) => {
  switch (error?.code) {
    case 'UserNotFoundException':
      return t('auth.userNotFound');
    case 'NotAuthorizedException':
      return t('auth.invalidCredentials');
    case 'UsernameExistsException':
      return t('auth.accountExists');
    case 'UserNotConfirmedException':
      return t('auth.userNotConfirmed');
    case 'ExpiredCodeException':
    case 'CodeMismatchException':
      return t('auth.codeExpired');
    default:
      return error?.message || t('errors.text500');
  }
};
