import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, FieldError, useController } from 'react-hook-form';

import { CalendarIcon } from 'static';

import { StyledPickerWrapper, StyledDatePicker } from './styled';
import { SharedStyledDateIcon } from '../styled';
import { StyledLabel, StyledErrorMessage } from '../../styled';

interface DatePickerControlledV2Props {
  name: string;
  control: Control;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  error?: FieldError;
  popperModifiers?: { [key: string]: string };
}

const DatePickerControlledV2: FC<DatePickerControlledV2Props> = ({
  name,
  control,
  label,
  minDate,
  maxDate,
  error,
  popperModifiers = {},
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name,
    control,
  });

  const CalendarContainer = ({ className, children }) => {
    return (
      <div
        style={{
          height: '100%',
          padding: '16px',
          backgroundColor: '#ffffff',
          color: '#304659',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          transition: 'all',
          gap: '8px',
          boxShadow: '0px 0px 8px 2px #cfcfcf',
        }}
      >
        <div
          className={className}
          style={{
            borderRadius: '8px',
            borderColor: '#ffffff',
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  return (
    <>
      <StyledPickerWrapper>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
        <StyledDatePicker
          id={name || false}
          selected={field.value}
          dateFormat="dd.MM.yyyy"
          minDate={minDate}
          maxDate={maxDate}
          error={error}
          popperPlacement="bottom-left"
          popperModifiers={popperModifiers}
          onChange={field.onChange}
          calendarContainer={CalendarContainer}
        />
        <SharedStyledDateIcon src={CalendarIcon} alt="" />
      </StyledPickerWrapper>
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </>
  );
};

export default DatePickerControlledV2;
