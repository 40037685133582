import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FIELDS, useSignUpForm } from './form';
import { checkIfUserExists } from 'services/communities';
import { deleteCognitoProfile } from 'services/user';
import { getAmplifyAuthErrorMessage } from '../utils';
import { useInfoPopup } from 'hooks';

export const useSignUp = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit, setValue, ...form } = useSignUpForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showInfoPopup } = useInfoPopup();
  const [searchParams] = useSearchParams();

  const onFailure = (error) => {
    const title = getAmplifyAuthErrorMessage(t, error);

    showInfoPopup({
      type: 'error',
      title,
    });
  };

  const onSubmit = handleSubmit(async (formData) => {
    const invitationId = searchParams.get('invitationId');
    const invitationCode = searchParams.get('invitationCode');

    const clientMetadata = {
      invitationId,
      invitationCode,
      language: i18n.language,
      [FIELDS.FIRST_NAME]: formData[FIELDS.FIRST_NAME],
      [FIELDS.LAST_NAME]: formData[FIELDS.LAST_NAME],
    };

    try {
      setIsLoading(true);

      const response = await checkIfUserExists(formData.email.toLowerCase());

      if (response.data.id) {
        showInfoPopup({
          type: 'error',
          title: t('auth.accountExists'),
        });
      } else {
        await deleteCognitoProfile(formData.email.toLowerCase());
        await Auth.signUp({
          username: formData.email.toLowerCase(),
          password: formData.password,
          clientMetadata,
        });
        showInfoPopup({
          type: 'success',
          title: t('auth.signUpSuccess'),
        });
        navigate('/');
      }
    } catch (err) {
      if (
        err?.response?.status === 400 &&
        err?.response?.data?.error === 'NOT_FOUND'
      ) {
        await deleteCognitoProfile(formData.email.toLowerCase());
        await Auth.signUp({
          username: formData.email.toLowerCase(),
          password: formData.password,
          clientMetadata,
        });
        showInfoPopup({
          type: 'success',
          title: t('auth.signUpSuccess'),
        });
        navigate('/');
      } else {
        onFailure(err);
      }
    } finally {
      setIsLoading(false);
    }
  });

  return {
    onSubmit,
    setValue,
    ...form,
    isLoading,
  };
};
