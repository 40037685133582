import { createRoutine } from 'redux-saga-routines';

export interface MessageTemplate {
  title: string;
  message: string;
  redirectLink?: string;
}

export type PushTemplateFormat = {
  id: string;
  communityId: string;
  userId: string;
  isTemplate: boolean;
  createdAt: string;
  messageTemplate: MessageTemplate;
};

export interface UserMessageTemplate {
  userId: string;
  communityId: string;
  messageTemplate: MessageTemplate[];
}

export const doGetPushTemplates = createRoutine('GET_PUSH_TEMPLATES', {
  trigger: (payload: {
    communityId: string;
    userId: string;
    defaultTemp?: UserMessageTemplate;
  }) => payload,
});

export const doCreatePushTemplate = createRoutine('CREATE_PUSH_TEMPLATES', {
  trigger: (payload: {
    userId: string;
    communityId: string;
    title: string;
    message: string;
    onSuccess?: () => void;
  }) => payload,
  success: (payload: PushTemplateFormat) => payload,
});

export const doDeletePushTemplate = createRoutine('DELETE_PUSH_TEMPLATE', {
  trigger: (payload: {
    id: string;
    userId: string;
    communityId: string;
    onSuccess?: () => void;
  }) => payload,
  success: (payload: { id: string }) => payload,
});

export const doSendPushNotification = createRoutine('SEND_PUSH_NOTIFICATION', {
  trigger: (payload: {
    data: {
      userId: string;
      communityId: string;
      withImage: boolean;
      title: string;
      message: string;
      redirectLink: string;
      sendCopyToMe: boolean;
      recipients: string[];
      eventId?: string;
    };
    onSuccess?: () => void;
  }) => payload,
  success: (payload: PushTemplateFormat) => payload,
});
