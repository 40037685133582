import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';

import {
  doApproveRequests,
  doRejectRequests,
  doRequestRequests,
} from 'store/actionCreators/currentCommunity';
import { CheckList, Button, Loader } from 'components';
import { RejectIcon } from 'static';
import { useHandleRequest } from './hooks';
import {
  getCurrentCommunity,
  getInviteRequestsLoading,
} from 'store/selectors/currentCommunity';
import { useInfoPopup } from 'hooks';

import { StyledButtonBlock, StyledPlaceholder } from './styled';

const RequestsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getInviteRequestsLoading);
  const { showInfoPopup } = useInfoPopup();

  useEffect(() => {
    dispatch(doRequestRequests(community.id));
  }, []);

  const handleApproveRequests = (requests) => {
    dispatch(
      doApproveRequests({
        ...requests,
        onSuccess: () =>
          showInfoPopup({
            type: 'success',
            title: t('group.addMembersSuccessWithMessage.title'),
            message: t('group.addMembersSuccessWithMessage.message'),
            duration: 4500,
            loop: false,
            noButton: true,
          }),
      }),
    );
  };

  const handleRejectRequests = (requests) => {
    dispatch(doRejectRequests(requests));
  };

  const { register, onSubmit, getValues } = useHandleRequest(
    handleApproveRequests,
    handleRejectRequests,
    community.id,
  );

  const handleSubmit = (submitType: 'approve' | 'reject') => {
    const selections = Object.values(getValues(undefined));
    const isAtleastOneSelected = selections.some(
      (checkBoxState) => checkBoxState === true,
    );
    if (isAtleastOneSelected) {
      onSubmit(submitType)();
    } else
      showInfoPopup({
        title: t('communitySettings.selectUsers.title'),
        message: t('communitySettings.selectUsers.message'),
        type: 'warning',
        duration: 3000,
        noButton: true,
        loop: false,
      });
  };

  if (isLoading || !community.requests) {
    return <Loader />;
  }

  return !isEmpty(community.requests) ? (
    <>
      <CheckList
        label={t('common.selectAll')}
        usersList
        withEmail
        items={community.requests.map((r) => ({ ...r.user.profile, id: r.id }))}
        register={register}
        preSelectAll
      />
      <StyledButtonBlock>
        {isLoading ? (
          <Loader type="button" size="28px" thickness={2} />
        ) : (
          <>
            <Button type="submit" onClick={() => handleSubmit('approve')}>
              {t('common.approve')}
            </Button>
            <Button
              variant="secondary"
              IconComponent={RejectIcon}
              onClick={() => handleSubmit('reject')}
            >
              {t('common.reject')}
            </Button>
          </>
        )}
      </StyledButtonBlock>
    </>
  ) : (
    <StyledPlaceholder>
      <h3>{t('community.noRequestsTitle')}</h3>
      <p>{t('community.noRequestsText')}</p>
    </StyledPlaceholder>
  );
};

export default RequestsPage;
