import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getEventResponses } from 'store/selectors/eventResponses';
import { EventGridItem, MemberFiguresStatistics, StatisticsData } from 'types';
import { AttendedStar, NoResponse, PenaltyDot } from 'static';
import {
  CellTitleWithIconWrapper,
  DescriptionWrapper,
} from '../AppointmentSchedule/styled';
import { Tooltip } from '../../index';

export const useHeaderData = (zoom: number) => {
  const { t } = useTranslation();
  const responses = useSelector(getEventResponses) || [];

  return [
    {
      title: t('common.email'),
    },
    {
      title: t('profile.person'),
      width: 260,
    },
    {
      title: t('profile.dateOfBirth'),
    },
    {
      title: t('event.eventName'),
      width: 260,
    },
    {
      title: t('event.eventType'),
    },
    {
      title: t('event.weekDay'),
    },
    {
      title: t('common.startTime'),
    },
    {
      title: t('common.endTime'),
    },
    {
      title: t('event.duration'),
    },
    {
      title: t('event.location'),
    },
    {
      title: t('event.eventOrganizer'),
    },
    {
      title: t('subscriptions.community'),
    },
    {
      title: (
        <CellTitleWithIconWrapper zoom={zoom}>
          <div>{t('event.awaiting')}</div>
          <NoResponse />
        </CellTitleWithIconWrapper>
      ),
    },
    {
      title: (
        <CellTitleWithIconWrapper zoom={zoom}>
          <div>{t('event.nomination')}</div>
          <AttendedStar />
        </CellTitleWithIconWrapper>
      ),
    },
    {
      title: (
        <CellTitleWithIconWrapper className="penalty" zoom={zoom}>
          <div>{t('event.penalty')}</div>
          <PenaltyDot />
        </CellTitleWithIconWrapper>
      ),
    },
    ...responses.map((r) => ({
      title: r.title,
      color: r.color,
    })),
  ];
};

export const useGridData = (data: MemberFiguresStatistics, zoom: number) => {
  const responses = useSelector(getEventResponses) || [];

  const gridData: (EventGridItem & { values: StatisticsData[] })[] =
    useMemo(() => {
      return (data?.data || []).map((i) => ({
        type: 'statistics',
        id: i.id,
        label: i.date,
        values: [
          ...[
            { text: i.email, align: 'flex-start' },
            { text: i.profileName, align: 'flex-start' },
            { text: i.dob, align: 'flex-start' },
            { text: i.eventName, align: 'flex-start' },
            { text: i.eventType, align: 'flex-start' },
            { text: i.weekday, align: 'flex-start' },
            { text: i.startTime, align: 'flex-start' },
            {
              text: i.endTime,
              align: 'flex-start',
            },
            { text: i.duration, align: 'flex-start' },
            { text: i.location, align: 'flex-start' },
            { text: i.eventOrganizer, align: 'flex-start' },
            { text: i.communityName, align: 'flex-start' },
          ].map((i, ind) => ({
            align: i.align,
            clickable: true,
            component: (
              <Tooltip
                isVisible
                zoom={zoom}
                text={i.text as string}
                offset={11 * zoom}
                position="top-center"
                visibleAfterWidth={(ind === 1 || ind === 3 ? 250 : 112) * zoom}
                opacity="1"
                tooltipStyle={{
                  fontSize: 12 * zoom,
                  maxWidth: 200 * zoom,
                  display: 'block',
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  lineBreak: 'normal',
                }}
              >
                <DescriptionWrapper>{i.text}</DescriptionWrapper>
              </Tooltip>
            ),
          })),
          {
            color: i.awaiting ? '#8298ABD1' : null,
            text: i.awaiting ? '1' : null,
            align: 'center',
          },
          {
            color: i.nomination ? '#8298ABD1' : null,
            text: i.nomination ? '1' : null,
            align: 'center',
          },
          {
            color: i.penalty ? '#8298ABD1' : null,
            text: i.penalty ? '1' : null,
            align: 'center',
          },
          ...responses.map((response) => ({
            align: 'center',
            text: i.eventResponses[response.id] ? '1' : null,
            color: i.eventResponses[response.id] ? `${response.color}d1` : null,
          })),
        ],
      }));
    }, [data, responses]);

  return gridData;
};
