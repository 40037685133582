import styled, { css } from 'styled-components';
import { breakpoints } from 'theme';

export const StyledTab = styled.li`
  &:not(:last-child) {
    margin-right: ${({ isSidebar, marginRight }) =>
      isSidebar ? '5px' : marginRight ? marginRight : '25px'};
  }

  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  position: relative;
  color: ${({ theme }) => theme.color.baliHai};
  white-space: nowrap;

  & > svg {
    width: 20px;
    margin-left: 5px;
    margin-right: 5px;
    path {
      fill: ${({ theme }) => theme.color.baliHai};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.brightTurquoise};
    & > svg {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }

  border-radius: 24px;
  /* padding: ${({ isSidebar }) => (isSidebar ? '0px 4px' : '0px 10px')}; */
  border: none;

  @media ${breakpoints.smPlus} {
    padding: ${({ isSidebar }) => (isSidebar ? '6px' : '10px')};
    margin: ${({ isSidebar }) => (isSidebar ? '2px 10px' : 'auto 0px')};
    transition: 0.25s;
  }
  @media ${breakpoints.downSmPlus} {
    padding: 2px 10px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.brightTurquoise};

      & > svg {
        path {
          fill: ${({ theme }) => theme.color.white};
        }
      }
    `}
`;

export const StyledItemPlaceholder = styled.div`
  flex-grow: 1;
`;
export const NewIcon = styled.div`
  display: flex;
  margin-right: 19px;
  cursor: pointer;
`;

export const StyledRightIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900000;
  > div {
    width: 20px;
    height: 20px;
    transition: all 0.2s linear;
    [aria-label='NEW'] {
      fill: white !important;
    }
    svg {
      [fill='rgb(15,204,198)'] {
        fill: #33aba7 !important;
      }
    }
    :hover {
      scale: 1.2;
      [aria-label='NEW'] {
        fill: ${({ theme }) => theme.color.pickledBluewood} !important;
      }
    }
  }
`;
