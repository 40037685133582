import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { useGoogleLogin } from '@react-oauth/google';
import Lottie from 'react-lottie';

import { ContentWrapper, Button, Loader, InfoPopup } from 'components';
import { getAvailableCommunities } from 'store/selectors/communities';
import {
  getChangeProfileSettingsLoading,
  getSettings,
  getFullAuthUser,
  getLinkCalendarLoading,
  getUnlinkCalendarLoading,
} from 'store/selectors/auth';
import CommunitiesList from './CommunitiesList';
import { GoogleCalendarIcon } from 'static';
import CheckboxRequiredImg from 'static/images/google-checkbox-required.png';
import { useChangeSyncEvents } from './hooks';
import ApplceCalendar from './AppleCalendar';
import { FIELDS } from './form';
import {
  doLinkGoogleCalendar,
  doUnlinkGoogleCalendar,
} from 'store/actionCreators/profile';
import { breakpoints } from 'theme';
import { useInfoPopup } from 'hooks';
import GoogleAuthButton from 'components/Buttons/Auth/Google';

import successCheck from 'static/animations/success-check.json';

import {
  Layout,
  Heading,
  CalendarsCard,
  ConnectionLabel,
  StyledButtonBlock,
  LoaderWrapper,
  BeforeConnectedWrapper,
  AfterConnectedWrapper,
  CalendarTitle,
  ConnectButtonWrapper,
} from './styled';

const UserProfileCalendars = () => {
  const { t } = useTranslation();
  const [expandedBlocks, setExpandedBlocks] = useState({
    google: false,
    apple: false,
  });
  const user = useSelector(getFullAuthUser);
  const settings = useSelector(getSettings);
  const communities = useSelector(getAvailableCommunities);
  const settingsSaving = useSelector(getChangeProfileSettingsLoading);
  const isConnecting = useSelector(getLinkCalendarLoading);
  const isDisconnecting = useSelector(getUnlinkCalendarLoading);
  const dispatch = useDispatch();
  const isSmPlus = useMedia(breakpoints.smPlus);
  const isMdPlus = useMedia(breakpoints.mdPlus);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const { showInfoPopup } = useInfoPopup();

  const defaultValues = useMemo(
    () => ({
      [FIELDS.SYNC_EVENTS_GOOGLE]:
        settings.syncEventsGoogleFromCommunities || [],
    }),
    [user, communities],
  );

  const isConnected = user.googleCalendarRefreshToken;
  const isAppleConnected = user.settings.appleCalendarUrl?.length > 0;

  const getRowHeight = useCallback(
    (type) => {
      if (type === 'google') {
        if (!isConnected) {
          return (isDownSmPlus ? 100 : 50) + 'px';
        }
      } else {
        if (!isAppleConnected) {
          return (isDownSmPlus ? 100 : 50) + 'px';
        }
      }

      const isExpanded = expandedBlocks[type];
      const communitiesHeight = (communities.length || 8) * 60;
      const extraOffset = !isSmPlus ? 65 : 0;
      // const mainBlockHeight = !isMdPlus && isConnected ? 100 : 50;
      const mainBlockHeight = !isMdPlus ? 100 : 50;
      const stepsHeight = type === 'apple' ? 164 : 0;

      return (
        extraOffset +
        (isExpanded
          ? mainBlockHeight + 60 + communitiesHeight + stepsHeight
          : mainBlockHeight) +
        'px'
      );
    },
    [
      isDownSmPlus,
      isSmPlus,
      isMdPlus,
      expandedBlocks,
      communities,
      isConnected,
    ],
  );

  const checkBoxWarningTitle = useMemo(() => {
    const title = t('calendarConnection.checkboxWarning');
    if (isDownSmPlus) return title;
    return title.split(':').join('\n');
  }, [isDownSmPlus]);

  const {
    formState: { isDirty },
    reset,
    syncGoogleFieldProps,
    onSubmit,
    setValue,
  } = useChangeSyncEvents(user?.id, defaultValues);

  useEffect(() => {
    reset(defaultValues);
  }, [settings.syncEventsGoogleFromCommunities]);

  const onBlockToggle = (type) => {
    setExpandedBlocks({
      ...expandedBlocks,
      [type]: !expandedBlocks[type],
    });
  };

  const onChange = (name, value) => {
    let isSelecting = false;
    if (name === FIELDS.SYNC_EVENTS_GOOGLE) {
      const currentValue = syncGoogleFieldProps.value;

      // commented out code is not being used as of now.

      // if (currentValue.includes(value)) {
      //   showInfoPopup({
      //     title: t('calendarConnection.disconnectConfirm.title'),
      //     message: t('calendarConnection.disconnectConfirm.message'),
      //     type: 'warning',
      //     buttonText: t('calendarConnection.disconnectConfirm.okButton'),
      //     cancelButtonText: t('common.cancel'),
      //     size: 'lg',
      //     onButtonClick: () => {
      //       syncGoogleFieldProps.onChange(
      //         currentValue.includes(value)
      //           ? currentValue.filter((i) => i !== value)
      //           : currentValue.concat([value]),
      //       );
      //       const submitHandler = onSubmit();
      //       submitHandler();
      //     },
      //   });
      //   return;
      // }

      isSelecting = true;
      syncGoogleFieldProps.onChange(
        currentValue.includes(value)
          ? currentValue.filter((i) => i !== value)
          : currentValue.concat([value]),
      );
    }
    // const submitHanlder = onSubmit(isSelecting);
    // submitHanlder();
  };

  const setAllCommunitiesChecked = () => {
    if (communities.length <= 3) {
      setValue(
        FIELDS.SYNC_EVENTS_GOOGLE,
        communities.map((community) => community.id),
      );

      //commented out "initial default all chekced action" code as api overload issue.

      // dispatch(
      //   doChangeProfileSettings({
      //     userId: user.id,
      //     data: { [FIELDS.SYNC_EVENTS_GOOGLE]: communities.map((c) => c.id) },
      //   }),
      // );
    }
  };

  const onUnlinkCalendar = (type) => {
    if (type === 'google') {
      dispatch(
        doUnlinkGoogleCalendar({
          settings,
          onSuccess: () => {
            showInfoPopup({
              title: t('calendarConnection.disconnectSuccess'),
              type: 'success',
              noButton: true,
              loop: false,
              duration: 3000,
            });
            setExpandedBlocks((prevState) => ({ ...prevState, google: false }));
          },
          onFailure: () =>
            showInfoPopup({
              title: t('errors.text500'),
              type: 'error',
            }),
        }),
      );
    }
  };

  const onAuthSuccess = async (res) => {
    dispatch(
      doLinkGoogleCalendar({
        code: res.code,
        SetAllCommunities: setAllCommunitiesChecked,
        onSuccess: () => {
          showInfoPopup({
            title: t('calendarConnection.authSuccessTitleTwo'),
            message: t('calendarConnection.authSuccessMessageTwo'),
            type: 'success',
            size: 'lg',
          });
          setExpandedBlocks({ ...expandedBlocks, google: true });
        },
        onFailure: () =>
          showInfoPopup({
            title: t('errors.text500'),
            type: 'error',
          }),
      }),
    );
  };

  const onAuthFailure = () => {
    showInfoPopup({
      title: t('errors.text500'),
      type: 'error',
    });
  };

  const onLogin = useGoogleLogin({
    onSuccess: onAuthSuccess,
    onError: onAuthFailure,
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/calendar',
  });

  return (
    <ContentWrapper>
      <Layout>
        <Layout.Header>
          <Heading>{t('calendarConnection.pageTitle')}</Heading>
        </Layout.Header>
        <Layout.Inner>
          <Layout.Inner.Title>
            {t('calendarConnection.connectTitle')}
          </Layout.Inner.Title>
          <Layout.Inner.Description>
            {t('calendarConnection.connectDescription')}
          </Layout.Inner.Description>
          {isConnected && (
            <>
              <hr />
              <Layout.Inner.Description>
                {t('calendarConnection.syncTip')}
              </Layout.Inner.Description>
            </>
          )}
          <CalendarsCard>
            <CalendarsCard.Row
              expanded={expandedBlocks.google}
              style={{
                height: getRowHeight('google'),
              }}
            >
              <CalendarsCard.Row.Main>
                {!isConnected ? (
                  <BeforeConnectedWrapper>
                    <CalendarTitle>
                      <GoogleCalendarIcon />
                      <span>{t('calendarConnection.googleCalendar')}</span>
                    </CalendarTitle>
                    {isConnecting ? (
                      <LoaderWrapper width="160px">
                        <Loader type="button" size="28px" thickness={2} />
                      </LoaderWrapper>
                    ) : (
                      <ConnectButtonWrapper>
                        <InfoPopup
                          // title={t('calendarConnection.checkboxWarning')}
                          title={checkBoxWarningTitle}
                          type="warning"
                          buttonText={t('common.ok')}
                          size="md"
                          image={CheckboxRequiredImg}
                          onButtonClick={onLogin}
                        >
                          <GoogleAuthButton
                            disableContinueAsText={true}
                            preventDefaultAction={true}
                            style={{
                              borderRadius: '32px',
                              margin: '0',
                              width: '240px',
                            }}
                          />
                        </InfoPopup>
                      </ConnectButtonWrapper>
                    )}
                  </BeforeConnectedWrapper>
                ) : (
                  <AfterConnectedWrapper>
                    <CalendarTitle>
                      <GoogleCalendarIcon />
                      <span>{t('calendarConnection.googleCalendar')}</span>
                    </CalendarTitle>
                    <div>
                      <span onClick={() => onBlockToggle('google')}>
                        {`${t('calendarConnection.selectCommunities')} (${
                          syncGoogleFieldProps.value.length
                        }/${communities.length})`}
                      </span>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        <Lottie
                          options={{
                            animationData: successCheck,
                            loop: false,
                            autoplay: true,
                          }}
                          width="40px"
                          height="40px"
                        />
                        {t('calendarConnection.connected')}
                        {/* <ConnectionLabel success={true} displayCheck={false}>
                      </ConnectionLabel> */}
                      </div>
                    </div>
                    <InfoPopup
                      title={t('calendarConnection.disconnectConfirm.title')}
                      message={t(
                        'calendarConnection.disconnectConfirm.message',
                      )}
                      type="warning"
                      buttonText={t(
                        'calendarConnection.disconnectConfirm.okButton',
                      )}
                      cancelButtonText={t(
                        'calendarConnection.disconnectConfirm.cancelButton',
                      )}
                      onButtonClick={() => onUnlinkCalendar('google')}
                      size="lg"
                    >
                      {isDisconnecting ? (
                        <LoaderWrapper width="120px">
                          <Loader type="button" size="28px" thickness={2} />
                        </LoaderWrapper>
                      ) : (
                        <ConnectionLabel>
                          {t('calendarConnection.disconnect')}
                        </ConnectionLabel>
                      )}
                    </InfoPopup>
                  </AfterConnectedWrapper>
                )}
              </CalendarsCard.Row.Main>
              {isConnected && (
                <CommunitiesList
                  fieldName={FIELDS.SYNC_EVENTS_GOOGLE}
                  selectedCommunities={syncGoogleFieldProps.value}
                  handleChange={onChange}
                  settingsSaving={settingsSaving}
                />
              )}
            </CalendarsCard.Row>
          </CalendarsCard>
          <ApplceCalendar
            isConnecting={isConnecting}
            isDisconnecting={isDisconnecting}
            settingsSaving={settingsSaving}
            onCommunityBlockToogle={onBlockToggle}
            expanded={expandedBlocks.apple}
            setExpanded={setExpandedBlocks}
            getRowHeight={getRowHeight}
            isConnected={isAppleConnected}
          />
        </Layout.Inner>
        {isConnected && (
          <Layout.Footer>
            <StyledButtonBlock>
              {settingsSaving ? (
                <Loader type="button" size="28px" thickness={2} />
              ) : (
                <Button
                  width={isSmPlus ? '360px' : '320px'}
                  disabled={!isDirty}
                  onClick={() => onSubmit(true)()}
                >
                  {t('common.saveChanges')}
                </Button>
              )}
            </StyledButtonBlock>
          </Layout.Footer>
        )}
      </Layout>
    </ContentWrapper>
  );
};

export default UserProfileCalendars;
