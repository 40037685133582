import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { EDIT_PROFILE } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';

import { Label, Text, Container, FieldEditIcon } from './styled';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'store/selectors/auth';
import { useNavigate } from 'react-router-dom';
import { PROFILE_ROUTES, ROUTES } from 'configs';

interface ColumnInfoBlockProps {
  label: string | JSX.Element;
  text?: string;
  preventEdit?: boolean;
  addMaxWidth?: boolean;
  name?: string;
  style?: { [key: string]: string | number };
}

const ColumnInfoBlock: FC<ColumnInfoBlockProps> = ({
  label,
  text,
  preventEdit,
  name,
  addMaxWidth,
  style,
}) => {
  const dispatch = useDispatch();
  const profile = useSelector(getAuthUser);
  const navigate = useNavigate();
  const openEditSidebar = (data: string) => {
    if (!data) {
      navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.SETTINGS}#update-email`);
      return;
    }
    dispatch(
      openModal({
        data: data && {
          user: { profile: { ...profile, selectedFiled: data } },
        },
        type: EDIT_PROFILE,
        placement: 'right',
      }),
    );
  };

  return (
    <Container addMaxWidth={addMaxWidth}>
      <Label>{label}</Label>
      {text ? <Text style={style}>{text}</Text> : <Text> - </Text>}
      {!preventEdit ? (
        <FieldEditIcon title="Edit" onClick={() => openEditSidebar(name)} />
      ) : null}
    </Container>
  );
};

export default ColumnInfoBlock;
