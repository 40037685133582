import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Control, FieldError, useController } from 'react-hook-form';
import moment from 'moment';

import { getSettings } from 'store/selectors/auth';
import { ClockIcon } from 'static';

import { StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledPickerErrorWrapper,
  StyledPickerWrapper,
  StyledTimePicker,
  StyledTimeIcon,
} from './styled';

interface TimePickerProps {
  name: string;
  control: Control;
  label?: string;
  width?: string;
  error?: FieldError;
  setValue?: any;
  formWatcher?: any;
  watchFieldName?: string;
}

const TimePicker: FC<TimePickerProps> = ({
  name,
  control,
  label,
  width,
  error,
  setValue,
  formWatcher,
  watchFieldName,
}) => {
  const { t } = useTranslation();
  const timeFormat = useSelector(getSettings)?.timeFormat;

  const { field } = useController({
    name,
    control,
  });

  const onChange = (value) => {
    const currentValue = formWatcher[watchFieldName];

    // changing start_time
    if (watchFieldName) {
      if (
        watchFieldName === 'endTime' ||
        watchFieldName === 'defaultSetting.to'
      ) {
        if (value >= currentValue) {
          const newEndVal = new Date(value.getTime() + 1000 * 60 * 15);
          const isSameDay = newEndVal.getDate() === value.getDate();
          field.onChange(value);
          if (isSameDay) {
            setValue(watchFieldName, newEndVal);
          } else {
            const endOfDayTime = new Date(moment().endOf('day').toISOString());
            setValue(watchFieldName, endOfDayTime);
          }
          return;
        }
      } else if (
        watchFieldName === 'startTime' ||
        watchFieldName === 'defaultSetting.from'
      ) {
        if (value <= currentValue) {
          const newStartVal = new Date(value.getTime() - 1000 * 60 * 15);
          const isSameDay = newStartVal.getDate() === value.getDate();
          field.onChange(value);
          if (isSameDay) {
            setValue(watchFieldName, newStartVal);
          } else {
            const resetStartTime = new Date(
              moment().startOf('day').toISOString(),
            );
            setValue(watchFieldName, resetStartTime);
          }
          return;
        }
      }
      field.onChange(value);
      return;
    }
    field.onChange(value);
  };

  return (
    <StyledPickerErrorWrapper>
      <StyledPickerWrapper width={width}>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
        <StyledTimePicker
          id={name}
          selected={field.value}
          dateFormat={timeFormat === 12 ? 'h:mm aa' : 'HH:mm'}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeFormat={timeFormat === 12 ? 'h:mm aa' : 'HH:mm'}
          error={error}
          onChange={onChange}
        />
        <StyledTimeIcon src={ClockIcon} alt="" />
      </StyledPickerWrapper>
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledPickerErrorWrapper>
  );
};

export default TimePicker;
