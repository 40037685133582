import React, { FC } from 'react';

import { Document } from 'types';

import {
  Container,
  StyledFileIcon,
  FileName,
  FileItemContainer,
  StyledDeleteIcon,
  DeleteFileButton,
} from './styled';

interface FileItemProps {
  file: Document;
  onDelete: (e: Event, id: string) => void;
  uploadBlockVisible: boolean;
}

const FileItem: FC<FileItemProps> = ({
  file,
  onDelete,
  uploadBlockVisible,
}) => {
  const handleDelete = (e) => {
    onDelete(e, file.id);
  };

  return (
    <Container href={file.url} target="_blank">
      <FileItemContainer>
        <StyledFileIcon />
        <FileName>{file.name}</FileName>
      </FileItemContainer>
      {uploadBlockVisible && (
        <DeleteFileButton onClick={handleDelete}>
          <StyledDeleteIcon />
        </DeleteFileButton>
      )}
    </Container>
  );
};

export default FileItem;
