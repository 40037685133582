import styled from 'styled-components';

import { LeaveIcon } from 'static';
import { breakpoints } from 'theme';

export const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;

export const ListWrapper = styled.div`
  @media ${breakpoints.downLg} {
    overflow: scroll hidden;
  }
`;

const List = styled.table`
  width: 100%;
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-spacing: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  table-layout: fixed;

  @media ${breakpoints.downLg} {
    table-layout: auto;
  }
`;

List.Head = styled.thead`
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;

List.Head.Cell = styled.th`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 12px;
  font-weight: 600;
  padding: 7px 15px;

  &:nth-of-type(1) {
    padding-left: 25px;
    width: 40%;

    @media ${breakpoints.downLg} {
      min-width: 340px;
    }
  }

  &:nth-of-type(2) {
    width: 30%;

    @media ${breakpoints.downLg} {
      min-width: 255px;
    }
  }

  &:nth-of-type(3) {
    width: 10%;
    text-align: center;

    @media ${breakpoints.downLg} {
      min-width: 85px;
    }
  }

  &:nth-of-type(4) {
    width: 10%;
    text-align: center;

    @media ${breakpoints.downLg} {
      min-width: 85px;
    }
  }

  &:nth-of-type(5) {
    padding-left: 25px;
    width: 10%;

    @media ${breakpoints.downLg} {
      min-width: 85px;
    }
  }
`;

List.Row = styled.tr``;

List.Row.Cell = styled.td`
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  padding: 0 15px;

  &:first-child {
    padding-left: 25px;
  }

  &:nth-of-type(3) {
    text-align: center;
  }

  &:nth-of-type(4) {
    text-align: center;
  }

  &:last-child {
    padding-left: 25px;
  }
`;

List.Loader = styled.div`
  margin-top: 25px;
  position: relative;
`;

const Community = styled.div`
  display: flex;
  align-items: center;
`;

Community.Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

Community.Logo = styled.div`
  display: flex;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.porcelain};
  margin-right: 12px;
`;

Community.Logo.Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

Community.Name = styled.div``;

Community.Icon = styled(LeaveIcon)`
  width: 15px;
  height: 18px;
  cursor: pointer;
`;

Community.Leave = styled.a`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${Community.Icon} {
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }

    span {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  &:active {
    ${Community.Icon} {
      path {
        fill: ${({ theme }) => theme.color.brightTurquoise};
      }
    }

    span {
      color: ${({ theme }) => theme.color.brightTurquoise};
    }
  }
`;

export { List, Community };
