import styled from 'styled-components';

import { getZoomedValue } from 'utils';
import { breakpoints } from 'theme';

export const SeeMoreTextWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div > div:last-of-type {
    font-weight: 400;
  }
`;

export const FooterCellContent = styled.div`
  width: 100%;
  text-align: left;
  margin-left: ${({ zoom }) => getZoomedValue(9, zoom, 'px')};
`;

export const ChartsWrapper = styled.div``;

export const ChartsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  margin-top: 50px;
  margin-bottom: 100px;
  padding-left: 25px;
  padding-right: 25px;

  & > div {
    min-height: 400px;
    width: calc(30% - 30px);

    &:first-of-type {
      width: 70%;
    }
  }

  @media ${breakpoints.downMdPlus} {
    flex-wrap: wrap;

    & > div {
      width: 100%;

      &:first-of-type {
        width: 100%;
      }
    }
  }
`;

export const CellTitleWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ zoom }) => getZoomedValue(8, zoom, 'px')};

  &.penalty {
    g {
      fill: black;
    }
  }
`;

export const DescriptionWrapper = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ShowPopupTrigger = styled.div`
  width: 100%;
  text-align: left;

  & > b {
    padding-left: 9px;
  }
`;
