import styled from 'styled-components';
import { breakpoints } from 'theme';

export const FileInputContainer = styled.div`
  padding: 12px 20px 12px 23px;
  height: 94px;
  background-color: ${({ theme }) => theme.color.dew};
`;

export const Headline = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 12px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;

export const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.dew};
  overflow: auto;
  height: calc(
    100svh - ${({ uploadBlockVisible }) => (uploadBlockVisible ? 289 : 195)}px
  );
  @media ${breakpoints.downSmPlus} {
    height: calc(
      100svh - ${({ uploadBlockVisible }) => (uploadBlockVisible ? 254 : 162)}px
    );
  }
  @media (max-width: 480px) {
    height: calc(
      100svh - ${({ uploadBlockVisible }) => (uploadBlockVisible ? 238 : 143)}px
    );
  }
`;
