import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './Forgot';
import Verify from './Verify';
import ResetPassword from './ResetPassword';
import ChangeEmail from './ChangeEmail';

const AuthRouter = () => (
  <Routes>
    <Route path="*" element={<SignIn />} />
    <Route path="signup" element={<SignUp />} />
    <Route path="verify" element={<Verify />} />
    <Route path="change-email" element={<ChangeEmail />} />
    <Route path="resetpassword" element={<ResetPassword />} />
    <Route path="forgotpassword" element={<ForgotPassword />} />
  </Routes>
);

export default AuthRouter;
