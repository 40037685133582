import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton, StyledIcon } from './styled';

interface ResetButtonProps {
  onClick: () => void;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={onClick}>
      <StyledIcon />
      <span>{t('auth.reset')}</span>
    </StyledButton>
  );
};

export default ResetButton;
