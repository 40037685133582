import {
  useForm,
  useController,
  useWatch,
  UseFormMethods,
  ControllerRenderProps,
} from 'react-hook-form';

export enum FIELDS {
  EVENT_RESPONSE_ID = 'eventResponseId',
  COMMENT = 'comment',
  TASKS = 'tasks',
  GUESTS = 'guests',
  PENALTY = 'penalty',
  ATTENDED = 'attended',
}

export type ReplyFormData = {
  [FIELDS.EVENT_RESPONSE_ID]: string | null;
  [FIELDS.TASKS]: string[];
  [FIELDS.GUESTS]: number;
  [FIELDS.COMMENT]: string;
  [FIELDS.PENALTY]: boolean;
  [FIELDS.ATTENDED]: boolean;
  date?: string;
};

export const useReplyForm = (
  eventReply,
): UseFormMethods<ReplyFormData> & {
  replyFieldProps: ControllerRenderProps<ReplyFormData>;
  guestsFieldProps: ControllerRenderProps<ReplyFormData>;
  tasksFieldProps: ControllerRenderProps<ReplyFormData>;
  values: {
    date?: string;
    [FIELDS.EVENT_RESPONSE_ID]?: string | null;
    [FIELDS.TASKS]?: string[];
    [FIELDS.GUESTS]?: number;
    [FIELDS.COMMENT]?: string;
    [FIELDS.PENALTY]?: boolean;
    [FIELDS.ATTENDED]?: boolean;
  };
} => {
  const form = useForm<ReplyFormData>({
    defaultValues: eventReply.reply
      ? {
          [FIELDS.EVENT_RESPONSE_ID]: eventReply.reply.eventResponseId || null,
          [FIELDS.COMMENT]: eventReply.reply.comment || '',
          [FIELDS.GUESTS]: eventReply.reply.guests || 0,
          [FIELDS.TASKS]: eventReply.reply.tasks || [],
          [FIELDS.PENALTY]: eventReply.reply.penalty || false,
          [FIELDS.ATTENDED]: eventReply.reply.attended || false,
        }
      : {
          [FIELDS.EVENT_RESPONSE_ID]: null,
          [FIELDS.TASKS]: [],
          [FIELDS.GUESTS]: 0,
          [FIELDS.COMMENT]: '',
          [FIELDS.PENALTY]: false,
          [FIELDS.ATTENDED]: false,
        },
  });
  const { field: replyFieldProps } = useController<ReplyFormData>({
    name: FIELDS.EVENT_RESPONSE_ID,
    control: form.control,
  });
  const { field: guestsFieldProps } = useController<ReplyFormData>({
    name: FIELDS.GUESTS,
    control: form.control,
  });
  const { field: tasksFieldProps } = useController<ReplyFormData>({
    name: FIELDS.TASKS,
    control: form.control,
  });

  useController<ReplyFormData>({
    name: FIELDS.PENALTY,
    control: form.control,
  });
  useController<ReplyFormData>({
    name: FIELDS.ATTENDED,
    control: form.control,
  });

  const values = useWatch<ReplyFormData>({
    name: [
      FIELDS.EVENT_RESPONSE_ID,
      FIELDS.COMMENT,
      FIELDS.GUESTS,
      FIELDS.PENALTY,
      FIELDS.ATTENDED,
      FIELDS.TASKS,
    ],
    control: form.control,
  });

  return {
    ...form,
    replyFieldProps,
    guestsFieldProps,
    tasksFieldProps,
    values,
  };
};
