import React, { FC, useCallback, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switcher, MultiSelectInput, Card } from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { useCommunityNotificationsForm } from './form';
import { FIELDS, NotificationsPageConfigItem, NotificationType } from './types';
import {
  CommunityNotificationClass,
  CommunityNotificationValues,
  EventResponse,
} from 'types';

import {
  StyledAddReminder,
  StyledReminderRow,
  StyledRemoveIcon,
} from './styled';
import { StyledLabel } from 'components/Inputs/styled';

interface CardProps {
  data: CommunityNotificationValues;
  config: NotificationsPageConfigItem;
  eventResponses: EventResponse[];
  notificationsClass: CommunityNotificationClass;
  notificationsType: NotificationType;
  onSubmit: (
    data: CommunityNotificationValues,
    notificationsType: NotificationType,
    notificationsClass: CommunityNotificationClass,
  ) => void;
}

const NotificationsCard: FC<CardProps> = ({
  data,
  config,
  eventResponses,
  notificationsType,
  notificationsClass,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const combineRepliesFilter = (
    data: CommunityNotificationValues,
  ): CommunityNotificationValues => {
    return {
      ...data,
      replies: data.replies.concat(
        ['penalty', 'attended', 'awaiting'].filter((i) => data[i]),
      ),
    };
  };

  const {
    errors,
    handleSubmit,
    register,
    updatedFieldsProps,
    selectedRepliesProps,
    reminderFieldsProps,
    values,
    control,
    reset,
    formState: { isDirty },
  } = useCommunityNotificationsForm(
    data.replies ? combineRepliesFilter(data) : data,
  );

  useEffect(() => {
    reset(data.replies ? combineRepliesFilter(data) : data);
  }, [data]);

  const reminderModifiers = [
    { value: 'minutes', label: t('event.minutes') },
    { value: 'hours', label: t('event.hours') },
    { value: 'days', label: t('event.days') },
  ];
  const fieldsOptions = [
    { value: 'title', label: t('event.eventName') },
    { value: 'location', label: t('event.location') },
    { value: 'description', label: t('event.eventDetails') },
    { value: 'eventTypeId', label: t('event.eventType') },
    { value: 'from', label: t('common.startDateTime') },
    { value: 'to', label: t('common.endDateTime') },
    { value: 'tasks', label: t('common.tasks') },
  ];

  const submitForm = useCallback(
    (checkIfDirty = true) => {
      if (isDirty || !checkIfDirty) {
        handleSubmit((data) => {
          onSubmit(data, notificationsType, notificationsClass);
        })();
      }
    },
    [isDirty, data.replies],
  );

  return (
    <Card disabled={!values.enabled} fluid={true}>
      <form>
        <Switcher
          label={t('common.enable')}
          name={FIELDS.ENABLED}
          register={register}
          onChange={() => submitForm(false)}
        />
        {config.updatedFieldsFilter && (
          <MultiSelectInput
            options={fieldsOptions}
            name={FIELDS.FIELDS}
            register={register}
            defaultValue={fieldsOptions
              .filter((i) => updatedFieldsProps.value.includes(i.value))
              .map((i) => ({ value: i.value, label: i.label }))}
            label={t('communitySettings.notifyByFields')}
            labelAlignment={'left'}
            setValue={(_, v) => {
              updatedFieldsProps.onChange(v.map((i) => i.value));
            }}
            onBlur={submitForm}
          />
        )}
        {config.eventRepliesFilter && (
          <MultiSelectInput
            options={[
              { value: 'awaiting', label: t('event.awaiting') },
              { value: 'penalty', label: t('event.penalty') },
              { value: 'attended', label: t('event.nomination') },
            ].concat(
              eventResponses.map((r) => ({
                value: r.id,
                label: r.title,
              })),
            )}
            name={FIELDS.REPLIES}
            register={register}
            defaultValue={eventResponses
              .filter((r) => selectedRepliesProps.value.includes(r.id))
              .map((r) => ({ value: r.id, label: r.title }))
              .concat(
                [
                  { value: 'awaiting', label: t('event.awaiting') },
                  { value: 'penalty', label: t('event.penalty') },
                  { value: 'attended', label: t('event.nomination') },
                ].filter((i) => selectedRepliesProps.value.includes(i.value)),
              )}
            label={t('communitySettings.notifyByReplies')}
            labelAlignment={'left'}
            setValue={(_, v) => {
              selectedRepliesProps.onChange(v.map((i) => i.value));
            }}
            onBlur={submitForm}
          />
        )}
        {config.timeInterval && (
          <>
            <StyledLabel>{t('communitySettings.remindBefore')}</StyledLabel>
            {reminderFieldsProps.fields.map((f, i) => (
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
              /* @ts-ignore*/
              <AnimatePresence key={f.id}>
                <StyledReminderRow
                  initial={{
                    opacity: 0,
                    scale: 0.9,
                    marginTop: i === 0 ? 0 : 8,
                  }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                >
                  <TextInput
                    type="number"
                    min="1"
                    pattern="\d*"
                    width="100%"
                    name={`${FIELDS.REMINDERS}[${i}].value`}
                    register={register()}
                    defaultValue={f.value}
                    error={
                      errors[FIELDS.REMINDERS] &&
                      errors[FIELDS.REMINDERS][i]?.value
                    }
                    onBlur={submitForm}
                  />
                  <Controller
                    name={`${FIELDS.REMINDERS}[${i}].modifier`}
                    control={control}
                    render={({ onChange, value, name }) => (
                      <SelectInputControlled
                        options={reminderModifiers}
                        width="100%"
                        name={name}
                        value={reminderModifiers.find(
                          (unit) => unit.value === value,
                        )}
                        onChange={(v) => {
                          onChange(v.value);
                          submitForm(false);
                        }}
                        error={
                          errors[FIELDS.REMINDERS] &&
                          errors[FIELDS.REMINDERS][i]?.modifier
                        }
                      />
                    )}
                  />
                  {i === 1 && (
                    <StyledRemoveIcon
                      onClick={() => {
                        reminderFieldsProps.remove(1);
                        setTimeout(() => {
                          submitForm(false);
                        }, 500);
                      }}
                    />
                  )}
                </StyledReminderRow>
              </AnimatePresence>
            ))}
            {reminderFieldsProps.fields.length < 2 && (
              <StyledAddReminder
                onClick={() =>
                  reminderFieldsProps.append({
                    value: 1,
                    modifier: null,
                  })
                }
              >
                {t('communitySettings.addReminder')}
              </StyledAddReminder>
            )}
          </>
        )}
      </form>
    </Card>
  );
};

export default NotificationsCard;
