import React, { useState, useEffect, FC } from 'react';

import { Divider, Tab } from 'components';

import { StyledTabsBlock, StyledTabsList, StyledTabsContent } from './styled';

interface TabFormProps {
  width?: string;
  children: JSX.Element[];
  isSidebar?: boolean;
}

const TabForm: FC<TabFormProps> = ({ width, children, isSidebar }) => {
  const availableChildren = children.filter((c) => !!c);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [withSubmit, setWithSubmit] = useState<boolean>(
    availableChildren[0].props.withSubmit,
  );
  let submitForm;

  useEffect(() => {
    if (
      availableChildren.filter(
        (tab) => tab.props.label === availableChildren[activeTab].props.label,
      )[0].props.withSubmit
    ) {
      setWithSubmit(true);
      submitForm = availableChildren.filter(
        (tab) => tab.props.label === availableChildren[activeTab].props.label,
      )[0].props.submitForm;
    } else {
      setWithSubmit(false);
    }
  }, [activeTab, availableChildren]);

  const onClickTab = async (tab: number) => {
    if (withSubmit) {
      try {
        await submitForm();
        await setActiveTab(tab);
      } catch (e) {
        console.log(e);
      }
    } else {
      await setActiveTab(tab);
    }
  };

  return (
    <>
      <StyledTabsBlock isSidebar={isSidebar}>
        <StyledTabsList
          isSidebar={isSidebar}
          length={availableChildren.length}
          width={width}
        >
          {availableChildren.map((child) => {
            const { label, iconLabel, grow, index } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                iconLabel={iconLabel}
                grow={grow}
                index={index}
                onClickTab={onClickTab}
                isSidebar={isSidebar}
              />
            );
          })}
        </StyledTabsList>
      </StyledTabsBlock>
      <Divider customMargin="0px" />
      <StyledTabsContent activeInd={activeTab}>
        {availableChildren.map((child) => child.props.children)}
      </StyledTabsContent>
    </>
  );
};

export default TabForm;
