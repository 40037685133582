import React, { useState, MutableRefObject } from 'react';
import { string, func, bool, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash/fp';
import { InfoIconGreen } from 'static';
import { SearchIcon } from '../../../static';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledInput,
  InputWrapper,
  ShowPasswordButton,
  ShowPasswordIcon,
  HidePasswordIcon,
  StyledInfoIcon,
  LabelContainer,
} from './styled';
import { StyledIconsBlock, StyledLeftIcon } from '../SearchInput/styled';
import { Tooltip } from 'components';

const TextInput = ({
  name,
  type,
  min = null,
  pattern = null,
  placeholder,
  disabled,
  readonly = false,
  label,
  error,
  register,
  width,
  value,
  onChange,
  onBlur = () => {},
  defaultValue,
  withShowIcon,
  extraWrapperStyle,
  inputValueStyle,
  withSearchIcon = false,
  showInfoIcon = false,
  infoText = '',
}) => {
  const { t } = useTranslation();
  const [inputType, setType] = useState(type);

  const togglePasswordVisibility = () =>
    inputType === 'text' ? setType('password') : setType('text');

  return (
    <StyledWrapper>
      <LabelContainer>
        {label && (
          <StyledLabel htmlFor={name} error={error}>
            {label}
          </StyledLabel>
        )}
        {infoText && showInfoIcon && (
          <StyledLabel>
            <Tooltip
              text={infoText}
              isVisible
              tooltipStyle={{
                fontSize: 11,
                maxWidth: 240,
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                lineBreak: 'normal',
              }}
              position="top-center"
              opacity="1"
              noArrow={false}
            >
              <StyledInfoIcon>
                <InfoIconGreen width={13} height={13} />
              </StyledInfoIcon>
            </Tooltip>
          </StyledLabel>
        )}
      </LabelContainer>

      <InputWrapper style={extraWrapperStyle ? extraWrapperStyle : {}}>
        <StyledInput
          width={width}
          id={name}
          name={name}
          type={inputType}
          min={min}
          pattern={pattern}
          disabled={disabled}
          readOnly={readonly}
          placeholder={placeholder}
          error={error}
          ref={register}
          value={value}
          onChange={(e) => (onChange ? onChange(e) : null)}
          onBlur={onBlur}
          defaultValue={defaultValue}
          withShowIcon={withShowIcon}
          style={inputValueStyle}
        />
        {withSearchIcon && (
          <StyledIconsBlock>
            <StyledLeftIcon src={SearchIcon} alt="" />
          </StyledIconsBlock>
        )}
        {type === 'password' && withShowIcon ? (
          <ShowPasswordButton
            type="button"
            onClick={togglePasswordVisibility}
            tabIndex="-1"
          >
            {inputType === 'password' ? (
              <ShowPasswordIcon />
            ) : (
              <HidePasswordIcon />
            )}
          </ShowPasswordButton>
        ) : null}
      </InputWrapper>

      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

TextInput.propTypes = {
  name: string.isRequired,
  type: string,
  placeholder: string,
  register: func || MutableRefObject,
  disabled: bool,
  label: string,
  error: shape({
    message: string,
    type: string,
  }),
  width: string,
  value: string,
  onChange: func,
  defaultValue: string,
  withShowIcon: bool,
  extraWrapperStyle: React.CSSProperties,
  inputValueStyle: React.CSSProperties,
};

TextInput.defaultProps = {
  type: 'text',
  placeholder: '',
  register: noop,
  disabled: false,
  label: '',
  error: null,
  width: '',
  value: undefined,
  onChange: noop,
  defaultValue: null,
  withShowIcon: false,
};

export default TextInput;
