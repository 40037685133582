import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const LabelWrapper = styled.label`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  label:last-child {
    margin: 10px 0 2px 0px;
  }
`;

export const StyledLabel = styled.label`
  margin: 10px 0 2px 10px;
  font-size: ${({ fontSize = 12, zoom = 1 }) =>
    `${Math.floor(fontSize * zoom)}px`};
  color: ${({ error, theme }) =>
    error ? theme.color.bittersweet : theme.color.baliHai};
  display: flex;
  align-items: center;
  ${({ fontWeight }) =>
    css`
      font-weight: ${fontWeight};
    `}

  & > svg {
    margin-left: 5px;
    margin-top: 2px;
  }
`;

export const StyledErrorMessage = styled.p`
  text-align: left;
  margin: 5px 0 10px 15px;
  font-size: 10px;
  color: ${({ theme }) => theme.color.bittersweet};
`;

export const StyledArea = styled.textarea`
  width: ${({ width }) => width || '340px'};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  resize: none;
  padding-right: ${({ withEmojiIcon }) => (withEmojiIcon ? '47px' : '16px')};

  &:focus:enabled {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;
