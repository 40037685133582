import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import Grid from 'components/Grid/gridv2';
import { Loader } from '../../index';
import { useHeaderData, useGridData } from './hooks';
import {
  getMemberFiguresStatistics,
  getMemberFiguresStatisticsLoading,
  getMemberFiguresStatisticsTotalLoading,
  getMemberFiguresStatisticsTotal,
} from 'store/selectors/statistics';
import MemberFiguresCharts from './MemberFiguresCharts';

interface MemberFiguresProps {
  zoom: number;
  selectedMembers: string[];
  gridDataHandler: (data: any[]) => void;
}

const MemberFigures: FC<MemberFiguresProps> = ({
  zoom,
  selectedMembers,
  gridDataHandler,
}) => {
  const data = useSelector(getMemberFiguresStatistics);
  const total = useSelector(getMemberFiguresStatisticsTotal);
  const isLoading = useSelector(getMemberFiguresStatisticsLoading);
  const totalLoading = useSelector(getMemberFiguresStatisticsTotalLoading);

  const headerData = useHeaderData(zoom);

  const gridData = useGridData(data, zoom);

  gridDataHandler(gridData);

  return (
    <div
      style={{
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      {data && isLoading ? <Loader floating={true} /> : null}
      <div
        style={{
          width: '100%',
        }}
      >
        {!data && isLoading ? null : (
          <Grid
            data={gridData}
            header={headerData}
            zoom={zoom}
            statisticsTab={true}
            topHeadHeight={0}
            mainGridHeight={70 * zoom + 39 * gridData.length * zoom}
          />
        )}
      </div>
      <MemberFiguresCharts
        total={total}
        totalLoading={totalLoading}
        selectedMembers={selectedMembers}
      />
    </div>
  );
};

export default MemberFigures;
