import styled from 'styled-components';

const getBackground = (color, userRole, theme) => {
  if (color) {
    return color;
  }

  if (userRole === 'admin') {
    return theme.color.brightTurquoise;
  }

  if (userRole === 'owner') {
    return theme.color.bittersweet;
  }

  return theme.color.baliHai;
};

const getBorderRadius = (radius, shape) => {
  if (radius) {
    return radius;
  }

  if (shape === 'circle') {
    return '50%';
  }

  if (shape === 'square') {
    return radius || '3px';
  }

  return 'none';
};

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: default;
  ${({
    theme,
    shape,
    width,
    height,
    borderRadius,
    backgroundColor,
    border,
    boxShadow,
    isStretch,
    isBlinking,
    top,
    right = 4,
    bottom,
    left = 4,
    userRole,
    opacity,
    zoom = 1,
  }) => `
    width: ${width || isStretch ? 'auto' : 16 * zoom}px;
    min-width: ${width || isStretch ? 'auto' : 16 * zoom}px;
    padding: ${isStretch ? '2px 2px 2px 2px' : 'none'};
    height: ${height ? height : 16 * zoom}px;
    opacity: ${opacity || 'none'};
    box-shadow: ${boxShadow ? boxShadow : 'none'};
    background-color: ${getBackground(backgroundColor, userRole, theme)};
    margin: ${top * zoom || 0}px ${right * zoom || 0}px ${
    bottom * zoom || 0
  }px ${left * zoom || 0}px;
    border-radius: ${getBorderRadius(borderRadius, shape)};
    border: ${border ? border : 'transparent'};
    ${
      isBlinking
        ? `
     transition: right 0.3s;
     animation: blinker 2s linear infinite;
      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    `
        : ''
    };
  `};
`;

export const BadgeContent = styled.p`
  ${({ theme, color, fontSize, zoom = 1 }) => `
   color: ${color ? color : theme.color.white};
   font-size: ${fontSize ? fontSize : 10 * zoom}px; 
  `};
  line-height: 100%;
`;
