import styled from 'styled-components';

export const RowColumn = styled.div`
  width: ${({ width }) => width || '44px'};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  align-content: center;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
`;

export const Row = styled.div`
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  background-color: ${({ theme }) => theme.color.white};
  opacity: ${({ withOpacity }) => (withOpacity ? '50%' : 'unset')};
`;

export const TextPlaceholder = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledButtonsBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40px;
  height: 100%;
  margin-left: auto;
`;

export const EditBtnWrapper = styled.div``;
