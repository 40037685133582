import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useState } from 'react';

import { useForgotPasswordForm } from './form';
import { useInfoPopup } from 'hooks';
import { getAmplifyAuthErrorMessage } from '../utils';

export const useForgotPassword = () => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, ...form } = useForgotPasswordForm();
  const [isLoading, setIsLoading] = useState(false);
  const { showInfoPopup } = useInfoPopup();

  const onFailure = (error) => {
    const title = getAmplifyAuthErrorMessage(t, error);

    showInfoPopup({
      type: 'error',
      title,
    });
  };

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(email.toLowerCase());

      showInfoPopup({
        type: 'success',
        title: t('auth.codeSended'),
      });
    } catch (error) {
      onFailure(error);
    } finally {
      setIsLoading(false);
    }
  });

  return {
    onSubmit,
    setValue,
    ...form,
    isLoading,
  };
};
