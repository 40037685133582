import React, { FC } from 'react';

import { StyledString, StyledButton, StyledIcon, StyledLabel } from './styled';

interface DeleteButtonStringProps {
  label: string;
  onClick: () => void;
}

const DeleteButtonString: FC<DeleteButtonStringProps> = ({
  label,
  onClick,
}) => (
  <StyledString>
    <StyledButton onClick={onClick}>
      <StyledIcon />
      <StyledLabel>{label}</StyledLabel>
    </StyledButton>
  </StyledString>
);

export default DeleteButtonString;
