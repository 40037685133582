import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useMedia } from 'react-use';

import { Button, Card, CloseButton, Divider } from 'components';
import PopupWrapper from '../Popups/Wrapper';
import useOutsideClick from 'hooks/outsideClick';
import { breakpoints } from 'theme';
import successCheck from 'static/animations/success-check.json';
import warningSign from 'static/animations/warning-sign.json';
import errorCross from 'static/animations/error-cross.json';
import info from 'static/animations/info.json';
import email from 'static/animations/email-sent.json';

import {
  Title,
  Message,
  Image,
  ExtraInfoWrapper,
  ExtraInfoText,
} from './styled';
import { CopyButton } from 'components/Inputs/InvitationLinkInput/styled';

export interface InfoPopupProps {
  children?: JSX.Element;
  isOpen?: boolean;
  title: string;
  message?: string;
  richMessage?: string;
  buttonText?: string;
  type?: 'success' | 'warning' | 'info' | 'error';
  size?: 'sm' | 'md' | 'lg';
  image?: string;
  loop?: boolean;
  noButton?: boolean;
  duration?: number;
  cancelButtonText?: string;
  extraInfoText?: string;
  extraInfoType?: 'message' | 'link' | 'warning';
  messageAlign?: 'left' | 'center' | 'right' | 'justify' | 'start' | 'end';
  multiStep?: { image: string; message: string }[];
  onButtonClick?: () => void;
  onClose?: () => void;
}

const InfoPopup: FC<InfoPopupProps> = ({
  children,
  isOpen,
  title,
  message,
  richMessage,
  buttonText,
  type = 'success',
  size = 'md',
  image,
  loop,
  noButton,
  duration,
  cancelButtonText,
  extraInfoText,
  extraInfoType,
  messageAlign = 'center',
  onButtonClick = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef();
  const [open, setOpen] = useState(isOpen || false);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const [multiStepIndex, setMultiStepIndex] = useState<number>(0);

  const onChangeIndex = (idx: number) => {
    setMultiStepIndex(idx);
  };

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  const closePopup = () => {
    setOpen(false);
    onClose();
  };

  const handleButtonClick = () => {
    onButtonClick();
    closePopup();
  };

  useOutsideClick(wrapperRef, closePopup);

  const animationData = useMemo(() => {
    if (type === 'success') {
      return successCheck;
    } else if (type === 'warning') {
      return warningSign;
    } else if (type === 'info') {
      return email;
    } else {
      return errorCross;
    }
  }, [type]);

  const animationOptions = {
    animationData,
    loop,
    autoplay: true,
  };

  useEffect(() => {
    let timer;
    if (duration) {
      timer = setTimeout(() => setOpen(false), duration);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  return (
    <>
      {children && <div onClick={toggleOpen}>{children}</div>}
      <PopupWrapper isOpen={open}>
        <Card cardRef={wrapperRef} size={size} greenBorder={true}>
          <CloseButton onClick={closePopup} />
          <Lottie
            options={animationOptions}
            width={size === 'lg' && !isDownSmPlus ? 150 : 80}
            height={size === 'lg' && !isDownSmPlus ? 150 : 80}
          />
          <Title marginTop="8px" marginBot="8px">
            {title}
          </Title>
          {image && <Image src={image} alt="img" />}
          {message && (
            <Message noButton={noButton} textAlign={messageAlign}>
              {message}
            </Message>
          )}
          {richMessage && (
            <Message noButton={noButton} textAlign={messageAlign}>
              <div dangerouslySetInnerHTML={{ __html: richMessage }}></div>
            </Message>
          )}
          {extraInfoText && (
            <ExtraInfoWrapper>
              <ExtraInfoText>{extraInfoText}</ExtraInfoText>
              {extraInfoType === 'link' && <CopyButton>Copy Link</CopyButton>}
            </ExtraInfoWrapper>
          )}
          {!noButton && (
            <Divider
              customMargin={size === 'lg' ? '0px 0px 32px' : '0px 0px 16px 0px'}
            />
          )}
          {!noButton && (
            <Button
              width={isDownSmPlus ? '90%' : '100%'}
              onClick={handleButtonClick}
              {...{ rounded: true }}
            >
              {buttonText || t('common.ok')}
            </Button>
          )}
          {cancelButtonText && (
            <Button
              width={isDownSmPlus ? '90%' : '100%'}
              onClick={closePopup}
              variant="secondary"
              {...{ rounded: true, marginTop: '8px' }}
            >
              {cancelButtonText || t('common.cancel')}
            </Button>
          )}
        </Card>
      </PopupWrapper>
    </>
  );
};

export default InfoPopup;
