import React, { FC } from 'react';

import { LogoUploadIcon } from 'static';

import {
  ImageWrapper,
  StyledImage,
  StyledPlaceholder,
  StyledLogoUpload,
  StyledTextButton,
  StyledTipText,
} from './styled';

interface FileBoxProps {
  src: string;
  placeholder?: string;
  errorMessage?: string;
  text: string;
  tipText?: string;
  image: string;
}

const FileBox: FC<FileBoxProps> = ({
  src,
  placeholder = LogoUploadIcon,
  errorMessage,
  text,
  tipText,
  image,
}) => (
  <ImageWrapper isError={errorMessage}>
    {image ? (
      <StyledImage image={image} src={src} alt="ImageBox" />
    ) : (
      <StyledPlaceholder>
        <StyledLogoUpload src={placeholder} />
        <StyledTextButton>{text}</StyledTextButton>
        {tipText && <StyledTipText>{tipText}</StyledTipText>}
      </StyledPlaceholder>
    )}
  </ImageWrapper>
);

export default FileBox;
