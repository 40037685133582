const size = {
  sm: '320px',
  smPlus: '600px',
  md: '960px',
  mdPlus: '1240px',
  lg: '1440px',
  lgPlus: '1920px',
};

const height = {
  md: '800px',
};

export const breakpoints = {
  sm: `(min-width: ${size.sm})`,
  smPlus: `(min-width: ${size.smPlus})`,
  md: `(min-width: ${size.md})`,
  mdPlus: `(min-width: ${size.mdPlus})`,
  lg: `(min-width: ${size.lg})`,
  lgPlus: `(min-width: ${size.lgPlus})`,
  downSm: `(max-width: ${size.sm})`,
  downSmPlus: `(max-width: ${size.smPlus})`,
  downMd: `(max-width: ${size.md})`,
  downMdPlus: `(max-width: ${size.mdPlus})`,
  downLg: `(max-width: ${size.lg})`,
  downLgPlus: `(max-width: ${size.lgPlus})`,
};

export const verticalBreakpoints = {
  md: `(min-height: ${height.md})`,
  downMd: `(max-height: ${height.md})`,
};

export const theme = {
  color: {
    black: '#000000',
    white: '#FFFFFF',
    // brightTurquoise: '#10CCC6',
    brightTurquoise: '#33ABA7',
    skobeloffGreen: '#0A7575',
    blackSqueeze: '#EBF2F7',
    baliHai: '#8298AB',
    pickledBluewood: '#304659',
    koromiko: '#FFAF63',
    bittersweet: '#FF6C5B',
    aquaSqueeze: '#F6FBFA',
    casper: '#A5BFCE',
    botticelli: '#C9D9E2',
    shark: '#202426',
    porcelain: '#E5E9E9',
    dew: '#FBFFFE',
    regentGray: '#859599',
    darkGrey: 'rgba(28,42,53,0.8)',
    lightGrey: '#CFCFCF',
    BlueBayoux: '#4F667A',
    trolleyGray: '#808080',
    blackCoffee: '#333029',
    closeToTimberBrown: '#5e5a50',
    lightGradient: 'linear-gradient(0deg, #01C8A8 0%, #19E1E1 100%)',
    darkGradient: 'linear-gradient(43.37deg, #37393C 0%, #19E1E1 100%)',
    dimmer: '#0000003b',
  },
};
