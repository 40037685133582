import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';

import newIconAnimation from 'static/animations/new.json';

import { CloseButton, Button } from 'components';

import ImportDe from 'static/images/deImport.png';
import ImportEn from 'static/images/enImport.png';
import { UpArrow } from 'static';
import { Image } from 'components/InfoPopup/styled';
import {
  StyledBody,
  StyledHeader,
  StyledPopup,
  StyledFooter,
  StyledAnchorTag,
} from './styled';
import { ROUTES } from 'configs';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useSelector } from 'react-redux';
import { Wrapper } from '../styled';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const ImportFeaturePopup: React.FC<Props> = ({ onClose, isOpen }) => {
  const community = useSelector(getCurrentCommunity);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isGerman = i18n.language === 'de';
  const pushSupportPageUrl =
    i18n.language === 'de'
      ? 'https://gumb.app/support/termine-hochladen-importieren/'
      : 'https://gumb.app/en/support/upload-import-events/';

  const imageUrl = i18n.language === 'de' ? ImportDe : ImportEn;

  const handleClick = () => {
    navigate(`${ROUTES.COMMUNITY}/${community.id}/overall/#import`, {
      replace: true,
    });
    onClose();
  };
  const buttonStyle = {
    textTransform: 'none',
    fontSize: '16px',
  };

  const animationOptions = {
    animationData: newIconAnimation,
    loop: true,
    autoplay: true,
  };

  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StyledPopup>
                <CloseButton onClick={onClose} />
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  <StyledHeader>
                    <div style={{ position: 'relative', bottom: '0px' }}>
                      <Image
                        src={imageUrl}
                        style={{ border: 'none', marginBottom: '0px' }}
                        width="70%"
                      />
                    </div>
                  </StyledHeader>
                  <StyledBody>
                    <h3>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          justifyContent: 'center',
                        }}
                      >
                        <Lottie
                          options={animationOptions}
                          width="48px"
                          height="48px"
                          style={{ margin: '0px' }}
                        />
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('featurePopups.importEvent.title'),
                        }}
                      ></span>
                      <UpArrow width="22" height="22" />
                    </h3>
                    <p
                      style={{ textAlign: 'center', lineHeight: 1.3 }}
                      dangerouslySetInnerHTML={{
                        __html: t('featurePopups.importEvent.subText'),
                      }}
                    ></p>
                    <span style={{ margin: '16px 0px' }}>
                      {isGerman && (
                        <StyledAnchorTag
                          target="_blank"
                          marginRight="1px"
                          display="inline"
                          href={pushSupportPageUrl}
                        >
                          Hier
                        </StyledAnchorTag>
                      )}
                      <p style={{ display: 'inline' }}>
                        {''}
                        {t('featurePopups.importEvent.secondText')}{' '}
                      </p>
                      {!isGerman && (
                        <StyledAnchorTag
                          target="_blank"
                          marginRight="1px"
                          display="inline"
                          href={pushSupportPageUrl}
                        >
                          here.
                        </StyledAnchorTag>
                      )}
                    </span>
                  </StyledBody>
                  <StyledFooter>
                    <Button
                      variant="secondary"
                      onClick={onClose}
                      buttonStyles={buttonStyle}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.importEvent.cancelButton')}
                    </Button>
                    <Button
                      onClick={handleClick}
                      buttonStyles={{ ...buttonStyle, color: 'black' }}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.importEvent.okButton')}
                    </Button>
                  </StyledFooter>
                </PerfectScrollbar>
              </StyledPopup>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default ImportFeaturePopup;
