import React, { memo } from 'react';

import { StatisticsData } from 'types';

import {
  StyledHighlight,
  StyledCellContainer,
  StyledCellContent,
} from './styled';

interface StatisticsCellProps {
  style: { [key: string]: string | number };
  zoom: number;
  isCurrentUser: boolean;
  cell: StatisticsData;
  divide?: 'left' | 'right';
}

const StatisticsCell = memo<StatisticsCellProps>(
  ({ style, cell = {}, zoom, isCurrentUser, divide }) => {
    const { color, text, align, width, component, clickable } = cell || {};

    return (
      <div style={style}>
        {!!isCurrentUser && <StyledHighlight zoom={zoom} />}
        <StyledCellContainer
          isStatisticsTab={true}
          zoom={zoom}
          divide={divide}
          clickable={clickable}
          isCurrentUser={isCurrentUser}
        >
          <StyledCellContent
            color={color}
            zoom={zoom}
            align={align}
            width={width}
          >
            {(!!text || text === 0) && (
              <span className="gumb-grid-cell-text gumb-grid-cell-text-statistics">
                {text}
              </span>
            )}
            {!!component && component}
          </StyledCellContent>
        </StyledCellContainer>
      </div>
    );
  },
);

StatisticsCell.displayName = 'StatisticsCell';

export default StatisticsCell;
