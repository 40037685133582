import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50vw;
`;

export const StyledTitle = styled.p`
  font-size: 68px;
  line-height: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const StyledText = styled.p`
  margin-top: 30px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: ${({ theme }) => theme.color.shark};
`;

export const StyledButtonWrapper = styled.div`
  margin-top: 32px;
`;

export const StyledImageWrapper = styled.div`
  height: 100svh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const StyledLogo = styled.img`
  object-fit: cover;
  height: 100%;
  min-height: 700px;
`;
