import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import {
  getCurrentCommunity,
  getCurrentCommunityError,
} from 'store/selectors/currentCommunity';
import {
  getCommunities,
  getCommunitiesLoaded,
} from 'store/selectors/communities';
import {
  doClearCurrentCommunityError,
  doGetCommunityLanding,
} from 'store/actionCreators/currentCommunity';
import { NOT_FOUND } from 'constants/errors';
import { CommunityLandingPage } from 'pages';
import { Loader } from 'components';

const CommunityLandingRouterWrapper = () => {
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const communities = useSelector(getCommunities);
  const areCommunitiesLoaded = useSelector(getCommunitiesLoaded);
  const error = useSelector(getCurrentCommunityError);
  const [isInCommunityChecked, setIsInCommunityChecked] = useState(false);
  const { communityId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (areCommunitiesLoaded) {
      const isInCommunity = communities.some((com) => com.id === communityId);

      if (isInCommunity) {
        return navigate(`/community/${communityId}`);
      }
      setIsInCommunityChecked(true);
      dispatch(doGetCommunityLanding(communityId));
    }
  }, [communityId, communities, areCommunitiesLoaded]);

  if (error) {
    if (error?.error === NOT_FOUND) {
      dispatch(doClearCurrentCommunityError());
      navigate('/404');
    }
  }

  return community && community.id && isInCommunityChecked ? (
    <CommunityLandingPage />
  ) : (
    <Loader type="main" />
  );
};

export default CommunityLandingRouterWrapper;
