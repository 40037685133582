import { useCallback, useMemo } from 'react';

import { EventToRender } from 'types';

const useGridDimensions = ({
  statisticsTab,
  zoom,
  header,
  stickyCellWidth,
  filteredData,
}: {
  statisticsTab: boolean;
  zoom: number;
  header: EventToRender[];
  stickyCellWidth: number;
  filteredData: any[];
}): {
  getColumnWidth: (value: any) => number;
  getRowHeight: (value: any) => number;
  gridWidth: number;
} => {
  const getColumnWidth = useCallback(
    ({ index }) => {
      return index
        ? Math.floor(header[index - 1]?.width || 157) * zoom
        : stickyCellWidth * zoom;
    },
    [zoom, stickyCellWidth, header],
  );

  const getRowHeight = useCallback(
    ({ index }) => {
      if (index === 0) {
        const baseHeight = (statisticsTab ? 70 : 150) * zoom;

        if (filteredData[0]?.type === 'event') {
          return filteredData[0].values?.length > 0 ? baseHeight : 0;
        }

        return baseHeight;
      }

      return 39 * zoom;
    },
    [zoom, statisticsTab, filteredData],
  );

  const gridWidth = useMemo(() => {
    if (header?.some((i) => i.width)) {
      const width = header
        .map((i) => i.width || 157)
        .reduce((a, b) => a + b, 0);

      return Math.floor(width * zoom) + stickyCellWidth * zoom;
    }

    return header.length * Math.floor(157 * zoom) + stickyCellWidth * zoom;
  }, [header]);

  return {
    getColumnWidth,
    getRowHeight,
    gridWidth,
  };
};

export default useGridDimensions;
