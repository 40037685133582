import { createRoutine } from 'redux-saga-routines';

import { EditCommunityEventTypeFormData } from 'sidebars/EditCommunityEventType/form';

export const doGetCommunityDefaultEventSetting = createRoutine(
  'GET_COMMUNITY_DEFAULT_EVENT_SETTINGS',
  {
    trigger: (payload: string) => payload,
  },
);

export const doCreateCommunityDefaultEventSetting = createRoutine(
  'CREATE_COMMUNITY_DEFAULT_EVENT_SETTING',
  {
    trigger: (payload: {
      data: EditCommunityEventTypeFormData;
      communityId: string;
      onSuccess: () => void;
    }) => payload,
  },
);

export const doEditCommunityDefaultEventSetting = createRoutine(
  'EDIT_COMMUNITY_DEFAULT_EVENT_SETTING',
  {
    trigger: (payload: {
      data: EditCommunityEventTypeFormData;
      communityId: string;
      id: string;
      onSuccess: () => void;
    }) => payload,
  },
);

export const doDeleteCommunityDefaultEventSetting = createRoutine(
  'DELETE_COMMUNITY_DEFAULT_EVENT_SETTING',
  {
    trigger: (payload: {
      communityId: string;
      id: string;
      onSuccess: () => void;
    }) => payload,
  },
);
