import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { getLinkParams } from 'utils/auth';
import { useResetPasswordForm } from './form';
import { getAmplifyAuthErrorMessage } from '../utils';
import { useInfoPopup } from 'hooks';

export const useResetPassword = (location) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit, setValue, ...form } = useResetPasswordForm();
  const { showInfoPopup } = useInfoPopup();

  const { code, userName } = getLinkParams(location.search);

  useEffect(() => {
    if (!code || !userName) {
      showInfoPopup({
        type: 'error',
        title: t('errors.invalidRequest'),
      });
      navigate('/');
    }
  }, []);

  const onFailure = (error) => {
    const title = getAmplifyAuthErrorMessage(t, error);

    showInfoPopup({
      type: 'error',
      title: title === t('auth.userNotFound') ? t('auth.codeExpired') : title,
    });

    navigate('/forgotpassword');
  };

  const onSubmit = handleSubmit(({ password }) => {
    Auth.forgotPasswordSubmit(userName, code, password)
      .then(() => {
        showInfoPopup({
          type: 'success',
          title: t('errors.passwordChanged'),
        });
        navigate('/');
      })
      .catch((err) => {
        onFailure(err);
      });
  });

  return {
    onSubmit,
    setValue,
    ...form,
  };
};
