import React from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import Content from './Content';
import { getAuthUser, getFullAuthUser, getMyId } from 'store/selectors/auth';
import styled from 'styled-components';
import { Loader } from 'components';
import { openModal } from 'store/actionCreators/modal';
import { useDispatch } from 'react-redux';
import { doLogout } from 'store/actionCreators/auth';
import { EDIT_PROFILE } from 'constants/sidebars';
import { breakpoints } from 'theme';
import { CommunityRoles } from 'types';
import { getCurrentUserInCommunity } from 'store/selectors/currentCommunity';

export const ContentWrapper = styled.div`
  width: calc(100% - 360px);
  height: 100svh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.dew};
  margin-left: 360px;
  transition: all 0.3s;
  background-color: #fbfffe;
  position: relative;

  @media ${breakpoints.downMd} {
    width: 100%;
    margin-left: 0;
  }
`;
export const Layout = styled.div`
  height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.dew};
`;
const UserProfilePage = () => {
  const user = useSelector(getFullAuthUser);
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserInCommunity);
  const userRole = currentUser?.communityPermission.role;
  const profile = useSelector(getAuthUser);
  const authId = useSelector(getMyId);
  const itsMe = authId === profile.userId;
  if (!user) {
    return <Loader />;
  }
  const onLogOut = () => {
    dispatch(doLogout());
  };
  const openEditSidebar = (data?: string) => {
    if (
      [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ||
      itsMe
    )
      dispatch(
        openModal({
          data: data && {
            user: { profile: { ...profile, selectedFiled: data } },
          },
          type: EDIT_PROFILE,
          placement: 'right',
        }),
      );
  };

  return (
    <ContentWrapper>
      <Layout>
        <Header onLogOut={onLogOut} openEditSidebar={openEditSidebar} />
        <Content openEditSidebar={openEditSidebar} userRole={userRole} />
      </Layout>
    </ContentWrapper>
  );
};

export default UserProfilePage;
