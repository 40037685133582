import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useController, Resolver } from 'react-hook-form';

export enum FIELDS {
  SYNC_EVENTS_GOOGLE = 'syncEventsGoogleFromCommunities',
  SYNC_EVENTS_APPLE = 'syncEventsAppleFromCommunities',
}

export type FormValues = {
  [FIELDS.SYNC_EVENTS_GOOGLE]: string[];
};

export type AppleFormValue = {
  [FIELDS.SYNC_EVENTS_APPLE]: string[];
};

export const useSyncEventsForm = (defaultValues) => {
  const resolver: Resolver<FormValues> = yupResolver(
    yup.object().shape({
      [FIELDS.SYNC_EVENTS_GOOGLE]: yup.array().of(yup.string().required()),
    }),
  );
  const formData = useForm<FormValues>({
    resolver,
    defaultValues,
  });
  const { field: syncGoogleFieldProps } = useController({
    name: FIELDS.SYNC_EVENTS_GOOGLE,
    control: formData.control,
  });

  return {
    ...formData,
    syncGoogleFieldProps,
  };
};

export const useAppleSyncEventsForm = (defaultValues) => {
  const resolver: Resolver<AppleFormValue> = yupResolver(
    yup.object().shape({
      [FIELDS.SYNC_EVENTS_APPLE]: yup.array().of(yup.string().required()),
    }),
  );
  const formData = useForm<AppleFormValue>({ resolver, defaultValues });
  const { field: syncAppleFieldProps } = useController({
    name: FIELDS.SYNC_EVENTS_APPLE,
    control: formData.control,
  });

  return { ...formData, syncAppleFieldProps };
};
