import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { PROFILE_ROUTES, ROUTES } from 'configs';
import {
  ReturnLinkButton,
  ButtonGroup,
  SubscriptionCard,
  MultiSubscriptionCard,
  Loader,
  AdBlockerPopup,
} from 'components';
import { getAllSubscriptions } from 'store/actionCreators/subscriptions';
import {
  hasAdblocker,
  selectAllSubscriptions,
  selectCurrentUserSubscription,
  selectIsSubscriptionLoading,
} from 'store/selectors/subscriptions';
import { getAuthUser } from 'store/selectors/auth';
import { breakpoints } from 'theme';

import {
  Header,
  HeaderContainer,
  ButtonBack,
  Content,
  ContentBody,
  SubscriptionsGroup,
} from './styled';

const SubscriptionsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDownMd = useMedia(breakpoints.downMd);
  const subscriptions = useSelector(selectAllSubscriptions);
  const currentUserSubscription = useSelector(selectCurrentUserSubscription);
  const isLoading = useSelector(selectIsSubscriptionLoading);
  const profile = useSelector(getAuthUser);
  const [currentPricing, setCurrentPricing] = useState<'left' | 'right'>(
    'left',
  );
  const [usingBlocker, setUsingBlocker] = useState<boolean>(false);
  const isUsingAdblocker = useSelector(hasAdblocker);

  useEffect(() => {
    dispatch(getAllSubscriptions());
  }, []);

  useEffect(() => {
    setUsingBlocker(isUsingAdblocker);
  }, [isUsingAdblocker]);

  const classicSubscriptions =
    subscriptions?.filter(({ name }) => name.includes('Classic')) || [];
  const customSubscriptions =
    subscriptions?.filter(({ name }) => name.includes('Custom')) || [];
  const pricing = currentPricing === 'left' ? 'yearly' : 'monthly';

  useEffect(() => {
    if (currentUserSubscription?.frequency) {
      setCurrentPricing(
        currentUserSubscription?.frequency === 'MONTH' ? 'right' : 'left',
      );
    }
  }, [currentUserSubscription]);

  const handleRadioClick = ({ target }) => setCurrentPricing(target.value);

  const freePlan = {
    id: 'TRIAL',
    name: t('subscriptions.freeTitle'),
    monthlyValue: 0,
    chatEnabled: true,
    yearlyValue: 0,
    options: [
      {
        ability: true,
        text: t('subscriptions.freeText1'),
      },
      {
        ability: true,
        text: t('subscriptions.freeText2'),
      },
      {
        ability: true,
        text: t('subscriptions.freeText3'),
      },
      {
        ability: true,
        text: t('subscriptions.freeText4'),
      },
      {
        ability: true,
        text: t('subscriptions.freeText5'),
      },
    ],
  };

  const maxContainerWidth = useMemo(() => {
    if (isDownMd) {
      return 'calc(100vw - 40px)';
    }

    return 'calc(100vw - 360px)';
  }, [isDownMd]);

  return (
    <Content>
      {!isLoading ? (
        <>
          <HeaderContainer>
            <Header>{t('subscriptions.title')}</Header>
          </HeaderContainer>
          <AdBlockerPopup
            isOpen={usingBlocker}
            onClose={() => {
              setUsingBlocker(false);
            }}
          />
          <ButtonBack>
            <ReturnLinkButton
              to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.SUBSCRIPTION}`}
              text={t('communitySettings.backToSubscriptionsDetails')}
            />
          </ButtonBack>
          <ContentBody>
            <ButtonGroup
              name="subscriptions"
              leftText={t('subscriptions.yearly')}
              leftValue="left"
              rightText={t('subscriptions.monthly')}
              rightValue="right"
              onClick={handleRadioClick}
              checkedButton={currentPricing}
              promo
            />
            <SubscriptionsGroup
              style={{
                maxWidth: maxContainerWidth,
              }}
            >
              <SubscriptionCard
                currentUserSubscription={currentUserSubscription}
                pricing="yearly"
                key={uuid()}
                currency={
                  classicSubscriptions && classicSubscriptions[0]?.currency
                }
                isTrial={true}
                profile={profile}
                {...freePlan}
              />
              {classicSubscriptions.map((item) => (
                <SubscriptionCard
                  currentUserSubscription={currentUserSubscription}
                  pricing={pricing}
                  key={uuid()}
                  profile={profile}
                  {...item}
                />
              ))}
              {!isEmpty(customSubscriptions) && (
                <MultiSubscriptionCard
                  subscriptions={customSubscriptions}
                  currentUserSubscription={currentUserSubscription}
                  pricing={pricing}
                />
              )}
            </SubscriptionsGroup>
          </ContentBody>
        </>
      ) : (
        <Loader />
      )}
    </Content>
  );
};

export default SubscriptionsPage;
