import React, { FC } from 'react';

import {
  StyledCommunityImageBlock,
  StyledCommunityImageLetter,
} from './styled';

interface DefaultCommunityImageProps {
  title: string;
  zoom?: number;
}

const DefaultCommunityImage: FC<DefaultCommunityImageProps> = ({
  title,
  zoom = 1,
}) => (
  <StyledCommunityImageBlock zoom={zoom}>
    <StyledCommunityImageLetter zoom={zoom}>
      {title.charAt(0).toUpperCase()}
    </StyledCommunityImageLetter>
  </StyledCommunityImageBlock>
);

export default DefaultCommunityImage;
