import React, { FC } from 'react';

import { taskIcons } from 'configs';

interface IconProps {
  icon: string;
}

const Icon: FC<IconProps> = ({ icon }) => {
  const IconToRender = taskIcons[icon];

  return <IconToRender />;
};

export default Icon;
