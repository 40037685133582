import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentIcon } from 'static';
import { PendingSettingsInvite } from '../../../components';
import Lottie from 'react-lottie';
import {
  StyledTab,
  StyledItemPlaceholder,
  NewIcon,
  StyledRightIcon,
} from './styled';
import { animationOptions } from 'components/CommunityMenu';

interface TabProps {
  label: string;
  iconLabel?: string;
  grow?: boolean;
  activeTab: number;
  invitesCount?: number;
  tabId?: string;
  index: number;
  onClickTab: (index: number) => void;
  isSidebar?: boolean;
}

const Tab: FC<TabProps> = ({
  label,
  iconLabel,
  grow,
  activeTab,
  invitesCount,
  tabId,
  index,
  onClickTab,
  isSidebar,
}) => {
  const { t } = useTranslation();
  const onClick = () => {
    onClickTab(index);
  };

  const renderIcon = () => {
    if (label === t('common.documentation')) {
      return <DocumentIcon />;
    }

    return null;
  };

  return (
    <>
      {label.length > 0 ? (
        <>
          {grow && <StyledItemPlaceholder />}
          <StyledTab
            marginRight={t('group.tabs.import') === label ? '6px' : false}
            isActive={activeTab === index}
            onClick={onClick}
            isSidebar={isSidebar}
          >
            <span>{iconLabel ? renderIcon() : label}</span>
            {tabId === 'request' && invitesCount > 0 && (
              <PendingSettingsInvite
                invitesCount={invitesCount}
                addCss={{ right: -7 }}
              />
            )}
          </StyledTab>
          {t('group.tabs.import') === label && (
            <NewIcon onClick={onClick}>
              <StyledRightIcon>
                <Lottie options={animationOptions} width={31} height={31} />
              </StyledRightIcon>
            </NewIcon>
          )}
        </>
      ) : null}
    </>
  );
};

export default Tab;
