import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton, StyledIcon } from './styled';

interface DeleteButtonProps {
  disabled?: boolean;
  withLabel?: boolean;
  label?: string;
  type?: 'submit' | 'button';
  children?: JSX.Element | JSX.Element[];
  width?: string;
  outlined?: boolean;
  onClick?: () => void;
}

const DeleteButton: FC<DeleteButtonProps> = ({
  disabled,
  withLabel,
  label,
  type = 'submit',
  children,
  width,
  outlined,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      withLabel={withLabel || label}
      type={type}
      width={width}
      outlined={outlined}
    >
      {children || <StyledIcon />}
      {withLabel && !children && <span>{t('common.delete')}</span>}
      {label && <span>{label}</span>}
    </StyledButton>
  );
};

export default DeleteButton;
