import styled from 'styled-components';

export const StyledButtonBlock = styled.a`
  width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 2px rgb(130, 152, 171, 0.25);
  text-decoration: none;
`;

export const StyledButton = styled.span`
  font-size: 17px;
  color: ${({ theme }) => theme.color.baliHai};
  user-select: none;
`;
