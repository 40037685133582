import styled from 'styled-components';

import { getZoomedValue } from 'utils/zoom';

export const StyledBlock = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;

    &:first-of-type {
      overflow: hidden;
    }
  }
`;

export const StyledName = styled.div`
  font-size: ${({ size, zoom }) =>
    getZoomedValue(size === 'sm' ? 14 : 15, zoom, 'px')};
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: ${({ zoom }) => getZoomedValue(10, zoom, 'px')};
`;
