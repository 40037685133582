import { createRoutine } from 'redux-saga-routines';

export const doLoginChat = createRoutine('LOGIN_CHAT');

export const doLogoutChat = createRoutine('LOGOUT_CHAT');

export const doCreateChat = createRoutine('CREATE_CHAT', {
  trigger: (payload: {
    communityId: string;
    chatName: string;
    members?: string[];
  }) => payload,
});

export const doAddMembers = createRoutine('ADD_MEMBERS', {
  trigger: (payload: { [key: string]: boolean }) => payload,
});

export const doGetChats = createRoutine('GET_CHATS', {
  trigger: (payload: string) => payload,
});

export const doSetCurrentChat = createRoutine('SET_CURRENT_CHAT', {
  trigger: (payload: {
    chatId?: string;
    userId?: string;
    authId?: string;
    groupId?: string;
    communityId?: string;
  }) => payload,
});

export const doSetOnlineMembers = createRoutine('SET_ONLINE_MEMBERS');
