import React, { FC } from 'react';

import { Circle, Icon } from './styled';

interface ScrollButtonProps {
  position: 'left' | 'right';
  isMenuOpen: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const ScrollButton: FC<ScrollButtonProps> = ({
  position,
  isMenuOpen,
  disabled,
  onClick,
}) => (
  <Circle
    position={position}
    isMenuOpen={isMenuOpen}
    disabled={disabled}
    onClick={() => onClick()}
  >
    <Icon position={position} />
  </Circle>
);

export default ScrollButton;
