import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, ProfileName } from 'components';
import {
  getProfileName,
  mapDailyRepeatedEventToArray,
  mapWeeklyRepeatedEventToArray,
  mapMonthlyRepeatedEventToArray,
  addDays,
} from 'utils';
import {
  getAwaiting,
  getEventToRender,
  getGuests,
  getRegisteredUsers,
  getResponsesValues,
  getTotalInvitees,
} from 'utils/schedule';
import { getTaskValues, getUserValues } from './values';
import {
  Community,
  CommunityOverallScheduler,
  EventType,
  EventResponse,
  EventTask,
  CommunityUser,
  EventToRender,
  FooterData,
  EventGridItem,
  User,
  EventReply,
  Event,
  SchedulerFilter,
  GroupUser,
  CommunityTotal,
} from 'types';

export const useEventsToRender = ({
  community,
  events,
  eventTypes,
  eventResponses,
  eventTasks,
  filters,
  timeFormat,
}: {
  community: Community;
  events: Event[];
  eventTypes: EventType[];
  eventResponses: EventResponse[];
  eventTasks: EventTask[];
  filters: SchedulerFilter;
  timeFormat: 12 | 24;
  user: CommunityUser;
}): EventToRender[] => {
  const filterToIncl = addDays(filters.to, 1);

  return useMemo<EventToRender[]>(() => {
    return events
      ?.map((event) => {
        const dateFrom = new Date(event.from);

        if (event.isRepeated) {
          const modifierToFunc = {
            day: mapDailyRepeatedEventToArray,
            week: mapWeeklyRepeatedEventToArray,
            month: mapMonthlyRepeatedEventToArray,
          };
          const modifier = event.repeatedRules.invetrvalModificator;

          if (modifierToFunc[modifier]) {
            return modifierToFunc[modifier](
              event,
              filters.from,
              filterToIncl,
              eventTypes,
              timeFormat,
            );
          }
        }

        if (
          dateFrom.getTime() >= filters.from.getTime() &&
          dateFrom.getTime() <= filterToIncl.getTime()
        ) {
          return getEventToRender(event, dateFrom, eventTypes, timeFormat, {
            community,
            responses: eventResponses,
            tasks: eventTasks,
          });
        }

        return null;
      })
      .flat()
      .filter((e) => !!e)
      .filter((e) => !(Object.keys(e).length === 0 && e.constructor === Object))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [eventTypes, events, filters.eventType]);
};

export const useFooterData = (
  responses: EventResponse[],
  events: EventToRender[],
  total: CommunityTotal,
): FooterData => {
  const { t } = useTranslation();

  const registeredUsers = getRegisteredUsers(events, responses, total, t);
  const guests = getGuests(events, responses, total, t);
  const responsesValues = getResponsesValues(events, responses, total);
  const totalInvitees = getTotalInvitees(events, t);
  const awaiting = getAwaiting(events, totalInvitees, registeredUsers, t);

  return {
    label: t('subscriptions.total'),
    data: [
      totalInvitees,
      registeredUsers,
      awaiting,
      ...responsesValues,
      guests,
    ],
  };
};

export const useGridData = ({
  tasks,
  eventsToRender,
  responses,
  openGiveReplySidebar,
  openTaskDetailsSidebar,
  currentUser,
  currentUserInGroup,
  overallScheduler,
  zoom,
  openViewMemberSidebar,
  community,
}: {
  tasks: EventTask[];
  eventsToRender: EventToRender[];
  responses: EventResponse[];
  openGiveReplySidebar: (props: {
    id: string;
    eventId: string;
    eventDate: Date;
    subReply?: EventReply;
    subTaskReplies?: string[];
    isViewOnly: boolean;
    isAdmin: boolean;
    name: string;
  }) => void;
  openTaskDetailsSidebar: (task: EventTask, userIds: string[]) => void;
  currentUser: CommunityUser;
  currentUserInGroup: GroupUser;
  overallScheduler: CommunityOverallScheduler;
  zoom: number;
  openViewMemberSidebar: (user: User) => void;
  community: Community;
}): EventGridItem[] => {
  const { t } = useTranslation();

  const getTasksData = (tasks: EventTask[]): EventGridItem[] => {
    return Array.isArray(tasks)
      ? [
          {
            type: 'group',
            id: 'tasks',
            label: t('common.tasks'),
          },
          ...tasks.map((task) => ({
            type: 'task',
            id: task.id,
            group: 'tasks',
            label: task.title,
            values: getTaskValues(task, eventsToRender, openTaskDetailsSidebar),
            icon: <Icon icon={task.icon} />,
          })),
        ]
      : [];
  };

  const getGroupsData = (
    overallScheduler: CommunityOverallScheduler,
  ): EventGridItem[] => {
    const groupData: EventGridItem[] = Array.isArray(overallScheduler.groups)
      ? overallScheduler.groups
          .filter((g) => !!g)
          .map((group) => [
            {
              type: 'group',
              id: group.id,
              label: group.name,
            },
            ...group.users.map((member) => ({
              type: 'user',
              id: member.profile.userId,
              group: group.id,
              label: (
                <ProfileName
                  avatar={member.profile.smallLogo || member.profile.logo}
                  name={getProfileName(member.profile)}
                  communityPermission={
                    overallScheduler.users.find((u) => u.id === member.id)
                      ?.communityPermission
                  }
                  groupPermission={member.groupPermission}
                  size="sm"
                  zoom={zoom}
                  onClick={() => {
                    openViewMemberSidebar(member);
                  }}
                />
              ),
              values: getUserValues({
                userId: member.profile.userId,
                responses,
                eventsToRender,
                openGiveReplySidebar,
                currentUser,
                currentUserInGroup,
                name: getProfileName(member.profile),
                community,
              }),
              onClick: () => openViewMemberSidebar(member),
            })),
          ])
          .flat()
      : [];

    if (overallScheduler.users) {
      const usersWithoutGroup =
        overallScheduler?.users?.filter(
          ({ id }) => !groupData.some((item) => item.id === id),
        ) || [];
      return groupData
        .concat([
          {
            type: 'group',
            id: 'no_group',
            label: t('common.withoutGroup'),
          },
          ...usersWithoutGroup.map((member) => ({
            type: 'user',
            id: member.id,
            group: 'no_group',
            label: (
              <ProfileName
                avatar={member.profile.smallLogo || member.profile.logo}
                name={getProfileName(member.profile)}
                communityPermission={
                  overallScheduler.users.find((u) => u.id === member.id)
                    ?.communityPermission
                }
                size="sm"
                zoom={zoom}
                onClick={() => {
                  openViewMemberSidebar(member);
                }}
              />
            ),
            values: getUserValues({
              userId: member.profile.userId,
              responses,
              eventsToRender,
              openGiveReplySidebar,
              currentUser,
              currentUserInGroup,
              name: getProfileName(member.profile),
              community,
            }),
            firstName: member.profile.firstName,
            lastName: member.profile.lastName,
            profileName: member.profile.profileName,
            email: member.profile.email,
            onClick: () => openViewMemberSidebar(member),
          })),
        ])
        .flat();
    }
    return groupData;
  };

  return useMemo(() => {
    return [...getTasksData(tasks), ...getGroupsData(overallScheduler)];
  }, [tasks, eventsToRender, overallScheduler, zoom]);
};
