import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldError } from 'react-hook-form';

import { COMMUNITY_ROLES_OPTIONS, GROUP_ROLES_OPTIONS } from 'configs';
import { closeModal } from 'store/actionCreators/modal';
import {
  getAddCommunityMember,
  getCurrentCommunity,
} from 'store/selectors/currentCommunity';
import { useInviteMembers } from './hooks';
import { FIELDS, InviteMemberFormData } from './form';
import {
  Button,
  DeleteButton,
  Loader,
  Sidebar,
  SidebarFooter,
  SidebarHeader,
  TextInput,
} from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV3';
import SelectInputControlledMulti from 'components/Inputs/SelectInputControlled/SelectInputControlledMultiV2';
import { CommunityRoles } from 'types';

import {
  Content,
  InputWrapper,
  StyledGroupItem,
  StyledGroupName,
  StyledGroupsList,
  StyledIcon,
} from './styled';

const AddMember = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getAddCommunityMember);

  const defaultValues: InviteMemberFormData = {
    [FIELDS.FIRST_NAME]: '',
    [FIELDS.LAST_NAME]: '',
    [FIELDS.MEMBER_EMAIL]: '',
    [FIELDS.ROLE]: {
      value: CommunityRoles.MEMBER,
      label: t('communitySettings.member'),
    },
    [FIELDS.GROUPS]: [],
  };

  const {
    onSubmit,
    register,
    errors,
    nameFieldsShown,
    setNameFieldsShown,
    emailChecking,
    groupsFieldsProps,
    control,
    reset,
  } = useInviteMembers({
    communityId: community?.id,
    users: community?.users,
    defaultValues,
  });

  const onReset = () => {
    if (nameFieldsShown) {
      setNameFieldsShown(false);
    }
    reset(defaultValues);
  };

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('communitySettings.addMember')}
          onCloseClick={() => dispatch(closeModal())}
        />
        <Content>
          <TextInput
            name={FIELDS.MEMBER_EMAIL}
            width="100%"
            register={register}
            label={t('common.email')}
            error={errors[FIELDS.MEMBER_EMAIL]}
            readonly={nameFieldsShown}
          />
          {nameFieldsShown ? (
            <>
              <TextInput
                name={FIELDS.FIRST_NAME}
                width="100%"
                label={t('profile.firstName')}
                register={register}
                error={errors[FIELDS.FIRST_NAME]}
              />
              <TextInput
                name={FIELDS.LAST_NAME}
                width="100%"
                label={t('profile.lastName')}
                register={register}
                error={errors[FIELDS.LAST_NAME]}
              />
            </>
          ) : null}
          <SelectInputControlled
            width="100%"
            options={COMMUNITY_ROLES_OPTIONS.filter(
              ({ value }) => value !== 'blocked',
            ).map((i) => ({
              ...i,
              label: t(`communitySettings.${i.value}`),
            }))}
            label={t('community.communityRole')}
            name={FIELDS.ROLE}
            control={control}
          />
          <SelectInputControlledMulti
            width="100%"
            name="groupsArr"
            options={community.groups?.map((group) => ({
              value: group.id,
              label: group.name,
            }))}
            label={t('common.groups')}
            value={groupsFieldsProps.fields.map((i) => ({
              value: i.value,
              label: i.label,
            }))}
            onChange={(v) => {
              if (v.length > groupsFieldsProps.fields.length) {
                const newValue = v.find(
                  (i) =>
                    !groupsFieldsProps.fields.some((f) => f.value === i.value),
                );

                groupsFieldsProps.append({
                  ...newValue,
                  role: {
                    value: GROUP_ROLES_OPTIONS[1].value,
                    label: t(
                      `communitySettings.${GROUP_ROLES_OPTIONS[1].value}`,
                    ),
                  },
                });
              } else if (v.length < groupsFieldsProps.fields.length) {
                const valueToRemoveInd = groupsFieldsProps.fields.findIndex(
                  (f) => !v.some((i) => i.value === f.value),
                );

                groupsFieldsProps.remove(valueToRemoveInd);
              }
            }}
          />
          {groupsFieldsProps.fields.length > 0 && (
            <StyledGroupsList nameFieldsShown={nameFieldsShown}>
              {groupsFieldsProps.fields.map((f, ind) => (
                <StyledGroupItem key={f.id}>
                  <StyledGroupName>{f.label}</StyledGroupName>
                  <input
                    name={`${FIELDS.GROUPS}[${ind}].value`}
                    ref={register}
                    defaultValue={f.value}
                    onClick={() => groupsFieldsProps.remove(0)}
                    style={{ display: 'none' }}
                  />
                  <SelectInputControlled
                    width="175px"
                    options={GROUP_ROLES_OPTIONS.map((i) => ({
                      ...i,
                      label: t(`communitySettings.${i.value}`),
                    }))}
                    name={`${FIELDS.GROUPS}[${ind}].role`}
                    control={control}
                    maxMenuHeight={150}
                    defaultValue={GROUP_ROLES_OPTIONS[1]}
                    error={
                      errors[FIELDS.GROUPS] &&
                      (errors[FIELDS.GROUPS][ind]?.role as FieldError)
                    }
                  />
                </StyledGroupItem>
              ))}
            </StyledGroupsList>
          )}
        </Content>
        <SidebarFooter>
          <InputWrapper>
            <DeleteButton withLabel type="button" onClick={onReset}>
              <StyledIcon />
              {t('auth.reset')}
            </DeleteButton>
            <Button
              width="200px"
              type="submit"
              variant={isLoading ? 'secondary' : null}
              disabled={isLoading}
            >
              {isLoading || emailChecking ? (
                <Loader type="button" size="28px" thickness={2} />
              ) : (
                t('communitySettings.addMember')
              )}
            </Button>
          </InputWrapper>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};

export default AddMember;
