import React, { useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';

import { Wrapper } from 'components/Popups/styled';
import { Button, Card, CloseButton, Divider, Loader } from 'components';
import {
  InfoBlock,
  SubmitPopup,
  InfoContainer,
  InfoTitle,
  InfoMessage,
  ButtonWrapper,
  StyledContainer,
  PopupHeadTitle,
} from './styled';

import { LinkTextWithButton } from './Message/styled';
import { breakpoints } from 'theme';
import { useMedia } from 'react-use';

import PopupWrapper from 'components/Popups/Wrapper';
import { ButtonLoaderWrapper } from './Templates/styled';

type ConfirmPopupProps = {
  isOpen?: boolean;
  onSubmitHandler?: () => void;
  onClose?: () => void;
  title?: string;
  message?: string;
  recipients?: string[];
  redirectLink?: string;
  isSendingMessage?: boolean;
};

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  isOpen,
  onSubmitHandler,
  onClose,
  title,
  message,
  recipients,
  redirectLink,
  isSendingMessage,
}) => {
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const [messageWidth, setMessageWidth] = useState<string>('320px');
  const [containerRef, { width: containerWidth }] = useMeasure();
  const [titleRef, { width: titleWidth }] = useMeasure();
  const { t } = useTranslation();

  useEffect(() => {
    if (containerWidth && titleWidth) {
      const width = containerWidth - titleWidth - 36;
      setMessageWidth(`${width}px`);
    }
  }, [containerWidth, titleWidth]);

  return (
    <PopupWrapper isOpen={isOpen}>
      <SubmitPopup>
        <CloseButton onClick={onClose} />
        <PopupHeadTitle>
          {t('pushNotification.sendConfirmHeader')}
        </PopupHeadTitle>
        <StyledContainer ref={containerRef}>
          <InfoContainer>
            <InfoBlock>
              <InfoTitle ref={titleRef}>
                {t('pushNotification.totalRecipients')}:
              </InfoTitle>
              <InfoMessage boldFonts messageWidth={messageWidth}>
                {recipients?.length ?? 0}
              </InfoMessage>
            </InfoBlock>
            {redirectLink && (
              <InfoBlock>
                <InfoTitle>{t('pushNotification.redirectLink')}:</InfoTitle>
                <InfoMessage
                  boldFonts
                  noLeftPadding
                  messageWidth={messageWidth}
                >
                  <LinkTextWithButton
                    textAlign={isDownSmPlus ? 'center' : 'left'}
                  >
                    <LinkTextWithButton.Link
                      href={redirectLink}
                      target="_blank"
                    >
                      {redirectLink}
                    </LinkTextWithButton.Link>
                  </LinkTextWithButton>
                </InfoMessage>
              </InfoBlock>
            )}
            <InfoBlock>
              <InfoTitle>{t('email.subject')}:</InfoTitle>
              <InfoMessage boldFonts messageWidth={messageWidth}>
                {title}
              </InfoMessage>
            </InfoBlock>
            <InfoBlock>
              <InfoTitle>{t('email.message')}:</InfoTitle>
              <InfoMessage messageWidth={messageWidth}>{message}</InfoMessage>
            </InfoBlock>
          </InfoContainer>
        </StyledContainer>
        <Divider customMargin="30px 0px" />
        <ButtonWrapper>
          <Button
            onClick={onClose}
            {...{ rounded: true }}
            variant="secondary"
            width={isDownSmPlus ? '100%' : '30%'}
            disabled={isSendingMessage}
          >
            {t('communitySettings.notificationPopupBack')}
          </Button>
          {isSendingMessage ? (
            <ButtonLoaderWrapper width={isDownSmPlus ? '100%' : '60%'}>
              <Loader type="button" size="28px" />
            </ButtonLoaderWrapper>
          ) : (
            <Button
              onClick={onSubmitHandler}
              {...{ rounded: true }}
              width={isDownSmPlus ? '100%' : '60%'}
            >
              {t('auth.send')}
            </Button>
          )}
        </ButtonWrapper>
      </SubmitPopup>
    </PopupWrapper>
  );
};

export default ConfirmPopup;
