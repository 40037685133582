import styled from 'styled-components';

import { RadioCheckedIcon, RadioDefaultIcon, RadioDisabledIcon } from 'static';

export const StyledBlock = styled.label`
  display: block;
  position: relative;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  color: ${({ theme }) => theme.color.pickledBluewood};

  &.disabled {
    color: ${({ theme }) => theme.color.baliHai};
  }
  margin-left: 25px;
`;

export const StyledInput = styled.input`
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:checked + span {
    background-image: url(${RadioCheckedIcon});
  }

  &:disabled + span {
    background-image: url(${RadioDisabledIcon});
  }

  label &:disabled {
    color: ${({ theme }) => theme.color.baliHai};
  }
`;

export const StyledRadio = styled.span`
  position: absolute;
  top: 10px;
  margin-left: -25px;
  width: 14px;
  height: 14px;
  background-image: url(${RadioDefaultIcon});
  background-repeat: no-repeat;
`;
