import React, { FC } from 'react';

import { StyledButton, StyledIcon } from './styled';

interface ReturnButtonProps {
  onClick: () => void;
}

const ReturnButton: FC<ReturnButtonProps> = ({ onClick }) => (
  <StyledButton type="button" onClick={onClick}>
    <StyledIcon />
  </StyledButton>
);

export default ReturnButton;
