import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: 100%;
  position: absolute;
  top: ${({ positionTop }) => (positionTop ? positionTop : '28px')};
  /* left: 92%; */
  right: ${({ positionRight = '4px' }) => positionRight};
  cursor: pointer;
`;

export const EmojiContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 58px;
  left: -257px;
  max-width: 327px;
  z-index: 2000;
  background: ${({ theme }) => theme.color};
`;
