import React, { FC, useState } from 'react';

import { ArrowLeftCircle } from 'static';
import { ArrowRight, ArrowLeft, Wrapper } from './styled';

interface GridScrollBlockProps {
  style: { [key: string]: string | number };
  zoom: number;
  onClick: (direction: 'start' | 'end') => void;
}

const GridScrollBlock: FC<GridScrollBlockProps> = ({
  style,
  zoom,
  onClick,
}) => {
  const [isAnimatingLeft, setIsAnimatingLeft] = useState(false);
  const [isAnimatingRight, setIsAnimatingRight] = useState(false);

  const handleClick = (type: 'start' | 'end') => {
    onClick(type);

    if (type === 'start') {
      setIsAnimatingLeft(true);
      setTimeout(() => {
        setIsAnimatingLeft(false);
      }, 1000);
    } else {
      setIsAnimatingRight(true);
      setTimeout(() => {
        setIsAnimatingRight(false);
      }, 1000);
    }
  };

  return (
    <Wrapper style={style}>
      <ArrowLeft
        onClick={() => handleClick('start')}
        zoom={zoom}
        isAnimating={isAnimatingLeft}
      >
        <ArrowLeftCircle />
      </ArrowLeft>
      <ArrowRight
        onClick={() => handleClick('end')}
        zoom={zoom}
        isAnimating={isAnimatingRight}
      >
        <ArrowLeftCircle />
      </ArrowRight>
    </Wrapper>
  );
};

export default GridScrollBlock;
