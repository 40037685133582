import React from 'react';
import { string } from 'prop-types';

import { StyledCell, StyledSpot, StyledText } from './styled';

const SpotCell = ({ text, color }) => (
  <StyledCell>
    <StyledSpot color={color} />
    <StyledText>{text}</StyledText>
  </StyledCell>
);

SpotCell.propTypes = {
  text: string.isRequired,
  color: string.isRequired,
};

export default SpotCell;
