import styled from 'styled-components';

export const ScrollBlock = styled.div`
  width: 100%;
  height: 0;
  overflow-y: hidden;
  overflow-x: scroll;

  div {
    width: ${({ gridWidth }) => gridWidth}px;
    height: 1px;
  }
`;
