import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, StyledIcon, Placeholder } from './styled';

interface ContentPlaceholderProps {
  placeholder?: string;
}

const ContentPlaceholder: FC<ContentPlaceholderProps> = ({ placeholder }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledIcon />
      <Placeholder>{placeholder || t('common.contentPlaceholder')}</Placeholder>
    </Container>
  );
};

export default ContentPlaceholder;
