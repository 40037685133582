import styled from 'styled-components';

export const Link = styled.a`
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ebf2f7;
  background-color: #f7faf9;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  align-items: center;
  svg {
    width: 22px;
    height: 22px;
  }

  svg {
    path {
      fill: ${({ fillColor }) => fillColor};
    }
  }
  &:hover {
    border-color: ${({ fillColor }) => fillColor};
    background-color: #d3d3d3ad;
  }
`;
