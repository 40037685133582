import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { doSendRequest } from 'store/actionCreators/currentCommunity';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import CommunityLandingPage from './CommunityLandingPage';
import { getAuthUser } from 'store/selectors/auth';

const CommunityLandingContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const { addToast } = useToasts();
  const community = useSelector(getCurrentCommunity);
  const currentUser = useSelector(getAuthUser);

  const isUserInCommunity = !!community?.users?.find(
    (u) => u.id === currentUser?.userId,
  );

  const onSuccess = () => {
    addToast(t('community.requestSent'), {
      appearance: 'success',
      autoDismiss: false,
      autoDismissTimeout: 7000,
    });
  };

  const onFailure = (message) => {
    addToast(message, {
      appearance: 'error',
      autoDismiss: false,
    });
  };

  const onSendRequest = () => {
    dispatch(
      doSendRequest({
        communityId,
        onSuccess,
        onFailure,
      }),
    );
  };

  return community ? (
    <CommunityLandingPage
      community={community}
      isUserInCommunity={isUserInCommunity}
      sendRequest={onSendRequest}
    />
  ) : (
    <Loader />
  );
};

export default CommunityLandingContainer;
