import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckButton } from 'components';
import { AttendedIcon } from 'static';

interface AttendedButtonProps {
  checked: boolean;
  onChange: (e: Event) => void;
}

const AttendedButton: FC<AttendedButtonProps> = ({ checked, onChange }) => {
  const { t } = useTranslation();

  return (
    <CheckButton
      value="attended"
      label={t('event.nomination')}
      checked={checked}
      onChange={onChange}
      icon={AttendedIcon}
    />
  );
};

export default AttendedButton;
