import { createRoutine } from 'redux-saga-routines';

export const doSendRequest = createRoutine('SEND_REQUEST', {
  trigger: (payload: {
    communityId: string;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doRequestRequests = createRoutine('REQUEST_REQUESTS', {
  trigger: (payload: string) => payload,
});

export const doApproveRequests = createRoutine('APPROVE_REQUESTS', {
  trigger: (payload: {
    communityId: string;
    data: string[];
    onSuccess: () => void;
  }) => payload,
});

export const doRejectRequests = createRoutine('REJECT_REQUESTS', {
  trigger: (payload: {
    communityId: string;
    data: string[];
    onSuccess: () => void;
  }) => payload,
});
