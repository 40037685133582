import React, { FC } from 'react';
import { isEmpty } from 'lodash/fp';

import { StyledButton, StyledLinkButton, StyledTextWrapper } from './styled';

interface ButtonProps {
  children: JSX.Element | string;
  variant?: 'primary' | 'secondary' | 'danger' | 'text' | 'default';
  width?: string;
  disabled?: boolean;
  link?: string;
  IconComponent?: any;
  zoom?: number;
  type?: 'submit' | 'button';
  style?: { [key: string]: string | number };
  buttonStyles?: any;
  onClick?: () => any;
}

const Button: FC<ButtonProps> = ({
  onClick,
  children,
  variant = 'primary',
  width = '340px',
  disabled,
  link,
  IconComponent,
  zoom = 1,
  style,
  ...props
}) =>
  isEmpty(link) ? (
    <StyledButton
      onClick={onClick}
      variant={variant}
      width={width}
      disabled={disabled}
      zoom={zoom}
      className={`btn-${variant}`}
      {...props}
      style={style}
    >
      {IconComponent ? (
        <>
          <IconComponent />
          <StyledTextWrapper zoom={zoom}>{children}</StyledTextWrapper>
        </>
      ) : (
        children
      )}
    </StyledButton>
  ) : (
    <StyledLinkButton
      to={link}
      variant={variant}
      width={width}
      disabled={disabled}
      zoom={zoom}
      {...props}
    >
      {IconComponent ? (
        <>
          <IconComponent />
          <StyledTextWrapper zoom={zoom}>{children}</StyledTextWrapper>
        </>
      ) : (
        children
      )}
    </StyledLinkButton>
  );

export default Button;
