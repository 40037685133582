import styled from 'styled-components';

export const StyledAvatarNoPhoto = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  color: ${({ theme }) => theme.color.regentGray};
`;
