import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  COMMUNITY_NAME = 'name',
  CATEGORY = 'category',
  LOGO = 'logo',
}

export type CreateCommunityValues = {
  [FIELDS.COMMUNITY_NAME]: string;
  [FIELDS.CATEGORY]: string;
  [FIELDS.LOGO]: string;
};

export const useCreateCommunityForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<CreateCommunityValues> = yupResolver(
    yup.object().shape({
      [FIELDS.COMMUNITY_NAME]: yup.string().required().max(50).min(3).trim(),
      [FIELDS.CATEGORY]: yup.string().required().trim(),
    }),
  );

  return useForm<CreateCommunityValues>({
    resolver,
  });
};
