import styled from 'styled-components';
import { breakpoints } from 'theme';

export const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.3s;
  :hover {
    background-color: ${({ theme }) => theme.color.blackSqueeze};
  }
  @media ${breakpoints.downSmPlus} {
    padding: 0px 10px;
  }
`;

export const ButtonsContainer = styled.div`
  gap: 24px;
  min-width: 70px;
  display: flex;
  row-gap: 20px;
  align-items: center;
  justify-content: space-between;
  @media ${breakpoints.downMdPlus} {
    gap: 16px;
  }
  @media ${breakpoints.downSmPlus} {
    gap: 10px;
  }
`;

export const DefaultSettingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 4px;
  @media ${breakpoints.downSmPlus} {
    display: none;
  }
`;

export const LinkText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.pickledBluewood};
`;
