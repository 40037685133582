import styled from 'styled-components';
import { LogoutIcon } from 'static';

export const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 0 27px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const Content = styled.div`
  background-color: white;
  height: calc(100% - 50px);
`;

export const StyledWrapper = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  padding: 0 25px;
  text-align: left;

  label {
    white-space: nowrap;
  }
`;

export const StyledLogoutIcon = styled(LogoutIcon)`
  width: 18px;
  height: 14px;

  path {
    fill: ${({ theme }) => theme.color.casper};
  }
`;

export const LogoutButton = styled.span`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 15px;
  margin-left: 12px;
  font-weight: normal;
  cursor: pointer;
`;

export const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;
