import { MutableRefObject, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const SCROLL_OFFSET = 500;

type UseLazyLoadingOptions = {
  isLoading: boolean;
  loader: (options?: any) => void;
  pageSize: number;
  listLength: number;
  hasNextPage: boolean;
  cleaner: (options?: { avoidEventTypes?: boolean }) => void;
  filterValues: { [key: string]: any };
  scollOffset?: number;
  avoidCleanup?: boolean;
};

const useLazyLoading = ({
  isLoading,
  loader,
  pageSize,
  listLength,
  hasNextPage,
  cleaner = () => {},
  filterValues,
  scollOffset,
  avoidCleanup,
}: UseLazyLoadingOptions): { listRef: MutableRefObject<HTMLDivElement> } => {
  const listRef = useRef<HTMLDivElement>();

  useEffect(() => {
    return avoidCleanup ? () => {} : cleaner;
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (listLength > 0) {
        cleaner({ avoidEventTypes: true });
      }
      loader({ page: 1, limit: pageSize, ...filterValues });
    }
  }, [filterValues]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.addEventListener('scroll', handleListScroll);

      return () =>
        listRef.current?.removeEventListener('scroll', handleListScroll);
    }
  }, [isLoading, listLength, hasNextPage]);

  const handleListScroll = debounce(() => {
    if (
      listRef.current.scrollLeft >=
        listRef.current.scrollWidth -
          listRef.current.clientWidth -
          (scollOffset || SCROLL_OFFSET) &&
      !isLoading &&
      hasNextPage
    ) {
      loader({
        page: listLength / pageSize + 1,
        limit: pageSize,
        ...filterValues,
      });
    }
  }, 150);

  return { listRef };
};

export default useLazyLoading;
