import React, { FC } from 'react';

import SocialNetworkIcon from './SocialNetworkIcon';
import { CommunityRoles, ProfileSocialLinkType } from 'types';

import { FieldEditIcon, StyledLink } from './styled';

interface SocialLinkButtonProps {
  href: string;
  socialNetwork: ProfileSocialLinkType;
  width?: string;
  openEditSidebar?: (data?: string) => void;
  userRole?: CommunityRoles;
  itsMe?: boolean;
}

export const getColor = (socialNetwork: string) => {
  switch (socialNetwork) {
    case ProfileSocialLinkType.FACEBOOK:
      return '#0866ff';
    case ProfileSocialLinkType.YOUTUBE:
      return '#f00';
    case ProfileSocialLinkType.TWITTER:
      return '#000';
    case ProfileSocialLinkType.INSTAGRAM:
      return '#dc2743';
    case ProfileSocialLinkType.LINKEDIN:
      return '#0a66c2';
    default:
      return null;
  }
};

const SocialLinkButton: FC<SocialLinkButtonProps> = ({
  href,
  socialNetwork,
  width = '14px',
  openEditSidebar,
  userRole,
  itsMe,
}) => (
  <div onClick={() => openEditSidebar(socialNetwork)}>
    <StyledLink
      fillColor={href && getColor(socialNetwork)}
      href={href && (!href?.startsWith('https://') ? 'https://' + href : href)}
      style={{ display: href == '-' ? 'none' : 'flex' }}
      width={width}
      rel="noreferrer"
      target="_blank"
    >
      <SocialNetworkIcon socialNetwork={socialNetwork} />
      {!href &&
        ([CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ||
          itsMe) && <FieldEditIcon />}
    </StyledLink>
  </div>
);

export default SocialLinkButton;
