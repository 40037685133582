import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getProfileName } from 'utils';
import { STATISTICS_TYPES } from 'configs';
import {
  getGroupAdmins,
  getGroupLeaders,
  getGroupMembers,
} from 'store/selectors/currentGroup';
import {
  EventResponse,
  EventType,
  GroupUser,
  OverallStatistics,
  OverallStatisticsEventType,
  OverallStatisticsReply,
} from 'types';

import { StyledAvatarNoPhoto } from './styled';

const getUserValuesReplies = (
  memberId: string,
  responses: (EventResponse | { color: string; id: string; title: string })[],
  replies: OverallStatisticsReply[],
) =>
  responses?.map(({ color, id }) => {
    let finalColor = '',
      text;

    const userReplies = replies.filter(({ userId }) => userId === memberId);
    if (id === 'penalty' || id === 'attended') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const x = userReplies.reduce((acc, cur) => acc + cur[id] * 1, 0);
      finalColor = x > 0 ? color : '';
      text = x > 0 ? x : undefined;
    } else {
      const thisResp = userReplies.find(
        ({ eventResponseId }) => eventResponseId === id,
      );

      finalColor = thisResp ? color : '';
      text = thisResp?.count;
    }

    return {
      responsesId: id,
      color: finalColor,
      text,
    };
  });

const getUserValuesEventTypes = (
  memberId: string,
  eventTypes: EventType[],
  data: OverallStatisticsEventType[],
  responses: (EventResponse | { color: string; id: string; title: string })[],
  replies: OverallStatisticsReply[],
) => {
  const userReplies = replies.filter(({ userId }) => userId === memberId);

  return eventTypes?.map(({ color, id }) => {
    const userValues = data.filter(({ userId }) => userId === memberId);
    const response = userValues.find(({ eventTypeId }) => eventTypeId === id);
    const repliesData = responses
      .filter((r) => !(r.id === 'penalty' || r.id === 'attended'))
      .map((r) => ({
        label: r.title,
        value: userReplies.find(
          ({ eventResponseId }) => eventResponseId === r.id,
        )?.count,
        color: r.color,
      }));

    return {
      color: response ? color : 'transparent',
      text: response?.count,
      repliesData,
    };
  });
};

export const useGridData = (
  statistic: OverallStatistics,
  responses: (EventResponse | { color: string; id: string; title: string })[],
  type: STATISTICS_TYPES,
  eventTypes: EventType[],
) => {
  const { t } = useTranslation();
  const adminMembers = useSelector(getGroupAdmins) || [];
  const leaders = useSelector(getGroupLeaders) || [];
  const members = useSelector(getGroupMembers) || [];

  const adminLabel = t('common.admin');
  const groupLeadLabel = t('common.group_lead');
  const membersLabel = t('common.member');

  const [gridData, setGridData] = useState([
    {
      type: 'group',
      id: 'admin',
      label: adminLabel,
    },
    {
      type: 'group',
      id: 'groupLead',
      label: groupLeadLabel,
    },
    {
      type: 'group',
      id: 'member',
      label: membersLabel,
    },
  ]);

  useEffect(() => {
    setGridData([
      {
        type: 'group',
        id: 'admin',
        label: adminLabel,
      },
      ...adminMembers.map((member) => ({
        type: 'statistics',
        id: member.profile.userId,
        group: 'admin',
        label: getProfileName(member.profile),
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getUserValuesReplies(
                member.profile.userId,
                responses,
                statistic.replies,
              )
            : getUserValuesEventTypes(
                member.profile.userId,
                eventTypes,
                statistic.repliesToEventTypes,
                responses,
                statistic.replies,
              ),
        icon: member.profile.smallLogo || member.profile.logo || (
          <StyledAvatarNoPhoto>
            {getProfileName(member.profile)[0].toUpperCase()}
          </StyledAvatarNoPhoto>
        ),
      })),
      {
        type: 'group',
        id: 'groupLead',
        label: groupLeadLabel,
      },
      ...leaders.map((member) => ({
        type: 'statistics',
        id: member.profile.userId,
        group: 'groupLead',
        label: getProfileName(member.profile),
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getUserValuesReplies(
                member.profile.userId,
                responses,
                statistic.replies,
              )
            : getUserValuesEventTypes(
                member.profile.userId,
                eventTypes,
                statistic.repliesToEventTypes,
                responses,
                statistic.replies,
              ),
        icon: member.profile.smallLogo || member.profile.logo || (
          <StyledAvatarNoPhoto>
            {getProfileName(member.profile)[0].toUpperCase()}
          </StyledAvatarNoPhoto>
        ),
      })),
      {
        type: 'group',
        id: 'member',
        label: membersLabel,
      },
      ...members.map((member) => ({
        type: 'statistics',
        id: member.profile.userId,
        group: 'member',
        label: getProfileName(member.profile),
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getUserValuesReplies(
                member.profile.userId,
                responses,
                statistic.replies,
              )
            : getUserValuesEventTypes(
                member.profile.userId,
                eventTypes,
                statistic.repliesToEventTypes,
                responses,
                statistic.replies,
              ),
        icon: member.profile.smallLogo || member.profile.logo || (
          <StyledAvatarNoPhoto>
            {getProfileName(member.profile)[0].toUpperCase()}
          </StyledAvatarNoPhoto>
        ),
      })),
    ]);
  }, [members, type, eventTypes, statistic, responses]);

  return gridData;
};

const getTotalValuesReplies = (
  responses: (EventResponse | { color: string; id: string; title: string })[],
  statistic: OverallStatisticsReply[],
  members: GroupUser[],
) => {
  return responses?.map(({ id }) => {
    if (id === 'penalty' || id === 'attended') {
      const groupReplies = statistic
        .filter(({ userId }) => {
          return members.find(({ id }) => id === userId);
        })
        .map((i) => parseInt(i[id], 10))
        .reduce((a, b) => a + b, 0);

      return groupReplies;
    } else {
      const replies = statistic.filter(
        ({ eventResponseId }) => id === eventResponseId,
      );
      const membersReplies = replies.filter(({ userId }) =>
        members.find(({ id: memberId }) => memberId === userId),
      );
      return membersReplies?.length;
    }
  });
};

const getTotalValuesEventTypes = (
  eventTypes: EventType[],
  statistic: OverallStatisticsEventType[],
  members: GroupUser[],
) => {
  return eventTypes?.map(({ id }) => {
    const replies = statistic.filter(({ eventTypeId }) => id === eventTypeId);
    const groupReplies = replies
      .filter(({ userId }) => members.find((u) => u.id === userId))
      .map((i) => parseInt(i.count, 10))
      .reduce((a, b) => a + b, 0);

    return groupReplies;
  });
};

export const useFooterData = (
  responses: (EventResponse | { color: string; id: string; title: string })[],
  statistic: OverallStatistics,
  eventTypes: EventType[],
  type: STATISTICS_TYPES,
) => {
  const { t } = useTranslation();
  const adminMembers = useSelector(getGroupAdmins) || [];
  const leaders = useSelector(getGroupLeaders) || [];
  const members = useSelector(getGroupMembers) || [];

  const totalLabel = t('subscriptions.total');
  const leadersLabel = t('common.group_lead');
  const membersLabel = t('common.member');
  const adminsLabel = t('common.admin');

  return {
    label: totalLabel,
    data: [
      {
        label: adminsLabel,
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getTotalValuesReplies(responses, statistic.replies, adminMembers)
            : getTotalValuesEventTypes(
                eventTypes,
                statistic.repliesToEventTypes,
                adminMembers,
              ),
      },
      {
        label: leadersLabel,
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getTotalValuesReplies(responses, statistic.replies, leaders)
            : getTotalValuesEventTypes(
                eventTypes,
                statistic.repliesToEventTypes,
                leaders,
              ),
      },
      {
        label: membersLabel,
        values:
          type === STATISTICS_TYPES.EVENT_REPLIES
            ? getTotalValuesReplies(responses, statistic.replies, members)
            : getTotalValuesEventTypes(
                eventTypes,
                statistic.repliesToEventTypes,
                members,
              ),
      },
    ],
  };
};
