import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getEvent } from 'services/events';
import { ACCESS_DENIED } from 'constants/errors';
import { closeModal, openModal } from 'store/actionCreators/modal';
import { POPUP_MESSAGE } from 'constants/popups';
import { EVENT_DETAILS, GIVE_REPLY } from 'constants/sidebars';
import { Event, EventReply, SchedulerFilter } from 'types';
import { getProfileName, getUTCDateFromDate } from '../utils';
import { getEventDetailsSideBarWidth } from '../utils/getEventDetailsSideBarWidth';
import { hasPermissionToReply } from '../utils/roles';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { getType } from 'store/selectors/modal';
import { getCurrentUserInGroup } from '../store/selectors/currentGroup';

export const getSingleEvent = async ({
  communityId,
  eventId,
  showAccessDeniedModal,
}: {
  communityId: string;
  eventId: string;
  showAccessDeniedModal: () => void;
}) => {
  try {
    const event = await getEvent(communityId, eventId);

    return event;
  } catch (e) {
    if (e.response?.data?.error === ACCESS_DENIED) {
      showAccessDeniedModal();
    }

    return null;
  }
};

const useEventRedirection = (filters: SchedulerFilter, events: Event[]) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams();
  const currentUser = useSelector(getCurrentUserInCommunity);
  const currentUserInGroup = useSelector(getCurrentUserInGroup);
  const modalType = useSelector(getType);
  const community = useSelector(getCurrentCommunity);

  const showAccessDeniedModal = () =>
    dispatch(
      openModal({
        type: POPUP_MESSAGE,
        data: {
          popupHeaderText: t('community.notInvitedTitle'),
          popupMessageText: t('community.notInvitedText'),
          popupButtonText: 'Ok',
          withoutCloseButton: true,
          handleConfirm: () => {
            if (location.pathname.includes('/event/')) {
              navigate(location.pathname.split('/event/')[0], {
                replace: true,
              });
            }
            dispatch(closeModal());
          },
        },
      }),
    );

  const openEventDetailsSidebar = useCallback(
    (event: Event) => {
      dispatch(
        openModal({
          type: EVENT_DETAILS,
          placement: 'right',
          data: {
            eventId,
            date: new Date(event.from),
            width: getEventDetailsSideBarWidth(community.eventResponses.length),
            filterFrom: filters.from,
            filterTo: filters.to,
          },
        }),
      );
    },
    [filters, community, eventId],
  );

  const openGiveReplySidebar = useCallback(
    (event: Event, subReply: EventReply, tasks: string[]) => {
      dispatch(
        openModal({
          type: GIVE_REPLY,
          placement: 'right',
          data: {
            userId: currentUser.id,
            isViewOnly: false,
            isAdmin: hasPermissionToReply(currentUser, currentUserInGroup),
            subReply: subReply
              ? {
                  ...subReply,
                  tasks,
                }
              : null,
            eventId,
            fetchedEvent: event,
            date: new Date(event.from),
            name: getProfileName(currentUser.profile),
            filterFrom: filters.from,
            filterTo: filters.to,
          },
        }),
      );
    },
    [filters, currentUser, currentUserInGroup, eventId],
  );

  useEffect(() => {
    (async () => {
      if (
        events &&
        eventId &&
        modalType !== GIVE_REPLY &&
        community.eventResponses &&
        community.eventTypes &&
        community.eventTasks &&
        !location.pathname.includes('/details')
      ) {
        const event: Event =
          events.find((e) => e.id === eventId) ||
          (await getSingleEvent({
            eventId,
            communityId: community.id,
            showAccessDeniedModal,
          }));

        if (!event) {
          return;
        }

        const isUserInvited = event.recipients.includes(currentUser.id);

        if (!isUserInvited) {
          openEventDetailsSidebar(event);

          return;
        }

        const subReply = event.subEventReplies
          ?.filter((r) => r.userId === currentUser.id)
          .filter(
            (r) =>
              new Date(r.date).getTime() ===
              getUTCDateFromDate(new Date(event.from)).getTime(),
          )[0];
        const eventTasks = event.subEventTaskReplies
          ?.filter((r) => r.userId === currentUser.id)
          .filter(
            (r) =>
              new Date(r.date).getTime() ===
              getUTCDateFromDate(new Date(event.from)).getTime(),
          )
          .map((task) => task.eventTaskId);

        openGiveReplySidebar(event, subReply, eventTasks);
      }
    })();
  }, [events, eventId, modalType]);

  useEffect(() => {
    if (
      events &&
      eventId &&
      modalType !== EVENT_DETAILS &&
      community.eventResponses &&
      community.eventTypes &&
      events.find((e) => e.id === eventId) &&
      location.pathname.includes('/details')
    ) {
      const event = events.find((e) => e.id === eventId);

      openEventDetailsSidebar(event);
    }
  }, [events, community, eventId, modalType]);
};

export default useEventRedirection;
