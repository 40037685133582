import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import { CheckBox } from 'components';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import { StyledSelect, StyledOptionCheckbox, StyledOptionText } from './styled';

interface SelectInputControlledCheckboxProps {
  name?: string;
  label?: string;
  width?: string;
  value: { [key: string]: any }[];
  options: { [key: string]: any }[];
  error?: FieldError;
  isDisabled?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  closeMenuOnSelect?: boolean;
  addAllOption?: boolean;
  onChange: (
    value: { [key: string]: any }[],
    prevValue: { [key: string]: any }[],
  ) => void;
  onBlur?: () => void;
}

const OptionMulti = (props) => {
  const { innerProps, isSelected, data } = props;
  return (
    <div {...innerProps}>
      <StyledOptionCheckbox>
        <CheckBox
          isSelected={
            data.value === 'all'
              ? props.selectProps.value.length + 1 ===
                props.selectProps.options.length
              : isSelected
          }
        />
        <StyledOptionText>{data.label}</StyledOptionText>
      </StyledOptionCheckbox>
    </div>
  );
};

const SelectInputControlledCheckbox: FC<SelectInputControlledCheckboxProps> = ({
  name,
  label,
  options,
  width,
  error,
  value,
  isDisabled,
  placeholder = '',
  maxMenuHeight,
  closeMenuOnSelect,
  addAllOption,
  onChange,
  onBlur = () => {},
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (v) => {
      if (v.some((i) => i.value === 'all')) {
        if (value.length !== options.length) {
          return onChange(options, value);
        }

        return onChange([], value);
      }

      onChange(v, value);
    },
    [value, options],
  );

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}{' '}
        </StyledLabel>
      )}
      <StyledSelect
        error={error}
        width={width}
        id={name}
        name={name}
        options={
          addAllOption
            ? [{ label: t('common.all'), value: 'all' }, ...options]
            : options
        }
        placeholder={placeholder}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        value={value}
        components={{
          Option: OptionMulti,
        }}
        isMulti={true}
        hideSelectedOptions={false}
        isClearable={false}
        minMenuHeight={250}
        maxMenuHeight={maxMenuHeight || 250}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleChange}
        onBlur={onBlur}
        styles={{
          control: (base) => ({
            ...base,
            flexWrap: 'no-wrap',
          }),
          valueContainer: (base) => ({
            ...base,
            flex: 'auto',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            overflowX: 'scroll',
          }),
          multiValue: (base) => ({
            ...base,
            minWidth: '60px',
            backgroundColor: '#edf3f8',
          }),
          multiValueRemove: () => ({
            display: 'none',
          }),
          option: (base, state) => ({
            ...base,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            backgroundColor: state.isSelected
              ? '#edf3f8!important'
              : 'transparent!important',
            width: '77px',
            textAlign: 'center',
            padding: '3px 6px',
          }),
        }}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default SelectInputControlledCheckbox;
