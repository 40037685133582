import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledCommunityTitleBlock = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? 1 : 0)};
  width: calc(100% - 30px);
  transition: opacity 0.3s;
`;

export const StyledCommunityImage = styled.img`
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  object-fit: cover;
  border-radius: 50%;
`;

export const StyledCommunityTitle = styled.span`
  width: 100%;
  max-width: 155px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${breakpoints.downMd} {
    width: auto;
    max-width: 80%;
  }
`;
