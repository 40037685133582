import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { noop } from 'lodash/fp';

import FileBox from './FileBox';
import { FileIcon, CloseIcon } from 'static';

import {
  StyledWrapper,
  HiddenInput,
  StyledPreviewBox,
  StyledPreviewItem,
  StyledErrorMessage,
} from './styled';

const AVAILABLE_SIZE = 1024 * 15;

const validFileSize = (size, maxSize = AVAILABLE_SIZE) =>
  Math.round(size / 1024) < maxSize;

interface FileInputControlledProps {
  value: File[];
  error?: string;
  maxSize?: number;
  width?: string;
  height?: string;
  text: string;
  tipText: string;
  isLoading: boolean;
  withPreview?: boolean;
  allowMultiple?: boolean;
  setError?: (value: string) => void;
  setValue: (value: File | File[] | null) => void;
  handleSubmit?: () => void;
}

const FileInputControlled: FC<FileInputControlledProps> = ({
  value,
  error,
  maxSize,
  width = '500px',
  height = '220px',
  text,
  tipText,
  isLoading,
  withPreview,
  allowMultiple,
  setError,
  setValue = noop,
  handleSubmit = noop,
}) => {
  const { t } = useTranslation();

  const convertFilesToArray = (files) => {
    return Array(files.length)
      .fill(0)
      .map((_, ind) => files[ind]);
  };

  const getTotalSize = (data) => {
    let size = 0;

    if (Array.isArray(data)) {
      const sizes = data.map((f) => f.size);

      if (sizes.length) {
        size = sizes.reduce((a, b) => a + b);
      }
    } else {
      size = data?.size || 0;
    }

    return size;
  };

  const previewVisible = useMemo(() => {
    const areFilesUploaded = Array.isArray(value) ? value.length > 0 : !!value;

    return withPreview && areFilesUploaded;
  }, [withPreview, value]);

  useEffect(() => {
    const size = getTotalSize(value);

    if (!validFileSize(size, maxSize)) {
      setError(t('documentation.tipMessage'));
    } else if (error) {
      setError('');
    }
  }, [value]);

  const handleChange = async (e) => {
    const chosen = allowMultiple
      ? convertFilesToArray(e.target.files)
      : e.target.files[0];

    if (Array.isArray(chosen) ? chosen.length === 0 : !chosen) return false;

    const size = getTotalSize(
      Array.isArray(chosen) ? [...value, ...chosen] : chosen,
    );

    if (!validFileSize(size, maxSize)) {
      setError(t('email.maxAttachmentsSize'));
    }

    await setValue(allowMultiple ? [...value, ...chosen] : chosen);
    handleSubmit();
    return null;
  };

  const handleItemDelete = (e, ind) => {
    e.stopPropagation();
    e.preventDefault();

    if (Array.isArray(value)) {
      const updatedArr = [...value];

      updatedArr.splice(ind, 1);
      setValue(updatedArr);
    } else {
      setValue(null);
    }

    return handleSubmit();
  };

  return (
    <StyledWrapper width={width} height={height} isError={error}>
      {previewVisible ? (
        <PerfectScrollbar
          style={{
            maxWidth: '100%',
            height: '100%',
          }}
          options={{
            wheelSpeed: 0.4,
            wheelPropagation: false,
            minScrollbarLength: 4,
            useBothWheelAxes: true,
          }}
        >
          <StyledPreviewBox>
            {Array.isArray(value) ? (
              value.map((i, ind) => (
                <StyledPreviewItem key={i.name}>
                  <StyledPreviewItem.DeleteButtonWrapper
                    onClick={(e) => handleItemDelete(e, ind)}
                  >
                    <CloseIcon />
                  </StyledPreviewItem.DeleteButtonWrapper>
                  <FileIcon />
                  <StyledPreviewItem.FileName>
                    {i.name}
                  </StyledPreviewItem.FileName>
                </StyledPreviewItem>
              ))
            ) : (
              <StyledPreviewItem />
            )}
          </StyledPreviewBox>
        </PerfectScrollbar>
      ) : (
        <FileBox
          errorMessage={error}
          text={text}
          tipText={tipText}
          isLoading={isLoading}
        />
      )}
      <HiddenInput
        type="file"
        onChange={handleChange}
        disabled={isLoading}
        multiple={allowMultiple}
      />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </StyledWrapper>
  );
};

export default FileInputControlled;
