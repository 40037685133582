import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ControllerRenderProps } from 'react-hook-form';

import { CommunityUsersByGroups, Loader } from 'components';
import {
  getCurrentCommunity,
  getMembersToGroupsLoading,
} from 'store/selectors/currentCommunity';
import { EventFormData } from '../form';

interface OverallEventParticipantsProps {
  recipientsFieldProps: ControllerRenderProps<EventFormData>;
}

const OverallEventParticipants: FC<OverallEventParticipantsProps> = ({
  recipientsFieldProps,
}) => {
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getMembersToGroupsLoading);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CommunityUsersByGroups
      value={recipientsFieldProps.value}
      membersByGroups={community.membersByGroups}
      users={community.users}
      onChange={recipientsFieldProps.onChange}
    />
  );
};

export default OverallEventParticipants;
