import styled from 'styled-components';
import { breakpoints } from 'theme';

export const ChatWrapper = styled.div`
  width: calc(100% - 360px);
  min-height: 100svh;
  background-color: white;
  margin-left: 360px;
  @media ${breakpoints.downMd} {
    width: 100%;
    margin-left: 0;
  }
`;
