import styled from 'styled-components';

import Card from '../Card';
import { breakpoints } from 'theme';

export const Wrapper = styled.div`
  position: relative;
`;

export const AppointmentSchedulerWrapper = styled.div`
  position: relative;
  height: calc(100svh - ${() => 67 + 71 + 27 + 'px'});
  overflow-x: hidden;
  overflow-y: scroll;

  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 130px);
  }
  @media (max-width: 480px) {
    height: calc(100svh - 113px);
  }
`;

export const ChartBox = styled(Card)`
  width: 33%;
`;

export const ChartBoxDimmer = styled.div`
  position: absolute;
  background: #ffffffd6;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;
