import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  OverflowSidebarContentWithTabs,
  CheckListControlled,
  FileInput,
  Loader,
} from 'components';
import { EventFormData } from '../form';
import { hasCommunityAdminPermissions } from 'utils';
import {
  getDocuments,
  getIsDocumentUploading,
} from 'store/selectors/documents';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { useDocumentation } from './hooks';

import { FileInputContainer, StyledUploadDiv } from './styled';

interface CreateEventDocumentsProps {
  documentsFieldProps: ControllerRenderProps<EventFormData>;
  groupId: string | null;
}

const CreateEventDocuments: FC<CreateEventDocumentsProps> = ({
  documentsFieldProps,
  groupId,
}) => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const communityDocuments = useSelector(getDocuments);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const isUploading = useSelector(getIsDocumentUploading);
  const filteredDocuments =
    communityDocuments?.filter(
      (d) => d.groupId === null || d.groupId === groupId,
    ) || [];
  const [initialDocs, setInitialDocs] = useState(filteredDocuments);

  useEffect(() => {
    if (!isUploading) {
      const uploadedDocs = filteredDocuments.filter(
        (d) => !initialDocs.some((i) => i.id === d.id),
      );

      uploadedDocs.forEach((d) => {
        documentsFieldProps.onChange([...documentsFieldProps.value, d.id]);
      });

      setInitialDocs(filteredDocuments);
    }
  }, [isUploading]);

  const { onSubmit, register, setValue } = useDocumentation(
    community.id,
    groupId,
  );

  const allChecked = useMemo(() => {
    return filteredDocuments.every((i) =>
      documentsFieldProps?.value?.includes(i.id),
    );
  }, [documentsFieldProps, filteredDocuments]);

  const handleSelectAllClick = useCallback(() => {
    if (allChecked) {
      documentsFieldProps.onChange([]);
    } else {
      documentsFieldProps.onChange(filteredDocuments.map((i) => i.id));
    }
  }, [allChecked, documentsFieldProps]);

  if (!(community && communityDocuments)) {
    return <Loader />;
  }

  return (
    <OverflowSidebarContentWithTabs>
      {hasCommunityAdminPermissions(currentUser.id, community) && (
        <FileInputContainer>
          <StyledUploadDiv>
            <FileInput
              text={t('documentation.chooseFile')}
              tipText={t('documentation.tipMessage')}
              register={register}
              setValue={setValue}
              handleSubmit={onSubmit}
              name="document"
              width="100%"
              height="100%"
              isLoading={isUploading}
            />
          </StyledUploadDiv>
        </FileInputContainer>
      )}
      <CheckListControlled
        renderType="documents"
        items={filteredDocuments}
        label={t('documentation.attachFiles')}
        selectedItems={documentsFieldProps.value}
        selectItem={(d) => {
          documentsFieldProps.onChange(
            documentsFieldProps.value.includes(d)
              ? documentsFieldProps.value.filter((i) => i !== d)
              : [...documentsFieldProps.value, d],
          );
        }}
        allChecked={allChecked}
        handleSelectAllClick={handleSelectAllClick}
      />
    </OverflowSidebarContentWithTabs>
  );
};

export default CreateEventDocuments;
