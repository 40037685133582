import { CommunitySocialLink } from '../../../types';

export enum FIELDS {
  COMMUNITY_NAME = 'name',
  PARTNERSHIPS = 'partnerships',
  LINKS = 'links',
  WEBSITE = 'website',
  COVER = 'cover',
  LOGO = 'logo',
  CATEGORY = 'category',
  DESCRIPTION = 'description',
}

export type CommunityValues = {
  [FIELDS.COMMUNITY_NAME]: string;
  [FIELDS.CATEGORY]: string;
  [FIELDS.WEBSITE]: CommunitySocialLink;
  [FIELDS.LINKS]: CommunitySocialLink[];
  [FIELDS.PARTNERSHIPS]: CommunitySocialLink[];
  [FIELDS.COVER]: string;
  [FIELDS.LOGO]: string;
  [FIELDS.DESCRIPTION]: string;
};
