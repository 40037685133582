import { createRoutine } from 'redux-saga-routines';

export const getAllSubscriptions = createRoutine('REQUEST_ALL_SUBSCRIPTIONS');

export const getSubscriptionPlanId = createRoutine(
  'REQUEST_SUBSCRIPTION_PLAN_ID',
);

export const clearSubscriptionPlanId = createRoutine(
  'CLEAR_SUBSCRIPTION_PLAN_ID',
);

export const getCurrentUserSubscription = createRoutine(
  'REQUEST_CURRENT_USER_SUBSCRIPTION',
);

export const setSubscription = createRoutine('SET_SUBSCRIPTION', {
  trigger: (payload: {
    productId: string;
    subscriptionId: string;
    pricing: string;
  }) => payload,
});

export const paymentApproved = createRoutine('PAYMENT_APPROVED');

export const switchSubscription = createRoutine('SWITCH_SUBSCRIPTION', {
  trigger: (payload: { id: string; frequency: string }) => payload,
});

export const startTrial = createRoutine('START_TRIAL');

export const activateSubscription = createRoutine('ACTIVATE_SUBSCRIPTION', {
  trigger: (payload: string) => payload,
});

export const cancelSubscription = createRoutine('CANCEL_SUBSCRIPTION', {
  trigger: (payload: string) => payload,
});

export const hasAdblocker = createRoutine('HAS_ADBLOCKER');
