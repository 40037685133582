import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControllerRenderProps,
  DeepMap,
  ErrorOption,
  FieldError,
  UseFormMethods,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  TextInput,
  TextArea,
  Button,
  DocumentationPopup,
  FileInputControlled,
  Loader,
  CheckBox,
} from 'components';
import SelectInputControlledMulti from 'components/Inputs/SelectInputControlled/SelectInputControlledMultiV2';
import { EmailModuleFormValues, FIELDS } from '../form';
import { getProfileName } from 'utils/common';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { Document } from 'types';

import {
  StyledButtonContainer,
  StyledCheckboxWrapper,
  StyledMessageSection,
  StyledForm,
} from './styled';
import { GridGhostWrapper, StyledSectionTitle } from '../styled';
import { hasCommunityOrGroupAdminPermissions } from 'utils';

interface EmailModuleMessageProps
  extends Partial<Pick<UseFormMethods<EmailModuleFormValues>, 'register'>> {
  attachmentsFieldProps: ControllerRenderProps<EmailModuleFormValues>;
  errors: DeepMap<EmailModuleFormValues, FieldError>;
  setError: (name: string, error: ErrorOption) => void;
  documentation: Document[];
  sending: boolean;
  sendToMe: boolean;
  onRemoveDocument: (document: Document) => void;
  setSelectedParticipants: (data: string[]) => void;
  selectedRecipients: string[];
  onDocumentsAttach: (documents: Document[]) => void;
  submitForm: () => void;
  onSendToMeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnWrapperClick: (e: MouseEvent) => void;
}

const EmailModuleMessage: FC<EmailModuleMessageProps> = ({
  attachmentsFieldProps,
  errors,
  setError,
  register,
  documentation,
  sending,
  sendToMe,
  onRemoveDocument,
  setSelectedParticipants,
  selectedRecipients,
  onDocumentsAttach,
  submitForm,
  onSendToMeChange,
  handleOnWrapperClick,
}) => {
  const { t } = useTranslation();
  const [documentsPopupOpen, setDocumentsPopupOpen] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const community = useSelector(getCurrentCommunity);

  const sender = `${getProfileName(currentUser.profile)} ${
    currentUser.profile.email ? '(' + currentUser.profile.email + ')' : ''
  }`;

  const getUsersValues = (data) => {
    return data.map((id) => ({
      value: id,
      label: `${getProfileName(
        community.users.find((i) => i.id === id)?.profile || {},
      )} (${community.users.find((i) => i.id === id)?.profile?.email})`,
    }));
  };

  const getUsersOptions = (arr) =>
    arr.map((u) => ({
      value: u.id,
      label: `${getProfileName(u.profile)} (${u.profile?.email})`,
    }));

  const handleRecipientsChange = (values) => {
    const ids = values.map((i) => i.value);

    setSelectedParticipants(ids);
  };

  return sender ? (
    <StyledMessageSection>
      {!hasCommunityOrGroupAdminPermissions(currentUser.id, community) && (
        <GridGhostWrapper onClick={(e) => handleOnWrapperClick(e)} />
      )}
      <DocumentationPopup
        isOpen={documentsPopupOpen}
        data={documentation}
        selectedDocuments={selectedDocuments}
        groups={community.groups}
        setSelectedDocuments={setSelectedDocuments}
        onRemoveDocument={onRemoveDocument}
        onSubmit={onDocumentsAttach}
        closePopup={() => setDocumentsPopupOpen(false)}
      />
      <StyledSectionTitle>{t('email.message')}</StyledSectionTitle>
      <StyledForm>
        <TextInput
          label={t('email.sender')}
          disabled={true}
          width="100%"
          defaultValue={sender}
        />
        <StyledCheckboxWrapper>
          <CheckBox
            label={t('email.sendCopy')}
            onChange={onSendToMeChange}
            isSelected={sendToMe}
          />
        </StyledCheckboxWrapper>
        <SelectInputControlledMulti
          name={FIELDS.RECIPIENTS}
          width="100%"
          label={t('email.recipients')}
          value={getUsersValues(selectedRecipients)}
          options={getUsersOptions(community.users)}
          onChange={handleRecipientsChange}
          error={errors[FIELDS.RECIPIENTS] && errors[FIELDS.RECIPIENTS][0]}
          closeMenuOnSelect={false}
        />
        <TextInput
          name={FIELDS.OTHER_RECIPIENTS}
          label={t('email.otherRecipients')}
          width="100%"
          register={register}
          error={
            errors[FIELDS.OTHER_RECIPIENTS] &&
            errors[FIELDS.OTHER_RECIPIENTS][0]
          }
        />
        <TextInput
          name={FIELDS.SUBJECT}
          label={t('email.subject')}
          width="100%"
          register={register}
          error={errors[FIELDS.SUBJECT]}
        />
        <TextArea
          name={FIELDS.MESSAGE}
          label={t('email.message')}
          width="100%"
          rows={15}
          register={register}
          error={errors[FIELDS.MESSAGE]}
        />
        <FileInputControlled
          text={t('documentation.attachFiles')}
          tipText={t('email.maxAttachmentsSize')}
          error={
            errors[FIELDS.ATTACHMENTS] && errors[FIELDS.ATTACHMENTS][0]?.message
          }
          setError={(e) =>
            setError(FIELDS.ATTACHMENTS, {
              type: 'required',
              message: e,
            })
          }
          value={attachmentsFieldProps.value}
          setValue={attachmentsFieldProps.onChange}
          maxSize={15 * 1024}
          width="100%"
          height="80px"
          isLoading={false}
          withPreview={true}
          allowMultiple={true}
        />
        <StyledButtonContainer>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setDocumentsPopupOpen(true)}
          >
            {t('email.attachCommunityDocs')}
          </Button>
        </StyledButtonContainer>
        <StyledButtonContainer>
          <Button
            type="button"
            variant={sending ? 'secondary' : null}
            onClick={() => {
              submitForm();
            }}
          >
            {sending ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('auth.send')
            )}
          </Button>
        </StyledButtonContainer>
      </StyledForm>
    </StyledMessageSection>
  ) : null;
};

export default React.memo(EmailModuleMessage);
