import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default styled(Link)`
  color: ${(props) => props.theme.color.brightTurquoise};
  text-decoration: none;

  &:visited {
    color: ${(props) => props.theme.brightTurquoise};
  }
`;
