import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ROUTES } from 'configs';
import { useCreateCommunityForm } from './form';
import { DEFAULT_RESPONSES } from 'constants/responses';
import { DEFAULT_EVENT_TYPES } from 'constants/eventTypes';
import { DEFAULT_TASKS } from 'constants/tasks';
import { doCreateCommunity } from 'store/actionCreators/communities';
import { closeModal } from 'store/actionCreators/modal';

export const useCreateCommunity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { handleSubmit, setValue, ...form } = useCreateCommunityForm();

  const onSuccess = (communityId) => {
    dispatch(closeModal());
    navigate(`${ROUTES.COMMUNITY}/${communityId}`);
    addToast(t('community.createSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = handleSubmit((data) => {
    const responses = DEFAULT_RESPONSES.map((response, index) => ({
      ...response,
      title: t(`communitySettings.defaultResponsesTypes.${index}`),
    }));

    const eventTypes = DEFAULT_EVENT_TYPES.map((eventType, index) => ({
      ...eventType,
      title: t(`communitySettings.defaultEventList.${index}`),
    }));

    const tasks = DEFAULT_TASKS.map((task, index) => ({
      ...task,
      title: t(`communitySettings.defaultTasks.${index}`),
    }));

    dispatch(
      doCreateCommunity({
        data: {
          ...data,
          responses,
          eventTypes,
          tasks,
        },
        onSuccess,
      }),
    );
  });

  return {
    onSubmit,
    setValue,
    ...form,
  };
};
