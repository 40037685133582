import styled from 'styled-components';

const getWidth = (width) => {
  if (width >= 376) {
    return width + 'px';
  }

  return '376px';
};

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: ${({ placement, width = 376 }) =>
    placement === 'right' ? getWidth(width) : 'unset'};
  z-index: 100000000;
`;

export const SidebarWrapper = styled.div`
  width: calc(100% - ${({ width = 376 }) => (width >= 376 ? width : 376)}px);
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0);
  left: ${({ placement, width = 376 }) =>
    placement === 'left' ? getWidth(width) : 'unset'};
  right: ${({ placement, width = 376 }) =>
    placement === 'right' ? getWidth(width) : 'unset'};
`;
