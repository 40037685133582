import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ContentWrapper, Tabs } from 'components';
import { CommunitiesList, MatrixList } from './components';
import { getCommunities } from 'store/selectors/communities';
import { getAuthUser } from 'store/selectors/auth';

import { Layout, Heading } from './styled';

const UserProfileCommunities = () => {
  const { t } = useTranslation();
  const communities = useSelector(getCommunities);
  const profile = useSelector(getAuthUser);

  return (
    <ContentWrapper minHeight="none">
      <Layout>
        <Layout.Header>
          <Heading>{t('profile.myCommunities')}</Heading>
        </Layout.Header>
        <Tabs>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div label={t('common.overview')} index={0}>
            <Layout.Inner>
              <Layout.Table>
                <Layout.Title>{t('communitySettings.owner')}</Layout.Title>
                <CommunitiesList
                  data={communities.filter(
                    (item) => profile?.userId === item.communityOwner.userId,
                  )}
                  userId={profile.userId}
                  withDelete={true}
                />
              </Layout.Table>
              <Layout.Table>
                <Layout.Title>{t('communitySettings.member')}</Layout.Title>
                <CommunitiesList
                  data={communities.filter(
                    (item) => profile?.userId !== item.communityOwner.userId,
                  )}
                  userId={profile.userId}
                  withLeave={true}
                />
              </Layout.Table>
            </Layout.Inner>
          </div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div label="Matrix" index={1}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <MatrixList />
          </div>
        </Tabs>
      </Layout>
    </ContentWrapper>
  );
};

export default UserProfileCommunities;
