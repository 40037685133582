import { put, takeLatest, select, call } from 'redux-saga/effects';
import { find } from 'lodash/fp';

import {
  doGetCurrentGroup,
  doGetGroupUsers,
  doGetTotal,
} from 'store/actionCreators/currentGroup';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getTotal } from 'services/groups';

function* onGetCurrentGroup({ payload }: ReturnType<typeof doGetCurrentGroup>) {
  try {
    const community = yield select(getCurrentCommunity);
    const group = find({ id: payload.groupId }, community.groups);
    if (group) {
      yield put(doGetCurrentGroup.success(group));
      yield put(doGetGroupUsers.trigger(payload));
    } else {
      throw Error;
    }
  } catch {
    yield put(doGetCurrentGroup.failure());
  }
}

function* onGetTotal({ payload }: ReturnType<typeof doGetTotal>) {
  try {
    const data = yield call(
      getTotal,
      payload.groupId,
      payload.from,
      payload.to,
    );
    yield put(doGetTotal.success(data));
  } catch ({ response }) {
    yield put(doGetTotal.failure(response.data));
  }
}

export default function* () {
  yield takeLatest(doGetCurrentGroup, onGetCurrentGroup);
  yield takeLatest(doGetTotal, onGetTotal);
}
