import { useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FIELDS, useEventMainInfoForm } from './form';
import { Community, Event } from 'types';
import { EditEventType } from '../../constants/sidebars';
import { getEventRequestBody } from './utils';
import { CreateEventStates } from '../../constants';
import {
  doCreateCommunityEvent,
  doCreateEvent,
  doUpdateCommunityEvents,
  doUpdateEvents,
} from 'store/actionCreators/currentCommunity';
import { getAllGroupMembers } from 'store/selectors/currentGroup';

export const useSubmitMainInfo = ({
  event,
  closeSidebar,
  filterFrom,
  filterTo,
  sidebarState,
  communityId,
  groupId,
  community,
}: {
  event: Event;
  closeSidebar: () => void;
  filterFrom: Date;
  filterTo: Date;
  sidebarState: CreateEventStates;
  communityId: string;
  groupId: string | null;
  community: Community;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const groupMembers = useSelector(getAllGroupMembers);

  const { handleSubmit, ...form } = useEventMainInfoForm({
    event,
  });

  useEffect(() => {
    if (groupId) {
      form.recipientsFieldProps.onChange(
        sidebarState === CreateEventStates.EVENT_CREATE
          ? groupMembers.map((u) => u.id)
          : event.recipients,
      );

      return;
    }

    form.recipientsFieldProps.onChange(
      sidebarState === CreateEventStates.EVENT_CREATE
        ? community.users.map((u) => u.id)
        : event.recipients,
    );
  }, [community.users, groupId]);

  useEffect(() => {
    if (form.errors[FIELDS.END_DATE]) {
      form.trigger(FIELDS.END_DATE);
    }

    const newWeekDays = form.weekDaysFieldProps.value?.map((day, index) => {
      if (index === (form.watchedValues[FIELDS.FROM] as Date)?.getDay()) {
        return {
          ...day,
          value: 1,
        };
      }

      return {
        ...day,
        value: 0,
      };
    });

    form.weekDaysFieldProps.onChange(newWeekDays);
  }, [form.watchedValues[FIELDS.FROM]]);

  const onCreateEventSuccess = () => {
    closeSidebar();
    addToast(t('event.createEventSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onUpdateEventSuccess = () => {
    closeSidebar();
    addToast(t('event.updateEventSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onFailure = (message) => {
    addToast(message, {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  const onSubmit = useCallback(
    (
      type: EditEventType,
      notificationsOption: { toNotify: boolean; notifyText?: string },
    ) => {
      handleSubmit((data) => {
        const requestBody = getEventRequestBody(data);
        const eventSeriesId = event?.eventSeriesId || null;

        if (sidebarState === CreateEventStates.EVENT_CREATE) {
          if (groupId) {
            dispatch(
              doCreateEvent({
                data: {
                  ...requestBody,
                  notify: notificationsOption,
                  eventSeriesId,
                },
                filterFrom,
                filterTo,
                groupId,
                onSuccess: onCreateEventSuccess,
                onFailure,
              }),
            );
          } else {
            dispatch(
              doCreateCommunityEvent({
                communityId,
                data: {
                  ...requestBody,
                  notify: notificationsOption,
                  eventSeriesId,
                },
                filterFrom,
                filterTo,
                onSuccess: onCreateEventSuccess,
                onFailure,
              }),
            );
          }
        } else {
          if (groupId && event?.groupId) {
            dispatch(
              doUpdateEvents({
                data: {
                  ...requestBody,
                  notify: notificationsOption,
                  eventSeriesId,
                  id: event?.id,
                },
                filterFrom,
                filterTo,
                groupId,
                editType: type,
                onSuccess: onUpdateEventSuccess,
                onFailure,
              }),
            );
          } else {
            dispatch(
              doUpdateCommunityEvents({
                data: {
                  ...requestBody,
                  notify: notificationsOption,
                  eventSeriesId,
                  id: event?.id,
                },
                communityId,
                filterFrom,
                filterTo,
                editType: type,
                onSuccess: onUpdateEventSuccess,
                onFailure,
              }),
            );
          }
        }
      })();
    },
    [event],
  );

  return {
    onSubmit,
    ...form,
  };
};
