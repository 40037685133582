import React, { FC } from 'react';
import { useLayer, Placement } from 'react-laag';
import { AnimatePresence } from 'framer-motion';

import { StyledTooltip } from './styled';

interface FilterTooltipProps {
  children: JSX.Element | JSX.Element[];
  isVisible?: boolean;
  position: Placement;
  tooltipStyle: { [key: string]: string | number };
  InnerComponent: any;
  arrowOffset?: number | null | undefined;
  closeTooltip: () => void;
}

const FilterTooltip: FC<FilterTooltipProps> = ({
  children,
  isVisible,
  position,
  tooltipStyle,
  InnerComponent,
  closeTooltip,
  arrowOffset,
}) => {
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isVisible,
    placement: position,
    triggerOffset: 10,
    arrowOffset,
    auto: true,
    onOutsideClick: closeTooltip,
    containerOffset: 20,
  });

  return (
    <>
      <div {...triggerProps}>{children}</div>
      {renderLayer(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isVisible && (
            <StyledTooltip
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              {...layerProps}
              style={{
                ...layerProps.style,
                ...tooltipStyle,
              }}
            >
              {typeof InnerComponent === 'function' ? (
                <InnerComponent />
              ) : (
                InnerComponent
              )}
            </StyledTooltip>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};

export default FilterTooltip;
