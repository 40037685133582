import styled from 'styled-components';

import { sharedStyles } from '../styled';

export const Button = styled.button`
  color: white;
  background: black;
  border-radius: 5px;
  ${sharedStyles}

  svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`;
