import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useEditCommunityTaskForm } from './form';
import { useInfoPopup } from 'hooks';
import { closeModal } from 'store/actionCreators/modal';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import {
  doCreateCommunityTask,
  doEditCommunityTask,
} from 'store/actionCreators/currentCommunity';
import { EventTask } from 'types';
import { useCallback } from 'react';

export const useEditCommunityTask = (eventTask: EventTask) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const { showInfoPopup } = useInfoPopup();

  const { handleSubmit, setValue, register, ...form } =
    useEditCommunityTaskForm(eventTask);

  const onSuccess = (title, message?: string) => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title,
      message,
      duration: 3000,
      noButton: true,
      loop: false,
    });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      handleSubmit((formData) => {
        const data = { ...formData, role: 'admin', maxParticipants: 999 };

        if (eventTask) {
          dispatch(
            doEditCommunityTask({
              communityId: community.id,
              data,
              id: eventTask.id,
              onSuccess: () =>
                onSuccess(
                  t('common.editSuccess.title'),
                  t('common.editSuccess.message'),
                ),
            }),
          );
        } else {
          dispatch(
            doCreateCommunityTask({
              communityId: community.id,
              data,
              onSuccess: () =>
                onSuccess(
                  t('common.createSuccess.title'),
                  t('common.createSuccess.message'),
                ),
            }),
          );
        }
      })();
    },
    [eventTask],
  );

  return {
    register,
    onSubmit,
    setValue,
    ...form,
  };
};
