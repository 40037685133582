import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const StyledPlaceholder = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: start;
  grid-template-columns: 30px auto;
  grid-column-gap: 17px;
  width: 100%;
`;

export const StyledLogoUpload = styled.img`
  grid-row: 1 / 3;
  width: 30px;
`;

export const StyledTextButton = styled.span`
  grid-row: 1 / 2;
  color: ${({ theme }) => theme.color.brightTurquoise};
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;

export const StyledTipText = styled.span`
  grid-row: 2 / 3;
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 10px;
  line-height: 20px;
`;
