import { createRoutine } from 'redux-saga-routines';

import { EventFormData } from 'sidebars/CreateEventOverall/form';
import { EditEventType } from 'constants/sidebars';
import { EventType } from 'types';

export const doRequestEvents = createRoutine('REQUEST_EVENTS', {
  trigger: (payload: {
    groupId: string;
    from: Date;
    to: Date;
    eventTypeId: string;
    filterValue: string;
    page: number;
    limit: number;
  }) => payload,
});

export const doRequestOverallEvents = createRoutine('REQUEST_OVERALL_EVENTS', {
  trigger: (payload: {
    communityId: string;
    from: Date;
    to: Date;
    eventTypeId: string;
    filterValue: string;
    page: number;
    limit: number;
  }) => payload,
});

export const doGetMailEvents = createRoutine('GET_MAIL_EVENTS', {
  trigger: (payload: {
    communityId: string;
    from: string;
    eventTypeId: string;
    eventsFromGroups: string[];
    key?: string;
    page: number;
    limit: number;
  }) => payload,
});

export const doCreateEvent = createRoutine('CREATE_EVENT', {
  trigger: (payload: {
    data: EventFormData & {
      notify: { toNotify: boolean; notifyText?: string };
      eventSeriesId: string;
    };
    groupId: string;
    filterFrom: Date;
    filterTo: Date;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doUpdateEvents = createRoutine('UPDATE_EVENTS', {
  trigger: (payload: {
    data: EventFormData & {
      notify: { toNotify: boolean; notifyText?: string };
      eventSeriesId: string;
      id: string;
    };
    editType: EditEventType;
    groupId: string;
    filterFrom: Date;
    filterTo: Date;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doDeleteEvents = createRoutine('DELETE_EVENTS', {
  trigger: (payload: {
    editType: EditEventType;
    groupId: string;
    eventId: string;
    notify: { toNotify: boolean; notifyText?: string };
    from: string;
    eventSeriesId: string;
    onSuccess: () => void;
    onFailure?: (message: string) => void;
  }) => payload,
});

export const doCreateCommunityEvent = createRoutine('CREATE_COMMUNITY_EVENT', {
  trigger: (payload: {
    data: EventFormData & {
      notify: { toNotify: boolean; notifyText?: string };
      eventSeriesId: string;
    };
    communityId: string;
    filterFrom: Date;
    filterTo: Date;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doUpdateCommunityEvents = createRoutine(
  'UPDATE_COMMUNITY_EVENTS',
  {
    trigger: (payload: {
      data: EventFormData & {
        notify: { toNotify: boolean; notifyText?: string };
        eventSeriesId: string;
        id: string;
      };
      editType: EditEventType;
      communityId: string;
      filterFrom: Date;
      filterTo: Date;
      onSuccess: () => void;
      onFailure: (message: string) => void;
    }) => payload,
  },
);

export const doDeleteCommunityEvents = createRoutine(
  'DELETE_COMMUNITY_EVENTS',
  {
    trigger: (payload: {
      editType: EditEventType;
      communityId: string;
      eventId: string;
      notify: { toNotify: boolean; notifyText?: string };
      from: string;
      eventSeriesId: string;
      onSuccess: () => void;
      onFailure?: (message: string) => void;
    }) => payload,
  },
);

export const doClearOverallEvents = createRoutine('CLEAR_COMMUNITY_EVENTS');

export const doClearGroupEvents = createRoutine('CLEAR_GROUP_EVENTS');

export const doClearMailEvents = createRoutine('CLEAR_MAIL_EVENTS');

export const doClearEventUploadStatus = createRoutine(
  'CLEAR_CLEAR_EVENT_UPLOAD_STATUAS',
);

export const doCreateBulkEvents = createRoutine('CREATE_BULK_EVENTS', {
  success: (payload: {
    uploadEventsCount: number;
    newEventTypeList: Array<EventType>;
    uploadStatus: 'completed';
  }) => payload,
});

export const doDeleteBulkEvents = createRoutine('DELTE_BULK_EVENTS', {
  trigger: (payload: {
    communityId: string;
    groupId?: string;
    onSuccess: () => void;
  }) => payload,
});

export const doGetEventUploadStatus = createRoutine(
  'GET_BULK_EVENT_UPLOAD_STATUS',
  {
    trigger: (payload: { communityId: string; groupId?: string }) => payload,
    success: (payload: {
      uploadEventsCount: number;
      uploadStatus: 'completed' | '';
    }) => payload,
  },
);

export const doResetUploadStatus = createRoutine('RESET_BULK_UPLOAD_STATUS', {
  success: (payload: { uploadStatus: '' }) => payload,
});
