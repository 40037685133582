import styled from 'styled-components';

import { breakpoints } from 'theme';

export const Container = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-right: 54px;

    @media ${breakpoints.downSmPlus} {
      margin-right: 30px;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Counter = styled.span`
  color: ${({ theme }) => theme.color.white};
  font-size: 22px;
  line-height: 32px;
  text-align: left;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  line-height: 20px;
  text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
`;

export const StatusIndicatorWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 7px;
`;

export const StatusIndicator = styled.div`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme, variant }) =>
    variant === 'primary' ? theme.color.brightTurquoise : theme.color.casper};
`;
