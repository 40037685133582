import styled from 'styled-components';

import { LogoutIcon } from 'static';
import { breakpoints } from 'theme';

export const defaultButtonStyles = () => `
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
`;

export const HeaderContainer = styled.div`
  min-width: 300px;
  display: flex;
  position: sticky;
  top: 0px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 27px;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  width: calc(100vw - 360px);
  z-index: 901;
  @media ${breakpoints.downMd} {
    padding-right: 50px;
    width: 100%;
  }
`;

export const Headline = styled.h1`
  font-size: 16px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoints.downMd} {
    padding-right: 15px;
  }
`;

export const StyledLogoutIcon = styled(LogoutIcon)`
  path {
    fill: ${({ theme }) => theme.color.baliHai};
  }
`;

export const LogoutButton = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 12px 18px;
  transition: color, fill, background-color 0.15s ease-in-out;
  border-radius: 36px;
  background: unset;

  &:hover {
    ${StyledLogoutIcon} {
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }
    background-color: #ebf2f7;
  }
`;
