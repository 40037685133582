import { all } from 'redux-saga/effects';

import communitiesSaga from './communities';
import profileSaga from './profile';
import authSaga from './auth';
import chatSaga from './chat';
import groupSaga from './groups';
import subscriptionsSaga from './subscriptions';
import {
  eventsSaga,
  eventRepliesSaga,
  responsesSaga,
  tasksSaga,
  currentCommunitySaga,
  documentsSaga,
  requestsSaga,
  usersSaga,
  pushNotificationSaga,
  defaultEventSettingSaga,
} from './currentCommunity';
import { currentGroupSaga, groupUsersSaga } from './currentGroup';
import statisticsSaga from './statistics';

export default function* rootSaga() {
  yield all([
    communitiesSaga(),
    profileSaga(),
    authSaga(),
    chatSaga(),
    groupSaga(),
    requestsSaga(),
    eventsSaga(),
    eventRepliesSaga(),
    responsesSaga(),
    tasksSaga(),
    documentsSaga(),
    usersSaga(),
    currentCommunitySaga(),
    currentGroupSaga(),
    groupUsersSaga(),
    subscriptionsSaga(),
    statisticsSaga(),
    pushNotificationSaga(),
    defaultEventSettingSaga(),
  ]);
}
