import { createRoutine } from 'redux-saga-routines';
import { Moment } from 'moment';

export const doGetAppointmentScheduleStatistics = createRoutine(
  'GET_APPOINTMENT_SCHEDULE_STATISTICS',
  {
    trigger: (payload: {
      communityId: string;
      from: Moment;
      to: Moment;
      eventTypeIds?: string[];
      groupIds?: string[];
      visibleColumns: string[];
      page: number;
      limit: number;
      excludeCommunityEvents?: boolean;
    }) => payload,
  },
);

export const doGetAppointmentScheduleStatisticsTotal = createRoutine(
  'GET_APPOINTMENT_SCHEDULE_STATISTICS_TOTAL',
  {
    trigger: (payload: {
      communityId: string;
      from: Moment;
      to: Moment;
      eventTypeIds?: string[];
      groupIds?: string[];
      visibleColumns: string[];
      excludeCommunityEvents?: boolean;
    }) => payload,
  },
);

export const doGetMemberFiguresStatistics = createRoutine(
  'GET_MEMBER_FIGURES_STATISTICS',
  {
    trigger: (payload: {
      communityId: string;
      from: Moment;
      to: Moment;
      eventTypeIds?: string[];
      groupIds?: string[];
      memberIds?: string[];
      excludeCommunityEvents?: boolean;
      page: number;
      limit: number;
    }) => payload,
  },
);

export const doGetMemberFiguresStatisticsTotal = createRoutine(
  'GET_MEMBER_FIGURES_STATISTICS_TOTAL',
  {
    trigger: (payload: {
      communityId: string;
      from: Moment;
      to: Moment;
      eventTypeIds?: string[];
      groupIds?: string[];
      memberIds?: string[];
      excludeCommunityEvents?: boolean;
      dispatch: any;
    }) => payload,
  },
);

export const doClearStatistics = createRoutine('CLEAR_STATISTICS');
export const doClearMemberFiguresStatisticsTotal = createRoutine(
  'CLEAR_MEMBER_FIGURES_STATISTICS_TOTAL',
);
