import React, { useState, useEffect } from 'react';
import { OsTypes, useDeviceData } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { AppStoreIcon, GooglePlayIcon } from 'static';

import { StyledWidget, CloseButton } from './styled';

const AppWidget = () => {
  const { t } = useTranslation();
  const [isDisplayed, setIsDisplayed] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const deviceData = useDeviceData();

  useEffect(() => {
    if (
      deviceData?.os?.name === OsTypes.Android ||
      deviceData?.os?.name === OsTypes.IOS
    ) {
      setTimeout(() => {
        setIsDisplayed(true);
      }, 2000);
    }
  }, []);

  const handleClose = () => {
    setIsDisplayed(false);
  };

  return (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
    /* @ts-ignore*/
    <AnimatePresence>
      {isDisplayed && (
        <StyledWidget
          initial={{ bottom: -133 }}
          animate={{ bottom: -23 }}
          exit={{ bottom: -133 }}
          transition={{
            duration: 0.4,
            type: 'spring',
            stiffness: 700,
            damping: 30,
          }}
        >
          <div>
            <p>{t('appWidget')}</p>
            {deviceData?.os?.name === OsTypes.IOS ? (
              <a
                href="https://apps.apple.com/us/app/gumb/id1534275726"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppStoreIcon />
              </a>
            ) : null}
            {deviceData?.os?.name === OsTypes.Android ? (
              <a
                href="https://play.google.com/store/apps/details?id=com.gumb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GooglePlayIcon />
              </a>
            ) : null}
            <CloseButton onClick={handleClose}>&#10060;</CloseButton>
          </div>
        </StyledWidget>
      )}
    </AnimatePresence>
  );
};

export default AppWidget;
