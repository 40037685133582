import { createRoutine } from 'redux-saga-routines';

export const doGetDocumentsOverview = createRoutine('GET_DOCUMENTS_OVERVIEW', {
  trigger: (payload: string) => payload,
});

export const doGetDocuments = createRoutine('GET_GROUP_DOCUMENTS', {
  trigger: (payload: string) => payload,
});

export const doUploadDocument = createRoutine('UPLOAD_GROUP_DOCUMENT', {
  trigger: (payload: {
    data: { document: File };
    communityId: string;
    groupId: string;
    onSuccess: () => void;
  }) => payload,
});

export const doDeleteDocument = createRoutine('DELETE_GROUP_DOCUMENT', {
  trigger: (payload: {
    communityId: string;
    documentId: string;
    onSuccess: () => void;
  }) => payload,
});
