import { put, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { openModal } from 'store/actionCreators/modal';
import { POPUP_MESSAGE } from 'constants/popups';
import { isCurrentUserOwner } from 'store/selectors/currentCommunity';
import { countries, zones } from 'moment-timezone/data/meta/latest.json';

export function* checkSubscription(
  subscriptionActiveSelector,
  subscriptionPendingSelector,
) {
  const isSubscriptionActive = yield select(subscriptionActiveSelector);
  if (!isSubscriptionActive) {
    const isSubscriptionPending = yield select(subscriptionPendingSelector);
    const isOwner = yield select(isCurrentUserOwner);
    if (isSubscriptionPending) {
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('subscriptions.inactiveSubscriptionHeader'),
            popupMessageText: i18n.t(
              isOwner
                ? 'subscriptions.inactiveSubscriptionMessage'
                : 'subscriptions.contactAdmin',
            ),
            popupButtonText: 'Ok',
          },
        }),
      );
    } else {
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('subscriptions.subscriptionNotPaid'),
            doClickOutSide: false,
            popupMessageText: i18n.t(
              isOwner
                ? 'subscriptions.limitExceededMessageOwner'
                : 'subscriptions.contactAdmin',
            ),
            popupButtonText: 'Ok',
          },
        }),
      );
    }
    throw new Error('Inactive subscription');
  }
}

export function* getCountryNameFromTimezone() {
  const cityToCountryTimezone = new Object();
  let countryName: string;
  const timezone = yield Intl.DateTimeFormat().resolvedOptions().timeZone; //get user timezone
  const timezoneData = timezone.split('/');
  const userCity = timezoneData[timezoneData.length - 1];
  try {
    Object.keys(zones).forEach((zone) => {
      const cityZone = zone.split('/');
      const city = cityZone[cityZone.length - 1]; //the last element is the city name.
      const countryKey = zones[zone].countries[0];
      cityToCountryTimezone[city] = countries[countryKey].name;
    });
    countryName = cityToCountryTimezone[userCity]
      ? cityToCountryTimezone[userCity]
      : 'Switzerland';
  } catch (err) {
    countryName = 'Switzerland';
  }

  return countryName;
}
