export const DEFAULT_EVENT_TYPES = [
  {
    title: 'Meeting',
    color: '#FF6900',
    isResponseRequired: false,
  },
  {
    title: 'Training',
    color: '#0693E3',
    isResponseRequired: false,
  },
  {
    title: 'Game',
    color: '#EB144C',
    isResponseRequired: false,
  },
  {
    title: 'Rehearsal',
    color: '#8ED1FC',
    isResponseRequired: false,
  },
  {
    title: 'Event',
    color: '#F78DA7',
    isResponseRequired: false,
  },
  {
    title: 'Board meeting',
    color: '#FCB900',
    isResponseRequired: false,
  },
];
