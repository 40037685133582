import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { doDeleteCommunity } from 'store/actionCreators/communities';
import { getProfileName } from 'utils/common';
import { ROUTES, PROFILE_ROUTES } from 'configs';
import {
  Loader,
  DefaultCommunityImage,
  ProfileName,
  PopupButtonMessage,
} from 'components';
import { VIEW_MEMBER } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';
import {
  getCommunitiesLoading,
  getDeleteCommunityLoading,
} from 'store/selectors/communities';
import { doDeleteCommunityUser } from 'store/actionCreators/currentCommunity';
import { isDeleteCommunityUserLoading } from 'store/selectors/currentCommunity';
import { CommunityListItem } from 'types';

import { List, Community, StyledFloatingLoader, ListWrapper } from './styled';

interface CommunitiesListProps {
  data: CommunityListItem[];
  userId: string;
  withDelete?: boolean;
  withLeave?: boolean;
}

const CommunitiesList: FC<CommunitiesListProps> = ({
  data,
  withDelete,
  withLeave,
  userId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const isFetching = useSelector(getCommunitiesLoading);
  const isDeletingUser = useSelector(isDeleteCommunityUserLoading);
  const isDeletingCommunity = useSelector(getDeleteCommunityLoading);

  const successMessage = (message) =>
    addToast(message, {
      appearance: 'success',
      autoDismiss: true,
    });

  const redirectToCommunity = (id: string) => {
    navigate(`${ROUTES.COMMUNITY}/${id}`);
  };

  const handleDeleteCommunity = (id) => {
    dispatch(
      doDeleteCommunity({
        data: id,
        onSuccess: () => successMessage(t('community.deleteSuccess')),
      }),
    );

    navigate(`${ROUTES.PROFILE}/${PROFILE_ROUTES.COMMUNITIES}`);
  };

  const handleLeaveFromCommunity = (id) => {
    dispatch(
      doDeleteCommunityUser({
        communityId: id,
        onSuccess: () => successMessage(t('community.leaveSuccess')),
        userIds: [userId],
        isCurrentUser: true,
      }),
    );
  };

  return (
    <ListWrapper>
      {(isDeletingUser || isDeletingCommunity) && (
        <StyledFloatingLoader>
          <Loader size="60px" type={null} />
        </StyledFloatingLoader>
      )}
      <List>
        <List.Head>
          <tr>
            <List.Head.Cell>
              {t('profileCommunities.table.labelCommunity')}
            </List.Head.Cell>
            <List.Head.Cell>{t('communitySettings.owner')}</List.Head.Cell>
            <List.Head.Cell>{t('common.groups')}</List.Head.Cell>
            <List.Head.Cell>{t('community.members')}</List.Head.Cell>
            <List.Head.Cell />
          </tr>
        </List.Head>
        <tbody>
          {data.map((item) => (
            <List.Row key={item.id}>
              <List.Row.Cell>
                <Community>
                  <Community.Wrapper
                    onClick={() => redirectToCommunity(item.id)}
                  >
                    <Community.Logo>
                      {!item.logo ? (
                        <DefaultCommunityImage title={item.name} />
                      ) : (
                        <Community.Logo.Image src={item.logo} />
                      )}
                    </Community.Logo>
                    <Community.Name>{item.name}</Community.Name>
                  </Community.Wrapper>
                </Community>
              </List.Row.Cell>
              <List.Row.Cell>
                <ProfileName
                  name={getProfileName(item.communityOwner)}
                  avatar={
                    item.communityOwner.smallLogo || item.communityOwner.logo
                  }
                  onClick={() => {
                    dispatch(
                      openModal({
                        type: VIEW_MEMBER,
                        placement: 'right',
                        data: { profile: item.communityOwner },
                      }),
                    );
                  }}
                />
              </List.Row.Cell>
              <List.Row.Cell>{item.groupsCount || 0}</List.Row.Cell>
              <List.Row.Cell>{item.usersCount || 0}</List.Row.Cell>
              {withDelete && (
                <List.Row.Cell>
                  <PopupButtonMessage
                    popupButtonText={t('common.delete')}
                    popupHeaderText={t(
                      'communitySettings.deleteCommunityHeader',
                    )}
                    onConfirm={() => handleDeleteCommunity(item.id)}
                  />
                </List.Row.Cell>
              )}
              {withLeave && (
                <List.Row.Cell>
                  <PopupButtonMessage
                    popupButtonText={t(
                      'profileCommunities.leaveCommunity.okButton',
                    )}
                    cancelButtonText={t(
                      'profileCommunities.leaveCommunity.cancelButton',
                    )}
                    popupHeaderText={t(
                      'profileCommunities.leaveCommunity.title',
                    )}
                    popupMessageText={t(
                      'profileCommunities.leaveCommunity.message',
                    )}
                    size="lg"
                    ButtonComponent={Community.Icon}
                    onConfirm={() => handleLeaveFromCommunity(item.id)}
                  />
                </List.Row.Cell>
              )}
            </List.Row>
          ))}
        </tbody>
      </List>
      {isFetching && (
        <List.Loader>
          <Loader type="button" size="28px" thickness={2} />
        </List.Loader>
      )}
    </ListWrapper>
  );
};

export default CommunitiesList;
