export enum SOCIAL_NETWORKS {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
}

export const socialNetwork = [
  'facebook',
  'twitter',
  'linkedin',
  'instagram',
  'youtube',
];
