import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader } from 'components';
import ChatComponent from 'components/Chat';
import { doSetCurrentChat } from 'store/actionCreators/chat';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';
import {
  getClient,
  getCurrentChat,
  getChatsAuthenticated,
} from 'store/selectors/chat';

import { ChatWrapper } from './styled';

const ChatContainer = () => {
  const { communityId, chatId } = useParams();
  const dispatch = useDispatch();
  const channel = useSelector(getCurrentChat);
  const client = useSelector(getClient);
  const isAuth = useSelector(getChatsAuthenticated);
  const isMenuOpen = useSelector(getIsMenuOpen);

  useEffect(() => {
    isAuth && dispatch(doSetCurrentChat({ communityId, chatId }));
  }, [communityId, chatId, isAuth, doSetCurrentChat]);

  return channel ? (
    <ChatWrapper isMenuOpen={isMenuOpen}>
      <ChatComponent channel={channel} client={client} />
    </ChatWrapper>
  ) : (
    <Loader />
  );
};

export default ChatContainer;
