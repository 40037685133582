import styled from 'styled-components';

import { ResetIcon } from 'static';

export const StyledIcon = styled(ResetIcon)`
  user-select: none;
`;

export const StyledButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.color.baliHai};
  display: flex;
  align-items: center;

  & > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 32px;
    text-transform: uppercase;
    margin-left: 10px;
  }

  &:hover {
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }
    span {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  &:active {
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.brightTurquoise};
      }
    }
    span {
      color: ${({ theme }) => theme.color.brightTurquoise};
    }
  }

  &:disabled {
    opacity: 40%;
  }
`;
