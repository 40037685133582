import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ICredentials } from 'aws-amplify/lib/Common/types/types';

export const logInGoogle = (): Promise<ICredentials> => {
  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });
};

export const logInFacebook = (): Promise<ICredentials> => {
  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook,
  });
};

export const logInApple = (): Promise<ICredentials> => {
  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Apple,
  });
};

export const getLinkParams = (
  link: string,
): {
  code: string;
  userName: string;
} => {
  const search = new URLSearchParams(link);
  const code = search.get('c');
  const userName = search.get('u');

  return {
    code,
    userName,
  };
};
