import styled from 'styled-components';
import { DeleteIcon } from 'static';

export const StyledDeleteIcon = styled(DeleteIcon)`
  height: 13px;
  width: 11px;
`;

export const DeleteImageButton = styled.button`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: 25px;
  width: 25px;
  position: absolute;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.color.dew};
  border-radius: 50%;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.15);
  bottom: 3px;
  left: 74px;
  cursor: pointer;

  &:hover ${StyledDeleteIcon} {
    path {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

export const LabelWrapper = styled.label`
  width: 100%;
  display: flex;
  user-select: none;
  position: relative;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 12px;
`;

export const TipText = styled.span`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 10px;
  line-height: 20px;
`;

export const TextButton = styled.span`
  color: ${({ theme }) => theme.color.brightTurquoise};
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.pickledBluewood};
  }
`;
