import { useCallback, useEffect, RefObject } from 'react';

interface UseClickOutsideProps {
  ref: RefObject<HTMLElement> | null;
  callback: () => void;
}

const useClickOutsideClose = ({ ref, callback }: UseClickOutsideProps) => {
  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const el = document.querySelector('#popUpWrapper');
      const elNotification = document.querySelector(
        '.react-toast-notifications__container',
      );
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        if (
          (el && el.contains(event.target as Node)) ||
          (elNotification && elNotification.contains(event.target as Node))
        ) {
          return;
        } else {
          callback();
        }
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    const handleOutsideClickCallback = (event: MouseEvent) =>
      handleOutsideClick(event);

    if (ref && ref.current) {
      document.addEventListener('mousedown', handleOutsideClickCallback);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClickCallback);
    };
  }, [ref, handleOutsideClick]);
};

export default useClickOutsideClose;
