import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { SharedStyledDatePicker, SharedStyledPickerWrapper } from '../styled';

export const StyledPickerWrapper = styled(SharedStyledPickerWrapper)`
  width: 100%;

  .react-datepicker-popper {
    z-index: 100;
    width: 100%;
    min-width: 260px;
    left: auto;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .react-datepicker__navigation {
    top: 0px;
  }
  .react-datepicker__navigation--previous {
    left: 0px;
  }
  .react-datepicker__navigation--next {
    right: 0px;
  }

  .react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .react-datepicker__header {
    padding-top: 0px;
    background-color: white;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__month {
    margin: 0px;
    margin-top: 0.4rem;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day {
    border-radius: 50%;
    transition: all 0.1s ease;
    font-family: 'Open Sans';
    :hover {
      background-color: ${({ theme }) => theme.color.brightTurquoise};
      color: ${({ theme }) => theme.color.white};
    }
  }
  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.color.lightGrey};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.color.brightTurquoise};
    color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledDatePicker = styled(SharedStyledDatePicker)`
  width: 100%;
`;

export const StyledCalendarWrapper = styled.div`
  height: '100%';
  padding: '16px';
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-radius: '10px';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  gap: '8px';
  box-shadow: '0px 0px 8px 2px #cfcfcf';
`;

export const StyledCalendarContainer = styled.div`
  border-radius: 8px;
  border-color: ${({ theme }) => theme.color.white};
  width: 100%;
  box-shadow: '0px 0px 8px 2px #cfcfcf';
`;
