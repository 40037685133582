import React, { FC, useCallback } from 'react';

import { LogoUploadPlaceholder } from 'static';
import { ImageWrapper, StyledImage } from './styled';

interface ImageBoxProps {
  src: string;
  placeholder?: string;
  errorMessage?: string;
  image: string;
}

const ImageBox: FC<ImageBoxProps> = ({
  src,
  placeholder = LogoUploadPlaceholder,
  errorMessage,
  image,
}) => {
  const handleImageError = useCallback(
    (e) => {
      e.target.src = placeholder;
    },
    [placeholder],
  );

  return (
    <ImageWrapper isError={errorMessage}>
      <StyledImage
        image={image}
        src={src || placeholder}
        onError={handleImageError}
        alt="ImageBox"
      />
    </ImageWrapper>
  );
};

export default ImageBox;
