import React, { FC, useEffect, useMemo, useState } from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/ru';

import { getEventResponses } from 'store/selectors/eventResponses';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { sliceIntoChunks } from '../utils';

import { ChartBox, ChartBoxDimmer } from '../styled';
import { ChartsWrapper, ChartsRow } from './styled';

const ANIMATION_DURATION = 1000;

//TODO: remove any

interface AppointmentScheduleChartsProps {
  total: any;
  totalLoading: boolean;
  selectedMembers: string[];
}

const MemberFiguresCharts: FC<AppointmentScheduleChartsProps> = ({
  total,
  totalLoading,
  selectedMembers,
}) => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const eventResponses = useSelector(getEventResponses) || [];
  const [
    eventRepliesChartLoadersDatasets,
    setEventRepliesChartLoadersDatasets,
  ] = useState([]);
  const [eventRepliesChartDatasets, setEventRepliesChartDatasets] = useState(
    [],
  );
  const [loaderInterval, setLoaderInterval] = useState(null);

  useEffect(() => {
    if (totalLoading && !total?.data && !loaderInterval) {
      const interval = setInterval(() => {
        const dividedData = sliceIntoChunks(selectedMembers);

        setEventRepliesChartLoadersDatasets(
          dividedData.map((i) =>
            eventResponses
              .map((r) => ({
                label: r.title,
                data: Array(i.length)
                  .fill(0)
                  .map(() => parseInt((Math.random() * 100).toFixed(0), 10)),
                backgroundColor: r.color,
              }))
              .concat([
                {
                  label: t('event.awaiting'),
                  data: Array(i.length)
                    .fill(0)
                    .map(() => parseInt((Math.random() * 100).toFixed(0), 10)),
                  backgroundColor: '#8298ab',
                },
              ]),
          ),
        );
      }, ANIMATION_DURATION);

      setLoaderInterval(interval);
    } else if (totalLoading && total?.data) {
      const dividedData = sliceIntoChunks(total.data);

      setEventRepliesChartDatasets([
        ...dividedData.map((i) =>
          eventResponses
            .map((r) => ({
              label: r.title,
              data: i.map((u) => u.eventResponsesCount[r.id] || 0),
              backgroundColor: r.color,
            }))
            .concat([
              {
                label: t('event.awaiting'),
                data: i.map((u) => u.eventResponsesCount.awaiting || 0),
                backgroundColor: '#8298ab',
              },
            ]),
        ),
        ...eventRepliesChartLoadersDatasets.slice(dividedData.length),
      ]);
    } else if (!totalLoading && total?.data && loaderInterval) {
      clearInterval(loaderInterval);
      setLoaderInterval(null);
      setEventRepliesChartLoadersDatasets([]);

      const dividedData = sliceIntoChunks(total.data);

      setEventRepliesChartDatasets(
        dividedData.map((i) =>
          eventResponses
            .map((r) => ({
              label: r.title,
              data: i.map((u) => u.eventResponsesCount[r.id] || 0),
              backgroundColor: r.color,
            }))
            .concat([
              {
                label: t('event.awaiting'),
                data: i.map((u) => u.eventResponsesCount.awaiting || 0),
                backgroundColor: '#8298ab',
              },
            ]),
        ),
      );
    }
  }, [
    total,
    totalLoading,
    eventResponses,
    loaderInterval,
    selectedMembers,
    eventRepliesChartLoadersDatasets,
  ]);

  const eventRepliesChartLabels = useMemo(() => {
    if (totalLoading) {
      return sliceIntoChunks(
        selectedMembers.map(
          (i) => i.substring(0, 15) + (i.length > 15 ? '...' : ''),
        ),
      );
    }

    return sliceIntoChunks(
      total?.data?.map(
        (u) =>
          u.profileName.substring(0, 15) +
          (u.profileName.length > 15 ? '...' : ''),
      ) || [],
    );
  }, [community, eventResponses, totalLoading, total, selectedMembers]);

  const eventTypesChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('statistics.charts.memberPresence'),
      },
    },
    maintainAspectRatio: false,
    animation: {
      duration: ANIMATION_DURATION,
      easing: 'linear',
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: (value) => (value % 1 === 0 ? value : null),
        },
      },
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };

  return (
    <ChartsWrapper>
      {eventRepliesChartLabels.map((i, ind) => (
        <ChartsRow key={ind}>
          <ChartBox>
            {totalLoading &&
              ind >= (sliceIntoChunks(total?.data || [])?.length || 0) && (
                <ChartBoxDimmer>
                  <div>{t('statistics.loadingStatistics')}</div>
                </ChartBoxDimmer>
              )}
            <Bar
              height={400}
              data={{
                labels: i,
                datasets: total?.data
                  ? eventRepliesChartDatasets[ind] || []
                  : eventRepliesChartLoadersDatasets[ind] || [],
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              options={eventTypesChartOptions}
            />
          </ChartBox>
        </ChartsRow>
      ))}
    </ChartsWrapper>
  );
};

export default MemberFiguresCharts;
