import styled from 'styled-components';

import { sharedStyles } from '../styled';

export const Button = styled.button`
  color: rgb(0 0 0 / 54%);
  background: white;
  box-shadow: 0 1px 3px 1px #00000036;
  border-radius: 3px;
  ${sharedStyles}

  svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`;
