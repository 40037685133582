import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;

export const getCreateEventReplyLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.createEventReply,
);

export const getUpdateEventReplyLoading = createSelector(
  currentCommunitySelector,
  (community) => community.loaders.updateEventReply,
);
