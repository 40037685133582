import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import DynamicInputs from 'components/Inputs/DynamicInputs';
import { categories } from 'constants/categories';
import {
  SelectInput,
  TextArea,
  Button,
  ImageInput,
  CoverInput,
  Loader,
  PlusButton,
} from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { editForm } from 'store/actionCreators/modal';
import { getUpdateCommunityLoading } from 'store/selectors/communities';
import { useGeneralCommunitySettings } from './hooks';
import { FIELDS } from './types';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getPattern, socialFieldToLabel } from './utils';

import {
  StyledWrapper,
  StyledCoverInputBlock,
  StyledLogoInputBlock,
  DynamicInputsBlock,
  StyledButtonBlock,
  StyledPlusBlock,
  WebsiteFieldWrapper,
} from './styled';

const SettingsGeneral = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const isLoading = useSelector(getUpdateCommunityLoading);

  const {
    errors,
    onSubmit,
    register,
    setValue,
    unregister,
    openAddFieldSidebar,
    deletePartnership,
    linksFieldProps,
    partnershipsFieldProps,
    setCoverDetail,
  } = useGeneralCommunitySettings(community);

  useEffect(() => {
    dispatch(editForm(false));
  }, []);

  const onChangeForm = () => {
    dispatch(editForm(true));
  };

  const onSubmitForm = (event) => {
    onSubmit(event);
    dispatch(editForm(false));
    setCoverDetail({ filename: '', delete: false });
  };

  return (
    <StyledWrapper>
      <form onSubmit={onSubmitForm} onChange={onChangeForm}>
        <StyledCoverInputBlock>
          <CoverInput
            name={FIELDS.COVER}
            text={t('community.chooseCover')}
            tipText={t('community.chooseCoverTip')}
            register={register}
            unregister={unregister}
            setValue={setValue}
            defaultValue={community.cover}
            setCoverDetail={setCoverDetail}
          />
        </StyledCoverInputBlock>
        <StyledLogoInputBlock>
          <ImageInput
            register={register}
            unregister={unregister}
            setValue={setValue}
            name={FIELDS.LOGO}
            defaultValue={community.logo}
          />
        </StyledLogoInputBlock>
        <TextInput
          register={register}
          name={FIELDS.COMMUNITY_NAME}
          width="100%"
          label={t('community.communityName')}
          error={errors[FIELDS.COMMUNITY_NAME]}
        />
        <SelectInput
          options={categories}
          name={FIELDS.CATEGORY}
          width="100%"
          label={t('community.category')}
          register={register}
          setValue={setValue}
          defaultValue={find({ value: community.category }, categories)}
          error={errors[FIELDS.CATEGORY]}
        />
        <TextArea
          register={register}
          name={FIELDS.DESCRIPTION}
          error={errors[FIELDS.DESCRIPTION]}
          width="100%"
          label={t('community.aboutCommunity')}
          rows={10}
        />
        <WebsiteFieldWrapper>
          <input
            type="hidden"
            name={`${FIELDS.WEBSITE}.label`}
            ref={register}
          />
          <TextInput
            width="100%"
            label={t('community.website')}
            error={errors[FIELDS.WEBSITE]?.value}
            name={`${FIELDS.WEBSITE}.value`}
            register={register}
          />
          <StyledPlusBlock>
            <PlusButton
              width="30px"
              text="Add website"
              onClick={openAddFieldSidebar}
            />
          </StyledPlusBlock>
        </WebsiteFieldWrapper>
        <DynamicInputsBlock>
          <DynamicInputs
            errors={errors[FIELDS.LINKS] as FieldError[]}
            groupItems={linksFieldProps.fields}
            name={FIELDS.LINKS}
            register={register}
            onDelete={deletePartnership}
          />
        </DynamicInputsBlock>
        {partnershipsFieldProps.fields.map((f, index) => (
          <Fragment key={f.id}>
            <input
              type="hidden"
              name={`${FIELDS.PARTNERSHIPS}.${index}.label`}
              value={f.label}
              ref={register}
            />
            <TextInput
              // pattern={getPattern(f.label).toString().slice(1, -1)}
              width="100%"
              label={socialFieldToLabel(f.label)}
              defaultValue={f.value}
              error={
                errors[FIELDS.PARTNERSHIPS] &&
                errors[FIELDS.PARTNERSHIPS][index]?.value
              }
              name={`${FIELDS.PARTNERSHIPS}.${index}.value`}
              register={register}
            />
          </Fragment>
        ))}
        <StyledButtonBlock>
          <Button
            type="submit"
            variant={isLoading ? 'secondary' : null}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader type="button" size="28px" thickness={2} />
            ) : (
              t('common.saveChanges')
            )}
          </Button>
        </StyledButtonBlock>
      </form>
    </StyledWrapper>
  );
};

export default SettingsGeneral;
