import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTooltip = styled(motion.div)`
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ background }) => background};
  border: 1px solid ${({ border }) => border};
  border-radius: 0.25rem;
  z-index: 1000000000000;
  padding: ${({ zoom }) => `${3 * zoom}px ${8 * zoom}px`};
  text-align: center;
  color: white;
  font-size: ${({ zoom }) => 12 * zoom}px;
`;
