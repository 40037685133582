import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledWrapper = styled.div`
  width: 500px;
  margin: auto;

  @media ${breakpoints.downSmPlus} {
    width: 94%;
  }
`;

export const StyledCoverInputBlock = styled.div`
  padding-top: 28px;
  @media ${breakpoints.downSmPlus} {
    margin-top: 20px;
  }
`;

export const StyledLogoInputBlock = styled.div`
  margin-top: 27px;
  margin-bottom: 16px;
`;

export const DynamicInputsBlock = styled.div`
  margin-bottom: 26px;
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 47px 0px;
  button {
    @media ${breakpoints.downSmPlus} {
      width: 100% !important;
    }
  }
`;

export const StyledPlusBlock = styled.div`
  margin-left: 3px;
  margin-top: 32px;
`;

export const WebsiteFieldWrapper = styled.div`
  display: flex;

  & > div:first-of-type {
    width: 100%;
    padding-right: 20px;
  }
`;
