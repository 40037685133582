import styled from 'styled-components';

import { CloseIcon } from 'static';
import { BenefitsList } from 'components/SubscriptionCard/styled';
import { breakpoints } from 'theme';

export const HeaderContainer = styled.div`
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 121;
`;

export const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  padding: 0 25px;
`;

export const ButtonBack = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 0 25px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.brightTurquoise};
`;

export const Content = styled.div`
  width: calc(100% - 360px);
  background-color: ${({ theme }) => theme.color.dew};
  margin-left: 360px;

  @media ${breakpoints.downMd} {
    width: 100%;
    margin-left: 0;
  }
`;

export const ContentBody = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  z-index: 12;
`;

export const ContentInner = styled.div`
  width: 697px;
  margin: auto;
  padding-top: 23px;
  text-align: left;

  @media ${breakpoints.downMdPlus} {
    width: 90%;
  }
`;

export const StyledP = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 35px;
`;

export const SubContainer = styled.div`
  margin-bottom: 12px;
  font-weight: 600;
`;

export const SubContainerBordered = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.02), 0 1px 6px 0 rgba(0, 0, 0, 0.06);
`;

export const SubContainerBL = styled(SubContainerBordered)`
  height: 265px;
`;

export const SubContainerBRT = styled(SubContainerBordered)`
  flex: 2;
  border-radius: 8px 8px 0 0;
  margin-bottom: 2px;
`;

export const SubContainerBRB = styled(SubContainerBordered)`
  flex: 1;
  padding-top: 22px;
  border-radius: 0 0 8px 8px;
`;

export const DetailsInner = styled.div`
  display: flex;
  margin: 0 auto 35px auto;
  font-size: 15px;
  font-weight: 400;

  & > div {
    width: 346px;
  }

  @media ${breakpoints.downMdPlus} {
    & > div {
      min-width: 0;
      flex: 1;

      &:first-of-type {
        flex: 1.2;
      }
    }
  }

  @media ${breakpoints.downSmPlus} {
    flex-direction: column;

    & > div {
      width: 100%;
      margin: auto;
    }
  }
`;

export const StyledRightWrapper = styled.div`
  margin-left: 12px;
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SubscriptionsGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 90px;
`;

export const StyledTitle = styled.span`
  margin-left: 14px;
`;

export const StyledHeader = styled.p`
  margin: 20px auto 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

export const StyledUList = styled(BenefitsList)`
  li:first-child {
    margin: 8px 18px 0px 40px;
  }

  li {
    display: flex;
    margin: 9px 18px 0 40px;

    div:first-child {
      align-items: flex-start;
      flex-direction: column;
    }

    span {
      font-size: 11px;
    }
  }
`;

export const StyledSummaryUL = styled.ul`
  padding: 0 30px 18px 31px;
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  line-height: 18px;

  li:first-child {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  li:last-child {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    line-height: 20px;
    margin-top: 11px;
  }

  span {
    font-size: 17px;
    font-weight: 600;
  }
`;

export const StyledTotalUL = styled.ul`
  padding: 0 30px 14px 31px;
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  line-height: 18px;

  li:nth-child(1) {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 4px;

    span {
      font-size: 20px;
      font-weight: 600;
    }
  }

  li:last-child {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 11px;
    line-height: 20px;
  }

  li:nth-child(2) {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    font-size: 11px;
    line-height: 20px;
    position: relative;
  }
`;

export const StyledCancelSubscription = styled.a`
  color: ${({ theme }) => theme.color.baliHai};
  text-decoration: none;
  cursor: pointer;
`;

export const StyledProblem = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(180, 180, 180);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  top: 12px;
  left: 5px;
  font-weight: 600;
  cursor: pointer;
`;

export const InnerAnimCircle = styled.div`
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background: #ff3333;
  transform: scale(1);
  left: auto;
  right: auto;
  animation: success-anim 1200ms ease infinite;
  @keyframes success-anim {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const StyledCross = styled(CloseIcon)`
  height: 7px;
  path {
    fill: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const StyledNumberContainer = styled.div`
  display: flex;
  width: 99px;
  margin: auto 0 auto auto;
`;

export const StyledBluredBox = styled.div`
  position: absolute;
  height: 67%;
  width: 200px;
  z-index: 10000;
  background: #ffffff73;
  backdrop-filter: blur(1px);
  border-radius: 5px;
`;
