import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ControllerRenderProps } from 'react-hook-form';

import {
  OverflowSidebarContentWithTabs,
  GroupUsersListControlled,
} from 'components';
import { EventFormData } from '../form';
import { getCurrentGroup } from 'store/selectors/currentGroup';
import OverallEventParticipants from './OverallEvent';

interface CreateEventParticipantsProps {
  recipientsFieldProps: ControllerRenderProps<EventFormData>;
  groupId: string | null;
}

const CreateEventParticipants: FC<CreateEventParticipantsProps> = ({
  recipientsFieldProps,
  groupId,
}) => {
  const group = useSelector(getCurrentGroup);

  return (
    <OverflowSidebarContentWithTabs>
      {groupId && group ? (
        <GroupUsersListControlled recipientsFieldProps={recipientsFieldProps} />
      ) : (
        <OverallEventParticipants recipientsFieldProps={recipientsFieldProps} />
      )}
    </OverflowSidebarContentWithTabs>
  );
};

export default CreateEventParticipants;
