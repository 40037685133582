export const staticTemplatedata = {
  en: [
    {
      title: 'Please enter your availability ✅❌ in Gumb',
      message:
        'Please remember to enter your availability for [Appointment/Event] on Gumb. This will help us plan better and ensure that everyone can be there.',
    },
    {
      title: '❌ Appointment cancellation: [Appointment subject]',
      message:
        'Hi everyone, unfortunately I have to cancel our appointment on [Date] at [Time] for [Appointment subject]. I apologize for the short notice and will let you know as soon as I have found a new appointment.',
    },
    {
      title: '📢 Important information for everyone:',
      message:
        'Hi everyone, this message contains important information for all team members...',
    },
  ],
  de: [
    {
      title: 'Bitte noch eure Verfügbarkeiten ✅❌ in Gumb eintragen',
      message:
        'Denkt bitte daran, eure Verfügbarkeiten für [Termin/Event] auf Gumb einzutragen. So können wir besser planen und sicherstellen, dass alle dabei sein können.',
    },
    {
      title: '❌ Terminabsage: [Betreff des Termins]',
      message:
        'Hallo zusammen, leider muss ich unseren Termin am [Datum] um [Uhrzeit] für [Betreff des Termins] absagen. Ich entschuldige mich für die kurzfristige Absage und informiere euch',
    },
    {
      title: '📢 Wichtige Info für Alle:',
      message:
        'Hallo zusammen, diese Nachricht enthält wichtige Informationen für alle Teammitglieder ...',
    },
  ],
  fr: [
    {
      title: 'Veuillez saisir vos disponibilités ✅❌ dans Gumb',
      message:
        'N\'oubliez pas de saisir vos disponibilités pour [Rendez-vous/événement] sur Gumb. Cela nous permettra de mieux planifier et de nous assurer que tout le monde pourra être présent.',
    },
    {
      title: '❌ Annulation de rendez-vous : [Objet du rendez-vous]',
      message:
        'Bonjour à tous, je suis désolé de devoir annuler notre rendez-vous du [Date] à [Heure] pour [Objet du rendez-vous]. Je m\'excuse pour le préavis court et je vous informerai dès que j\'aurai trouvé un nouveau rendez-vous.',
    },
    {
      title: '📢 Informations importantes pour tous :',
      message:
        'Bonjour à tous, ce message contient des informations importantes pour tous les membres de l\'équipe...',
    },
  ],
  it: [
    {
      title: 'Inserisci la tua disponibilità ✅❌ su Gumb',
      message:
        'Ricorda di inserire la tua disponibilità per [Appuntamento/Evento] su Gumb. Questo ci aiuterà a pianificare meglio e a garantire che tutti possano essere presenti.',
    },
    {
      title: '❌ Annullamento appuntamento: [Oggetto appuntamento]',
      message:
        'Ciao a tutti, purtroppo devo annullare il nostro appuntamento del [Data] alle [Ora] per [Oggetto appuntamento]. Mi scuso per il breve preavviso e vi informerò non appena avrò trovato un nuovo appuntamento.',
    },
    {
      title: '📢 Informazioni importanti per tutti:',
      message:
        'Ciao a tutti, questo messaggio contiene informazioni importanti per tutti i membri del team...',
    },
  ],
  es: [
    {
      title: 'Por favor, ingrese su Gumb su disponibilidad ✅❌',
      message:
        'No olviden ingresar su disponibilidad para [Cita/Evento] en Gumb. Esto nos ayudará a planificar mejor y asegurarnos de que todos puedan asistir.',
    },
    {
      title: '❌ Cancelación de cita: [Asunto de la cita]',
      message:
        'Hola a todos, lamento tener que cancelar nuestra cita del [Fecha] a la [Hora] para [Asunto de la cita]. Me disculpo por el corto aviso y les informaré tan pronto como tenga una nueva cita.',
    },
    {
      title: '📢 Información importante para todos:',
      message:
        'Hola a todos, este mensaje contiene información importante para todos los miembros del equipo...',
    },
  ],
  ru: [
    {
      title: 'Пожалуйста, введите свою доступность ✅❌ в Gumb',
      message:
        'Пожалуйста, не забудьте ввести свою доступность для [Событие/Встреча] в Gumb. Это поможет нам лучше спланировать и убедиться, что все смогут присутствовать.',
    },
    {
      title: '❌ Отмена встречи: [Тема встречи]',
      message:
        'Привет всем, к сожалению, я должен отменить нашу встречу на [Дата] в [Время] по теме [Тема встречи]. Извиняюсь за короткое уведомление и сообщу вам, как только найду новую дату встречи.',
    },
    {
      title: '📢 Важная информация для всех:',
      message:
        'Привет всем, это сообщение содержит важную информацию для всех членов команды...',
    },
  ],
};
