import styled from 'styled-components';

import { ReturnIcon } from 'static';
import { breakpoints } from 'theme';

export const StyledHeader = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 0 27px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.pickledBluewood};

  @media ${breakpoints.downMd} {
    padding-right: 50px;
  }
`;

export const StyledContent = styled.div`
  background-color: white;
  height: calc(100% - 50px);
  overflow-y: auto;
`;

export const StatusIndicator = styled.div`
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
  background-color: ${({ theme, isRead }) =>
    isRead ? 'transparent' : theme.color.brightTurquoise};
  border: ${({ theme, isRead }) =>
    isRead ? `1px solid ${theme.color.baliHai}` : 'none'};
  position: relative;
  top: 1px;
`;

export const StyledArrowIcon = styled(ReturnIcon)`
  width: 14px;
  height: auto;
  position: absolute;
  right: 40px;
  transform: rotate(180deg);

  polygon {
    fill: ${({ theme }) => theme.color.brightTurquoise};
  }

  @media ${breakpoints.downSmPlus} {
    width: 10px;
    right: 20px;
  }
`;

export const NotificationWrapper = styled.a`
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 70px 10px 27px;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  transition: background 0.3s;

  &:hover {
    background: ${({ href, theme }) =>
      href ? theme.color.brightTurquoise + '2e' : 'white'};
  }

  @media ${breakpoints.downSmPlus} {
    padding: 7px 40px 7px 27px;
    min-height: 50px;
  }
`;

export const NotificationDate = styled.span`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 10px;
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
`;

export const Message = styled.p`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 15px;
  font-weight: normal;
  margin-left: 15px;

  @media ${breakpoints.downSmPlus} {
    font-size: 13px;
  }
`;

export const NotificationImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 15px;

  @media ${breakpoints.downSmPlus} {
    width: 30px;
    height: 30px;
  }
`;
