import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledStickyButtonBlock = styled.div`
  position: fixed;
  bottom: 36px;
  right: 1.5%;

  @media ${breakpoints.downMdPlus} {
    bottom: 26px;
    right: 3%;
  }
  @media ${breakpoints.downSmPlus} {
    bottom: 16px;
    right: 4%;
  }
`;

export const StyledFilterBlock = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr 1fr;
  grid-gap: 20px;
  align-items: end;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 20px;
`;

export const StyledApplyButtonBlock = styled.div`
  margin-bottom: -1px;
`;

export const StyledResetButtonBlock = styled.div`
  justify-self: center;
  align-self: flex-end;
  margin-bottom: 5px;
`;
