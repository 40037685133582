import styled from 'styled-components';

export const StyledProgressBar = styled.div`
  position: relative;
  height: 4px;
  border-radius: 12px;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.color.dew};
`;

export const Filler = styled.div`
  height: 100%;
  border-radius: inherit;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  transition: width 0.2s ease-in;
`;
