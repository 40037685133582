import styled, { keyframes, css } from 'styled-components';
const pulseRing = keyframes`
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowLeft = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.brightTurquoise};
  padding: ${({ zoom }) => zoom * 15}px;
  cursor: pointer;
  ${({ isAnimating }) =>
    isAnimating
      ? css`
          svg {
            transform: scale(1.03);
          }
          &:before {
            content: '';
            position: absolute;
            display: block;
            width: ${({ zoom }) => zoom * 100}px;
            height: ${({ zoom }) => zoom * 100}px;
            box-sizing: border-box;
            border-radius: 50%;
            animation: ${pulseRing} 1.7s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: red;
            animation-fill-mode: forwards;
          }
        `
      : 'none'};

  svg {
    position: relative;
    width: ${({ zoom }) => zoom * 45}px;
    height: ${({ zoom }) => zoom * 45}px;
    background: ${({ theme }) => theme.color.baliHai};
    border: 1px solid ${({ theme }) => theme.color.baliHai};
    border-radius: 50%;
    transition: transform 0.2s, background 0.2s, border-color 0.2s;
    background: ${({ theme }) => theme.color.brightTurquoise};
    border-color: ${({ theme }) => theme.color.brightTurquoise};
    &:hover {
      transform: scale(1.3) rotate(0deg);
      background: ${({ theme }) => theme.color.pickledBluewood};
      border-color: ${({ theme }) => theme.color.pickledBluewood};
    }
    path {
      fill: white;
    }
  }
`;

export const ArrowRight = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.brightTurquoise};
  padding: ${({ zoom }) => zoom * 15}px;
  cursor: pointer;
  ${({ isAnimating }) =>
    isAnimating
      ? css`
          svg {
            transform: scale(1.03);
          }
          &:before {
            content: '';
            position: absolute;
            display: block;
            width: ${({ zoom }) => zoom * 100}px;
            height: ${({ zoom }) => zoom * 100}px;
            box-sizing: border-box;
            border-radius: 50%;
            animation: ${pulseRing} 1.7s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: red;
            animation-fill-mode: forwards;
          }
        `
      : 'none'};
  svg {
    position: relative;
    width: ${({ zoom }) => zoom * 45}px;
    height: ${({ zoom }) => zoom * 45}px;
    background: ${({ theme }) => theme.color.baliHai};
    border-radius: 50%;
    transition: transform 0.2s, background 0.2s, border-color 0.2s;
    transform: rotate(180deg);
    background: ${({ theme }) => theme.color.pickledBluewood};
    border: 1px solid ${({ theme }) => theme.color.pickledBluewood};
    path {
      fill: ${({ theme }) => theme.color.brightTurquoise};
    }
    &:hover {
      path {
        fill: #33aba7c4;
      }
      transform: scale(1.3) rotate(180deg);
      background: ${({ theme }) => theme.color.white};
      border: none;
    }
  }
`;
