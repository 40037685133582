import styled from 'styled-components';

export const StyledRecipentsContainer = styled.div`
  overflow: hidden;
  position: relative;
  height: calc(100svh - 50px);
`;

export const StyledHeaderWrapper = styled.div`
  padding: 0 30px;
`;

export const StyledSearchWrapper = styled.div`
  padding: 13px 16px 14px 15px;

  input {
    padding-right: 48px;
  }
`;
