import { includes, filter } from 'lodash/fp';

import { getProfileName } from './common';
import { GroupUser } from 'types';

export const searchByProfileName = (
  filterValue: string,
  users: GroupUser[],
): GroupUser[] =>
  filterValue
    ? filter(
        (user) =>
          includes(
            filterValue.toLowerCase(),
            getProfileName(user.profile).toLowerCase(),
          ) ||
          includes(filterValue.toLowerCase(), user.profile.email.toLowerCase()),
        users,
      )
    : users;
