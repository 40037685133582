import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CheckBox, LogoWithTitle } from 'components';
import { getAvailableCommunities } from 'store/selectors/communities';
import { UsersIcon } from 'static';

import { List, NoCommunitiesBlock } from './styled';

const CommunitiesList = ({
  selectedCommunities,
  fieldName,
  handleChange,
  settingsSaving,
}) => {
  const { t } = useTranslation();
  const communities = useSelector(getAvailableCommunities);

  return (
    <List>
      <List.Body>
        {communities?.length > 0 ? (
          communities.map((item) => (
            <List.Row key={item.id}>
              <List.Row.Cell>
                <CheckBox
                  isSelected={selectedCommunities.includes(item.id)}
                  name={fieldName}
                  onChange={() => handleChange(fieldName, item.id)} //keeping the changes made here
                  disabled={settingsSaving}
                  width="20px"
                  checkBoxWidth="20px"
                />
              </List.Row.Cell>
              <List.Row.Cell>
                <LogoWithTitle logo={item.logo} title={item.name} />
              </List.Row.Cell>
            </List.Row>
          ))
        ) : (
          <NoCommunitiesBlock>
            <UsersIcon />
            <span>{t('calendarConnection.noCommunities')}</span>
          </NoCommunitiesBlock>
        )}
      </List.Body>
    </List>
  );
};

export default CommunitiesList;
