import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'components';
import { CellsTypes } from 'constants/index';
import { Document } from 'types';

interface EventDetailsDocumentsProps {
  documents: Document[];
}

const EventDetailsDocuments: FC<EventDetailsDocumentsProps> = ({
  documents,
}) => {
  const { t } = useTranslation();

  const tableConfig = {
    title: t('documentation.attachFiles'),
    headers: [
      {
        id: 'name',
        width: '100%',
        type: CellsTypes.DOCUMENT,
        headerType: 'title',
      },
    ],
  };

  return <Table data={documents} config={tableConfig} />;
};

export default EventDetailsDocuments;
