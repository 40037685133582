import { createRoutine } from 'redux-saga-routines';

import { EditEventType } from 'constants/sidebars';
import { ReplyFormData } from 'pages/UserProfileEvents/form';

export const doCreateEventReply = createRoutine('CREATE_EVENT_REPLY', {
  trigger: (payload: {
    data: ReplyFormData;
    groupId: string;
    communityId?: string;
    eventId: string;
    userId: string;
    eventSeriesId: string;
    type: EditEventType;
    from: string | null;
    filterFrom: Date;
    filterTo: Date;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doCreateOverallEventReply = createRoutine(
  'CREATE_OVERALL_EVENT_REPLY',
  {
    trigger: (payload: {
      data: ReplyFormData;
      communityId?: string;
      eventId: string;
      userId: string;
      eventSeriesId: string;
      type: EditEventType;
      from: string | null;
      filterFrom: Date;
      filterTo: Date;
      onSuccess: () => void;
      onFailure: (message: string) => void;
    }) => payload,
  },
);

export const doUpdateEventReply = createRoutine('UPDATE_EVENT_REPLY', {
  trigger: (payload: {
    data: ReplyFormData;
    groupId: string;
    communityId?: string;
    eventId: string;
    userId: string;
    eventReplyId: string;
    eventSeriesId: string;
    type: EditEventType;
    from: string | null;
    filterFrom: Date;
    filterTo: Date;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doUpdateOverallEventReply = createRoutine(
  'UPDATE_OVERALL_EVENT_REPLY',
  {
    trigger: (payload: {
      data: ReplyFormData;
      communityId?: string;
      eventId: string;
      userId: string;
      eventReplyId: string;
      eventSeriesId: string;
      type: EditEventType;
      from: string | null;
      filterFrom: Date;
      filterTo: Date;
      onSuccess: () => void;
      onFailure: (message: string) => void;
    }) => payload,
  },
);

export const doDeleteEventReply = createRoutine('DELETE_EVENT_REPLY', {
  trigger: (payload: {
    groupId: string;
    communityId?: string;
    eventId: string;
    userId: string;
    eventSeriesId: string;
    eventReplyId: string;
    type: EditEventType;
    from: string | null;
    filterFrom: Date;
    filterTo: Date;
    onSuccess: () => void;
    onFailure: (message: string) => void;
  }) => payload,
});

export const doDeleteOverallEventReply = createRoutine(
  'DELETE_OVERALL_EVENT_REPLY',
  {
    trigger: (payload: {
      communityId?: string;
      eventId: string;
      userId: string;
      eventSeriesId: string;
      eventReplyId: string;
      type: EditEventType;
      from: string | null;
      filterFrom: Date;
      filterTo: Date;
      onSuccess: () => void;
      onFailure: (message: string) => void;
    }) => payload,
  },
);
