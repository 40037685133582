import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'configs';

import { doDeleteGroup } from 'store/actionCreators/groups';

import { PopupButtonMessage } from 'components';
import { useToasts } from 'react-toast-notifications';

const SettingsOther = ({ group }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const onSuccess = () => {
    addToast(t('group.deleteSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const deleteGroup = (data) => dispatch(doDeleteGroup({ data, onSuccess }));

  const onDeleteGroup = (groupId, communityId) => {
    deleteGroup({ groupId, communityId });
    navigate(`${ROUTES.COMMUNITY}/${communityId}`);
  };

  return (
    <PopupButtonMessage
      text={t('group.deleteGroup')}
      onConfirm={() => onDeleteGroup(group.id, group.communityId)}
      popupButtonText={t('common.delete')}
      popupHeaderText={t('groupSettings.deleteGroupHeader')}
    />
  );
};

SettingsOther.propTypes = {
  group: shape({}).isRequired,
};

export default SettingsOther;
