import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';
import Content from './Content';
import { Community } from 'types';
import {
  canUserEditCommunity,
  getCommonCommunityLinks,
  getCommunityLinks,
} from 'utils/common';
import { getFullAuthUser } from 'store/selectors/auth';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';

import { Layout, StyledWrapper } from './styled';

interface CommunityLandingPageProps {
  community: Community;
  isUserInCommunity: boolean;
  sendRequest: () => void;
}

const CommunityLandingPage: FC<CommunityLandingPageProps> = ({
  community,
  isUserInCommunity,
  sendRequest = () => {},
}) => {
  const currentUser = useSelector(getFullAuthUser);
  const isMenuOpen = useSelector(getIsMenuOpen);

  const { name, cover, logo, description, invites, usersCount, users } =
    community;
  const socialLinks = getCommunityLinks(community);
  const commonLinks = getCommonCommunityLinks(community);
  const hasAdminPermissions = canUserEditCommunity(currentUser, community);

  const inviteSent = useMemo(() => {
    return invites ? invites.some((el) => el.userId === currentUser.id) : false;
  }, [invites, currentUser.id]);

  return (
    <StyledWrapper
      isUserInCommunity={isUserInCommunity}
      isMenuOpen={isMenuOpen}
    >
      <Layout>
        <Header
          title={name}
          cover={cover}
          logo={logo}
          isUserInCommunity={isUserInCommunity}
          isMenuOpen={isMenuOpen}
          hasAdminPermissions={hasAdminPermissions}
          usersCount={users?.length ?? usersCount}
        />
        <Content
          buttonDisabled={inviteSent}
          description={description}
          links={commonLinks}
          isUserInCommunity={isUserInCommunity}
          sendRequest={sendRequest}
          socialLinks={socialLinks}
          isMenuOpen={isMenuOpen}
        />
      </Layout>
    </StyledWrapper>
  );
};

export default CommunityLandingPage;
