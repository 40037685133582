import { theme } from 'theme';

const getTextColor = (colorHexCode: string) => {
  //convert hex code to an RGB array as [r,g,b]
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const updatedHexCode = colorHexCode.replace(
    shorthandRegex,
    function (m, r, g, b) {
      return r + r + g + g + b + b;
    },
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    updatedHexCode,
  );

  const rgbCode = [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];

  // convert background color to grayscale luminance
  const luminance =
    (0.299 * rgbCode[0] + 0.587 * rgbCode[1] + 0.114 * rgbCode[2]) / 255;

  return luminance > 0.5 ? theme.color.black : theme.color.white;
};

export default getTextColor;
