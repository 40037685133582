import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { useChangePasswordForm } from './form';

export const useChangePassword = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { handleSubmit, setValue, ...form } = useChangePasswordForm();

  const onSubmit = handleSubmit(async (values) => {
    const user = await Auth.currentAuthenticatedUser();
    Auth.changePassword(user, values.oldPassword, values.password)
      .then(() => {
        addToast(t('errors.passwordChanged'), {
          appearance: 'success',
          autoDismiss: true,
        });
      })
      .catch(({ message }) => {
        addToast(t('errors.errorToast', { message }), {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  });

  return {
    onSubmit,
    setValue,
    ...form,
  };
};
