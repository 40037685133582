export enum STATISTICS_TYPES {
  APPOINTMENT_SCHEDULE = 'appointmentSchedule',
  MEMBER_FIGURES = 'memberFigures',
  EVENT_REPLIES = 'eventReplies',
  EVENT_TYPES = 'eventTypes',
}

export enum APPOINTMENT_SCHEDULE_COLUMNS {
  DESCRIPTION = 'description',
  LOCATION = 'location',
  CREATION_DATE = 'creationDate',
  ORGANIZED_BY = 'organizedBy',
  START = 'start',
  END = 'end',
}
