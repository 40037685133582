import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { css } from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import newIcon from 'static/animations/new.json';

import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { CommunityRoles, ModalTypes } from 'types';
import { CREATE_CHAT } from 'constants/sidebars';
import {
  CommunityTitle,
  Loader,
  MenuChatList,
  MenuItem,
  MenuList,
  MenuMembersList,
  SettingsButton,
  PendingSettingsInvite,
  Divider,
} from 'components';

import { doToggleMenu } from 'store/actionCreators/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { doGetChats } from 'store/actionCreators/chat';
import {
  getChatsToView,
  getChatsLoading,
  getChatsAuthenticated,
} from 'store/selectors/chat';
import {
  getCommunitiesLoading,
  getCommunities,
} from 'store/selectors/communities';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { selectIsChatsAvailable } from 'store/selectors/subscriptions';

import {
  StyledMenuBlock,
  StyledMenuHeader,
  StyledMenuItemsBlock,
  StyledSettingsLink,
  StyledBurger,
} from './styled';
import {
  MailIcon,
  HomePageIcon,
  GroupIcon,
  DocumentationIcon,
  ChatIcon,
  PushNotificationLogo,
} from 'static';

export const animationOptions = {
  animationData: newIcon,
  autoplay: true,
  loop: true,
};

const CommunityMenu = () => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const groups = community.groups || community.overallScheduler?.groups;
  const communities = useSelector(getCommunities);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const isCommunitiesLoading = useSelector(getCommunitiesLoading);
  const isChatsLoading = useSelector(getChatsLoading);
  const chatsEnabled = useSelector(selectIsChatsAvailable);
  const chats = useSelector(getChatsToView);
  const isAuthenticated = useSelector(getChatsAuthenticated);
  const userRole = currentUser?.communityPermission.role;
  const { chatUsers = [], users = [] } = community;
  const [accessToChat, setAccessToChat] = useState(false);
  const [listMaxHeights, setListMaxHeights] = useState({
    groups: 0,
    chats: 0,
    members: 0,
  });
  const groupsListRef = useRef(null);
  const chatsListRef = useRef(null);
  const membersListRef = useRef(null);
  const [menuState, setMenuState] = useLocalStorage<string>('menuShown', '');
  const navigate = useNavigate();
  const isLoading = isChatsLoading || isCommunitiesLoading;
  const invitesCount = communities.find((c) => c.id === community.id)?.requests
    ?.length;

  const handleGroupEventPopupOpen = () => {
    navigate(`${ROUTES.COMMUNITY}/${communityId}/overall`);

    setTimeout(() => {
      dispatch(
        openModal({
          type: ModalTypes.COMMUNITY_POPUP,
        }),
      );
    }, 1500);
  };

  const openCreateChatSidebar = () =>
    dispatch(
      openModal({
        type: CREATE_CHAT,
        placement: 'right',
      }),
    );

  useEffect(() => {
    if (!menuState) {
      setMenuState('true');
      dispatch(doToggleMenu());
    }

    window.addEventListener('resize', setListsHeight);

    return () => window.removeEventListener('resize', setListsHeight);
  }, []);

  useEffect(() => {
    setListsHeight();
  }, [community, chatsEnabled, accessToChat]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isAuthenticated && dispatch(doGetChats(communityId));
  }, [communityId, isAuthenticated, dispatch]);

  useEffect(() => {
    if (Array.isArray(chatUsers) && chatUsers.length > 0) {
      chatUsers.forEach((user) => {
        if (user.id === currentUser?.profile.id && chatsEnabled) {
          setAccessToChat(true);
        }
      });
    }
  }, [chatUsers]);

  const setListsHeight = () => {
    let groupsListHeight =
      groupsListRef?.current?.getBoundingClientRect().height + 10 || 0;
    let chatsListHeight =
      chatsListRef?.current?.getBoundingClientRect().height + 10 || 0;
    let membersListHeight =
      membersListRef?.current?.getBoundingClientRect().height + 10 || 0;
    const heightLeft = window.innerHeight - 310;

    if (groupsListHeight + chatsListHeight + membersListHeight > heightLeft) {
      while (
        groupsListHeight + chatsListHeight + membersListHeight >
        heightLeft
      ) {
        if (groupsListHeight > 280) {
          groupsListHeight -= 5;
        }

        if (
          chatsListHeight > (heightLeft - 280) / 2 &&
          chatsListHeight >= membersListHeight
        ) {
          chatsListHeight -= 5;
        }

        if (
          membersListHeight > (heightLeft - 280) / 2 &&
          membersListHeight >= chatsListHeight
        ) {
          membersListHeight -= 5;
        }
      }
    }

    setListMaxHeights({
      groups: groupsListHeight,
      chats: chatsListHeight,
      members: membersListHeight,
    });
  };

  const handleMenuToggle = () => {
    setMenuState(menuState ? 'true' : '');
    dispatch(doToggleMenu());
  };
  const commonMemberProps = {
    list: community.chatUsers,
    listRef: membersListRef,
    listMaxHeight: listMaxHeights.members,
    userRole,
    users: users.filter(
      (u) => u.communityPermission?.role !== CommunityRoles.BLOCKED,
    ),
    groupList: groups,
  };

  return (
    <StyledMenuBlock isMenuOpen={isMenuOpen}>
      <StyledMenuHeader>
        {invitesCount > 0 &&
          [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) && (
            <PendingSettingsInvite
              invitesCount={invitesCount}
              addCss={{
                right: isMenuOpen ? 34 : 4,
                top: 6,
              }}
            />
          )}
        <CommunityTitle
          title={community ? community.name : ''}
          image={community ? community.logo : ''}
          isMenuOpen={isMenuOpen}
        />
        {isMenuOpen &&
        [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ? (
          <StyledSettingsLink
            end
            to={`${ROUTES.COMMUNITY}/${community && community.id}/${
              COMMUNITY_ROUTES.SETTINGS
            }`}
          >
            <SettingsButton />
          </StyledSettingsLink>
        ) : null}
        <StyledBurger isMenuOpen={isMenuOpen} onClick={handleMenuToggle}>
          <div></div>
          <div></div>
          <div></div>
        </StyledBurger>
      </StyledMenuHeader>
      <Divider customMargin="0px 0px 16px 0px" />
      {community && !isLoading ? (
        <StyledMenuItemsBlock isMenuOpen={isMenuOpen}>
          <MenuItem
            title={t('menu.homePage')}
            icon={<HomePageIcon />}
            to={`${ROUTES.COMMUNITY}/${community.id}`}
          />
          <MenuList
            title={t('menu.groupScheduler')}
            titleLink={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.OVERALL}`}
            icon={<GroupIcon />}
            list={community.groups}
            isPlusIcon
            isAddButtonContained
            userRole={userRole}
            listRef={groupsListRef}
            listMaxHeight={listMaxHeights.groups}
            onClick={handleGroupEventPopupOpen}
            communityId={community.id}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.DOCUMENTATION}`}
            title={t('menu.documentation')}
            icon={<DocumentationIcon />}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.PUSH_NOTIFICATION}`}
            title={t('pushNotification.header')}
            icon={<PushNotificationLogo />}
            toolTipText={t('pushNotification.plusTooltip')}
            isAdminOrOwner={[
              CommunityRoles.ADMIN,
              CommunityRoles.OWNER,
            ].includes(userRole)}
            isAddButtonContained
            onClick={() => {
              addToast(t('pushNotification.toasterText'), {
                appearance: 'success',
                autoDismiss: false,
              });
            }}
            iconStyles={css`
              width: 14px;
              height: 14px;
              position: relative;
              left: -1px;
            `}
            menuBlockStyles={{ gap: '8px' }}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.EMAIL}`}
            title={t('common.email')}
            icon={<MailIcon />}
            toolTipText={t('email.plusTooltip')}
            isAdminOrOwner={[
              CommunityRoles.ADMIN,
              CommunityRoles.OWNER,
            ].includes(userRole)}
            isAddButtonContained
            onClick={() => {}}
          />
          {(chatsEnabled || accessToChat) && (
            <MenuChatList
              icon={<ChatIcon />}
              list={chats}
              listRef={chatsListRef}
              listMaxHeight={listMaxHeights.chats}
              onClick={openCreateChatSidebar}
            />
          )}
          {[CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ? (
            <MenuMembersList
              to={`${ROUTES.COMMUNITY}/${community?.id}/${COMMUNITY_ROUTES.SETTINGS}#members`}
              {...commonMemberProps}
            />
          ) : (
            <MenuMembersList {...commonMemberProps} />
          )}
        </StyledMenuItemsBlock>
      ) : (
        isMenuOpen && <Loader />
      )}
    </StyledMenuBlock>
  );
};

export default CommunityMenu;
