import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  doDeleteCommunityEvents,
  doDeleteEvents,
} from 'store/actionCreators/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import { Event } from 'types';
import { EditEventType } from 'constants/sidebars';

export const useEventDetails = ({
  groupId,
  communityId,
  eventId,
  event,
  isOverallEvent,
}: {
  groupId: string;
  communityId: string;
  eventId: string;
  event: Event;
  isOverallEvent: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const onSuccessDelete = () => {
    dispatch(closeModal());
    addToast(t('group.deleteEventSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onDeleteEvents = (
    editType: EditEventType,
    notify: boolean,
    notifyText?: string,
  ) =>
    isOverallEvent
      ? dispatch(
          doDeleteCommunityEvents({
            communityId,
            eventId,
            eventSeriesId: event?.eventSeriesId,
            from: event?.from,
            editType,
            notify: { toNotify: !!notify, notifyText: notifyText },
            onSuccess: onSuccessDelete,
          }),
        )
      : dispatch(
          doDeleteEvents({
            groupId,
            eventId,
            eventSeriesId: event?.eventSeriesId,
            from: event?.from,
            editType,
            notify: { toNotify: !!notify, notifyText: notifyText },
            onSuccess: onSuccessDelete,
          }),
        );

  return {
    onDeleteEvents,
  };
};
