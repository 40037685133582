import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoints } from 'theme';

export const StyledContainer = styled.div`
  padding: 0 19px 0 19px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100svh - 206px);
  position: relative;
  @media ${breakpoints.downSmPlus} {
    padding: 0 8px 0 8px;
    max-height: calc(100svh - 172px);
  }
`;

export const StyledTitle = styled.p`
  font-size: 24px;
  line-height: 26px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 800;
  margin-bottom: 32px;
  padding-right: 10px;
  @media ${breakpoints.downMd} {
    font-weight: 700;
  }
`;

export const StyledIconTextBlock = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  margin-bottom: 16px;
  margin-top: ${({ marginTop }) =>
    marginTop ? `${(marginTop - 1) * -30 + 32}px` : ''};
`;

export const BlackDot = styled.div`
  width: 10px;
  height: 10px;
  margin-top: 6px;
  border-radius: 50%;
  margin-left: 11px;
  background-color: ${({ theme }) => theme.color.black};
`;

export const StyledTextBlock = styled.div`
  display: flex;
  width: 100%;
  ${({ addCss }) => addCss}
`;

export const StyledText = styled.p`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: ${({ lineHeight }) => lineHeight || '20px'};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ theme, color }) => (color ? color : theme.color.shark)};
  white-space: break-spaces;
  ${({ ellipsis }) =>
    ellipsis &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;

StyledText.Bold = styled.span`
  font-weight: 600;
`;

export const StyledSmallText = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledOrganizerBlock = styled.div`
  display: flex;
`;

export const StyledStatisticsBlock = styled.div`
  margin: 16px auto 30px;

  @media ${breakpoints.downMd} {
    overflow-x: scroll;
  }
`;

export const StyledGroupsBlock = styled.div`
  margin-top: 16px;
`;

export const StyledRepliesBlock = styled.div`
  display: flex;

  & > div {
    display: flex;
    align-items: center;
    min-width: 55px;

    svg {
      margin-right: 5px;
    }
  }
`;

export const StyledNoRepliesWrapper = styled.div`
  min-width: 60px !important;
`;

export const StyledReplyCircle = styled.div`
  width: ${({ width }) => width || 20}px;
  height: ${({ width }) => width || 20}px;
  min-width: ${({ width }) => width || 20}px;
  border-radius: 50%;
  margin-right: 5px;
`;

export const StyledReplyCircleWrapper = styled.div`
  width: 55px;
  min-width: 55px;
`;

export const StyledGroupsList = styled.div`
  margin-top: 16px;
`;

export const StyledTotalBlock = styled.div`
  position: relative;
  left: -19px;
  padding: 12px 19px;
  width: ${({ width = 450 }) => width}px;
  height: ${({ height = 110 }) => height}px;
  transition: transform 0.3s, box-shadow 0.3s, height 0.5s;
  cursor: pointer;
  overflow: hidden;
  background: white;

  &:hover {
    box-shadow: 0px 0px 10px -3px #10ccc6;
  }
  @media ${breakpoints.downSmPlus} {
    padding: 12px 19px 12px 4px;
  }

  @media ${breakpoints.downSmPlus} {
    left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const StyledGroupRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  left: -19px;
  padding: 5px 19px;
  transition: transform 0.3s, box-shadow 0.3s, height 0.5s;
  cursor: pointer;
  width: ${({ width = 450 }) => width}px;
  height: ${({ height }) => height || 30}px;
  overflow: hidden;
  background: white;
  margin-top: 3px;
  margin-bottom: 10px;

  &:hover {
    box-shadow: 0px 0px 10px -3px #10ccc6;
  }
  @media ${breakpoints.downSmPlus} {
    padding: 4px 4px;
  }
  @media ${breakpoints.downSmPlus} {
    width: ${({ width = 450 }) => (width < 450 ? '100%' : width + 'px')};
    left: 0px;
  }
`;

export const StyledTotalRowHead = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  padding: 10px 12px;
  border-radius: 8px;
  gap: 12px;
  @media ${breakpoints.downSmPlus} {
    width: 100% !important;
    max-width: 100% !important;
  }
`;

export const TotalStatistics = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

TotalStatistics.Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.baliHai};
`;

TotalStatistics.Img = styled.img`
  width: 20px;
  height: 20px;
  filter: invert(82%) sepia(92%) saturate(320%) hue-rotate(371deg)
    brightness(154%) contrast(124%);
`;

TotalStatistics.NoRepliesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

export const TotalTabWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColorDotTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
`;

export const ReplyCircleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledGroupRowHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledGroupName = styled.div`
  display: flex;
  max-width: 140px;
  overflow: hidden;
  align-items: center;

  svg {
    min-width: 15px;
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
`;

export const StyledCollapsedReplies = styled.div`
  width: ${({ width }) => width + 'px' || '100%'};
  margin-right: 19px;
  @media ${breakpoints.downSmPlus} {
    margin-right: 0px;
    width: 100%;
  }
`;

export const StyledCollapsedRepliesRow = styled.div`
  margin: 15px 0 6px 0px;
  border-radius: 6px;
  border: 1px solid green;
`;

export const StyledCollapsedRepliesRowTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2px 12px;
  background: #eaf2f6;
  border-radius: 6px;

  & > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
    height: 14px;
    margin-right: 5px;
  }
`;

export const StyledCollapsedRepliesRowBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const pulse = keyframes`
    0% {
        transform: scale(1.25);
    }
    50% {
        transform: scale(1);
    } 
    80% {
        transform: scale(1.23);
    }
    100% {
        transform: scale(1.25);
    }
`;

export const StyledCollapsedRepliesListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 2%;
  width: 46%;
  max-width: 46%;
  overflow: hidden;

  svg {
    margin-left: 7px;
    min-width: 12px;
  }
  span {
    display: flex;
    align-items: center;
    height: 0.8rem;
    border-radius: 50%;
    margin-left: 7px;
    min-width: 12px;
  }
`;

export const InviteesImg = styled.img`
  transition: 0.3s ease;
  margin-top: -1px;
  width: 13px;
  height: 13px;
`;
export const StyledOptionalMessage = styled(motion.div)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: 14px;
  font-weight: normal;
  border-radius: 12px;
  box-shadow: 0px -1px 4px 1px ${({ theme }) => theme.color.dimmer};
  top: ${({ index }) => `-${index * 30}px`};
`;

StyledOptionalMessage.MessageBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-left: 32px;
  padding-bottom: 4px;
  padding-top: 6px;
  vertical-align: middle;
  margin-bottom: 8px;
`;

StyledOptionalMessage.Message = styled.div`
  width: 100%;
  display: block;
  visibility: visible;
  color: ${({ textColor }) => textColor};
  padding-left: 8px;
`;

export const DateTag = styled.div`
  background-color: ${({ theme }) => theme.color.dimmer};
  width: max-content;
  display: flex;
  font-size: 10px;
  padding: 4px 8px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-radius: 6px;
  margin-bottom: 4px;
  ${({ showPlusButton }) =>
    showPlusButton
      ? css`
          display: flex;
          justify-content: center;
        `
      : ''};
`;

export const OptionalMessageList = styled(motion.ul)`
  width: 100%;
  position: relative;
  top: 0;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  padding: 20px 16px;
  box-shadow: 0px 0px 8px 2px ${({ theme }) => theme.color.lightGrey};
  border-radius: 12px;
  margin-top: 6px;
`;

export const OptionalMessageListItem = styled(motion.li)`
  position: sticky;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;
  color: ${({ theme }) => theme.color.white};
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: 14px;
  font-weight: normal;
  border-radius: 6px;
  margin: 10px 0px;
  padding: 8px 12px 12px 12px;
`;

OptionalMessageListItem.Message = styled.p`
  width: 100%;
  overflow-wrap: break-word;
`;

export const OptionalMessageAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;
