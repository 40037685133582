import styled from 'styled-components';

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledText = styled.span`
  margin-left: 14px;
  font-size: 15px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const StyledSpot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-left: 3px;
`;
