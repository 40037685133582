import React, { FC } from 'react';

import {
  StyledSettingsButton,
  StyledSpan,
  StyledSettingsBlinkButton,
} from './styled';

interface PendingSettingsInviteProps {
  invitesCount: number;
  blink?: boolean;
  addCss: { [key: string]: string | number };
}

const PendingSettingsInvite: FC<PendingSettingsInviteProps> = ({
  invitesCount,
  blink = false,
  addCss,
}) => (
  <StyledSpan addCss={addCss} className="notifications-count">
    {blink ? (
      <StyledSettingsBlinkButton type="button">
        {invitesCount}
      </StyledSettingsBlinkButton>
    ) : (
      <StyledSettingsButton type="button">
        <span>{invitesCount}</span>
      </StyledSettingsButton>
    )}
  </StyledSpan>
);

export { PendingSettingsInvite };
