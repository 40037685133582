import React, { useEffect, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { isEmpty, filter, includes } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProfileName } from 'utils';
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  ProfileName,
  StyledListItem,
  StyledNameWrapper,
  ListPlaceholder,
} from 'components';
import { closeModal } from 'store/actionCreators/modal';
import { getCurrentMembers } from 'store/selectors/currentCommunity';
import { CommunityUser, TaskDetailsData } from 'types';
import { useClickOutsideClose } from 'hooks';

const TaskDetails = ({ task, taskMembersIds = [] }: TaskDetailsData) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [taskMembers, setTaskMembers] = useState<CommunityUser[]>([]);
  const members = useSelector(getCurrentMembers);
  const refModal = React.createRef<HTMLDivElement>();

  useEffect(() => {
    setTaskMembers(() =>
      filter(({ id }) => includes(id, taskMembersIds), members),
    );
  }, [members, taskMembersIds]);

  const renderMember = ({ index, key, style }) => {
    const { profile } = taskMembers[index];

    return (
      <StyledListItem key={key} style={style}>
        <StyledNameWrapper>
          <ProfileName
            name={getProfileName(profile)}
            avatar={profile.smallLogo || profile.logo}
          />
        </StyledNameWrapper>
      </StyledListItem>
    );
  };

  const closeSidebar = () => {
    dispatch(closeModal());
  };

  useClickOutsideClose({ ref: refModal, callback: closeSidebar });

  return (
    <Sidebar ref={refModal}>
      <SidebarHeader
        label={task.title}
        onReturnClick={closeSidebar}
        variant="secondary"
      />
      <SidebarContent>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore*/}
        <AutoSizer>
          {({ width }) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <List
              rowRenderer={renderMember}
              height={737}
              rowCount={taskMembers.length}
              width={width}
              rowHeight={61}
            />
          )}
        </AutoSizer>
        {isEmpty(taskMembers) && (
          <ListPlaceholder>{t('common.noUsers')}</ListPlaceholder>
        )}
      </SidebarContent>
    </Sidebar>
  );
};

export default TaskDetails;
