// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { assoc, compose } from 'lodash/fp';

import {
  getAllSubscriptions,
  getSubscriptionPlanId,
  clearSubscriptionPlanId,
  getCurrentUserSubscription,
  setSubscription,
  switchSubscription,
  startTrial,
  activateSubscription,
  cancelSubscription,
  hasAdblocker,
} from 'store/actionCreators/subscriptions';
import { SubscriptionPlan, UserSubscription } from 'types';

interface SubscriptionsState {
  allSubscriptions: SubscriptionPlan[] | null;
  currentUserSubscription: UserSubscription;
  userSelectedSubscription: { id: string; frequency: string };
  paypalSubscriptionPlanId: string | null;
  isLoading: boolean;
  hasAdblocker: boolean;
}

const initialState: SubscriptionsState = {
  allSubscriptions: null,
  currentUserSubscription: null,
  userSelectedSubscription: null,
  paypalSubscriptionPlanId: null,
  isLoading: false,
  hasAdblocker: false,
};

export default (
  state: SubscriptionsState = initialState,
  { type, payload },
): SubscriptionsState => {
  switch (type) {
    case getAllSubscriptions.TRIGGER:
      return compose(assoc(['isLoading'], true))(state);
    case getAllSubscriptions.SUCCESS:
      return compose(
        assoc(['allSubscriptions'], payload),
        assoc(['isLoading'], false),
      )(state);
    case getAllSubscriptions.FAILURE:
      return compose(
        assoc(['allSubscriptions'], null),
        assoc(['isLoading'], false),
      )(state);
    case getSubscriptionPlanId.TRIGGER:
      return compose(assoc(['isLoading'], true))(state);
    case getSubscriptionPlanId.SUCCESS:
      return compose(
        assoc(['paypalSubscriptionPlanId'], payload),
        assoc(['isLoading'], false),
      )(state);
    case getSubscriptionPlanId.FAILURE:
      return compose(
        assoc(['paypalSubscriptionPlanId'], null),
        assoc(['isLoading'], false),
      )(state);
    case clearSubscriptionPlanId.TRIGGER:
      return compose(assoc(['paypalSubscriptionPlanId'], null))(state);
    case getCurrentUserSubscription.TRIGGER:
      return compose(assoc(['isLoading'], true))(state);
    case getCurrentUserSubscription.FAILURE:
      return compose(
        assoc(['currentUserSubscription'], null),
        assoc(['isLoading'], false),
      )(state);
    case setSubscription.TRIGGER:
      return compose(assoc(['isLoading'], true))(state);
    case setSubscription.SUCCESS:
      return compose(assoc(['isLoading'], false))(state);
    case setSubscription.FAILURE:
    case cancelSubscription.FAILURE:
      return compose(assoc(['isLoading'], false))(state);
    case switchSubscription.TRIGGER: {
      return compose(assoc(['userSelectedSubscription'], payload))(state);
    }
    case startTrial.TRIGGER: {
      return compose(assoc(['isLoading'], true))(state);
    }
    case activateSubscription.TRIGGER: {
      return compose(assoc(['isLoading'], true))(state);
    }
    case activateSubscription.SUCCESS:
    case getCurrentUserSubscription.SUCCESS:
    case cancelSubscription.SUCCESS:
      return compose(
        assoc(['currentUserSubscription'], payload),
        assoc(['isLoading'], false),
      )(state);
    case activateSubscription.FAILURE:
      return compose(assoc(['isLoading'], false))(state);
    case cancelSubscription.TRIGGER: {
      return compose(assoc(['isLoading'], true))(state);
    }
    case hasAdblocker.SUCCESS:
      return compose(assoc(['hasAdblocker'], true))(state);
    default:
      return state;
  }
};
