import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';

import newIconAnimation from 'static/animations/new.json';

import { CloseButton, Button } from 'components';

import appleCalImage from 'static/images/apple-cal-feature.png';
import appleCalImageGerman from 'static/images/apple-cal-feature-de.png';
import { Image } from 'components/InfoPopup/styled';
import { StyledAnchorTag } from 'pages/UserProfileCalendars/styled';
import { StyledBody, StyledHeader, StyledPopup, StyledFooter } from './styled';
import { Wrapper } from '../styled';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const AppleCalendarFeaturePopup: React.FC<Props> = ({ onClose, isOpen }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isGerman = i18n.language === 'de';
  const supportPageUrl =
    i18n.language === 'de'
      ? 'https://gumb.app/support/gumb-mit-apple-ical-kalender-verbinden/'
      : 'https://gumb.app/en/support/connect-gumb-with-apple-calender/';
  const imageUrl = i18n.language === 'de' ? appleCalImageGerman : appleCalImage;
  const handleClick = () => {
    navigate('/profile/connect-calendar', { replace: true });
    onClose();
  };
  const buttonStyle = {
    textTransform: 'none',
    fontSize: '16px',
  };

  const animationOptions = {
    animationData: newIconAnimation,
    loop: true,
    autoplay: true,
  };
  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StyledPopup>
                <CloseButton onClick={onClose} />
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  <StyledHeader>
                    <div style={{ position: 'relative', bottom: '0px' }}>
                      <Image
                        src={imageUrl}
                        style={{ border: 'none', marginBottom: '0px' }}
                        width="70%"
                      />
                    </div>
                  </StyledHeader>
                  <StyledBody>
                    <h3>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          justifyContent: 'center',
                        }}
                      >
                        <Lottie
                          options={animationOptions}
                          width="48px"
                          height="48px"
                          style={{ margin: '0px' }}
                        />
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('featurePopups.appleCalendar.title'),
                        }}
                      ></span>
                    </h3>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('featurePopups.appleCalendar.message'),
                      }}
                    ></p>
                    <span
                      style={{
                        marginTop: '16px',
                        fontWeight: 'normal',
                        color: '#304569',
                        display: isGerman ? 'flex' : 'auto',
                        flexDirection: isGerman ? 'row-reverse' : 'row',
                      }}
                    >
                      {t('featurePopups.appleCalendar.extraMessage')}
                      <StyledAnchorTag
                        target="_blank"
                        href={supportPageUrl}
                        display="inline-flex"
                        marginLeft="5px"
                        marginRight="5px"
                      >
                        {t('featurePopups.appleCalendar.redirectText')}
                      </StyledAnchorTag>
                    </span>
                  </StyledBody>
                  <StyledFooter>
                    <Button
                      variant="secondary"
                      onClick={onClose}
                      buttonStyles={buttonStyle}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.appleCalendar.cancelButton')}
                    </Button>
                    <Button
                      onClick={handleClick}
                      buttonStyles={{ ...buttonStyle, color: 'black' }}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.appleCalendar.okButton')}
                    </Button>
                  </StyledFooter>
                </PerfectScrollbar>
              </StyledPopup>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default AppleCalendarFeaturePopup;
