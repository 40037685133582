import styled from 'styled-components';

import { EyeIconGreen } from 'static';

export const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > button {
    margin-top: 25px;
  }
`;

export const LeftList = styled.div`
  width: 35%;
  height: 255px;
  overflow-y: scroll;
  padding-right: 10px;
  border-right: 2px solid ${({ theme }) => theme.color.porcelain};

  &::-webkit-scrollbar {
    display: none;
  }
`;

LeftList.Item = styled.div`
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: ${({ theme, selected }) =>
    selected ? theme.color.brightTurquoise : theme.color.porcelain};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
`;

export const RightList = styled.div`
  width: 65%;
  height: 255px;
  overflow-y: scroll;
  padding-left: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

RightList.Item = styled.div`
  font-size: 14px;
  padding: 8px 12px 8px 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.color.porcelain};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s, color 0.2s;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      max-width: 325px;
      width: max-content;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

RightList.Item.PreviewIcon = styled(EyeIconGreen)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
