import React, { useState } from 'react';
import { includes, map } from 'lodash/fp';
import { CheckBox } from 'components';

const useCheckboxes = (members, register, preSelectAll) => {
  const [allChecked, setAllChecked] = useState(preSelectAll ? true : false);
  const [checkboxes, setCheckboxes] = useState(
    preSelectAll ? members?.map((item) => item.id) : [],
  );

  const selectAll = () => {
    setAllChecked((prev) => !prev);
    return !allChecked
      ? setCheckboxes(map(({ id }) => id, members))
      : setCheckboxes([]);
  };

  const handleChange = ({ target }) => {
    const newValue = checkboxes.includes(target.name)
      ? checkboxes.filter((i) => i !== target.name)
      : [...checkboxes, target.name];

    setCheckboxes(newValue);

    if (allChecked && newValue.length < members.length) {
      setAllChecked(false);
    } else if (!allChecked && newValue.length === members.length) {
      setAllChecked(true);
    }
  };

  const createCheckbox = (id) => (
    <CheckBox
      isSelected={includes(id, checkboxes)}
      onChange={handleChange}
      key={id}
      register={register}
      name={id}
    />
  );

  return {
    createCheckbox,
    selectAll,
    allChecked,
    checkboxes,
  };
};

export default useCheckboxes;
