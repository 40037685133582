import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { doLogin } from 'store/actionCreators/auth';
import { useSignInForm } from './form';
import { useInfoPopup } from 'hooks';
import { getAmplifyAuthErrorMessage } from '../utils';

export const useSignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, setValue, ...form } = useSignInForm();
  const { showInfoPopup } = useInfoPopup();

  const onFailure = (error) => {
    const title = getAmplifyAuthErrorMessage(t, error);

    showInfoPopup({
      type: 'error',
      title,
    });
  };

  const onSubmit = handleSubmit((formData) => {
    dispatch(
      doLogin({
        email: formData.email.toLowerCase(),
        password: formData.password,
        onFailure,
      }),
    );
  });

  return {
    onSubmit,
    setValue,
    ...form,
  };
};
