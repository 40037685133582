import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';
import { useInfoPopup } from 'hooks';
import { EventType } from 'types';
import { doDeleteCommunityDefaultEventSetting } from 'store/actionCreators/currentCommunity';

export const useEventTypes = (communityId: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showInfoPopup } = useInfoPopup();

  const onAdd = () =>
    dispatch(
      openModal({
        type: EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING,
        placement: 'right',
      }),
    );

  const onEdit = (defaultValues: EventType) =>
    dispatch(
      openModal({
        type: EDIT_COMM_EVENT_TYPE_AND_DEFAULT_SETTING,
        placement: 'right',
        data: defaultValues,
      }),
    );

  const onSuccessDelete = () =>
    showInfoPopup({
      type: 'success',
      title: t('common.deleteSuccess.title'),
      message: t('common.deleteSuccess.message'),
      duration: 3000,
      loop: false,
      noButton: true,
    });

  const onDelete = (id) =>
    dispatch(
      doDeleteCommunityDefaultEventSetting({
        id,
        communityId,
        onSuccess: onSuccessDelete,
      }),
    );

  return {
    onDelete,
    onAdd,
    onEdit,
  };
};
