import styled from 'styled-components';

import { defaultButtonStyles } from 'pages/UserProfile/Header/styled';
import { AccountSettings } from 'static';

export const StyledEditIcon = styled(AccountSettings)`
  width: 18px;
  path {
    fill: ${({ theme }) => theme.color.black};
  }
`;
export const StyledEditText = styled.span`
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
  color: ${({ theme }) => theme.color.black};
  @media (max-width: 810px) {
    display: ${({ titleVisible }) => (titleVisible ? 'block' : 'none')};
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
  }
`;

export const EditButtonWrapper = styled.button`
  ${defaultButtonStyles};
  width: auto;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  padding: 18px 18px;
  border-radius: 36px;
  border: 0.5px solid ${({ theme }) => theme.color.brightTurquoise};
  transition: color, background-color 0.15s ease-in-out;
  &:hover ${StyledEditIcon} {
    path {
      fill: ${({ theme }) => theme.color.white};
    }
  }
  &:hover ${StyledEditText} {
    color: ${({ theme }) => theme.color.white};
  }

  &:hover {
    border-color: #33aba7c4;
    border: 0.5px solid transparent;
    background-color: #33aba7c4;
  }

  @media (max-width: 428px) {
    margin-right: 10px;
  }
`;
