import { Community, MyAppointmentsEvent } from 'types';
import { ControllerRenderProps } from 'react-hook-form';

export const getRecipientsFilteredByReplies = (
  selectedEvent: MyAppointmentsEvent,
  currentRecipients: string[],
  filters: { [p: string]: any },
) => {
  const recipients = selectedEvent.recipients;
  const isAwaiting = filters.find((i) => i.value === 'awaiting');
  const isPenalty = filters.find((i) => i.value === 'penalty');
  const isAttended = filters.find((i) => i.value === 'attended');
  const eventReplies = selectedEvent.subEventReplies;

  let users = [];

  if (filters.length === 0) {
    return [...new Set(currentRecipients.concat(selectedEvent.recipients))];
  }

  if (isAwaiting) {
    const usersWithoutReplies = recipients.filter(
      (id) => !eventReplies.some((r) => r.userId === id),
    );

    users = users.concat(usersWithoutReplies);
  }

  if (isPenalty) {
    const usersWithPenalty = eventReplies
      .filter((r) => r.penalty)
      .map((r) => r.userId);

    users = users.concat(usersWithPenalty);
  }

  if (isAttended) {
    const usersWithAttended = eventReplies
      .filter((r) => r.attended)
      .map((r) => r.userId);

    users = users.concat(usersWithAttended);
  }

  const eventRepliesIds = filters
    .filter((i) => !['penalty', 'attended', 'awaiting'].includes(i.value))
    .map((i) => i.value);

  if (eventRepliesIds.length) {
    const otherUsers = eventReplies
      .filter((r) => eventRepliesIds.includes(r.eventResponseId))
      .map((r) => r.userId);

    users = users.concat(otherUsers);
  }

  const uniqueRecipients = [...new Set(users)];
  const recipientsToRemove = recipients.filter(
    (id) => !uniqueRecipients.includes(id),
  );

  return [...new Set(currentRecipients.concat(uniqueRecipients))].filter(
    (id) => !recipientsToRemove.includes(id),
  );
};

export const handleRecipientsChange = <T>(
  value: string[],
  community: Community,
  controlledFieldProps: ControllerRenderProps<T>,
): void => {
  const selectedUsers = value
    .map((id) => community.users.find((u) => u.id === id)?.id)
    .filter((i) => i);

  controlledFieldProps.onChange(selectedUsers);
};
