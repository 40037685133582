import styled, { css } from 'styled-components';
import { StyledWrapper } from '../Inputs/styled';
import { BorderMIcon, BorderPIcon } from 'static';

export const StyledInputWrapper = styled(StyledWrapper)`
  flex: 1;
  max-width: 120px;
  @media (max-width: 480px) {
    display: none;
  } ;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  border: 1px solid
    ${({ theme, color }) =>
      color === 'danger' ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-align: center;
  -moz-appearance: textfield;
  pointer-events: none;
  user-select: none;

  &::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &::-webkit-outer-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const StyledInputBox = styled.div`
  max-width: 120px;
  width: 120px;
  height: 39px;
  position: relative;
  margin: auto;
`;

export const StyledLeftIcon = styled(BorderMIcon)`
  position: absolute;
  z-index: 1;
  left: 9px;
  top: calc(50% - 9px);
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  ${({ disabled }) =>
    disabled
      ? `
        pointer-events: none;
        opacity: 0.5;
    `
      : `
        cursor: pointer;
    `};
  ${({ color }) =>
    color === 'danger'
      ? `
        filter: invert(13%) sepia(96%) saturate(7477%) hue-rotate(0deg) brightness(114%) contrast(106%);
    `
      : color === 'black'
      ? css`
          path {
            fill: ${({ theme }) => theme.color.brightTurquoise};
          }
        `
      : `
        filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(572%) hue-rotate(158deg) brightness(89%) contrast(86%);
    `};
  &:active {
    transform: scale(1.2);
  }

  &:hover {
    border: 0.5px solid ${({ theme }) => theme.color.black};
    ${({ color }) =>
      color !== 'danger' &&
      css`
        background-color: ${({ theme }) => theme.color.black};
      `};
  }
`;

export const StyledRightIcon = styled(BorderPIcon)`
  position: absolute;
  z-index: 1;
  right: 9px;
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(572%)
    hue-rotate(158deg) brightness(89%) contrast(86%);
  ${({ disabled }) =>
    disabled
      ? `
        pointer-events: none;
        opacity: 0.5;
    `
      : `
        cursor: pointer;
    `};

  ${({ color }) =>
    color === 'danger'
      ? `
        filter: invert(13%) sepia(96%) saturate(7477%) hue-rotate(0deg) brightness(114%) contrast(106%);
    `
      : `
        filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(572%) hue-rotate(158deg) brightness(89%) contrast(86%);
    `};

  &:hover {
    ${({ color }) =>
      color !== 'danger' &&
      `
        filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%) hue-rotate(128deg) brightness(95%) contrast(87%);
        cursor: pointer;
    `};
  }
`;
