import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Grid from 'components/Grid/gridv2';
import { useGridData, useFooterData } from './hooks';
import { STATISTICS_TYPES } from 'configs';
import { getEventTypes } from 'store/selectors/eventTypes';
import { getEventResponsesForScheduler } from 'store/selectors/eventResponses';
import { OverallStatistics } from 'types';

import './styles.css';

interface StatisticsTotalProps {
  type: STATISTICS_TYPES;
  statistic: OverallStatistics;
  zoom: number;
  gridDataHandler: (data: any[]) => void;
}

const StatisticsTotal: FC<StatisticsTotalProps> = ({
  type,
  statistic,
  zoom,
  gridDataHandler,
}) => {
  const { t } = useTranslation();
  const eventTypes = useSelector(getEventTypes);
  const responses = useSelector(getEventResponsesForScheduler) || [];

  const getFilteredEventReplies = responses.map(({ title, color, id }) => {
    if (id === 'penalty' || id === 'attended') {
      title = t(`community.${id}`);
    }
    return {
      color,
      title: t(title),
      placement: 'statistic',
    };
  });

  const getFilteredEventTypes = eventTypes.map(({ title, color }) => {
    return {
      color,
      title,
      placement: 'statistic',
    };
  });

  const gridData = useGridData(statistic, responses, type, eventTypes);

  gridDataHandler(gridData);

  const footerData = useFooterData(responses, statistic, eventTypes, type);

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Grid
          data={gridData}
          footer={footerData}
          header={
            type === STATISTICS_TYPES.EVENT_REPLIES
              ? getFilteredEventReplies
              : getFilteredEventTypes
          }
          zoom={zoom}
          statisticsTab={true}
          topHeadHeight={
            window.innerWidth > 600 ? 386 : window.innerWidth > 480 ? 351 : 334
          }
        />
      </div>
    </div>
  );
};

export default StatisticsTotal;
