import { call, put, takeEvery } from 'redux-saga/effects';

import {
  doGetCommunityTasks,
  doDeleteCommunityTask,
  doCreateCommunityTask,
  doEditCommunityTask,
} from 'store/actionCreators/currentCommunity';
import {
  requestCommunityTasks,
  requestDeleteCommunityTask,
  requestCreateCommunityTask,
  requestEditCommunityTask,
} from 'services/events';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

function* onGetCommunityTasks({
  payload,
}: ReturnType<typeof doGetCommunityTasks>) {
  try {
    const data = yield call(requestCommunityTasks, payload);
    yield put(doGetCommunityTasks.success(data));
  } catch ({ task }) {
    yield put(doGetCommunityTasks.failure(task));
  }
}

function* onCreateCommunityTask({
  payload,
}: ReturnType<typeof doCreateCommunityTask>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      requestCreateCommunityTask,
      payload.communityId,
      payload.data,
    );
    yield put(doCreateCommunityTask.success(data));
    yield call(payload.onSuccess);
  } catch ({ task }) {
    yield put(doCreateCommunityTask.failure(task));
  }
}

function* onEditCommunityTask({
  payload,
}: ReturnType<typeof doEditCommunityTask>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, id } = payload;
    const data = yield call(
      requestEditCommunityTask,
      communityId,
      id,
      payload.data,
    );
    yield put(doEditCommunityTask.success(data));
    yield call(payload.onSuccess);
  } catch ({ task }) {
    yield put(doEditCommunityTask.failure(task.data));
  }
}

function* onDeleteCommunityTask({
  payload,
}: ReturnType<typeof doDeleteCommunityTask>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    yield call(requestDeleteCommunityTask, payload.communityId, payload.id);
    yield put(doDeleteCommunityTask.success(payload));
    yield call(payload.onSuccess);
  } catch ({ task }) {
    yield put(doDeleteCommunityTask.failure(task));
  }
}

export default function* () {
  yield takeEvery(doGetCommunityTasks, onGetCommunityTasks);
  yield takeEvery(doDeleteCommunityTask, onDeleteCommunityTask);
  yield takeEvery(doCreateCommunityTask, onCreateCommunityTask);
  yield takeEvery(doEditCommunityTask, onEditCommunityTask);
}
