import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from 'components/Buttons/Button';
import { GumbLogo } from 'static';
import LogoImg from 'static/BGLogo.jpg';

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100svh;
  overflow: hidden scroll;
  background: white;
`;

export const Header = styled.h1`
  position: relative;
  font-size: 26px;
  color: ${(props) => props.theme.color.pickledBluewood};
  font-weight: 400;

  & > svg {
    position: absolute;
    left: -5px;
    top: 13px;
    cursor: pointer;
  }
`;

export const MessageContainer = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.color.pickledBluewood};
`;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 50vw;
  min-height: 700px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 60px 0;
  margin-right: 38vw;

  @media (max-width: 1240px) {
    height: 100%;
    padding: 30px 0;
    margin-right: 0;
  }

  form {
    margin: auto;
  }
`;

export const ImageContainer = styled.div`
  position: fixed;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 40vw;
  height: 100%;
  background-image: url(${LogoImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 1240px) {
    display: none;
  }
`;

export const StyledGumbLogo = styled(GumbLogo)`
  display: none;
  margin-bottom: 20px;

  @media (max-width: 1240px) {
    display: inline;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 15px;

  @media (max-width: 350px) {
    max-width: 250px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  font-size: 13px;
  font-weight: 400;
`;

export const ForgotPassword = styled(Link)`
  color: ${(props) => props.theme.color.baliHai};
  text-decoration: none;
`;

export const StyledForm = styled.form`
  max-width: 340px;
  @media (max-width: 350px) {
    max-width: 250px;
  }
`;

export const StyledLink = styled.a`
  color: ${(props) => props.theme.color.brightTurquoise};
  text-decoration: none;

  &:visited {
    color: ${(props) => props.theme.brightTurquoise};
  }
`;
