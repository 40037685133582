import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import { decodeJwt } from 'jose';
import validate from 'uuid-validate';

import Notification from './Notification';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import Languages from './Language';
import TimeFormat from './TimeFormat';
import Permissions from './Permissions';
import Other from './Other';
import { ContentWrapper, Loader, Tabs } from 'components';
import {
  getChangeProfileSettingsLoading,
  getFullAuthUser,
} from 'store/selectors/auth';

import { Header, Content, StyledFloatingLoader } from './styled';

const UserProfileSettingsPage = () => {
  const { t } = useTranslation();
  const user = useSelector(getFullAuthUser);
  const loading = useSelector(getChangeProfileSettingsLoading);
  const decoded = decodeJwt(user?.idToken);
  const canChangeCredentials = decoded && validate(decoded['cognito:username']);

  if (isEmpty(user)) {
    return <Loader />;
  }

  return (
    <ContentWrapper minHeight="auto">
      {loading && (
        <StyledFloatingLoader>
          <Loader size="60px" type={null} />
        </StyledFloatingLoader>
      )}
      <Header>{t('profileSettings.title')}</Header>
      <Content>
        <Tabs>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div label={t('profileSettings.notification')} index={0}>
            <Notification />
          </div>
          {canChangeCredentials && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div label={t('profileSettings.changeEmail')} index={1}>
              <ChangeEmail />
            </div>
          )}
          {canChangeCredentials && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div label={t('profileSettings.changePassword')} index={2}>
              <ChangePassword />
            </div>
          )}
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t('profileSettings.language')}
            index={canChangeCredentials ? 3 : 1}
          >
            <Languages />
          </div>
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t('profileSettings.timeFormat')}
            index={canChangeCredentials ? 4 : 2}
          >
            <TimeFormat />
          </div>
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t('profileSettings.permissions')}
            index={canChangeCredentials ? 5 : 3}
          >
            <Permissions />
          </div>
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t('profileSettings.other')}
            index={canChangeCredentials ? 6 : 4}
          >
            <Other />
          </div>
        </Tabs>
      </Content>
    </ContentWrapper>
  );
};

export default UserProfileSettingsPage;
