import styled from 'styled-components';
import { Button } from 'components';

export const StyledAddMembersButton = styled(Button)`
  margin: 0 0 20px 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledListItem = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  font-weight: 400;
  font-size: 15px;
  overflow: hidden;
  cursor: pointer;
`;

export const StyledNameWrapper = styled.div`
  width: 290px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 1.5%;
  /* position: relative;
  border-radius: 30px;
  :hover {
    opacity: 0.4;
    background-color: ${({ theme }) => theme.color.blackSqueeze};
    transition: all 0.35s;
    svg {
      visibility: visible;
      transition: all 0.35s;
    }
  } */
`;

export const StyledGroupItem = styled.div`
  background-color: #ebf2f7;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #8298ab;
  padding-left: 15px;
  font-size: 12px;
`;

export const StyledAvatarNoPhoto = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  color: ${({ theme }) => theme.color.regentGray};
`;
