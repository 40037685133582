import React, { FC } from 'react';

import { StyledWrapper, StyledText } from './styled';
import { StyledFileIcon } from '../FileItem/styled';

interface IconDocumentProps {
  name: string;
}

const IconDocument: FC<IconDocumentProps> = ({ name }) => (
  <StyledWrapper>
    <StyledFileIcon />
    <StyledText>{name}</StyledText>
  </StyledWrapper>
);

export default IconDocument;
