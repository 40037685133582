import { useEffect, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { doAddNotification } from 'store/actionCreators/profile';
import {
  doRequestCommunities,
  doUpdateCommunity,
  doRequestCommunityInvites,
} from 'store/actionCreators/communities';
import {
  doCreateOverallEventReply,
  doUpdateOverallEventReply,
  doDeleteOverallEventReply,
  doCreateEventReply,
  doUpdateEventReply,
  doDeleteEventReply,
  doCreateCommunityEvent,
  doUpdateCommunityEvents,
  doDeleteCommunityEvents,
  doCreateEvent,
  doUpdateEvents,
  doDeleteEvents,
  doCreateCommunityTask,
  doEditCommunityTask,
  doDeleteCommunityTask,
  doChangeUserProfile,
  doRequestCurrentCommunity,
} from 'store/actionCreators/currentCommunity';
import { getCurrentUserSubscription } from 'store/actionCreators/subscriptions';
import {
  doCreateGroup,
  doDeleteGroup,
  doUpdateGroup,
} from 'store/actionCreators/groups';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getCurrentIdToken } from 'store/selectors/auth';
import { messageTypes, typeToRoutes } from 'utils';
import { Community } from 'types';

const NotificationsListener = () => {
  const { addToast, removeAllToasts } = useToasts();
  const dispatch = useDispatch();
  const currentCommunity = useSelector(getCurrentCommunity);
  const idToken = useSelector(getCurrentIdToken);

  const currentCommunityRef = useRef<Community>();

  const addNotification = async (notification) => {
    await dispatch(doAddNotification(notification));
    const { message, type } = notification;
    switch (type) {
      case messageTypes.COMMUNITY_INVITE_APPROVED: {
        addToast(message, {
          appearance: 'success',
          autoDismiss: true,
        });
        //fetch current community if user is on same community route.
        const isRouteWithCurrentCommunityId = window.location.href
          .split('/')
          .includes(currentCommunityRef.current.id);

        if (isRouteWithCurrentCommunityId)
          return (() => {
            dispatch(
              doRequestCurrentCommunity({ communityId: currentCommunity.id }),
            );
            dispatch(doRequestCommunities());
          })();
        return dispatch(doRequestCommunities());
      }
      case messageTypes.CANCEL_SUBSCRIPTION:
      case messageTypes.SUBSCRIPTION_REMINDER: {
        return addToast(message, {
          appearance: 'success',
          autoDismiss: false,
        });
      }
      case messageTypes.ACTIVATE_SUBSCRIPTION: {
        removeAllToasts();

        return addToast(message, {
          appearance: 'success',
          autoDismiss: false,
        });
      }
      default:
        return addToast(notification, {
          appearance: 'success',
          autoDismiss: true,
        });
    }
  };

  const handleMessage = (message) => {
    const data = JSON.parse(message);
    const { type } = data;
    const verificationFunc = typeToRoutes[type];

    if (
      !verificationFunc ||
      !verificationFunc({
        communityId: data?.data?.communityId,
        currentCommunity,
        userId: data?.data?.userId,
      })
    ) {
      return;
    }

    switch (type) {
      case messageTypes.CREATE_COMMUNITY_EVENT_REPLY: {
        return dispatch(
          doCreateOverallEventReply.success(
            Array.isArray(data?.data) ? data.data : [data.data],
          ),
        );
      }
      case messageTypes.UPDATE_COMMUNITY_EVENT_REPLY: {
        return dispatch(
          doUpdateOverallEventReply.success(
            Array.isArray(data?.data) ? data.data : [data.data],
          ),
        );
      }
      case messageTypes.DELETE_COMMUNITY_EVENT_REPLY: {
        return dispatch(doDeleteOverallEventReply.success(data.data));
      }
      case messageTypes.CREATE_GROUP_EVENT_REPLY: {
        return dispatch(
          doCreateEventReply.success(
            Array.isArray(data?.data) ? data.data : [data.data],
          ),
        );
      }
      case messageTypes.UPDATE_GROUP_EVENT_REPLY: {
        return dispatch(
          doUpdateEventReply.success({
            response: Array.isArray(data?.data) ? data.data : [data.data],
            userId: Array.isArray(data?.data)
              ? data.data[0].userId
              : data.data.userId,
          }),
        );
      }
      case messageTypes.DELETE_GROUP_EVENT_REPLY: {
        return dispatch(doDeleteEventReply.success(data.data));
      }
      case messageTypes.CREATE_COMMUNITY_EVENT: {
        return dispatch(
          doCreateCommunityEvent.success(
            data.data.map((event) => ({
              ...event,
              subEventReplies: [],
              subEventTaskReplies: [],
            })),
          ),
        );
      }
      case messageTypes.UPDATE_COMMUNITY_EVENT: {
        return dispatch(
          doUpdateCommunityEvents.success({ response: data.data }),
        );
      }
      case messageTypes.DELETE_COMMUNITY_EVENT: {
        return dispatch(
          doDeleteCommunityEvents.success({ ids: data.data.data }),
        );
      }
      case messageTypes.CREATE_GROUP_EVENT: {
        return dispatch(
          doCreateEvent.success(
            data.data.map((event) => ({
              ...event,
              subEventReplies: [],
              subEventTaskReplies: [],
            })),
          ),
        );
      }
      case messageTypes.UPDATE_GROUP_EVENT: {
        return dispatch(doUpdateEvents.success({ response: data.data }));
      }
      case messageTypes.DELETE_GROUP_EVENT: {
        return dispatch(doDeleteEvents.success({ ids: data.data.data }));
      }
      case messageTypes.CREATE_EVENT_TASK: {
        return dispatch(doCreateCommunityTask.success(data.data));
      }
      case messageTypes.UPDATE_EVENT_TASK: {
        return dispatch(doEditCommunityTask.success(data.data));
      }
      case messageTypes.DELETE_EVENT_TASK: {
        return dispatch(doDeleteCommunityTask.success(data.data));
      }
      case messageTypes.CREATE_GROUP: {
        return dispatch(doCreateGroup.success(data.data));
      }
      case messageTypes.UPDATE_GROUP: {
        return dispatch(doUpdateGroup.success(data.data));
      }
      case messageTypes.DELETE_GROUP: {
        const { communityId, groupId } = data.data;
        return dispatch(doDeleteGroup.success({ communityId, groupId }));
      }
      case messageTypes.UPDATE_COMMUNITY: {
        return dispatch(doUpdateCommunity.success(data.data));
      }
      case messageTypes.UPDATE_PROFILE: {
        return dispatch(doChangeUserProfile.success(data.data));
      }
      case messageTypes.GET_COMMUNITY_INVITES:
        return dispatch(doRequestCommunityInvites.success(data.data));
      case messageTypes.ACTIVATE_SUBSCRIPTION:
        if (data.data) {
          dispatch(getCurrentUserSubscription.success(data.data));
        }
        return addNotification(data);
      default:
        return addNotification(data);
    }
  };

  const connect = () => {
    const socket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}?Authorization=${idToken}`,
    );

    socket.onmessage = (event) => handleMessage(event.data);

    return socket;
  };

  const checkConnection = (socket) => {
    if (socket.readyState !== 1) {
      return connect();
    }

    return null;
  };

  useEffect(() => {
    let socket = connect();
    const interval = setInterval(() => {
      const result = checkConnection(socket);

      if (result) {
        socket = result;
      }
    }, 30000);

    return () => {
      if (socket) {
        socket.close();
      }
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    currentCommunityRef.current = currentCommunity;
  }, [currentCommunity]);

  return null;
};

export default NotificationsListener;
