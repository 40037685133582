import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const StyledArea = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
  pointer-events: all;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: #10ccc6;
    }

    &:checked + span:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }

    &:focus + span {
      box-shadow: 0 0 1px #2196f3;
    }
  }
`;

export const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  border-radius: 34px;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    border-radius: 50%;
    transition: 0.4s;
  }
`;
