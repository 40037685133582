import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getClient,
  getCurrentChat,
  getChatsAuthenticated,
} from 'store/selectors/chat';
import { getMyId } from 'store/selectors/auth';
import { doSetCurrentChat } from 'store/actionCreators/chat';
import { Chat } from 'components';

import { ChatWrapper } from './styled';

const ChatContainer = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const channel = useSelector(getCurrentChat);
  const client = useSelector(getClient);
  const isAuth = useSelector(getChatsAuthenticated);
  const authId = useSelector(getMyId);

  useEffect(() => {
    isAuth && dispatch(doSetCurrentChat({ userId, authId }));
  }, [isAuth, userId, authId, doSetCurrentChat]);

  return channel ? (
    <ChatWrapper>
      <Chat channel={channel} client={client} />
    </ChatWrapper>
  ) : null;
};

export default ChatContainer;
