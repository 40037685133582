import styled from 'styled-components';

export const StyledLink = styled.div`
  padding: 10px;
  border-right: 2px solid ${({ theme }) => theme.color.lightGrey};
  color: ${({ theme }) => theme.color.brightTurquoise};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70%;
  font-weight: 400;
  height: 40px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  /* height: 32px; */
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.color.brightTurquoise};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const CopyButtonWrapper = styled.div`
  position: relative;
  width: ${({ expand }) => (expand ? '50%' : '30%')};
  display: flex;
  justify-content: center;
  overflow: hidden;
  min-width: 144px;
`;
