import styled from 'styled-components';

export const StyledParagraph = styled.div`
  /* display: inline-block; */
  /* width: 100%; */
  position: relative;
  max-height: ${({ maxHeight }) => maxHeight};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  overflow: ${({ overflow = 'hidden' }) => overflow};
  text-overflow: ellipsis;
  /* height: fit-content; */
  /* white-space: nowrap; */
`;

export const StyledText = styled.div`
  /* height: fit-content; */
`;
export const StyledDescriptionText = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-weight: normal;
  white-space: pre-wrap;
  line-break: normal;
  a {
    color: ${({ theme }) => theme.color.brightTurquoise};
    word-wrap: break-word;
  }
`;

export const StyledDots = styled.div`
  display: inline;
  padding-right: 4px;
`;

export const Extension = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 10;
  padding: 0px 4px;
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const StyledButton = styled.p`
  display: inline;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
`;
