import React from 'react';

import { EventReply, EventTaskReply } from 'types';
import { StyledText } from 'sidebars/EventDetails/Event/styled';
import { UserAvatar } from 'components';
import { getProfileName } from 'utils';

import { EventCard } from './styled';

export const getProfilesListInnerData = (
  replies: EventReply[] | EventTaskReply[],
  zoom: number,
  type: 'task' | 'reply',
) => {
  return replies.map((r) => (
    <EventCard.Task.ProfileRow key={`${r.id}-${type}`} zoom={zoom}>
      <UserAvatar
        key={`${r.id}-${type}-avatar`}
        avatar={r.user.profile?.smallLogo || r.user.profile?.logo}
        alt={r.user.profile?.email}
        style={{
          width: 20 * zoom + 'px',
          height: 20 * zoom + 'px',
          minWidth: 20 * zoom + 'px',
          margin: 0,
          marginRight: 8 * zoom + 'px',
        }}
      />
      <StyledText
        fontSize={12 * zoom + 'px'}
        lineHeight={16 * zoom + 'px'}
        ellipsis={true}
      >
        {getProfileName(r.user.profile)}
      </StyledText>
    </EventCard.Task.ProfileRow>
  ));
};
