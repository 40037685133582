import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash/fp';

import { RadioButton, Loader } from 'components';
import { TIME_FORMATS } from 'constants/timeFormats';
import {
  getChangeProfileSettingsLoading,
  getFullAuthUser,
  getSettings,
} from 'store/selectors/auth';
import { useSettings } from '../hooks';

import { StyledWrapper } from '../styled';

const TimeFormat = () => {
  const user = useSelector(getFullAuthUser);
  const settings = useSelector(getSettings);
  const isLoading = useSelector(getChangeProfileSettingsLoading);
  const { register, onSubmit } = useSettings(user.id);

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <form>
        {map(
          (format) => (
            <StyledWrapper key={format.value}>
              <RadioButton
                register={register}
                name="timeFormat"
                label={format.label}
                value={format.value}
                checked={format.value === settings.timeFormat}
                onChange={onSubmit}
              />
            </StyledWrapper>
          ),
          TIME_FORMATS,
        )}
      </form>
    </>
  );
};

export default TimeFormat;
