import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tabs, CloseButton } from 'components';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { doRequestCurrentCommunity } from 'store/actionCreators/currentCommunity';
import { getCommunities } from 'store/selectors/communities';
import NotificationsTab from './NotificationsTab';
import EventTypesTab from './EventTypes';
import ResponsesTab from './Responses';
import TasksTab from './Tasks';
import GeneralTab from './General';
import Roles from './Members';
import Requests from './Requests';
import Other from './Other';
import { CommunityRoles } from 'types';
import {
  StyledContentWrapper,
  StyledHeader,
  StyledTitle,
  TabWrapper,
} from './styled';
const CommunitySettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const communities = useSelector(getCommunities);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const { communityId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!community) {
      dispatch(doRequestCurrentCommunity({ communityId }));
    }
  }, [doRequestCurrentCommunity, communityId, community]);

  const userRole = currentUser?.communityPermission.role;
  const invitesCount =
    communities.find((c) => c.id === community?.id)?.requests?.length || 0;

  const goTo404 = () => {
    navigate('/404');
  };

  const goBack = () => {
    navigate(-1);
  };

  if (!community) {
    return null;
  } else if (
    userRole !== CommunityRoles.ADMIN &&
    userRole !== CommunityRoles.OWNER
  ) {
    goTo404();
  }

  return (
    <StyledContentWrapper isMenuOpen={isMenuOpen}>
      {/*<Prompt when={edit} message={t('common.modalExit')} />*/}
      <StyledHeader isMenuOpen={isMenuOpen}>
        <StyledTitle isMenuOpen={isMenuOpen}>
          {t('communitySettings.title')}
        </StyledTitle>
        <CloseButton hidden={false} onClick={goBack} />
      </StyledHeader>
      <Tabs invitesCount={invitesCount}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('communitySettings.tabs.general')} index={0}>
          <TabWrapper>
            <GeneralTab />
          </TabWrapper>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('common.notifications')} index={1}>
          <TabWrapper>
            <NotificationsTab />
          </TabWrapper>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('communitySettings.tabs.eventTypes')} index={2}>
          <EventTypesTab />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('communitySettings.tabs.responses')} index={3}>
          <ResponsesTab />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('communitySettings.tabs.tasks')} index={4}>
          <TasksTab />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('communitySettings.tabs.roles')} index={5}>
          <Roles />
        </div>
        <div
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tabId="request"
          label={t('communitySettings.tabs.requests')}
          index={6}
        >
          <Requests />
        </div>
        {currentUser.communityPermission?.role === CommunityRoles.OWNER && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <div label={t('communitySettings.tabs.other')} index={7}>
            <Other />
          </div>
        )}
      </Tabs>
    </StyledContentWrapper>
  );
};

export default CommunitySettingsPage;
