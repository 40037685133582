import React, { useState, useEffect } from 'react';

import SubscriptionCard from './index';

const MultiSubscriptionCard = ({
  subscriptions,
  pricing,
  currentUserSubscription,
}) => {
  const [planInd, setPlanInd] = useState(0);

  useEffect(() => {
    if (currentUserSubscription) {
      const ind = subscriptions.findIndex(
        (subs) => subs.id === currentUserSubscription?.subscriptionPlanId,
      );

      if (ind >= 0) {
        setPlanInd(ind);
      }
    }
  }, [currentUserSubscription]);

  const addHandler = () => {
    if (planInd === subscriptions.length - 1) return;

    setPlanInd(planInd + 1);
  };

  const subHandler = () => {
    if (!planInd) return;

    setPlanInd(planInd - 1);
  };

  return (
    <SubscriptionCard
      isCustom
      pricing={pricing}
      currentUserSubscription={currentUserSubscription}
      addHandler={addHandler}
      subHandler={subHandler}
      {...subscriptions[planInd]}
    />
  );
};

export default MultiSubscriptionCard;
