import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useController, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  RECIPIENTS = 'recipients',
  SEND_TO_ME = 'sendCopy',
  OTHER_RECIPIENTS = 'otherRecipients',
  SUBJECT = 'subject',
  MESSAGE = 'message',
  ATTACHMENTS = 'attachments',
}

export type EmailModuleFormValues = {
  [FIELDS.RECIPIENTS]: string[];
  [FIELDS.SEND_TO_ME]: boolean;
  [FIELDS.OTHER_RECIPIENTS]: string;
  [FIELDS.SUBJECT]: string;
  [FIELDS.MESSAGE]: string;
  [FIELDS.ATTACHMENTS]: any[];
};

export const useEmailModuleForm = (defaultValues: EmailModuleFormValues) => {
  const { t } = useTranslation();

  const resolver: Resolver<EmailModuleFormValues> = yupResolver(
    yup.object().shape({
      [FIELDS.SEND_TO_ME]: yup.bool(),
      [FIELDS.RECIPIENTS]: yup
        .array()
        .of(yup.string())
        .required(t('errors.required')),
      [FIELDS.OTHER_RECIPIENTS]: yup
        .array()
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s,]+/) : [];
        })
        .of(
          yup
            .string()
            .email(({ value }) => `${t('errors.email')} (${value})`)
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              {
                excludeEmptyString: true,
                message: ({ value }) => `${t('errors.email')} (${value})`,
              },
            ),
        ),
      [FIELDS.SUBJECT]: yup.string().required(t('errors.required')),
      [FIELDS.MESSAGE]: yup.string().required(t('errors.required')),
    }),
  );

  const form = useForm<EmailModuleFormValues>({
    resolver,
    defaultValues,
  });

  const { field: attachmentsFieldProps } = useController({
    name: FIELDS.ATTACHMENTS,
    control: form.control,
    defaultValue: defaultValues[FIELDS.ATTACHMENTS],
  });

  const { field: recipientsFieldProps } = useController({
    name: FIELDS.RECIPIENTS,
    control: form.control,
    defaultValue: defaultValues[FIELDS.RECIPIENTS],
  });

  const { field: sendToMeFieldProps } = useController({
    name: FIELDS.SEND_TO_ME,
    control: form.control,
    defaultValue: defaultValues[FIELDS.SEND_TO_ME],
  });

  const values = useWatch({
    name: [
      FIELDS.SEND_TO_ME,
      FIELDS.RECIPIENTS,
      FIELDS.OTHER_RECIPIENTS,
      FIELDS.SUBJECT,
      FIELDS.MESSAGE,
      FIELDS.ATTACHMENTS,
    ],
    control: form.control,
    defaultValue: defaultValues,
  });

  return {
    ...form,
    values,
    attachmentsFieldProps,
    recipientsFieldProps,
    sendToMeFieldProps,
  };
};
