import React, { FC, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import { InfoIcon } from 'static';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import { StyledSelect } from './styled';

interface SelectInputProps {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
  width?: string;
  defaultValue?: { value: string; label: string };
  setValue: (name: string, value: string) => void;
  register: any;
  info?: string;
  error?: FieldError;
  clearError?: (name: string) => void;
  isDisabled?: string;
  fullHeight?: boolean;
}

const SelectInput: FC<SelectInputProps> = ({
  name,
  label,
  options,
  width,
  defaultValue,
  setValue = () => {},
  register = () => {},
  info,
  error,
  clearError,
  isDisabled,
  fullHeight,
}) => {
  const { t } = useTranslation();
  const defVal =
    !!defaultValue && options.find(({ value }) => value === defaultValue.value);
  const [value, changeValue] = useState(defVal);

  useEffect(() => {
    register({ name });

    if (defVal) {
      setValue(name, defVal.value);
    }
  }, []);

  const changeHandler = (v) => {
    changeValue(v);

    if (v) {
      setValue(name, v.value);
    }

    if (clearError) {
      clearError(name);
    }
  };

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}{' '}
          {info && (
            <>
              <InfoIcon data-tip data-for="infoTip" />
              <ReactTooltip id="infoTip" effect="solid">
                {info}
              </ReactTooltip>
            </>
          )}
        </StyledLabel>
      )}
      <StyledSelect
        error={error}
        width={width}
        id={name}
        name={name}
        options={options}
        placeholder=""
        classNamePrefix="react-select"
        onChange={changeHandler}
        value={value}
        isDisabled={isDisabled}
        fullHeight={fullHeight}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default SelectInput;
