import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Button, Loader, MultiSelectInput } from 'components';
import { useChangeAllowedToEdit } from './hooks';
import { FIELDS } from './form';
import {
  getCommunities,
  getCommunitiesLoading,
} from 'store/selectors/communities';
import { getEditProfileLoading, getFullAuthUser } from 'store/selectors/auth';

import { StyledButtonBlock, StyledForm, StyledWrapper } from './styled';

const Permissions = () => {
  const { t } = useTranslation();
  const user = useSelector(getFullAuthUser);
  const communities = useSelector(getCommunities);
  const communitiesLoading = useSelector(getCommunitiesLoading);
  const saving = useSelector(getEditProfileLoading);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const defaultValues = useMemo(
    () =>
      user.profile.allowedToEdit?.map((id) => ({
        value: id,
        label: communities.find((c) => c.id === id)?.name,
      })) || [],
    [user, communities],
  );

  const { onSubmit, register, setValue, watcher } =
    useChangeAllowedToEdit(defaultValues);

  useEffect(() => {
    const hasChanged = !isEqual(watcher, defaultValues);

    if (hasChanged === !formChanged) {
      setFormChanged(hasChanged);
    }
  }, [watcher, formChanged]);

  return communitiesLoading ? (
    <Loader />
  ) : (
    <StyledForm onSubmit={onSubmit}>
      <StyledWrapper>
        <MultiSelectInput
          width="100%"
          options={communities.map((c) => ({
            value: c.id,
            label: c.name,
          }))}
          label={t('profileSettings.allowedToEdit')}
          defaultValue={defaultValues}
          setValue={setValue}
          name={FIELDS.ALLOWED_TO_EDIT}
          register={register}
        />
      </StyledWrapper>
      <StyledButtonBlock>
        {saving ? (
          <Loader type="button" size="28px" thickness={2} />
        ) : (
          <Button disabled={!formChanged} type="submit">
            {t('common.saveChanges')}
          </Button>
        )}
      </StyledButtonBlock>
    </StyledForm>
  );
};

export default Permissions;
