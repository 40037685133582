import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';

import {
  StyledMenuItemBlock,
  StyledIcon,
  StyledTitle,
  StyledMenuItemLinkBlock,
  StyledRightIcon,
} from './styled';
import { PlusButtonWrapper } from '../CommunityMenuListWrapper/styled';
import PlusButton from 'components/Buttons/PlusButton';
import { tooltipProps } from '../CommunityMenuListWrapper';

interface CommunityMenuItemProps {
  title: string;
  icon: ReactNode;
  to?: string;
  left?: boolean;
  iconStyles?: CSSProperties;
  RightIcon?: JSX.Element;
  menuBlockStyles?: React.CSSProperties;
  toolTipText?: string;
  isAdminOrOwner?: boolean;
  isAddButtonContained?: boolean;
  onClick?: () => void;
}

const CommunityMenuItem: FC<CommunityMenuItemProps> = ({
  title,
  left,
  icon,
  to,
  iconStyles,
  RightIcon = <></>,
  menuBlockStyles,
  toolTipText,
  isAdminOrOwner,
  isAddButtonContained,
  onClick,
}) => {
  return (
    <>
      {to ? (
        <StyledMenuItemLinkBlock
          end
          to={to}
          left={left}
          menuBlockStyles={menuBlockStyles}
        >
          <StyledIcon iconStyles={iconStyles}>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
          {RightIcon && <StyledRightIcon>{RightIcon}</StyledRightIcon>}
          {isAddButtonContained && isAdminOrOwner && (
            <PlusButtonWrapper style={{ right: '9px' }}>
              <PlusButton
                text={toolTipText}
                tooltipStyle={tooltipProps.tooltipStyle}
                position="top-center"
                width="28px"
                contained={isAddButtonContained}
                onClick={onClick}
                adjustToolTipPosition={{ x: -0.7 }}
              />
            </PlusButtonWrapper>
          )}
        </StyledMenuItemLinkBlock>
      ) : (
        <StyledMenuItemBlock to={to}>
          <StyledIcon iconStyles={iconStyles}>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
        </StyledMenuItemBlock>
      )}
    </>
  );
};

export default CommunityMenuItem;
