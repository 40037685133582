import styled from 'styled-components';

import { ReactComponent as ClockIcon } from 'static/icons/clock.svg';

export const Container = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(ClockIcon)`
  width: 32px;
  height: 32px;
  fill: ${({ theme }) => theme.color.baliHai};
`;

export const Placeholder = styled.h5`
  color: ${({ theme }) => theme.color.baliHai};
  margin-top: 15px;
  text-align: center;
`;
