import axios from 'axios';

export const getLocation = async () => {
  const data = await axios.get(
    `https://api.ipgeolocation.io/ipgeo${
      process.env.REACT_APP_API_PREFIX === 'stage'
        ? '?apiKey=3be9216964854320bcf9a0c55568fffa'
        : ''
    } `,
  );

  return data?.data;
};
