import React, { FC } from 'react';

import { DefaultAvatar } from 'static';

import { StyledAvatar } from './styled';

interface UserAvatarProps {
  avatar: string;
  alt: string;
  style?: { [key: string]: string | number };
  zoom?: number;
  size?: 'sm' | 'md';
}

const UserAvatar: FC<UserAvatarProps> = ({
  avatar = DefaultAvatar,
  alt,
  style,
  zoom = 1,
  size,
}) => {
  const handleLoadingError = (e) => {
    e.target.src = DefaultAvatar;
  };

  return avatar ? (
    <StyledAvatar
      src={avatar}
      alt={alt}
      style={style}
      zoom={zoom}
      size={size}
      onError={handleLoadingError}
    />
  ) : (
    <StyledAvatar
      src={DefaultAvatar}
      alt={alt}
      style={style}
      zoom={zoom}
      size={size}
      onError={handleLoadingError}
    />
  );
};

export default UserAvatar;
