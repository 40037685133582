import { createSelector } from 'reselect';

import { getGroups } from 'store/selectors/currentCommunity';
import { ChatState } from '../reducers/chat';
import { IRootState } from '../reducers';

const chatSelector = (state: IRootState): ChatState => state.chat;

export const getClient = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.client || null,
);

export const getChatsLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.isLoading || null,
);

export const getChatsAuthenticated = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.isAuthenticated || null,
);

export const getChats = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.chats || [],
);

export const getChatsToView = createSelector(
  [getChats, getGroups],
  (chats, groups) => {
    return chats?.filter(
      ({ id: chatId, data }) =>
        groups?.find(({ id: groupId }) => chatId === groupId) || data.isMain,
    );
  },
);

export const getCurrentChat = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.currentChat || null,
);
