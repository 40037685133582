import styled from 'styled-components';

export const BoxContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 6px;
  z-index: 321654654;
  border-radius: 5px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;

export const RedirectionBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  border-radius: 5px;
  cursor: ${({ primary }) => (primary ? 'auto' : 'pointer')};
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: ${({ primary }) =>
    primary
      ? ({ theme }) => theme.color.white
      : ({ theme }) => theme.color.pickledBluewood};
  background-color: ${({ primary }) =>
    primary
      ? ({ theme }) => theme.color.pickledBluewood
      : ({ theme }) => theme.color.blackSqueeze};
`;
