import React, { FC, useRef, useState } from 'react';
import { noop } from 'lodash/fp';

import { Button, CloseButton } from 'components';
import { useOutsideClick } from 'hooks';

import { StyledPopup, Header, Wrapper, Message } from './styled';

interface PopupButtonMessageProps {
  popupHeaderText: string;
  popupMessageText?: string;
  popupButtonText: string;
  doClickOutSide?: boolean;
  withoutCloseButton?: boolean;
  handleConfirm?: () => void;
}

const PopupButtonMessage: FC<PopupButtonMessageProps> = ({
  popupHeaderText,
  popupMessageText,
  popupButtonText,
  withoutCloseButton,
  doClickOutSide = true,
  handleConfirm = noop,
}) => {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState<boolean>(true);

  const closePopup = () => setOpen(false);

  useOutsideClick(wrapperRef, closePopup, doClickOutSide);

  if (!open) {
    return null;
  }

  return (
    <Wrapper>
      <StyledPopup ref={wrapperRef}>
        <CloseButton hidden={withoutCloseButton} onClick={closePopup} />
        <Header>{popupHeaderText}</Header>
        {popupMessageText && <Message>{popupMessageText}</Message>}
        <Button
          width="80%"
          type="button"
          onClick={handleConfirm === noop ? closePopup : handleConfirm}
        >
          {popupButtonText}
        </Button>
      </StyledPopup>
    </Wrapper>
  );
};

export default PopupButtonMessage;
