import React, { ChangeEvent, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';
import { getUserPushTemplateLoaders } from 'store/selectors/currentCommunity';

import MobileNotification from '../Notification';
import {
  Button,
  CheckBox,
  Divider,
  TextInput,
  Loader,
  Tooltip,
} from 'components';
import { useInfoPopup } from 'hooks';

import {
  ButtonLoaderWrapper,
  ButtonWrapper,
  CheckBoxWrapper,
  PushTemplateContainer,
  PushTemplateWrapper,
  SelectBoxContainer,
  TemplateList,
} from './styled';

import { StyledLabel } from 'components/Inputs/styled';

import { FORMFIELDS, PushNotificationModuleFormValues } from '../form';
import { PushTemplate } from 'types/community';
import { StyledSectionHeader } from '../styled';

import { theme } from 'theme';
import { StyledInfoIcon } from 'components/Inputs/TextInput/styled';
import { InfoIconGreen } from 'static';

type TemplateDataProp = {
  templateName: string;
  title: string;
  message: string;
  imageUrl?: string;
  recipientList?: string[];
};

interface PushMessageTemplateProps
  extends Partial<
    Pick<UseFormMethods<PushNotificationModuleFormValues>, 'register'>
  > {
  savedTemplates?: TemplateDataProp[];
  templateList?: PushTemplate[];
  setValue?: (
    name: string,
    value: unknown,
    config?: Partial<{
      shouldValidate: boolean;
      shouldDirty: boolean;
    }>,
  ) => void;
  errors: DeepMap<PushNotificationModuleFormValues, FieldError>;
  selectedRecipients: string[];
  onSendCopyChange: (e: ChangeEvent<HTMLInputElement>) => void;
  pushMessageView?: 'single' | 'double' | 'triple';
  isSelected?: boolean;
  handleRemove?: (id: string) => void;
}

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const PushMessageTemplates: React.FC<PushMessageTemplateProps> = ({
  setValue,
  errors,
  selectedRecipients,
  onSendCopyChange,
  register,
  pushMessageView,
  isSelected,
  templateList = [],
  handleRemove,
}) => {
  const { t } = useTranslation();
  const [deleteTemplateId, setDeleteTemplateid] = useState<string>('');
  const notDelete =
    templateList.findIndex((li) => !li.isTemplate) && templateList.length === 1;
  const userPushTemplateLoaders = useSelector(getUserPushTemplateLoaders);

  const { showInfoPopup } = useInfoPopup();

  const handleApply = (data: { title: string; message: string }) => {
    const { title, message } = data;
    setValue(FORMFIELDS.TITLE, title);
    setValue(FORMFIELDS.MESSAGE, message.slice(0, 175));
  };
  const lastUsedLabel = useMemo(() => t('pushNotification.lastUsed'), [t]);
  const templateLabel = useMemo(() => t('pushNotification.template'), [t]);

  return (
    <PushTemplateContainer>
      {userPushTemplateLoaders.getTemplates ? (
        <Loader type="documentation" />
      ) : (
        <>
          <SelectBoxContainer
            padding="16.5px 8px 0px 8px"
            style={{ alignItems: 'center' }}
          >
            {pushMessageView === 'triple' && (
              <>
                <CheckBoxWrapper>
                  <CheckBox
                    width="340px"
                    isSelected={isSelected}
                    onChange={onSendCopyChange}
                    label={t('pushNotification.sendCopy')}
                    labelStyles={{ marginLeft: 0, paddingLeft: '25px' }}
                  />
                </CheckBoxWrapper>

                <TextInput
                  label={t('pushNotification.pasteUrl')}
                  name={FORMFIELDS.REDIRECT_LINK}
                  type="text"
                  error={errors[FORMFIELDS.REDIRECT_LINK]}
                  register={register}
                  extraWrapperStyle={{ width: 'calc(100% - 0px)' }}
                  width="100%"
                  inputValueStyle={{ color: theme.color.brightTurquoise }}
                  showInfoIcon={true}
                  infoText={t('pushNotification.urlInfoOne')}
                />
              </>
            )}
          </SelectBoxContainer>
          <StyledSectionHeader>
            {t('pushNotification.templates')}
            <Tooltip
              text={t('pushNotification.templateLimitInfo')}
              isVisible
              tooltipStyle={{
                fontSize: 11,
                maxWidth: 240,
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                lineBreak: 'normal',
              }}
              position="top-center"
              opacity="1"
              noArrow={false}
            >
              <StyledInfoIcon>
                <InfoIconGreen width={13} height={13} />
              </StyledInfoIcon>
            </Tooltip>
          </StyledSectionHeader>
          <Divider />
          {templateList.length > 0 && (
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
            /* @ts-ignore*/
            <AnimatePresence>
              <TemplateList variants={container}>
                {templateList.map((templateData, idx) => (
                  <PushTemplateWrapper
                    key={templateData.id}
                    variants={item}
                    exit={{ opacity: 0 }}
                  >
                    <StyledLabel>
                      {!templateData.isTemplate
                        ? t('pushNotification.lastUsed')
                        : t('pushNotification.template')}
                    </StyledLabel>

                    <MobileNotification
                      title={templateData.title}
                      message={templateData.message}
                      templateView
                      hideImage
                    />
                    <ButtonWrapper>
                      <Button
                        {...{ rounded: true }}
                        onClick={() => handleApply(templateData)}
                        variant="secondary"
                      >
                        {t('common.apply')}
                      </Button>
                      {templateData.isTemplate && !notDelete && (
                        <>
                          {deleteTemplateId === templateData.id &&
                          userPushTemplateLoaders.deleteTemplate ? (
                            <ButtonLoaderWrapper width="30%">
                              <Loader type="button" size="28px" />
                            </ButtonLoaderWrapper>
                          ) : (
                            <Button
                              variant="secondary"
                              {...{ rounded: true }}
                              width="30%"
                              onClick={() => {
                                setDeleteTemplateid(templateData.id);
                                handleRemove(templateData.id);
                              }}
                            >
                              {t('common.delete')}
                            </Button>
                          )}
                        </>
                      )}
                    </ButtonWrapper>
                  </PushTemplateWrapper>
                ))}
              </TemplateList>
            </AnimatePresence>
          )}
        </>
      )}
    </PushTemplateContainer>
  );
};

export default PushMessageTemplates;
