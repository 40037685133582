import styled, { css } from 'styled-components';

const sharedStylesButton = css`
  height: 39px;
  position: relative;
  flex: 1;
  z-index: 1000;
`;

const sharedStylesRadio = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.01;
  z-index: 100;

  &:hover + label {
    color: ${({ theme }) => theme.color.brightTurquoise};
  }

  &:checked + label {
    color: ${({ theme }) => theme.color.brightTurquoise};
    border-color: ${({ theme }) => theme.color.brightTurquoise};
  }

  &:disabled + label {
    border-color: ${({ theme }) => theme.color.blackSqueeze};
    color: ${({ theme }) => theme.color.baliHai};
    cursor: default;
  }
`;

const sharedStylesLabel = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  cursor: pointer;
  z-index: 90;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 13px;

  & > span {
    position: relative;
  }
`;

export const StyledPromoLabel = styled.span`
  display: block;
  position: absolute;
  top: -3px;
  left: auto;
  right: -26px;
  bottom: 0;
  color: #ffffff;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  height: 12px;
  width: 24px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.bittersweet};
`;

export const StyledButtonGroup = styled.div`
  width: ${({ width }) => width};
  display: flex;
  text-align: center;
  z-index: 12;
`;

export const StyledLeftButton = styled.div`
  ${sharedStylesButton}
`;

export const StyledRightButton = styled.div`
  ${sharedStylesButton}
  margin-left: -2px;
`;

export const StyledLeftRadio = styled.input`
  ${sharedStylesRadio}

  &:not(:checked) + label {
    border-right-width: 0px;
  }

  &:indeterminate + label {
    border-right-width: 1px;
  }

  &:disabled + label {
    border-right-width: 1px;
  }
`;

export const StyledRightRadio = styled.input`
  ${sharedStylesRadio}

  &:checked + label {
    border-left-width: 1px;
  }
`;

export const StyledLeftLabel = styled.label`
  ${sharedStylesLabel}
  border-radius: 4px 0 0 4px;
`;

export const StyledRightLabel = styled.label`
  ${sharedStylesLabel}
  border-radius: 0 4px 4px 0;
  border-left-width: 0;
`;
