import styled from 'styled-components';

import { breakpoints } from 'theme';

export const Title = styled.h3`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '30px')};
  margin-bottom: ${({ marginBot }) => (marginBot ? marginBot : '26px')};
  white-space: pre-wrap;

  @media ${breakpoints.downMd} {
    font-size: 26px;
    line-height: 44px;
  }
  @media ${breakpoints.downSmPlus} {
    font-size: 16px;
  }
`;

export const Image = styled.img`
  width: ${({ width }) => (width ? width : '100%')};
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  border-radius: 8px;
`;

export const Message = styled.p`
  white-space: pre-wrap;
  text-align: ${({ textAlign }) => textAlign};
  padding: 0 20px 0 20px;
  font-size: 17px;
  font-style: normal;
  line-height: 28px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.pickledBluewood};
  margin-bottom: ${({ noButton }) => (noButton ? '0px' : '32px')};

  @media ${breakpoints.downMd} {
    font-size: 14px;
    line-height: 22px;
  }

  @media ${breakpoints.downSmPlus} {
    font-weight: 500;
  }
`;

export const ExtraInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ExtraInfoText = styled(Message)`
  font-weight: bold;
`;
