import { createSelector } from 'reselect';

import { IRootState } from '../reducers';
import { getCurrentCommunity } from './currentCommunity';
import { CurrentCommunityState } from '../reducers/currentCommunity';

export const currentCommunitySelector = (
  state: IRootState,
): CurrentCommunityState => state.currentCommunity;

export const getEventTypes = createSelector(
  getCurrentCommunity,
  (community) => community.eventTypes || null,
);

export const getEventTypeLoading = createSelector(
  currentCommunitySelector,
  (community) =>
    community.loaders.createEventType ||
    community.loaders.editEventType ||
    community.loaders.deleteEventType,
);
