import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProfileMenu, MainMenu } from 'components';

import { SidebarWrapper, StyledBurger } from './styled';

const ProfileSidebarMobile = () => {
  const [initialized, setInitialized] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (isOpen && initialized) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <>
      <StyledBurger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <SidebarWrapper isOpen={isOpen}>
        <MainMenu />
        <ProfileMenu />
      </SidebarWrapper>
    </>
  );
};

export default ProfileSidebarMobile;
