import { NotificationsPageConfig, NotificationType } from './types';

const config: NotificationsPageConfig = {
  [NotificationType.EVENT_CREATE]: {
    updatedFieldsFilter: false,
    eventRepliesFilter: false,
    timeInterval: false,
  },
  [NotificationType.EVENT_UPDATE]: {
    updatedFieldsFilter: true,
    eventRepliesFilter: true,
    timeInterval: false,
  },
  [NotificationType.EVENT_DELETE]: {
    updatedFieldsFilter: false,
    eventRepliesFilter: true,
    timeInterval: false,
  },
  [NotificationType.EVENT_REMINDER]: {
    updatedFieldsFilter: false,
    eventRepliesFilter: true,
    timeInterval: true,
  },
};

export default config;
