import React, { FC, useState, useRef, ElementType } from 'react';

import { useOutsideClick } from 'hooks';
import { DeleteButton, DeleteButtonString, InfoPopup } from 'components';

interface PopupButtonMessageProps {
  ButtonComponent?: ElementType;
  IconComponent?: ElementType;
  text?: string;
  popupHeaderText: string;
  popupMessageText?: string;
  popupButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  size?: 'sm' | 'md' | 'lg';
}

const PopupButtonMessage: FC<PopupButtonMessageProps> = ({
  ButtonComponent,
  IconComponent,
  text,
  popupHeaderText,
  popupMessageText,
  popupButtonText,
  cancelButtonText,
  onConfirm = () => {},
  size,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const closePopup = () => setOpen(false);
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, closePopup);

  const handleConfirm = () => {
    onConfirm();
    closePopup();
  };

  const handleTypeButton = text ? (
    <DeleteButtonString label={text} onClick={toggleOpen} />
  ) : (
    <DeleteButton label={text} onClick={toggleOpen} />
  );

  return (
    <InfoPopup
      title={popupHeaderText}
      message={popupMessageText}
      type="warning"
      buttonText={popupButtonText}
      cancelButtonText={cancelButtonText}
      onButtonClick={handleConfirm}
      size={size}
    >
      {ButtonComponent ? (
        <ButtonComponent type="button" onClick={toggleOpen}>
          {IconComponent ? <IconComponent /> : null}
        </ButtonComponent>
      ) : (
        handleTypeButton
      )}
    </InfoPopup>
  );
};

export default PopupButtonMessage;
