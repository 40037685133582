import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledWidget = styled(motion.div)`
  position: fixed;
  width: 100%;
  bottom: -133px;
  background: white;
  box-shadow: 0 4px 10px 3px #0ec7c1;
  padding: 14px 0 38px 0;
  z-index: 1000;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 90%;
    margin: auto;
  }

  p {
    color: ${({ theme }) => theme.color.pickledBluewood};
    text-align: left;
    font-weight: 600;
    line-height: 22px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: -21px;
  right: 10px;
  background: white;
  padding: 6px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 8px 1px #0ec7c1;
  text-align: center;
`;
