import React, { FC, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ControllerRenderProps } from 'react-hook-form';

import Team from 'components/Grid/icons/Team.png';
import { FIELDS, ReplyFormData } from './form';
import { EventTask, EventTaskReply, MyAppointmentsEvent } from 'types';
import { Tooltip, CheckBox } from 'components';
import { getProfilesListInnerData } from './utils';

import { EventCard, ProfilesList } from './styled';

interface TasksListProps {
  tasks: EventTask[];
  zoom: number;
  event: MyAppointmentsEvent;
  tasksFieldProps: ControllerRenderProps<ReplyFormData>;
}

const TasksList: FC<TasksListProps> = ({
  tasks,
  zoom,
  event,
  tasksFieldProps,
}) => {
  const getFilteredTaskReplies = useCallback(
    (taskId: string): EventTaskReply[] => {
      return event.subEventTaskReplies?.filter((r) => r.eventTaskId === taskId);
    },
    [event],
  );

  return (
    <PerfectScrollbar
      style={{
        width: '100%',
        height: Math.floor(95 * zoom) + 'px',
      }}
      options={{
        wheelSpeed: 0.2,
        wheelPropagation: false,
        minScrollbarLength: 4,
      }}
    >
      <EventCard.TasksList zoom={zoom}>
        {event.tasks.map((t) =>
          tasks.find((i) => i.id === t) ? (
            <EventCard.Task key={t + event.id} zoom={zoom}>
              <Tooltip
                text={tasks.find((i) => i.id === t).title}
                zoom={zoom}
                isVisible
                tooltipStyle={{
                  fontSize: 12 * zoom,
                  height: 23 * zoom + 'px',
                }}
                visibleAfterWidth={
                  (event.subEventTaskReplies?.length > 0 ? 162 : 187) * zoom
                }
                offset={15 * zoom}
                position="top-center"
              >
                <EventCard.Task.Label zoom={zoom}>
                  <span>{tasks.find((i) => i.id === t).title}</span>
                </EventCard.Task.Label>
              </Tooltip>
              <div>
                {getFilteredTaskReplies(t).length > 0 && (
                  <Tooltip
                    text={
                      <ProfilesList>
                        {getProfilesListInnerData(
                          getFilteredTaskReplies(t),
                          zoom,
                          'task',
                        )}
                      </ProfilesList>
                    }
                    zoom={zoom}
                    isVisible
                    offset={5 * zoom}
                    position="top-center"
                    noArrow
                    opacity="1"
                    tooltipStyle={{
                      backgroundColor: '#FFF',
                      boxShadow: '0px 0px 4px 0px #6a6a6a7d',
                      width:
                        (event.subEventTaskReplies.filter(
                          (r) => r.eventTaskId === t,
                        ).length > 1
                          ? 270
                          : 135) *
                          zoom +
                        'px',
                    }}
                  >
                    <span>
                      <img
                        className="gumb-grid-cell-icon"
                        src={Team}
                        alt="team"
                      />
                      {
                        event.subEventTaskReplies.filter(
                          (r) => r.eventTaskId === t,
                        ).length
                      }
                    </span>
                  </Tooltip>
                )}
                <CheckBox
                  isSelected={tasksFieldProps.value?.includes(t)}
                  onChange={() =>
                    tasksFieldProps.onChange(
                      tasksFieldProps.value.includes(t)
                        ? tasksFieldProps.value.filter((i) => i !== t)
                        : [...tasksFieldProps.value, t],
                    )
                  }
                  name={FIELDS.TASKS + `/${event.id}`}
                  zoom={zoom}
                  disabled={event.isDisabled}
                />
              </div>
            </EventCard.Task>
          ) : null,
        )}
      </EventCard.TasksList>
    </PerfectScrollbar>
  );
};

export default TasksList;
