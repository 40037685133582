import React, { FC, useState } from 'react';
import { ItemWrapper, BtnText, Text } from './styled';
import { useTranslation } from 'react-i18next';

interface TextWithMoreProps {
  text: string;
  afterMore: number;
}

const TextWithMore: FC<TextWithMoreProps> = ({ text, afterMore }) => {
  const { t } = useTranslation();
  const [isEllipsis, setIsEllipsis] = useState(true);

  const toggleEllipsis = () => {
    setIsEllipsis((prevVal) => !prevVal);
  };

  const truncatedText =
    isEllipsis && text.split(' ').length > afterMore + 5
      ? `${text.split(' ').slice(0, afterMore).join(' ')}... `
      : text + ' ';

  return (
    <ItemWrapper>
      <Text
        isEllipsis={isEllipsis}
        truncatedText={
          truncatedText.startsWith('https://' || 'http://') ||
          truncatedText.split(' ')[0].length > 25
        }
      >
        {truncatedText}
      </Text>
      {text.split(' ').length > afterMore + 5 && (
        <BtnText onClick={toggleEllipsis}>
          {isEllipsis ? t('common.more') : t('common.less')}
        </BtnText>
      )}
    </ItemWrapper>
  );
};

export default TextWithMore;
