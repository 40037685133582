import React from 'react';

import { Container, StyledDivider, Title } from './styled';

interface DividerProps {
  title?: string;
  orientation?: 'left' | 'middle' | 'right';
  customMargin?: string;
  customPadding?: string;
  children?: JSX.Element;
}

const Divider: React.FC<DividerProps> = ({
  children,
  orientation,
  title,
  customMargin = '',
  customPadding,
}) => {
  return (
    <Container customPadding={customPadding}>
      <StyledDivider customMargin={customMargin}></StyledDivider>
      <Title position={orientation}>{title}</Title>
      {children}
    </Container>
  );
};

export default Divider;
