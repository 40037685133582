import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { StyledContainer, StyledArea, StyledSlider } from './styled';
import { StyledLabel } from '../styled';

interface SwitcherProps extends Partial<Pick<UseFormMethods, 'register'>> {
  label?: string;
  name?: string;
  disable?: boolean;
  checked?: boolean;
  onChange?: (arg?: React.ChangeEvent<HTMLInputElement>) => void;
  wrapperStyle?: { [key: string]: string | number };
  labelStyle?: { [key: string]: string | number };
}

const Switcher: FC<SwitcherProps> = ({
  label,
  name,
  register,
  disable,
  wrapperStyle,
  labelStyle,
  checked,
  onChange = () => {},
}) => (
  <StyledContainer style={wrapperStyle}>
    {label && (
      <StyledLabel htmlFor={name} noMargin style={labelStyle}>
        {label}
      </StyledLabel>
    )}
    <StyledArea disabled={disable}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        ref={register}
        onChange={(e) => onChange(e)}
      />
      <StyledSlider />
    </StyledArea>
  </StyledContainer>
);

export default Switcher;
