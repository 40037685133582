import React, { FC, useRef, useState, useEffect } from 'react';
import Picker from 'emoji-picker-react';

import { Container, EmojiContainer } from './styled';

interface EmojiPickerProps {
  value: string;
  setValue: (value: string) => void;
  cursorPosition?: number;
  setCursorPosition?: React.Dispatch<React.SetStateAction<number>>;
  positionTop?: string;
  positionRight?: string;
}

const EmojiPicker: FC<EmojiPickerProps> = ({
  value,
  setValue,
  cursorPosition,
  setCursorPosition,
  positionTop,
  positionRight,
}) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const spanRef = useRef<HTMLSpanElement>();
  const pickerRef = useRef(null);

  const handleOnEnter = () => {
    setShowEmojis(!showEmojis);
  };

  const handleEmojiClick = (emojiCode, emojiData) => {
    const newValue =
      value.slice(0, cursorPosition) +
      emojiData.emoji +
      value.slice(cursorPosition);
    setValue(newValue);
    setCursorPosition((currPosition) => currPosition + 2);
  };

  const handleClickOutside = (event) => {
    if (
      spanRef.current &&
      pickerRef.current &&
      !spanRef.current.contains(event.target) &&
      !pickerRef.current.contains(event.target)
    ) {
      setShowEmojis(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container positionTop={positionTop} positionRight={positionRight}>
      <span
        ref={spanRef}
        className="emoji"
        role="img"
        aria-hidden={true}
        onClick={handleOnEnter}
        style={{ width: 24, height: 24, fontSize: 23 }}
      >
        😊
      </span>
      {showEmojis && (
        <EmojiContainer ref={pickerRef}>
          <Picker
            onEmojiClick={handleEmojiClick}
            disableSearchBar={true}
            disableSkinTonePicker={true}
            preload={true}
          />
        </EmojiContainer>
      )}
    </Container>
  );
};

export default EmojiPicker;
