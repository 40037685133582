import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: ${({ width }) => width || '100px'};
  height: ${({ height }) => height || '100px'};
  min-width: ${({ width }) => width || '100px'};
  min-height: ${({ height }) => height || '100px'};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  opacity: ${({ image }) => (image ? '100%' : '60%')};

  &:hover {
    opacity: 100%;
  }
`;
