import React, { FC, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Button, Card } from 'components';
import useOutsideClick from 'hooks/outsideClick';
import { breakpoints } from 'theme';

import { Trigger, Wrapper } from './styled';

export interface PopupProps {
  children: JSX.Element;
  buttonText?: string;
  size?: 'sm' | 'md';
  InnerComponent: any;
  onButtonClick?: () => void;
}

const Popup: FC<PopupProps> = ({
  children,
  buttonText,
  size,
  InnerComponent,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef();
  const [open, setOpen] = useState(false);
  const isSmPlus = useMedia(breakpoints.smPlus);

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    closePopup();
  };

  useOutsideClick(wrapperRef, closePopup);

  return (
    <>
      {children && <Trigger onClick={toggleOpen}>{children}</Trigger>}
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {open && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Card cardRef={wrapperRef} size={size}>
                {typeof InnerComponent === 'function' ? (
                  <InnerComponent />
                ) : (
                  InnerComponent
                )}
                <Button
                  width={isSmPlus ? '80%' : '300px'}
                  onClick={handleButtonClick}
                >
                  {buttonText || t('common.ok')}
                </Button>
              </Card>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default Popup;
