import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  EMAIL = 'email',
}

export type ForgotFormValues = {
  [FIELDS.EMAIL]: string;
};

export const useForgotPasswordForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  const resolver: Resolver<ForgotFormValues> = yupResolver(
    yup.object().shape({
      [FIELDS.EMAIL]: yup.string().email(t('errors.email')).required(),
    }),
  );

  return useForm<ForgotFormValues>({
    resolver,
  });
};
