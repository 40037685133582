import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'lodash/fp';

import { Loader } from 'components';
import { useDocumentation } from './hooks';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import {
  getDocuments,
  getIsDocumentUploading,
} from 'store/selectors/documents';
import {
  doUploadDocument,
  doDeleteDocument,
} from 'store/actionCreators/currentCommunity/documents';

import DocumentationPage from './Documentation';

const DocumentationContainer = ({
  t,
  community,
  documents,
  currentUser,
  deleteDocument,
  uploadDocument,
  isUploading,
}) => {
  const { onDelete, onSubmit, register, setValue } = useDocumentation(
    t,
    community.id,
    uploadDocument,
    deleteDocument,
  );

  const props = {
    t,
    documents,
    isUploading,
    community,
    currentUser,
    onSubmit,
    register,
    setValue,
    onDelete,
  };

  return community && documents ? <DocumentationPage {...props} /> : <Loader />;
};

const mapStateToProps = (state) => ({
  community: getCurrentCommunity(state),
  currentUser: getCurrentUserInCommunity(state),
  documents: getDocuments(state),
  isUploading: getIsDocumentUploading(state),
});

const mapDispatchToProps = {
  deleteDocument: doDeleteDocument,
  uploadDocument: doUploadDocument,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentationContainer);
