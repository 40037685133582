import React, { useCallback, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useLocalStorage } from 'react-use';
import i18n from 'i18next';
import { Page404, Page500 } from 'pages';
import { ROUTES } from 'configs/routes';
import {
  getAuthAuthenticated,
  getAuthLoading,
  getAuthUser,
} from 'store/selectors/auth';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import { setHeaders } from 'services';
import { Loader } from 'components';
import AppRouter from './AppRouter';
import { default as AuthRouter } from 'pages/Auth';
import { logInGoogle, logInFacebook } from 'utils/auth';
import { getProfileName } from '../utils';
import { FullUser, IN_APP_NOTIFICATION_TYPE } from '../types';
import { getClient } from 'store/selectors/chat';
import { useToasts } from 'react-toast-notifications';
import { doAddNotification } from 'store/actionCreators/profile';
import { getMessage } from 'utils/common';
import { getNotifications } from 'store/selectors/auth';

const Router = () => {
  const [filteredChannels, setFilteredChannels] = useState([]);
  const dispatch = useDispatch();
  const { addToast, removeAllToasts } = useToasts();
  const user = useSelector(getAuthUser);
  const notifications = useSelector(getNotifications) || [];
  const chatClient = useSelector(getClient);
  const isAuthenticated = useSelector(getAuthAuthenticated);
  const isLoading = useSelector(getAuthLoading);
  const [prevAuth, setPrevAuth] = useLocalStorage<{ [key: string]: string }>(
    'socialSignIns',
    {},
  );
  const onAuthSuccess = (user: FullUser, session) => {
    if (user?.profile && session?.idToken?.payload?.identities) {
      const profileName = getProfileName(user.profile);
      const newIdentities: { [key: string]: string } = {};

      session.idToken.payload.identities.forEach((i) => {
        if (i.providerName === 'Google') {
          newIdentities.google = profileName;
        } else if (i.providerName === 'Facebook') {
          newIdentities.facebook = profileName;
        } else if (i.providerName === 'SignInWithApple') {
          newIdentities.apple = profileName;
        }
      });

      setPrevAuth({ ...prevAuth, ...newIdentities });
    }
  };

  const sendNotification = useCallback((notification) => {
    addToast(notification, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });
  }, []);

  const sendNotificationSticky = useCallback((notification) => {
    addToast(notification, {
      appearance: 'success',
      autoDismiss: false,
    });
  }, []);

  const fetchChannels = useCallback(async () => {
    try {
      const filters = { members: { $in: [chatClient.userID] } };
      const channels = await chatClient.queryChannels(
        filters,
        {},
        { limit: 50 },
      );
      const filteredChannels = channels.filter(
        (channel) =>
          channel?.id?.startsWith('!members') && channel.state.unreadCount > 0,
      );
      setFilteredChannels(filteredChannels);
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  }, [chatClient]);

  useEffect(() => {
    if (window?.location?.pathname?.startsWith('/profile/message')) {
      removeAllToasts();
      return;
    }
    const handleNotification = async (event) => {
      if (
        event &&
        event?.message?.user?.id !== user?.userId &&
        event?.channel_id?.startsWith('!members') &&
        (event?.type === 'message.new' ||
          event?.type === 'notification.message_new')
      ) {
        const notification = {
          id: event?.message?.id,
          userId: event?.message?.user?.id,
          message: `💬 ${getMessage(i18n)} ${event?.message?.user?.name} \n`,
          image: event?.message?.user?.image,
          link: `${window?.location.origin}${ROUTES.PROFILE}/${ROUTES.MESSAGE}/${event?.message?.user?.id}`,
          type: IN_APP_NOTIFICATION_TYPE.MESSAGE,
          isRead: false,
        };
        await dispatch(doAddNotification(notification));
        sendNotification(notification);
      }
    };

    chatClient?.on(handleNotification);

    return () => {
      chatClient?.off('notification.message_new', handleNotification);
    };
  }, [chatClient, dispatch]);

  useEffect(() => {
    if (window?.location?.pathname?.startsWith('/profile/message')) {
      removeAllToasts();
      return;
    }
    const sendNotifications = async () => {
      const notificationsSent = new Set();
      for (const channel of filteredChannels) {
        const memberData = Object.values(channel.state.members).filter(
          (li) => li?.['user_id'] !== user.userId,
        );
        const isSendNotification = notifications
          ?.slice(0, filteredChannels.length)
          .some(
            (notification) => notification.id === memberData?.[0]?.['user_id'],
          );
        if (isSendNotification) break;
        if (
          memberData.length > 0 &&
          !notificationsSent.has(memberData?.[0]?.['user_id'])
        ) {
          const notification = {
            id: memberData?.[0]?.['user_id'],
            userId: memberData?.[0]?.['user_id'],
            message: `💬 ${getMessage(i18n)} ${
              memberData?.[0]?.['user']?.name
            } \n`,
            image: memberData?.[0]?.['user']?.image,
            link: `${window?.location.origin}${ROUTES.PROFILE}/${ROUTES.MESSAGE}/${memberData?.[0]?.['user_id']}`,
            type: IN_APP_NOTIFICATION_TYPE.MESSAGE,
          };
          if (Object.values(notification).length > 0) {
            await dispatch(doAddNotification(notification));
            sendNotificationSticky(notification);
            notificationsSent.add(user.id);
          }
        }
      }
    };
    if (filteredChannels.length > 0) {
      sendNotifications();
    }
  }, [filteredChannels]);

  useEffect(() => {
    if (chatClient) fetchChannels();
  }, [chatClient, dispatch]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const error = search.get('error_description');

    if (
      error &&
      /already.found.an.entry.for.username.google/gi.test(error.toString())
    ) {
      logInGoogle();
    } else if (
      error &&
      /already.found.an.entry.for.username.facebook/gi.test(error.toString())
    ) {
      logInFacebook();
    } else if (
      !window.location.pathname.includes('change-email') &&
      !window.location.pathname.includes('verify')
    ) {
      dispatch(doCheckIsAuthorized({ onSuccess: onAuthSuccess }));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshSession, 1000 * 60 * 20);

    return () => clearInterval(interval);
  }, []);

  const refreshSession = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const cognitoSession = await Auth.currentSession();

      cognitoUser.refreshSession(
        cognitoSession['refreshToken'],
        (err, session) => {
          if (session) {
            const { idToken } = session;

            setHeaders(idToken.jwtToken);
          }
        },
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  };

  return !isLoading ? (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.PAGE404} element={<Page404 />} />
        <Route path={ROUTES.PAGE500} element={<Page500 />} />
        {isAuthenticated &&
        !window.location.pathname.includes('change-email') &&
        !window.location.pathname.includes('verify') ? (
          <Route path="*" element={<AppRouter />} />
        ) : (
          <Route path="*" element={<AuthRouter />} />
        )}
      </Routes>
    </BrowserRouter>
  ) : (
    <Loader />
  );
};

export default Router;
