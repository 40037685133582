import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import debounce from 'lodash/debounce';

import { doUpdateCommunityNotificationsSettings } from 'store/actionCreators/currentCommunity';
import {
  getCurrentCommunity,
  getIsMenuOpen,
  getUpdateCurrentCommunityLoading,
} from 'store/selectors/currentCommunity';
import config from './config';
import Card from './Card';
import { Loader } from 'components';
import { NotificationType, NotificationTypeIcon } from './types';
import { CommunityNotificationClass, CommunityNotificationValues } from 'types';

import {
  ContentWrapper,
  StyledClassBox,
  StyledFloatingLoader,
  StyledHeader,
  StyledRow,
  StyledTypeBox,
  StyledCardsWrapper,
  StyledCardWrapper,
} from './styled';
import { useMedia } from 'react-use';
import { breakpoints } from 'theme';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const isUpdating = useSelector(getUpdateCurrentCommunityLoading);
  const isDownMdPlus = useMedia(breakpoints.downMdPlus);
  const isDownLg = useMedia(breakpoints.downLg);
  const eventTypeIcon = Object.values(NotificationTypeIcon);
  const onSuccess = debounce(() => {
    addToast(t('communitySettings.editSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  }, 1000);

  const onSubmit = (
    data: CommunityNotificationValues,
    notificationsType: NotificationType,
    notificationsClass: CommunityNotificationClass,
  ) => {
    const typeConfig = config[notificationsType];
    const values: CommunityNotificationValues = { enabled: data.enabled };

    if (typeConfig.updatedFieldsFilter) {
      values.fields = data.fields;
    }
    if (typeConfig.eventRepliesFilter) {
      values.replies = data.replies.filter(
        (r) => r !== 'penalty' && r !== 'attended' && r !== 'awaiting',
      );
      values.penalty = data.replies.some((r) => r === 'penalty');
      values.attended = data.replies.some((r) => r === 'attended');
      values.awaiting = data.replies.some((r) => r === 'awaiting');
    }
    if (typeConfig.timeInterval) {
      values.rates = data.rates;
    }

    const updatedNotifications = {
      ...community.notifications,
    };

    updatedNotifications.events[notificationsType][notificationsClass] = values;

    dispatch(
      doUpdateCommunityNotificationsSettings({
        data: {
          id: community.id,
          category: community.category,
          notifications: updatedNotifications,
        },
        preventUpdateOnSuccess: true,
        onSuccess,
      }),
    );
  };

  return community.eventResponses ? (
    <ContentWrapper>
      {isUpdating && (
        <StyledFloatingLoader>
          <Loader size="60px" type={null} />
        </StyledFloatingLoader>
      )}
      {!isDownMdPlus && (
        <StyledHeader>
          {!isDownLg && <StyledTypeBox></StyledTypeBox>}
          <StyledClassBox isMenuOpen={isMenuOpen}>
            <span>{t('communitySettings.notificationsClasses.0')}</span>
          </StyledClassBox>
          <StyledClassBox isMenuOpen={isMenuOpen}>
            <span>{t('communitySettings.notificationsClasses.1')}</span>
          </StyledClassBox>
          <StyledClassBox isMenuOpen={isMenuOpen}>
            <span>{t('communitySettings.notificationsClasses.2')}</span>
          </StyledClassBox>
        </StyledHeader>
      )}
      {Object.keys(config).map((key: NotificationType, ind) => (
        <StyledRow key={key}>
          <StyledTypeBox>
            <span>{eventTypeIcon[ind]}</span>
            <span>{t(`communitySettings.notificationsTypes.${ind}`)}</span>
          </StyledTypeBox>
          <StyledCardsWrapper>
            {Object.keys(CommunityNotificationClass).map(
              (notificationClass, index) => (
                <Fragment key={notificationClass}>
                  {isDownMdPlus && (
                    <StyledClassBox isMenuOpen={isMenuOpen}>
                      <span>
                        {t(`communitySettings.notificationsClasses.${index}`)}
                      </span>
                    </StyledClassBox>
                  )}
                  <StyledCardWrapper>
                    <Card
                      notificationsType={key}
                      notificationsClass={
                        CommunityNotificationClass[notificationClass]
                      }
                      config={config[key]}
                      data={
                        community.notifications.events[key][
                          CommunityNotificationClass[notificationClass]
                        ]
                      }
                      eventResponses={community.eventResponses}
                      onSubmit={onSubmit}
                    />
                  </StyledCardWrapper>
                </Fragment>
              ),
            )}
          </StyledCardsWrapper>
        </StyledRow>
      ))}
    </ContentWrapper>
  ) : (
    <Loader />
  );
};

export default NotificationsPage;
