import React, { FC } from 'react';

import { StyledButton, StyledIcon } from './styled';

interface EditButtonProps {
  disabled?: boolean;
  outlined?: boolean;
  onClick: () => void;
  extraWrapperStyle?: React.CSSProperties;
}

const EditButton: FC<EditButtonProps> = ({
  disabled,
  outlined,
  onClick,
  extraWrapperStyle,
}) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    outlined={outlined}
    style={extraWrapperStyle}
  >
    <StyledIcon />
  </StyledButton>
);

export default EditButton;
