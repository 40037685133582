import React, { FC } from 'react';

import {
  StyledBlock,
  StyledInput,
  StyledIconBlock,
  StyledIcon,
} from './styled';

interface CheckButtonProps {
  value: string;
  label: string;
  checked: boolean;
  icon: string;
  onChange: (e: Event) => void;
}

const CheckButton: FC<CheckButtonProps> = ({
  value,
  label,
  checked,
  icon,
  onChange = () => {},
}) => (
  <StyledBlock
    className={checked && 'checked'}
    attended={value === 'attended' ? true : false}
  >
    <StyledInput
      type="checkbox"
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <StyledIconBlock>
      <StyledIcon src={icon} alt="" />
    </StyledIconBlock>
    {label}
  </StyledBlock>
);

export default CheckButton;
