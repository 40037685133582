import React, { RefObject, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import Router from 'router';
import { ToastProvider } from 'react-toast-notifications';
import Toast from 'components/Toast';
import { config } from 'constants/amplify';
import store from 'store';
import smoothscroll from 'smoothscroll-polyfill';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import i18n from 'i18next';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './i18next';
import { theme } from './theme';
import { remoteConfig } from './utils';

import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { translation } from './configs/translation';

declare global {
  interface Window {
    ReactToastProviderRef: RefObject<unknown>;
  }
}

Amplify.configure(config);

window.ReactToastProviderRef = React.createRef();

function App() {
  useEffect(() => {
    document.title = 'Gumb – Web-App';

    const scriptOne = document.createElement('script');
    const scriptTwo = document.createElement('script');

    scriptOne.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA}`;
    scriptOne.async = true;
    scriptTwo.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.REACT_APP_GA}');`;

    document.body.appendChild(scriptOne);
    document.body.appendChild(scriptTwo);
    smoothscroll.polyfill();

    (async () => {
      try {
        await fetchAndActivate(remoteConfig);

        const values = getValue(remoteConfig, 'translations').asString();
        const parsed = JSON.parse(values);
        Object.keys(parsed).forEach((lang) => {
          i18n.addResourceBundle(lang, 'translation', parsed[lang], true, true);
        });
      } catch (e) {
        const parsed = translation;
        Object.keys(parsed).forEach((lang) => {
          i18n.addResourceBundle(lang, 'translation', parsed[lang], true, true);
        });
      }
    })();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Toaster />
        <ToastProvider
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          components={{ Toast }}
          placement="top-center"
          ref={window.ReactToastProviderRef}
          autoDismissTimeout={6000}
        >
          <GoogleOAuthProvider clientId="634800902990-5rmde934lu1levk1pfojobifchdin8c3.apps.googleusercontent.com">
            <div className="App">
              <Router />
            </div>
          </GoogleOAuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
