import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 50px;
  position: sticky;
  top: 0;
`;

export const Header = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 0 25px;
  padding-left: ${({ isMenuOpen }) => (isMenuOpen ? 23 : 80)}px;
  transition: padding-left 0.3s;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 700;
`;
