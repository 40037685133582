export const DEFAULT_RESPONSES = [
  {
    title: 'Yes, I am coming',
    color: '#06C706',
    isCommentRequired: false,
  },
  {
    title: 'No, I am not coming',
    color: '#F06262',
    isCommentRequired: false,
  },
  {
    title: 'May be',
    color: '#FFAF63',
    isCommentRequired: false,
  },
  {
    title: 'Neutral',
    color: '#6C757D',
    isCommentRequired: false,
  },
];
