import React, { FC } from 'react';

import {
  Container,
  Counter,
  Description,
  StatusIndicator,
  StatusIndicatorWrapper,
  InfoWrapper,
} from './styled';

interface SignedCounterProps {
  value: number;
  description: string;
  variant?: 'primary' | 'secondary';
}

const SignedCounter: FC<SignedCounterProps> = ({
  value,
  description,
  variant = 'secondary',
}) => (
  <Container>
    <StatusIndicatorWrapper>
      <StatusIndicator variant={variant} />
    </StatusIndicatorWrapper>
    <InfoWrapper>
      <Counter>{value}</Counter>
      <Description>{description}</Description>
    </InfoWrapper>
  </Container>
);

export default SignedCounter;
