import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'utils/toastAbstraction';
import i18n from 'i18next';
import { get, isEmpty } from 'lodash/fp';

import {
  setSubscriptionRequest,
  getSubscriptionsRequest,
  getSubscriptionPlanIdRequest,
  startTrialRequest,
  activateSubscriptionRequest,
  cancelSubscriptionRequest,
} from 'services/subscriptions';
import {
  getAllSubscriptions,
  getSubscriptionPlanId,
  getCurrentUserSubscription,
  setSubscription,
  paymentApproved,
  startTrial,
  activateSubscription,
  cancelSubscription,
  hasAdblocker,
} from 'store/actionCreators/subscriptions';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import { getLocation } from 'services/geolocation';

export function* onGetSubscriptions() {
  let countryName = '';
  try {
    const { country_name } = yield call(getLocation);
    countryName = country_name;
  } catch (_) {
    countryName = 'Switzerland';
    yield put(hasAdblocker.success());
  }

  try {
    yield put(getAllSubscriptions.request());
    const { data } = yield call(
      getSubscriptionsRequest,
      `/plans${
        countryName ? `?country=${countryName.split(' ').join('_')}` : ''
      }`,
    );
    yield put(getCurrentUserSubscription.trigger());
    yield put(getAllSubscriptions.success(data));
  } catch ({ response }) {
    yield put(getAllSubscriptions.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onGetSubscriptionPlanId({
  payload,
}: ReturnType<typeof getSubscriptionPlanId>) {
  let countryName = '';
  try {
    const { country_name } = yield call(getLocation);
    countryName = country_name;
  } catch (_) {
    countryName = 'Switzerland';
  }
  try {
    yield put(getSubscriptionPlanId.request());
    const { paypalPlanId } = yield call(
      getSubscriptionPlanIdRequest,
      `${
        countryName
          ? `?productId=${payload.productId}&frequency=${
              payload.frequency
            }&country=${countryName.split(' ').join('_').toLowerCase()}`
          : ''
      }`,
    );
    yield put(getSubscriptionPlanId.success(paypalPlanId));
  } catch ({ response }) {
    yield put(getSubscriptionPlanId.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onGetCurrentUserSubscription() {
  try {
    yield put(getCurrentUserSubscription.request());
    const data = yield call(getSubscriptionsRequest);
    yield put(getCurrentUserSubscription.success(data));
  } catch ({ response }) {
    if (response.status === 404) {
      yield put(getCurrentUserSubscription.success());
    } else {
      yield put(getCurrentUserSubscription.failure(response.data));
      if (get(['status'], response) === 401)
        yield put(doCheckIsAuthorized.trigger({}));
    }
  }
}

export function* onSetSubscription({
  payload,
}: ReturnType<typeof setSubscription>) {
  let countryName = '';
  try {
    const { country_name } = yield call(getLocation);
    countryName = country_name;
  } catch (_) {
    countryName = 'Switzerland';
  }
  try {
    if (isEmpty(payload)) {
      yield put(setSubscription.success());
    } else {
      const data = {
        ...payload,
        country: countryName.toLowerCase().split(' ').join('_'),
      };
      yield put(setSubscription.request(data));
      const { paypalPlan } = yield call(setSubscriptionRequest, data);
      yield put(setSubscription.success({ paypalPlan }));
    }
  } catch ({ response }) {
    yield toast(i18n.t('subscriptions.failedToSubscribe'), {
      appearance: 'error',
      autoDismiss: true,
    });
    yield put(setSubscription.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onPaymentApproved({
  payload,
}: ReturnType<typeof paymentApproved>) {
  try {
    yield put(setSubscription.request(payload));
    const subscription = yield payload.subscription.get();
    yield put(setSubscription.success(subscription));
    yield toast(i18n.t('subscriptions.approved'), {
      appearance: 'success',
      autoDismiss: false,
    });
  } catch (e) {
    yield toast(i18n.t('subscriptions.paymentError'), {
      appearance: 'error',
      autoDismiss: true,
    });
    yield put(setSubscription.failure(e));
  }
}

export function* onStartTrial() {
  try {
    const response = yield call(startTrialRequest);
    yield put(activateSubscription.success(response.data));
    yield toast(i18n.t('subscriptions.trialActivated'), {
      appearance: 'success',
      autoDismiss: false,
    });
  } catch (e) {
    yield toast(e.message, {
      appearance: 'error',
      autoDismiss: true,
    });
    yield put(activateSubscription.failure());
  }
}

export function* onActivateSubscription({
  payload,
}: ReturnType<typeof activateSubscription>) {
  try {
    const response = yield call(activateSubscriptionRequest, payload);
    yield put(activateSubscription.success(response.data));
    yield toast(i18n.t('subscriptions.activated'), {
      appearance: 'success',
      autoDismiss: false,
    });
  } catch (e) {
    yield toast(e.message, {
      appearance: 'error',
      autoDismiss: true,
    });
    yield put(activateSubscription.failure());
  }
}

export function* onCancelSubscription({
  payload,
}: ReturnType<typeof cancelSubscription>) {
  try {
    const response = yield call(cancelSubscriptionRequest, payload);
    yield put(cancelSubscription.success(response.data));
    yield toast(i18n.t('subscriptions.cancelled'), {
      appearance: 'success',
      autoDismiss: false,
    });
  } catch (e) {
    yield toast(e.message, {
      appearance: 'error',
      autoDismiss: true,
    });
    yield put(cancelSubscription.failure());
  }
}

export default function* () {
  yield takeLatest(getAllSubscriptions, onGetSubscriptions);
  yield takeLatest(getSubscriptionPlanId, onGetSubscriptionPlanId);
  yield takeLatest(getCurrentUserSubscription, onGetCurrentUserSubscription);
  yield takeLatest(setSubscription, onSetSubscription);
  yield takeLatest(paymentApproved, onPaymentApproved);
  yield takeLatest(startTrial, onStartTrial);
  yield takeLatest(activateSubscription, onActivateSubscription);
  yield takeLatest(cancelSubscription, onCancelSubscription);
}
