import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';

import { useOutsideClick } from 'hooks';

import { PopupEvent } from 'components';

const PopupEventWrapper = ({
  children = null,
  isOpen = false,
  popupMessageText,
  popupButtons = null,
  buttonContainerStyles = {},
  popupStyles = {},
  innerComponent = null,
  onPopupClose = () => {},
  darkBackground,
  extraMessageStyle = {},
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setOpen((open) => !open);
  };
  const closePopup = () => {
    setOpen(false);
    onPopupClose();
  };

  const wrapperRef = useRef();

  useOutsideClick(wrapperRef, closePopup);

  return (
    <>
      <div onClick={toggleOpen}>{children}</div>

      {createPortal(
        <AnimatePresence>
          {open && (
            <PopupEvent
              wrapperRef={wrapperRef}
              closePopup={closePopup}
              popupMessageText={popupMessageText}
              popupButtons={popupButtons}
              buttonContainerStyles={buttonContainerStyles}
              popupStyles={popupStyles}
              innerComponent={innerComponent}
              extraMessageStyle={extraMessageStyle}
              darkBackground={darkBackground}
            />
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default PopupEventWrapper;
