import styled from 'styled-components';

import { AddIcon } from 'static';
import { breakpoints } from 'theme';

export const PageContainer = styled.div`
  display: flex;
  min-height: 100svh;
  position: relative;
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  transition: all 0.3s;
  background-color: #fbfffe;
  @media ${breakpoints.sm} {
    width: 100vw;
    margin-left: 0px;
  }

  @media ${breakpoints.md} {
    width: calc(100% - ${({ isMenuOpen = true }) => (isMenuOpen ? 360 : 60)}px);
    margin-left: ${({ isMenuOpen = true }) => (isMenuOpen ? '360px' : '60px')};
  }
`;

export const InputsBlock = styled.div`
  display: flex;
  width: 325px;
  padding-top: 16px;

  @media ${breakpoints.downMd} {
    width: calc(100%);
  }
`;

export const ColorInputBlock = styled.div`
  width: 105px;

  & .twitter-picker {
    max-width: 105px;
  }
`;

export const ButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  button {
    width: 100%;
    flex: 1;
  }
`;

export const DeleteButtonBlock = styled.div`
  display: flex;
  align-items: center;
  flex: 0.45;
  border-radius: 9999999px;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.baliHai};
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;

export const EditButtonBlock = styled.div`
  flex: 1;
  display: flex;
`;

export const StyledListItem = styled.div`
  height: 61px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  font-weight: 400;
  font-size: 15px;
`;

export const StyledNameWrapper = styled.div`
  width: 290px;
`;

export const ListPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: ${({ theme }) => theme.color.baliHai};
  height: 60px;
`;

export const StyledPlusIcon = styled(AddIcon)`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;
