import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

import { CommunityRoles } from 'types';

export enum FIELDS {
  ROLE = 'role',
}

export type EditUserRoleValues = {
  [FIELDS.ROLE]: { label: string; value: CommunityRoles };
};

export const useEditUserRoleForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  const resolver: Resolver<EditUserRoleValues> = yupResolver(
    yup.object().shape({
      [FIELDS.ROLE]: yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      }),
    }),
  );

  return useForm<EditUserRoleValues>({
    resolver,
  });
};
