import styled from 'styled-components';
import { breakpoints } from 'theme';

const Layout = styled.div`
  height: 100svh;
  background: ${({ theme }) => theme.color.dew};
`;

Layout.Header = styled.div`
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  margin-bottom: 10px;
`;

Layout.Inner = styled.div`
  background-color: ${({ theme }) => theme.color.dew};
  height: calc(100svh - 154px);
  overflow-y: scroll;
  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 119px);
  }
`;

Layout.Table = styled.div`
  margin-bottom: 10px;

  table {
    thead {
      th {
        height: 30px;
      }
    }
  }
`;

Layout.Title = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 700;
  font-size: 14px;
  padding: 30px 25px 20px 25px;
  text-transform: uppercase;
  text-align: left;
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
`;

export { Layout, Heading };
