import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import Linkify from 'react-linkify';

import {
  StyledParagraph,
  StyledText,
  StyledButton,
  Extension,
  StyledDots,
  StyledDescriptionText,
} from './styled';

type ParagraphProps = {
  text: string;
  rows: number;
  style?: React.CSSProperties;
  backgroundColor?: string;
  textColor?: string;
  textStyle?: React.CSSProperties;
};

const Paragraph: React.FC<ParagraphProps> = ({
  text,
  rows,
  style,
  backgroundColor = theme.color.white,
  textColor = theme.color.black,
  textStyle,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);
  const paragraphRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [lineHeight, setLineHeight] = useState<string>('0');

  const toggleExpand = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  useEffect(() => {
    const paragraphElement = paragraphRef.current;

    if (paragraphElement && lineHeight === '0') {
      const computedStyle = window.getComputedStyle(paragraphElement);
      const newLineHeight = computedStyle.lineHeight;

      setTimeout(() => {
        setLineHeight(newLineHeight);
      }, 50);
    }

    if (
      paragraphElement &&
      paragraphElement.scrollHeight > paragraphElement.clientHeight &&
      lineHeight !== '0'
    ) {
      setIsEllipsis(true);
    } else {
      setIsEllipsis(false);
    }

    return () => {
      setIsEllipsis(false);
    };
  }, [lineHeight, paragraphRef]);

  const getMaxHeight = () => {
    if (expanded) {
      return isEllipsis ? 'none' : '20px';
    } else {
      return `${parseInt(lineHeight) * rows}px`;
    }
  };

  return (
    <StyledParagraph
      ref={paragraphRef}
      maxHeight={getMaxHeight()}
      overflow={expanded ? 'visible' : 'hidden'}
      style={style}
    >
      <StyledText
        ref={textRef}
        backgroundColor={backgroundColor}
        style={textStyle}
      >
        <Linkify>
          <StyledDescriptionText>
            {text}
            {expanded && (
              <span style={{ marginLeft: '4px' }}>
                <StyledButton
                  onClick={toggleExpand}
                  backgroundColor={backgroundColor}
                >
                  {expanded ? t('common.less') : t('common.more')}
                </StyledButton>
              </span>
            )}
          </StyledDescriptionText>
        </Linkify>
      </StyledText>
      {isEllipsis && !expanded && (
        <Extension backgroundColor={backgroundColor} textColor={textColor}>
          <StyledDots>...</StyledDots>
          <StyledButton onClick={toggleExpand}>
            {expanded ? t('common.less') : t('common.more')}
          </StyledButton>
        </Extension>
      )}
    </StyledParagraph>
  );
};

export default Paragraph;
