import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { EventPdf } from 'components';
import { getEventPDF } from 'services/events';
import { requestCommunities } from 'services/communities';
import {
  MyAppointmentsEvent,
  Event,
  Community,
  CommunityListItem,
  EventType,
  EventResponse,
  EventTask,
} from 'types';

export const generatePdf = async (
  event: MyAppointmentsEvent | Event,
  community: Community | CommunityListItem,
  eventTypes: EventType[],
  responses: EventResponse[],
  tasks: EventTask[],
  timeFormat: 12 | 24,
  loadCommunity = false,
): Promise<void> => {
  let loadedCommunity = null;

  if (loadCommunity) {
    loadedCommunity = await requestCommunities({ communityId: community.id });
  }

  const htmlString = renderToStaticMarkup(
    <EventPdf
      event={event}
      community={loadedCommunity || community}
      eventTypes={eventTypes}
      eventResponses={responses}
      tasks={tasks}
      timeFormat={timeFormat}
    />,
  );

  try {
    const data = await getEventPDF(community.id, event.id, {
      html: htmlString,
    });
    const link = document.createElement('a');

    link.href = 'data:application/pdf;base64,' + data;

    const fileName = event.title.replaceAll('.', '_');

    link.download = fileName;

    link.click();
  } catch (e) {
    console.log(e);
  }
};
