import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export type SignInFormValues = {
  [FIELDS.EMAIL]: string;
  [FIELDS.PASSWORD]: string;
};

export const useSignInForm = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
  });

  const resolver: Resolver<SignInFormValues> = yupResolver(
    yup.object().shape({
      [FIELDS.EMAIL]: yup.string().trim().email(t('errors.email')).required(),
      [FIELDS.PASSWORD]: yup.string().required(t('errors.required')),
    }),
  );

  return useForm<SignInFormValues>({
    resolver,
  });
};
