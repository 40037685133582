import styled, { keyframes } from 'styled-components';

import { breakpoints } from 'theme';

const width = (width) => keyframes`
  from {
    width: 0;
  }

  to {
    width: ${width || 376}px;
  }
`;

export const Container = styled.div`
  width: ${({ width }) => (width >= 376 ? width : 376)}px;
  height: 100svh;
  border-radius: 8px;
  background-color: #fbfffe;
  box-shadow: 0 4px 12px 0 rgba(48, 70, 89, 0.2);
  animation: ${({ width: sidebarWidth = 376 }) =>
      width(sidebarWidth >= 376 ? sidebarWidth : 376)}
    0.1s linear;
  position: fixed;
  top: 0;
  left: ${({ placement = 'right' }) => (placement === 'left' ? 0 : 'unset')};
  right: ${({ placement = 'right' }) => (placement === 'right' ? 0 : 'unset')};
  z-index: 2;

  @media ${breakpoints.downMd} {
    width: 450px;
  }

  @media ${breakpoints.downSmPlus} {
    width: 100%;
  }
`;
