import { axiosInstance as axios } from '../index';
import { EditCommunityResponseFormData } from 'sidebars/EditCommunityResponse/form';

export const requestCommunityResponses = async (id: string) => {
  const response = await axios.get(`/communities/${id}/eventResponse`);
  return response.data;
};

export const requestCreateCommunityResponse = async (
  communityId: string,
  data: EditCommunityResponseFormData,
) => {
  const response = await axios.post(
    `/communities/${communityId}/eventResponse`,
    data,
  );
  return response.data;
};

export const requestEditCommunityResponse = async (
  communityId: string,
  id: string,
  data: EditCommunityResponseFormData,
) => {
  const response = await axios.put(
    `/communities/${communityId}/eventResponse/${id}`,
    data,
  );
  return response.data;
};

export const requestDeleteCommunityResponse = async (
  communityId: string,
  id: string,
) => {
  const response = await axios.delete(
    `/communities/${communityId}/eventResponse/${id}`,
  );
  return response.data;
};
