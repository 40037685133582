import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100svh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.darkGrey};
  z-index: 10000000000000000;
  left: 0;
  top: 0;
  padding: 0px 16px;
`;

export const StyledPopup = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PopupWrapper = styled.div`
  padding: 50px 20px 40px 20px;
  border-radius: 8px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.06);
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.color.white};

  & > button:first-of-type {
    position: absolute;
    right: 13px;
    top: 13px;
  }
`;
export const Title = styled.h2`
  justify-content: center;
  align-items: center;
  padding-bottom: ${({ padding }) => (padding ? padding : '18px')};
  font-size: 16px;
  font-weight: 700;
  color: #304659;
`;

export const ShareButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 24px;
  transition: all ease 0.3s;
`;

export const Text = styled.p`
  font-size: 13px;
  color: #8298ab;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60px;
  padding: 8px 2px;
  border-radius: 4px;
  &:hover {
    background-color: #ebf2f7;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;
export const StyledInvitationLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  color: ${({ linkCopied }) => (!linkCopied ? 'white' : '#304659')};
  cursor: pointer;
  padding: 7px 42px;
  border: 1px dashed #12dad2;
  border-radius: 999999px;
  background-color: ${({ linkCopied }) => (!linkCopied ? '#33aba7' : 'white')};
  svg {
    margin-left: 6px;

    g {
      fill: ${({ linkCopied }) => (!linkCopied ? 'white' : '#304659')};
    }
  }

  &:hover {
    background-color: white;
    color: #304659;
    svg g {
      fill: #304659;
    }
  }
`;
