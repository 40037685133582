import React from 'react';
import { useTranslation } from 'react-i18next';

import { GoogleButton, AppleButton, FacebookButton } from 'components';

import { Container, Label } from './styled';

const SocialContainer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Label>{t('auth.signInWith') || 'sign in with'}</Label>
      <GoogleButton />
      <AppleButton />
      <FacebookButton />
    </Container>
  );
};

export default SocialContainer;
