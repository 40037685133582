import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'components';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import { ReactComponent as LeftIcon } from 'static/icons/left.svg';
import { FIELDS } from './form';
import { useForgotPassword } from './hooks';

import { StyledButton, Header, StyledForm, StyledGumbLogo } from '../styled';

const ForgotPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, onSubmit, errors, isLoading } = useForgotPassword();

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledGumbLogo />
      <Header>
        <LeftIcon onClick={() => navigate('/')} />
        {t('auth.recovery')}
      </Header>
      <TextInput
        register={register}
        error={errors[FIELDS.EMAIL]}
        name={FIELDS.EMAIL}
        label={t('auth.labels.email')}
      />
      <StyledButton
        variant={isLoading ? 'secondary' : null}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader type="button" size="28px" thickness={2} />
        ) : (
          t('auth.send')
        )}
      </StyledButton>
    </StyledForm>
  );
};

export default ForgotPage;
