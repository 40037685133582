import { createSelector } from 'reselect';
import { get, getOr } from 'lodash/fp';

import { getAuthUser } from './auth';
import { IRootState } from '../reducers';
import { GroupRoles, GroupUser } from 'types';
import { CurrentGroupState } from '../reducers/currentGroup';

const currentGroupSelector = (state: IRootState): CurrentGroupState =>
  state.currentGroup;
const usersInGroupSelector = (
  state: IRootState,
): { count: number; data: GroupUser[] } => state.currentGroup.data?.users;

export const getCurrentGroup = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.data,
);

export const getCurrentUserInGroup = createSelector(
  [usersInGroupSelector, getAuthUser],
  (users, currentUser) =>
    users?.data?.find((u) => u.id === currentUser.userId) || null,
);

export const getAllGroupMembers = createSelector(
  getCurrentGroup,
  (currentGroup) => currentGroup?.users?.data || [],
);

export const getTotal = createSelector(getCurrentGroup, (currentGroup) =>
  get(['total'], currentGroup),
);

export const getRequestTotalLoading = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.loaders?.getTotal,
);

export const getGroupAdmins = createSelector(getAllGroupMembers, (members) =>
  members?.filter((m) => m?.groupPermission?.role == GroupRoles.ADMIN),
);

export const getGroupMembers = createSelector(getAllGroupMembers, (members) =>
  members?.filter((m) => m?.groupPermission?.role == GroupRoles.MEMBER),
);

export const getGroupLeaders = createSelector(getAllGroupMembers, (members) =>
  members?.filter((m) => m?.groupPermission?.role == GroupRoles.GROUP_LEAD),
);

export const getStatistic = createSelector(
  getCurrentGroup,
  (currentGroup) => currentGroup?.statistic,
);

export const getStatisticLoading = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.loaders?.getStatistic,
);

export const getChangeGroupUserRoleLoading = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.loaders?.changeRole,
);

export const getDeleteGroupUserLoading = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.loaders?.deleteGroupUser,
);

export const getCurrentGroupLoading = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.loaders?.currentGroup,
);

export const getCurrentGroupError = createSelector(
  currentGroupSelector,
  (currentGroup) => getOr(false, ['errors', 'currentGroup'], currentGroup),
);

export const getAddMembersToGroupLoading = createSelector(
  currentGroupSelector,
  (currentGroup) => currentGroup?.loaders?.doAddMembersToGroup,
);
