import { axiosInstance as axios } from './index';

export const getSubscriptionsRequest = async (parameters?: string) => {
  const response = await axios.get(`subscriptions${parameters || '?new=true'}`);
  return response.data;
};

export const getSubscriptionPlanIdRequest = async (parameters: string) => {
  const response = await axios.get(`subscriptions/plan${parameters || ''}`);
  return response.data;
};

export const setSubscriptionRequest = async ({
  productId,
  subscriptionId,
  pricing,
  country,
}: {
  productId: string;
  subscriptionId: string;
  pricing: string;
  country: string;
}) => {
  const response = await axios.post('subscriptions', {
    country,
    frequency: pricing,
    subscriptionPlanId: productId,
    subscriptionId,
  });
  return response.data;
};

export const startTrialRequest = () => {
  return axios.get('subscriptions/startTrial');
};

export const activateSubscriptionRequest = (subscriptionId: string) => {
  return axios.post('subscriptions/activateSubscription', {
    subscriptionId,
  });
};

export const cancelSubscriptionRequest = (subscriptionId: string) => {
  return axios.post('subscriptions/cancelSubscription', {
    subscriptionId,
  });
};
