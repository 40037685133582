import React, { FC } from 'react';

import { CellsTypes } from 'constants/index';
import { PlusButton } from 'components';
import TableRow from './Row';

import {
  StyledTable,
  StyledHeader,
  StyledHeaderRow,
  StyledHeaderCell,
  StyledBody,
} from './styled';

interface TableProps {
  config: {
    title: string;
    headers: {
      id: string;
      width: string;
      type: CellsTypes;
      headerType: string;
      label?: string;
    }[];
  };
  data: any[];
}

const Table: FC<TableProps> = ({ config, data }) => {
  const renderHeaderCellContent = (headerType, label, tableTitle) => {
    switch (headerType) {
      case 'title':
        return tableTitle;
      case 'button':
        return <PlusButton width="30px" onClick={() => {}} />;
      default:
        return label;
    }
  };

  return (
    <StyledTable>
      <StyledHeader>
        <StyledHeaderRow withPadding={true}>
          {config.headers.map((cell) => (
            <StyledHeaderCell
              key={cell.id}
              width={cell.width}
              type={cell.type}
              headerType={cell.headerType}
            >
              {renderHeaderCellContent(
                cell.headerType,
                cell.label,
                config.title,
              )}
            </StyledHeaderCell>
          ))}
        </StyledHeaderRow>
      </StyledHeader>
      <StyledBody>
        {data.map((dataItem) => (
          <TableRow
            key={dataItem.id}
            dataItem={dataItem}
            config={config}
            withBadge
          />
        ))}
      </StyledBody>
    </StyledTable>
  );
};

export default Table;
