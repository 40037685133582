import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { doUpdateGroup } from 'store/actionCreators/groups';

import { useGeneralGroupSettingsForm } from './form';

import { useToasts } from 'react-toast-notifications';

export const useGeneralGroupSettings = (group) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const { handleSubmit, ...form } = useGeneralGroupSettingsForm({
    name: group.name,
  });

  const onSuccess = () => {
    addToast(t('group.changeGroupNameSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const updateGroup = (data) => dispatch(doUpdateGroup(data));

  const onSubmit = handleSubmit((values) =>
    updateGroup({
      id: group.id,
      onSuccess,
      ...values,
    }),
  );

  return {
    onSubmit,
    ...form,
  };
};
