import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

export const useDocumentation = (
  t,
  communityId,
  uploadDocuments,
  deleteDocument,
) => {
  const { handleSubmit, ...form } = useForm();
  const { addToast } = useToasts();

  const onSuccessUpload = () => {
    addToast(t('documentation.uploadSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSuccessDelete = () => {
    addToast(t('documentation.deleteSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = handleSubmit((data) => {
    uploadDocuments({
      communityId,
      data,
      onSuccess: onSuccessUpload,
    });
  });

  const onDelete = (e, documentId) => {
    e.preventDefault();
    deleteDocument({ communityId, documentId, onSuccess: onSuccessDelete });
  };

  return {
    onSubmit,
    onDelete,
    ...form,
  };
};
