import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  position: fixed;
  width: ${({ isOpen }) => (isOpen ? '100%' : 0)};
  max-width: 320px;
  overflow: hidden;
  display: flex;
  box-shadow: rgb(48 70 89 / 20%) 0px 4px 12px 0px;
  transition: all 0.3s;
  z-index: 10000002;

  & > div {
    position: relative;
    margin-left: 0;
    margin-right: 0;

    &:last-of-type {
      min-width: 250px;
    }
  }
`;

export const StyledBurger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 10000001;
  background: white;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px #00000038;
  position: fixed;

  div {
    width: 19px;
    height: 4px;
    margin: 1.5px auto;
    background: #25cdc8;
    border-radius: 1px;
  }
`;
